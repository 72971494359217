import Button from "src/components/button/Button"
import Flexbox from "src/components/wrapper/Flexbox"
import IconGrid from 'src/assets/images/icons/icon-grid-on.svg'
import { useState } from "react"
import ListingPhotosCarouselOverModal from "./ListingPhotosCarouselOverModal"
import TypeFace from "src/components/typography/Typefaces"

const ListingPhotos = ({
  photos,
  isSold,
  isRented,
}) => {

  const [carouselSelectionIndex, setCarouselSelectionIndex] = useState(0)
  const [carouselModalIsOpen, setCarouselModalIsOpen] = useState(false)

  const showAllPhotos = () => {
    setCarouselSelectionIndex(0)
    setCarouselModalIsOpen(true)
  }

  if (!photos) return null
  return (
    <Flexbox row style={styles.container}>
      <Flexbox style={{ flex: 1, height: '100%' }}>
      {isSold && 
      <div style={styles.soldInfo}>
         <TypeFace bold color="red">
              SOLD
         </TypeFace>
       </div>
      }
      {isRented && 
      <div style={styles.soldInfo}>
         <TypeFace bold color="red">
              RENTED
         </TypeFace>
       </div>
      }
        <img
          src={photos[0]?.url}
          onClick={() => {
            setCarouselSelectionIndex(0)
            setCarouselModalIsOpen(true)
          }}
          style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            objectFit: 'cover'
          }}
        />
      </Flexbox>
      {photos.length === 2 ? (
        <Flexbox style={{ flex: 1, height: '100%' }}>
          <img
            src={photos[1]?.url}
            onClick={() => {
              setCarouselSelectionIndex(1)
              setCarouselModalIsOpen(true)
            }}
            style={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              objectFit: 'cover'
            }}
          />
        </Flexbox>
      ) : (
        <>
          {photos.length <= 4 ? (
            <Flexbox style={{ width: 250, gap: 3, height: '100%' }}>
              <img
                src={photos[1]?.url}
                onClick={() => {
                  setCarouselSelectionIndex(1)
                  setCarouselModalIsOpen(true)
                }}
                style={{
                  width: '100%',
                  flex: 1,
                  cursor: 'pointer',
                  objectFit: 'cover'
                }}
              />
              <img
                src={photos[2]?.url}
                onClick={() => {
                  setCarouselSelectionIndex(2)
                  setCarouselModalIsOpen(true)
                }}
                style={{
                  width: '100%',
                  flex: 1,
                  cursor: 'pointer',
                  objectFit: 'cover'
                }}
              />
            </Flexbox>
          ) : (
            <>
              <Flexbox style={{ width: 250, gap: 3, height: '100%' }}>
                <img
                  src={photos[1]?.url}
                  onClick={() => {
                    setCarouselSelectionIndex(1)
                    setCarouselModalIsOpen(true)
                  }}
                  style={{
                    width: '100%',
                    flex: 1,
                    cursor: 'pointer',
                    objectFit: 'cover'
                  }}
                />
                <img
                  src={photos[2]?.url}
                  onClick={() => {
                    setCarouselSelectionIndex(2)
                    setCarouselModalIsOpen(true)
                  }}
                  style={{
                    width: '100%',
                    flex: 1,
                    cursor: 'pointer',
                    objectFit: 'cover'
                  }}
                />
              </Flexbox>
              <Flexbox style={{ width: 250, gap: 3, height: '100%' }}>
                <img
                  src={photos[3]?.url}
                  onClick={() => {
                    setCarouselSelectionIndex(3)
                    setCarouselModalIsOpen(true)
                  }}
                  style={{
                    width: '100%',
                    height: 261,
                    cursor: 'pointer',
                    objectFit: 'cover'
                  }}
                />
                <img
                  src={photos[4]?.url}
                  onClick={() => {
                    setCarouselSelectionIndex(4)
                    setCarouselModalIsOpen(true)
                  }}
                  style={{
                    width: '100%',
                    height: 261,
                    cursor: 'pointer',
                    objectFit: 'cover'
                  }}
                />
              </Flexbox>
            </>
          )}
        </>
      )}
      <Button icon={<img src={IconGrid} />} secondary style={styles.seeAllButtonStyle} onClick={showAllPhotos}>
        See All Photos
      </Button>
      <ListingPhotosCarouselOverModal
        modalIsOpen={carouselModalIsOpen}
        photos={photos}
        initialIndex={carouselSelectionIndex}
        onCloseModal={() => setCarouselModalIsOpen(false)}
      />
    </Flexbox>
  )
}

export default ListingPhotos

const styles = {
  container: {
    width: '100%',
    height: 528,
    position: 'relative',
    gap:3,
    overflow: 'hidden',
  },
  seeAllButtonStyle: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    height: 45,
    paddingLeft: 10,
    paddingRight: 10,
  },
  soldInfo: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
}