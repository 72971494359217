import { useState } from "react"
import Button from "./Button"
import IconLightingBolt from 'src/assets/images/icons/icon-light-bolting.svg'
import "./MeetNowButton.css"
import useMediaQuery from "src/hooks/useMediaQuery"
import DownloadAppModal from "../modal/DownloadAppModal"

const MeetNowButton = ({
  style,
  smallMode = false,
}) => {

  const { isMobile } = useMediaQuery()

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div style={{
      height: isMobile ? (smallMode ? '60px' : '75px') : '75px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft:'8px',
      paddingRight: '8px',
      justifyContent: 'center',
      marginTop: isMobile ? (smallMode ? 0 : 20) : (smallMode ? 10 : 20),
      marginBottom: isMobile ? (smallMode ? 0 : 20) : (smallMode ? 0 : 20),
      width: isMobile ? 260 : 512,
      ...style,
    }}>
      <div id="meet-now-animating" style={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: '#3151B77F',
        zIndex: 0,
        borderRadius: '5px',
      }}>
      </div>
      <Button primary
        style={{
          height: isMobile ? (smallMode ? 45 : 60) : 60,
          width: '100%',
          zIndex: 1,
          padding: 0,
        }}
        textSize={isMobile ? (smallMode ? 16 : 24) : 24}
        icon={<img src={IconLightingBolt} width={isMobile ? (smallMode ? 16 : 24) : 30} height={isMobile ? 16 : 30}/>}
        onClick={() => setIsModalOpen(true)}
      >
        Meet Now
      </Button>
      <DownloadAppModal
        modalIsOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export default MeetNowButton