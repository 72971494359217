import React, { useEffect, useState } from 'react';
import { Colors } from 'src/theme/Colors';
import Button from '../button/Button';
import TextInput from '../input/TextInput';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import Modal from 'react-modal';
import {
  signInWithPhoneNumber,
  RecaptchaVerifier
} from 'firebase/auth';
import { auth, firestore } from 'src/firebase';
import { useAlert } from 'src/hooks/alert/AlertContext';
import IconClose from 'src/assets/images/icons/icon-close-black.svg'
import useMediaQuery from 'src/hooks/useMediaQuery';

const VerificationModal = (props) => {
  var recaptchaWrapperRef;
    const { isMobile } = useMediaQuery()
    const [processing, setProcessing] = useState(false)
  const [verificationCode, setVerificationCode] = useState("")
  const [userId, setUserId] = useState('')
  const {showAlert} = useAlert()
  const [error, setError] = useState("")

  const setUpRecaptcha = () => {
      
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
      size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
      },
      auth
      );
    
  window.recaptchaVerifier.verify();
 
  
}; 

const handleResendCode = async () => {
    setProcessing(true)
    setUpRecaptcha();
    const appVerifier = await window.recaptchaVerifier;
    console.log("appVerifier",appVerifier)
    try {
      const response = await signInWithPhoneNumber(auth, props.phoneNumber, appVerifier);
      setProcessing(false)
      console.log(response.verificationId) 
      if (appVerifier) {
        appVerifier.clear();
        document.getElementById('recaptchaWrapper').innerHTML = `<div id="recaptcha-container"></div>`
      }
    }
    catch (e) {
      console.log(e);
      if (appVerifier) {
        appVerifier.clear();
        document.getElementById('recaptchaWrapper').innerHTML = `<div id="recaptcha-container"></div>`
      }
      setProcessing(false);
    }

}

  const handleClose  = () => {

  }
  const handleVerifyCode = async () => {
    // Request for OTP verification
    if (verificationCode.length == 6) {
      console.log("verificationCode=",verificationCode)
      try {
          const res = await props.confirmationResult.confirm(verificationCode);
          setVerificationCode("");
          //console.log('res=',res)
          //console.log('resUId-test=',res.user.uid)
          setUserId(res.user.uid)
          const createdUserID = res.user.uid
          {props.isLogin === 0 ? props.getUserProfileData(createdUserID) : props.sendDataToParent(createdUserID)}
          props.closeModal()
      } catch (e) {
        console.log('error=',e)
          //showAlert(true, "Please check the OTP and try again");
          setError("Please check the OTP and try again")
      }

    } else {
       // showAlert(true, "Please enter a 6 digit OTP code.")
       setError("Please enter a 6 digit OTP code.")
    }
  }

  return (
    <>
    <div ref={ref => recaptchaWrapperRef = ref} id='recaptchaWrapper'>
      <div id="recaptcha-container"></div>
    </div>
    
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
        <Flexbox style={{
          width: isMobile ? 'calc(100vw - 80px)' : 320,
          padding: isMobile ? '20px 16px 16px' : '40px 30px 30px',
        }}>
        <Flexbox onClick={props.closeModal} style={styles.closeButton}>
          <img src={IconClose} width={24} height={24} />
        </Flexbox>
         <TypeFace bold size={18}>
             Verify Code
          </TypeFace>
          <TextInput 
            style={styles.emailInput}
            placeholder='Verification code'
            value={verificationCode}
            keyboardType='numeric'
            maxLength={6}
            onChange={e => {
              setVerificationCode(e.target.value)
              setError("")
            }}
          />
          {!!error && (
            <TypeFace size={11} color="red">{error}</TypeFace>
          )}
           <Flexbox row style={{...styles.buttons, marginTop: 30, alignItems: 'center'}}>
            <Button primary onClick={handleVerifyCode} style={styles.loginButton}>
              Verify Account
            </Button>
          </Flexbox>
          <Flexbox style={{...styles.buttons, marginTop: 30, alignItems: 'center'}}>
            <TypeFace onClick={handleResendCode} style={styles.resendButton}>
              Resend code
            </TypeFace>
          </Flexbox>
        </Flexbox>
      
    </Modal>
    </>
  )
}

export default VerificationModal

const styles = {
    container: {
        backgroundColor: 'white',
        marginHorizontal: 16,
        paddingVertical: 20,
      },
      modalWrapper: {
        margin: 0,
        justifyContent: 'center'
      },
      headerBox: {
        height: 22,
        paddingHorizontal: 10,
        flexDirection: 'row-reverse',
        marginBottom: 12,
        marginRight: 10,
      },
      themeButton: {
        width: '50%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.primary,
        borderRadius: 5
      },
      themeButtonLink: {
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
      },
      themeButtonLinkTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.primary,
        textDecorationLine: "underline",
        textDecorationColor: Colors.primary,
        textDecorationStyle: "solid",
      },
      themeButtonTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff'
      },
      verificationView: {
        width: '100%',
        alignItems: 'center',
      },
      textInput: {
        height: 44,
        width: '50%',
        paddingHorizontal: 10,
        borderRadius: 3,
        borderColor: Colors.gray1,
        borderWidth: 1,
        marginTop: 6,
      },
      buttons: {
        width: '55%',
        marginTop: 10,
        justifyContent: 'space-between',
        gap: 20,
        alignItems: 'center',
      },
      emailInput: {
        width: '55%',
        marginTop: 30,
      },
      loginButton: {
        flex: 1,
        height: 40,
      },
      resendButton: {
        width: '55%',
        flex: 1,
        height: 40,
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.primary,
        textDecorationLine: "underline",
        textDecorationColor: Colors.primary,
        textDecorationStyle: "solid",
        cursor: 'pointer'
      },
      closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: 44,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }
}