import { useMemo, useState, useEffect } from "react"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { collection, deleteField, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "src/firebase"
import { useFirestore } from "src/hooks/firestore/FirestoreContext"
import ReactSelect from "react-select"
import { Colors } from "src/theme/Colors"
import SubmitAgencyInformationModal from "src/components/account/SubmitAgencyInformationModal"
import Button from "src/components/button/Button";
import IconAvatarPlaceholder from 'src/assets/images/icons/icon-avatar-placeholder-gray.svg'
import { UserRoleValue } from "src/model/types";
import TextInput from "src/components/input/TextInput";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAlert } from "src/hooks/alert/AlertContext";
import { getFirestoreDocs } from "src/data/dbWrappers";
import { updateAlogliaObject } from "src/algolia";

const Agents = () => {

  const { isMobile } = useMediaQuery()
  const { showAlert } = useAlert()

  const allListings = []
  const activeListings = []
  const suspendedListings = []

  const { userProfile, myAgency, myAgencyAgentsList } = useFirestore()

  const [searchKeyword, setSearchKeyword] = useState("")

  const [createAgencyModalOpened, setCreateAgencyModalOpened] = useState(false)

  const userActionOptions = [
    {label: 'Suspended', value: 'suspend'},
    {label: 'Active', value: 'active'},
  ]

  const processAgentRelease = (agentId) => {

    updateDoc(doc(firestore, 'profiles', agentId), {
      agency: deleteField(),
      agencyApproved: false,
      agencyReleased: true,
      releasedDate: new Date().getTime(),
      activatedDate: '',
    })

    updateDoc(doc(firestore, `profiles/${agentId}/agencyHistory/${myAgency?.id}`), {
      releasedAt: new Date().getTime(),
      releaseddBy: userProfile?.userId
    })

    const q = query(collection(firestore, 'listing'), where("by", "==", agentId))
    getDocs(q).then(docsData => {
      let listings = []
      docsData.forEach(data => {
        listings.push(data.data())
      })
     
      //console.log('team=',[agentId])
      listings.forEach(listing => {
        updateDoc(doc(firestore, `listing/${listing.id}`), {
          agency: "",
          team: [agentId],
          status: 'Released',
        })

        if (listing.algoliaID) {
          updateAlogliaObject(listing.algoliaID, {
            agency: "",
            status: 'Released',
          })
        }
      })
    })
  }

  const handleReleaseAgent = (agentId, agentName) => {
    showAlert(
      true,
      `Are you sure you want to release ${agentName}?`,
      [
        {
          title: "Release",
          onClick: () => {
            showAlert(false)
            processAgentRelease(agentId)
          }
        },
        {
          title: "Cancel",
          onClick: () => {
            showAlert(false)
          }
        },
      ],
      0,
      `${agentName} will not have access to listings or your brokerage`
    )
  }

  const approveAgentLicense = (agent) => {
    updateDoc(doc(firestore, 'profiles', agent.userId), {
      agencyApproved: true,
      agencyReleased: false,
      releasedDate: '',
      activatedDate: new Date().getTime(),
    })

    updateDoc(doc(firestore, `profiles/${agent.userId}/agencyHistory/${myAgency?.id}`), {
      approvedAt: new Date().getTime(),
      approvedBy: userProfile?.userId
    })
  }

  const declineAgentLicense = (agent) => {
    updateDoc(doc(firestore, 'profiles', agent.userId), {
      agencyDeclined: true,
      agency: ""
    })

    updateDoc(doc(firestore, `profiles/${agent.userId}/agencyHistory/${myAgency?.id}`), {
      declinedAt: new Date().getTime(),
      declinedBy: userProfile?.userId
    })
  }

  const agentsList = useMemo(() => {
    return myAgencyAgentsList?.filter(agent => agent.role === UserRoleValue.Agent || agent.role === UserRoleValue.SalesPerson)
  }, [myAgencyAgentsList])

  const keywordFilteredAgents = useMemo(() => {
    if (searchKeyword === "") {
      return agentsList
    } else {
      return agentsList.filter(agent => {
        return agent.name.toLowerCase().includes(searchKeyword.toLowerCase()) || agent.email.toLowerCase().includes(searchKeyword.toLowerCase())
      })
    }
  }, [agentsList, searchKeyword])

  const handleSuspend = (user) => {
    if (user.suspended) {
      updateDoc(doc(firestore, `profiles/${user.userId}`), {
        suspended: false,
        activatedDate: new Date().getTime(),
      })

      const qSuspended = query(collection(firestore, 'listing'), where("by", "==", user.userId), where("status", '==', 'Suspended'))
      getDocs(qSuspended).then(docsData => {
        docsData.forEach(data => {
          allListings.push(data.data())
        })

        allListings.forEach(listing => {
          updateDoc(doc(firestore, `listing/${listing.id}`), {
            status: 'Active'
          })

          if (listing.algoliaID) {
            updateAlogliaObject(listing.algoliaID, {
              status: 'Active',
            })
          }
        })
      })
      
    } else {
      updateDoc(doc(firestore, `profiles/${user.userId}`), {
        suspended: true,
        activatedDate: '',
      })

      const qActive = query(collection(firestore, 'listing'), where("by", "==", user.userId), where("status", '==', 'Active'))
      getDocs(qActive).then(docsData => {
        docsData.forEach(data => {
          allListings.push(data.data())
        })

        allListings.forEach(listing => {
          updateDoc(doc(firestore, `listing/${listing.id}`), {
            status: 'Suspended',
          })

          if (listing.algoliaID) {
            updateAlogliaObject(listing.algoliaID, {
              status: 'Suspended',
            })
          }
        })
      })
    }
  }
  
  

  return (
    <Flexbox style={{ alignItems: 'flex-start' }}>
      <TypeFace>
        Agents
      </TypeFace>
      {(!userProfile?.agency || userProfile.agency === '') ? (
        <TypeFace medium size={14} color='#5f5f5f' style={{ textAlign: 'start', marginTop: 16 }}>
          Please set up your Agency in My Profile section first.
        </TypeFace>
      ) : (
        <>
          <TypeFace bold size={14} style={{ textAlign: 'start', marginTop: 30 }}>
            {myAgency?.name}
          </TypeFace>
          <Flexbox>
            <TextInput
              style={styles.agentSearch}
              placeholder="Search by name..."
              onChange={e => setSearchKeyword(e.target.value)}
            />
          </Flexbox>
          {keywordFilteredAgents?.map((agent, index) => (
            <Flexbox
              key={index}
              style={{
                ...styles.agentRow,
                padding: isMobile ? '10px 10px' : '20px 20px',
                backgroundColor: agent.suspended ? '#f001' : '#0000'
              }}
            >
              <Flexbox row style={{
                width: '100%',
                gap: isMobile ? 10 : 20,
              }}>
                {!!agent?.photo?.url ? (
                  <img
                      src={agent.photo.url}
                      style={styles.avatar}
                      alt="avatar"
                    />
                  ) : (
                    <img
                      src={IconAvatarPlaceholder}
                      style={styles.avatar}
                      alt="avatar-placeholder"
                    />
                  )}
                <Flexbox style={{ flex: 1, alignItems: 'flex-start'}}>
                  <TypeFace medium>
                    {agent.name}
                  </TypeFace>
                  <TypeFace size={11}>
                    {agent.email}
                  </TypeFace>
                  {isMobile ? (
                    <ReactSelect
                      options={userActionOptions}
                      onChange={v => handleSuspend(agent)}
                      value={agent.suspended ? {label: 'Suspended', value: 'suspend'} : {label: 'Active', value: 'active'}}
                      styles={{
                        container: (base) => ({
                          ...base,
                          marginTop: 10,
                        }),
                        placeholder: (base) => ({
                          ...base,
                          textAlign:'start'
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          textAlign:'start'
                        }),
                        option: (base) => ({
                          ...base,
                          textAlign:'start'
                        }),
                      }}
                    />
                  ) : (
                    <TypeFace semiBold size={11} color={agent.suspended ? 'red' : 'black'}>
                      {agent.suspended ? 'Suspended' : agent.agencyApproved === false ? 'Waiting for license approval' : 'Active'}
                    </TypeFace>
                  )}
                </Flexbox>
                {!isMobile ? (
                  <ReactSelect
                    options={userActionOptions}
                    onChange={v => handleSuspend(agent)}
                    value={agent.suspended ? {label: 'Suspended', value: 'suspend'} : {label: 'Active', value: 'active'}}
                    styles={{
                      placeholder: (base) => ({
                        ...base,
                        textAlign:'start'
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        textAlign:'start'
                      }),
                      option: (base) => ({
                        ...base,
                        textAlign:'start'
                      }),
                    }}
                  />
                ) : (
                  <TypeFace semiBold size={11} color={agent.suspended ? 'red' : 'black'}>
                    {agent.suspended ? 'Suspended' : agent.agencyApproved === false ? 'Waiting for approval' : 'Active'}
                  </TypeFace>
                )}                
              </Flexbox>
              <div style={{ height: 1, width: '100%', backgroundColor: Colors.gray3, marginTop: 15 }} />
              <Flexbox row style={{ 
                justifyContent: 'flex-start', gap: isMobile ? 5: 20,
                width: '100%',
                marginTop: 15,
                flexDirection: isMobile ? 'column' : 'row', 
                alignItems: isMobile ? 'flex-start' : 'center' }}
              >
                <TypeFace medium size={12}>
                  License name: <TypeFace bold size={14}>{agent.licenseName ?? agent.name}</TypeFace>
                </TypeFace>
                <TypeFace medium size={12}>
                  License number: <TypeFace bold size={14}>{agent.licenseNumber}</TypeFace>
                </TypeFace>
                <div style={{ flex: 1 }} />
                {agent.agencyDeclined ? (
                  <TypeFace size={isMobile ? 11 : 12} semiBold>
                    DECLINED
                  </TypeFace>
                ) : (
                  <>
                    {agent.agencyApproved === false ? (
                      <Flexbox row style={{ gap: 10 }}>
                        <Button primary textSize={12} style={styles.licenseButtons} onClick={() => approveAgentLicense(agent)}>
                          Approve
                        </Button>
                        <Button third textSize={12} style={styles.licenseButtons} onClick={() => declineAgentLicense(agent)}>
                          Decline
                        </Button>
                      </Flexbox>
                    ) : (
                      <>
                        <TypeFace size={isMobile ? 11 : 12} semiBold>
                          Status: APPROVED
                        </TypeFace>
                        <TypeFace
                          size={isMobile ? 11 : 12}
                          semiBold
                          color="red"
                          style={{ marginTop: isMobile ? 16 : 0, cursor: 'pointer' }}
                          onClick={() => handleReleaseAgent(agent?.userId, agent?.name)}
                        >
                          Release agent
                        </TypeFace>
                      </>
                    )}
                  </>
                )}
              </Flexbox>
            </Flexbox>
          ))}
        </>
      )}
      <SubmitAgencyInformationModal
        modalIsOpen={createAgencyModalOpened}
        closeModal={() => setCreateAgencyModalOpened(false)}
      />
    </Flexbox>
  )
}

export default Agents

const styles = {
  tabBarSection: {
    width: '100%',
    marginTop: 20
  },
  sectionButtons: {
    height: 40,
    border: '1px solid #dfdfdf',
    cursor: 'pointer',
  },
  sectionButtonItem: {
    width: 160,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  vertSeparator: {
    backgroundColor: '#dfdfdf',
    width: 1,
    height: '100%',
  },
  tabBarItemText: {
    cursor: 'pointer',
  },
  createButton: {
    height: 40,
    borderRadius: 0
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  },
  agentRow: {
    marginTop: 20,
    width: '90%',
    borderRadius: 5,
    padding: '20px 20px',
    boxShadow: '0 0 3px #3151B73F',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: 'cover',
  },
  licenseButtons: {
    height: 32,
  },
  agentSearch: {
    border: 'none',
    borderRadius: 0,
    width: 300,
    height: 30,
    marginTop: 20,
    borderBottom: '1px solid #dfdfdf',
  }
}