// import { tsvData, tsvDataJSON } from "./data/testdata";

import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { firestore } from "src/firebase";
import {
  addnew,
  BronxAreas,
  BrooklynAreas,
  ManhattanAreas,
  ManhattanErrored,
  NassauAreas,
  NJAreas,
  QueensAreas,
  StatenAreas,
  SuffolkAreas,
} from "./mockdataareas";
import { searchObjects } from "src/algolia";
import { getComponents } from "src/utils/geoUtils";

function tsvJSON(tsv) {
  const lines = tsv.split("\n");
  const result = [];
  const headers = lines[0].split("\t");

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split("\t");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
}

function isBoundingBoxInside(childBbox, parentBbox) {
  return (
    childBbox.north <= parentBbox.north && // Child's north must be less than or equal to parent's north
    childBbox.south >= parentBbox.south && // Child's south must be greater than or equal to parent's south
    childBbox.east <= parentBbox.east && // Child's east must be less than or equal to parent's east
    childBbox.west >= parentBbox.west // Child's west must be greater than or equal to parent's west
  );
}

function updateParentBoundingBox(childBbox, parentBbox) {
  return {
    north: Math.max(childBbox.north, parentBbox.north), // Update the farthest north point
    south: Math.min(childBbox.south, parentBbox.south), // Update the farthest south point
    east: Math.max(childBbox.east, parentBbox.east), // Update the farthest east point
    west: Math.min(childBbox.west, parentBbox.west), // Update the farthest west point
  };
}

async function pushCounty(area) {
  try {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${area}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let resData = await fetch(url);
    let res = await resData.json();

    // console.log(res);

    let comp = res.results[0].address_components;

    // const type = comp.filter((item) => item.types.includes("sublocality"));
    let county = comp.filter((item) => item.types.includes("administrative_area_level_2"))[0]?.long_name;

    let name = county;

    let state = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0]?.long_name;
    let state_short = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0]?.short_name;
    let bbox = {
      south: res.results[0].geometry.viewport.southwest.lat,
      west: res.results[0].geometry.viewport.southwest.lng,
      north: res.results[0].geometry.viewport.northeast.lat,
      east: res.results[0].geometry.viewport.northeast.lng,
    };
    let center = res.results[0].geometry.location;
    let place_name = res.results[0].formatted_address?.replaceAll(", USA", "");
    // let postal = comp.filter((item) => item.types.includes("postal_code"));

    // if (postal.length > 0 && place_name.includes(postal[0].long_name)) {
    //   place_name = place_name.replaceAll(postal[0].long_name, "");
    // }

    let data = {
      name,
      place_name,
      county,
      state,
      state_short,
      components: {
        text: name,
        bbox,
        center,
        types: res.results[0].types,
      },
      created: new Date(),
      type: "county",
      types: res.results[0].types,
    };

    // console.log(data);

    let result = await addDoc(collection(firestore, "locations"), data);
    console.log(result);

    // countys
  } catch (error) {
    console.log(error, area);
  }
}

async function pushArea(area, borough) {
  try {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${area}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let resData = await fetch(url);
    let res = await resData.json();

    // console.log(res);

    let comp = res.results[0].address_components;

    let name = comp.filter(
      (item) =>
        item.types.includes("neighborhood") ||
        item.types.includes("colloquial_area") ||
        item.types.includes("establishment") ||
        item.types.includes("locality") ||
        item.types.includes("administrative_area_level_3")
    )[0].long_name;

    let county = comp.filter((item) => item.types.includes("administrative_area_level_2"))[0].long_name;
    let state = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].long_name;
    let state_short = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].short_name;
    let bbox = {
      south: res.results[0].geometry.viewport.southwest.lat,
      west: res.results[0].geometry.viewport.southwest.lng,
      north: res.results[0].geometry.viewport.northeast.lat,
      east: res.results[0].geometry.viewport.northeast.lng,
    };
    let center = res.results[0].geometry.location;
    let place_name = res.results[0].formatted_address?.replaceAll(", USA", "");

    let postal = comp.filter((item) => item.types.includes("postal_code"));

    if (postal.length > 0 && place_name.includes(postal[0].long_name)) {
      place_name = place_name?.replaceAll(postal[0].long_name, "");
    }

    let data = {
      name,
      place_name,
      county,
      state,
      state_short,
      // borough,
      components: {
        text: name,
        bbox,
        center,
        types: res.results[0].types,
      },
      created: new Date(),
    };

    // if (area.split(", ")[0] !== name) {
    //   console.log({ area, name, county });
    // }

    // console.log(area, name);

    let result = await addDoc(collection(firestore, "locations"), data);
    console.log(result);

    //areas
  } catch (error) {
    console.log(error, area);
  }
}

async function pushSubArea(item, borough) {
  try {
    let { subareas, areas, county: coun } = item;

    let q = query(collection(firestore, "locations"), where("name", "==", areas));

    let querySnapshot = await getDocs(q);

    let parent = querySnapshot.docs[0].data();
    let parentId = querySnapshot.docs[0].id;
    let parentBbox = parent.components.bbox;

    let add = `${subareas}, ${coun}`;

    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let resData = await fetch(url);
    let res = await resData.json();

    let comp = res.results[0].address_components;

    let name = comp.filter(
      (item) =>
        item.types.includes("neighborhood") ||
        item.types.includes("colloquial_area") ||
        item.types.includes("establishment") ||
        item.types.includes("sublocality") ||
        item.types.includes("locality") ||
        item.types.includes("route") ||
        item.types.includes("administrative_area_level_3")
    )[0].long_name;

    let county = comp.filter((item) => item.types.includes("administrative_area_level_2"))[0].long_name;
    let state = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].long_name;
    let state_short = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].short_name;
    let bbox = {
      south: res.results[0].geometry.viewport.southwest.lat,
      west: res.results[0].geometry.viewport.southwest.lng,
      north: res.results[0].geometry.viewport.northeast.lat,
      east: res.results[0].geometry.viewport.northeast.lng,
    };
    let center = res.results[0].geometry.location;
    let place_name = res.results[0].formatted_address?.replaceAll(", USA", "");
    let postal = comp.filter((item) => item.types.includes("postal_code"));

    if (postal.length > 0 && place_name.includes(postal[0].long_name)) {
      place_name = place_name?.replaceAll(postal[0].long_name, "");
    }

    let data = {
      name,
      place_name,
      county,
      state,
      state_short,
      borough,
      components: {
        text: name,
        bbox,
        center,
        types: res.results[0].types,
      },
      created: new Date(),
    };

    let updatedParentBbox = isBoundingBoxInside(bbox, parentBbox) ? "" : updateParentBoundingBox(bbox, parentBbox);

    if (!isBoundingBoxInside(bbox, parentBbox)) {
      const docRef = doc(firestore, "locations", parentId);
      let updateres = await updateDoc(docRef, {
        components: {
          ...parent.components,
          bbox: updatedParentBbox,
        },
      });
      console.log(updateres, "updated", name, parentId);
    }

    let result = await addDoc(collection(firestore, "locations"), data);
    console.log(result, data);

    // if (subareas !== name) {
    //   console.log(subareas, name, data);
    // }

    // console.log(subareas, "====", name, place_name, isBoundingBoxInside(bbox, parentBbox), updatedParentBbox);

    // area
  } catch (error) {
    console.log(error, item);
  }
}

async function pushLowerSubArea(item, borough) {
  try {
    let { subareas, lower_subareas, county } = item;

    let q = query(collection(firestore, "locations"), where("name", "==", subareas));

    let querySnapshot = await getDocs(q);

    let parent = querySnapshot.docs[0].data();
    let parentId = querySnapshot.docs[0].id;
    let parentBbox = parent.components.bbox;

    let add = `${lower_subareas}, ${county}, NY`;

    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let resData = await fetch(url);
    let res = await resData.json();

    // console.log(res);

    let comp = res.results[0].address_components;

    let name = comp.filter(
      (item) =>
        item.types.includes("neighborhood") ||
        item.types.includes("colloquial_area") ||
        item.types.includes("establishment") ||
        item.types.includes("sublocality") ||
        item.types.includes("locality") ||
        item.types.includes("route")
    )[0].long_name;

    let coun = comp.filter((item) => item.types.includes("administrative_area_level_2"))[0].long_name;
    let state = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].long_name;
    let state_short = comp.filter((item) => item.types.includes("administrative_area_level_1"))[0].short_name;
    let bbox = {
      south: res.results[0].geometry.viewport.southwest.lat,
      west: res.results[0].geometry.viewport.southwest.lng,
      north: res.results[0].geometry.viewport.northeast.lat,
      east: res.results[0].geometry.viewport.northeast.lng,
    };
    let center = [res.results[0].geometry.location.lng, res.results[0].geometry.location.lat];
    let place_name = res.results[0].formatted_address?.replaceAll(", USA", "");
    let postal = comp.filter((item) => item.types.includes("postal_code"));

    if (postal.length > 0 && place_name.includes(postal[0].long_name)) {
      place_name = place_name?.replaceAll(postal[0].long_name, "");
    }

    let data = {
      name,
      place_name,
      county: coun,
      state,
      state_short,
      borough,
      components: {
        text: name,
        bbox,
        center,
        types: res.results[0].types,
      },
      created: new Date(),
    };

    let updatedParentBbox = isBoundingBoxInside(bbox, parentBbox) ? "" : updateParentBoundingBox(bbox, parentBbox);

    if (!isBoundingBoxInside(bbox, parentBbox)) {
      let pbox = updatedParentBbox;
      const docRef = doc(firestore, "locations", parentId);
      let updateres = await updateDoc(docRef, {
        components: {
          ...parent.components,
          bbox: pbox,
        },
      });
      console.log(updateres, "updated", name, parentId);
    }

    console.log(name, " ===== ", place_name, isBoundingBoxInside(bbox, parentBbox), updatedParentBbox);

    let result = await addDoc(collection(firestore, "locations"), data);
    console.log(result);
  } catch (error) {
    console.log(error, item.subareas);
  }
}

async function areasLayer(item) {}

const TestRoute = () => {
  async function handleClick() {
    // const data = tsvJSON(addnew);
    // let county = new Set();
    // let areas = new Set();
    // let subareas_set = new Set();
    // let subareas = [];
    // let lower_subareas_set = new Set();
    // let lower_subareas = [];

    // data.forEach((item, i) => {
    //   if (item.county) county.add(item.county);
    //   if (item.areas) areas.add(item.areas);
    //   if (item.subareas) subareas_set.add(item.subareas);
    //   if (item.lower_subareas) lower_subareas_set.add(item.lower_subareas);
    // });

    // subareas_set.forEach((item) => {
    //   const sub = data.find((sub) => sub.subareas === item);
    //   if (sub) subareas.push(sub);
    // });

    // lower_subareas_set.forEach((item) => {
    //   const lower_sub = data.find((lsub) => lsub.lower_subareas === item);
    //   if (lower_sub) lower_subareas.push(lower_sub);
    // });

    // console.log({ county, areas, subareas, subareas_set, lower_subareas, lower_subareas_set });

    // county.forEach((item) => {
    //   pushCounty(item);
    // });

    // areas.forEach((item) => {
    //   pushArea(`${item}, New Jersey`);
    // });

    // subareas.forEach((item) => {
    //   pushSubArea(item, "Manhattan");
    // });

    // lower_subareas.forEach((item) => {
    //   pushLowerSubArea(item, "Suffolk County");
    // });

    // console.log(JSON.stringify(data, null, 2));

    const docRef = doc(firestore, "locations", "UKrMtlKWW6uGHf43umsx");
    const docSnap = await getDoc(docRef);

    console.log(JSON.stringify(docSnap.data(), null, 2));

    // areas.forEach((item) => {
    //   data.push({
    //     id: item.id,
    //     ...item.data(),
    //   });
    // });

    // console.log(JSON.stringify(data, null, 2));

    // let data = await searchObjects(
    //   "",
    //   "address.components:Manhattan AND type:sale AND status:Active",
    //   "newest",
    //   [],
    //   null,
    //   null
    // );

    // console.log(data);

    // let data = await getComponents("27 E 65th St apt 12e, New York, NY 10065");

    // console.log(data);
  }
  return (
    <div style={{ height: "100vh", width: "100vw", backgroundColor: "black" }}>
      {" "}
      <button onClick={handleClick}>Test</button>
    </div>
  );
};

export default TestRoute;
