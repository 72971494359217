export const monthlyPaymentAmount = ({
  salePrice,  // 1250000
  downPayment, // 250000
  interestRate, // 0.1
  termYears,  // 10
  extra = 0, // 2129
}) => {

  // console.log("Calculating => ", salePrice, downPayment, interestRate, termYears, extra)
  const mortgageAmount = salePrice - downPayment
  const loanAmount = parseFloat(salePrice) - (downPayment ? parseFloat(downPayment) : 0);
  console.log("extra = ", extra)
  const r = parseFloat(interestRate) / 100 / 12;
  const n = parseFloat(termYears) * 12;
  const numerator = loanAmount * r * Math.pow(1 + r, n);
  const denominator = Math.pow(1 + r, n) - 1;
  const monthlyPayment = (numerator / denominator).toFixed(2);
  console.log("monthlyPayment = ", monthlyPayment)

  const estMonthlyPayment = Number(monthlyPayment) + extra

  const res = {
    mortgageAmount:mortgageAmount.toFixed(0),
    mortgagePayment:Number(monthlyPayment).toFixed(2),
    estMonthlyPayment:estMonthlyPayment
  }

  return res

  /* const totalPayable = (monthlyPayment * n).toFixed(2);
  //console.log("totalPayable = ", totalPayable)
  const totalInterest = (totalPayable - loanAmount).toFixed(2);
  console.log("totalInterest = ", totalInterest) */

 /*  console.log("mortgageAmount = ", mortgageAmount, "pow cal => ", Math.pow(interestRate + 1, termYears * 12))

  const mortgagePayment = mortgageAmount * 
    (interestRate * Math.pow(interestRate + 1, termYears * 12)) / (Math.pow(interestRate + 1, termYears * 12) - 1)
   console.log("mortgage payment => ", mortgagePayment)
   
  const estMonthlyPayment = mortgagePayment + extra*/

  
}

export const defaultInterestRateForTermYears = (termYears) => {
  if (termYears <= 15) {
    return 0.06875
  } else {
    return 0.07625
  }
}

export const calculateNetEffectiveRents = ({
  grossRent,
  leaseTerm,
  monthsFree,
}) => {
  return (Number(grossRent) * (Number(leaseTerm) - Number(monthsFree)) / Number(leaseTerm)).toFixed(0)
}

export const calculateDaysOnMarket = (activatedTimesArray) => {
  if (!activatedTimesArray || activatedTimesArray.length === 0) {
    return 0  // data not available
  } else {
    const sorted = activatedTimesArray.sort((a, b) => a - b)
    let totalTimeInHrs = 0
    for (let idx = 0; idx < sorted.length; idx += 2) {
      if (idx + 1 < sorted.length) {
        totalTimeInHrs += (sorted[idx + 1] - sorted[idx]) / 1000 / 3600
      }
    }

    if (sorted.length % 2 === 1) {
      totalTimeInHrs += (new Date().getTime() - sorted[sorted.length - 1]) / 1000 / 3600
    }

    return Math.floor(totalTimeInHrs / 24) + 1
  }
}