export const splitArrayIntoChunksArray = (array, chunkSize) => {
  return array.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/chunkSize)
  
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
  
    resultArray[chunkIndex].push(item)
  
    return resultArray
  }, [])
  
}

export const compareIfTwoStringArraySame = (stringArray1, stringArray2) => {
  if ((stringArray1?.length ?? 0) !== (stringArray2?.length ?? 0)) {
    return false
  }

  stringArray1?.forEach(item => {
    if (!stringArray2?.includes(item)) {
      return false
    }
  })

  return true
}