import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import TextInput from "../input/TextInput"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";  
import { firestore, storage } from "src/firebase"
import PhotoThumbInListing from "../photo/PhotoThumbInListing"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import shortid from "shortid"
import { setDoc, doc, collection, getDocs, deleteDoc } from "firebase/firestore"
import debounce from "lodash.debounce"
import useMediaQuery from "src/hooks/useMediaQuery"
import FileUploader from "../fileUploader/FileUploader"
import UploadingPhotoThumb from "../photo/UploadingPhotoThumb"
import { Draggable } from 'react-drag-reorder'
import './draggable-customize.css'

const EditListingPhotosMedia = ({
  highlightErrorFields,
  listing,
  onUpdateRootFields,
  onForceSave
}) => {

  const fileTypes = ["JPEG", "PNG", "JPG"];

  const { id } = useParams()
  const { isMobile } = useMediaQuery()

  const [uploadingFloorFiles, setUploadingFloorFiles] = useState([])
  const [uploadedFloorFiles, setUploadedFloorFiles] = useState([])
  const uploadingFloorFilesRef = useRef()
  const uploadedFloorFilesRef = useRef()
  const [floorFilesCurrentlyUploading, setFloorFilesCurrentlyUploading] = useState(false)
  const [floorFilesCurrentlyUpdating, setFloorFilesCurrentlyUpdating] = useState(false)

  const [uploadingPhotoFiles, setUploadingPhotoFiles] = useState([])
  const [uploadedPhotos, setUploadedPhotos] = useState([])
  const uploadingPhotoFilesRef = useRef()
  const uploadedPhotoFilesRef = useRef()
  const [photosCurrentlyUploading, setPhotosCurrentlyUploading] = useState(false)
  const [photosCurrentlyUpdating, setPhotosCurrentlyUpdating] = useState(false)

  const handleUploadPhotoFiles = (files) => {
    let filesArray = []
    for (let i = 0; i < files.length; i++) {
      const shortId = shortid()
      const fileData = {
        file: files[i],
        fileId: shortId,
        progress: 0,
      }
      filesArray.push(fileData)
    }
    setUploadingPhotoFiles(filesArray)

    uploadingPhotoFilesRef.current = filesArray
    uploadedPhotoFilesRef.current = uploadedPhotos
    setPhotosCurrentlyUploading(true)

    const uploadedPhotoCount = uploadedPhotos.length;
    filesArray.forEach((file, index) => {
      const fileId = file.fileId
      const storageRef = ref(storage,`/photos/${id}/photo/${fileId}/`)
      const uploadTask = uploadBytesResumable(storageRef, file.file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          const idx = uploadingPhotoFilesRef.current.findIndex(file => file.fileId === fileId)
          uploadingPhotoFilesRef.current[idx] = {
            ...uploadingPhotoFilesRef.current[idx],
            progress: percent
          }
          setUploadingPhotoFiles(uploadingPhotoFilesRef.current)
        },
        err => {
          console.log("uploading file error => ", err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(url => {
            const photoData = {
              id: fileId,
              url,
              index: uploadedPhotoCount + index,
            }
            setDoc(doc(firestore, `listing/${id}/photos/${fileId}`), photoData)
            uploadingPhotoFilesRef.current = uploadingPhotoFilesRef.current.filter(file => file.fileId !== fileId)
            setUploadingPhotoFiles(uploadingPhotoFilesRef.current)
            uploadedPhotoFilesRef.current = [...uploadedPhotoFilesRef.current, photoData]
            setUploadedPhotos(uploadedPhotoFilesRef.current)
            if (uploadingPhotoFilesRef.current.length === 0) {
              setPhotosCurrentlyUploading(false)
              onForceSave(true)
            }
          })
        }
      )
    })
  }

  const handleUploadFloorPlanFiles = (files) => {
    let filesArray = []
    for (let i = 0; i < files.length; i++) {
      const shortId = shortid()
      const fileData = {
        file: files[i],
        fileId: shortId,
        progress: 0,
      }
      filesArray.push(fileData)
    }
    
    setUploadingFloorFiles(filesArray)
    
    uploadingFloorFilesRef.current = filesArray
    uploadedFloorFilesRef.current = uploadedFloorFiles
    setFloorFilesCurrentlyUploading(true)

    const uploadedFloorFilesCount = uploadedFloorFiles.length;
    filesArray.forEach((file, index) => {
      const fileId = file.fileId
      const storageRef = ref(storage,`/photos/${id}/floor/${fileId}/`)
      const uploadTask = uploadBytesResumable(storageRef, file.file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          const idx = uploadingFloorFilesRef.current.findIndex(file => file.fileId === fileId)
          uploadingFloorFilesRef.current[idx] = {
            ...uploadingFloorFilesRef.current[idx],
            progress: percent
          }
          setUploadingFloorFiles(uploadingFloorFilesRef.current)
        },
        err => {
          console.log("uploading file error => ", err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(url => {
            const photoData = {
              id: fileId,
              url,
              index: uploadedFloorFilesCount + index,
            }
            setDoc(doc(firestore, `listing/${id}/floor/${fileId}`), photoData)
            uploadingFloorFilesRef.current = uploadingFloorFilesRef.current.filter(file => file.fileId !== fileId)
            setUploadingFloorFiles(uploadingFloorFilesRef.current)
            uploadedFloorFilesRef.current = [...uploadedFloorFilesRef.current, photoData]
            setUploadedFloorFiles(uploadedFloorFilesRef.current)
            if (uploadingFloorFilesRef.current.length === 0) {
              setFloorFilesCurrentlyUploading(false)
              onForceSave(true)
            }
          })
        }
      )
    })
  }

  const changeVideoLink = (event) => {
    const changedValue = event.target.value
    onUpdateRootFields({
      video: changedValue
    })
  }

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeVideoLink, 500);
  }, []);

  const handleDeletePhoto = (photoId) => {
    const newArr = [...uploadedPhotos.filter(photo => photo.id !== photoId)]
    deleteDoc(doc(firestore, `listing/${id}/photos/${photoId}`))
    savePhotoIndexes(newArr)
  }

  const handleDeleteFloorPlan = (photoId) => {
    const newArr = [...uploadedFloorFiles.filter(floorPlan => floorPlan.id !== photoId)]
    deleteDoc(doc(firestore, `listing/${id}/floor/${photoId}`))
    saveFloorPlanIndexes(newArr)
  }

  const loadPhotosForListing = () => {
    getDocs(collection(firestore, `listing/${id}/photos`)).then(snapshot => {
      let photosData = []
      snapshot.forEach(doc => {
        photosData.push(doc.data())
      })
      photosData.sort((a, b) => a.index - b.index)
      setUploadedPhotos(photosData)
    })

    getDocs(collection(firestore, `listing/${id}/floor`)).then(snapshot => {
      let photosData = []
      snapshot.forEach(doc => {
        photosData.push(doc.data())
      })
      photosData.sort((a, b) => a.index - b.index)
      setUploadedFloorFiles(photosData)
    })
  }

  useEffect(() => {
    loadPhotosForListing()
  }, [id])

  useEffect(() => {
    if (listing) {
      document.getElementById('video-url-input').value = listing.video ?? ""
    }
  }, [listing])

  const handlePhotoRearrange = (currentPos, newPos) => {
    if (newPos >= uploadedPhotos.length) {
      var k = newPos - uploadedPhotos.length + 1;
      while (k--) {
        uploadedPhotos.push(undefined);
      }
    }
    uploadedPhotos.splice(newPos, 0, uploadedPhotos.splice(currentPos, 1)[0]);
    savePhotoIndexes(uploadedPhotos)
  }

  const savePhotoIndexes = (newArr) => {
    const promisses = []
    setPhotosCurrentlyUpdating(true)
    newArr = newArr.map((photo, index) => {
      if (photo.index !== index)
        promisses.push(setDoc(doc(firestore, `listing/${id}/photos/${photo.id}`), { index }, { merge: true }))
      return { ...photo, index }
    })
    newArr.sort((a, b) => a.index - b.index)
    setUploadedPhotos(newArr)
    Promise.all(promisses).then(() => setPhotosCurrentlyUpdating(false))
  }

  const handleFloorPlanRearrange = (currentPos, newPos) => {
    if (newPos >= uploadedFloorFiles.length) {
      var k = newPos - uploadedFloorFiles.length + 1;
      while (k--) {
        uploadedFloorFiles.push(undefined);
      }
    }
    uploadedFloorFiles.splice(newPos, 0, uploadedFloorFiles.splice(currentPos, 1)[0]);
    saveFloorPlanIndexes(uploadedFloorFiles)
  }

  const saveFloorPlanIndexes = (newArr) => {
    const promisses = []
    setFloorFilesCurrentlyUpdating(true)
    newArr = newArr.map((photo, index) => {
      if (photo.index !== index)
        promisses.push(setDoc(doc(firestore, `listing/${id}/floor/${photo.id}`), { index }, { merge: true }))
      return { ...photo, index }
    })
    newArr.sort((a, b) => a.index - b.index)
    setUploadedFloorFiles(newArr)
    Promise.all(promisses).then(() => setFloorFilesCurrentlyUpdating(false))
  }

  return (
    <Flexbox style={styles.container}>
      <Flexbox row style={{...styles.sectionPanel, width: isMobile ? '100%' : 800 }}>
        <TypeFace medium size={20} color={highlightErrorFields && (uploadedPhotos?.length ?? 0) === 0 ? 'red' : 'black'}>
        Photos & Floor Plans<TypeFace>*</TypeFace>
        </TypeFace>
      </Flexbox>
      <div style={styles.separator} />
      <div style={{ alignSelf: 'center', marginTop: 20 }}>
        <FileUploader
          multiple={true}
          currenlyUploading={photosCurrentlyUploading}
          label="Upload or drop photo files here"
          handleChange={handleUploadPhotoFiles}
          name="file"
          disabled={photosCurrentlyUploading}
          types={fileTypes}
        />
      </div>
      <Flexbox row style={styles.photosBox}>
        {photosCurrentlyUploading || photosCurrentlyUpdating ? (
          <Fragment>
            {uploadingPhotoFiles?.map((file, index) => (
              <UploadingPhotoThumb
                key={`uploading-photo-${index}`}
                file={file.file}
                percent={file.progress}
              />
            ))}
            {uploadedPhotos?.map((photo, index) => (
              <PhotoThumbInListing
                uri={photo.url}
                key={`photo-${index}-${photo.id}`}
                index={index + 1}
                photoId={photo.id}
                onDeletePhoto={handleDeletePhoto}
              />
            ))}
          </Fragment>
        ) : (
          uploadedPhotos.length > 0 ? (
            <Draggable onPosChange={handlePhotoRearrange}>
              {uploadedPhotos?.map((photo, index) => (
                <PhotoThumbInListing
                  uri={photo.url}
                  key={`photo-${index}-${photo.id}`}
                  index={index + 1}
                  photoId={photo.id}
                  onDeletePhoto={handleDeletePhoto}
                />
              ))}
            </Draggable>
          ) : (
            <></>
          )
        )}
      </Flexbox>
      {/*  <Flexbox row style={{...styles.sectionPanel, width: isMobile ? '100%' : 800 }}>
        <TypeFace medium size={20}>
          Floor Plans
        </TypeFace>
      </Flexbox>
      <div style={styles.separator} />
      <div style={{ alignSelf: 'center', marginTop: 20 }}>
        <FileUploader
          multiple={true}
          currenlyUploading={floorFilesCurrentlyUploading}
          label="Upload or drop floor plan files here"
          handleChange={handleUploadFloorPlanFiles}
          name="file"
          disabled={floorFilesCurrentlyUploading}
          types={fileTypes}
        />
      </div> 
      <Flexbox row style={styles.photosBox}>
      {floorFilesCurrentlyUploading || floorFilesCurrentlyUpdating ? (
          <Fragment>
            {uploadingFloorFiles?.map((file, index) => (
              <UploadingPhotoThumb
                key={`uploading-floor-${index}`}
                file={file.file}
                percent={file.progress}
              />
            ))}
            {uploadedFloorFiles?.map((photo, index) => (
              <PhotoThumbInListing
                uri={photo.url}
                key={`uploaded-floor-${index}-${photo.id}`}
                index={index + 1}
                photoId={photo.id}
                onDeletePhoto={handleDeleteFloorPlan}
              />
            ))}
          </Fragment>
        ) : (
          uploadedFloorFiles.length > 0 ? (
            <Draggable onPosChange={handleFloorPlanRearrange}>
              {uploadedFloorFiles?.map((photo, index) => (
                <PhotoThumbInListing
                  uri={photo.url}
                  key={`uploaded-floor-${index}-${photo.id}`}
                  index={index + 1}
                  photoId={photo.id}
                  onDeletePhoto={handleDeleteFloorPlan}
                />
              ))}
            </Draggable>
          ) : (
            <></>
          )
        )}
      </Flexbox> */}
      <Flexbox row style={{...styles.sectionPanel, width: isMobile ? '100%' : 800 }}>
        <TypeFace medium size={20}>
          Video
        </TypeFace>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace bold size={12} style={{ marginTop: 40 }}>
        Youtube Video URL
      </TypeFace>
      <TextInput id={'video-url-input'} style={styles.input} onChange={debouncedChangeHandler} />
      <TypeFace medium size={10} style={{ marginBottom: 80 }}>
        {/* Please enter a URL from Youtube or Vimeo */}
      </TypeFace>
    </Flexbox>
  )
}

export default EditListingPhotosMedia

const styles = {
  container: {
    padding: "0 0",
    width: '100%',
    alignItems: 'flex-start'
  },
  sectionPanel: {
    width: 800,
    marginTop: 80,
    height: 50,
  },
  uploadButton: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    cursor: 'pointer',
    height: 50,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#dfdfdf'
  },
  input: {
    width: 300,
    height: 40,
    marginTop: 5,
    borderRadius: 0,
  },
  photosBox: {
    width: '100%',
    flexWrap: 'wrap',
  }
}