export const buildingTypeOptions = [
  { value: "single-family", label: "Single Family" },
  { value: "multi-family", label: "Multi Family" },
  { value: "townHouse", label: "Townhouse" },
  { value: "condo", label: "Condo" },
  { value: "coop", label: "Co-op" },
  /* {value: 'condop', label: 'Condop'}, */
  { value: "rental", label: "Rental" },
];

export const rentalMarketAsTypeOptions = [
  { value: "condo", label: "Condo" },
  { value: "coop", label: "Co-op" },
  { value: "singleFamily", label: "Single Family" },
  { value: "multiFamily", label: "Multi-family" },
  { value: "rentalUnit", label: "Rental Unit" },
  { value: "townHouse", label: "Townhouse" },
];

export const salesMarketAsTypeOptions = [
  //{value: 'auction', label: 'Auction'},
  //{value: 'building', label: 'Building'},
  { value: "condo", label: "Condo" },
  /*  {value: 'condop', label: 'Condop'}, */
  { value: "coop", label: "Co-op" },
  //{value: 'timeshare', label: 'Timeshare'},
  { value: "singleFamily", label: "Single Family" },
  { value: "multiFamily", label: "Multi-family" },
  { value: "rentalUnit", label: "Rental Unit" },
  { value: "townHouse", label: "Townhouse" },
  //{value: 'other', label: 'Other Type'},
];

export const salesTypeOptions = [
  { value: "traditionalSale", label: "Traditional Sale" },
  { value: "sponsorUnit", label: "Sponsor Unit" },
  { value: "foreclosure", label: "Foreclosure" },
  { value: "auction_sale", label: "Auction Sale" },
  { value: "restricted_sale", label: "Restricted Sale" },
  { value: "short_sale", label: "Short Sale" },
];

export const unitAmenitiesSearchOptions = [
  { label: "Balcony", value: "balcony" },
  //{label: 'Garden', value: 'garden'},
  { label: "Private Roof Deck", value: "private_roof_deck" },
  //{label: 'Roof Rights', value: 'roof_rights'},
  { label: "Terrace", value: "terrace" },
  //{label: 'Private Backyard', value: 'private_backyard'},
  { label: "Central Air", value: "central_air" },
  { label: "Dishwasher", value: "dishwasher" },
  { label: "Hardwood Floors", value: "hardwood_floors" },
  { label: "Washer/Dryer in Unit", value: "washer" },
  { label: "Fireplace", value: "fireplace" },
  { label: "City View", value: "city" },
  { label: "Garden View", value: "garden-view" },
  { label: "Park View", value: "park" },
  { label: "Skyline View", value: "skyline" },
  { label: "Water View", value: "water" },
];

export const additionalUnitAmenitiesForHouseMode = [
  { value: "full-base", label: "Full basement" },
  { value: "partial-base", label: "Partial basement" },
  { value: "crawl-base", label: "Crawl space basement" },
  { value: "finished-base", label: "Finished basement" },
  { value: "unfinished-base", label: "Unfinished basement" },
  { value: "partially-finished-base", label: "Partially finished basement" },
  { value: "full-attic", label: "Full attics" },
  { value: "partial-attic", label: "Partial attics" },
  { value: "finished-attic", label: "Finished attics" },
  { value: "unfinished-attic", label: "Unfinished attics" },
  { value: "partially-finished-attic", label: "Partially finished attics" },
  { value: "central", label: "A/C - Central" },
  { value: "window_units", label: "A/C - Window units" },
  { value: "electric-heating", label: "Electric heating" },
  { value: "baseboard", label: "Baseboard heating" },
  { value: "radiator", label: "Radiator heating" },
];

export const buildingAmenitiesSearchOptions = [
  { label: "Courtyard", value: "curtyard" },
  { label: "Roof Deck", value: "roof" },
  { label: "Private Backyard", value: "private_backyard" },
  { label: "Garden", value: "garden" },
  { label: "Patio", value: "patio" },
  { label: "Fenced Yard", value: "fenced_yard" },
  { label: "Gated Community", value: "gated_community" },
  { label: "Concierge", value: "concierge" },
  { label: "Elevator", value: "elevator" },
  { label: "Laundry in Building", value: "laundry" },
  { label: "Live in super", value: "live-in-super" },
  { label: "Smoke-free", value: "smoke-free" },
  { label: "Wheelchair Access", value: "wheelchair-access" },
  { label: "Doorman", value: "doorman" },
  { label: "Garage Parking", value: "garage" },
  { label: "Valet Parking", value: "valet" },
  { label: "Private Driveway", value: "private" },
  { label: "Bike Room", value: "bike" },
  { label: "Cold Storage", value: "cold" },
  { label: "Locker/Cage", value: "locker" },
  { label: "Package Room", value: "package" },
  { label: "Children's Playroom", value: "children" },
  { label: "Gym", value: "gym" },
  { label: "Package Room", value: "media" },
  { label: "Recreation", value: "recreation" },
  { label: "Close to public transportation", value: "transportation" },
  { label: "Close to school", value: "school" },
  { label: "Close to shops", value: "shops" },
  { label: "Close to parks", value: "parks" },
];

export const bathRoomsSearchOptions = [
  { label: "Any", value: "any" },
  { label: "1 or More", value: "1" },
  { label: "1.5 or More", value: "1.5" },
  { label: "2 or More", value: "2" },
  { label: "2.5 or More", value: "2.5" },
  { label: "3 or More", value: "3" },
  { label: "3.5 or More", value: "3.5" },
  { label: "4 or More", value: "4" },
];

export const taxSearchOptions = [
  { label: "Any", value: "any" },
  { label: "Up to $500", value: 500 },
  { label: "Up to $750", value: 750 },
  { label: "Up to $1000", value: 1000 },
  { label: "Up to $1500", value: 1500 },
  { label: "Up to $2000", value: 2000 },
  { label: "Up to $2500", value: 2500 },
  { label: "Up to $3000", value: 3000 },
  { label: "Up to $4000", value: 4000 },
  { label: "Up to $5000", value: 5000 },
  { label: "Up to $6000", value: 6000 },
  { label: "Up to $7000", value: 7000 },
  { label: "Up to $8000", value: 8000 },
  { label: "Up to $9000", value: 9000 },
  { label: "Up to $10000", value: 10000 },
  { label: "Up to $15000", value: 15000 },
];

export const configureYearsSearchOptions = (withAny = true) => {
  let options = [];
  if (withAny) {
    options.push({ label: "Any", value: "any" });
  }
  for (let i = 2024; i >= 1900; i--) {
    options = [...options, { label: `${i}`, value: i }];
  }

  return options;
};

export const yearsSearchOptions = configureYearsSearchOptions();

export const ListingStatusValues = {
  Draft: "Draft",
  Active: "Active",
  Sold: "Sold",
};

export const buildingTypeLabelFromOption = (option) => {
  return buildingTypeOptions.find((val) => val.value === option)?.label ?? "";
};

export const BuildingFeatures = [
  { key: "concierge", value: "Concierge" },
  { key: "elevator", value: "Elevator" },
  { key: "laundry", value: "Laundry in Building" },
  { key: "live-in-super", value: "Live in Super" },
  { key: "smoke-free", value: "Smoke Free" },
  { key: "wheelchair-access", value: "Wheelchair Access" },
];

export const BuildingLocations = [
  { key: "transportation", value: "Close to public transportation" },
  { key: "school", value: "Close to school" },
  { key: "shops", value: "Close to shops" },
  { key: "parks", value: "Close to parks" },
];

export const UnitOutspaces = [
  { key: "balcony", value: "Balcony" },
  //{key: 'garden', value: 'Garden'},
  { key: "private_roof_deck", value: "Private Roof Deck" },
  //{key: 'roof_rights', value: 'Roof Rights'},
  { key: "terrace", value: "Terrace" },
  //{key: 'private_backyard', value: 'Private Backyard'},
];

export const UnitFeatures = [
  { key: "central_air", value: "Central Air" },
  { key: "dishwasher", value: "Dishwasher" },
  { key: "hardwood_floors", value: "Hardwood Floors" },
  { key: "washer", value: "Washer/Dryer in Unit" },
];

export const UnitFireplaces = [
  { key: "decorative", value: "Decorative" },
  { key: "electric", value: "Electric" },
  { key: "gas", value: "Gas" },
  { key: "wood", value: "Wood" },
];

export const UnitViews = [
  { key: "city", value: "City" },
  { key: "garden-view", value: "Garden" },
  { key: "park", value: "Park" },
  { key: "skyline", value: "Skyline" },
  { key: "water", value: "Water" },
];

export const UnitACTypes = [
  { key: "central", label: "Central" },
  { key: "window_units", label: "Window units" },
  { key: "others-ac", label: "Others" },
];

export const UnitHeatingTypes = [
  { key: "electric-heating", label: "Electric" },
  { key: "baseboard", label: "Baseboard" },
  { key: "radiator", label: "Radiator" },
  { key: "other-heating", label: "Other" },
];

export const UnitBasements = [
  { key: "full-base", label: "Full" },
  { key: "partial-base", label: "Partial" },
  { key: "crawl-base", label: "Crawl Space" },
  { key: "finished-base", label: "Finished" },
  { key: "unfinished-base", label: "Unfinished" },
  { key: "partially-finished-base", label: "Partially Finished" },
  { key: "base-none", label: "None" },
];

export const UnitAttics = [
  { key: "full-attic", label: "Full" },
  { key: "partial-attic", label: "Partial" },
  { key: "finished-attic", label: "Finished" },
  { key: "unfinished-attic", label: "Unfinished" },
  { key: "partially-finished-attic", label: "Partially Finished" },
  { key: "attic-none", label: "None" },
];

export const UnitHeatingFuels = [
  { key: "natural_gas", label: "Natural gas" },
  { key: "electric-fuel", label: "Electric" },
  { key: "oil", label: "Oil" },
  { key: "other-fuel", label: "Other" },
];

export const propertyStatusOptions = [
  { value: "Draft", label: "Draft" },
  { value: "Active", label: "Active" },
  { value: "Contract", label: "Under contract" },
  { value: "OffMarket", label: "Temporarily off market" },
  { value: "Delisted", label: "Delisted" },
  { value: "Sold", label: "Sold" },
];

export const propertyStatusOptionsRented = [
  { value: "Draft", label: "Draft" },
  { value: "Active", label: "Active" },
  { value: "Contract", label: "Under contract" },
  { value: "OffMarket", label: "Temporarily off market" },
  { value: "Delisted", label: "Delisted" },
  { value: "Rented", label: "Rented" },
];

export const propertyStatusLabelFromOption = (optionValue) => {
  return propertyStatusOptions.find((val) => val.value === optionValue)?.label ?? "";
};

export const propertyStatusLabelFromOptionRented = (optionValue) => {
  return propertyStatusOptionsRented.find((val) => val.value === optionValue)?.label ?? "";
};

export const UserRoleValue = {
  Customer: "customer",
  ManageMent: "management",
  Broker: "broker",
  Agent: "agent",
  SalesPerson: "salesperson",
};

export const managementRoles = ["broker", "salesperson", "agent", "management"];

export const userRoles = [
  { label: "Buyer / Renter", value: UserRoleValue.Customer },
  { label: "Broker", value: UserRoleValue.Broker },
  { label: "Licensed Real Estate Salesperson", value: UserRoleValue.SalesPerson },
  { label: "Associate Broker", value: UserRoleValue.Agent },
  { label: "Management Company", value: UserRoleValue.ManageMent },
];

export const roleLabelFromValue = (value) => {
  return userRoles.find((role) => role.value === value)?.label ?? value;
};
