import React, { useEffect, useMemo, useRef, useState } from "react";
import { searchObjects, searchObjectsAdmin, updateAlogliaObject } from "src/algolia";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Flex, Select, Table, Typography, Input, Button, notification } from "antd";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { priceFormat } from "src/utils/strings";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "src/firebase";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;

function SelectCell({ record }) {
  const [status, setStatus] = useState("");
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setStatus(record.status);
  }, [record.status]);
  function handleChange(value) {
    try {
      setStatus(value);
      updateDoc(doc(firestore, `listing/${record.dataId}`), {
        status: value,
      });
      updateAlogliaObject(record.objectID, {
        status: value,
      });
      api.success({
        message: "Success",
        description: "Status updated successfully!",
      });
    } catch (err) {
      api.error({
        message: "Error",
        description: "Oops!! Something went wrong!" + err,
      });
    }
  }
  return (
    <>
      {contextHolder}
      <Select
        style={{ width: 120 }}
        value={status}
        options={[
          { value: "Active", label: "Active" },
          { value: "Contract", label: "Contract" },
          { value: "OffMarket", label: "OffMarket" },
          { value: "Delisted", label: "Delisted" },
          { value: "Blocked", label: "Blocked" },
        ]}
        onChange={handleChange}
      />
    </>
  );
}

const AdminListingsAgents = () => {
  const [hits, setHits] = useState();
  const [query, setQuery] = useState("");
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { creatorId } = useParams();

  const navigate = useNavigate();

  function getData(filters, query = "") {
    searchObjectsAdmin(query, filters, "newest-dev", page, pageSize).then((res) => {
      setTotal(res.nbHits);
      setHits(res.hits);
    });
  }

  useEffect(() => {
    getData(`NOT status:'Draft' AND creatorId:'${creatorId}'`);
  }, [page, pageSize]);

  function handleChange({ type, status, query }) {
    let typeFilters = "";
    let statusFilters = "";
    let filter = "";

    if (type && type.length > 0) {
      for (let i = 0; i < type.length; i++) {
        typeFilters += i === type.length - 1 ? `type:${type[i]}` : `type:${type[i]} OR `;
      }
    }

    if (status && status.length > 0) {
      for (let i = 0; i < status.length; i++) {
        statusFilters += i === status.length - 1 ? `status:${status[i]}` : `status:${status[i]} OR `;
      }
    }

    if (typeFilters !== "") {
      if (statusFilters !== "") {
        filter = `(${typeFilters}) AND (${statusFilters})`;
      } else {
        filter = `(${typeFilters})`;
      }
    } else {
      if (statusFilters !== "") {
        filter = `(${statusFilters})`;
      }
    }

    getData(filter, query);
  }

  function handleReset() {
    setQuery("");
    (function () {
      setType([]);
      setStatus([]);
    })();
    getData("");
  }

  function handleTypeChange(value) {
    setType(value);
  }

  let statuses = [
    { value: "Active", label: "Active" },
    { value: "Contract", label: "Contract" },
    { value: "OffMarket", label: "OffMarket" },
    { value: "Delisted", label: "Delisted" },
    { value: "Blocked", label: "Blocked" },
  ];

  let columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => {
        let postal = record.address.zip;
        return (
          <Link to={`/listing/${record.dataId}`} style={{ textDecoration: "none" }}>
            {record.unit && `Unit ${record.unit},`}
            {record.address.full} {postal ? `, ${postal}` : ""}
          </Link>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_, record) => <Text style={{ textTransform: "capitalize" }}>{record.type}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => <Text style={{ textTransform: "capitalize" }}>{record.status}</Text>,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (_, record) => (
        <Text>{record.type === "sale" ? `$${priceFormat(record.price)}` : `$${priceFormat(record.grossRent)}`}</Text>
      ),
    },
    {
      title: "Agency",
      dataIndex: "agency",
      render: (_, record) => <Text>{record.agency}</Text>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return <SelectCell record={record} />;
      },
    },
  ];

  const headerRight = (
    <div>
      <Search
        placeholder="Search Area, City, State or Zip"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={(value) => {
          setQuery(value);
          handleChange({ type, status, query: value });
        }}
        style={{
          width: 300,
        }}
        allowClear
        size="large"
      />
    </div>
  );

  const header = (
    <Flex gap={10}>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/admin/agencies")}
        style={{ color: "black", fontWeight: "bold" }}
      />
      <span>Listings</span>
    </Flex>
  );

  return (
    <AdminLayout title={header} headerRight={headerRight}>
      <Flex row gap={10} align="center">
        <Text>Filters:</Text>
        <Select
          placeholder="Select Type"
          mode="multiple"
          style={{ width: 200 }}
          className="filter-select"
          options={[
            { label: "Rental", value: "rental" },
            { label: "Sale", value: "sale" },
          ]}
          onChange={handleTypeChange}
          value={type}
        />
        <Select
          placeholder="Select Status"
          mode="multiple"
          style={{ width: 250 }}
          className="filter-select"
          options={
            type.length > 0
              ? type.includes("rental")
                ? [...statuses, { label: "Rented", value: "Rented" }]
                : [...statuses, { label: "Sold", value: "Sold" }]
              : [...statuses, { label: "Sold", value: "Sold" }, { label: "Rented", value: "Rented" }]
          }
          onChange={(e) => setStatus(e)}
          value={status}
        />
        <Button type="primary" onClick={() => handleChange({ type, status, query })}>
          APPLY
        </Button>
        <Button onClick={handleReset}>RESET</Button>
      </Flex>
      <Table
        dataSource={hits}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
        rowKey={(_, index) => index}
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        onChange={(pagination) => {
          setPage(pagination.current - 1);
          setPageSize(pagination.pageSize);
        }}
      />
    </AdminLayout>
  );
};

export default AdminListingsAgents;

const styles = {
  headerRow: {
    width: "100%",
    height: 30,
    marginTop: 20,
    backgroundColor: "#dfdfdf",
    alignItems: "center",
  },
};
