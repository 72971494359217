import { auth } from "src/firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Divider, Flex, Typography } from "antd";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";

const { Text } = Typography;

const AdminSideMenu = (props) => {
  const navigate = useNavigate();
  const unmountedRef = useRef(false);
  const { userProfile } = useFirestore();

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const links = [
    {
      name: "Dashboard",
      link: "/admin/dashboard",
    },
    {
      type: "divider",
    },
    {
      name: "Admins",
      link: "/admin/list",
    },
    {
      name: "Customers",
      link: "/admin/customers",
    },
    {
      name: "Licensed Brokerages",
      link: "/admin/agencies",
    },
    {
      name: "Management Companies",
      link: "/admin/management-companies",
    },
    {
      name: "Sales/Rental Leads",
      link: "/admin/agency-requests",
    },
    {
      name: "Released Agents",
      link: "/admin/released-agents",
    },
    {
      type: "divider",
    },
    {
      name: "Listings",
      link: "/admin/listings",
    },

    {
      name: "Reviews",
      link: "/admin/reviews",
    },
    {
      name: "Reports",
      link: "/admin/reports",
    },
    {
      type: "divider",
    },
  ];

  const fullLinks =
    userProfile?.role === "admin"
      ? [...links, { name: "Payments", link: "/admin/payments" }, { type: "divider" }]
      : links;

  return (
    <Flex vertical style={{ width: 200, padding: 5, paddingLeft: 0 }}>
      {fullLinks.map((link, i) => {
        if (link.type === "divider") {
          return <Divider key={i} style={{ margin: "5px 0" }} />;
        }
        if (userProfile.role === "manager" && link.name === "Admins") {
          return null;
        }
        return (
          <NavLink key={i} className={"nav-link"} to={link.link}>
            {link.name}
          </NavLink>
        );
      })}
      <Flex align="center" justify="flex-start" className="nav-link logout" onClick={handleLogout}>
        <Text type="danger" style={{ cursor: "pointer" }}>
          Log out
        </Text>
      </Flex>
    </Flex>
  );
};

export default AdminSideMenu;
