import React from "react";
import { Colors } from "src/theme/Colors";
import TypeFace from "../typography/Typefaces";
import { useLocation, useNavigate } from "react-router-dom";
import IconHeart from "src/assets/images/icons/icon-heart-white.svg";
import Flexbox from "../wrapper/Flexbox";
import IconScheduleWhite from "src/assets/images/icons/menu/icon-schedule-white.svg";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { useDispatch, useSelector } from "react-redux";
import { storeSearchObject } from "src/redux/search/actions";
import { selectSearchObject } from "src/redux/search/selectors";
import useMediaQuery from "src/hooks/useMediaQuery";

export const BlueMenuBarWithBackButton = (props) => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <TypeFace onClick={() => navigate(-1)} semiBold size={14} color={"white"}>
        {"<"} Go Back
      </TypeFace>
    </div>
  );
};

const HomeBlueMenuBar = (props) => {
  const { isMobile } = useMediaQuery();
  const { userProfile } = useFirestore();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentSearchObject = useSelector(selectSearchObject);

  const handleSavedProperties = () => {
    navigate("/my/profile/saved");
  };

  const handleRent = () => {
    // if (location.pathname.includes('search')) {
    //   return
    // }
    dispatch(
      storeSearchObject({
        ...currentSearchObject,
        type: "rental",
      })
    );
    navigate("/search");
  };

  const handleBuy = () => {
    // if (location.pathname.includes('search')) {
    //   return
    // }
    dispatch(
      storeSearchObject({
        ...currentSearchObject,
        type: "sale",
      })
    );
    navigate("/search");
  };

  return (
    <div style={styles.container}>
      <Flexbox row style={{ width: "90%", maxWidth: 1178, alignItems: "center" }}>
        <TypeFace
          style={{ cursor: "pointer" }}
          onClick={handleRent}
          bold={!currentSearchObject || currentSearchObject?.type === "rental"}
          onPress={props.onRent}
          size={16}
          color={"white"}
        >
          Rent
        </TypeFace>
        <TypeFace
          onClick={handleBuy}
          onPress={props.onRent}
          size={16}
          bold={currentSearchObject?.type === "sale"}
          color={"white"}
          style={styles.buyButton}
        >
          Buy
        </TypeFace>
        <div style={{ flex: 1 }} />
        {!isMobile && userProfile?.role === "customer" && (
          <>
            <Flexbox row style={styles.savedProperties} onClick={() => navigate("/my/profile/schedules")}>
              <img src={IconScheduleWhite} width={20} height={20} />
              <TypeFace semiBold size={14} color={"white"} style={{ cursor: "pointer" }}>
                Schedules
              </TypeFace>
            </Flexbox>
            <div style={{ width: 20 }} />
            <Flexbox row style={styles.savedProperties} onClick={handleSavedProperties}>
              <img src={IconHeart} width={20} height={20} />
              <TypeFace semiBold size={14} color={"white"} style={{ cursor: "pointer" }}>
                Saved properties
              </TypeFace>
            </Flexbox>
          </>
        )}
      </Flexbox>
    </div>
  );
};

export default HomeBlueMenuBar;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: 44,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary,
  },
  buyButton: {
    marginLeft: 20,
    cursor: "pointer",
  },
  savedProperties: {
    alignItems: "center",
    gap: 10,
    cursor: "pointer",
  },
};
