import moment from 'moment'
import React, { useMemo, useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { BuildingFeatures, BuildingLocations, UnitACTypes, UnitAttics, UnitBasements, UnitFeatures, UnitHeatingFuels, UnitHeatingTypes, UnitOutspaces, UnitViews, buildingTypeLabelFromOption } from 'src/model/types'
import { Colors } from 'src/theme/Colors'
import { calculateDaysOnMarket, calculateNetEffectiveRents, monthlyPaymentAmount } from 'src/utils/calculators'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'
import { priceFormat } from 'src/utils/strings'
import IconCalculator from 'src/assets/images/icons/icon-calculator.svg'
import PaymentCalculatorModal from '../PaymentCalculatorModal'
import ListingDetailsPriceHistoryTable from './ListingDetailsPriceHistoryTable'
import Button from 'src/components/button/Button'
import useMediaQuery from 'src/hooks/useMediaQuery'
import ListingDetailsSideInformationView from './ListingDetailsSideInformationView'
import { Fragment } from 'react'
import VideoTourModal from './ListingDetailsVideoTourModal'
import { titleCase } from 'src/utils/strings';
import { salesTypeOptions } from "src/model/types"

const ListingDetailsInformationView = ({
  listingData,
  isMyListing,
  isMyAgencyListing,
  agency,
  teamMembers,
  isMeetLaterAlreadySetup,
  meetLaterWhen,
  messagingThreadId,
  handleOpenMessageRoom,
  handleOpenMeetLater
}) => {

  const { isMobile } = useMediaQuery()

  const [isOpeningPaymentCalculator, setIsOpeningPaymentCalculator] = useState(false)

  const [isOpeningVideoTour, setIsOpeningVideoTour] = useState(false)

  const [isDescriptionFull, setIsDescriptionFull] = useState(false)

  const marketAs = listingData?.unitInfo?.marketAs

  const unitAmenitiesList = useMemo(() => {
    let amenitiesList = []
    if (listingData?.unitInfo) {
      if (listingData.unitInfo.outdoor) {
        listingData.unitInfo.outdoor.forEach(outdoor => {
          const value = UnitOutspaces.find(item => item.key === outdoor)?.value
          if (value) {
            amenitiesList.push(value)
          }
        })
      }

      if (listingData.unitInfo.features) {
        listingData.unitInfo.features.forEach(feature => {
          const value = UnitFeatures.find(item => item.key === feature)?.value
          if (value) {
            amenitiesList.push(value)
          }
        })
      }
      if(listingData?.building.type !== 'single-family' && listingData?.building.type !== 'multi-family' && listingData?.building.type !== 'townHouse') {
        if (listingData.unitInfo.views) {
          listingData.unitInfo.views.forEach(view => {
            const value = UnitViews.find(item => item.key === view)?.value
            if (value) {
              amenitiesList.push(`${value} view`)
            }
          })
        }
      }

      if (listingData.unitInfo.acTypes) {
        const value = UnitACTypes.find(item => item.key === listingData.unitInfo.acTypes)?.label
        amenitiesList.push(<TypeFace semiBold>A/C Type:{' '}<TypeFace>{value}</TypeFace></TypeFace>)
      }

      if (listingData.unitInfo.heatingTypes) {
        const heatingTypeValues = []
        listingData.unitInfo.heatingTypes.forEach(heatingType => {
          const value = UnitHeatingTypes.find(item => item.key === heatingType)?.label
          if (value) {
            heatingTypeValues.push(value)
          }
        })

        amenitiesList.push(<TypeFace semiBold>Heating:{' '}<TypeFace>{heatingTypeValues.join(' | ')}</TypeFace></TypeFace>)
      }

      if (listingData.unitInfo.marketAs === 'singleFamily' || listingData.unitInfo.marketAs === 'multiFamily') {
        if (listingData.unitInfo.basements) {
          const labelValues = []
          listingData.unitInfo.basements.forEach(option => {
            const value = UnitBasements.find(item => item.key === option)?.label
            if (value) {
              labelValues.push(value)
            }
          })

          amenitiesList.push(<TypeFace semiBold>Basement:{' '}<TypeFace>{labelValues.join(' | ')}</TypeFace></TypeFace>)
        }

        if (listingData.unitInfo.attics) {
          const labelValues = []
          listingData.unitInfo.attics.forEach(option => {
            const value = UnitAttics.find(item => item.key === option)?.label
            if (value) {
              labelValues.push(value)
            }
          })

          amenitiesList.push(<TypeFace semiBold>Attic:{' '}<TypeFace>{labelValues.join(' | ')}</TypeFace></TypeFace>)
        }

        if (listingData.unitInfo.heatingFuels) {
          const labelValues = []
          listingData.unitInfo.heatingFuels.forEach(option => {
            const value = UnitHeatingFuels.find(item => item.key === option)?.label
            if (value) {
              labelValues.push(value)
            }
          })

          amenitiesList.push(<TypeFace semiBold>Heating Fuels:{' '}<TypeFace>{labelValues.join(' | ')}</TypeFace></TypeFace>)
        }
      }
    }
    return amenitiesList
  }, [listingData?.unitInfo])

  const extraMonthlyFee = useMemo(() => {
    if (listingData) {
      let fee = 0
      if (marketAs === 'building' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') {
        fee += Number((listingData.monthlyTax / 12).toFixed(2) ?? "0")
      }

      if (marketAs === 'condo') {
        fee += Number(listingData.monthlyTax ?? "0")
      }
  
      if (marketAs === 'coop') {
        fee += Number(listingData.maintenance ?? "0")
      }
  
      if (marketAs === 'coop' || marketAs === 'condo') {
        fee += Number(listingData.commonCharges ?? "0")
      }

      return fee
    }
    return 0
  }, [listingData, marketAs])

  const buildingAmenitiesList = useMemo(() => {
    let amenitiesList = []
    if (listingData?.building) {
      if(listingData?.building.type !== 'single-family' && listingData?.building.type !== 'multi-family' && listingData?.building.type !== 'townHouse') {
        if (listingData.building.pets) {
          if (listingData.building.pets === 'no') {
            amenitiesList.push("No pets allowed")
          } else if (listingData.building.pets === 'cats_dogs') {
            amenitiesList.push("Pets policy: Cats and Dogs")
          } else {
            amenitiesList.push("Pets policy: Cats only")
          }
        }
      }

      if (listingData.building.outdoor) {
        listingData.building.outdoor.forEach(outdoor => {
          if (outdoor === 'courtyard') {
            amenitiesList.push('Courtyard')
          } else if (outdoor === 'roof') {
            amenitiesList.push('Roof deck')
          }else if (outdoor === 'private_backyard') {
            amenitiesList.push('Private Backyard')
          }else if (outdoor === 'garden') {
            amenitiesList.push('Garden')
          }else if (outdoor === 'patio') {
            amenitiesList.push('Patio')
          }else if (outdoor === 'fenced_yard') {
            amenitiesList.push('Fenced Yard')
          }else if (outdoor === 'gated_community') {
            amenitiesList.push('Gated Community')
          }
        })
      }

      if (listingData.building.basements) {
        listingData.building.basements.forEach(basement => {
          if (basement === 'crawl-base') {
            amenitiesList.push('Crawl Space Basement')
          } else if (basement === 'full-base') {
            amenitiesList.push('Full Basement')
          }else if (basement === 'partial-base') {
            amenitiesList.push('Partial Basement')
          }else if (basement === 'finished-base') {
            amenitiesList.push('Finished Basement')
          }else if (basement === 'unfinished-base') {
            amenitiesList.push('Unfinished Basement')
          }else if (basement === 'partially-finished-base') {
            amenitiesList.push('Partially Finished Basement')
          }else if (basement === 'base-none') {
            //amenitiesList.push('None')
          }
        })
      }

      if (listingData.building.attics) {
        listingData.building.attics.forEach(attic => {
          if (attic === 'full-attic') {
            amenitiesList.push('Full Attic')
          } else if (attic === 'partial-attic') {
            amenitiesList.push('Partial Attic')
          }else if (attic === 'finished-attic') {
            amenitiesList.push('Finished Attic')
          }else if (attic === 'unfinished-attic') {
            amenitiesList.push('Unfinished Attic')
          }else if (attic === 'partially-finished-attic') {
            amenitiesList.push('Partially Finished Attic')
          }else if (attic === 'attic-none') {
            //amenitiesList.push('None')
          }
        })
      }

      if (listingData.building.features) {
        listingData.building.features.forEach(feature => {
          const value = BuildingFeatures.find(item => item.key === feature)?.value
          if (value) {
            amenitiesList.push(value)
          }
        })
      }

      if (listingData.building.doorMan) {
        if (listingData.building.doorMan === 'no') {
          amenitiesList.push('No Doorman')
        } else {
          amenitiesList.push(`Doorman: ${listingData.building.doorMan}`)
        }
      }

      if (listingData.building.locations) {
        listingData.building.locations.forEach(location => {
          const value = BuildingLocations.find(item => item.key === location)?.value
          if (value) {
            amenitiesList.push(value)
          }
        })
      }
    }

    return amenitiesList
  }, [listingData])

  const handlePaymentCalculator = () => {
    setIsOpeningPaymentCalculator(true)
  }

  const handleVideoTour = () => {
    setIsOpeningVideoTour(true)
  }

  const baths = useMemo(() => {
    //console.log('listingData?.unitInfo?.halfBaths=',listingData?.unitInfo)
    if (!(listingData?.unitInfo?.fullBaths * 1) && !(listingData?.unitInfo?.halfBaths * 1))
      return "No"
    return (listingData?.unitInfo?.fullBaths * 1 ?? 0) + (listingData?.unitInfo?.halfBaths * 1 ?? 0) * 0.5
  }, [listingData])

  return (
    <>
    <Flexbox  style={{
      flex: 1,
      alignItems: isMobile ? 'center' : 'flex-start',
    }}>
      <TypeFace semiBold color={Colors.primary} size={21}>
        {extractStreetAddressFromFullAddress(listingData?.address?.full)}
        {listingData?.unit !== '' && 
        <>
        , Unit {listingData?.unit}
        </>}
        
      </TypeFace>
     
      <Flexbox row style={{ gap: 15 }}>
        {listingData?.status === 'Sold' ?
         <>
        <TypeFace bold size={18}>
          ${priceFormat(listingData?.soldPrice, true)}
        </TypeFace>
         <TypeFace bold size={14} color={Colors.gray1}>
         Last Sold: {moment(new Date(listingData?.soldDate)).format('MMM D, yyyy')}
         </TypeFace>
         </>
          :
          listingData?.status === 'Rented' ? 
          <>
          <TypeFace bold size={18}>
          ${priceFormat(listingData?.rentedPrice, true)}
        </TypeFace>
         <TypeFace bold size={14} color={Colors.gray1}>
         Last Rented: {moment(new Date(listingData?.rentedDate)).format('MMM D, yyyy')}
         </TypeFace>
         </>
          :
          <>
          <TypeFace bold size={18}>
            ${listingData?.type === 'rental' ? priceFormat(listingData?.grossRent ?? '') : priceFormat(listingData?.price, true)}
          </TypeFace>
          <TypeFace bold size={14} color={Colors.gray1}>
            {listingData?.type === 'rental' ? 'FOR RENT' : 'FOR SALE'}{listingData.ownerPaysFee === 1 ? ", NO FEE" : ""}
            {' ('}
            {salesTypeOptions.find(val => val.value === listingData?.unitInfo.saleType)?.label ?? ""}{')'}
          </TypeFace>
          </>
        }
      </Flexbox>
      <Flexbox row style={{ gap: 15, marginTop: 10 }}>
        {listingData?.unitInfo?.totalRooms && (
          <Fragment>
            <TypeFace semiBold size={15}>
              {listingData?.unitInfo?.totalRooms} Rooms
            </TypeFace>
            <TypeFace size={15} color="#9f9f9f">
              |
            </TypeFace>
          </Fragment>
        )}
        <TypeFace semiBold size={15}>
          {listingData?.unitInfo?.legalBeds} Beds
        </TypeFace>
        <TypeFace size={15} color="#9f9f9f">
          |
        </TypeFace>
        <TypeFace semiBold size={15}>
          {baths} Baths 
        </TypeFace>
        {listingData?.unitInfo?.size && (
          <Fragment>
            <TypeFace size={15} color="#9f9f9f">
              |
            </TypeFace>
            <TypeFace semiBold size={15}>
              {listingData?.unitInfo?.size}ft²
            </TypeFace>
          </Fragment>
        )}
      </Flexbox>
      <Flexbox row style={{ justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 40, maxWidth: 540 }}>
        {listingData?.type === 'rental' && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              AVAILABLE ON
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {moment(listingData?.unitInfo.dateAvailable).format('MM/DD/YYYY')}
            </TypeFace>
          </Flexbox>
        )}
        <Flexbox style={styles.importantInfoPanel}>
          <TypeFace bold size={12} color="#7f7f7f">
            DAYS ON MARKET
          </TypeFace>
          <div style={{ flex: 1 }} />
          <TypeFace semiBold size={16} color="#3f3f3f">
            {calculateDaysOnMarket(listingData?.activatedAt)} Days
          </TypeFace>
        </Flexbox>
        {/* {listingData?.type === 'rental' && listingData?.unitInfo.concession === 0 && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              CONCESSION
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              No
            </TypeFace>
          </Flexbox>
        )} */}
        {listingData?.type === 'rental' && listingData?.unitInfo.concession === 1 && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              MONTHS FREE
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {listingData?.unitInfo?.monthsFree}
            </TypeFace>
          </Flexbox>
        )}
        {listingData?.type === 'rental' && listingData?.unitInfo.concession === 1 && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              LEASE TERM
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {listingData?.unitInfo?.leaseTerm}
            </TypeFace>
          </Flexbox>
        )}
        {listingData?.type === 'rental' && listingData?.unitInfo.concession === 1 && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              NET EFFECTIVE RENTS
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              ${priceFormat(calculateNetEffectiveRents({
                grossRent: listingData?.grossRent,
                leaseTerm: listingData?.unitInfo?.leaseTerm,
                monthsFree: listingData?.unitInfo?.monthsFree
              }), true)}
            </TypeFace>
          </Flexbox>
        )}
        {(listingData?.type === 'sale' && (marketAs === 'building' || marketAs === 'condo' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse')) && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              {(marketAs === 'building' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') ? 'YEARLY TAXES' : 'MONTHLY TAXES'}
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              ${priceFormat(listingData?.monthlyTax, true)}
            </TypeFace>
          </Flexbox>
        )}
        {(listingData?.type === 'sale' && (marketAs === 'condop' || marketAs === 'coop')) && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              MAINTENANCE/TAXES
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              ${priceFormat(listingData?.maintenance, true)}
            </TypeFace>
          </Flexbox>
        )}
        {(listingData?.type === 'sale' && (marketAs === 'condo')) && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              COMMON CHARGES
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              ${priceFormat(listingData?.commonCharges, true)}
            </TypeFace>
          </Flexbox>
        )}
        {listingData?.type === 'sale' && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              ESTIMATED PAYMENT
            </TypeFace>
            <div style={{ flex: 1 }} />
            <Flexbox row style={{ gap: 10 }}>
              <TypeFace semiBold size={16} color="#3f3f3f">
                ${priceFormat(monthlyPaymentAmount({
                  salePrice: Number(listingData?.price ?? 0),
                  downPayment: Number(listingData?.price ?? 0) * 0.2,
                  interestRate: 7.625,
                  termYears: 30,
                  extra: extraMonthlyFee,
                }).estMonthlyPayment, true)}
              </TypeFace>
              <img
                src={IconCalculator}
                width={16}
                height={16}
                style={{ cursor: 'pointer' }}
                alt="payment-calculator"
                onClick={handlePaymentCalculator}
              />
            </Flexbox>
          </Flexbox>
        )}
        {(listingData?.type === 'sale' && (marketAs === 'condo')) && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              MAX FINANCING
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {listingData?.maxFinancing}%
            </TypeFace>
          </Flexbox>
        )}
        {((marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (listingData?.unitInfo?.lotSize ?? 0) > 0) && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              LOT SIZE
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {listingData?.unitInfo?.lotSizeText}
            </TypeFace>
          </Flexbox>
        )}
       {/*  {listingData?.coBrokerSelected === 1 && ( */}
        {!!listingData?.coBrokeCommission && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              CO-BROKE COMMISSION
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              {listingData.coBrokeCommission}%
            </TypeFace>
          </Flexbox>
        )}
        {listingData?.type === 'rental' && listingData?.unitInfo.furnished === 1 && (
          <Flexbox style={styles.importantInfoPanel}>
            <TypeFace bold size={12} color="#7f7f7f">
              FURNISHED
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace semiBold size={16} color="#3f3f3f">
              Yes
            </TypeFace>
          </Flexbox>
        )}
      </Flexbox>
      {isMobile && (
        <ListingDetailsSideInformationView
          listingData={listingData}
          isMyListing={isMyListing}
          isMyAgencyListing={isMyAgencyListing}
          agency={agency}
          teamMembers={teamMembers}
          isMeetLaterAlreadySetup={isMeetLaterAlreadySetup}
          meetLaterWhen={meetLaterWhen}
          messagingThreadId={messagingThreadId}
          handleOpenMessageRoom={handleOpenMessageRoom}
          handleOpenMeetLater={handleOpenMeetLater}
        />
      )}
      {listingData?.unitInfo?.desc && (
        <>
          <TypeFace bold size={24} style={{ marginTop: 40 }}>
            DESCRIPTION
          </TypeFace>
          <TypeFace size={14} style={{ textAlign: 'start', marginTop: 15, wordWrap: 'break-word', maxWidth: '90vw' }}>
            {listingData?.unitInfo?.desc?.length > 300 ? 
              (isDescriptionFull ? listingData?.unitInfo?.desc : (listingData?.unitInfo?.desc?.substring(0, 300)) + "...")
             : listingData?.unitInfo?.desc}
          </TypeFace>
          {listingData?.unitInfo?.desc?.length > 300 && (
            <Button secondary style={{ marginTop: 10 }} onClick={() => setIsDescriptionFull(!isDescriptionFull)}>
              {isDescriptionFull ? "Read Less" : "Read More"}
            </Button>
          )}
        </>
      )}
      <TypeFace bold size={24} style={{ marginTop: 40 }}>
        UNIT AMENITIES
      </TypeFace>
      <Flexbox row style={{ justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 10, columnGap: 50}}>
        {unitAmenitiesList?.map((amenity, index) => (
          <TypeFace key={index} size={14} style={{ height: 30, textAlign: 'start', minWidth: 220 }}>
            {amenity}
          </TypeFace>
        ))}
      </Flexbox>
      <TypeFace bold size={24} style={{ marginTop: 40 }}>
        ABOUT BUILDING
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, gap: 20 }}>
        <TypeFace bold size={12}>
          Built in <TypeFace size={16}>{listingData?.building.year}</TypeFace>
        </TypeFace>
        <TypeFace bold size={12}>
          Type: <TypeFace  size={16}>
            {buildingTypeLabelFromOption(listingData?.building.type)}
          </TypeFace>
        </TypeFace>
      </Flexbox>
      <TypeFace semiBold color="#5f5f5f" size={14} style={{ marginTop: 15 }}>
        Amenities
      </TypeFace>
      <Flexbox row style={{ justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 10}}>
        {buildingAmenitiesList?.map((amenity, index) => (
          <TypeFace key={index} size={14} style={{ width: 250, height: 30, textAlign: 'start' }}>
            {amenity}
          </TypeFace>
        ))}
      </Flexbox>
      <TypeFace semiBold color="#3f3f3f" size={18} style={{ marginTop: 40 }}>
        PRICE HISTORY
      </TypeFace>
      <ListingDetailsPriceHistoryTable listingId={listingData?.id} />
      <PaymentCalculatorModal
        modalIsOpen={isOpeningPaymentCalculator}
        closeModal={() => setIsOpeningPaymentCalculator(false)}
        originalSalesPrice={listingData?.price}
        marketAs={marketAs}
        monthlyTax={listingData?.monthlyTax}
        commonCharges={listingData?.commonCharges}
        maintenance={listingData?.maintenance}
      />
      <VideoTourModal
        modalIsOpen={isOpeningVideoTour}
        closeModal={() => setIsOpeningVideoTour(false)}
        videoURL={listingData?.video}
      />
      
    </Flexbox>
    {(listingData?.video !== '' && listingData?.video !== undefined) &&
      <Flexbox style={{ flexWrap: 'wrap'}}>
         <Button secondary style={{ marginTop: 10 }} onClick={handleVideoTour}>
            Watch Video
         </Button>
       </Flexbox> 
    }
    </>
  )
}

export default ListingDetailsInformationView

const styles = {
  importantInfoPanel: {
    width: 153,
    height: 40,
    marginBottom: 20,
    marginRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    border: '1px solid #dfdfdf',
    alignItems: 'flex-start',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
  },
  viewTour : {
    paddingLeft:10,
    marginRight:100
  }
}