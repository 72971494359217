import { doc, getDoc, getDocFromCache, getDocs, getDocsFromCache } from "firebase/firestore"
import { firestore } from "src/firebase"

export const getListing = (id) => {
  return getDoc(doc(firestore, "listing", id))
}

export const getFirestoreProfileDoc = (profileId, usingCache = true) => {
  if (!usingCache) {
    return getDoc(doc(firestore, `profiles/${profileId}`))
  }
  return new Promise((resolve, reject) => {
    getDocFromCache(doc(firestore, `profiles/${profileId}`))
      .then(documentSnapshot => {
        resolve(documentSnapshot)
      })
      .catch(() => {
        getDoc(doc(firestore, `profiles/${profileId}`))
          .then(docSnapshot => {
            resolve(docSnapshot)
          })
          .catch(err => {
            reject(err)
          })
      })
  })
}

export const getFirestoreListingDoc = (listingId, usingCache = true) => {
  if (!usingCache) {
    return getDoc(doc(firestore, `listing/${listingId}`))
  }
  return new Promise((resolve, reject) => {
    getDocFromCache(doc(firestore, `listing/${listingId}`))
      .then(documentSnapshot => {
        resolve(documentSnapshot)
      })
      .catch(() => {
        getDoc(doc(firestore, `listing/${listingId}`))
          .then(docSnapshot => {
            resolve(docSnapshot)
          })
          .catch(err => {
            reject(err)
          })
      })
  })
}


export const getFirestoreAgencyDoc = (agencyId, usingCache = true) => {
  if (!usingCache) {
    return getDoc(doc(firestore, `agencies/${agencyId}`))
  }
  return new Promise((resolve, reject) => {
    getDocFromCache(doc(firestore, `agencies/${agencyId}`))
      .then(documentSnapshot => {
        resolve(documentSnapshot)
      })
      .catch(() => {
        getDoc(doc(firestore, `agencies/${agencyId}`))
          .then(docSnapshot => {
            resolve(docSnapshot)
          })
          .catch(err => {
            reject(err)
          })
      })
  })
}


export const getFirestoreDocs = (query, usingCache = true) => {
  if (!usingCache) {
    return getDocs(query)
  } else {
    return new Promise((resolve, reject) => {
      getDocsFromCache(query)
        .then(querySnapshot => {
          // If no cached, it will return empty results
          if (querySnapshot.empty) {
            getDocs(query)
              .then(querySnapshot => {
                resolve(querySnapshot)
              })
              .catch(err => {
                reject(err)
              })
          } else {
            resolve(querySnapshot)
          }
        })
        .catch(() => {
          getDocs(query)
            .then(querySnapshot => {
              resolve(querySnapshot)
            })
            .catch(err => {
              reject(err)
            })
        })
      })
  }
}