import React from 'react'
import { Colors } from 'src/theme/Colors'
import TypeFace from '../typography/Typefaces'
import IconFacebook from 'src/assets/images/icons/icon-facebook.svg'
import IconInstagram from 'src/assets/images/icons/icon-instagram.svg'
import IconLinkedIn from 'src/assets/images/icons/icon-linkedin.svg'
import IconYoutube from 'src/assets/images/icons/icon-youtube.svg'
import useMediaQuery from 'src/hooks/useMediaQuery'

const HomeSocialConnectPanel = () => {

  const { isMobile } = useMediaQuery()

  return (
    <div style={styles.container}>
      <TypeFace bold color={'white'} size={24}>
        Connect with Us
      </TypeFace>
      <div style={styles.actions}>
        <div>
          <img src={IconYoutube} width={44} height={44} />
        </div>
        <div>
          <img src={IconLinkedIn} width={44} height={44} />
        </div>
        <div>
          <img src={IconInstagram} width={44} height={44} />
        </div>
        <div>
          <img src={IconFacebook} width={44} height={44} />
        </div>
      </div>
    </div>
  )
}

export default HomeSocialConnectPanel

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.primary,
    height: 174,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionCardStyle: {
    flex: 1,
    height: 320,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    gap: 40,
  },
}