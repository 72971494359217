import React, { useEffect, useState } from "react";
import { Colors } from "src/theme/Colors";
import TypeFace from "../../typography/Typefaces";
import Flexbox from "../../wrapper/Flexbox";
import IconBedRoom from "src/assets/images/icons/icon-bedroom.svg";
import IconBathRoom from "src/assets/images/icons/icon-bathroom.svg";
import IconRoomSize from "src/assets/images/icons/icon-room-size.svg";
import { priceFormat } from "src/utils/strings";
import { buildingTypeLabelFromOption } from "src/model/types";
import { collection } from "firebase/firestore";
import { firestore } from "src/firebase";
import { useNavigate } from "react-router-dom";
import { extractStreetAddressFromFullAddress } from "src/utils/geoUtils";
import useMediaQuery from "src/hooks/useMediaQuery";
import { getFirestoreDocs, getFirestoreListingDoc } from "src/data/dbWrappers";

const CardSearchResultItemInHome = (props) => {
  const hit = props.hit;

  const navigate = useNavigate();

  const { isMobile } = useMediaQuery();

  const [listingdDoc, setListingDoc] = useState();
  const [photos, setPhotos] = useState();

  const handleClick = () => {
    navigate(`/listing/${hit.dataId}`);
  };

  const loadFirebaseDocument = () => {
    const documentId = props.hit.dataId;
    getFirestoreListingDoc(documentId).then((docData) => {
      setListingDoc(docData.data());
    });
    getFirestoreDocs(collection(firestore, `listing/${documentId}/photos`)).then((collectionData) => {
      let photosList = [];
      collectionData.forEach((doc) => {
        photosList.push(doc.data());
      });
      photosList.sort((a, b) => a.index - b.index);
      setPhotos(photosList);
    });
  };

  useEffect(() => {
    if (props.hit) {
      loadFirebaseDocument();
    }
  }, [props.hit]);

  return (
    <Flexbox
      onClick={handleClick}
      style={{
        ...styles.container,
        width: isMobile ? "100%" : 380,
        maxWidth: isMobile ? "100%" : 380,
        height: 520,
      }}
    >
      {photos && photos.length > 0 ? (
        <img src={photos[0].url} width={"100%"} height={214} alt="listing-photo" />
      ) : (
        <Flexbox style={{ width: "100%", height: 214 }} />
      )}
      <Flexbox style={styles.contentArea}>
        <TypeFace color={Colors.primary} size={28} bold>
          {hit.type === "sale" ? `$${priceFormat(hit.price, true)}` : `$${priceFormat(hit.grossRent, true)}`}
        </TypeFace>
        <TypeFace color="#5f5f5f" size={16} semiBold style={{ marginTop: 10 }}>
          {buildingTypeLabelFromOption(hit.building.type)} in {hit.address.neighborhood}
        </TypeFace>
        <TypeFace size={16} bold style={{ marginTop: 10, textAlign: "left" }}>
          {hit.address.full}, {hit.address.zip}, {listingdDoc?.unit && `#${listingdDoc?.unit}`}
        </TypeFace>
        <div style={{ height: 30 }} />
        <Flexbox row>
          <img src={IconBedRoom} alt="bed-icon" />
          <div style={{ width: 5 }} />
          <TypeFace semiBold color="#5f5f5f" size={13}>
            {hit.unitInfo?.legalBeds ?? "No"} Bed
          </TypeFace>
          <div style={{ width: 20 }} />
          <img src={IconBathRoom} width={16} height={16} alt="bath-icon" />
          <div style={{ width: 5 }} />
          <TypeFace semiBold color="#5f5f5f" size={13}>
            {hit.unitInfo?.fullBaths ?? "No"} Baths
          </TypeFace>
          {!!hit.unitInfo?.size && (
            <>
              <div style={{ width: 20 }} />
              <img src={IconRoomSize} width={13} height={12} alt="room-size" />
              <div style={{ width: 5 }} />
              <TypeFace semiBold color="#5f5f5f" size={13}>
                {hit.unitInfo?.size}ft²
              </TypeFace>
            </>
          )}
        </Flexbox>
        <TypeFace color="#5f5f5f" size={12} semiBold style={{ textAlign: "start", marginTop: 20 }}>
          Listing by {hit.agency}
        </TypeFace>
      </Flexbox>
    </Flexbox>
  );
};

export default CardSearchResultItemInHome;

const styles = {
  container: {
    border: "1px solid #dfdfdf",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "white",
    marginRight: 8,
    marginBottom: 30,
    cursor: "pointer",
  },
  contentArea: {
    padding: "40px 16px",
    alignItems: "flex-start",
    background: "white",
    cursor: "pointer",
  },
};
