import { collection, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "src/components/button/Button";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { firestore } from "src/firebase";
import { Colors } from "src/theme/Colors";
import { extractStreetAddressFromFullAddress } from "src/utils/geoUtils";
import { Flex, Table, Typography } from "antd";
import AdminLayout from "../../../../layouts/AuthLayout";
import styles from "../../admin.module.scss";

const { Text } = Typography;

const reportReasonDescriptions = {
  inappropriate: "Inappropriate property",
  "fake listing": "Fake Listing",
  wrong: "Wrong information",
  others: "Others",
};

const AdminListingReports = () => {
  const unmountedRef = useRef(false);

  const [reports, setReports] = useState([]);

  const handleOpenListing = (listingId) => {};

  useEffect(() => {
    unmountedRef.current = false;
    const reportsSnapshotsListner = onSnapshot(
      query(collection(firestore, "reportListings"), orderBy("reported", "desc")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setReports(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      reportsSnapshotsListner();
    };
  }, []);

  const columns = [
    {
      title: "Property",
      dataIndex: "listingId",
      key: "listingId",
      render: (_, review) => (
        <Link to={`/listing/${review.id}`} style={{ textDecoration: "none" }}>
          {review.address}
        </Link>
      ),
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (_, report) => <Text>{report.name}</Text>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, report) => <Text>{report.email}</Text>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (_, report) => <Text>{reportReasonDescriptions[report.reason]}</Text>,
    },

    {
      title: "Reported At",
      dataIndex: "when",
      key: "when",
      render: (text) => moment(text).format("MMM DD, YYYY"),
    },
  ];

  const expandable = {
    expandedRowRender: (record) => {
      return (
        <Flex vertical gap={10} style={{ width: "50%" }}>
          <Text strong>Comments:</Text>
          <Text>{record.comment}</Text>
        </Flex>
      );
    },
  };

  return (
    <AdminLayout title="Reported listings">
      <Table
        expandable={expandable}
        dataSource={reports}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
        rowKey={(_, index) => index}
      />
    </AdminLayout>
  );
};

export default AdminListingReports;
