import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Flexbox from 'src/components/wrapper/Flexbox';
import useMediaQuery from 'src/hooks/useMediaQuery';
import LoaderGif from 'src/assets/images/loader3.gif';
import TypeFace from 'src/components/typography/Typefaces';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from 'src/firebase';
import IconAvatarPlaceholder from 'src/assets/images/icons/icon-avatar-placeholder.svg'
import { Colors } from 'src/theme/Colors';
import { filterDataByUserId } from 'src/algolia';
import CardSearchResultItem from 'src/components/home/search/CardSearchResultItem';
import HomeFooter from 'src/components/home/HomeFooter';
import Button from 'src/components/button/Button';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import HomeBlueMenuBar from 'src/components/home/HomeBlueMenuBar';
import { getFirestoreAgencyDoc, getFirestoreProfileDoc } from 'src/data/dbWrappers';
import { UserRoleValue, roleLabelFromValue } from 'src/model/types';
import { useAlert } from 'src/hooks/alert/AlertContext';

const Profile = () => {

  const { isMobile } =  useMediaQuery()
  const { userProfile } = useFirestore()
  const { showAlert } = useAlert()
  const navigate = useNavigate()

  const {profileId} = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState()
  const [profileAgency, setProfileAgency] = useState()
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)

  const [selectedSection, setSelectedSection] = useState(0)

  const [userListings, setUserListings] = useState()

  const loadProfileData = () => {
    setIsLoading(true)
    
    getFirestoreProfileDoc(profileId)
      .then(data => {
        const profileData = data.data()
        setProfile(profileData)
        if (profileData.agency) {
          getFirestoreAgencyDoc(profileData.agency).then(agencyData => {
            setIsLoading(false)
            setProfileAgency(agencyData.data())
          })
        } else {
          setIsLoading(false)
        }
      })

    if (userProfile?.userId === profileId && userProfile.role === 'customer') {

    } else {
      filterDataByUserId(profileId).then(res => {
        console.log("RES => ", res)
        setUserListings(res)
      })
    }
  }

  const handleSignout = () => {
    auth.signOut().then(() => {
      navigate('/')
    })
  }

  const handleContactAgent = () => {
    // console.log("Email => ", profile?.email)
    // console.log("Phone  => ", profile?.phone)

    // const actionButtons = []

    // if (profile?.email) {
    //   actionButtons.push({
    //     title: "Email ",
    //     onClick: () => {
    //       showAlert(false)
    //     }
    //   })
    // }

    // if (profile?.phone) {
    //   actionButtons.push({
    //     title: "Phone",
    //     onClick: () => {
    //       showAlert(false)
    //     }
    //   })
    // }

    // showAlert(true, 'Contact agent', actionButtons)
    if (profile?.email)  {
      // window.open(`mailto:${profile?.email}`)
      // window.open(`mailto:${profile?.email}`)
      showAlert(true, `Send an email to ${profile?.email}`)
    }
  }

  useEffect(() => {
    loadProfileData()
  }, [profileId, userProfile])

  return (
    <div className="App">
      {/* {isMobile && <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />} */}
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {/* {!isMobile && <HomeBlueMenuBar />} */}
          <HomeBlueMenuBar />
          <Flexbox style={{ width: '100%', marginBottom: isMobile ? 20 : 80 }}>
            {isLoading ? (
              <img src={LoaderGif} style={{ width: 30, height: 30, marginTop: 40 }} />
            ) : (
              <Flexbox style={{
                width: '90%',
                maxWidth: 1178,
                paddingTop: 30,
                alignItems: 'center'
              }}>
                <Flexbox style={{
                  gap: 30,
                  alignSelf: 'flex-start',
                  alignItems: 'flex-start',
                  flexDirection: isMobile ? 'column' : 'row',
                }}>
                  <Flexbox>
                    {!!profile?.photo?.url ? (
                      <img src={profile.photo.url} style={{
                        ...styles.avatar,
                        width: isMobile ? 60 : 200,
                        height: isMobile ? 60 : 200,
                      }}/>
                    ) : (
                      <img src={IconAvatarPlaceholder} width={isMobile ? 60 : 200} height={isMobile ? 60 : 200} style={{
                        ...styles.avatar,
                        width: isMobile ? 60 : 200,
                        height: isMobile ? 60 : 200,
                      }}/>
                    )}
                  </Flexbox>
                  
                  <Flexbox style={{
                    alignItems: 'flex-start'
                  }}>
                    <TypeFace size={isMobile ? 28 : 21}>
                      {profile?.name}
                    </TypeFace>
                    {/* <TypeFace size={isMobile ? 23 : 18}  style={{ marginTop: 10 }}>
                      {profile?.email}
                    </TypeFace> */}
                    <TypeFace bold size={isMobile ? 23 : 18} style={{ marginTop: 10, textAlign: 'left' }}>
                      {roleLabelFromValue(profile?.role)}
                    </TypeFace>
                    {profile?.role !== 'customer' && (
                      <TypeFace size={isMobile ? 23 : 18}>
                        {profileAgency?.name}
                      </TypeFace>
                    )}
                    {profile?.role !== 'customer' && (
                      <Button secondary style={{ marginTop: 20 }} onClick={handleContactAgent}>
                        Contact
                      </Button>
                    )}
                    {(userProfile?.userId === profileId && userProfile.role === 'customer') && (
                      <Button secondary style={{ marginTop: 20 }} onClick={handleSignout}>
                        Sign out
                      </Button>
                    )}
                  </Flexbox>
                </Flexbox>
                {!(userProfile?.userId === profileId && userProfile.role === 'customer') && (
                  <>
                    <Flexbox row style={{
                      width: '100%',
                      height: 105,
                      justifyContent: 'flex-start',
                    }}>
                      <Flexbox style={{ width: 100, height: 50 }} onClick={() => {
                        setSelectedSection(0)
                      }}>
                        <TypeFace bold size={18}>
                          Bio
                        </TypeFace>
                        {selectedSection === 0 && (
                          <div style={{
                            width: 40,
                            height: 4,
                            backgroundColor: Colors.primary,
                            marginTop: 10,
                          }} />
                        )}
                      </Flexbox>
                      <Flexbox style={{ width: 100, height: 50 }} onClick={() => {
                        setSelectedSection(1)
                      }}>
                        <TypeFace bold size={18}>
                          Listings
                        </TypeFace>
                        {selectedSection === 1 && (
                          <div style={{
                            width: 100,
                            height: 4,
                            backgroundColor: Colors.primary,
                            marginTop: 10,
                          }} />
                        )}
                      </Flexbox>
                    </Flexbox>
                    <div style={styles.separator} />
                    {selectedSection === 0 ? (
                      <Flexbox style={{
                        width: '100%',
                        alignItems: 'flex-start'
                      }}>
                        <TypeFace bold size={isMobile ? 23 : 18}>
                          ABOUT
                        </TypeFace>
                        <TypeFace size={isMobile ? 19 : 16} style={{ width: '80%', textAlign: 'left', marginTop: 20 }}>
                          {profile?.bio}
                        </TypeFace>
                        <TypeFace bold size={isMobile ? 23 : 18} style={{ marginTop: 20 }}>
                          LICENSE
                        </TypeFace>
                        <TypeFace bold size={isMobile ? 19 : 14} style={{ marginLeft: 20, marginTop: 10 }}>
                          License name: {profile?.name}
                        </TypeFace>
                        <TypeFace bold size={isMobile ? 19 : 14} style={{ marginLeft: 20, marginTop: 10 }}>
                          License number: {profile?.role === UserRoleValue.Broker ? profile?.agency : profile?.licenseNumber}
                        </TypeFace>
                      </Flexbox>
                    ) : (
                      <Flexbox style={{
                        width: '100%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}>
                        {userListings?.hits.map(hit => (
                          <CardSearchResultItem hit={hit}/>
                        ))}
                      </Flexbox>
                    )}
                  </>
                )}
              </Flexbox>
            )}
          </Flexbox>
          <HomeFooter />
        </div>
      </div>
    </div>
  )
}

export default Profile

const styles = {
  profileContainer: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 200,
    height: 200,
    borderRadius: 100,
    alignSelf: 'center'
  },
  separator: {
    height: 1,
    width: '100%',
    marginBottom: 30,
    backgroundColor: Colors.gray3
  },
  avatarButtons: {
    height: 32,
  },
}
