import { doc, getDoc, setDoc } from "firebase/firestore";
import { areasLayer } from "src/data/AreasData";
import { firestore } from "src/firebase";

export const extractAddressComponent = (googleGeoAddressComponents, mapGeoAddressComponents) => {
  const postal_code =
    googleGeoAddressComponents.find((component) => component.types.includes("postal_code"))?.long_name ?? "";
  const stateComp = googleGeoAddressComponents.find((component) =>
    component.types.includes("administrative_area_level_1")
  );
  const cityComp = googleGeoAddressComponents.find(
    (component) => component.types.includes("administrative_area_level_2") || component.types.includes("city")
  );
  const neighborhoodComp = mapGeoAddressComponents.find((component) => component.place_type.includes("neighborhood"));
  const localityComp = mapGeoAddressComponents.find((component) => component.place_type.includes("locality"));
  const districtComp = mapGeoAddressComponents.find((component) => component.place_type.includes("district"));

  const res = {
    postal_code,
    state: {
      long: stateComp?.long_name,
      short: stateComp?.short_name,
    },
    city: {
      long: cityComp?.long_name,
      short: cityComp?.short_name,
    },
    components: mapGeoAddressComponents?.map((comp) => {
      return {
        types: comp.place_type,
        text: comp.text,
        bbox: comp.bbox,
        center: comp.center,
      };
    }),
  };

  if (localityComp) {
    res.locality = localityComp.text;
  }

  if (neighborhoodComp) {
    res.neighborhood = neighborhoodComp.text;
  }

  if (districtComp) {
    res.district = districtComp.text;
  }

  return res;
};

export const checkBoundingBoxContainsIn = (areaBox, subAreaBox) => {
  return (
    (areaBox[0] - subAreaBox[0]) * (subAreaBox[0] - areaBox[2]) > 0 &&
    (areaBox[0] - subAreaBox[2]) * (subAreaBox[2] - areaBox[2]) > 0 &&
    (areaBox[1] - subAreaBox[1]) * (subAreaBox[1] - areaBox[3]) > 0 &&
    (areaBox[1] - subAreaBox[3]) * (subAreaBox[3] - areaBox[3]) > 0
  );
};

export const extractStreetAddressFromFullAddress = (fullAddress) => {
  return fullAddress?.split(",")[0];
};

export const extractCityStateFromFullAddress = (fullAddress) => {
  return ", " + fullAddress?.split(",")[1] + ", " + fullAddress?.split(",")[2] + ", " + fullAddress?.split(",")[3];
};

export const getStaticMapUrl = (location, width, height) => {
  let url = "https://maps.googleapis.com/maps/api/staticmap?";
  url += `center=${location?.lat},${location?.lng}`;
  url += "&zoom=14";
  url += `&size=${width}x${height}`;
  url += `&markers=color:red|${location?.lat},${location?.lng}`;
  url += `&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  console.log(url);
  return url;
};

export async function getComponents(address) {
  let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  let mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address?.replaceAll(
    " ",
    "%20"
  )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
  let resData = await Promise.all([fetch(url), fetch(mapboxUrl)]);

  let res = await Promise.all(resData.map((res) => res.json()));

  // console.log(res);

  let allComponents = [];
  let googlecomponents = res[0].results[0].address_components;

  let mapboxcomponents = res[1].features[0].context;

  let streetData = googlecomponents.filter((item) => item.types.includes("street_number"))[0].long_name;
  let routeData = googlecomponents.filter((item) => item.types.includes("route"))[0].long_name;

  let postal = googlecomponents.find((component) => component.types.includes("postal_code"))?.long_name ?? "";
  let postalSuff =
    googlecomponents.find((component) => component.types.includes("postal_code_suffix"))?.long_name ?? "";

  googlecomponents.forEach((item) => {
    let condition = item.types.includes("street_number") || item.types.includes("route");
    if (!condition) {
      if (item.long_name === item.short_name) {
        allComponents.push(item.short_name);
      } else {
        allComponents.push(item.long_name);
        allComponents.push(item.short_name);
      }
    }
  });

  mapboxcomponents.forEach((item) => {
    allComponents.push(item.text);
  });

  console.log(allComponents);

  let areasLSubs = areasLayer
    .filter((item) => allComponents.includes(item.lower_subareas))
    .map((item) => [item.lower_subareas, item.subareas, item.areas]);

  let areasSubs = areasLayer
    .filter((item) => allComponents.includes(item.subareas))
    .map((item) => [item.subareas, item.areas]);
  let areas = areasLayer.filter((item) => allComponents.includes(item.areas)).map((item) => item.areas);

  const neighborhoodComp = mapboxcomponents.find((component) => component.id.includes("neighborhood"));

  let finalComps = [...new Set([...allComponents, ...areasLSubs.flat(), ...areasSubs.flat(), ...areas])];

  let addressData = {
    full: address,
    geo: res[0].results[0].geometry.location,
    components: postalSuff
      ? [streetData + " " + routeData + " " + postal + "-" + postalSuff, ...finalComps]
      : [streetData + " " + routeData, ...finalComps],
    zip: postal,
    neighborhood: neighborhoodComp?.text ?? "",
  };

  return addressData;
}

export async function addZip(postal_code) {
  if (postal_code) {
    const docRef = doc(firestore, "zips", postal_code);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      let snap = await setDoc(doc(firestore, "zips", postal_code), {
        postal_code,
        created: new Date(),
      });
    }
  }
}
