import { Divider, Statistic } from "antd";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { searchObjects } from "src/algolia";
import Flexbox from "src/components/wrapper/Flexbox";
import { firestore } from "src/firebase";
import { Colors } from "src/theme/Colors";
import styles from "../../admin.module.scss";
import AdminLayout from "../../../../layouts/AuthLayout";
import useAreas from "src/hooks/data/useAreas";

const AdminDashboard = () => {
  const unmountedRef = useRef(false);

  const [adminData, setAdminData] = useState();
  const [allListingsCount, setAllListingsCount] = useState();
  const [allSalesListingsCount, setAllSalesListingsCount] = useState();
  const [allRentalListingsCount, setAllRentalListingsCount] = useState();

  const loadAllListingsCount = () => {
    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted").then((res) => {
      setAllListingsCount(res.nbHits);
    });

    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted AND type:sale").then((res) => {
      setAllSalesListingsCount(res.nbHits);
    });

    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted AND type:rental").then((res) => {
      setAllRentalListingsCount(res.nbHits);
    });
  };

  useEffect(() => {
    unmountedRef.current = false;
    loadAllListingsCount();
    const adminSnapshotListner = onSnapshot(doc(firestore, `configs/admin`), (docSnapshot) => {
      if (!unmountedRef.current) {
        setAdminData(docSnapshot.data());
      }
    });
    return () => {
      unmountedRef.current = true;
      adminSnapshotListner();
    };
  }, []);

  const userDetails = [
    {
      path: "/admin/customers",
      title: "All Customers",
      count: adminData?.customerTotal,
    },
    {
      path: "/admin/agencies",
      title: "Brokers",
      count: adminData?.brokerTotal,
    },
    {
      path: "/admin/management-companies",
      title: "Management Companies",
      count: adminData?.managementTotal,
    },
    {
      path: "#",
      title: "Associate Brokers",
      count: adminData?.agentTotal,
    },
    {
      path: "#",
      title: "Licensed Real Estate Sales Persons",
      count: adminData?.salespersonTotal,
    },
  ];

  const listingInfos = [
    {
      path: "/admin/listings?type=sale",
      title: "Sale Listings",
      count: allSalesListingsCount,
    },
    {
      path: "/admin/listings?type=rental",
      title: "Rental Listings",
      count: allRentalListingsCount,
    },
  ];

  return (
    <AdminLayout title={"Dashboard"}>
      <>
        <Flexbox row className={styles.contentContainer}>
          {userDetails.map((item, index) => (
            <Link key={index} to={item.path} className={styles.contentItem}>
              <Statistic
                title={item.title.toUpperCase()}
                value={item.count}
                valueStyle={{ color: Colors.primary, fontSize: 40 }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column-reverse",
                }}
              />
            </Link>
          ))}
        </Flexbox>

        <Divider />

        <Flexbox row className={styles.contentContainer}>
          {listingInfos.map((item, index) => (
            <Link key={index} to={item.path} className={styles.contentItem}>
              <Statistic
                title={item.title.toUpperCase()}
                value={item.count}
                valueStyle={{ color: Colors.primary, fontSize: 40 }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column-reverse",
                }}
              />
            </Link>
          ))}
        </Flexbox>
      </>
    </AdminLayout>
  );
};

export default AdminDashboard;
