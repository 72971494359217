import { useParams } from "react-router-dom"

const StripeAccountReturn = () => {
  const params = useParams()

  console.log("return with params => ", params)

  /*
  {}{}
  */

  return (
    <div />
  )
}

export default StripeAccountReturn