import React from "react";
import HomeBlueCopyRightFooter from "src/components/home/HomeBlueCopyRightFooter";
import HomeBlueMenuBar from "src/components/home/HomeBlueMenuBar";
import HomeBuildProfilePanel from "src/components/home/HomeBuildProfilePanel";
import HomeDownloadAppPanel from "src/components/home/HomeDownloadAppPanel";
import HomeFooter from "src/components/home/HomeFooter";
import HomeHeaderDesktop from "src/components/home/HomeHeaderDesktop";
import HomeNeighborhoodPanel from "src/components/home/HomeNeighborhoodPanel";
import HomeNeighbourhoodPanel from "src/components/home/HomeNeighbourhoodPanel";
import HomePanel1 from "src/components/home/HomePanel1";
import HomePanel2 from "src/components/home/HomePanel2";
import HomeRecommendedListings from "src/components/home/HomeRecommendedListings";
import HomeSocialConnectPanel from "src/components/home/HomeSocialConnectPanel";
import HomeSpecialActionsPanel from "src/components/home/HomeSpecialActionsPanel";
import HomeOtherLinks from "src/components/home/HomeOtherLinks";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { storeSearchObject } from "src/redux/search/actions";
import { selectSearchObject } from "src/redux/search/selectors";
import { get } from "lodash";

const Home = () => {
  const dispatch = useDispatch();
  const currentSearchObject = useSelector(selectSearchObject);

  const { isMobile } = useMediaQuery();
  React.useEffect(() => {
    dispatch(
      storeSearchObject({
        type: get(currentSearchObject, "type", "rental"),
        price: {},
        query: [],
      })
    );
  }, []);

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {!isMobile && <HomeBlueMenuBar />}
          <HomePanel1 />
          <HomeNeighborhoodPanel />
          <HomePanel2 />
          {/* <HomeSearchPanel />
          <HomeRecommendedListings />
          <HomeSpecialActionsPanel />
          <HomeBuildProfilePanel />
          <HomeNeighbourhoodPanel />
          <HomeDownloadAppPanel />
          <HomeDownloadAppPanel /> */}
          <HomeSocialConnectPanel />
          <HomeFooter isHome={true} />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  );
};

export default Home;
