import { doc, addDoc, collection, updateDoc } from "firebase/firestore"
import { firestore } from "src/firebase"

export const createNewListing = async (
  userId,
  type,
  address,
  lat,
  lng,
  unit,
  addressComponents,
  agency,
  userIsManagement = false,
) => {

  try {
    const docRef = await addDoc(collection(firestore, "listing"), {
      type,
      address: {
        full: address,
        geo: {
          lat,
          lng
        },
        ...addressComponents
      },
      unit,
      by: userId,
      created: new Date().getTime(),
      agency,
      managing: userIsManagement,
      status: "Draft",
      team: [userId]
    });
    
    const docId = docRef.id

    updateDoc(doc(firestore, `listing/${docId}`), {
      id: docId
    })

    return docId
  } catch (err) {
    console.log("Error adding", err)
    throw err
  }  
}

export const updateListing = (id, data, isDraft = true) => {
  if (isDraft) {
    
  }
  return updateDoc(doc(firestore, `listing/${id}`), data)    
}
