import { useEffect, useMemo, useState } from "react"
import { calculateNetEffectiveRents } from "src/utils/calculators"
import OptionSelectButton from "../button/OptionSelectButton"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"
import TextInput from "../input/TextInput"

const NetEffectiveRentPanel = ({
  highlightErrorFields,
  grossRent,
  listing,
  style,
  onUpdateUnitInfoFields
}) => {

  const { isMobile } = useMediaQuery()

  const monthsFreeOptions = [1, 2, 3, 4, 5]
  const leaseTerms = [12, 13, 14, 18, 24]

  const [monthsFree, setMonthsFree] = useState(0)
  const [leaseTerm, setLeaseTerm] = useState(0)

  const [manualMonthsFree, setManualMonthsFree] = useState()
  const [manualLeaseTerm, setManualLeaseTerm] = useState()

  const netEffectiveRent = useMemo(() => {
    if (grossRent) {

      if (monthsFree === -1) {
        if (manualMonthsFree === '') {
          return undefined
        }

        if (Number(manualMonthsFree) === NaN) {
          return undefined
        }
      }

      if (leaseTerm === -1) {
        if (manualLeaseTerm === '') {
          return undefined
        }

        if (Number(manualLeaseTerm) === NaN || Number(manualLeaseTerm) === 0) {
          return undefined
        }
      }

      return calculateNetEffectiveRents({
        grossRent: grossRent,
        leaseTerm: (leaseTerm >= 0 && leaseTerm < leaseTerms.length) ? leaseTerms[leaseTerm] : Number(manualLeaseTerm),
        monthsFree: monthsFree >= 0 ? monthsFreeOptions[monthsFree] : Number(manualMonthsFree)
      })
    }
  }, [monthsFree, leaseTerm, grossRent, manualMonthsFree, manualLeaseTerm])

  useEffect(() => {
    if (listing?.unitInfo) {
      const months = listing.unitInfo.monthsFree
      if (monthsFreeOptions.includes(months)) {
        setMonthsFree((listing.unitInfo.monthsFree ?? 0) - 1)
      } else {
        setMonthsFree(-1)
        setManualMonthsFree(months === undefined ? "" : `${months}`)
      }

      const term = listing.unitInfo.leaseTerm
      if (term > 0) {
        if (leaseTerms.includes(term)) {
          setLeaseTerm(leaseTerms.findIndex(v => v === Number(term)))
        } else {
          setLeaseTerm(-1)
          setManualLeaseTerm(`${term}`)
        }
      }
    }
  }, [listing])

  return (
    <Flexbox style={{...styles.container, ...style, width: isMobile ? 'calc(100% - 40px)' : 600}}>
      <TypeFace bold size={12} color={highlightErrorFields && monthsFree === -1 ? 'red' : 'black'}>
        MONTHS FREE
      </TypeFace>
      <Flexbox row style={{ alignItems: 'center', marginTop: 5 }}>
        <OptionSelectButton
          options={monthsFreeOptions}
          selected={monthsFree}
          onSelected={selected => {
            setManualMonthsFree("")
            onUpdateUnitInfoFields({
              'monthsFree': monthsFreeOptions[selected]
            })
            setMonthsFree(selected)
          }}
        />
        <TextInput 
          style={styles.manualInput}
          placeholder='Enter manually'
          value={manualMonthsFree}
          onChange={e => {
            const manualValue = e.target.value
            if (manualValue !== "") {
              setMonthsFree(-1)
              setManualMonthsFree(e.target.value)

              if (!manualValue.endsWith('.') && Number(manualValue) !== NaN) {
                onUpdateUnitInfoFields({
                  'monthsFree': Number(manualValue)
                })
              }
            } else {
              setManualMonthsFree("")
            }
          }}
        />
      </Flexbox>
      <TypeFace bold size={12} style={{ marginTop: 30}} color={highlightErrorFields && leaseTerm === undefined ? 'red' : 'black'}>
        LEASE TERM(IN MONTHS)
      </TypeFace>
      <Flexbox row style={{ marginTop: 5 }}>
        <OptionSelectButton
          options={leaseTerms}
          selected={leaseTerm}
          onSelected={selected => {
            setManualLeaseTerm('')
            onUpdateUnitInfoFields({
              'leaseTerm': leaseTerms[selected]
            })
            setLeaseTerm(selected)
          }}
        />
        <TextInput 
          style={styles.manualInput}
          placeholder='Enter manually'
          value={manualLeaseTerm}
          onChange={e => {
            const manualValue = e.target.value
            if (manualValue !== "") {
              setLeaseTerm(-1)
              setManualLeaseTerm(e.target.value)

              if (!manualValue.endsWith('.') && Number(manualValue) !== NaN) {
                onUpdateUnitInfoFields({
                  'leaseTerm': Number(manualValue)
                })
              }
            } else {
              setManualLeaseTerm("")
            }
          }}
        />
      </Flexbox>
      
      <TypeFace bold size={12} style={{ marginTop: 30}} color={highlightErrorFields && !netEffectiveRent ? 'red' : 'black'}>
        NET EFFECTIVE RENT
      </TypeFace>
      <Flexbox style={styles.netEffectiveRentValue}>
        <TypeFace>
          ${netEffectiveRent}
        </TypeFace>
      </Flexbox>
    </Flexbox>
  )
}

export default NetEffectiveRentPanel

const styles = {
  container: {
    width: 600,
    alignItems: 'flex-start',
    backgroundColor: "#EFEFEF",
    padding: 20,
  },
  netEffectiveRentValue: {
    width: 240,
    height: 40,
    marginTop: 20,
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 20px',
    border: '1px solid #777'
  },
  manualInput: {
    width: 200,
    height: 40,
    borderRadius: 0,
    marginLeft: 20
  },
}