import { useEffect, useMemo, useRef, useState } from "react";
// import Button from "src/components/button/Button";
import AddressUnitInputModal from "src/components/listing/AddressUnitInputModal";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { useAlert } from "src/hooks/alert/AlertContext";
import MyDraftProperties from "src/components/properties/MyDraftProperties";
import MyActivePropertyCell from "src/components/properties/MyActivePropertyCell";
import useMediaQuery from "src/hooks/useMediaQuery";
import { UserRoleValue } from "src/model/types";
import { Colors } from "src/theme/Colors";
import AuthLayout from "src/layouts/AuthLayout";
import { Button, Empty, Flex, Tabs } from "antd";

const MyProperties = () => {
  const { userProfile, myAgency } = useFirestore();
  const { isMobile } = useMediaQuery();

  const { showAlert } = useAlert();

  const [section, setSection] = useState(0);
  const [createModalOpening, setCreateModelOpening] = useState(false);

  const [myListings, setMyListings] = useState([]);

  const unsubscribeRef = useRef();

  const handleCreate = () => {
    if (userProfile.profileBlocked) {
      showAlert(true, "Your profile is under review, contact support@listmeet.com to reinstate your profile");
      return;
    }

    if (userProfile.role === UserRoleValue.ManageMent) {
      if (userProfile.profileStatus !== "approved") {
        showAlert(true, "Listmeet has not approved your company yet.");
      } else {
        setCreateModelOpening(true);
      }
    } else {
      if (userProfile.agencyApproved === false) {
        showAlert(
          true,
          userProfile.role === UserRoleValue.Broker
            ? "Listmeet has not approved your brokerage yet."
            : "Your agency license has not been approved, yet"
        );
      } else if (userProfile.suspended === true) {
        showAlert(
          true,
          userProfile.role === UserRoleValue.Broker
            ? "Your brokerage has been suspended."
            : "Contact your broker to reinstate your account."
        );
      } else {
        if (myAgency) {
          setCreateModelOpening(true);
        } else {
          showAlert(true, "Please set up agency first");
        }
      }
    }
  };

  const listingCount = () => {
    let count = {
      sale: 0,
      rental: 0,
      sold: 0,
      rented: 0,
    };
    myListings.forEach((listing) => {
      if (listing.type === "sale" && listing.agency === userProfile?.agency) {
        if (listing.status === "Sold") count.sold = count.sold + 1;
        if (listing.status === "Active") count.sale = count.sale + 1;
      }
      if (listing.type === "rental" && listing.agency === userProfile?.agency) {
        if (listing.status === "Rented") count.rented = count.rented + 1;
        if (listing.status === "Active") count.rental = count.rental + 1;
      }
    });

    return count;
  };

  const draftListings = useMemo(() => {
    return myListings?.filter((listing) => {
      switch (section) {
        case 0:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.status === "Draft" &&
            listing.type === "sale"
          );
        case 1:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.status === "Draft" &&
            listing.type === "rental"
          );
        default:
          return false;
      }
    });
  }, [myListings, section]);

  const activeListings = useMemo(() => {
    return myListings?.filter((listing) => {
      switch (section) {
        case 0:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.type === "sale" &&
            listing.status === "Active"
          );
        case 1:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.type === "rental" &&
            listing.status === "Active"
          );
        case 2:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.status === "Sold" &&
            listing.type === "sale"
          );
        case 3:
          return (
            (listing.managing || listing.agency === userProfile?.agency) &&
            listing.status === "Rented" &&
            listing.type === "rental"
          );
        default:
          return !listing.managing && listing.status === "Active" && listing.agency !== userProfile?.agency;
      }
    });
  }, [myListings, section, myAgency]);

  const otherListings = useMemo(() => {
    return myListings?.filter((listing) => {
      if (listing.managing || listing.agency === userProfile?.agency) {
        switch (section) {
          case 0:
            return (
              listing.type === "sale" &&
              listing.status !== "Active" &&
              listing.status !== "Draft" &&
              listing.status !== "Sold"
            );
          case 1:
            return (
              listing.type === "rental" &&
              listing.status !== "Active" &&
              listing.status !== "Draft" &&
              listing.status !== "Rented"
            );
          default:
            return (
              !listing.managing &&
              listing.status !== "Active" &&
              listing.status !== "Draft" &&
              listing.agency !== userProfile?.agency
            );
        }
      } else {
        return false;
      }

      if (section === 2) {
        return (
          !listing.managing &&
          listing.status !== "Active" &&
          listing.status !== "Draft" &&
          listing.agency !== userProfile?.agency
        );
      } else {
        if (listing.managing) {
          if (listing.status !== "Active" && listing.status !== "Draft") {
            if (section === 0) {
              return listing.type === "sale";
            } else {
              return listing.type === "rental";
            }
          } else {
            return false;
          }
        } else {
          if (listing.agency !== userProfile?.agency) {
            return false;
          }
          return (
            listing.status !== "Active" &&
            listing.status !== "Draft" &&
            ((section === 0 && listing.type === "sale") || (section === 1 && listing.type === "rental"))
          );
        }
      }
    });
  }, [myListings, section, myAgency]);

  function getActiveListingsCount() {
    switch (section) {
      case 0:
        return `${activeListings.length} Active Sales`;
      case 1:
        return `${activeListings.length} Active Rentals`;
      case 2:
        return `${activeListings.length} Sold Listings`;
      case 3:
        return `${activeListings.length} Rented Listings`;
      default:
        return "";
    }
  }

  function getActiveListingsText() {
    if (activeListings.length === 0) {
      return (
        <Flex justify="center" align="center" style={{ width: "100%" }}>
          <Empty description="No Listings Found" />
        </Flex>
      );
    } else {
      return (
        <TypeFace bold size={14}>
          {getActiveListingsCount()}
        </TypeFace>
      );
    }
  }

  useEffect(() => {
    if (userProfile?.userId) {
      const q = query(collection(firestore, "listing"), where("team", "array-contains", userProfile.userId));
      unsubscribeRef.current = onSnapshot(q, (querySnapshot) => {
        let listings = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status !== "Deleted") {
            listings.push(data);
          }
        });
        setMyListings(listings);
      });
    }
  }, [userProfile]);

  useEffect(() => {
    return () => {
      unsubscribeRef.current?.();
    };
  }, []);

  const headerRight = (
    <Button type="primary" onClick={handleCreate} primary style={styles.createButton}>
      {section === 0 && "Create New Sale"}
      {section === 1 && "Create New Rental"}
    </Button>
  );

  const Content = (
    <>
      {(draftListings?.length ?? 0) > 0 && <MyDraftProperties section={section} draftListings={draftListings} />}
      <Flex align="flex-start" justify="flex-start" style={{ marginBottom: 10 }}>
        {getActiveListingsText()}
      </Flex>

      <Flexbox style={styles.contentRow}>
        {activeListings?.map((listing, index) => (
          <MyActivePropertyCell listing={listing} key={`active-${index}-${listing.id}`} editAvailable={true} />
        ))}
      </Flexbox>

      {(otherListings?.length ?? 0) > 0 && (
        <>
          <TypeFace>
            {(otherListings?.length ?? 0) === 0 ? "No" : `${otherListings.length}`} other{" "}
            {section === 0 ? "sale" : "rental"}
            {otherListings?.length > 1 ? "s" : ""}
          </TypeFace>

          <Flexbox style={styles.contentRow}>
            {otherListings?.map((listing, index) => (
              <MyActivePropertyCell listing={listing} key={`active-${index}-${listing.id}`} editAvailable={true} />
            ))}
          </Flexbox>
        </>
      )}
    </>
  );

  const items = [
    {
      key: "0",
      label: `Sales ${listingCount.sale > 0 ? `(${listingCount.sale})` : ""}`,
      children: Content,
    },
    {
      key: "1",
      label: `Rentals ${listingCount.rental > 0 ? `(${listingCount.rental})` : ""}`,
      children: Content,
    },
    {
      key: "2",
      label: `Sold ${listingCount.sold > 0 ? `(${listingCount.sold})` : ""}`,
      children: Content,
    },
    {
      key: "3",
      label: `Rented ${listingCount.rented > 0 ? `(${listingCount.rented})` : ""}`,
      children: Content,
    },
  ];

  console.log(listingCount());

  return (
    <AuthLayout title="My Properties" gap={5} headerRight={headerRight}>
      <Flex style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey="0"
          items={items}
          onChange={(key) => setSection(Number(key))}
          style={{ width: "100%" }}
        />
      </Flex>

      <AddressUnitInputModal
        type={section === 0 ? "sale" : "rental"}
        modalIsOpen={createModalOpening}
        closeModal={() => setCreateModelOpening(false)}
      />
    </AuthLayout>
  );
};

export default MyProperties;

const styles = {
  tabBarSection: {
    width: "100%",
  },
  sectionButtons: {
    height: 40,
    cursor: "pointer",
  },
  sectionButtonItem: {
    width: 120,
    height: 40,
    justifyContent: "center",
    alignItems: "text",
    cursor: "pointer",
  },
  vertSeparator: {
    backgroundColor: "#dfdfdf",
    width: 1,
    height: "100%",
  },
  tabBarItemText: {
    cursor: "pointer",
  },
  // createButton: {
  //   height: 40,
  //   borderRadius: 0,
  // },
  horSeparator: {
    backgroundColor: "#efefef",
    width: "100%",
    height: 1,
  },
  contentRow: {
    width: "100%",
  },
  noDataText: {
    alignSelf: "center",
    marginTop: 40,
    textAlign: "center",
  },
};
