import useMediaQuery from "src/hooks/useMediaQuery"
import Flexbox from "../wrapper/Flexbox"

const UploadingPhotoThumb = (props) => {

  const { isMobile } = useMediaQuery()

  return (
    <Flexbox style={{
      ...styles.container,
      width: isMobile ? 120 : 150,
      marginRight: isMobile ? 10 : 20,
    }}>
      <Flexbox style={styles.photoContainer}>
        {props.file && (
          <img
            src={URL.createObjectURL(props.file)}
            width={150}
            height={100}
          />
        )}
      </Flexbox>
      <Flexbox row style={styles.infoPanel}>
        {/* <TypeFace bold size={11} style={{ marginLeft: 10 }}>
          {props.index}
        </TypeFace>
        <div style={{ flex: 1 }} />
        <Flexbox onClick={handleDelete} row style={styles.deleteButton}>
          <img src={IconDelete} width={12} height={12} />
        </Flexbox> */}
      </Flexbox>
      <Flexbox style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#0008',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Flexbox style={{
          width: '80%',
          height: 10,
          borderRadius: 10,
          backgroundColor: '#bcbcbc',
          alignItems: 'flex-start'
        }}>
          <Flexbox style={{
            width: `${props.percent}%`,
            height: 10,
            borderRadius: 10,
            backgroundColor: '#00f'
          }}>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default UploadingPhotoThumb

const styles = {
  container: {
    width: 150,
    height: 130,
    marginTop: 20,
    marginRight: 20,
    boxShadow: '0 0 3px #3151B73F',
    position: 'relative',
  },
  photoContainer: {
    width: 150,
    height: 100
  },
  infoPanel: {
    width: '100%',
    height: 30,
  },
  deleteButton: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 10,
  }
}