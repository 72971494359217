import useMediaQuery from "src/hooks/useMediaQuery"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import IconDelete from "src/assets/images/icons/icon-delete.svg"

const PhotoThumbInListing = (props) => {

  const { isMobile } = useMediaQuery()

  const handleDelete = () => {
    props.onDeletePhoto?.(props.photoId)
  }

  return (
    <Flexbox style={{
      ...styles.container,
      marginRight: isMobile ? 16 : 20,
    }}>
      <Flexbox style={styles.photoContainer}>
        <img
          src={props.uri ? props.uri : props.file ? URL.createObjectURL(props.file) : ""}
          width={150}
          height={100}
        />
      </Flexbox>
      <Flexbox row style={styles.infoPanel}>
        <TypeFace bold size={11} style={{ marginLeft: 10 }}>
          {props.index}
        </TypeFace>
        <div style={{ flex: 1 }} />
        <Flexbox onClick={handleDelete} row style={styles.deleteButton}>
          <img src={IconDelete} width={12} height={12} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default PhotoThumbInListing

const styles = {
  container: {
    width: 150,
    height: 130,
    marginTop: 20,
    marginRight: 20,
    boxShadow: '0 0 3px #3151B73F',
  },
  photoContainer: {
    width: 150,
    height: 100
  },
  infoPanel: {
    width: '100%',
    height: 30,
  },
  deleteButton: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 10,
  }
}