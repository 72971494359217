import { collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import TypeFace from "src/components/typography/Typefaces";
import { firestore } from "src/firebase";
import { priceFormat } from "src/utils/strings";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Flex, Table, Typography } from "antd";
import styles from "../../admin.module.scss";

const { Text } = Typography;

const AdminPayments = () => {
  const unmountedRef = useRef(false);
  const [completedPayments, setCompletedPayments] = useState();

  const totalFeesCollected = useMemo(() => {
    if (completedPayments) {
      return completedPayments.reduce((acc, cur) => {
        if (cur.fee) {
          return acc + cur.fee;
        } else {
          return acc + Math.round(cur.amount * 0.15 * 100) / 100;
        }
      }, 0);
    } else {
      return 0;
    }
  }, [completedPayments]);

  useEffect(() => {
    unmountedRef.current = false;
    const listener = onSnapshot(
      query(collection(firestore, "payments"), where("status", "==", "completed"), orderBy("created", "desc")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          console.log(d);
          setCompletedPayments(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      listener();
    };
  }, []);

  let columns = [
    {
      title: "Type",
      dataIndex: "type",
      render: (_, item) => <Text>{item.type ?? "Commission"}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, item) => <Text>${priceFormat(item.amount / 100)}</Text>,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      render: (_, item) => (
        <Text>${item.fee ? priceFormat(item.fee / 100) : priceFormat((item.amount * 0.15) / 100)}</Text>
      ),
    },
    {
      title: "Paid By",
      dataIndex: "paidBy",
      render: (_, item) => <Text>{item.paidBy ?? "Justin"}</Text>,
    },
    {
      title: "Paid To",
      dataIndex: "paidTo",
      render: (_, item) => <Text>{item.paidTo ?? "ListMeet LLC"}</Text>,
    },
    {
      title: "Paid At",
      dataIndex: "created",
      key: "created",
      render: (_, record) => moment(record.created).format("MMM DD, YYYY"),
    },
  ];

  const expandable = {
    expandedRowRender: (review) => {
      return (
        <Flex vertical gap={10} style={{ width: "50%" }}>
          <Text strong>Comments:</Text>
          <Text>{review.message}</Text>
        </Flex>
      );
    },
  };

  return (
    <AdminLayout
      title="Payments"
      headerRight={
        <TypeFace>
          Total fees collected: <TypeFace bold>${priceFormat(totalFeesCollected / 100)}</TypeFace>
        </TypeFace>
      }
    >
      <Table
        expandable={expandable}
        dataSource={completedPayments}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
      />
    </AdminLayout>
  );
};

export default AdminPayments;
