import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { firestore } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import MessageRoom from "./MessageRoom";
import MessageThreadCell from "./MessageThreadCell";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useNavigate, useParams } from "react-router";
import { userRoles, UserRoleValue } from "src/model/types";
import { Colors } from "src/theme/Colors";
import AuthLayout from "src/layouts/AuthLayout";

const Messages = () => {
  const { userProfile, myAgency } = useFirestore();
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const { messageId } = useParams();

  const messageThreadsSnapshotLisner = useRef();
  const unmountedRef = useRef(false);

  const [noMessage, setNoMessage] = useState(false);
  const [messageThreads, setMessageThreads] = useState();
  const [selectedMessageThreadId, setSelectedMessageThreadId] = useState();
  const [mobileOpenedMessageThread, setMobileOpenedMessageThread] = useState();
  const [mobileOpenedMessageThreadName, setMobileOpenedMessageThreadName] = useState();

  const loadUserMessagesList = () => {
    messageThreadsSnapshotLisner.current?.();
    const q = query(
      collection(firestore, "messages"),
      where("senders", "array-contains", userProfile.userId),
      orderBy("lm.at", "desc")
    );
    messageThreadsSnapshotLisner.current = onSnapshot(q, (docsSnapshot) => {
      let messages = [];
      docsSnapshot.forEach((doc) => {
        const msg = doc.data();
        if (!msg.deleted?.[userProfile.userId]) {
          messages.push({
            ...msg,
            id: doc.id,
          });
        }
      });
      if (!unmountedRef.current) {
        // if (selectedMessageThreadId === undefined && messageId === undefined) {
        //   setSelectedMessageThreadId(messages[0].id)
        // }
        if (messages.length === 0) {
          setNoMessage(true);
        } else {
          setNoMessage(false);
        }
        setMessageThreads(messages);
      }
    });
  };

  const myAgencyMessages = useMemo(() => {
    return messageThreads?.filter((thread) => {
      if (userProfile.role === UserRoleValue.ManageMent) return thread.agency === userProfile.userId;
      else return thread.agency === userProfile?.agency;
    });
  }, [messageThreads, myAgency]);

  useEffect(() => {
    if (messageId) {
      setSelectedMessageThreadId(messageId);
    }
    if (!messageId && messageThreads?.length > 0) {
      navigate(`/manage/messages/${messageThreads[0]?.id}`);
    }
  }, [messageId, messageThreads]);

  useEffect(() => {
    if (userProfile?.userId) {
      loadUserMessagesList();
    }
  }, [userProfile?.userId]);

  useEffect(() => {
    if (mobileOpenedMessageThread?.senders) {
      let senderIds = mobileOpenedMessageThread.senders.filter((snder) => snder !== userProfile?.userId);
      const q = query(collection(firestore, "profiles"), where("userId", "in", senderIds));
      getDocs(q).then((docs) => {
        let users = [];
        docs.forEach((doc) => {
          users.push(doc.data());
        });
        if (users) {
          setMobileOpenedMessageThreadName(users.map((profile) => profile.name).join(","));
        }
      });
    }
  }, [mobileOpenedMessageThread?.senders, userProfile?.userId]);

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      messageThreadsSnapshotLisner.current?.();
      unmountedRef.current = true;
    };
  }, []);

  const renderNoMessagesUI = () => {
    return (
      <TypeFace semiBold style={styles.noMessagesText} color={Colors.gray1}>
        No Messages
      </TypeFace>
    );
  };
  const renderMessagesUI = () => {
    return (
      ///

      <Flexbox row style={styles.content}>
        <div
          style={{
            ...styles.threadListDiv,
            width: isMobile ? "100%" : 300,
          }}
        >
          {myAgencyMessages?.map((messageThread, index) => (
            <MessageThreadCell
              key={`thread-${index}`}
              messageThread={messageThread}
              selected={!isMobile && messageThread.id === selectedMessageThreadId}
              onSelect={(thread) => {
                // setSelectedMessageThreadId(thread.id)
                if (!selectedMessageThreadId) {
                  setSelectedMessageThreadId(thread.id);
                } else {
                  navigate(`/manage/messages/${thread.id}`);
                }
                setMobileOpenedMessageThread(thread);
              }}
              onDeleted={(messageThreadId) => {
                if (selectedMessageThreadId === messageThreadId) {
                  setSelectedMessageThreadId();
                }
              }}
            />
          ))}
        </div>
        <div style={styles.midDivider} />
        {!isMobile ? (
          <Flexbox style={styles.roomContainer}>
            {selectedMessageThreadId && <MessageRoom threadId={selectedMessageThreadId} />}
          </Flexbox>
        ) : (
          <>
            {mobileOpenedMessageThread && (
              <div style={{ position: "absolute", width: "100%", backgroundColor: "white", height: "100%" }}>
                <MessageRoom threadId={mobileOpenedMessageThread.id} />
              </div>
            )}
          </>
        )}
      </Flexbox>
    );
  };
  const renderScreenUI = () => {
    if (userProfile?.profileBlocked) {
      <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
        Your profile is under review, contact support@listmeet.com to reinstate your profile.
      </TypeFace>;
    } else {
      if (userProfile?.role === UserRoleValue.ManageMent) {
        if (userProfile.profileStatus !== "approved") {
          <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
            Listmeet has not approved your company yet.
          </TypeFace>;
        } else {
          return noMessage === true ? renderNoMessagesUI() : renderMessagesUI();
        }
      } else {
        if (userProfile?.agencyApproved === false) {
          if (userProfile?.role === UserRoleValue.Broker) {
            <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
              Listmeet has not approved your brokerage yet.
            </TypeFace>;
          } else {
            <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
              Sign up with a brokerage in your "My Profile" section before using ListMeet.
            </TypeFace>;
          }
        } else if (userProfile?.suspended === true) {
          if (userProfile?.role === UserRoleValue.Broker) {
            <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
              Your brokerage has been suspended.
            </TypeFace>;
          } else {
            <TypeFace style={styles.noMessagesText} color={Colors.gray1}>
              Contact your broker to reinstate your account.
            </TypeFace>;
          }
        } else {
          return noMessage === true ? renderNoMessagesUI() : renderMessagesUI();
        }
      }
    }
  };
  const title = isMobile
    ? mobileOpenedMessageThread !== undefined
      ? mobileOpenedMessageThreadName ?? ""
      : "Messages"
    : "Messages";
  return (
    <AuthLayout title={title} gap={0}>
      <Flexbox style={{ width: "100%" }}>
        <div style={{ flex: 1 }} />
        {isMobile && mobileOpenedMessageThread !== undefined && (
          <TypeFace onClick={() => setMobileOpenedMessageThread()} size={12}>
            Back to List
          </TypeFace>
        )}
      </Flexbox>
      {renderScreenUI()}
    </AuthLayout>
  );
};

export default Messages;

const styles = {
  container: {
    width: "100%",
    alignItems: "flex-start",
    height: "calc(100% - 20px)",
    paddingBottom: 20,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 190px)",
    alignItems: "flex-start",
    marginTop: 20,
    border: "1px solid #EFEFEF",
    position: "relative",
  },
  threadListDiv: {
    width: 300,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    overflow: "scroll",
  },
  midDivider: {
    width: 1,
    height: "100%",
    backgroundColor: "#EFEFEF",
  },
  roomContainer: {
    flex: 1,
    height: "100%",
  },
  noMessagesText: {
    alignSelf: "center",
    marginTop: 40,
    textAlign: "center",
  },
};
