import React from 'react'
import useMediaQuery from 'src/hooks/useMediaQuery'
import TypeFace from '../typography/Typefaces'
import Flexbox from '../wrapper/Flexbox'

const HomeDownloadAppPanel = ({ style, isModal }) => {

  const { isMobile } = useMediaQuery()

  return (
    <Flexbox style={{
      ...styles.container,
      flexDirection: isMobile ? 'column' : 'row',
      height: isMobile ? 'unset' : 425,
      gap: 40,
      paddingTop: isMobile ? 30 : 0,
      paddingBottom: isMobile ? 30 : 0,
      ...style
    }}>
      <Flexbox>
        {isModal ? (
          <>
            <TypeFace bold size={16} style={{ marginBottom: 4 }}>
              Let ListMeet be your companion on the go.
            </TypeFace>
            <TypeFace semiBold size={15}>
              Embrace effortless on demand showings with our “meet now” feature
            </TypeFace>
            <TypeFace semiBold color={'black'} size={14} style={{ marginTop: 20 }}>
              Download iOS or Android application
            </TypeFace>
          </>
        ) : (
          <>
            <TypeFace bold size={28}>
              Let ListMeet be your Companion<br />on the Go
            </TypeFace>
            <TypeFace semiBold color={'black'} size={20} style={{ marginTop: 20 }}>
              Download iOS or Android application
            </TypeFace>
          </>
        )}
      </Flexbox>
      <Flexbox>
        <img src={require('src/assets/images/appstore.png')} style={{ width: 204, height: 59, cursor: 'pointer' }} />
        <img src={require('src/assets/images/googleplay.png')} style={{ width: 204, height: 59, marginTop: 30, cursor: 'pointer' }} />
      </Flexbox>
      {!isMobile && (
        <img src={require('src/assets/images/img-iphonescreen.png')} style={{ width: 187, height: 332 }} />
      )}
    </Flexbox>
  )
}

export default HomeDownloadAppPanel

const styles = {
  container: {
    backgroundColor: 'white',
    width: '100%',
    height: 425,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCardStyle: {
    flex: 1,
    height: 320,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 50,
  },
}