import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Modal from 'react-modal';
import Button from 'src/components/button/Button';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import { firestore } from 'src/firebase';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';

const stripePromise = loadStripe(process.env.REACT_APP_stripe_api_key);

const CheckoutForm = ({
  paymentAmount
}) => {

  const stripe = useStripe();
  const elements = useElements();

  const [checkoutProcessing, setCheckoutProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting....")

    if (!stripe || !elements) {
      console.log("Stripe.js hasn't yet loaded.")
      return;
    }

    setCheckoutProcessing(true)

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://listmeet.com/payments/done',
      },
    });

    // redirecting to return_url
    // payment_intent=pi_3NE31NBQ5DH4L9u02EYu9Ycu
    //  &payment_intent_client_secret=pi_3NE31NBQ5DH4L9u02EYu9Ycu_secret_EBXbceWYMplCmpIMP5cTX21lO
    //  &redirect_status=succeeded

    if (error) {
      console.log("Pay error => ", error.message)
      setErrorMessage(error.message);
      setCheckoutProcessing(false)
    } else {
    }
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        disabled={!stripe || checkoutProcessing}
        primary
        style={{ width: '100%', marginTop: 20, height: 45, borderRadius: 5 }}
        onClick={handleSubmit}
      >
        {checkoutProcessing ? "Processing..." : `Pay $${paymentAmount}`}
      </Button>
    </form>
  );
};

const PaymentSheet = ({
  listingId,
  listingAgentId,
  listingAgencyName,
  messageThreadId,
  isOpened,
  onClose
}) => {

  const { userProfile } = useFirestore()

  const [payAmount, setPayAmount] = useState(0)
  const [inputValue, setInputValue] = useState(0)
  const [stripeOptions, setStripeOptions] = useState()
  const [processing, setProcessing] = useState(false)
  
  const afterOpenModal = () => {
    document.body.style.overflow = 'hidden'
    setPayAmount(0)
    setInputValue(0)
    setProcessing(false)
    setStripeOptions(undefined)
  }

  const closeModal = () => {
    document.body.style.overflow = 'auto'
    onClose()
  }

  const handleContinuePay = () => {
    setProcessing(true)
    processStripe(inputValue)
  }

  const processStripe = (amount) => {
    fetch('https://us-central1-listmeetdev.cloudfunctions.net/checkoutPaymentIntent', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount * 100,
        messageThreadId
      })
    })
    .then(response => response.json())
    .then(res => {

      setProcessing(false)

      const clientSecret = res.client_secret
      const intentId = res.payment_intent_id
      const intentAmount = res.amount

      setDoc(doc(firestore, `payments/${intentId}`), {
        listingId,
        listingAgentId,
        messageThreadId,
        customer: userProfile.userId,
        paidBy: userProfile.name,
        paidTo: listingAgencyName,
        intent: intentId,
        amount: intentAmount,
        type: 'Commission',
        status: 'incomplete',
        message: `${userProfile.name} paid commission to ${listingId}`,
        created: new Date().getTime(),
      })

      setPayAmount(amount)
      setStripeOptions({
        clientSecret,
        appearance: {
          theme: 'stripe'
        }
      })
    })
    .catch(err => {
      setProcessing(false)
    })
  }

  if (listingId === undefined || listingAgentId === undefined || messageThreadId === undefined) {
    return null
  }

  return (
    <Modal
      isOpen={isOpened}
      onRequestClose={closeModal}
      onAfterOpen={afterOpenModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={styles.container}>
        {payAmount === 0 && (
          <>
            <TypeFace>
              Please enter an amount to pay
            </TypeFace>
            <CurrencyInput
              prefix="$"
              allowNegativeValue={false}
              style={styles.priceInput}
              onValueChange={(value) => {
                setInputValue(value)
              }}
            />
            <Button primary disabled={processing || (inputValue > 0 ? false : true)} style={styles.continuePayButton} onClick={handleContinuePay}>
              {processing ? "Wait a moment..." : `Pay${inputValue > 0 ? ` $${inputValue}` : ""}`}
            </Button>
          </>
        )}
        {stripeOptions && (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <CheckoutForm paymentAmount={payAmount} />
          </Elements>
        )}
      </Flexbox>
    </Modal>
  )
}

export default PaymentSheet

const styles = {
  container: {
    width: 500,
    padding: 20,
    border: '1px solid #cfcfcf'
  },
  priceInput: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'OpenSans',
    fontWeight: 400,
    marginTop: 20,
  },
  continuePayButton: {
    marginTop: 30,
    height: 45,
    width: 300,
  }
}