import { useEffect, useMemo, useState } from "react"
import ReactSelect from "react-select/creatable"
import { components } from "react-select"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { priceFormat } from "src/utils/strings"
import useMediaQuery from "src/hooks/useMediaQuery"

const PriceRangeSelectBox = (props) => {

  const { isMobile } = useMediaQuery()

  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState()
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState()

  const pricesInSales = [
    200000, 210000, 220000, 230000, 240000, 250000, 275000, 300000, 325000, 350000, 375000, 400000, 425000, 450000, 475000,
    500000, 525000, 550000, 575000, 600000, 625000, 650000, 675000, 700000, 725000, 750000, 775000, 800000,825000, 850000, 875000,
    900000, 925000, 950000, 975000, 1000000,1100000, 1200000, 1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000,
    2000000, 2250000, 2500000, 2750000, 3000000, 3500000, 4000000, 4500000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000,
    12500000, 15000000, 17500000, 20000000, 22500000, 25000000
  ]

  const configurePricesInRents = () => {
    let values = []
    for (let val = 1000; val <= 10000; val += 100) {
      values.push(val)
    }

    for (let val = 10500; val <= 15000; val += 500) {
      values.push(val)
    }

    for (let val = 16000; val <= 20000; val += 1000) {
      values.push(val)
    }

    return values
  }

  const pricesInRents = configurePricesInRents()

  const Input = (props) => {
    if (props.isHidden) {
      return <components.Input {...props} />
    }

    return (
      <components.Input
        type="text"
        {...props}
        onKeyDown={(event) => {
          if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
    )
  }

  const minPriceOptions = useMemo(() => {
    if (!props.isForSale) {
      return [
        {value: 'custom', label: 'MIN'},
        ...pricesInRents.map(price => {
          return {
            label: `$${priceFormat(price)}`,
            value: price
          }
        })
      ]
    } else {
      return [
        {value: 'custom', label: 'MIN'},
        ...pricesInSales.map(price => {
          return {
            label: `$${priceFormat(price)}`,
            value: price
          }
        })
      ]
    }
  }, [props.isForSale])

  const maxPriceOptions = useMemo(() => {
    if (!props.isForSale) {
      return [
        ...pricesInRents.map(price => {
          return {
            label: `$${priceFormat(price)}`,
            value: price
          }
        }),
        {value: 'custom', label: 'MAX'},
      ]
    } else {
      return [
        ...pricesInSales.map(price => {
          return {
            label: `$${priceFormat(price)}`,
            value: price
          }
        }),
        {value: 'custom', label: 'MAX'},
      ]
    }
  }, [props.isForSale])

  const selectedMinPrice = useMemo(() => {
    if (selectedMinPriceValue) {
      const option = minPriceOptions.find(val => val.value === selectedMinPriceValue)
      if (option) {
        return option
      } else {
        return {
          label: `$${selectedMinPriceValue}`,
          value: selectedMinPriceValue
        }
      }
    } else {
      return null
      // return {
      //   label: 'Min',
      //   value: 'custom'
      // }
    }
    
  }, [selectedMinPriceValue, minPriceOptions])

  const selectedMaxPrice = useMemo(() => {
    if (selectedMaxPriceValue) {
      const option = maxPriceOptions.find(val => val.value === selectedMaxPriceValue)
      if (option) {
        return option
      } else {
        return {
          label: `$${selectedMaxPriceValue}`,
          value: selectedMaxPriceValue
        }
      }
    } else {
      return  null
      // return {
      //   label: 'Max',
      //   value: 'custom'
      // }
    }
  }, [selectedMaxPriceValue, maxPriceOptions])

  useEffect(() => {
    setSelectedMinPriceValue(props.minValue)
  }, [props.minValue])

  useEffect(() => {
    setSelectedMaxPriceValue(props.maxValue)
  }, [props.maxValue])
  
  return (
    <Flexbox row style={{ gap: 5, width: isMobile ? '100%' : 'unset' }}>
      <Flexbox style={{ alignItems: 'flex-start', flex: isMobile ? 1 : 'unset' }}>
        <div style={{
          ...styles.minPriceOptions,
          width: isMobile ? '100%' : (props.optionWidth || 120),
        }}>
          <ReactSelect
            options={minPriceOptions}
            value={selectedMinPrice}
            components={{
              Input,
              DropdownIndicator: (props) => !isMobile ? null : (
                <components.DropdownIndicator {...props}>
                </components.DropdownIndicator>
              ),
              IndicatorSeparator:(props) => !isMobile ? null : (
                <components.IndicatorSeparator {...props}>
                </components.IndicatorSeparator>
              )
            }}
            placeholder='min'
            // defaultValue={{label: 'MIN', value: 'custom'}}
            styles={{
              control: (base) => ({
                ...base,
                border: 'none',
                borderRadius: 0
              }),
              container: (base) => ({
                ...base,
                border: '1px solid black',
                boxShadow: 'none'
              }),
              valueContainer:(base) => ({
                ...base,
                textAlign: isMobile ? 'left' : 'center',
              }),
              dropdownIndicator: base => ({
                ...base,
                color: 'black',
              }),
              indicatorSeparator: base => ({
                ...base,
                backgroundColor: 'black',
              }),
            }}
            getOptionLabel={(option) => {
              if (option.__isNew__) {
                return `${option.value}`
              } else {
                return option.label
              }
            }}
            onChange={newValue => {
              setSelectedMinPriceValue(`${newValue.value}`)
              props.onValueChanged?.(Number(newValue.value), selectedMaxPriceValue)
            }}
          />
        </div>
      </Flexbox>
      <TypeFace>
        ~
      </TypeFace>
      <Flexbox style={{ alignItems: 'flex-start', flex: isMobile ? 1 : 'unset' }}>
        <div style={{
          ...styles.maxPriceOptions,
          width: isMobile ? '100%' : (props.optionWidth || 120)
        }}>
          <ReactSelect
            options={maxPriceOptions}
            components={{
              Input,
              DropdownIndicator: (props) => !isMobile ? null : (
                <components.DropdownIndicator {...props}>
                </components.DropdownIndicator>
              ),
              IndicatorSeparator:(props) => !isMobile ? null : (
                <components.IndicatorSeparator {...props}>
                </components.IndicatorSeparator>
              )
            }}
            // defaultValue={{label: 'MAX', value: 'custom'}}
            placeholder="max"
            value={selectedMaxPrice}
            onChange={newValue => {
              setSelectedMaxPriceValue(`${newValue.value}`)
              props.onValueChanged?.(selectedMinPriceValue, Number(newValue.value))
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: 'none',
                borderRadius: 0
              }),
              container: (base) => ({
                ...base,
                border: '1px solid black',
                boxShadow: 'none'
              }),
              valueContainer:(base) => ({
                ...base,
                textAlign: isMobile ? 'left' : 'center',
              }),
              dropdownIndicator: base => ({
                ...base,
                color: 'black',
              }),
              indicatorSeparator: base => ({
                ...base,
                backgroundColor: 'black',
              }),
            }}
            getOptionLabel={(option) => {
              if (option.__isNew__) {
                return `${option.value}`
              } else {
                return option.label
              }
            }}
          />
        </div>
      </Flexbox>
    </Flexbox>
  )
}

export default PriceRangeSelectBox

const styles = {
  minPriceOptions: {
    width: 120, height: 40, marginTop: 5
  },
  maxPriceOptions: {
    width: 120, height: 40, marginTop: 5
  }
}