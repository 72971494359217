import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import TypeFace from "../typography/Typefaces";
import "./customstyles.css";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { selectSearchObject } from "src/redux/search/selectors";
import { searchProperties } from "src/algolia";
import Flexbox from "../wrapper/Flexbox";
import neighborhoodBackground from "src/assets/images/bg/bg-neighbourhood.jpg";
import CardSearchResultItemInHome from "./search/CardSearchResultItemInHome";
import Button from "../button/Button";
import { storeSearchObject } from "src/redux/search/actions";
import { useNavigate } from "react-router";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={`${className} slick-arrows`} style={style} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={`${className} slick-arrows`} style={style} onClick={onClick} />;
}

function SliderData(props) {
  const { recommendedListings, loading, isMobile } = props;
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: isMobile ? 1 : Math.min(recommendedListings.length, 3),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (loading) return <div>Loading...</div>;

  if (recommendedListings.length === 0) {
    return (
      <Flexbox style={{ height: 500, width: "100%", alignItems: "center", justifyContent: "center" }}>
        <TypeFace size={20} semiBold>
          No listings
        </TypeFace>
      </Flexbox>
    );
  }

  return (
    <div
      style={{
        width:
          !isMobile && recommendedListings.length > 0 && recommendedListings.length < 3
            ? 388 * recommendedListings.length
            : "100%",
        marginTop: 20,
        alignSelf: "flex-start",
      }}
    >
      <Slider {...settings}>
        {recommendedListings.map((data, index) => (
          <div key={`recommended-${index}`}>
            <CardSearchResultItemInHome hit={data} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

const HomeNeighborhoodPanel = () => {
  const { isMobile } = useMediaQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentSearchObject = useSelector(selectSearchObject);

  const [recommendedListings, setRecommendedListings] = useState([]);

  const [selectedArea, setSelectedArea] = useState({
    name: "Manhattan",
  });

  const [loading, setLoading] = useState(false);

  const presentingAreas = [
    {
      name: "Manhattan",
    },
    {
      name: "Brooklyn",
    },
    {
      name: "Queens",
    },
    {
      name: "Bronx",
    },
    {
      name: "Staten Island",
    },
    {
      name: "New York",
    },
    {
      name: "New Jersey",
    },
  ];

  async function getData() {
    setLoading(true);
    let data = await searchProperties("", {
      filters: `status:Active AND address.components: '${selectedArea.name}'`,
      hitsPerPage: 10,
    });
    setRecommendedListings(data.hits);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [selectedArea.name]);

  const handleExploreAll = () => {
    if (currentSearchObject?.type === "rental") {
      dispatch(
        storeSearchObject({
          type: "rental",
          price: {},
        })
      );
    } else {
      dispatch(
        storeSearchObject({
          type: "sale",
          price: {},
        })
      );
    }
    navigate("/search");
  };

  return (
    <Flexbox style={{ ...styles.container, height: isMobile ? "unset" : 912 }}>
      <Flexbox style={{ maxWidth: 1178, width: "90%", alignItems: "center" }}>
        <span style={{ ...styles.headerText, textAlign: isMobile ? "center" : "start" }}>
          Take the Best From Your Favorite Neighborhood
        </span>
        <span>Uncover A Variety of Neighborhoods. Find your Perfect Fit!</span>
        <Flexbox row style={styles.neighborhoodTabs}>
          {presentingAreas?.map((area) => (
            <Flexbox
              key={area.name}
              onClick={() => setSelectedArea(area)}
              style={{
                ...styles.areaTabItem,
                borderBottom: selectedArea?.name === area.name ? "4px solid #3151B7" : "4px solid transparent",
                marginRight: isMobile ? 0 : 0,
              }}
            >
              <TypeFace
                size={isMobile ? 14 : 18}
                bold
                style={{ cursor: "pointer", textTransform: "uppercase", minWidth: 160 }}
              >
                {area.name}
              </TypeFace>
            </Flexbox>
          ))}
        </Flexbox>
        <div style={{ width: "100%", height: 1, backgroundColor: "#D9D9D9" }} />
        <SliderData recommendedListings={recommendedListings} loading={loading} isMobile={isMobile} />
        <Button onClick={handleExploreAll} secondary style={{ marginTop: 40, height: 45 }} textSize={19}>
          Explore All
        </Button>
      </Flexbox>
    </Flexbox>
  );
};

export default HomeNeighborhoodPanel;

const styles = {
  container: {
    width: "100%",
    height: 912,
    backgroundImage: `url(${neighborhoodBackground})`,
  },
  headerText: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: "bold",
  },
  neighborhoodTabs: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    overflow: "auto",
  },
  areaTabItem: {
    height: 44,
    cursor: "pointer",
    justifyContent: "center",
    transition: "all ease-in-out 0.2s",
  },
};
