import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(process.env.REACT_APP_algolia_app_id, process.env.REACT_APP_algolia_write_api_key);
const algoliaIndex = algoliaClient.initIndex(process.env.REACT_APP_algolia_index);

export const testMethod = (object) => {
  algoliaIndex
    .saveObjects(object)
    .wait()
    .then((response) => {
      console.log(response);
      algoliaIndex
        .search("Fo")
        .then((objects) => {
          console.log(objects);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const saveAlgoliaObjects = (objects) => {
  return algoliaIndex.saveObjects(objects).wait();
};

export const saveAlgoliaObject = (object) => {
  console.log("this-final-object=", JSON.stringify(object));
  return algoliaIndex
    .saveObject(object, {
      autoGenerateObjectIDIfNotExist: true,
    })
    .wait();
};

export const searchObjectsAdmin = (query, filters, sortOption, page = 0, hitsPerPage = 10) => {
  const indexMap = {
    newest: "newest-dev",
    "price-high": "priceHighest-dev",
    "price-low": "priceLowest-dev",
  };

  const indexName = indexMap[sortOption] || "newest-dev";
  const replicaAlgoliaIndex = algoliaClient.initIndex(indexName);

  const params = {
    filters,
    page: page,
    hitsPerPage: hitsPerPage,
  };

  return replicaAlgoliaIndex.search(query, params);
};
export const searchObjects = (
  query,
  filters,
  sortOption,
  insideBoundingBox,
  userLocation,
  userRadius,
  page = 0,
  pageSize = 20000
) => {
  const indexName =
    sortOption === "newest" ? "newest-dev" : sortOption === "price-high" ? "priceHighest-dev" : "priceLowest-dev";

  const replicaAlgoliaIndex = algoliaClient.initIndex(indexName);

  let zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;

  const params = {
    filters,
  };
  if (zipRegex.test(query)) params.facetFilters = `address.zip:${query}`;

  if (userLocation) params.aroundLatLng = userLocation;
  if (userRadius) params.aroundRadius = userRadius;

  params.hitsPerPage = pageSize;
  params.page = page;
  // if (insideBoundingBox && insideBoundingBox.length > 0) {
  //   params.insideBoundingBox = insideBoundingBox.map((item) => parseFloat(item));
  // }

  return replicaAlgoliaIndex.search(query, params);
};

export const filterDataByUserId = (userId) => {
  console.log(userId);
  return algoliaIndex.search("", {
    filters: `creatorId:${userId} AND status:Active`,
  });
};

export const getAlgoliaObjects = (objectIDs) => {
  return algoliaIndex.getObjects(objectIDs);
};

export const deleteAlgoliaObject = (objectId) => {
  algoliaIndex.deleteObject(objectId);
};

export const updateAlogliaObject = (objectID, updateData) => {
  algoliaIndex.partialUpdateObject({
    ...updateData,
    objectID,
  });
};

function getIndex(sort) {
  switch (sort) {
    case "newest":
      return "newest-dev";
    case "price-high":
      return "priceHighest-dev";
    case "price-low":
      return "priceLowest-dev";
    default:
      return "newest-dev";
  }
}

// New Style
export const searchProperties = (query, params, sortOption = "newest") => {
  const indexName = getIndex(sortOption);
  const replicaAlgoliaIndex = algoliaClient.initIndex(indexName);
  return replicaAlgoliaIndex.search(query, params);
};
