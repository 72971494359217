import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where, deleteField, arrayUnion, arrayRemove } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { getFirestoreListingDoc } from 'src/data/dbWrappers'
import { firestore } from 'src/firebase'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import useMediaQuery from 'src/hooks/useMediaQuery'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'
import { sendPushNotificationForChat } from 'src/utils/strings';

const MyScheduleEntry = ({
  schedule,
  isPastSchedule,
}) => {

  const { isMobile } = useMediaQuery()
  const navigate = useNavigate()
  const { userProfile } = useFirestore()

  const [listing, setListing] = useState()

  const unmountedRef = useRef(false)

  const loadListing = (listingId) => {
    getFirestoreListingDoc(listingId).then(listingDoc => {
      if (!unmountedRef.current) {
        setListing(listingDoc.data())
      }
    })
  }

  const handleOpenListing = () => {
    window.open(`/listing/${schedule.listingId}`, '_blank', 'noreferrer')
  }

  const handleOpenMessage = async () => {
    const messageThreadQuery = query(collection(firestore, 'messages'), where('senders', 'array-contains', userProfile.userId))
    getDocs(messageThreadQuery).then(messageThreadsSnap => {
      if (!messageThreadsSnap.empty) {
        let docId = null
        messageThreadsSnap.forEach(doc => {

          const docData = doc.data()
          
          if (docData.listing === schedule.listingId && docData.senders.includes(schedule.customer.id)) {
            docId = doc.id
          }
        })
        
        if (docId !== null) {
          navigate(`/manage/messages/${docId}`)
        }
      }
    })
  }

  const handleDelete = () => {
    updateDoc(doc(firestore, `schedules/${schedule.id}`), {
      deleted: true,
    })
  }

  const handleCancel = () => {
    // window.open(`mailto:${schedule.customer.email}`)

    const newChatMessage = {
      at: new Date().getTime(),
      by: userProfile.userId,
      msg: "Showing canceled",
      extra: {
        type: "cancel"
      }
    }

    addDoc(collection(firestore, `messages/${schedule.id}/chat`), newChatMessage).then(chatDocRef => {
      getDoc(doc(firestore, `messages/${schedule.id}`)).then(messageDoc => {
        if (!messageDoc.empty) {
          const unreads = {}
          const message = messageDoc.data()
          message.senders.forEach(sender => {
            // plus message badge for each senders
            if (sender !== userProfile?.userId) {
              updateDoc(doc(firestore, `profiles/${sender}`), {
                "notifications.message": arrayUnion(schedule.id),
                "notifications.schedule": arrayRemove(schedule.id)
              })
              unreads[`unreads.${sender}`] = arrayUnion(chatDocRef.id)
                // Send Push Notification when meeting canceled
              getDoc(doc(firestore, `profiles/${sender}`)).then(profileDoc => {
                const myProfile = profileDoc.data()
                sendPushNotificationForChat("Message Received","Your showing has been canceled",myProfile.deviceToken,"NewChat")
              })
            } else {
              updateDoc(doc(firestore, `profiles/${sender}`), {
                "notifications.schedule": arrayRemove(schedule.id)
              })
            }
          })

          updateDoc(doc(firestore, `messages/${schedule.id}`), {
            deleted: deleteField(),
            status: 'cancelled',
            lm: {
              ...newChatMessage,
              id: chatDocRef.id
            },
            ...unreads
          })
        }
      })

      updateDoc(doc(firestore, `schedules/${schedule.id}`), {
        canceled: true,
        canceledBy: userProfile.userId,
      })
    })
  }

  useEffect(() => {
    if (schedule?.listingId) { 
      loadListing(schedule.listingId)
    }
  }, [schedule])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
    }
  }, [])

  return (
    <Flexbox row style={{
      ...styles.container,
      width: isMobile ? 'calc(100% - 30px)' : 600,
      flexDirection: isMobile ? 'column' : 'row',
      gap: 10,
    }}>
      <Flexbox style={{
        ...styles.dateTime,
        borderWidth : isMobile ? 0 : 1
      }}>
        <TypeFace bold size={24} color={isPastSchedule ? '#7f7f7f' : 'black'}>
          {moment(new Date(schedule.customer.when)).format('MMM D').toUpperCase()}
        </TypeFace>
        <TypeFace semiBold size={16} color={isPastSchedule ? '#7f7f7f' : 'black'}>
          {moment(new Date(schedule.customer.when)).format('h:mm a').toUpperCase()}
        </TypeFace>
      </Flexbox>
      <Flexbox style={styles.content}>
        <TypeFace bold size={15} color={isPastSchedule ? '#7f7f7f' : 'black'}>
          {schedule.customer.name}
        </TypeFace>
        <TypeFace medium size={12} color={isPastSchedule ? '#7f7f7f' : 'black'}>
          Schedule on <TypeFace semiBold size={15}>{extractStreetAddressFromFullAddress(listing?.address?.full)}{' '}</TypeFace><TypeFace bold size={15}>{listing?.unit}</TypeFace>
        </TypeFace>
        <Flexbox row style={styles.buttons}>
          {schedule.canceled ? (
            <TypeFace>Meeting cancelled</TypeFace>
          ) : schedule.completed ? (
            <TypeFace>Meeting completed</TypeFace>
          ): <Button secondary style={styles.button} onClick={handleCancel}>
          Cancel meeting
        </Button>
          }
          
          {/* <Button secondary style={styles.button} onClick={() => {
            window.open(`sms:+1${schedule.customer.phone}`)
          }}>
            Send SMS
          </Button> */}
        </Flexbox>
      </Flexbox>
      <Flexbox style={{
        ...styles.rightButtons,
        flexDirection: isMobile ? 'row' : 'column',
        marginTop: isMobile ? 30 : 0
      }}>
        <Button
          primary
          onClick={handleOpenListing}
          style={{
            ...styles.rightButton,
            width: isMobile ? 120 : 100,
            height: isMobile ? 44 : 30
          }} textSize={isMobile ? 14 : 11}
        >
          Open Listing
        </Button>
        <Button
          onClick={handleOpenMessage}
          primary
          style={{
            ...styles.rightButton,
            width: isMobile ? 120 : 100,
            height: isMobile ? 44 : 30
          }}
          textSize={isMobile ? 14 : 11}
        >
          Message
        </Button>
        {isPastSchedule && (
          <TypeFace onClick={handleDelete} size={11} color="red" style={{ cursor: 'pointer' }}>
            Delete
          </TypeFace>
        )}
      </Flexbox>
    </Flexbox>
  )
}

export default MyScheduleEntry

const styles = {
  container: {
    width: 600,
    border: '1px solid #ccc',
    borderRadius: 10,
    padding: 15,
    marginTop: 20
  },
  dateTime: {
    width: 100,
    height: 100,
    border: '1px solid #ccc',
    borderRadius: 10,
    marginRight: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    marginTop: 10,
    gap: 10
  },
  button: {
    height: 30,
  },
  rightButtons: {
    gap: 10,
  },
  rightButton: {
    padding: 0,
    width: 100,
    height: 30,
  },
  content: {
    flex: 1,
    alignItems: 'flex-start'
  }
}