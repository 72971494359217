import React from 'react';

const TypeFace = (props) => {
  const { style, type, className } = props;
  
  const weightStyle = props.bold ? styles.bold : props.medium ? styles.medium : props.semiBold ? styles.semiBold : styles.regular

  const finalStyle = {
    ...styles.font,
    ...weightStyle,
    color: props.color,
    fontSize: props.size,
    wordWrap: 'break-word',
    ...style,
  }

  return (
    <>
      {type === 'div' ? (
        <div
          className={className}
          style={finalStyle}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {props.children}
        </div>
      ) : (
        <label
          className={className}
          style={finalStyle}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          htmlFor={props.for}
        >
          {props.children}
        </label>
      )}
    </>
  )
}

export default TypeFace

const styles = {
  font: {
    fontFamily: 'OpenSans',
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  semiBold: {
    fontWeight: 600,
  },
  medium: {
    fontWeight: 500,
  },
  regular: {
    fontWeight: 400,
  }
}