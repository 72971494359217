import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_firebaseApiKey,
  authDomain: process.env.REACT_APP_firebaseAuthDomain,
  projectId: process.env.REACT_APP_firebaseProjectId,
  storageBucket: process.env.REACT_APP_firebaseStorageBucket,
  messagingSenderId: process.env.REACT_APP_firebaseMessagingSenderId,
  appId: process.env.REACT_APP_firebaseAppId,
  measurementId: process.env.REACT_APP_firebaseMeasurementID
};

const app = initializeApp(firebaseConfig);

export const firestore = initializeFirestore(app, {
  forceOwnership: true,
  synchronizeTabs: true,
  experimentalForceOwningTab: true
});

enableIndexedDbPersistence(firestore, { synchronizeTabs: true })
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.error("Persistence can only be enabled in one tab at a time without multi-tab support.");
    } else if (err.code === 'unimplemented') {
      console.error("The current browser does not support all of the features required to enable persistence");
    }
  });

export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
