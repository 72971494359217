import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "../../../components/listing/carousel-customize.css"
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from '../../../components/typography/Typefaces';
import Modal from 'react-modal';
import Flexbox from 'src/components/wrapper/Flexbox';
import Button from 'src/components/button/Button';
import IconLeft from 'src/assets/images/icons/icon-chevron-left-white.svg'
import IconRight from 'src/assets/images/icons/icon-chevron-right-white.svg'

const ListingPhotosCarouselOverModal = ({
  photos,
  initialIndex = 0,
  modalIsOpen,
  onCloseModal,
}) => {

  const { isMobile } = useMediaQuery()

  const [selectedPhotoItem, setSelectedPhotoItem] = useState(initialIndex)

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    document.body.style.overflow = 'hidden'
    setSelectedPhotoItem(initialIndex)
  }

  function closeModal() {
    document.body.style.overflow = 'auto'
    onCloseModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="totalBlackModal"
      overlayClassName="totalBlackOverlay"
    >
      <Flexbox style={styles.container}>
        <Flexbox row style={styles.topBar}>
          <TypeFace bold color="white" style={{ flex: 1, textAlign: 'center' }}>
            {selectedPhotoItem + 1}/{photos.length}
          </TypeFace>
          <Button onClick={closeModal} secondary textColor="white" style={styles.closeButton}>
            Close
          </Button>
        </Flexbox>
        <Flexbox row style={styles.content}>
          <Flexbox style={styles.arrowButtonsContainer}>
            <Flexbox style={{
              ...styles.arrowButton,
              opacity: selectedPhotoItem > 0 ? 1 : 0.2,
            }} onClick={() => {
              if (selectedPhotoItem > 0) {
                setSelectedPhotoItem(selectedPhotoItem - 1)
              }
            }}>
              <img src={IconLeft} width={20} height={20} />
            </Flexbox>
          </Flexbox>
          <div style={styles.carouselContainer}>
            <Carousel
              emulateTouch
              showArrows={false}
              showStatus={false}
              selectedItem={selectedPhotoItem}
              onChange={(index, item) => setSelectedPhotoItem(index)}
              showThumbs={false}
            >
              {photos?.map((photo, index) => (
                <div key={`photo-${index}`} style={{ width: isMobile ? '100%' : 800, height: 400 }}>
                  <img
                    alt=""
                    src={photo.url}
                    style={styles.carouselPhoto}
                  />
                </div>  
              ))}
            </Carousel>
          </div>
          <Flexbox style={styles.arrowButtonsContainer}>
            <Flexbox style={{
              ...styles.arrowButton,
              opacity: selectedPhotoItem < photos.length - 1 ? 1 : 0.2,
            }} onClick={() => {
              if (selectedPhotoItem < photos.length - 1) {
                setSelectedPhotoItem(selectedPhotoItem + 1)
              }
            }}>
              <img src={IconRight} width={20} height={20} />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default ListingPhotosCarouselOverModal

const styles = {
  container: {
    width: 900,
    height: 500,
    backgroundColor: 'rgba(0,0,0,0.95)'
  },
  topBar: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'transparent',
    border: '1px solid #fff'
  },
  content: {
    width: '100%',
    height: 400,
  },
  arrowButtonsContainer: {
    width: 50,
    height: 400,
    justifyContent: 'center',
  },
  arrowButton: {
    width: 24,
    height: 24,
    border: '1px solid #fff',
    borderRadius: 20,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  carouselPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  carouselContainer: {
    width: 800,
    height: 400,
  }
}