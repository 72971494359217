export const _areas = [
  {
    id: "01dmZwmPzg6prj4gMLyI",
    state_short: "NY",
    name: "Great Kills",
    components: {
      types: ["neighborhood", "political"],
      text: "Great Kills",
      center: [-74.156292, 40.5543273],
      bbox: [40.5293601, -74.16271859999999, 40.5676703, -74.115186],
    },
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 923000000,
    },
    place_name: "Great Kills, Staten Island, NY",
    state: "New York",
  },
  {
    id: "0AYLFtPDGDyloMDIx0YQ",
    state_short: "NY",
    name: "Carroll Gardens",
    state: "New York",
    place_name: "Carroll Gardens, Brooklyn, NY",
    components: {
      text: "Carroll Gardens",
      center: [-73.9991637, 40.6795331],
      bbox: [40.671976, -74.00341019999999, 40.6864471, -73.9891435],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 760000000,
    },
    county: "Kings County",
  },
  {
    id: "0IiLWNbbYDjjwJqKFj2L",
    name: "Hillcrest",
    county: "Queens County",
    state: "New York",
    components: {
      center: [-73.8001762, 40.7229706],
      text: "Hillcrest",
      types: ["neighborhood", "political"],
      bbox: [40.71580609999999, -73.8089956, 40.739025, -73.7902819],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 573000000,
    },
    place_name: "Hillcrest, Queens, NY",
    state_short: "NY",
  },
  {
    id: "0KGFPhYXBjjJJgZ0NiWI",
    state: "New York",
    place_name: "Manhattan Valley, New York, NY",
    county: "New York County",
    components: {
      text: "Manhattan Valley",
      center: [-73.9684247, 40.7966989],
      types: ["neighborhood", "political"],
      bbox: [40.791684, -73.9718257, 40.8041882, -73.9582099],
    },
    name: "Manhattan Valley",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 524000000,
    },
  },
  {
    id: "0kxKmnRgbNoFte4oZuzW",
    place_name: "Concord, Staten Island, NY ",
    state_short: "NY",
    components: {
      text: "Concord",
      bbox: [40.6068108197085, -74.08565708029151, 40.6095087802915, -74.0829591197085],
      types: ["colloquial_area", "political"],
      center: [-74.0843081, 40.6081598],
    },
    name: "Concord",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 922000000,
    },
    county: "Richmond County",
  },
  {
    id: "0smBT51NEVCuJ3TQlCHf",
    place_name: "Atlantic County, NJ",
    state_short: "NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 968000000,
    },
    county: "Atlantic County",
    state: "New Jersey",
    components: {
      types: ["administrative_area_level_2", "political"],
      text: "Atlantic County",
      bbox: [39.2885581, -74.985271, 39.7297749, -74.232062],
      center: [-74.6868815, 39.5333379],
    },
    name: "Atlantic County",
  },
  {
    id: "0zvsEMEMGmzpxD0lBTpv",
    county: "New York County",
    created: {
      seconds: 1725925688,
      nanoseconds: 741000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.7641334, -73.981901, 40.7684723, -73.9727181],
      center: [-73.9773018, 40.7661882],
      types: ["route"],
      text: "Central Park South",
    },
    name: "Central Park South",
    place_name: "Central Park South, New York, NY",
    state: "New York",
  },
  {
    id: "15OLOs0u7DivsNzqod6M",
    state_short: "NY",
    place_name: "Pelham Parkway, Bronx, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.85030709999999, -73.8699323, 40.8569732, -73.857365],
      center: [-73.8639594, 40.8553279],
      text: "Pelham Parkway",
    },
    county: "Bronx County",
    name: "Pelham Parkway",
  },
  {
    id: "1AWZw2aIQA1I0BUuGrHC",
    place_name: "Oxford, Warren County, NJ",
    components: {
      types: ["locality", "political"],
      bbox: [40.7814749, -75.025313, 40.832713, -74.953184],
      text: "Oxford",
      center: [-74.999813, 40.8132568],
    },
    name: "Oxford",
    created: {
      seconds: 1725915869,
      nanoseconds: 832000000,
    },
    county: "Warren County",
    state_short: "NJ",
    state: "New Jersey",
  },
  {
    id: "1BsB7DKpColg7ZlnA6Zz",
    county: "Bronx County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
    name: "Mount Eden",
    components: {
      center: [-73.9168766, 40.8407018],
      text: "Mount Eden",
      bbox: [40.836279, -73.9228061, 40.8451791, -73.91110499999999],
      types: ["neighborhood", "political"],
    },
    place_name: "Mount Eden, Bronx, NY ",
  },
  {
    id: "1HlAzYF2FM7XUEAJMO3i",
    created: {
      seconds: 1725914391,
      nanoseconds: 747000000,
    },
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Boerum Hill",
      bbox: [40.680638, -73.9942932, 40.6910082, -73.97797469999999],
      center: [-73.9844722, 40.6848689],
      types: ["neighborhood", "political"],
    },
    name: "Boerum Hill",
    place_name: "Boerum Hill, Brooklyn, NY",
  },
  {
    id: "1cUMGLMLaQyvrmnxWsWG",
    state: "New Jersey",
    county: "Bergen County",
    place_name: "Paramus, Bergen County, NJ",
    components: {
      bbox: [40.9085321, -74.101378, 40.980635, -74.0388439],
      text: "Paramus",
      center: [-74.0754189, 40.9445428],
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 865000000,
    },
    state_short: "NJ",
    name: "Paramus",
  },
  {
    id: "1iaPtYTLopZLvATSVPyj",
    county: "Nassau County",
    name: "Wantagh",
    state: "New York",
    place_name: "Wantagh, NY",
    components: {
      text: "Wantagh",
      types: ["locality", "political"],
      bbox: [40.630801, -73.53187989999999, 40.707896, -73.4827959],
      center: [-73.5101258, 40.6837121],
    },
    state_short: "NY",
    created: {
      seconds: 1725915360,
      nanoseconds: 985000000,
    },
  },
  {
    id: "1zOC8R2r43e9dhdS2ho1",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 867000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: [-73.9756567, 40.6539346],
      text: "Windsor Terrace",
      bbox: [40.6472954, -73.9862359, 40.662158, -73.9714193],
    },
    state_short: "NY",
    state: "New York",
    name: "Windsor Terrace",
    place_name: "Windsor Terrace, Brooklyn, NY",
  },
  {
    id: "204w84uFCUso4UH0cfFm",
    components: {
      center: [-73.6556848, 40.7748231],
      text: "Searingtown",
      types: ["locality", "political"],
      bbox: [40.7598774, -73.6708394, 40.78164410000001, -73.649515],
    },
    place_name: "Searingtown, NY",
    state: "New York",
    name: "Searingtown",
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 67000000,
    },
    state_short: "NY",
  },
  {
    id: "252m36LrzapAXqduOpHV",
    created: {
      seconds: 1725914391,
      nanoseconds: 826000000,
    },
    county: "Kings County",
    name: "Vinegar Hill",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      text: "Vinegar Hill",
      center: [-73.98226830000002, 40.70371859999999],
      bbox: [40.7013741, -73.9847016, 40.7059655, -73.97909299999999],
    },
    place_name: "Vinegar Hill, Brooklyn, NY ",
    state_short: "NY",
  },
  {
    id: "2caVNRUvf0D8RX9nGNiX",
    name: "Inwood",
    county: "New York County",
    state_short: "NY",
    state: "New York",
    components: {
      center: [-73.9212019, 40.8677145],
      text: "Inwood",
      types: ["neighborhood", "political"],
      bbox: [40.8557032, -73.932667, 40.877591, -73.9104948],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
    place_name: "Inwood, New York, NY",
  },
  {
    id: "2dPzPRdbfVDc86cligVL",
    place_name: "Bayonne, Hudson County, NJ",
    county: "Hudson County",
    components: {
      types: ["locality", "political"],
      center: [-74.1143091, 40.6687141],
      bbox: [40.6421489, -74.1613741, 40.701072, -74.049594],
      text: "Bayonne",
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    state_short: "NJ",
    state: "New Jersey",
    name: "Bayonne",
  },
  {
    id: "2jmK4LpYGu7jZcfWreqd",
    state: "New York",
    place_name: "Westerleigh, Staten Island, NY",
    state_short: "NY",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 867000000,
    },
    name: "Westerleigh",
    components: {
      text: "Westerleigh",
      center: [-74.1386767, 40.616296],
      types: ["neighborhood", "political"],
      bbox: [40.610301, -74.14605399999999, 40.6263896, -74.13072489999999],
    },
  },
  {
    id: "2tZnGIRTDFcRmj1dUTx0",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 769000000,
    },
    place_name: "Pompton Lakes, Passaic County, NJ",
    state: "New Jersey",
    county: "Passaic County",
    components: {
      center: [-74.2907041, 41.0053753],
      types: ["locality", "political"],
      bbox: [40.9788, -74.302993, 41.025173, -74.266713],
      text: "Pompton Lakes",
    },
    name: "Pompton Lakes",
  },
  {
    id: "31J4sSb6vdtAkEyDUMRT",
    created: {
      seconds: 1725915869,
      nanoseconds: 653000000,
    },
    county: "Morris County",
    components: {
      text: "Morris Plains",
      center: [-74.4818698, 40.83959220000001],
      bbox: [40.8198129, -74.495925, 40.8530589, -74.460166],
      types: ["locality", "political"],
    },
    place_name: "Morris Plains, Morris County, NJ",
    name: "Morris Plains",
    state_short: "NJ",
    state: "New Jersey",
  },
  {
    id: "3JlIhbLYwP3CBtvpF6we",
    state: "New York",
    place_name: "New Springville, Staten Island, NY ",
    name: "New Springville",
    state_short: "NY",
    components: {
      bbox: [40.5708238, -74.17090689999999, 40.5966419, -74.1455981],
      text: "New Springville",
      center: [-74.16034840000002, 40.5847957],
      types: ["neighborhood", "political"],
    },
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 926000000,
    },
  },
  {
    id: "3Lj0Yz2J3sNwsJ9Xx8Q7",
    name: "Cumberland County",
    components: {
      text: "Cumberland County",
      center: [-75.06111469999999, 39.2587106],
      types: ["administrative_area_level_2", "political"],
      bbox: [39.056179, -75.44475109999999, 39.568746, -74.851794],
    },
    state: "New Jersey",
    place_name: "Cumberland County, NJ",
    county: "Cumberland County",
    state_short: "NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 978000000,
    },
  },
  {
    id: "3ZJ60EAw24l0W7ucDFZV",
    state_short: "NJ",
    place_name: "Camden County, NJ",
    components: {
      text: "Camden County",
      types: ["administrative_area_level_2", "political"],
      bbox: [39.6083518, -75.139832, 39.9960808, -74.7362161],
      center: [-75.0143824, 39.8592439],
    },
    created: {
      seconds: 1725911238,
      nanoseconds: 938000000,
    },
    state: "New Jersey",
    name: "Camden County",
    county: "Camden County",
  },
  {
    id: "3sgadOqeZuybRgmiD8ld",
    place_name: "Queens, NY",
    county: "Queens County",
    state: "New York",
    components: {
      center: [-73.7948516, 40.7282239],
      text: "Queens County",
      types: ["political", "sublocality", "sublocality_level_1"],
      bbox: [40.4897939, -74.0421119, 40.8122421, -73.70027209999999],
    },
    name: "Queens",
    created: {
      seconds: 1725911049,
      nanoseconds: 35000000,
    },
    state_short: "NY",
  },
  {
    id: "3vwzwFRGHHLn9EDZoaZu",
    name: "Franklin Township",
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    county: "Somerset County",
    components: {
      types: ["locality", "political"],
      text: "Franklin Township",
      bbox: [40.37439690000001, -74.63746309999999, 40.554559, -74.4590419],
      center: [-74.5228916, 40.4933734],
    },
    state_short: "NJ",
    state: "New Jersey",
    place_name: "Franklin Township, Somerset County, NJ",
  },
  {
    id: "3yJmDSPd6DeYUcBybZ9u",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      bbox: [39.5837511, -75.1844211, 39.6021631, -75.164665],
      text: "Elmer",
      center: [-75.1701819, 39.5951147],
      types: ["locality", "political"],
    },
    county: "Salem County",
    name: "Elmer",
    created: {
      seconds: 1725915869,
      nanoseconds: 793000000,
    },
    place_name: "Elmer, Salem County, NJ",
  },
  {
    id: "40OcqAPPC5eAHxilL0Zg",
    created: {
      seconds: 1725921195,
      nanoseconds: 570000000,
    },
    name: "Jamaica Estates",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    components: {
      center: [-73.783822, 40.7179512],
      text: "Jamaica Estates",
      types: ["neighborhood", "political"],
      bbox: [40.7107253, -73.79366, 40.7291933, -73.77354679999999],
    },
    place_name: "Jamaica Estates, Queens, NY",
  },
  {
    id: "49mkIcVcGwerm7kA48ZE",
    county: "Nassau County",
    place_name: "East Garden City, Uniondale, NY",
    name: "East Garden City",
    created: {
      seconds: 1725921859,
      nanoseconds: 759000000,
    },
    state: "New York",
    components: {
      text: "East Garden City",
      center: [-73.5980781, 40.7304187],
      bbox: [40.712882, -73.6171008, 40.7475659, -73.58000679999999],
      types: ["colloquial_area", "political"],
    },
    state_short: "NY",
  },
  {
    id: "4Pvhn12AfuyXqxs8WJdS",
    county: "Monmouth County",
    created: {
      seconds: 1725915869,
      nanoseconds: 853000000,
    },
    state_short: "NJ",
    name: "Red Bank",
    state: "New Jersey",
    components: {
      text: "Red Bank",
      types: ["locality", "political"],
      center: [-74.0643065, 40.3470543],
      bbox: [40.3362489, -74.090631, 40.363686, -74.0498909],
    },
    place_name: "Red Bank, Monmouth County, NJ",
  },
  {
    id: "4Zczx68vdsr251mn9WAK",
    state: "New Jersey",
    county: "Mercer County",
    created: {
      seconds: 1725915869,
      nanoseconds: 830000000,
    },
    components: {
      center: [-74.6796651, 40.2115109],
      bbox: [40.136843, -74.752766, 40.2709029, -74.587847],
      text: "Hamilton Township",
      types: ["locality", "political"],
    },
    state_short: "NJ",
    place_name: "Hamilton Township, Mercer County, NJ",
    name: "Hamilton Township",
  },
  {
    id: "4bGPYIhobBsq0NKeqHdQ",
    components: {
      text: "Locust Valley",
      types: ["locality", "political"],
      center: [-73.5970707, 40.8759324],
      bbox: [40.8706289, -73.61455080000002, 40.88503, -73.5645689],
    },
    created: {
      seconds: 1725921859,
      nanoseconds: 814000000,
    },
    name: "Locust Valley",
    state_short: "NY",
    place_name: "Locust Valley, NY ",
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "4dyR79fx0ick0NdHXiXZ",
    name: "Oakwood",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 914000000,
    },
    state_short: "NY",
    county: "Richmond County",
    place_name: "Oakwood, Staten Island, NY ",
    components: {
      text: "Oakwood",
      types: ["colloquial_area", "political"],
      center: [-74.1159754, 40.563994],
      bbox: [40.5626450197085, -74.1173243802915, 40.5653429802915, -74.1146264197085],
    },
  },
  {
    id: "4zjEvTp3pzbGJM2f78lP",
    name: "East Hills",
    state: "New York",
    county: "Nassau County",
    place_name: "East Hills, NY",
    components: {
      center: [-73.62707259999999, 40.7937115],
      types: ["locality", "political"],
      bbox: [40.777991, -73.644058, 40.8124271, -73.608141],
      text: "East Hills",
    },
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 760000000,
    },
  },
  {
    id: "5DbF1TsVpabcSQdQlgfA",
    state: "New Jersey",
    county: "Hunterdon County",
    components: {
      types: ["locality", "political"],
      bbox: [40.5032519, -74.868607, 40.66846100000001, -74.702231],
      center: [-74.73766189999999, 40.5687141],
      text: "Readington Township",
    },
    place_name: "Readington Township, Hunterdon County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 828000000,
    },
    state_short: "NJ",
    name: "Readington Township",
  },
  {
    id: "5H3YaNjZ4VJ2xizSuVri",
    state_short: "NY",
    name: "Freeport",
    components: {
      bbox: [40.62337309999999, -73.603841, 40.6735478, -73.564632],
      types: ["locality", "political"],
      text: "Freeport",
      center: [-73.58318349999999, 40.6576022],
    },
    county: "Nassau County",
    state: "New York",
    place_name: "Freeport, NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 11000000,
    },
  },
  {
    id: "5O2Zau6b7FSS8MA2jR8R",
    state: "New York",
    created: {
      seconds: 1725921195,
      nanoseconds: 566000000,
    },
    components: {
      text: "Central Harlem",
      center: [-73.9482305, 40.8089419],
      bbox: [40.7968871, -73.9595748, 40.8308904, -73.9338182],
      types: ["neighborhood", "political"],
    },
    county: "New York County",
    name: "Central Harlem",
    place_name: "Central Harlem, New York, NY",
    state_short: "NY",
  },
  {
    id: "5eaZ242J6n7dtkYvwXK1",
    name: "Jamaica",
    state: "New York",
    components: {
      bbox: [40.6443744, -73.8662949, 40.739025, -73.77354679999999],
      text: "Jamaica",
      center: [-73.7991953, 40.7037439],
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
    county: "Queens County",
    place_name: "Jamaica, Queens, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 817000000,
    },
  },
  {
    id: "5nNY8SRneY6Aj2q1iCf2",
    name: "The Rockaways",
    state_short: "NY",
    place_name: "Rockaways, Queens, NY",
    county: "Queens County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    components: {
      bbox: [40.5421497, -73.9414834, 40.6142804, -73.73710179999999],
      text: "Rockaway Peninsula",
      types: ["establishment", "natural_feature"],
      center: [-73.7977928, 40.592658],
    },
  },
  {
    id: "5yaziWe8fCplunGp8SBR",
    place_name: "Willowbrook, Staten Island, NY ",
    state_short: "NY",
    state: "New York",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 925000000,
    },
    components: {
      types: ["colloquial_area", "political"],
      bbox: [40.6018108197085, -74.13982518029152, 40.6045087802915, -74.13712721970852],
      center: [-74.13847620000001, 40.6031598],
      text: "Willowbrook",
    },
    name: "Willowbrook",
  },
  {
    id: "6063ODOUBXlbGZBpniQt",
    created: {
      seconds: 1725914391,
      nanoseconds: 849000000,
    },
    name: "Upper West Side",
    place_name: "Upper West Side, New York, NY",
    state_short: "NY",
    state: "New York",
    county: "New York County",
    components: {
      bbox: [40.768483, -73.9963789, 40.806098, -73.9582099],
      text: "Upper West Side",
      types: ["neighborhood", "political"],
      center: [-73.9753676, 40.7870106],
    },
  },
  {
    id: "67kETFTyYdQcE21TdSrG",
    state: "New York",
    place_name: "Merrick, NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 780000000,
    },
    state_short: "NY",
    county: "Nassau County",
    name: "Merrick",
    components: {
      bbox: [40.624677, -73.576914, 40.6800699, -73.5272761],
      text: "Merrick",
      center: [-73.55151599999999, 40.6628795],
      types: ["locality", "political"],
    },
  },
  {
    id: "69amYH1ocvICm58aBCeh",
    created: {
      seconds: 1725915869,
      nanoseconds: 871000000,
    },
    components: {
      text: "Clifton",
      center: [-74.16375529999999, 40.8584328],
      bbox: [40.8199619, -74.197734, 40.899398, -74.1178039],
      types: ["locality", "political"],
    },
    name: "Clifton",
    state_short: "NJ",
    state: "New Jersey",
    county: "Passaic County",
    place_name: "Clifton, Passaic County, NJ",
  },
  {
    id: "6AuMaTKn05lnCTWSRyLf",
    name: "Bath Beach",
    state: "New York",
    place_name: "Bath Beach, Brooklyn, NY",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 836000000,
    },
    components: {
      center: [-74.0062078, 40.6038852],
      text: "Bath Beach",
      bbox: [40.594391, -74.02004710000001, 40.612493, -73.9938083],
      types: ["neighborhood", "political"],
    },
    county: "Kings County",
  },
  {
    id: "6KijqwQk2DA0LohTOigc",
    name: "College Point",
    components: {
      center: [-73.8389657, 40.786395],
      text: "College Point",
      types: ["neighborhood", "political"],
      bbox: [40.76573399999999, -73.8591727, 40.79763210000001, -73.8234794],
    },
    state: "New York",
    county: "Queens County",
    place_name: "College Point, Queens, NY",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 717000000,
    },
  },
  {
    id: "6W5wNzKK6nE10uJsf94p",
    county: "Gloucester County",
    components: {
      bbox: [39.7075988, -75.129509, 39.8049059, -75.0152531],
      text: "Washington Township",
      center: [-75.07279559999999, 39.7561387],
      types: ["locality", "political"],
    },
    place_name: "Washington Township, Gloucester County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 933000000,
    },
    name: "Washington Township",
    state_short: "NJ",
    state: "New Jersey",
  },
  {
    id: "6WVANAngyqDKjX5RQGhS",
    name: "Mount Laurel Township",
    state_short: "NJ",
    place_name: "Mount Laurel Township, Burlington County, NJ",
    components: {
      center: [-74.89099879999999, 39.934002],
      bbox: [39.91428, -74.983717, 40.0007449, -74.8485501],
      types: ["locality", "political"],
      text: "Mount Laurel Township",
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 881000000,
    },
    county: "Burlington County",
    state: "New Jersey",
  },
  {
    id: "6XNoikZ3q5NZdN8ja7QN",
    county: "New York County",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 521000000,
    },
    components: {
      bbox: [40.7283442, -74.0106087, 40.7409452, -73.99685199999999],
      text: "West Village",
      center: [-74.0047554, 40.73468],
      types: ["neighborhood", "political"],
    },
    place_name: "West Village, New York, NY",
    state: "New York",
    name: "West Village",
  },
  {
    id: "6YXr1NBag4wCl1U6q8YN",
    created: {
      seconds: 1725914391,
      nanoseconds: 745000000,
    },
    state: "New York",
    name: "Fort Greene",
    place_name: "Fort Greene, Brooklyn, NY",
    county: "Kings County",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Fort Greene",
      bbox: [40.681683, -73.98149219999999, 40.6981962, -73.9683311],
      center: [-73.97418739999999, 40.6920638],
    },
  },
  {
    id: "6eOKMJT7SUyof1Rt78Lp",
    county: "Hudson County",
    components: {
      text: "Jersey City",
      types: ["locality", "political"],
      center: [-74.0431435, 40.7177545],
      bbox: [40.661622, -74.1170569, 40.768867, -74.020405],
    },
    name: "Jersey City",
    created: {
      seconds: 1725915869,
      nanoseconds: 691000000,
    },
    place_name: "Jersey City, Hudson County, NJ",
    state: "New Jersey",
    state_short: "NJ",
  },
  {
    id: "6xKjKikPUr7PE7TnNtVe",
    place_name: "Ozone Park, Queens, NY",
    state_short: "NY",
    components: {
      center: [-73.844659, 40.6806669],
      bbox: [40.6668285, -73.8662949, 40.69129059999999, -73.8334656],
      text: "Ozone Park",
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 572000000,
    },
    name: "Ozone Park",
    state: "New York",
    county: "Queens County",
  },
  {
    id: "70xipIOU5MG7a2MCMnGR",
    created: {
      seconds: 1725915869,
      nanoseconds: 811000000,
    },
    components: {
      text: "Toms River",
      types: ["locality", "political"],
      center: [-74.1979458, 39.9537358],
      bbox: [39.9330019, -74.270651, 40.0587159, -74.05857979999999],
    },
    state_short: "NJ",
    name: "Toms River",
    place_name: "Toms River, Ocean County, NJ",
    county: "Ocean County",
    state: "New Jersey",
  },
  {
    id: "74Gfsos1eCaSEHUqbeT2",
    place_name: "Woodbury, NY ",
    created: {
      seconds: 1725915360,
      nanoseconds: 982000000,
    },
    state_short: "NY",
    state: "New York",
    name: "Woodbury",
    county: "Nassau County",
    components: {
      center: [-73.46762249999999, 40.8256545],
      text: "Woodbury",
      bbox: [40.79490210000001, -73.491709, 40.84129009999999, -73.44964449999999],
      types: ["locality", "political"],
    },
  },
  {
    id: "7C1lnhXOFvtg3kWs6QMu",
    state_short: "NY",
    components: {
      center: [-73.8372237, 40.57978629999999],
      text: "Rockaway Park",
      types: ["neighborhood", "political"],
      bbox: [40.5739587, -73.8484076, 40.58382770000001, -73.8299593],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 594000000,
    },
    state: "New York",
    place_name: "Rockaway Park, Queens, NY",
    county: "Queens County",
    name: "Rockaway Park",
  },
  {
    id: "7hx1SmrXV7TwCTK4wR7a",
    name: "Pleasant Plains",
    place_name: "Pleasant Plains, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 920000000,
    },
    county: "Richmond County",
    state_short: "NY",
    components: {
      text: "Pleasant Plains",
      types: ["colloquial_area", "political"],
      bbox: [40.5226454197085, -74.21704888029149, 40.5253433802915, -74.21435091970848],
      center: [-74.21569989999999, 40.5239944],
    },
    state: "New York",
  },
  {
    id: "7r9NGdJ3mJ7S9EcSRC7s",
    place_name: "Gloucester County, NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 979000000,
    },
    state_short: "NJ",
    name: "Gloucester County",
    state: "New Jersey",
    county: "Gloucester County",
    components: {
      center: [-75.1078332, 39.7103023],
      text: "Gloucester County",
      bbox: [39.5149669, -75.4290609, 39.888317, -74.87725999999999],
      types: ["administrative_area_level_2", "political"],
    },
  },
  {
    id: "82Y0RTQ3g1P2BuAmeduH",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Wayne",
      types: ["locality", "political"],
      center: [-74.2765441, 40.92537249999999],
      bbox: [40.88287100000001, -74.28918589999999, 41.014063, -74.1955409],
    },
    county: "Passaic County",
    created: {
      seconds: 1725915869,
      nanoseconds: 857000000,
    },
    place_name: "Wayne, Passaic County, NJ",
    name: "Wayne",
  },
  {
    id: "8EmjvD4yuN2wLXZBS4PH",
    state_short: "NY",
    county: "Kings County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 753000000,
    },
    components: {
      center: [-73.9921028, 40.6350319],
      types: ["neighborhood", "political"],
      text: "Borough Park",
      bbox: [40.621966, -74.0082836, 40.6471082, -73.97709429999999],
    },
    place_name: "Borough Park, Brooklyn, NY",
    name: "Borough Park",
  },
  {
    id: "8GnGoF4aAWBPgtk8jSTN",
    state: "New York",
    name: "Williston Park",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 758000000,
    },
    place_name: "Williston Park, NY",
    components: {
      text: "Williston Park",
      center: [-73.6448513, 40.75649],
      types: ["locality", "political"],
      bbox: [40.75119, -73.6605635, 40.7664949, -73.63902999999999],
    },
    county: "Nassau County",
  },
  {
    id: "8HoywNwgWmJ7enAefVSC",
    state_short: "NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 0,
    },
    place_name: "Mineola, NY",
    state: "New York",
    components: {
      center: [-73.6411129, 40.74921399999999],
      types: ["locality", "political"],
      text: "Mineola",
      bbox: [40.73684790000001, -73.6605635, 40.7674109, -73.6207359],
    },
    county: "Nassau County",
    name: "Mineola",
  },
  {
    id: "8IrW52AvvLSCa7oB8Rd7",
    name: "Garden City Park",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 782000000,
    },
    county: "Nassau County",
    place_name: "Garden City Park, NY ",
    state: "New York",
    components: {
      types: ["locality", "political"],
      center: [-73.6630251, 40.7410315],
      bbox: [40.7330909, -73.67325799999999, 40.7532691, -73.65187990000001],
      text: "Garden City Park",
    },
  },
  {
    id: "8IvyG8x3hOzgZuEe4CkJ",
    components: {
      center: [-73.67179689999999, 40.6548253],
      types: ["locality", "political"],
      text: "Lynbrook",
      bbox: [40.63375509999999, -73.69077589999999, 40.671297, -73.6556311],
    },
    name: "Lynbrook",
    place_name: "Lynbrook, NY ",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 17000000,
    },
    county: "Nassau County",
    state_short: "NY",
  },
  {
    id: "8KLUMjtkaVRyP5NgPeIJ",
    place_name: "Cape May County, NJ",
    state_short: "NJ",
    county: "Cape May County",
    name: "Cape May County",
    state: "New Jersey",
    created: {
      seconds: 1725911238,
      nanoseconds: 947000000,
    },
    components: {
      bbox: [38.788657, -75.1684619, 39.324375, -74.46493],
      text: "Cape May County",
      types: ["administrative_area_level_2", "political"],
      center: [-74.8273184, 39.105884],
    },
  },
  {
    id: "8KqJ43IFO19QZlOikht9",
    county: "Warren County",
    created: {
      seconds: 1725911238,
      nanoseconds: 962000000,
    },
    state: "New Jersey",
    name: "Warren County",
    place_name: "Warren County, NJ",
    components: {
      bbox: [40.5917761, -75.2039209, 41.09441, -74.76942509999999],
      text: "Warren County",
      center: [-74.92087719999999, 40.8841854],
      types: ["administrative_area_level_2", "political"],
    },
    state_short: "NJ",
  },
  {
    id: "8htCumrupHg3F2CuErEh",
    state_short: "NY",
    name: "Hollis",
    created: {
      seconds: 1725923327,
      nanoseconds: 508000000,
    },
    state: "New York",
    county: "Queens County",
    components: {
      types: ["neighborhood", "political"],
      text: "Hollis",
      bbox: [40.703182, -73.7712562, 40.718672, -73.74987],
      center: [-73.762495, 40.7112203],
    },
    place_name: "Hollis, Queens, NY",
  },
  {
    id: "958gek11wKz0XLTzpnhy",
    created: {
      seconds: 1725921195,
      nanoseconds: 501000000,
    },
    components: {
      center: [-73.9903489, 40.740083],
      types: ["neighborhood", "political"],
      bbox: [40.7383561, -73.9941752, 40.7448022, -73.9833605],
      text: "Flatiron District",
    },
    state: "New York",
    place_name: "Flatiron District, New York, NY",
    name: "Flatiron District",
    state_short: "NY",
    county: "New York County",
  },
  {
    id: "95noyAwoJoZS55ejSnvb",
    name: "Mott Haven",
    components: {
      types: ["neighborhood", "political"],
      text: "Mott Haven",
      center: [-73.9228881, 40.8091068],
      bbox: [40.7990266, -73.9325985, 40.8194526, -73.9029856],
    },
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 850000000,
    },
    state: "New York",
    state_short: "NY",
    place_name: "Mott Haven, Bronx, NY",
  },
  {
    id: "9ACk2NuBtTIiYR288S7p",
    name: "Bellmore",
    state: "New York",
    created: {
      seconds: 1725915360,
      nanoseconds: 985000000,
    },
    county: "Nassau County",
    place_name: "Bellmore, NY",
    state_short: "NY",
    components: {
      text: "Bellmore",
      center: [-73.5270709, 40.6687125],
      types: ["locality", "political"],
      bbox: [40.624677, -73.576914, 40.706379, -73.51337],
    },
  },
  {
    id: "9H1svQL5N8d7u6qcleRA",
    components: {
      text: "Upper East Side",
      types: ["neighborhood", "political"],
      center: [-73.9565551, 40.7735649],
      bbox: [40.7583091, -73.9732511, 40.7879499, -73.941962],
    },
    county: "New York County",
    state_short: "NY",
    name: "Upper East Side",
    place_name: "Upper East Side, New York, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 840000000,
    },
    state: "New York",
  },
  {
    id: "9NOSQ6XrIgXQyjdCq6DW",
    created: {
      seconds: 1725914391,
      nanoseconds: 713000000,
    },
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.8813824, -73.8991185, 40.8859971, -73.8875995],
      center: [-73.89313899999999, 40.8837203],
      text: "Van Cortlandt Village",
    },
    state: "New York",
    county: "Bronx County",
    place_name: "Van Cortlandt Village, Bronx, NY ",
    state_short: "NY",
    name: "Van Cortlandt Village",
  },
  {
    id: "9OVYkw0RKj8b3S8DIqWb",
    county: "New York County",
    components: {
      text: "Midtown Manhattan",
      bbox: [40.72686849999999, -74.0089488, 40.7741244, -73.95889869999999],
      types: ["neighborhood", "political"],
      center: [-73.9840195, 40.7549309],
    },
    place_name: "Midtown Manhattan, New York, NY",
    state_short: "NY",
    name: "Midtown",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
  },
  {
    id: "9OkN5qtat6ARGvOsr1x2",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 878000000,
    },
    state: "New York",
    components: {
      text: "Bloomfield",
      types: ["colloquial_area", "political"],
      bbox: [40.6112549197085, -74.1795484802915, 40.6139528802915, -74.1768505197085],
      center: [-74.17819949999999, 40.6126039],
    },
    county: "Richmond County",
    place_name: "Bloomfield, Staten Island, NY ",
    name: "Bloomfield",
  },
  {
    id: "9VFujDL59XQYpfjB9C5M",
    county: "Nassau County",
    name: "Muttontown",
    state_short: "NY",
    components: {
      types: ["locality", "political"],
      center: [-73.5476252, 40.8239883],
      bbox: [40.794809, -73.571467, 40.848607, -73.505749],
      text: "Muttontown",
    },
    place_name: "Muttontown, NY",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 59000000,
    },
  },
  {
    id: "9rBckXHIpmH4gjlSFRtC",
    created: {
      seconds: 1725915360,
      nanoseconds: 994000000,
    },
    place_name: "Carle Place, NY",
    state_short: "NY",
    state: "New York",
    county: "Nassau County",
    components: {
      text: "Carle Place",
      center: [-73.6104058, 40.7526008],
      types: ["locality", "political"],
      bbox: [40.7423181, -73.6245266, 40.7582661, -73.600084],
    },
    name: "Carle Place",
  },
  {
    id: "9xuV3is31olhvM40vj0I",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 567000000,
    },
    components: {
      text: "Battery Park City",
      bbox: [40.7045626, -74.0193666, 40.7186805, -74.0128113],
      types: ["neighborhood", "political"],
      center: [-74.0158441, 40.7115786],
    },
    place_name: "Battery Park City, New York, NY",
    state: "New York",
    county: "New York County",
    name: "Battery Park City",
  },
  {
    id: "A5HvAfQHzgj3zyA5jIXR",
    created: {
      seconds: 1725915869,
      nanoseconds: 691000000,
    },
    state_short: "NJ",
    components: {
      bbox: [39.471523, -75.46026499999999, 39.5788, -75.3070089],
      types: ["locality", "political"],
      text: "Quinton",
      center: [-75.4125645, 39.5459869],
    },
    county: "Salem County",
    place_name: "Quinton, Salem County, NJ",
    state: "New Jersey",
    name: "Quinton",
  },
  {
    id: "ACSAtqbftVXD4eYprsfk",
    components: {
      center: [-73.9060662, 40.640248],
      bbox: [40.6222886, -73.92165849999999, 40.6577511, -73.8770746],
      text: "Canarsie",
      types: ["neighborhood", "political"],
    },
    county: "Kings County",
    state_short: "NY",
    state: "New York",
    place_name: "Canarsie, Brooklyn, NY",
    name: "Canarsie",
    created: {
      seconds: 1725914391,
      nanoseconds: 836000000,
    },
  },
  {
    id: "AIpHMxEKWBHBIHdla6ng",
    county: "Middlesex County",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 646000000,
    },
    state: "New Jersey",
    place_name: "Sayreville, Middlesex County, NJ",
    components: {
      text: "Sayreville",
      bbox: [40.416597, -74.3861581, 40.5093071, -74.243652],
      types: ["locality", "political"],
      center: [-74.360846, 40.45940210000001],
    },
    name: "Sayreville",
  },
  {
    id: "AMpptCgAqXO5gWudVgVf",
    components: {
      text: "Prospect Heights",
      types: ["neighborhood", "political"],
      bbox: [40.6717057, -73.9774381, 40.6840388, -73.9626109],
      center: [-73.9668408, 40.6774196],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 901000000,
    },
    name: "Prospect Heights",
    state: "New York",
    county: "Kings County",
    state_short: "NY",
    place_name: "Prospect Heights, Brooklyn, NY",
  },
  {
    id: "AWKEOsqFugUQPjeJjY7a",
    components: {
      center: [-74.1159755, 40.5739937],
      bbox: [40.57264471970849, -74.11732448029151, 40.57534268029149, -74.11462651970851],
      text: "New Dorp",
      types: ["colloquial_area", "political"],
    },
    county: "Richmond County",
    state: "New York",
    name: "New Dorp",
    state_short: "NY",
    place_name: "New Dorp, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 912000000,
    },
  },
  {
    id: "AWnxMCMq6ASj15IMkBAm",
    components: {
      bbox: [40.5959390197085, -74.0882376802915, 40.5986369802915, -74.08553971970849],
      text: "Old Town",
      types: ["neighborhood", "political"],
      center: [-74.08699519999999, 40.5972845],
    },
    county: "Richmond County",
    place_name: "Old Town, Staten Island, NY ",
    state_short: "NY",
    state: "New York",
    name: "Old Town",
    created: {
      seconds: 1725914391,
      nanoseconds: 925000000,
    },
  },
  {
    id: "Acmct58dWjzYXMA7vaC3",
    county: "New York County",
    created: {
      seconds: 1725914391,
      nanoseconds: 870000000,
    },
    place_name: "Financial District, New York, NY",
    name: "Financial District",
    components: {
      center: [-74.00827869999999, 40.7077143],
      bbox: [40.7005852, -74.0193666, 40.7186805, -73.9993878],
      types: ["neighborhood", "political"],
      text: "Financial District",
    },
    state_short: "NY",
    state: "New York",
  },
  {
    id: "AyXOpovr1sBCoyQc0Bqs",
    state: "New York",
    components: {
      bbox: [40.6671133, -73.9242677, 40.69077, -73.9000497],
      text: "Ocean Hill",
      types: ["neighborhood", "political"],
      center: [-73.9108212, 40.6782737],
    },
    county: "Kings County",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 499000000,
    },
    name: "Ocean Hill",
    place_name: "Ocean Hill, Brooklyn, NY",
  },
  {
    id: "B2wVf5xEYw0B62JDSarR",
    components: {
      bbox: [40.5852616, -73.8079119, 40.6037391, -73.7860539],
      types: ["neighborhood", "political"],
      text: "Arverne",
      center: [-73.7977928, 40.592658],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 575000000,
    },
    state_short: "NY",
    state: "New York",
    name: "Arverne",
    place_name: "Arverne, Queens, NY",
    county: "Queens County",
  },
  {
    id: "B36N5HfuGwSJfvixRrlL",
    created: {
      seconds: 1725915869,
      nanoseconds: 854000000,
    },
    components: {
      types: ["locality", "political"],
      text: "Hillsborough Township",
      bbox: [40.43138, -74.79784099999999, 40.5647619, -74.5721361],
      center: [-74.6374329, 40.5081222],
    },
    name: "Hillsborough Township",
    state_short: "NJ",
    county: "Somerset County",
    place_name: "Hillsborough Township, Somerset County, NJ",
    state: "New Jersey",
  },
  {
    id: "B7UNWaRpeDH6xRQPVawt",
    place_name: "Midtown East, New York, NY",
    state: "New York",
    components: {
      center: [-73.9718815, 40.7571432],
      bbox: [40.7313276, -73.98879459999999, 40.7641613, -73.9584941],
      types: ["neighborhood", "political"],
      text: "Midtown East",
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 500000000,
    },
    state_short: "NY",
    name: "Midtown East",
    county: "New York County",
  },
  {
    id: "BIOFL1RmEFzZiCblyMFZ",
    components: {
      types: ["neighborhood", "political"],
      text: "Arrochar",
      bbox: [40.5880917, -74.0858662, 40.600585, -74.06184329999999],
      center: [-74.0704232, 40.5967312],
    },
    state: "New York",
    place_name: "Arrochar, Staten Island, NY ",
    name: "Arrochar",
    created: {
      seconds: 1725914391,
      nanoseconds: 917000000,
    },
    county: "Richmond County",
    state_short: "NY",
  },
  {
    id: "BIYafsTjYoDz6m9KG1EJ",
    place_name: "East Atlantic Beach, NY",
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    components: {
      center: [-73.7095365, 40.5849014],
      text: "East Atlantic Beach",
      types: ["locality", "political"],
      bbox: [40.579144, -73.718104, 40.5915041, -73.7010751],
    },
    name: "East Atlantic Beach",
    created: {
      seconds: 1725915361,
      nanoseconds: 26000000,
    },
  },
  {
    id: "BMlgKZpHhz5OMYpmps27",
    place_name: "Stone Harbor, Cape May County, NJ",
    state: "New Jersey",
    state_short: "NJ",
    county: "Cape May County",
    components: {
      center: [-74.76436079999999, 39.0464066],
      bbox: [39.017527, -74.7899429, 39.0659489, -74.747013],
      types: ["locality", "political"],
      text: "Stone Harbor",
    },
    name: "Stone Harbor",
    created: {
      seconds: 1725915869,
      nanoseconds: 646000000,
    },
  },
  {
    id: "BViLxVrKfyYmjDidSyNy",
    state: "New York",
    state_short: "NY",
    name: "Bushwick",
    created: {
      seconds: 1725914391,
      nanoseconds: 738000000,
    },
    place_name: "Bushwick, Brooklyn, NY",
    components: {
      bbox: [40.679768, -73.9419668, 40.7093928, -73.9013609],
      types: ["neighborhood", "political"],
      text: "Bushwick",
      center: [-73.9170604, 40.6957755],
    },
    county: "Kings County",
  },
  {
    id: "BZYyX30Dca7bNdjGvg6D",
    created: {
      seconds: 1725921195,
      nanoseconds: 576000000,
    },
    county: "Queens County",
    name: "South Ozone Park",
    state_short: "NY",
    place_name: "South Ozone Park, Queens, NY",
    state: "New York",
    components: {
      bbox: [40.659734, -73.84216649999999, 40.6938801, -73.8010314],
      types: ["neighborhood", "political"],
      center: [-73.8124984, 40.6764003],
      text: "South Ozone Park",
    },
  },
  {
    id: "BfNnGdXziSfcw1SyUgHZ",
    name: "Woodside",
    place_name: "Woodside, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Woodside",
      bbox: [40.7350351, -73.9169745, 40.7673528, -73.89006069999999],
      center: [-73.9068858, 40.7532704],
    },
    county: "Queens County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
  },
  {
    id: "BjG8lmsMX55e1Y5Wh7qH",
    state_short: "NY",
    name: "Manhasset",
    place_name: "Manhasset, NY",
    components: {
      types: ["locality", "political"],
      center: [-73.6995749, 40.7978787],
      text: "Manhasset",
      bbox: [40.7698909, -73.715942, 40.811796, -73.667439],
    },
    created: {
      seconds: 1725915361,
      nanoseconds: 13000000,
    },
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "Bo4ObXR0Wmh8uNeZddSw",
    components: {
      center: [-75.2990798, 39.4590032],
      text: "Shiloh",
      types: ["locality", "political"],
      bbox: [39.4528261, -75.3113329, 39.4660571, -75.27993099999999],
    },
    place_name: "Shiloh, Cumberland County, NJ",
    county: "Cumberland County",
    state: "New Jersey",
    name: "Shiloh",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 638000000,
    },
  },
  {
    id: "ByqXjE5KlXbXG4UHC3eE",
    state: "New York",
    components: {
      types: ["locality", "political"],
      bbox: [40.8052198, -73.7406879, 40.8732729, -73.6496729],
      center: [-73.69818579999999, 40.8256561],
      text: "Port Washington",
    },
    state_short: "NY",
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 59000000,
    },
    name: "Port Washington",
    place_name: "Port Washington, NY",
  },
  {
    id: "CDMnMqVm4ZVrUjR62AX0",
    components: {
      bbox: [40.6742488, -73.7869741, 40.7088107, -73.74410689999999],
      text: "St. Albans",
      center: [-73.76436880000001, 40.6895283],
      types: ["neighborhood", "political"],
    },
    county: "Queens County",
    state_short: "NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 447000000,
    },
    name: "St. Albans",
    state: "New York",
    place_name: "St. Albans, Queens, NY",
  },
  {
    id: "CSVJlAgcE5pC7NbzJISg",
    state: "New York",
    components: {
      bbox: [40.8493068197085, -73.8678730802915, 40.8520047802915, -73.86517511970848],
      types: ["colloquial_area", "political"],
      text: "Bronxdale",
      center: [-73.86652409999999, 40.8506558],
    },
    place_name: "Bronxdale, Bronx, NY ",
    name: "Bronxdale",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 763000000,
    },
    state_short: "NY",
  },
  {
    id: "Cd9iZtaqE1TzDSpEqDaS",
    place_name: "Hackensack, Bergen County, NJ",
    county: "Bergen County",
    components: {
      bbox: [40.8587798, -74.0679318, 40.9132537, -74.0248865],
      types: ["locality", "political"],
      center: [-74.0434736, 40.8859325],
      text: "Hackensack",
    },
    name: "Hackensack",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 831000000,
    },
    state_short: "NJ",
  },
  {
    id: "CeisQ6ujfCaMLy1Ve0z8",
    state: "New York",
    name: "Roslyn",
    state_short: "NY",
    place_name: "Roslyn, NY",
    components: {
      center: [-73.6509622, 40.7998227],
      bbox: [40.75963489999999, -73.6690239, 40.828406, -73.608141],
      types: ["locality", "political"],
      text: "Roslyn",
    },
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 68000000,
    },
  },
  {
    id: "CkdqAT2LtdF1RWCy13VO",
    name: "Great Neck",
    created: {
      seconds: 1725915361,
      nanoseconds: 16000000,
    },
    components: {
      text: "Great Neck",
      center: [-73.7284647, 40.8006567],
      bbox: [40.7759518, -73.7575112, 40.8139188, -73.7101138],
      types: ["locality", "political"],
    },
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    place_name: "Great Neck, NY",
  },
  {
    id: "CrIhHxUdH7beeGkHiwOe",
    state_short: "NY",
    place_name: "New Brighton, Staten Island, NY ",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 989000000,
    },
    name: "New Brighton",
    components: {
      center: [-74.090226, 40.6404369],
      bbox: [40.635449, -74.0961691, 40.6474079, -74.0865498],
      text: "New Brighton",
      types: ["neighborhood", "political"],
    },
    state: "New York",
  },
  {
    id: "D5YTtVtm23NPGHanGww6",
    created: {
      seconds: 1725915869,
      nanoseconds: 679000000,
    },
    components: {
      center: [-74.8290555, 40.8539879],
      types: ["locality", "political"],
      bbox: [40.8280519, -74.8504519, 40.8825498, -74.801917],
      text: "Hackettstown",
    },
    county: "Warren County",
    place_name: "Hackettstown, Warren County, NJ",
    state: "New Jersey",
    state_short: "NJ",
    name: "Hackettstown",
  },
  {
    id: "DKkQJkLg0GpmMTgLdZhp",
    place_name: "Salem, Salem County, NJ",
    components: {
      types: ["locality", "political"],
      text: "Salem",
      bbox: [39.5548741, -75.49496909999999, 39.584022, -75.452387],
      center: [-75.46714229999999, 39.5717796],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 691000000,
    },
    state_short: "NJ",
    name: "Salem",
    county: "Salem County",
    state: "New Jersey",
  },
  {
    id: "DNbMzE0WRA2usmObrIln",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 675000000,
    },
    state: "New Jersey",
    name: "Ewing Township",
    components: {
      types: ["locality", "political"],
      center: [-74.79091249999999, 40.2599864],
      bbox: [40.23283989999999, -74.8565078, 40.2922079, -74.748806],
      text: "Ewing Township",
    },
    place_name: "Ewing Township, Mercer County, NJ",
    county: "Mercer County",
  },
  {
    id: "DXl8zrsVTBRAVpswRfTH",
    name: "Garden City",
    county: "Nassau County",
    state_short: "NY",
    place_name: "Garden City, NY",
    state: "New York",
    components: {
      text: "Garden City",
      types: ["locality", "political"],
      bbox: [40.705658, -73.682924, 40.7475659, -73.58000679999999],
      center: [-73.6342954, 40.7267682],
    },
    created: {
      seconds: 1725915360,
      nanoseconds: 984000000,
    },
  },
  {
    id: "DbayYBXdqbORJx10tjSj",
    state: "New York",
    components: {
      center: [-73.9550857, 40.7844653],
      text: "Carnegie Hill",
      types: ["neighborhood", "political"],
      bbox: [40.7787704, -73.9605188, 40.787869, -73.9493608],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 527000000,
    },
    state_short: "NY",
    place_name: "Carnegie Hill, New York, NY",
    name: "Carnegie Hill",
    county: "New York County",
  },
  {
    id: "DnEBKA89rpLhiqdKbQKb",
    place_name: "Concourse, Bronx, NY",
    county: "Bronx County",
    name: "Concourse",
    state: "New York",
    components: {
      bbox: [40.818436, -73.9320059, 40.838479, -73.90722889999999],
      center: [-73.9227554, 40.8316761],
      text: "Concourse",
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 834000000,
    },
    state_short: "NY",
  },
  {
    id: "DwsHfeWi1c3VV7S8F039",
    county: "New York County",
    name: "Nolita",
    created: {
      seconds: 1725921195,
      nanoseconds: 501000000,
    },
    components: {
      center: [-73.99486069999999, 40.7230413],
      types: ["neighborhood", "political"],
      bbox: [40.719431, -73.9982402, 40.7250838, -73.9926194],
      text: "Nolita",
    },
    state_short: "NY",
    place_name: "Nolita, New York, NY",
    state: "New York",
  },
  {
    id: "ECA2UHppEzP1Wbagx4Xn",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    name: "Co-op City",
    state: "New York",
    county: "Bronx County",
    state_short: "NY",
    components: {
      bbox: [40.8725399197085, -73.83079338029152, 40.8752378802915, -73.82809541970852],
      text: "Co-op City",
      types: ["colloquial_area", "political"],
      center: [-73.82944440000001, 40.8738889],
    },
    place_name: "Co-op City, Bronx, NY ",
  },
  {
    id: "EIgVQ8UViyRA6avkV4OY",
    state: "New York",
    name: "Murray Hill",
    state_short: "NY",
    county: "Queens County",
    place_name: "Murray Hill, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Murray Hill",
      bbox: [40.7530824, -73.8246781, 40.771727, -73.789424],
      center: [-73.8073261, 40.7634996],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 583000000,
    },
  },
  {
    id: "EQJgwoLTqQ5YufzlkDdx",
    place_name: "Bergen County, NJ",
    components: {
      types: ["administrative_area_level_2", "political"],
      text: "Bergen County",
      bbox: [40.76159, -74.272226, 41.1332421, -73.8939789],
      center: [-74.07701, 40.9262762],
    },
    state_short: "NJ",
    state: "New Jersey",
    created: {
      seconds: 1725911238,
      nanoseconds: 928000000,
    },
    name: "Bergen County",
    county: "Bergen County",
  },
  {
    id: "ERfkftC2Ff9nUREbr1o6",
    county: "Queens County",
    state_short: "NY",
    name: "Howard Beach",
    place_name: "Howard Beach, Queens, NY",
    components: {
      bbox: [40.6443744, -73.85969639999999, 40.666065, -73.8367225],
      center: [-73.8429989, 40.6571222],
      text: "Howard Beach",
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 572000000,
    },
    state: "New York",
  },
  {
    id: "EZDvfh3kTQWJ0HAbjRFX",
    state: "New York",
    components: {
      types: ["locality", "political"],
      text: "Long Beach",
      center: [-73.65790799999999, 40.5884373],
      bbox: [40.5746319, -73.7013991, 40.5958391, -73.6357019],
    },
    county: "Nassau County",
    place_name: "Long Beach, NY",
    state_short: "NY",
    name: "Long Beach",
    created: {
      seconds: 1725915361,
      nanoseconds: 1000000,
    },
  },
  {
    id: "EgOd267gRrtZN5p2kK6s",
    state_short: "NJ",
    county: "Passaic County",
    name: "Paterson",
    components: {
      center: [-74.17181099999999, 40.9167654],
      bbox: [40.8886819, -74.2061969, 40.94159, -74.129233],
      text: "Paterson",
      types: ["locality", "political"],
    },
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 811000000,
    },
    place_name: "Paterson, Passaic County, NJ",
  },
  {
    id: "EiVkdkiP5iGCZnyT1W8C",
    state: "New Jersey",
    state_short: "NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 972000000,
    },
    name: "Morris County",
    place_name: "Morris County, NJ",
    components: {
      center: [-74.54632819999999, 40.8336038],
      types: ["administrative_area_level_2", "political"],
      text: "Morris County",
      bbox: [40.6484469, -74.8898189, 41.08611399999999, -74.2665911],
    },
    county: "Morris County",
  },
  {
    id: "Etlxx7blwIfcdKjmTmg4",
    place_name: "Hopatcong, Sussex County, NJ",
    state_short: "NJ",
    county: "Sussex County",
    components: {
      center: [-74.6593281, 40.9328762],
      types: ["locality", "political"],
      text: "Hopatcong",
      bbox: [40.91085289999999, -74.697709, 40.9994808, -74.613751],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 699000000,
    },
    name: "Hopatcong",
    state: "New Jersey",
  },
  {
    id: "EukaLSxebuuqZZfzPpOc",
    state: "New York",
    name: "Ridgewood",
    state_short: "NY",
    county: "Queens County",
    components: {
      types: ["neighborhood", "political"],
      text: "Ridgewood",
      bbox: [40.6917298, -73.92405769999999, 40.7139974, -73.8888254],
      center: [-73.9018292, 40.7043986],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 754000000,
    },
    place_name: "Ridgewood, Queens, NY",
  },
  {
    id: "ExqQ4SPAQ1WJ8MxAliI3",
    created: {
      seconds: 1725914391,
      nanoseconds: 877000000,
    },
    place_name: "Woodlawn Heights, Bronx, NY ",
    state: "New York",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Woodlawn Heights",
      bbox: [40.8951779, -73.8770041, 40.90271, -73.8615768],
      center: [-73.8668996, 40.8976347],
    },
    name: "Woodlawn Heights",
    county: "Bronx County",
  },
  {
    id: "F4g8XRIdI3HiuTmhXMPF",
    state_short: "NY",
    place_name: "Riverdale, Bronx, NY",
    state: "New York",
    county: "Bronx County",
    name: "Riverdale",
    components: {
      text: "Riverdale",
      center: [-73.9088276, 40.89961830000001],
      types: ["neighborhood", "political"],
      bbox: [40.8755781, -73.92459819999999, 40.9040101, -73.896705],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
  },
  {
    id: "F8SU0qlFfBzykjqEYAtu",
    name: "Pleasantville",
    county: "Atlantic County",
    state_short: "NJ",
    components: {
      types: ["locality", "political"],
      bbox: [39.356318, -74.5504638, 39.418938, -74.485844],
      center: [-74.5240422, 39.3898388],
      text: "Pleasantville",
    },
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 660000000,
    },
    place_name: "Pleasantville, Atlantic County, NJ",
  },
  {
    id: "FEvkqInlOHZhyWvRxOnw",
    state: "New York",
    state_short: "NY",
    county: "Richmond County",
    name: "Tompkinsville",
    components: {
      center: [-74.0872892, 40.63259559999999],
      types: ["neighborhood", "political"],
      bbox: [40.62866770000001, -74.0972187, 40.6382556, -74.0689751],
      text: "Tompkinsville",
    },
    place_name: "Tompkinsville, Staten Island, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 877000000,
    },
  },
  {
    id: "FO8uGFho7P34AJ8OPsPr",
    state_short: "NJ",
    place_name: "Cherry Hill, Camden County, NJ",
    components: {
      center: [-75.0246312, 39.926813],
      text: "Cherry Hill",
      bbox: [39.8593501, -75.06617109999999, 39.9541879, -74.926935],
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 889000000,
    },
    name: "Cherry Hill",
    county: "Camden County",
    state: "New Jersey",
  },
  {
    id: "FU3lptW9d0PQ7EoDJ5zb",
    name: "East Brunswick",
    state_short: "NJ",
    place_name: "East Brunswick, Middlesex County, NJ",
    components: {
      bbox: [40.37110699999999, -74.48571679999999, 40.487319, -74.3558539],
      text: "East Brunswick",
      types: ["locality", "political"],
      center: [-74.4049778, 40.4316955],
    },
    state: "New Jersey",
    county: "Middlesex County",
    created: {
      seconds: 1725915869,
      nanoseconds: 857000000,
    },
  },
  {
    id: "FWx3wp064ClRyf5u3HdP",
    name: "Forest Hills",
    state: "New York",
    county: "Queens County",
    state_short: "NY",
    components: {
      text: "Forest Hills",
      bbox: [40.7043654, -73.85852179999999, 40.73990320000001, -73.8281504],
      types: ["neighborhood", "political"],
      center: [-73.8448469, 40.718106],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 774000000,
    },
    place_name: "Forest Hills, Queens, NY",
  },
  {
    id: "FXA4RBpM8gVj7RzG17Or",
    state: "New Jersey",
    name: "Moorestown",
    state_short: "NJ",
    county: "Burlington County",
    created: {
      seconds: 1725915869,
      nanoseconds: 920000000,
    },
    components: {
      text: "Moorestown",
      types: ["locality", "political"],
      center: [-74.948886, 39.9688817],
      bbox: [39.937802, -75.00550190000001, 40.013644, -74.8822259],
    },
    place_name: "Moorestown, Burlington County, NJ",
  },
  {
    id: "FZh7K7zpe0vlyjjIuFc2",
    county: "Queens County",
    name: "Malba",
    state: "New York",
    place_name: "Malba, Queens, NY ",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.8257325, 40.7906659],
      text: "Malba",
      bbox: [40.7845749, -73.83218579999999, 40.79763210000001, -73.8234794],
    },
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 520000000,
    },
  },
  {
    id: "Fqbc8bv5fOJ00NQyUpV6",
    county: "Queens County",
    state: "New York",
    name: "Rego Park",
    created: {
      seconds: 1725914391,
      nanoseconds: 719000000,
    },
    components: {
      types: ["neighborhood", "political"],
      text: "Rego Park",
      center: [-73.8624893, 40.72557219999999],
      bbox: [40.7108153, -73.8749546, 40.736372, -73.8517299],
    },
    state_short: "NY",
    place_name: "Rego Park, Queens, NY",
  },
  {
    id: "FteNCV8NWXeWI2E0w3mn",
    state_short: "NY",
    place_name: "Bay Terrace, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 746000000,
    },
    county: "Richmond County",
    state: "New York",
    components: {
      bbox: [40.5539288197085, -74.13551568029152, 40.5566267802915, -74.13281771970851],
      types: ["colloquial_area", "political"],
      text: "Bay Terrace",
      center: [-74.13416670000001, 40.5552778],
    },
    name: "Bay Terrace",
  },
  {
    id: "Fzvx29RJUxPRfMAcgzIW",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
    components: {
      bbox: [40.5797595, -73.9872413, 40.6100972, -73.9576462],
      types: ["neighborhood", "political"],
      text: "Gravesend",
      center: [-73.9749909, 40.5968007],
    },
    name: "Gravesend",
    state_short: "NY",
    place_name: "Gravesend, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
  },
  {
    id: "G1Xmr6umpQZp7vgUNCyJ",
    place_name: "North Bellmore, NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 780000000,
    },
    name: "North Bellmore",
    county: "Nassau County",
    components: {
      center: [-73.5334597, 40.69148999999999],
      text: "North Bellmore",
      types: ["locality", "political"],
      bbox: [40.6741369, -73.5592219, 40.706379, -73.52109879999999],
    },
    state_short: "NY",
    state: "New York",
  },
  {
    id: "G3jxBPGEL6BiDvr1GpVZ",
    place_name: "East New York, Brooklyn, NY",
    county: "Kings County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.6355521, -73.9039882, 40.679888, -73.855516],
      center: [-73.8759245, 40.6590529],
      text: "East New York",
    },
    name: "East New York",
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1725924822,
      nanoseconds: 394000000,
    },
  },
  {
    id: "G85lPxkGMBwYBw6JZwfR",
    name: "Middle Township",
    state_short: "NJ",
    components: {
      text: "Middle Township",
      bbox: [38.9832701, -74.935323, 39.1791179, -74.7157399],
      center: [-74.8451915, 39.1201616],
      types: ["locality", "political"],
    },
    place_name: "Middle Township, Cape May County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 700000000,
    },
    county: "Cape May County",
    state: "New Jersey",
  },
  {
    id: "GI7wjvtzaCkXqgoRrflz",
    state: "New York",
    place_name: "Rockaway Beach, Queens, NY",
    county: "Queens County",
    created: {
      seconds: 1725921195,
      nanoseconds: 573000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.5793794, -73.8311204, 40.593606, -73.8040302],
      center: [-73.81662589999999, 40.5860224],
      text: "Rockaway Beach",
      types: ["neighborhood", "political"],
    },
    name: "Rockaway Beach",
  },
  {
    id: "GITpRAkwQFRIu7IifsN5",
    place_name: "Todt Hill, Staten Island, NY",
    county: "Richmond County",
    name: "Todt Hill",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 918000000,
    },
    components: {
      bbox: [40.5800168, -74.11487509999999, 40.6103779, -74.0859766],
      center: [-74.10189749999999, 40.5975007],
      types: ["neighborhood", "political"],
      text: "Todt Hill",
    },
    state: "New York",
  },
  {
    id: "GNzMg9jvLesC6qBgDGAB",
    county: "Queens County",
    place_name: "Long Island City, Queens, NY",
    name: "Long Island City",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.727849, -73.9628577, 40.7630563, -73.9094829],
      text: "Long Island City",
      center: [-73.9485424, 40.744679],
    },
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 833000000,
    },
  },
  {
    id: "GO4CNQmjOvllwTO2tmQq",
    place_name: "Brooklyn Heights, Brooklyn, NY",
    state_short: "NY",
    state: "New York",
    name: "Brooklyn Heights",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 745000000,
    },
    components: {
      bbox: [40.68912359999999, -74.0051149, 40.7045029, -73.9896813],
      types: ["neighborhood", "political"],
      text: "Brooklyn Heights",
      center: [-73.9955523, 40.6959294],
    },
  },
  {
    id: "GSCz0a4Y1EpKtNk0jfVz",
    name: "New Hyde Park",
    created: {
      seconds: 1725915361,
      nanoseconds: 18000000,
    },
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    components: {
      types: ["locality", "political"],
      text: "New Hyde Park",
      bbox: [40.7233181, -73.69721, 40.7532691, -73.65187990000001],
      center: [-73.6879082, 40.7351018],
    },
    place_name: "New Hyde Park, NY",
  },
  {
    id: "GUheN8YgypB5473EH6xO",
    state_short: "NY",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.7407795, -73.99187909999999, 40.7472713, -73.9815661],
      text: "NoMad",
      center: [-73.9874105, 40.7446072],
    },
    county: "New York County",
    place_name: "NoMad, New York, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 521000000,
    },
    name: "NoMad",
  },
  {
    id: "GeVQUEW2dudA1Xhbziwk",
    place_name: "Hawthorne, Passaic County, NJ",
    name: "Hawthorne",
    created: {
      seconds: 1725915869,
      nanoseconds: 851000000,
    },
    components: {
      bbox: [40.9343298, -74.1715881, 40.9838651, -74.141048],
      types: ["locality", "political"],
      text: "Hawthorne",
      center: [-74.1537549, 40.9492649],
    },
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
  },
  {
    id: "GlFtIAFaiUWE4LK7IfSC",
    county: "Nassau County",
    state_short: "NY",
    components: {
      bbox: [40.829958, -73.63571, 40.856978, -73.5902479],
      center: [-73.62373880000001, 40.8353776],
      text: "Glen Head",
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725921859,
      nanoseconds: 812000000,
    },
    name: "Glen Head",
    state: "New York",
    place_name: "Glen Head, NY",
  },
  {
    id: "GslUULTS8dae7qQqH55e",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 682000000,
    },
    name: "Sparta",
    components: {
      bbox: [40.990047, -74.6884269, 41.1186109, -74.536002],
      center: [-74.63672559999999, 41.0360345],
      types: ["locality", "political"],
      text: "Sparta",
    },
    county: "Sussex County",
    place_name: "Sparta, Sussex County, NJ",
    state_short: "NJ",
  },
  {
    id: "Gz1VSLZECPQrVCELM6vY",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
    components: {
      bbox: [40.7091004, -74.0040592, 40.7344458, -73.9716661],
      text: "Lower East Side",
      center: [-73.9842724, 40.715033],
      types: ["neighborhood", "political"],
    },
    name: "Lower East Side",
    place_name: "Lower East Side, New York, NY",
    county: "New York County",
    state: "New York",
  },
  {
    id: "H4iixwrw5Uowr7TctoVs",
    components: {
      center: [-74.50348579999999, 39.327894],
      text: "Margate City",
      bbox: [39.31707110000001, -74.5229471, 39.3448859, -74.490633],
      types: ["locality", "political"],
    },
    state_short: "NJ",
    name: "Margate City",
    created: {
      seconds: 1725915869,
      nanoseconds: 851000000,
    },
    state: "New Jersey",
    place_name: "Margate City, Atlantic County, NJ",
    county: "Atlantic County",
  },
  {
    id: "H75bj0SHsAN4fhkfyy3a",
    place_name: "Uniondale, NY",
    state: "New York",
    components: {
      text: "Uniondale",
      center: [-73.5931669, 40.700611],
      types: ["locality", "political"],
      bbox: [40.684017, -73.6171008, 40.7475659, -73.574837],
    },
    created: {
      seconds: 1725915360,
      nanoseconds: 981000000,
    },
    name: "Uniondale",
    county: "Nassau County",
    state_short: "NY",
  },
  {
    id: "HF3YC6sPXbS2A60Lqczb",
    county: "Ocean County",
    created: {
      seconds: 1725911238,
      nanoseconds: 944000000,
    },
    name: "Ocean County",
    components: {
      text: "Ocean County",
      bbox: [39.475198, -74.5531051, 40.172402, -73.96541409999999],
      types: ["administrative_area_level_2", "political"],
      center: [-74.3118212, 39.9652553],
    },
    place_name: "Ocean County, NJ",
    state: "New Jersey",
    state_short: "NJ",
  },
  {
    id: "HGP8zqzF4iCMg7k4Ahvg",
    place_name: "Country Club, Bronx, NY ",
    state: "New York",
    state_short: "NY",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 833000000,
    },
    name: "Country Club",
    components: {
      bbox: [40.8378177197085, -73.82107118029151, 40.8405156802915, -73.8183732197085],
      text: "Country Club",
      types: ["colloquial_area", "political"],
      center: [-73.8197222, 40.8391667],
    },
  },
  {
    id: "HLw9rAgGoSerWQubKddd",
    state_short: "NY",
    place_name: "The Bronx, NY",
    components: {
      bbox: [40.7857429, -73.933808, 40.9175771, -73.74806],
      types: ["political", "sublocality", "sublocality_level_1"],
      center: [-73.8648268, 40.8447819],
      text: "Bronx County",
    },
    state: "New York",
    created: {
      seconds: 1725911049,
      nanoseconds: 53000000,
    },
    county: "Bronx County",
    name: "The Bronx",
  },
  {
    id: "HRDCQ3wP7jIX7lDntn01",
    name: "Red Hook",
    place_name: "Red Hook, Brooklyn, NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-74.00831889999999, 40.6733676],
      bbox: [40.6613687, -74.0202999, 40.68750470000001, -73.9950228],
      text: "Red Hook",
    },
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 709000000,
    },
    county: "Kings County",
    state: "New York",
  },
  {
    id: "HZpQQk32PyNCfNddA27N",
    created: {
      seconds: 1725914391,
      nanoseconds: 810000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.7279041, -73.93742, 40.75266329999999, -73.90885709999999],
      center: [-73.9196324, 40.7432759],
      types: ["neighborhood", "political"],
      text: "Sunnyside",
    },
    place_name: "Sunnyside, Queens, NY",
    state: "New York",
    county: "Queens County",
    name: "Sunnyside",
  },
  {
    id: "HhEKRROUSbzqqJSx8X4Y",
    state: "New York",
    name: "Sunnyside",
    state_short: "NY",
    place_name: "Sunnyside, Staten Island, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
    county: "Richmond County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.610163, -74.117004, 40.6206639, -74.097261],
      center: [-74.1026407, 40.6128507],
      text: "Sunnyside",
    },
  },
  {
    id: "Hi4dWFkr0EnuUlGBAkUX",
    created: {
      seconds: 1725923327,
      nanoseconds: 478000000,
    },
    state_short: "NY",
    components: {
      text: "Morningside Heights",
      center: [-73.9620581, 40.8105443],
      bbox: [40.80185549999999, -73.9711901, 40.818091, -73.9563131],
      types: ["neighborhood", "political"],
    },
    name: "Morningside Heights",
    state: "New York",
    county: "New York County",
    place_name: "Morningside Heights, New York, NY",
  },
  {
    id: "HwbN0jAeguM7kWufPO7Z",
    name: "SoHo",
    state: "New York",
    place_name: "SoHo, New York, NY",
    county: "New York County",
    components: {
      bbox: [40.7189454, -74.0054619, 40.7283442, -73.9958967],
      text: "SoHo",
      types: ["neighborhood", "political"],
      center: [-74.00191740000001, 40.7245908],
    },
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
  },
  {
    id: "IAjnWVQN5psXlPdVz8IW",
    created: {
      seconds: 1725915869,
      nanoseconds: 880000000,
    },
    state: "New Jersey",
    name: "Washington",
    components: {
      center: [-74.9793378, 40.7584326],
      bbox: [40.7446541, -74.9993811, 40.768524, -74.96210599999999],
      text: "Washington",
      types: ["locality", "political"],
    },
    county: "Warren County",
    state_short: "NJ",
    place_name: "Washington, Warren County, NJ",
  },
  {
    id: "IFSlrd7NjtRHoHfvX3ph",
    county: "Camden County",
    state: "New Jersey",
    name: "Voorhees Township",
    place_name: "Voorhees Township, Camden County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 871000000,
    },
    components: {
      text: "Voorhees Township",
      bbox: [39.809486, -75.0184739, 39.8738388, -74.9086069],
      center: [-74.961517, 39.8519447],
      types: ["locality", "political"],
    },
    state_short: "NJ",
  },
  {
    id: "IFvc5K6MWAueC70OHcaj",
    name: "Bound Brook",
    county: "Somerset County",
    place_name: "Bound Brook, Somerset County, NJ",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 685000000,
    },
    components: {
      center: [-74.53848889999999, 40.56843629999999],
      text: "Bound Brook",
      bbox: [40.5551959, -74.557675, 40.581725, -74.5237021],
      types: ["locality", "political"],
    },
    state_short: "NJ",
  },
  {
    id: "IGZWaeql5QEG535FRqbj",
    state: "New Jersey",
    state_short: "NJ",
    name: "Vineland",
    place_name: "Vineland, Cumberland County, NJ",
    components: {
      bbox: [39.3777149, -75.0871289, 39.568643, -74.896626],
      text: "Vineland",
      center: [-75.02596369999999, 39.4863773],
      types: ["locality", "political"],
    },
    county: "Cumberland County",
    created: {
      seconds: 1725915869,
      nanoseconds: 723000000,
    },
  },
  {
    id: "ILXPUp7PCFXA6fOVxvnL",
    county: "Nassau County",
    name: "Westbury",
    components: {
      text: "Westbury",
      center: [-73.5876273, 40.7556561],
      bbox: [40.745723, -73.6257859, 40.81619389999999, -73.5624057],
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915360,
      nanoseconds: 967000000,
    },
    state_short: "NY",
    place_name: "Westbury, NY",
    state: "New York",
  },
  {
    id: "IO0rEFPOPznBXIafOdao",
    created: {
      seconds: 1725914391,
      nanoseconds: 718000000,
    },
    state_short: "NY",
    name: "Throgs Neck",
    place_name: "Throgs Neck, Bronx, NY ",
    state: "New York",
    county: "Bronx County",
    components: {
      center: [-73.8213213, 40.818399],
      text: "Throgs Neck",
      bbox: [40.8041801, -73.8422038, 40.83637909999999, -73.7898019],
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "IeAdG3SI4bOEiKVA8d99",
    created: {
      seconds: 1725923327,
      nanoseconds: 454000000,
    },
    state: "New York",
    place_name: "Gramercy Park, New York, NY",
    state_short: "NY",
    name: "Gramercy Park",
    county: "New York County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.7313276, -73.98879459999999, 40.74018510000001, -73.9785298],
      text: "Gramercy Park",
      center: [-73.9844722, 40.7367783],
    },
  },
  {
    id: "IfXjNfFi9zYqfUy0bkg1",
    place_name: "Pelham Bay, Bronx, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 852000000,
    },
    state_short: "NY",
    county: "Bronx County",
    components: {
      bbox: [40.8299377, -73.8426039, 40.8590791, -73.82510309999999],
      types: ["neighborhood", "political"],
      text: "Pelham Bay",
      center: [-73.8304179, 40.8438486],
    },
    name: "Pelham Bay",
    state: "New York",
  },
  {
    id: "IsFz2Sd28KoG7YaDtMuq",
    name: "Elm Park",
    created: {
      seconds: 1725914392,
      nanoseconds: 11000000,
    },
    state_short: "NY",
    county: "Richmond County",
    components: {
      text: "Elm Park",
      types: ["neighborhood", "political"],
      center: [-74.14694899999999, 40.63108649999999],
      bbox: [40.62973751970849, -74.1482979802915, 40.63243548029149, -74.14560001970848],
    },
    state: "New York",
    place_name: "Elm Park, Staten Island, NY ",
  },
  {
    id: "J0gZkpK1D41vBmJ509Mm",
    created: {
      seconds: 1725921859,
      nanoseconds: 782000000,
    },
    name: "Great Neck Plaza",
    county: "Nassau County",
    components: {
      bbox: [40.780713, -73.734674, 40.79130809999999, -73.71550289999999],
      text: "Great Neck Plaza",
      types: ["locality", "political"],
      center: [-73.7265202, 40.7867681],
    },
    state_short: "NY",
    place_name: "Great Neck Plaza, NY ",
    state: "New York",
  },
  {
    id: "J4L1HW5sV6Cj0Mx2oanA",
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 0,
    },
    place_name: "Flower Hill, NY",
    components: {
      types: ["locality", "political"],
      text: "Flower Hill",
      bbox: [40.79715590000001, -73.69539929999999, 40.817303, -73.652638],
      center: [-73.68124089999999, 40.8073228],
    },
    name: "Flower Hill",
  },
  {
    id: "JALRw5bfb4HNQnYobI8a",
    county: "New York County",
    state: "New York",
    name: "Civic Center",
    place_name: "Civic Center, New York, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 816000000,
    },
    state_short: "NY",
    components: {
      center: [-74.00283639999999, 40.7140519],
      types: ["neighborhood", "political"],
      text: "Civic Center",
      bbox: [40.7097756, -74.00873419999999, 40.71776149999999, -73.9982199],
    },
  },
  {
    id: "JCsSFZRl5Rr1ofqYoJ3T",
    name: "Hunts Point",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.8803315, 40.8094385],
      bbox: [40.8005801, -73.9029856, 40.822979, -73.86840409999999],
      text: "Hunts Point",
    },
    state_short: "NY",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 803000000,
    },
    place_name: "Hunts Point, Bronx, NY",
    state: "New York",
  },
  {
    id: "JWhbaTuVuwF40Nx14M8j",
    state_short: "NJ",
    state: "New Jersey",
    county: "Bergen County",
    created: {
      seconds: 1725915869,
      nanoseconds: 723000000,
    },
    place_name: "Ridgewood, Bergen County, NJ",
    components: {
      text: "Ridgewood",
      bbox: [40.955272, -74.1490879, 41.007844, -74.0775589],
      center: [-74.11653129999999, 40.9792645],
      types: ["locality", "political"],
    },
    name: "Ridgewood",
  },
  {
    id: "JobuuVNCEJR4fVYQyHiR",
    created: {
      seconds: 1725915869,
      nanoseconds: 653000000,
    },
    state: "New Jersey",
    components: {
      text: "Atlantic City",
      center: [-74.4229266, 39.3642834],
      types: ["locality", "political"],
      bbox: [39.335544, -74.501592, 39.4176778, -74.404054],
    },
    state_short: "NJ",
    name: "Atlantic City",
    place_name: "Atlantic City, Atlantic County, NJ",
    county: "Atlantic County",
  },
  {
    id: "JpHdnKW0qSRRXwzMzDqS",
    name: "Ventnor City",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 809000000,
    },
    components: {
      bbox: [39.3229709, -74.5064309, 39.3584169, -74.45864100000001],
      center: [-74.4773735, 39.3403942],
      text: "Ventnor City",
      types: ["locality", "political"],
    },
    place_name: "Ventnor City, Atlantic County, NJ",
  },
  {
    id: "JqzukWImfpnFTftDYVSh",
    name: "Charleston",
    place_name: "Charleston, Staten Island, NY ",
    components: {
      center: [-74.2373672, 40.5367719],
      types: ["colloquial_area", "political"],
      text: "Charleston",
      bbox: [40.53542291970849, -74.23871618029149, 40.53812088029149, -74.23601821970848],
    },
    county: "Richmond County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 828000000,
    },
  },
  {
    id: "JrBTc267hR3Yuywq3Jcz",
    name: "Harlem",
    place_name: "Harlem, New York, NY",
    state_short: "NY",
    county: "New York County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 919000000,
    },
    components: {
      center: [-73.9464769, 40.8115504],
      bbox: [40.7827839, -73.9623589, 40.834443, -73.9286431],
      types: ["neighborhood", "political"],
      text: "Harlem",
    },
  },
  {
    id: "K6p83y4wTpTs3rPpsZfQ",
    place_name: "Pelham Gardens, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    components: {
      bbox: [40.8560789, -73.85659509999999, 40.8716631, -73.82680309999999],
      types: ["neighborhood", "political"],
      text: "Pelham Gardens",
      center: [-73.84343919999999, 40.8627726],
    },
    state_short: "NY",
    name: "Pelham Gardens",
    created: {
      seconds: 1725914391,
      nanoseconds: 877000000,
    },
  },
  {
    id: "KS0oFWzxdZdAIWvYyAsr",
    name: "South Valley Stream",
    state: "New York",
    place_name: "South Valley Stream, NY ",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 779000000,
    },
    components: {
      text: "South Valley Stream",
      types: ["locality", "political"],
      center: [-73.7176315, 40.6559368],
      bbox: [40.6444148, -73.72838899999999, 40.6646073, -73.708815],
    },
    county: "Nassau County",
  },
  {
    id: "KsrHTefR5poAaaNDBxZe",
    state_short: "NJ",
    place_name: "Trenton, Mercer County, NJ",
    state: "New Jersey",
    components: {
      text: "Trenton",
      types: ["locality", "political"],
      bbox: [40.183809, -74.819507, 40.248298, -74.728904],
      center: [-74.759717, 40.2205824],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    county: "Mercer County",
    name: "Trenton",
  },
  {
    id: "L3ijcz4w0eI25ZvFSqRs",
    name: "Corona",
    county: "Queens County",
    place_name: "Corona, Queens, NY",
    components: {
      bbox: [40.7339983, -73.8777685, 40.7604018, -73.84582209999999],
      types: ["neighborhood", "political"],
      text: "Corona",
      center: [-73.8642613, 40.7449859],
    },
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 817000000,
    },
    state_short: "NY",
  },
  {
    id: "L478aKoJMilIZSM2LlOv",
    place_name: "Lenox Hill, New York, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 547000000,
    },
    name: "Lenox Hill",
    components: {
      bbox: [40.7586825, -73.9732511, 40.775726, -73.949107],
      center: [-73.9602312, 40.7662315],
      text: "Lenox Hill",
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
    county: "New York County",
    state: "New York",
  },
  {
    id: "L5mYKxyxRS8SxUMbGjfK",
    county: "Queens County",
    created: {
      seconds: 1725914391,
      nanoseconds: 719000000,
    },
    name: "Bellerose Manor",
    place_name: "Bellerose Manor, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.7271909, 40.73576389999999],
      bbox: [40.7225804, -73.74830539999999, 40.7440232, -73.7105],
      text: "Bellerose Manor",
    },
    state_short: "NY",
    state: "New York",
  },
  {
    id: "L9vm2Eprjan9Movg8qoO",
    components: {
      bbox: [39.794947, -75.237218, 39.884502, -75.1303159],
      types: ["locality", "political"],
      text: "West Deptford",
      center: [-75.1895576, 39.8318378],
    },
    state: "New Jersey",
    place_name: "West Deptford, Gloucester County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 643000000,
    },
    state_short: "NJ",
    name: "West Deptford",
    county: "Gloucester County",
  },
  {
    id: "LDMb8ttlUCRza63P6GxN",
    name: "Stapleton",
    components: {
      bbox: [40.62514391970849, -74.07899038029149, 40.62784188029149, -74.07629241970848],
      center: [-74.07764139999999, 40.6264929],
      types: ["colloquial_area", "political"],
      text: "Stapleton",
    },
    state: "New York",
    place_name: "Stapleton, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    state_short: "NY",
    county: "Richmond County",
  },
  {
    id: "LKBWU6qPI3ySzpEJ4Joc",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 853000000,
    },
    county: "Bronx County",
    components: {
      center: [-73.8616476, 40.8631189],
      types: ["neighborhood", "political"],
      bbox: [40.8566197, -73.8710054, 40.869699, -73.84996129999999],
      text: "Allerton",
    },
    name: "Allerton",
    state: "New York",
    place_name: "Allerton, Bronx, NY",
  },
  {
    id: "LLA5DaJxJMXfcJYdst20",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 765000000,
    },
    county: "Bronx County",
    state: "New York",
    components: {
      bbox: [40.8102789, -73.8572423, 40.8243909, -73.8398042],
      text: "Castle Hill",
      types: ["neighborhood", "political"],
      center: [-73.8507279, 40.8176831],
    },
    name: "Castle Hill",
    place_name: "Castle Hill, Bronx, NY ",
  },
  {
    id: "LX4P44wlhibAdFAMLXpL",
    name: "Downtown Brooklyn",
    state_short: "NY",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 827000000,
    },
    place_name: "Downtown Brooklyn, Brooklyn, NY",
    components: {
      bbox: [40.6864741, -73.99172399999999, 40.7059085, -73.9785862],
      center: [-73.9845407, 40.6960679],
      text: "Downtown Brooklyn",
      types: ["neighborhood", "political"],
    },
    state: "New York",
  },
  {
    id: "LhdKeB0MpooZ3jRzYoxY",
    state: "New York",
    state_short: "NY",
    name: "Washington Heights",
    created: {
      seconds: 1725914391,
      nanoseconds: 835000000,
    },
    place_name: "Washington Heights, New York, NY",
    county: "New York County",
    components: {
      center: [-73.9393554, 40.8417082],
      text: "Washington Heights",
      types: ["neighborhood", "political"],
      bbox: [40.8281291, -73.9505678, 40.8595902, -73.9224529],
    },
  },
  {
    id: "LipMhk1sWnx3OODEHTNN",
    place_name: "Arden Heights, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    components: {
      center: [-74.1735044, 40.556413],
      types: ["neighborhood", "political"],
      bbox: [40.5406311, -74.1994792, 40.5670014, -74.1652654],
      text: "Arden Heights",
    },
    name: "Arden Heights",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 919000000,
    },
  },
  {
    id: "LiyCU1P4PrF5w9HI8ywh",
    place_name: "Brighton Beach, Brooklyn, NY",
    state: "New York",
    state_short: "NY",
    county: "Kings County",
    name: "Brighton Beach",
    components: {
      center: [-73.9596565, 40.5780706],
      types: ["neighborhood", "political"],
      bbox: [40.5731169, -73.96859239999999, 40.584843, -73.953411],
      text: "Brighton Beach",
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 864000000,
    },
  },
  {
    id: "LvMHqqQbWHkLhUm5vYL5",
    name: "Atlantic Beach",
    state: "New York",
    place_name: "Atlantic Beach, NY",
    components: {
      bbox: [40.5801839, -73.7432369, 40.594229, -73.7169759],
      center: [-73.72902069999999, 40.58899359999999],
      text: "Atlantic Beach",
      types: ["locality", "political"],
    },
    state_short: "NY",
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 66000000,
    },
  },
  {
    id: "LvafWTC5Vl03451367Jq",
    state: "New York",
    place_name: "Midwood, Brooklyn, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 754000000,
    },
    name: "Midwood",
    state_short: "NY",
    county: "Kings County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.6086071, -73.97640899999999, 40.6318593, -73.94411819999999],
      text: "Midwood",
      center: [-73.95997779999999, 40.6204388],
    },
  },
  {
    id: "Lz2hVaZBQ5AwyxMgdeE2",
    created: {
      seconds: 1725914391,
      nanoseconds: 922000000,
    },
    state: "New York",
    state_short: "NY",
    place_name: "Dongan Hills, Staten Island, NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-74.088452, 40.5906641],
      bbox: [40.5847149, -74.1008021, 40.5970592, -74.0814632],
      text: "Dongan Hills",
    },
    name: "Dongan Hills",
    county: "Richmond County",
  },
  {
    id: "M1iO06yQbiPspLmTex27",
    place_name: "Park Slope, Brooklyn, NY",
    state_short: "NY",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
    components: {
      text: "Park Slope",
      bbox: [40.6574724, -73.9927269, 40.6848525, -73.9701749],
      center: [-73.98142279999999, 40.6710672],
      types: ["neighborhood", "political"],
    },
    name: "Park Slope",
    state: "New York",
  },
  {
    id: "M6NklDu8SpsBblDbXs6T",
    place_name: "Floral Park, Queens, NY",
    state: "New York",
    name: "Floral Park",
    state_short: "NY",
    county: "Queens County",
    created: {
      seconds: 1725914391,
      nanoseconds: 766000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: [-73.70556239999999, 40.7379091],
      text: "Floral Park",
      bbox: [40.727227, -73.7176228, 40.7522482, -73.70039489999999],
    },
  },
  {
    id: "MWVLbPNaqoKCxj6TFN3b",
    components: {
      text: "Old Brookville",
      center: [-73.6048493, 40.8320441],
      types: ["locality", "political"],
      bbox: [40.8118604, -73.625982, 40.855035, -73.57700799999999],
    },
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 805000000,
    },
    name: "Old Brookville",
    county: "Nassau County",
    place_name: "Old Brookville, NY",
  },
  {
    id: "MlQKzDQBiSdeAZoOUFwC",
    state: "New York",
    components: {
      center: [-73.9125304, 40.665214],
      bbox: [40.650415, -73.9252491, 40.676727, -73.8996379],
      types: ["neighborhood", "political"],
      text: "Brownsville",
    },
    state_short: "NY",
    name: "Brownsville",
    created: {
      seconds: 1725914391,
      nanoseconds: 752000000,
    },
    place_name: "Brownsville, Brooklyn, NY",
    county: "Kings County",
  },
  {
    id: "MpSFGMCMj7MrbocPjeoY",
    components: {
      text: "Weehawken",
      types: ["locality", "political"],
      bbox: [40.7566009, -74.03269879999999, 40.780685, -74.0013271],
      center: [-74.02537149999999, 40.7663711],
    },
    county: "Hudson County",
    place_name: "Weehawken, Hudson County, NJ",
    state: "New Jersey",
    name: "Weehawken",
    created: {
      seconds: 1725915869,
      nanoseconds: 865000000,
    },
    state_short: "NJ",
  },
  {
    id: "MrFKJF8KFnL3RArbbgtr",
    components: {
      types: ["locality", "political"],
      text: "Oceanside",
      bbox: [40.6095029, -73.661943, 40.65739689999999, -73.6136729],
      center: [-73.6401296, 40.6387141],
    },
    created: {
      seconds: 1725915361,
      nanoseconds: 70000000,
    },
    state_short: "NY",
    state: "New York",
    name: "Oceanside",
    place_name: "Oceanside, NY",
    county: "Nassau County",
  },
  {
    id: "MvJ63JYODpd1eme49JiJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 869000000,
    },
    state: "New Jersey",
    county: "Gloucester County",
    state_short: "NJ",
    place_name: "Glassboro, Gloucester County, NJ",
    components: {
      text: "Glassboro",
      bbox: [39.678584, -75.168261, 39.73739, -75.05478500000001],
      center: [-75.1118422, 39.70289229999999],
      types: ["locality", "political"],
    },
    name: "Glassboro",
  },
  {
    id: "NWZ55sY9i0FRujFJEQxE",
    created: {
      seconds: 1725914391,
      nanoseconds: 823000000,
    },
    state: "New York",
    place_name: "Clinton Hill, Brooklyn, NY",
    components: {
      center: [-73.9661144, 40.6896834],
      bbox: [40.679558, -73.971446, 40.6979329, -73.95785099999999],
      text: "Clinton Hill",
      types: ["neighborhood", "political"],
    },
    county: "Kings County",
    name: "Clinton Hill",
    state_short: "NY",
  },
  {
    id: "NoXFBJPhSmFp6i9DGPPL",
    components: {
      types: ["neighborhood", "political"],
      text: "Douglaston",
      bbox: [40.74389559999999, -73.7571859, 40.78285289999999, -73.71386269999999],
      center: [-73.7433727, 40.76401509999999],
    },
    name: "Douglaston",
    state_short: "NY",
    state: "New York",
    county: "Queens County",
    place_name: "Douglaston, Queens, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 747000000,
    },
  },
  {
    id: "O4Z0cTI8sWoekM5D7tAK",
    county: "Nassau County",
    created: {
      seconds: 1725921859,
      nanoseconds: 765000000,
    },
    components: {
      types: ["locality", "political"],
      bbox: [40.8051109, -73.6490039, 40.828406, -73.6215856],
      text: "Roslyn Harbor",
      center: [-73.63707269999999, 40.8164891],
    },
    place_name: "Roslyn Harbor, NY",
    state: "New York",
    state_short: "NY",
    name: "Roslyn Harbor",
  },
  {
    id: "OEwSLfHs1Mqud74xroii",
    county: "Queens County",
    place_name: "South Jamaica, Queens, NY",
    state_short: "NY",
    state: "New York",
    name: "South Jamaica",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.7920918, 40.6804703],
      text: "South Jamaica",
      bbox: [40.6348399, -73.808984, 40.7325461, -73.725055],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 574000000,
    },
  },
  {
    id: "OWHjjYI4Spa0N4KrLSnc",
    state: "New York",
    place_name: "East Rockaway, NY",
    components: {
      bbox: [40.63375509999999, -73.679406, 40.6531968, -73.6556311],
      center: [-73.6695747, 40.6420477],
      types: ["locality", "political"],
      text: "East Rockaway",
    },
    created: {
      seconds: 1725921859,
      nanoseconds: 703000000,
    },
    name: "East Rockaway",
    state_short: "NY",
    county: "Nassau County",
  },
  {
    id: "Obe0keQcV43dVFNU0FBf",
    created: {
      seconds: 1725914391,
      nanoseconds: 824000000,
    },
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.79678, -73.9325059, 40.811779, -73.9021542],
      center: [-73.9166051, 40.8022025],
      text: "Port Morris",
    },
    state_short: "NY",
    state: "New York",
    place_name: "Port Morris, Bronx, NY",
    county: "Bronx County",
    name: "Port Morris",
  },
  {
    id: "PGDgtoctfXbt0wj6ynYn",
    place_name: "East Flatbush, Brooklyn, NY",
    name: "East Flatbush",
    components: {
      center: [-73.9299899, 40.6481656],
      types: ["neighborhood", "political"],
      text: "East Flatbush",
      bbox: [40.63172460000001, -73.952518, 40.665596, -73.9082588],
    },
    state: "New York",
    state_short: "NY",
    county: "Kings County",
    created: {
      seconds: 1725921195,
      nanoseconds: 566000000,
    },
  },
  {
    id: "PL1Cmhd0aByg9YeavOSu",
    components: {
      bbox: [40.8395655, -73.87430390000002, 40.8520313, -73.85340380000001],
      types: ["neighborhood", "political"],
      center: [-73.8706949, 40.8415796],
      text: "Van Nest",
    },
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 766000000,
    },
    county: "Bronx County",
    name: "Van Nest",
    place_name: "Van Nest, Bronx, NY",
    state: "New York",
  },
  {
    id: "PLTYzG9RnpdNngstc3Jr",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      text: "Middle Village",
      bbox: [40.7054529, -73.89090130000001, 40.7318485, -73.85962210000001],
      center: [-73.87425, 40.717372],
    },
    place_name: "Middle Village, Queens, NY",
    state_short: "NY",
    name: "Middle Village",
    county: "Queens County",
    created: {
      seconds: 1725914391,
      nanoseconds: 828000000,
    },
  },
  {
    id: "PRgfxlEaL6K0qyHK0gYv",
    state_short: "NY",
    name: "West Hempstead",
    created: {
      seconds: 1725921859,
      nanoseconds: 744000000,
    },
    components: {
      bbox: [40.6825339, -73.6681579, 40.7127009, -73.63180000000001],
      center: [-73.65012949999999, 40.7048242],
      types: ["locality", "political"],
      text: "West Hempstead",
    },
    place_name: "West Hempstead, NY",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "PcCVIstmdppj1INxUnys",
    components: {
      text: "Belvidere",
      bbox: [40.8177829, -75.0888761, 40.840947, -75.059128],
      types: ["locality", "political"],
      center: [-75.07767470000002, 40.82982],
    },
    name: "Belvidere",
    created: {
      seconds: 1725915869,
      nanoseconds: 850000000,
    },
    state_short: "NJ",
    county: "Warren County",
    place_name: "Belvidere, Warren County, NJ",
    state: "New Jersey",
  },
  {
    id: "Phtof4sFUAQZ54mSe4d2",
    name: "Lakeview",
    place_name: "Lakeview, NY",
    county: "Nassau County",
    components: {
      text: "Lakeview",
      center: [-73.6529475, 40.6772526],
      bbox: [40.6629379, -73.6625509, 40.690287, -73.6327369],
      types: ["locality", "political"],
    },
    state: "New York",
    created: {
      seconds: 1725921859,
      nanoseconds: 746000000,
    },
    state_short: "NY",
  },
  {
    id: "PjXbEuS38YXtlT06Tgh9",
    components: {
      bbox: [40.70808419999999, -74.0040592, 40.71936549999999, -73.9841008],
      text: "Chinatown",
      center: [-73.9970307, 40.7157509],
      types: ["neighborhood", "political"],
    },
    county: "New York County",
    name: "Chinatown",
    place_name: "Chinatown, New York, NY",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 558000000,
    },
    state: "New York",
  },
  {
    id: "PvqGz9Y3QF9TS1koehbv",
    county: "Somerset County",
    place_name: "North Plainfield, Somerset County, NJ",
    name: "North Plainfield",
    created: {
      seconds: 1725915869,
      nanoseconds: 849000000,
    },
    state: "New Jersey",
    components: {
      types: ["locality", "political"],
      text: "North Plainfield",
      center: [-74.4273743, 40.6301025],
      bbox: [40.6022161, -74.465242, 40.64177400000001, -74.413737],
    },
    state_short: "NJ",
  },
  {
    id: "QCWXsdZaLSZH2ySo2Sy1",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 857000000,
    },
    name: "Newton",
    county: "Sussex County",
    place_name: "Newton, Sussex County, NJ",
    components: {
      types: ["locality", "political"],
      text: "Newton",
      bbox: [41.038007, -74.77384909999999, 41.07139189999999, -74.7299929],
      center: [-74.7526651, 41.0581527],
    },
    state: "New Jersey",
  },
  {
    id: "QTO9AosEJrK2nFzzvihU",
    components: {
      text: "Passaic County",
      types: ["administrative_area_level_2", "political"],
      bbox: [40.8199381, -74.50321199999999, 41.2037883, -74.106786],
      center: [-74.35874729999999, 41.1231647],
    },
    state: "New Jersey",
    state_short: "NJ",
    place_name: "Passaic County, NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 961000000,
    },
    county: "Passaic County",
    name: "Passaic County",
  },
  {
    id: "QTWIdxHT1liUOg3iNEmL",
    county: "Queens County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.7523233, -73.7449165, 40.7784413, -73.71386269999999],
      text: "Little Neck",
      center: [-73.7315837, 40.7662087],
    },
    state: "New York",
    state_short: "NY",
    name: "Little Neck",
    place_name: "Little Neck, Queens, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 500000000,
    },
  },
  {
    id: "QbKz0OXRfY583RtiKf37",
    state: "New York",
    county: "Richmond County",
    name: "Grasmere",
    components: {
      bbox: [40.60280931970851, -74.08511278029151, 40.6055072802915, -74.08241481970849],
      types: ["neighborhood", "political"],
      text: "Grasmere",
      center: [-74.0837638, 40.6041583],
    },
    place_name: "Grasmere, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 918000000,
    },
    state_short: "NY",
  },
  {
    id: "R7LbmAvAkIgSSgSIqr5E",
    components: {
      bbox: [40.771543, -73.5689913, 40.8030119, -73.51503199999999],
      text: "Jericho",
      types: ["locality", "political"],
      center: [-73.5398476, 40.79204410000001],
    },
    state_short: "NY",
    name: "Jericho",
    place_name: "Jericho, NY",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 66000000,
    },
  },
  {
    id: "R8wncbrUhrrMyfuTN35X",
    place_name: "Silver Lake, Staten Island, NY",
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 870000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: [-74.0916944, 40.6245495],
      text: "Silver Lake",
      bbox: [40.6181259, -74.1075461, 40.63192979999999, -74.0830014],
    },
    name: "Silver Lake",
    county: "Richmond County",
  },
  {
    id: "RM7yudu3IhnM6OLUrtSZ",
    county: "New York County",
    state_short: "NY",
    created: {
      seconds: 1725924980,
      nanoseconds: 55000000,
    },
    name: "Central Park",
    components: {
      types: ["establishment", "park", "point_of_interest", "tourist_attraction"],
      center: [-73.9655834, 40.7825547],
      bbox: [40.7641334, -73.9830118, 40.8031268, -73.94972659999999],
      text: "Central Park",
    },
    place_name: "Central Park, New York, NY",
    state: "New York",
  },
  {
    id: "ROM9KJKv3OBDJ2371D5C",
    components: {
      bbox: [40.6077239, -74.14724919999999, 40.6278229, -74.10361209999999],
      types: ["neighborhood", "political"],
      center: [-74.1225608, 40.6130802],
      text: "Castleton Corners",
    },
    state_short: "NY",
    state: "New York",
    place_name: "Castleton Corners, Staten Island, NY",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 925000000,
    },
    name: "Castleton Corners",
  },
  {
    id: "RdIQOcVz9ch0G6jBb7dt",
    place_name: "Port Richmond, Staten Island, NY",
    state: "New York",
    county: "Richmond County",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 870000000,
    },
    name: "Port Richmond",
    components: {
      bbox: [40.62421579999999, -74.14622829999999, 40.64140099999999, -74.12126769999999],
      text: "Port Richmond",
      center: [-74.13606519999999, 40.6339267],
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "Rlrq6yKL4XlwsBXhme6L",
    place_name: "Long Branch, Monmouth County, NJ",
    county: "Monmouth County",
    state: "New Jersey",
    components: {
      types: ["locality", "political"],
      text: "Long Branch",
      center: [-73.9923596, 40.30427780000001],
      bbox: [40.260211, -74.01257989999999, 40.329783, -73.97166109999999],
    },
    state_short: "NJ",
    name: "Long Branch",
    created: {
      seconds: 1725915869,
      nanoseconds: 698000000,
    },
  },
  {
    id: "Ry7BMEORBeAXKKUIldDR",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
    components: {
      text: "Tribeca",
      center: [-74.0086323, 40.7162692],
      types: ["neighborhood", "political"],
      bbox: [40.7113379, -74.0137633, 40.72590780000001, -74.0018893],
    },
    place_name: "Tribeca, New York, NY",
    name: "Tribeca",
    state_short: "NY",
    county: "New York County",
  },
  {
    id: "RyBscHvZJHwjO9CIrVBf",
    components: {
      types: ["neighborhood", "political"],
      text: "Morrisania",
      bbox: [40.8204754, -73.9166379, 40.844582, -73.8928561],
      center: [-73.9058574, 40.8310814],
    },
    place_name: "Morrisania, Bronx, NY",
    name: "Morrisania",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    state: "New York",
    county: "Bronx County",
    state_short: "NY",
  },
  {
    id: "Ryuzi1FhQV2ZEZJ6g9ry",
    state_short: "NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 451000000,
    },
    place_name: "Springfield Gardens, Queens, NY ",
    name: "Springfield Gardens",
    state: "New York",
    county: "Queens County",
    components: {
      text: "Springfield Gardens",
      types: ["neighborhood", "political"],
      bbox: [40.6669817, -73.7870145, 40.6783073, -73.7711542],
      center: [-73.779798, 40.6715916],
    },
  },
  {
    id: "S1KNtztbsqyPZnsc2m2x",
    components: {
      text: "Jackson Township",
      bbox: [39.9990331, -74.469543, 40.17235489999999, -74.241539],
      types: ["locality", "political"],
      center: [-74.35874729999999, 40.0918895],
    },
    state: "New Jersey",
    place_name: "Jackson Township, Ocean County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 793000000,
    },
    county: "Ocean County",
    name: "Jackson Township",
    state_short: "NJ",
  },
  {
    id: "S6B5ZIHs4fAN28RgSMfo",
    name: "Syosset",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      text: "Syosset",
      bbox: [40.7861629, -73.5280131, 40.8433731, -73.4797719],
      center: [-73.502068, 40.8262101],
    },
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1725915360,
      nanoseconds: 979000000,
    },
    place_name: "Syosset, NY",
  },
  {
    id: "S8DSnEbvYQHoQ4GA0AeQ",
    created: {
      seconds: 1725914391,
      nanoseconds: 831000000,
    },
    place_name: "East Tremont, Bronx, NY ",
    county: "Bronx County",
    components: {
      bbox: [40.84402911970851, -73.89231828029149, 40.84672708029151, -73.88962031970848],
      center: [-73.8909693, 40.8453781],
      text: "East Tremont",
      types: ["colloquial_area", "political"],
    },
    name: "East Tremont",
    state: "New York",
    state_short: "NY",
  },
  {
    id: "SBPwm5dxnpdTusQz7zcZ",
    created: {
      seconds: 1725923327,
      nanoseconds: 451000000,
    },
    name: "Chelsea",
    components: {
      text: "Chelsea",
      center: [-74.00137370000002, 40.7465004],
      bbox: [40.7373582, -74.0088629, 40.7570384, -73.9877916],
      types: ["neighborhood", "political"],
    },
    state: "New York",
    state_short: "NY",
    county: "New York County",
    place_name: "Chelsea, New York, NY",
  },
  {
    id: "SLhNiK7dSVx3x0CsiMiN",
    components: {
      types: ["locality", "political"],
      text: "Egg Harbor Township",
      bbox: [39.2928469, -74.7238449, 39.466395, -74.4873219],
      center: [-74.6166192, 39.3822537],
    },
    place_name: "Egg Harbor Township, Atlantic County, NJ",
    state: "New Jersey",
    state_short: "NJ",
    name: "Egg Harbor Township",
    county: "Atlantic County",
    created: {
      seconds: 1725915869,
      nanoseconds: 857000000,
    },
  },
  {
    id: "SOrhXDHY9vR0ZNxzgmO9",
    state: "New York",
    place_name: "Morris Park, Bronx, NY",
    name: "Morris Park",
    components: {
      text: "Morris Park",
      center: [-73.8513735, 40.850332],
      bbox: [40.8429881, -73.865262, 40.857679, -73.8356029],
      types: ["neighborhood", "political"],
    },
    county: "Bronx County",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
  },
  {
    id: "SpaUaRKNUDBtVbNNiA5c",
    state: "New York",
    created: {
      seconds: 1725915360,
      nanoseconds: 998000000,
    },
    county: "Nassau County",
    place_name: "Plainview, NY",
    components: {
      center: [-73.4673454, 40.7764882],
      bbox: [40.7615989, -73.50367010000001, 40.805436, -73.444096],
      text: "Plainview",
      types: ["locality", "political"],
    },
    state_short: "NY",
    name: "Plainview",
  },
  {
    id: "SvIrOQ5NpeAWUe9BPgzv",
    name: "Pennsville Township",
    county: "Salem County",
    state_short: "NJ",
    place_name: "Pennsville Township, Salem County, NJ",
    components: {
      bbox: [39.56933, -75.5597921, 39.698813, -75.4633269],
      text: "Pennsville Township",
      center: [-75.5206297, 39.6552687],
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 660000000,
    },
    state: "New Jersey",
  },
  {
    id: "Svejsq1gu7Z6hiTfEmt8",
    components: {
      bbox: [40.818436, -73.92658399999999, 40.8363789, -73.90722889999999],
      types: ["neighborhood", "political"],
      center: [-73.9184388, 40.8255863],
      text: "Concourse Village",
    },
    name: "Concourse Village",
    place_name: "Concourse Village, Bronx, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 603000000,
    },
    state: "New York",
    state_short: "NY",
    county: "Bronx County",
  },
  {
    id: "Sx9d3y75eXG2IbnMU9Rn",
    place_name: "Greenwich Village, New York, NY",
    county: "New York County",
    components: {
      text: "Greenwich Village",
      center: [-74.0027418, 40.7335719],
      types: ["neighborhood", "political"],
      bbox: [40.7253563, -74.0106087, 40.742136, -73.9907956],
    },
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    name: "Greenwich Village",
  },
  {
    id: "T61euiG00V3a3T98wEHT",
    created: {
      seconds: 1725921195,
      nanoseconds: 571000000,
    },
    place_name: "Spuyten Duyvil, Bronx, NY ",
    county: "Bronx County",
    components: {
      bbox: [40.8755781, -73.92459819999999, 40.88624980000001, -73.9085051],
      center: [-73.9154069, 40.88116369999999],
      text: "Spuyten Duyvil",
      types: ["neighborhood", "political"],
    },
    state: "New York",
    name: "Spuyten Duyvil",
    state_short: "NY",
  },
  {
    id: "TCuO1lH2olTrYJWxtnxq",
    name: "Phillipsburg",
    state_short: "NJ",
    components: {
      bbox: [40.66617189999999, -75.2039209, 40.7064979, -75.16020890000001],
      center: [-75.1901761, 40.69370989999999],
      text: "Phillipsburg",
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    county: "Warren County",
    place_name: "Phillipsburg, Warren County, NJ",
    state: "New Jersey",
  },
  {
    id: "TDuH0PxrJvQlzwIJ1gz8",
    created: {
      seconds: 1725914391,
      nanoseconds: 852000000,
    },
    state: "New York",
    name: "Wakefield",
    state_short: "NY",
    place_name: "Wakefield, Bronx, NY",
    county: "Bronx County",
    components: {
      text: "Wakefield",
      types: ["neighborhood", "political"],
      center: [-73.8507279, 40.89655],
      bbox: [40.881978, -73.8675269, 40.9101091, -73.8383028],
    },
  },
  {
    id: "TIiIzfwAvDHPn32WxJYC",
    created: {
      seconds: 1725914391,
      nanoseconds: 918000000,
    },
    name: "Eltingville",
    state: "New York",
    place_name: "Eltingville, Staten Island, NY",
    county: "Richmond County",
    components: {
      center: [-74.156292, 40.5394463],
      bbox: [40.5309239, -74.1697863, 40.5610839, -74.1462731],
      text: "Eltingville",
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
  },
  {
    id: "TK1GLCDhhkzQdKnX4p6I",
    state: "New York",
    state_short: "NY",
    name: "Port Washington North",
    components: {
      text: "Port Washington North",
      center: [-73.70179689999999, 40.8448224],
      types: ["locality", "political"],
      bbox: [40.834381, -73.7079581, 40.85067, -73.6904869],
    },
    created: {
      seconds: 1725925688,
      nanoseconds: 777000000,
    },
    place_name: "Port Washington North, NY ",
    county: "Nassau County",
  },
  {
    id: "ThIZ4o8J2YsYsOhkuvwy",
    state_short: "NY",
    state: "New York",
    county: "Bronx County",
    components: {
      bbox: [40.86426669999999, -73.90590519999999, 40.885878, -73.8795037],
      types: ["neighborhood", "political"],
      text: "Jerome Park",
      center: [-73.8893661, 40.8734044],
    },
    name: "Jerome Park",
    created: {
      seconds: 1725914391,
      nanoseconds: 817000000,
    },
    place_name: "Jerome Park, Bronx, NY",
  },
  {
    id: "Tj3ka00pzEok8jEglofY",
    created: {
      seconds: 1725914391,
      nanoseconds: 925000000,
    },
    state: "New York",
    name: "Annadale",
    county: "Richmond County",
    place_name: "Annadale, Staten Island, NY ",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-74.17390569999999, 40.5319911],
      text: "Annadale",
      bbox: [40.519603, -74.18658719999999, 40.544354, -74.1545591],
    },
  },
  {
    id: "Tup304ZbiF3KKHCAlkTT",
    components: {
      bbox: [40.619447, -74.1261431, 40.6346369, -74.0971433],
      types: ["neighborhood", "political"],
      text: "West Brighton",
      center: [-74.10931409999999, 40.6270298],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 922000000,
    },
    place_name: "West Brighton, Staten Island, NY",
    name: "West Brighton",
    state_short: "NY",
    county: "Richmond County",
    state: "New York",
  },
  {
    id: "U1ynge5reT3AFzFOZeZh",
    created: {
      seconds: 1725914391,
      nanoseconds: 816000000,
    },
    county: "Kings County",
    components: {
      text: "Gowanus",
      center: [-73.9879753, 40.6751161],
      types: ["neighborhood", "political"],
      bbox: [40.6654866, -73.9988853, 40.6839576, -73.9808178],
    },
    state_short: "NY",
    place_name: "Gowanus, Brooklyn, NY",
    state: "New York",
    name: "Gowanus",
  },
  {
    id: "U2FjLPLN6tb99N1A96YY",
    state_short: "NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 437000000,
    },
    state: "New York",
    name: "Two Bridges",
    county: "New York County",
    place_name: "Two Bridges, New York, NY",
    components: {
      bbox: [40.70808419999999, -74.001267, 40.7144925, -73.9841008],
      text: "Two Bridges",
      types: ["neighborhood", "political"],
      center: [-73.99696, 40.7107469],
    },
  },
  {
    id: "USHSk8mMbHljZpBAypwA",
    state: "New York",
    place_name: "Kips Bay, New York, NY",
    state_short: "NY",
    county: "New York County",
    components: {
      center: [-73.9800645, 40.74232920000001],
      text: "Kips Bay",
      bbox: [40.734901, -73.98473369999999, 40.7463913, -73.9717143],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725923327,
      nanoseconds: 448000000,
    },
    name: "Kips Bay",
  },
  {
    id: "UawXff42t0Dh3LA8fdt5",
    county: "Gloucester County",
    state: "New Jersey",
    place_name: "Mantua Township, Gloucester County, NJ",
    components: {
      center: [-75.1821597, 39.7818322],
      bbox: [39.714821, -75.230098, 39.798809, -75.11381390000001],
      types: ["locality", "political"],
      text: "Mantua Township",
    },
    state_short: "NJ",
    name: "Mantua Township",
    created: {
      seconds: 1725915869,
      nanoseconds: 915000000,
    },
  },
  {
    id: "UfYDt8eJ95A9940B4DOh",
    county: "Morris County",
    components: {
      bbox: [40.7802309, -74.50144399999999, 40.81836089999999, -74.456181],
      types: ["locality", "political"],
      center: [-74.4815438, 40.79676670000001],
      text: "Morristown",
    },
    name: "Morristown",
    created: {
      seconds: 1725915869,
      nanoseconds: 849000000,
    },
    place_name: "Morristown, Morris County, NJ",
    state: "New Jersey",
    state_short: "NJ",
  },
  {
    id: "UnUgMzkJxHsaV36hlk4Q",
    created: {
      seconds: 1725921195,
      nanoseconds: 559000000,
    },
    place_name: "North Corona, Queens, NY",
    name: "North Corona",
    state_short: "NY",
    county: "Queens County",
    components: {
      types: ["neighborhood", "political"],
      text: "North Corona",
      bbox: [40.7482832, -73.8777685, 40.7604018, -73.852603],
      center: [-73.8669188, 40.7543725],
    },
    state: "New York",
  },
  {
    id: "UsixoNkR3urRfC7rtj3e",
    place_name: "Unionport, Bronx, NY ",
    name: "Unionport",
    components: {
      center: [-73.8501349, 40.8273229],
      bbox: [40.8259739197085, -73.85148388029151, 40.8286718802915, -73.84878591970849],
      text: "Unionport",
      types: ["colloquial_area", "political"],
    },
    state: "New York",
    county: "Bronx County",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 766000000,
    },
  },
  {
    id: "VOIhFXqJRvsCkBORvcwd",
    county: "Nassau County",
    name: "Baldwin",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725915360,
      nanoseconds: 984000000,
    },
    components: {
      bbox: [40.6381839, -73.623694, 40.6874508, -73.5968359],
      center: [-73.6119129, 40.6686336],
      types: ["locality", "political"],
      text: "Baldwin",
    },
    place_name: "Baldwin, NY",
  },
  {
    id: "VZeDclX0tbSmvQJznTSP",
    components: {
      text: "Somerset County",
      bbox: [40.373733, -74.79788099999999, 40.758288, -74.4017419],
      types: ["administrative_area_level_2", "political"],
      center: [-74.64004320000001, 40.5292099],
    },
    state: "New Jersey",
    state_short: "NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 972000000,
    },
    place_name: "Somerset County, NJ",
    name: "Somerset County",
    county: "Somerset County",
  },
  {
    id: "VnhtFO91xusf7kl4QD36",
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
    state_short: "NY",
    name: "Woodhaven",
    state: "New York",
    components: {
      center: [-73.8566087, 40.6901366],
      text: "Woodhaven",
      bbox: [40.6831835, -73.8690429, 40.6999388, -73.8441508],
      types: ["neighborhood", "political"],
    },
    place_name: "Woodhaven, Queens, NY",
    county: "Queens County",
  },
  {
    id: "VpKWJmst9lZmgoUIiaac",
    name: "Cobble Hill",
    state: "New York",
    place_name: "Cobble Hill, Brooklyn, NY",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 752000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.6829299, -74.0007037, 40.6915438, -73.9891732],
      center: [-73.9962255, 40.686536],
      text: "Cobble Hill",
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "VwXiHmrQGYaWsaY7KfVQ",
    state_short: "NY",
    name: "Great Neck Estates",
    state: "New York",
    county: "Nassau County",
    place_name: "Great Neck Estates, NY",
    components: {
      center: [-73.73679829999999, 40.7870459],
      bbox: [40.7759518, -73.74958, 40.7960779, -73.72938490000001],
      types: ["locality", "political"],
      text: "Great Neck Estates",
    },
    created: {
      seconds: 1725921859,
      nanoseconds: 785000000,
    },
  },
  {
    id: "W9KtMeyzwJ1MYDnO2vmj",
    components: {
      center: [-74.0120817, 40.2203907],
      types: ["locality", "political"],
      text: "Asbury Park",
      bbox: [40.21312500000001, -74.0272561, 40.2312219, -73.99468089999999],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 832000000,
    },
    name: "Asbury Park",
    county: "Monmouth County",
    place_name: "Asbury Park, Monmouth County, NJ",
    state: "New Jersey",
    state_short: "NJ",
  },
  {
    id: "WEwy5ycsVvX3hkusF2sr",
    place_name: "Massapequa Park, NY ",
    components: {
      types: ["locality", "political"],
      bbox: [40.650822, -73.464345, 40.705561, -73.4363169],
      text: "Massapequa Park",
      center: [-73.4553119, 40.6804893],
    },
    name: "Massapequa Park",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725921859,
      nanoseconds: 780000000,
    },
    county: "Nassau County",
  },
  {
    id: "WOOJGhEbRcHEdO6dnFwL",
    place_name: "Kew Gardens, Queens, NY",
    name: "Kew Gardens",
    state: "New York",
    county: "Queens County",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Kew Gardens",
      center: [-73.8272029, 40.705695],
      bbox: [40.7013971, -73.8381015, 40.7154118, -73.81725709999999],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 911000000,
    },
  },
  {
    id: "WOw0MrDi1zo4M5nwxBdU",
    created: {
      seconds: 1725914391,
      nanoseconds: 852000000,
    },
    county: "Queens County",
    components: {
      bbox: [40.7409194, -73.72990519999999, 40.75958199999999, -73.7018208],
      types: ["neighborhood", "political"],
      text: "Glen Oaks",
      center: [-73.7163805, 40.7500305],
    },
    name: "Glen Oaks",
    state: "New York",
    place_name: "Glen Oaks, Queens, NY",
    state_short: "NY",
  },
  {
    id: "WX44reEQzoK1Apd4wSFU",
    state_short: "NJ",
    place_name: "Burlington County, NJ",
    county: "Burlington County",
    components: {
      types: ["administrative_area_level_2", "political"],
      bbox: [39.541198, -75.059913, 40.183651, -74.389708],
      center: [-74.6868815, 39.8558488],
      text: "Burlington County",
    },
    name: "Burlington County",
    created: {
      seconds: 1725911238,
      nanoseconds: 951000000,
    },
    state: "New Jersey",
  },
  {
    id: "WbBPMay1lI0C6u8ZlLaK",
    components: {
      center: [-73.9593998, 40.6414876],
      bbox: [40.628212, -73.9726081, 40.6555327, -73.9453688],
      types: ["neighborhood", "political"],
      text: "Flatbush",
    },
    state: "New York",
    state_short: "NY",
    name: "Flatbush",
    place_name: "Flatbush, Brooklyn, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 532000000,
    },
    county: "Kings County",
  },
  {
    id: "XHZDTlUO13LgNgEKOxIl",
    components: {
      text: "Mount Hope",
      center: [-73.9051185, 40.8488863],
      bbox: [40.844579, -73.914206, 40.854079, -73.8997049],
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
    state: "New York",
    name: "Mount Hope",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 825000000,
    },
    place_name: "Mount Hope, Bronx, NY",
  },
  {
    id: "XMLlMzYL2yHI8X7OlsU6",
    county: "Somerset County",
    place_name: "Somerville, Somerset County, NJ",
    components: {
      center: [-74.60987999999999, 40.5742696],
      types: ["locality", "political"],
      text: "Somerville",
      bbox: [40.5560339, -74.626395, 40.581697, -74.586259],
    },
    name: "Somerville",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 646000000,
    },
    state_short: "NJ",
  },
  {
    id: "XN9c9PbN93khOuZAcJWp",
    components: {
      center: [-72.61511689999999, 40.9848784],
      types: ["administrative_area_level_2", "political"],
      bbox: [40.534265, -73.49740489999999, 41.3103871, -71.777491],
      text: "Suffolk County",
    },
    state_short: "NY",
    county: "Suffolk County",
    name: "Suffolk County",
    place_name: "Suffolk County, NY",
    created: {
      seconds: 1725911000,
      nanoseconds: 861000000,
    },
    state: "New York",
  },
  {
    id: "Xia9WRHUU9Dju9JNUWar",
    created: {
      seconds: 1725921859,
      nanoseconds: 782000000,
    },
    state: "New York",
    state_short: "NY",
    components: {
      types: ["locality", "political"],
      center: [-73.7003817, 40.6814623],
      bbox: [40.675202, -73.726405, 40.6968629, -73.679909],
      text: "North Valley Stream",
    },
    county: "Nassau County",
    name: "North Valley Stream",
    place_name: "North Valley Stream, NY",
  },
  {
    id: "Y1qnifbYOMJzUpa80PW6",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 761000000,
    },
    name: "Fresh Meadows",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.7232788, -73.7885209, 40.7433307, -73.77013260000001],
      text: "Fresh Meadows",
      center: [-73.7801447, 40.7335179],
    },
    place_name: "Fresh Meadows, Queens, NY",
    state_short: "NY",
    county: "Queens County",
  },
  {
    id: "YBzyibMPTWcHjPwXp3tS",
    components: {
      types: ["administrative_area_level_2", "political"],
      text: "Essex County",
      bbox: [40.674368, -74.37740889999999, 40.9089699, -74.1127869],
      center: [-74.2648829, 40.7947466],
    },
    state: "New Jersey",
    state_short: "NJ",
    name: "Essex County",
    created: {
      seconds: 1725911238,
      nanoseconds: 927000000,
    },
    county: "Essex County",
    place_name: "Essex County, NJ",
  },
  {
    id: "YC3CvMmiVRaxskX8UMKA",
    components: {
      bbox: [40.7939439, -73.728331, 40.8002349, -73.7155141],
      text: "Great Neck Gardens",
      center: [-73.72388889999999, 40.7972222],
      types: ["locality", "political"],
    },
    state: "New York",
    state_short: "NY",
    place_name: "Great Neck Gardens, NY",
    county: "Nassau County",
    name: "Great Neck Gardens",
    created: {
      seconds: 1725921859,
      nanoseconds: 782000000,
    },
  },
  {
    id: "YD3MM4MKg8B5mVdefLWn",
    place_name: "Harding Park, Bronx, NY ",
    name: "Harding Park",
    county: "Bronx County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
    state_short: "NY",
    components: {
      text: "Harding Park",
      types: ["neighborhood", "political"],
      center: [-73.8551385, 40.8096822],
      bbox: [40.8044801, -73.8705094, 40.8158555, -73.84740409999999],
    },
  },
  {
    id: "YH5y9XTe0Fl62enALSuT",
    created: {
      seconds: 1725915869,
      nanoseconds: 706000000,
    },
    county: "Middlesex County",
    state_short: "NJ",
    components: {
      types: ["locality", "political"],
      text: "Woodbridge Township",
      bbox: [40.4908049, -74.338493, 40.608903, -74.228289],
      center: [-74.2846022, 40.5575986],
    },
    name: "Woodbridge Township",
    state: "New Jersey",
    place_name: "Woodbridge Township, Middlesex County, NJ",
  },
  {
    id: "YRUZb7SP5LpyICZkf5QT",
    county: "Essex County",
    state: "New Jersey",
    components: {
      text: "West Orange",
      center: [-74.23908279999999, 40.7985699],
      types: ["locality", "political"],
      bbox: [40.75739189999999, -74.3050489, 40.826557, -74.22089],
    },
    state_short: "NJ",
    place_name: "West Orange, Essex County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 682000000,
    },
    name: "West Orange",
  },
  {
    id: "YTwNPfqJFSgMDIWiMvpR",
    state_short: "NJ",
    name: "Parsippany-Troy Hills",
    place_name: "Parsippany-Troy Hills, Morris County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 833000000,
    },
    state: "New Jersey",
    components: {
      text: "Parsippany-Troy Hills",
      center: [-74.41738769999999, 40.8652865],
      types: ["locality", "political"],
      bbox: [40.824991, -74.533554, 40.9019109, -74.34088],
    },
    county: "Morris County",
  },
  {
    id: "YVZStXhzEezEDf6tB2p0",
    name: "Jackson Heights",
    components: {
      center: [-73.8830701, 40.7556818],
      text: "Jackson Heights",
      bbox: [40.7465327, -73.8997646, 40.7660969, -73.8694026],
      types: ["neighborhood", "political"],
    },
    county: "Queens County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 836000000,
    },
    place_name: "Jackson Heights, Queens, NY",
    state_short: "NY",
  },
  {
    id: "YeBTN9QWkulbfI7uwwtm",
    county: "Bronx County",
    name: "City Island",
    state_short: "NY",
    components: {
      types: ["colloquial_area", "political"],
      center: [-73.7865218, 40.8473226],
      text: "City Island",
      bbox: [40.8459736197085, -73.78787078029151, 40.8486715802915, -73.7851728197085],
    },
    state: "New York",
    place_name: "City Island, Bronx, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 817000000,
    },
  },
  {
    id: "Yy8mlJwBBvhBUS5Iehp9",
    created: {
      seconds: 1725921195,
      nanoseconds: 499000000,
    },
    name: "Little Italy",
    county: "New York County",
    state_short: "NY",
    components: {
      center: [-73.9973273, 40.7191413],
      bbox: [40.7168155, -74.000548, 40.721577, -73.9944313],
      types: ["neighborhood", "political"],
      text: "Little Italy",
    },
    state: "New York",
    place_name: "Little Italy, New York, NY ",
  },
  {
    id: "Z1bbTNMpg2xY4egeAFdm",
    place_name: "Richmond Hill, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.8310309, 40.6999853],
      text: "Richmond Hill",
      bbox: [40.6894584, -73.8491504, 40.70963589999999, -73.81396289999999],
    },
    name: "Richmond Hill",
    state: "New York",
    created: {
      seconds: 1725921195,
      nanoseconds: 583000000,
    },
    county: "Queens County",
    state_short: "NY",
  },
  {
    id: "Z4VqM1onWckz40YIxaqx",
    name: "Clinton",
    state_short: "NJ",
    county: "Hunterdon County",
    place_name: "Clinton, Hunterdon County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 856000000,
    },
    state: "New Jersey",
    components: {
      types: ["locality", "political"],
      bbox: [40.62457089999999, -74.929054, 40.6449439, -74.8994629],
      text: "Clinton",
      center: [-74.90988999999999, 40.6367682],
    },
  },
  {
    id: "Z9JyMRlRkgysalENHG3J",
    state_short: "NY",
    place_name: "Laurelton, Queens, NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 470000000,
    },
    state: "New York",
    components: {
      text: "Laurelton",
      bbox: [40.665535, -73.7601311, 40.6886451, -73.7262889],
      center: [-73.7447853, 40.67764],
      types: ["neighborhood", "political"],
    },
    county: "Queens County",
    name: "Laurelton",
  },
  {
    id: "ZGR7lQMxuRJ3IFAaBdbH",
    name: "NoHo",
    county: "New York County",
    state_short: "NY",
    state: "New York",
    components: {
      bbox: [40.7241366, -73.9967609, 40.7300598, -73.9907527],
      center: [-73.9925526, 40.7286587],
      text: "NoHo",
      types: ["neighborhood", "political"],
    },
    place_name: "NoHo, New York, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 870000000,
    },
  },
  {
    id: "ZUARN4LGJCzKaIsLCz6a",
    state: "New York",
    place_name: "Hempstead, NY ",
    state_short: "NY",
    name: "Hempstead",
    created: {
      seconds: 1725915361,
      nanoseconds: 53000000,
    },
    components: {
      bbox: [40.6629379, -73.6681579, 40.7475659, -73.56858],
      center: [-73.61876149999999, 40.7060923],
      text: "Hempstead",
      types: ["locality", "political"],
    },
    county: "Nassau County",
  },
  {
    id: "Zbi7FCSr8oCN0G3JyUTL",
    county: "Richmond County",
    components: {
      bbox: [40.528459, -74.21840209999999, 40.5554959, -74.1747651],
      text: "Woodrow",
      types: ["neighborhood", "political"],
      center: [-74.19151769999999, 40.5394173],
    },
    place_name: "Woodrow, Staten Island, NY",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 926000000,
    },
    state: "New York",
    name: "Woodrow",
  },
  {
    id: "ZcMFjpDoN9LO3XscVIi0",
    place_name: "St. George, Staten Island, NY ",
    state_short: "NY",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 853000000,
    },
    name: "St. George",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      center: [-74.0799469, 40.6427017],
      bbox: [40.6357345, -74.0897129, 40.649101, -74.0689751],
      text: "St. George",
    },
  },
  {
    id: "Zh9totrRiVLplBGI65BK",
    state: "New York",
    place_name: "Hewlett, NY",
    name: "Hewlett",
    state_short: "NY",
    components: {
      text: "Hewlett",
      bbox: [40.631726, -73.7106191, 40.6508139, -73.677555],
      types: ["locality", "political"],
      center: [-73.6956864, 40.6431591],
    },
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 67000000,
    },
  },
  {
    id: "ZuFO8LmpxxPAWSpWKLMx",
    components: {
      types: ["locality", "political"],
      text: "Point Pleasant",
      center: [-74.0681931, 40.0831714],
      bbox: [40.0590148, -74.0916609, 40.097611, -74.047758],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 854000000,
    },
    state: "New Jersey",
    name: "Point Pleasant",
    place_name: "Point Pleasant, Ocean County, NJ",
    county: "Ocean County",
    state_short: "NJ",
  },
  {
    id: "ZxxWZEwL2mCFqRaCGfw0",
    name: "Tottenville",
    components: {
      center: [-74.23554039999999, 40.5083408],
      types: ["neighborhood", "political"],
      text: "Tottenville",
      bbox: [40.496103, -74.2560881, 40.5216029, -74.222487],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 924000000,
    },
    state: "New York",
    county: "Richmond County",
    state_short: "NY",
    place_name: "Tottenville, Staten Island, NY",
  },
  {
    id: "ZyMEa0JPQbr3iiX7LgfK",
    state: "New York",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      center: [-73.70904279999999, 40.7698453],
      text: "Lake Success",
      bbox: [40.7571809, -73.72940740000001, 40.782778, -73.6887781],
    },
    place_name: "Lake Success, NY",
    state_short: "NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 62000000,
    },
    name: "Lake Success",
  },
  {
    id: "ZzoeWDVGUYI0pEr4EocJ",
    state_short: "NJ",
    name: "Montclair",
    state: "New Jersey",
    place_name: "Montclair, Essex County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 831000000,
    },
    county: "Essex County",
    components: {
      types: ["locality", "political"],
      center: [-74.2090053, 40.8259007],
      bbox: [40.788323, -74.23763989999999, 40.861847, -74.18268909999999],
      text: "Montclair",
    },
  },
  {
    id: "a2a55mPxB79GIsqI1Vr3",
    created: {
      seconds: 1725914391,
      nanoseconds: 927000000,
    },
    county: "Richmond County",
    name: "Huguenot",
    components: {
      bbox: [40.5359785197085, -74.19593738029151, 40.53867648029149, -74.1932394197085],
      text: "Huguenot",
      types: ["colloquial_area", "political"],
      center: [-74.1945884, 40.5373275],
    },
    state: "New York",
    state_short: "NY",
    place_name: "Huguenot, Staten Island, NY ",
  },
  {
    id: "aDUByLFU7qqIYjX2l3eD",
    state_short: "NY",
    name: "Melrose",
    created: {
      seconds: 1725914391,
      nanoseconds: 752000000,
    },
    components: {
      center: [-73.9171183, 40.8179194],
      bbox: [40.8165878697085, -73.91838858029149, 40.8192858302915, -73.91569061970849],
      text: "Melrose",
      types: ["establishment", "point_of_interest"],
    },
    place_name: "Melrose, Bronx, NY ",
    state: "New York",
    county: "Bronx County",
  },
  {
    id: "aRRYZNI4GX8xom0aArCI",
    created: {
      seconds: 1725921859,
      nanoseconds: 760000000,
    },
    state_short: "NY",
    place_name: "North Massapequa, NY",
    county: "Nassau County",
    name: "North Massapequa",
    components: {
      bbox: [40.681079, -73.4835059, 40.71827589999999, -73.4500281],
      center: [-73.4620685, 40.70093370000001],
      types: ["locality", "political"],
      text: "North Massapequa",
    },
    state: "New York",
  },
  {
    id: "acC1FS0je0CMKYzqfSeo",
    created: {
      seconds: 1725915360,
      nanoseconds: 988000000,
    },
    place_name: "North Merrick, NY",
    name: "North Merrick",
    state_short: "NY",
    state: "New York",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      text: "North Merrick",
      bbox: [40.674292, -73.575946, 40.6990659, -73.5448837],
      center: [-73.5635312, 40.6913859],
    },
  },
  {
    id: "acteavBG3eRaEtbVHHD2",
    state: "New Jersey",
    place_name: "Hoboken, Hudson County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 868000000,
    },
    components: {
      types: ["locality", "political"],
      text: "Hoboken",
      bbox: [40.7303209, -74.044117, 40.7590989, -74.0137841],
      center: [-74.0323626, 40.7439905],
    },
    county: "Hudson County",
    name: "Hoboken",
    state_short: "NJ",
  },
  {
    id: "adcWvNTGcGr8PhIvvQgr",
    county: "Bronx County",
    name: "Soundview",
    components: {
      text: "Soundview",
      bbox: [40.813935, -73.88450499999999, 40.837298, -73.8568487],
      types: ["neighborhood", "political"],
      center: [-73.8695889, 40.8247728],
    },
    place_name: "Soundview, Bronx, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 879000000,
    },
    state: "New York",
    state_short: "NY",
  },
  {
    id: "aeEtRMksfa682OwWaxiH",
    state_short: "NJ",
    name: "Ocean City",
    place_name: "Ocean City, Cape May County, NJ",
    county: "Cape May County",
    components: {
      types: ["locality", "political"],
      text: "Ocean City",
      center: [-74.5746001, 39.2776156],
      bbox: [39.210927, -74.6549139, 39.310097, -74.5511509],
    },
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 896000000,
    },
  },
  {
    id: "afDwzKf299pVVD1qcsLR",
    state: "New Jersey",
    name: "Bridgewater",
    components: {
      types: ["locality", "political"],
      center: [-74.6049061, 40.5939636],
      text: "Bridgewater",
      bbox: [40.542879, -74.6879051, 40.649311, -74.5121829],
    },
    county: "Somerset County",
    created: {
      seconds: 1725915869,
      nanoseconds: 863000000,
    },
    state_short: "NJ",
    place_name: "Bridgewater, Somerset County, NJ",
  },
  {
    id: "agR8BevFH7nXlq3MwhgD",
    state: "New Jersey",
    name: "Teaneck",
    state_short: "NJ",
    place_name: "Teaneck, Bergen County, NJ",
    county: "Bergen County",
    components: {
      text: "Teaneck",
      types: ["locality", "political"],
      bbox: [40.8598989, -74.0373879, 40.917079, -73.9873898],
      center: [-74.0116536, 40.8932469],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 680000000,
    },
  },
  {
    id: "atYc1YmfZ1cE6mluFlNM",
    state_short: "NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 68000000,
    },
    state: "New York",
    place_name: "Elmont, NY",
    county: "Nassau County",
    name: "Elmont",
    components: {
      text: "Elmont",
      types: ["locality", "political"],
      bbox: [40.685457, -73.7291761, 40.72087, -73.6850893],
      center: [-73.712909, 40.700936],
    },
  },
  {
    id: "b9Qa7ehIBXZEfgoahSGQ",
    name: "Mount Olive",
    state_short: "NJ",
    county: "Morris County",
    place_name: "Mount Olive, Morris County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 686000000,
    },
    state: "New Jersey",
    components: {
      text: "Mount Olive",
      bbox: [40.8055219, -74.82168109999999, 40.920823, -74.681705],
      types: ["locality", "political"],
      center: [-74.73294109999999, 40.8514881],
    },
  },
  {
    id: "bANoSlSSox9p7LWIAemr",
    state_short: "NY",
    name: "Breezy Point",
    county: "Queens County",
    state: "New York",
    components: {
      text: "Breezy Point",
      center: [-73.9155359, 40.5608702],
      types: ["neighborhood", "political"],
      bbox: [40.5421497, -73.9414834, 40.5753043, -73.8632788],
    },
    place_name: "Breezy Point, Queens, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 606000000,
    },
  },
  {
    id: "bEwYJUTGf73fUBLfmTvu",
    created: {
      seconds: 1725915869,
      nanoseconds: 793000000,
    },
    state_short: "NJ",
    place_name: "Cape May, Cape May County, NJ",
    components: {
      text: "Cape May",
      center: [-74.90600529999999, 38.9351125],
      bbox: [38.928519, -74.93880109999999, 38.9521469, -74.86870189999999],
      types: ["locality", "political"],
    },
    county: "Cape May County",
    name: "Cape May",
    state: "New Jersey",
  },
  {
    id: "bI9b9Mf7Gc9B5BxlTTkT",
    place_name: "Woodmere, NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 67000000,
    },
    components: {
      types: ["locality", "political"],
      center: [-73.71263139999999, 40.6320482],
      text: "Woodmere",
      bbox: [40.618403, -73.74670189999999, 40.653509, -73.6964701],
    },
    state: "New York",
    county: "Nassau County",
    name: "Woodmere",
    state_short: "NY",
  },
  {
    id: "bgUtt9FXo9ics88Htlbg",
    county: "Richmond County",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      center: [-74.21634279999999, 40.5456634],
      bbox: [40.5406409, -74.2221691, 40.561083, -74.1875175],
      text: "Rossville",
    },
    place_name: "Rossville, Staten Island, NY",
    state: "New York",
    name: "Rossville",
    created: {
      seconds: 1725914391,
      nanoseconds: 925000000,
    },
  },
  {
    id: "cORysI1K2eB5yec3ewmY",
    state_short: "NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 455000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: [-73.7419017, 40.7156628],
      text: "Queens Village",
      bbox: [40.698445, -73.75671009999999, 40.7325461, -73.725606],
    },
    name: "Queens Village",
    county: "Queens County",
    place_name: "Queens Village, Queens, NY",
    state: "New York",
  },
  {
    id: "cj3THkIEFgMCGSqEKdHt",
    state_short: "NY",
    name: "Tremont",
    place_name: "Tremont, Bronx, NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 827000000,
    },
    county: "Bronx County",
    components: {
      bbox: [40.8403803, -73.91110499999999, 40.85458240000001, -73.88378759999999],
      types: ["neighborhood", "political"],
      center: [-73.90096729999999, 40.8477113],
      text: "Tremont",
    },
  },
  {
    id: "cmYmSMvyGZYAXNEtUIC1",
    county: "Richmond County",
    components: {
      center: [-74.1585424, 40.5908869],
      bbox: [40.5806124, -74.1675865, 40.59535959999999, -74.1458411],
      types: ["neighborhood", "political"],
      text: "Heartland Village",
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 748000000,
    },
    name: "Heartland Village",
    place_name: "Heartland Village, Staten Island, NY ",
    state_short: "NY",
    state: "New York",
  },
  {
    id: "cvA49R4rAO5xECWhTQ6X",
    name: "Grymes Hill",
    county: "Richmond County",
    components: {
      types: ["colloquial_area", "political"],
      center: [-74.09347509999999, 40.6187151],
      bbox: [40.6173661197085, -74.0948240802915, 40.6200640802915, -74.0921261197085],
      text: "Grymes Hill",
    },
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 878000000,
    },
    state: "New York",
    place_name: "Grymes Hill, Staten Island, NY ",
  },
  {
    id: "cxDuwmiBC7W9lJqyJckY",
    state: "New York",
    county: "New York County",
    name: "West Harlem",
    state_short: "NY",
    place_name: "West Harlem, New York, NY",
    components: {
      text: "West Harlem",
      center: [-73.9522075, 40.8189374],
      bbox: [40.80185549999999, -73.9711901, 40.834443, -73.9413373],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 566000000,
    },
  },
  {
    id: "czoMh1Tt5zjVDNwcF3UM",
    state: "New Jersey",
    name: "Princeton",
    county: "Mercer County",
    created: {
      seconds: 1725915869,
      nanoseconds: 765000000,
    },
    place_name: "Princeton, Mercer County, NJ",
    state_short: "NJ",
    components: {
      center: [-74.6672226, 40.3572976],
      types: ["locality", "political"],
      text: "Princeton",
      bbox: [40.3048718, -74.722177, 40.3917548, -74.61750789999999],
    },
  },
  {
    id: "d3aQS2Hdh7Un3qst4tt5",
    name: "Flatbush",
    place_name: "Flatbush, Brooklyn, NY",
    state_short: "NY",
    components: {
      center: [-73.9593998, 40.6414876],
      types: ["neighborhood", "political"],
      bbox: [40.628212, -73.9726081, 40.665596, -73.9082588],
      text: "Flatbush",
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 808000000,
    },
    state: "New York",
    county: "Kings County",
  },
  {
    id: "dUWa4gnjqiXnrGX54nkn",
    components: {
      text: "Sands Point",
      types: ["locality", "political"],
      center: [-73.71874199999999, 40.8517669],
      bbox: [40.83287, -73.7406879, 40.8732729, -73.6630359],
    },
    name: "Sands Point",
    place_name: "Sands Point, NY ",
    state: "New York",
    state_short: "NY",
    county: "Nassau County",
    created: {
      seconds: 1725925688,
      nanoseconds: 769000000,
    },
  },
  {
    id: "dkAgWeFBuR6ZTwc5vJ3k",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 803000000,
    },
    state_short: "NY",
    components: {
      text: "University Heights",
      types: ["neighborhood", "political"],
      center: [-73.90965, 40.8574661],
      bbox: [40.853748, -73.920006, 40.8628452, -73.9009693],
    },
    name: "University Heights",
    place_name: "University Heights, Bronx, NY",
    state: "New York",
  },
  {
    id: "dkg0LWsl7AwYYhcxE2pb",
    state: "New York",
    components: {
      text: "Yorkville",
      types: ["neighborhood", "political"],
      center: [-73.94920789999999, 40.7762231],
      bbox: [40.770222, -73.95730429999999, 40.7852155, -73.941962],
    },
    place_name: "Yorkville, New York, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 501000000,
    },
    state_short: "NY",
    county: "New York County",
    name: "Yorkville",
  },
  {
    id: "e2bIj8WkgYLBxmsLXvmA",
    name: "Brooklyn",
    components: {
      types: ["political", "sublocality", "sublocality_level_1"],
      center: [-73.9441579, 40.6781784],
      text: "Kings County",
      bbox: [40.551042, -74.05663, 40.739446, -73.8333651],
    },
    state: "New York",
    county: "Kings County",
    created: {
      seconds: 1725911049,
      nanoseconds: 15000000,
    },
    state_short: "NY",
    place_name: "Brooklyn, NY",
  },
  {
    id: "eP2KkOspTzCXpUceQIkI",
    county: "Richmond County",
    state_short: "NY",
    place_name: "Rosebank, Staten Island, NY ",
    state: "New York",
    name: "Rosebank",
    components: {
      text: "Rosebank",
      bbox: [40.61264421970849, -74.06760108029151, 40.61534218029149, -74.06490311970849],
      center: [-74.0662521, 40.6139932],
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
  },
  {
    id: "ePT2hB6klQNH5JUXSQ7r",
    place_name: "Crotona Park East, Bronx, NY ",
    state_short: "NY",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 861000000,
    },
    name: "Crotona Park East",
    state: "New York",
    components: {
      center: [-73.8933509, 40.8365344],
      text: "Crotona Park East",
      types: ["neighborhood", "political"],
      bbox: [40.8351854197085, -73.89469988029151, 40.8378833802915, -73.8920019197085],
    },
  },
  {
    id: "eRy9mGKABnZP2JsO1eIR",
    place_name: "Fordham Heights, Manhattan, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 818000000,
    },
    name: "Fordham Heights",
    state_short: "NY",
    county: "Bronx County",
    state: "New York",
    components: {
      text: "Fordham Heights",
      types: ["neighborhood", "political"],
      bbox: [40.849379, -73.90710490000001, 40.8627482, -73.8911626],
      center: [-73.8992392, 40.85790739999999],
    },
  },
  {
    id: "eXzKZLO4NHGaJNhFTj9h",
    created: {
      seconds: 1725915869,
      nanoseconds: 879000000,
    },
    state_short: "NJ",
    state: "New Jersey",
    name: "Lawrence Township",
    place_name: "Lawrence Township, Mercer County, NJ",
    county: "Mercer County",
    components: {
      center: [-74.7294313, 40.2778055],
      bbox: [40.23981089999999, -74.76239000000001, 40.3605019, -74.664039],
      types: ["locality", "political"],
      text: "Lawrence Township",
    },
  },
  {
    id: "eZCaypAFCWKdwwYeQ2aq",
    state_short: "NJ",
    name: "Andover Township",
    place_name: "Andover Township, Sussex County, NJ",
    components: {
      center: [-74.7295522, 41.0155479],
      types: ["locality", "political"],
      bbox: [40.969225, -74.7873699, 41.082754, -74.686142],
      text: "Andover Township",
    },
    county: "Sussex County",
    created: {
      seconds: 1725915869,
      nanoseconds: 682000000,
    },
    state: "New Jersey",
  },
  {
    id: "egoQsLoDHhpvygKj9ROg",
    components: {
      center: [-73.99264579999999, 40.7584306],
      types: ["neighborhood", "political"],
      text: "Midtown West",
      bbox: [40.7373582, -74.0088629, 40.7741244, -73.9734618],
    },
    state_short: "NY",
    name: "Midtown West",
    created: {
      seconds: 1725922837,
      nanoseconds: 774000000,
    },
    county: "New York County",
    state: "New York",
    place_name: "Midtown West, New York, NY",
  },
  {
    id: "ep2W2TENDyxcx085qIis",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 833000000,
    },
    county: "Bronx County",
    place_name: "Melrose, Bronx, NY ",
    name: "Melrose",
    components: {
      bbox: [40.8231960197085, -73.91176328029151, 40.8258939802915, -73.90906531970849],
      text: "Melrose",
      center: [-73.9104143, 40.824545],
      types: ["colloquial_area", "political"],
    },
  },
  {
    id: "fExyhoaJh6BGd6Cyt2qk",
    county: "Bronx County",
    state: "New York",
    components: {
      center: [-73.9023362, 40.8964814],
      types: ["neighborhood", "political"],
      text: "Fieldston",
      bbox: [40.8844781, -73.90814050000002, 40.9007517, -73.8964911],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 761000000,
    },
    name: "Fieldston",
    place_name: "Fieldston, Bronx, NY",
    state_short: "NY",
  },
  {
    id: "fHADJLsr9hI9A5kWoHET",
    state: "New Jersey",
    name: "Woodbury",
    state_short: "NJ",
    components: {
      bbox: [39.8240559, -75.17082289999999, 39.855007, -75.1350949],
      types: ["locality", "political"],
      center: [-75.1526774, 39.83816909999999],
      text: "Woodbury",
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 925000000,
    },
    county: "Gloucester County",
    place_name: "Woodbury, Gloucester County, NJ",
  },
  {
    id: "fLve7iWNiux1Rhv88OKi",
    name: "Mill Neck",
    county: "Nassau County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725921859,
      nanoseconds: 782000000,
    },
    components: {
      types: ["locality", "political"],
      center: [-73.5551246, 40.8870431],
      text: "Mill Neck",
      bbox: [40.8672116, -73.5750999, 40.9029619, -73.541245],
    },
    place_name: "Mill Neck, NY",
  },
  {
    id: "fNFaCQLn1BH4eW2Yb3y0",
    county: "Queens County",
    place_name: "Ditmars Steinway, Queens, NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 528000000,
    },
    components: {
      types: ["neighborhood", "political"],
      text: "Ditmars Steinway",
      bbox: [40.7676198, -73.9278366, 40.7909358, -73.89113119999999],
      center: [-73.9084659, 40.77796350000001],
    },
    state: "New York",
    state_short: "NY",
    name: "Ditmars Steinway",
  },
  {
    id: "fQ04wKsvL50Avx4t9vAN",
    name: "Uniondale",
    state_short: "NY",
    place_name: "Uniondale, NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 741000000,
    },
    county: "Nassau County",
    state: "New York",
    components: {
      types: ["locality", "political"],
      text: "Uniondale",
      bbox: [40.684017, -73.6171008, 40.7475659, -73.574837],
      center: [-73.5931669, 40.700611],
    },
  },
  {
    id: "fS7sB0zGq25bojKLkBwm",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 916000000,
    },
    place_name: "Sewell, Gloucester County, NJ",
    county: "Gloucester County",
    components: {
      bbox: [39.7547381, -75.1534467, 39.774197, -75.13171109999999],
      types: ["neighborhood", "political"],
      text: "Sewell",
      center: [-75.1441926, 39.7662167],
    },
    name: "Sewell",
    state: "New Jersey",
  },
  {
    id: "fVwyfxitdEHlM25RAjE9",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
    state: "New York",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Travis - Chelsea",
      bbox: [40.57709699999999, -74.2066383, 40.603491, -74.1755247],
      center: [-74.19151769999999, 40.5890113],
    },
    name: "Travis - Chelsea",
    place_name: "Travis - Chelsea, Staten Island, NY",
  },
  {
    id: "fZaEdrLAZdT4MwYdWSyt",
    created: {
      seconds: 1725915361,
      nanoseconds: 79000000,
    },
    components: {
      bbox: [40.8118604, -73.625982, 40.915782, -73.482631],
      center: [-73.5322797, 40.8655816],
      text: "Oyster Bay",
      types: ["locality", "political"],
    },
    state_short: "NY",
    state: "New York",
    name: "Oyster Bay",
    place_name: "Oyster Bay, NY",
    county: "Nassau County",
  },
  {
    id: "fg97qcC0MVH4uxmo5Fzx",
    components: {
      bbox: [40.179679, -74.64963589999999, 40.2661173, -74.53645689999999],
      center: [-74.6195327, 40.2162036],
      text: "Robbinsville Township",
      types: ["locality", "political"],
    },
    place_name: "Robbinsville Township, Mercer County, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    name: "Robbinsville Township",
    created: {
      seconds: 1725915869,
      nanoseconds: 854000000,
    },
  },
  {
    id: "fluthkvF0I7VBjVvw6F6",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1725921859,
      nanoseconds: 762000000,
    },
    state_short: "NY",
    name: "East Massapequa",
    place_name: "East Massapequa, NY",
    components: {
      text: "East Massapequa",
      types: ["locality", "political"],
      center: [-73.43512, 40.6687326],
      bbox: [40.650186, -73.454047, 40.7067062, -73.4232531],
    },
  },
  {
    id: "fm6XPhX64HE2qOZja2hh",
    created: {
      seconds: 1725915869,
      nanoseconds: 881000000,
    },
    place_name: "Evesham, Burlington County, NJ",
    state: "New Jersey",
    county: "Burlington County",
    name: "Evesham",
    state_short: "NJ",
    components: {
      bbox: [39.7816311, -74.957223, 39.9198279, -74.8622779],
      center: [-74.88579890000001, 39.8522951],
      types: ["locality", "political"],
      text: "Evesham",
    },
  },
  {
    id: "fuUV0uiizYMaWIwYRNR2",
    county: "New York County",
    components: {
      center: [-73.9558333, 40.8169443],
      bbox: [40.81559531970849, -73.9571822802915, 40.81829328029149, -73.9544843197085],
      types: ["neighborhood", "political"],
      text: "Manhattanville",
    },
    state_short: "NY",
    state: "New York",
    name: "Manhattanville",
    place_name: "Manhattanville, New York, NY ",
    created: {
      seconds: 1725923327,
      nanoseconds: 478000000,
    },
  },
  {
    id: "fwoBKO2Slo1cRdJj7Y0B",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 829000000,
    },
    name: "Hammonton",
    state_short: "NJ",
    county: "Atlantic County",
    place_name: "Hammonton, Atlantic County, NJ",
    components: {
      center: [-74.8023853, 39.6365056],
      bbox: [39.5835199, -74.859134, 39.7299091, -74.67578689999999],
      types: ["locality", "political"],
      text: "Hammonton",
    },
  },
  {
    id: "gAuMZfaePApAujtNG2db",
    state: "New York",
    created: {
      seconds: 1725923327,
      nanoseconds: 452000000,
    },
    components: {
      bbox: [40.6825921, -73.7473072, 40.703019, -73.7253021],
      text: "Cambria Heights",
      types: ["neighborhood", "political"],
      center: [-73.7330753, 40.692158],
    },
    name: "Cambria Heights",
    state_short: "NY",
    county: "Queens County",
    place_name: "Cambria Heights, Queens, NY",
  },
  {
    id: "gGVanA7y9vSVrSIeUDyE",
    place_name: "Greenpoint, Brooklyn, NY",
    state: "New York",
    county: "Kings County",
    state_short: "NY",
    components: {
      bbox: [40.7196156, -73.9623281, 40.7391349, -73.929304],
      text: "Greenpoint",
      types: ["neighborhood", "political"],
      center: [-73.95150319999999, 40.7304701],
    },
    name: "Greenpoint",
    created: {
      seconds: 1725914391,
      nanoseconds: 721000000,
    },
  },
  {
    id: "gH2AvJ65XofG9LnUPOPD",
    county: "Middlesex County",
    state_short: "NJ",
    name: "Edison",
    state: "New Jersey",
    place_name: "Edison, Middlesex County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 764000000,
    },
    components: {
      types: ["locality", "political"],
      center: [-74.4120953, 40.5187154],
      bbox: [40.470796, -74.4399981, 40.605503, -74.316052],
      text: "Edison",
    },
  },
  {
    id: "gPS17yM09w2tVhpgIAWg",
    county: "Camden County",
    place_name: "Haddonfield, Camden County, NJ",
    components: {
      center: [-75.03767069999999, 39.8915022],
      text: "Haddonfield",
      bbox: [39.871918, -75.0514731, 39.9165149, -75.016322],
      types: ["locality", "political"],
    },
    name: "Haddonfield",
    created: {
      seconds: 1725915869,
      nanoseconds: 915000000,
    },
    state_short: "NJ",
    state: "New Jersey",
  },
  {
    id: "geQ0p2dEXyhG7OM0MJYT",
    name: "Wildwood",
    county: "Cape May County",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    components: {
      types: ["locality", "political"],
      center: [-74.8148894, 38.9917797],
      text: "Wildwood",
      bbox: [38.976884, -74.8346181, 38.99878289999999, -74.8018961],
    },
    place_name: "Wildwood, Cape May County, NJ",
    state_short: "NJ",
  },
  {
    id: "giYTMBfIoeO1oBoN1Jlm",
    state: "New York",
    state_short: "NY",
    place_name: "Garden City South, NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 784000000,
    },
    components: {
      center: [-73.6609631, 40.7123242],
      text: "Garden City South",
      bbox: [40.705658, -73.6657069, 40.7175908, -73.651074],
      types: ["locality", "political"],
    },
    county: "Nassau County",
    name: "Garden City South",
  },
  {
    id: "hLHTBME7GLCgJ4WV9uj1",
    components: {
      center: [-73.81356099999999, 40.7109315],
      types: ["neighborhood", "political"],
      text: "Briarwood",
      bbox: [40.704042, -73.82595789999999, 40.7163223, -73.80278349999999],
    },
    state: "New York",
    county: "Queens County",
    place_name: "Briarwood, Queens, NY",
    name: "Briarwood",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 719000000,
    },
  },
  {
    id: "hTuLczUkRfURMs1nN6Ay",
    place_name: "Baychester, Bronx, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 860000000,
    },
    county: "Bronx County",
    name: "Baychester",
    state: "New York",
    components: {
      center: [-73.83308439999999, 40.8693862],
      types: ["neighborhood", "political"],
      bbox: [40.8587791, -73.84551700000002, 40.877524, -73.8187032],
      text: "Baychester",
    },
    state_short: "NY",
  },
  {
    id: "hVFUihnbqqHezJRVeKqZ",
    created: {
      seconds: 1725921195,
      nanoseconds: 498000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.7827839, -73.9558541, 40.8180871, -73.9286431],
      center: [-73.93892129999999, 40.7957399],
      text: "East Harlem",
      types: ["neighborhood", "political"],
    },
    place_name: "East Harlem, New York, NY",
    county: "New York County",
    state: "New York",
    name: "East Harlem",
  },
  {
    id: "hWV5pDgc4WDxpt88PLVg",
    components: {
      text: "Kensington",
      types: ["neighborhood", "political"],
      bbox: [40.6276029, -73.9883334, 40.64827380000001, -73.96676289999999],
      center: [-73.97318729999999, 40.63852019999999],
    },
    county: "Kings County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 762000000,
    },
    name: "Kensington",
    place_name: "Kensington, Brooklyn, NY",
  },
  {
    id: "hbSLWPtzWEE9QioybhA9",
    place_name: "Marine Park, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    name: "Marine Park",
    components: {
      center: [-73.93318649999999, 40.6113817],
      text: "Marine Park",
      bbox: [40.6016479, -73.944807, 40.622401, -73.91974909999999],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 810000000,
    },
  },
  {
    id: "he01JJ5mPZRjfvRbj7as",
    state_short: "NY",
    components: {
      bbox: [40.6449149, -73.50681, 40.7178689, -73.47688529999999],
      types: ["locality", "political"],
      text: "Seaford",
      center: [-73.4881809, 40.6659344],
    },
    county: "Nassau County",
    name: "Seaford",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 1000000,
    },
    place_name: "Seaford, NY",
  },
  {
    id: "iPUqHpszfUyiHKXlIkoz",
    name: "Bulls Head",
    components: {
      text: "Bulls Head",
      center: [-74.162443, 40.6072329],
      bbox: [40.5926407, -74.1734702, 40.621123, -74.14282899999999],
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
    place_name: "Bulls Head, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
  },
  {
    id: "iPehlObO4TkvdBo0sFbX",
    created: {
      seconds: 1725921195,
      nanoseconds: 582000000,
    },
    place_name: "Far Rockaway, Queens, NY",
    state_short: "NY",
    name: "Far Rockaway",
    state: "New York",
    components: {
      text: "Far Rockaway",
      types: ["neighborhood", "political"],
      center: [-73.74484369999999, 40.5998931],
      bbox: [40.590386, -73.76779100000002, 40.611778, -73.73710179999999],
    },
    county: "Queens County",
  },
  {
    id: "iUyBVd2VwJxu74d1O0mE",
    name: "Woodstown",
    state_short: "NJ",
    state: "New Jersey",
    components: {
      center: [-75.32824540000001, 39.65150269999999],
      text: "Woodstown",
      types: ["locality", "political"],
      bbox: [39.637336, -75.3362278, 39.6637621, -75.3146439],
    },
    place_name: "Woodstown, Salem County, NJ",
    county: "Salem County",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
  },
  {
    id: "ibSszXltHSDSH6AgF2Rg",
    name: "Valley Stream",
    state: "New York",
    place_name: "Valley Stream, NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 53000000,
    },
    state_short: "NY",
    components: {
      bbox: [40.6444148, -73.72838899999999, 40.6968629, -73.679909],
      text: "Valley Stream",
      types: ["locality", "political"],
      center: [-73.70846449999999, 40.6642699],
    },
    county: "Nassau County",
  },
  {
    id: "idS2v8sBbYTz2YY4nviP",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
    components: {
      text: "Newark",
      types: ["locality", "political"],
      center: [-74.1723667, 40.735657],
      bbox: [40.6739029, -74.2513239, 40.788664, -74.1127869],
    },
    name: "Newark",
    place_name: "Newark, Essex County, NJ",
    county: "Essex County",
    state: "New Jersey",
  },
  {
    id: "jM5RD9n1NH8twUN2lMiM",
    county: "Hunterdon County",
    created: {
      seconds: 1725911238,
      nanoseconds: 936000000,
    },
    components: {
      center: [-74.92087719999999, 40.56695149999999],
      types: ["administrative_area_level_2", "political"],
      text: "Hunterdon County",
      bbox: [40.339427, -75.1954959, 40.787729, -74.70270289999999],
    },
    state_short: "NJ",
    name: "Hunterdon County",
    place_name: "Hunterdon County, NJ",
    state: "New Jersey",
  },
  {
    id: "jQ1aImLpg5WgOvqKOUDZ",
    place_name: "Little Falls, Passaic County, NJ",
    state: "New Jersey",
    name: "Little Falls",
    county: "Passaic County",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 811000000,
    },
    components: {
      bbox: [40.8596221, -74.2544759, 40.89264, -74.1906889],
      text: "Little Falls",
      types: ["locality", "political"],
      center: [-74.20822860000001, 40.8690101],
    },
  },
  {
    id: "jRfFjnnsnVPJMgLD7i58",
    place_name: "Maspeth, Queens, NY",
    county: "Queens County",
    name: "Maspeth",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Maspeth",
      types: ["neighborhood", "political"],
      bbox: [40.7122838, -73.92880439999999, 40.7409005, -73.8848247],
      center: [-73.9065883, 40.7294018],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 809000000,
    },
  },
  {
    id: "jY5zybjJfhm1PZAstngm",
    place_name: "Bayside, Queens, NY",
    county: "Queens County",
    state_short: "NY",
    name: "Bayside",
    created: {
      seconds: 1725914391,
      nanoseconds: 811000000,
    },
    state: "New York",
    components: {
      text: "Bayside",
      bbox: [40.7442366, -73.7846, 40.7821967, -73.7448264],
      types: ["neighborhood", "political"],
      center: [-73.7692026, 40.7602604],
    },
  },
  {
    id: "jrq2EQkSVSkprAf9FPiR",
    name: "Sheepshead Bay",
    components: {
      text: "Sheepshead Bay",
      bbox: [40.582148, -73.9666379, 40.6159111, -73.9256371],
      types: ["neighborhood", "political"],
      center: [-73.94575379999999, 40.5953955],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 809000000,
    },
    place_name: "Sheepshead Bay, Brooklyn, NY",
    state_short: "NY",
    state: "New York",
    county: "Kings County",
  },
  {
    id: "k6HUS3UBd0rhjM6GgRzD",
    components: {
      center: [-73.679852, 40.7987119],
      types: ["locality", "political"],
      bbox: [40.794617, -73.6936801, 40.803497, -73.668684],
      text: "Munsey Park",
    },
    state: "New York",
    state_short: "NY",
    county: "Nassau County",
    place_name: "Munsey Park, NY ",
    name: "Munsey Park",
    created: {
      seconds: 1725921859,
      nanoseconds: 784000000,
    },
  },
  {
    id: "kDdMOwmcdiSjam7LKQrx",
    state_short: "NJ",
    name: "Neptune Township",
    components: {
      center: [-74.0386271, 40.2091219],
      bbox: [40.18250279999999, -74.09443379999999, 40.234395, -73.99848109999999],
      text: "Neptune Township",
      types: ["locality", "political"],
    },
    state: "New Jersey",
    place_name: "Neptune Township, Monmouth County, NJ",
    county: "Monmouth County",
    created: {
      seconds: 1725915869,
      nanoseconds: 794000000,
    },
  },
  {
    id: "kM9fxe3otHVZfyYNm9wC",
    created: {
      seconds: 1725914391,
      nanoseconds: 748000000,
    },
    state: "New York",
    components: {
      center: [-74.0183, 40.6167679],
      bbox: [40.6067646, -74.0244341, 40.633812, -73.9973401],
      types: ["neighborhood", "political"],
      text: "Dyker Heights",
    },
    place_name: "Dyker Heights, Brooklyn, NY",
    name: "Dyker Heights",
    county: "Kings County",
    state_short: "NY",
  },
  {
    id: "kV9SNKM6Ll1pGQweCoaT",
    components: {
      types: ["locality", "political"],
      center: [-73.6604069, 40.7942674],
      text: "Roslyn Estates",
      bbox: [40.787521, -73.6690239, 40.799713, -73.6531141],
    },
    county: "Nassau County",
    place_name: "Roslyn Estates, NY",
    state: "New York",
    name: "Roslyn Estates",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 767000000,
    },
  },
  {
    id: "kWHoHxJVxQ5Vp4Llp3TD",
    county: "New York County",
    components: {
      center: [-74.0022078, 40.754247],
      types: ["route"],
      bbox: [40.7526498197085, -74.00354268029152, 40.7553477802915, -74.0008447197085],
      text: "Manhattan",
    },
    state: "New York",
    created: {
      seconds: 1725923327,
      nanoseconds: 473000000,
    },
    place_name: "Hudson Yards, New York, NY ",
    name: "Hudson Yards",
    state_short: "NY",
  },
  {
    id: "kXqTvUmDQKtL5d8ceA9t",
    components: {
      types: ["neighborhood", "political"],
      text: "Dumbo",
      bbox: [40.7014434, -73.99454279999999, 40.70556690000001, -73.98450849999999],
      center: [-73.9881451, 40.7033164],
    },
    state_short: "NY",
    place_name: "Dumbo, Brooklyn, NY ",
    county: "Kings County",
    created: {
      seconds: 1725914391,
      nanoseconds: 712000000,
    },
    state: "New York",
    name: "Dumbo",
  },
  {
    id: "kd6NFS5y0rP4CYZWqxw4",
    county: "Nassau County",
    components: {
      bbox: [40.834029, -73.525616, 40.878881, -73.482631],
      center: [-73.5109566, 40.8709319],
      types: ["locality", "political"],
      text: "Oyster Bay Cove",
    },
    place_name: "Oyster Bay Cove, NY",
    state_short: "NY",
    name: "Oyster Bay Cove",
    state: "New York",
    created: {
      seconds: 1725921859,
      nanoseconds: 811000000,
    },
  },
  {
    id: "kxT9lR2gfgnj9wHEE2TD",
    state: "New York",
    place_name: "Pomonok, Queens, NY",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 561000000,
    },
    county: "Queens County",
    name: "Pomonok",
    components: {
      bbox: [40.7256542, -73.81522, 40.7385846, -73.80094629999999],
      center: [-73.81077239999999, 40.732341],
      text: "Pomonok",
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "lCoH8zuo54dAbuhrs88V",
    created: {
      seconds: 1725915360,
      nanoseconds: 996000000,
    },
    state_short: "NY",
    state: "New York",
    place_name: "East Meadow, NY",
    county: "Nassau County",
    name: "East Meadow",
    components: {
      center: [-73.5590157, 40.7139898],
      bbox: [40.694497, -73.5890489, 40.749524, -73.52627],
      text: "East Meadow",
      types: ["locality", "political"],
    },
  },
  {
    id: "lEGYrJJ8WOBua6dUCSa2",
    state: "New York",
    state_short: "NY",
    place_name: "Graniteville, Staten Island, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 926000000,
    },
    name: "Graniteville",
    county: "Richmond County",
    components: {
      center: [-74.15528119999999, 40.6183926],
      text: "Graniteville",
      bbox: [40.608146, -74.16884800000001, 40.6272193, -74.1441759],
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "lNHi3MugRYhtcfDSYQDX",
    name: "Bayville",
    county: "Nassau County",
    state_short: "NY",
    components: {
      center: [-73.56206890000001, 40.9106541],
      types: ["locality", "political"],
      bbox: [40.8990911, -73.5832339, 40.915782, -73.52803500000002],
      text: "Bayville",
    },
    created: {
      seconds: 1725921859,
      nanoseconds: 783000000,
    },
    state: "New York",
    place_name: "Bayville, NY ",
  },
  {
    id: "lQCpm3HwwWvwVvvrlFr5",
    name: "Bloomfield",
    county: "Essex County",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 793000000,
    },
    state: "New Jersey",
    components: {
      text: "Bloomfield",
      types: ["locality", "political"],
      bbox: [40.7680481, -74.21189, 40.848799, -74.162628],
      center: [-74.1854209, 40.8067546],
    },
    place_name: "Bloomfield, Essex County, NJ",
  },
  {
    id: "lYfbX508neUPqBrjGPU1",
    created: {
      seconds: 1725923327,
      nanoseconds: 464000000,
    },
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    place_name: "Rosedale, Queens, NY ",
    components: {
      types: ["neighborhood", "political"],
      text: "Rosedale",
      bbox: [40.6348399, -73.7462031, 40.68221, -73.725055],
      center: [-73.7330753, 40.6575584],
    },
    name: "Rosedale",
  },
  {
    id: "lfjBdNN9TaJGePvogS4L",
    state_short: "NJ",
    state: "New Jersey",
    place_name: "Monmouth County, NJ",
    name: "Monmouth County",
    county: "Monmouth County",
    components: {
      text: "Monmouth County",
      types: ["administrative_area_level_2", "political"],
      bbox: [40.07913, -74.614576, 40.52554, -73.88506],
      center: [-74.12399599999999, 40.2589455],
    },
    created: {
      seconds: 1725911238,
      nanoseconds: 972000000,
    },
  },
  {
    id: "lpsS1C4sHnLBOHmmctAA",
    place_name: "Rockville Centre, NY",
    name: "Rockville Centre",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 65000000,
    },
    state_short: "NY",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      text: "Rockville Centre",
      bbox: [40.6474229, -73.6586839, 40.6900228, -73.617594],
      center: [-73.6412406, 40.65871380000001],
    },
  },
  {
    id: "m5uTXlMBTVnwn7KQDtZy",
    state: "New Jersey",
    place_name: "Medford, Burlington County, NJ",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 926000000,
    },
    county: "Burlington County",
    components: {
      bbox: [39.7851501, -74.8632909, 39.9360579, -74.7542431],
      text: "Medford",
      types: ["locality", "political"],
      center: [-74.8390162, 39.86618259999999],
    },
    name: "Medford",
  },
  {
    id: "m5wni7DJr0DxByAaGJ5j",
    state: "New Jersey",
    state_short: "NJ",
    county: "Cumberland County",
    created: {
      seconds: 1725915869,
      nanoseconds: 871000000,
    },
    name: "Millville",
    components: {
      bbox: [39.3323881, -75.1490099, 39.44701, -74.950823],
      types: ["locality", "political"],
      center: [-75.0393438, 39.4020596],
      text: "Millville",
    },
    place_name: "Millville, Cumberland County, NJ",
  },
  {
    id: "mAqRtE2GQqP45xCiVJny",
    created: {
      seconds: 1725921859,
      nanoseconds: 778000000,
    },
    county: "Nassau County",
    state: "New York",
    components: {
      types: ["locality", "political"],
      center: [-73.7034639, 40.8067675],
      text: "Plandome",
      bbox: [40.8022989, -73.7114579, 40.811796, -73.68738300000001],
    },
    name: "Plandome",
    state_short: "NY",
    place_name: "Plandome, NY ",
  },
  {
    id: "mAusJTrYB72fLTI6mzLX",
    created: {
      seconds: 1725921859,
      nanoseconds: 743000000,
    },
    name: "Roosevelt",
    county: "Nassau County",
    state: "New York",
    components: {
      text: "Roosevelt",
      center: [-73.5890168, 40.67871299999999],
      types: ["locality", "political"],
      bbox: [40.6652289, -73.60152409999999, 40.691261, -73.56858],
    },
    state_short: "NY",
    place_name: "Roosevelt, NY",
  },
  {
    id: "mD1Jt1ULyl0fSIYbUL6o",
    county: "Cumberland County",
    name: "Bridgeton",
    place_name: "Bridgeton, Cumberland County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 871000000,
    },
    state_short: "NJ",
    components: {
      types: ["locality", "political"],
      text: "Bridgeton",
      bbox: [39.4027581, -75.25303300000002, 39.455303, -75.20384],
      center: [-75.2340768, 39.427337],
    },
    state: "New Jersey",
  },
  {
    id: "mH1BgpDg4NenTPaFSZ5R",
    name: "Manhattan Beach",
    county: "Kings County",
    state: "New York",
    components: {
      center: [-73.93892129999999, 40.57815799999999],
      types: ["neighborhood", "political"],
      text: "Manhattan Beach",
      bbox: [40.5742124, -73.9560503, 40.5831758, -73.93104129999999],
    },
    place_name: "Manhattan Beach, Brooklyn, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
    state_short: "NY",
  },
  {
    id: "mS33vEUyP4iCZk38T2Vm",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.8675202, -73.90590519999999, 40.8859971, -73.8813235],
      center: [-73.8976328, 40.8711235],
      text: "Kingsbridge Heights",
    },
    state_short: "NY",
    county: "Bronx County",
    place_name: "Kingsbridge Heights, Bronx, NY",
    name: "Kingsbridge Heights",
    created: {
      seconds: 1725914391,
      nanoseconds: 830000000,
    },
  },
  {
    id: "mVrBHKNBG1V1twPjnA6U",
    place_name: "North Wildwood, Cape May County, NJ",
    state: "New Jersey",
    components: {
      center: [-74.79933299999999, 39.0006686],
      text: "North Wildwood",
      bbox: [38.9883979, -74.8189379, 39.0258648, -74.7861799],
      types: ["locality", "political"],
    },
    name: "North Wildwood",
    state_short: "NJ",
    county: "Cape May County",
    created: {
      seconds: 1725915869,
      nanoseconds: 720000000,
    },
  },
  {
    id: "mh6CYhGmWlKXcq7RUZRz",
    state: "New York",
    components: {
      types: ["neighborhood", "political"],
      text: "Williamsburg",
      center: [-73.9570696, 40.7081156],
      bbox: [40.6979329, -73.96984499999999, 40.7251773, -73.9369427],
    },
    state_short: "NY",
    name: "Williamsburg",
    place_name: "Williamsburg, Brooklyn, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 839000000,
    },
    county: "Kings County",
  },
  {
    id: "mhITw7V5r3fH4mnb2b1d",
    state: "New York",
    created: {
      seconds: 1725921195,
      nanoseconds: 601000000,
    },
    place_name: "Neponsit, Queens, NY ",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.5666656, -73.8663411, 40.5771459, -73.8563848],
      text: "Neponsit",
      center: [-73.8610191, 40.5707646],
    },
    county: "Queens County",
    state_short: "NY",
    name: "Neponsit",
  },
  {
    id: "ml4BIG0BHlFAktQFG70a",
    components: {
      types: ["locality", "political"],
      text: "Englewood",
      bbox: [40.8655679, -73.9945719, 40.9134581, -73.949229],
      center: [-73.9726381, 40.8928771],
    },
    county: "Bergen County",
    created: {
      seconds: 1725915869,
      nanoseconds: 680000000,
    },
    state: "New Jersey",
    state_short: "NJ",
    name: "Englewood",
    place_name: "Englewood, Bergen County, NJ",
  },
  {
    id: "n3VS644DyzYX3d2lZXk9",
    components: {
      bbox: [40.58903341970849, -74.0681565802915, 40.59173138029149, -74.0654586197085],
      center: [-74.06680759999999, 40.5903824],
      text: "South Beach",
      types: ["colloquial_area", "political"],
    },
    place_name: "South Beach, Staten Island, NY ",
    state: "New York",
    name: "South Beach",
    created: {
      seconds: 1725914391,
      nanoseconds: 910000000,
    },
    state_short: "NY",
    county: "Richmond County",
  },
  {
    id: "n3jGSd0DlaZuwg5iUqCs",
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    place_name: "Hicksville, NY",
    name: "Hicksville",
    created: {
      seconds: 1725915360,
      nanoseconds: 982000000,
    },
    components: {
      types: ["locality", "political"],
      bbox: [40.7431277, -73.5601421, 40.7930564, -73.499665],
      text: "Hicksville",
      center: [-73.5251253, 40.7684331],
    },
  },
  {
    id: "nAevTeCZw5TxvIiGyVeJ",
    state: "New York",
    name: "Flatlands",
    county: "Kings County",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.6113917, -73.9474364, 40.6334541, -73.9168823],
      text: "Flatlands",
      center: [-73.9321664, 40.6232714],
    },
    place_name: "Flatlands, Brooklyn, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 753000000,
    },
    state_short: "NY",
  },
  {
    id: "nOnlmARcehOHC2X7DIsm",
    state_short: "NJ",
    name: "Manchester Township",
    county: "Ocean County",
    state: "New Jersey",
    place_name: "Manchester Township, Ocean County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 767000000,
    },
    components: {
      bbox: [39.8548029, -74.495277, 40.055882, -74.2315431],
      text: "Manchester Township",
      types: ["locality", "political"],
      center: [-74.33928209999999, 39.9711696],
    },
  },
  {
    id: "nSZhBoi7ytmXmHAIT2oP",
    county: "Bronx County",
    state: "New York",
    components: {
      text: "Clason Point",
      bbox: [40.8044801, -73.880505, 40.82214099999999, -73.84740409999999],
      center: [-73.8624893, 40.8144344],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 803000000,
    },
    state_short: "NY",
    name: "Clason Point",
    place_name: "Clason Point, Bronx, NY ",
  },
  {
    id: "nWMsXUvqz2Iy4j62q3EN",
    components: {
      text: "Glen Cove",
      center: [-73.6337389, 40.8623217],
      types: ["locality", "political"],
      bbox: [40.829958, -73.6944479, 40.9508209, -73.5902479],
    },
    name: "Glen Cove",
    state_short: "NY",
    state: "New York",
    county: "Nassau County",
    place_name: "Glen Cove, NY",
    created: {
      seconds: 1725915361,
      nanoseconds: 13000000,
    },
  },
  {
    id: "nbB0IrVg5u6mbhtuZSYh",
    components: {
      center: [-74.94294479999999, 40.36593939999999],
      types: ["locality", "political"],
      text: "Lambertville",
      bbox: [40.354963, -74.9541309, 40.385805, -74.93003709999999],
    },
    state_short: "NJ",
    name: "Lambertville",
    state: "New Jersey",
    place_name: "Lambertville, Hunterdon County, NJ",
    county: "Hunterdon County",
    created: {
      seconds: 1725915869,
      nanoseconds: 865000000,
    },
  },
  {
    id: "nbj8AEBKcVioJgBx29jJ",
    state_short: "NY",
    county: "Nassau County",
    place_name: "Levittown, NY",
    name: "Levittown",
    created: {
      seconds: 1725915361,
      nanoseconds: 73000000,
    },
    state: "New York",
    components: {
      text: "Levittown",
      bbox: [40.7000169, -73.54568979999999, 40.743601, -73.48447390000001],
      center: [-73.51429209999999, 40.7259336],
      types: ["locality", "political"],
    },
  },
  {
    id: "nf8cIKCkY7SJfvM9ogg4",
    county: "Mercer County",
    state: "New Jersey",
    components: {
      center: [-74.7337069, 40.3028533],
      types: ["administrative_area_level_2", "political"],
      text: "Mercer County",
      bbox: [40.136771, -74.9429091, 40.424156, -74.48109509999999],
    },
    created: {
      seconds: 1725911238,
      nanoseconds: 963000000,
    },
    state_short: "NJ",
    name: "Mercer County",
    place_name: "Mercer County, NJ",
  },
  {
    id: "nnoifgUHSTtDhEHuV3C9",
    name: "Westchester Square",
    state: "New York",
    state_short: "NY",
    components: {
      center: [-73.8464802, 40.839815],
      types: ["neighborhood", "political"],
      bbox: [40.8342087, -73.8554839, 40.84782089999999, -73.8390751],
      text: "Westchester Square",
    },
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 848000000,
    },
    place_name: "Westchester Square, Bronx, NY",
  },
  {
    id: "noRCHCS0l3tivVglfpf9",
    place_name: "Pennsauken Township, Camden County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 867000000,
    },
    state: "New Jersey",
    state_short: "NJ",
    name: "Pennsauken Township",
    county: "Camden County",
    components: {
      bbox: [39.9242009, -75.12382090000001, 39.9960808, -75.0101459],
      types: ["locality", "political"],
      text: "Pennsauken Township",
      center: [-75.058009, 39.9562052],
    },
  },
  {
    id: "o7rVHjRl26YnufU72Lxw",
    county: "Kings County",
    components: {
      center: [-73.9921833, 40.6139307],
      bbox: [40.601786, -74.01178449999999, 40.6275358, -73.97297309999999],
      text: "Bensonhurst",
      types: ["neighborhood", "political"],
    },
    place_name: "Bensonhurst, Brooklyn, NY",
    state_short: "NY",
    name: "Bensonhurst",
    created: {
      seconds: 1725914391,
      nanoseconds: 746000000,
    },
    state: "New York",
  },
  {
    id: "oAuCSD3gigEjwri4Zwd0",
    place_name: "Travis - Chelsea, Staten Island, NY",
    name: "Travis - Chelsea",
    created: {
      seconds: 1725914391,
      nanoseconds: 825000000,
    },
    components: {
      types: ["neighborhood", "political"],
      text: "Travis - Chelsea",
      center: [-74.19151769999999, 40.5890113],
      bbox: [40.57709699999999, -74.2066383, 40.603491, -74.1755247],
    },
    state: "New York",
    county: "Richmond County",
    state_short: "NY",
  },
  {
    id: "oDhAMUUysGiyymmQKZWD",
    created: {
      seconds: 1725915869,
      nanoseconds: 879000000,
    },
    county: "Burlington County",
    state: "New Jersey",
    components: {
      types: ["locality", "political"],
      text: "Pemberton Township",
      bbox: [39.89882790000001, -74.7360589, 40.010807, -74.47077689999999],
      center: [-74.5834866, 39.9729123],
    },
    name: "Pemberton Township",
    place_name: "Pemberton Township, Burlington County, NJ",
    state_short: "NJ",
  },
  {
    id: "oF3ZVhCPbtXk52i3iaqs",
    created: {
      seconds: 1725914391,
      nanoseconds: 754000000,
    },
    county: "Kings County",
    name: "Crown Heights",
    state: "New York",
    state_short: "NY",
    components: {
      center: [-73.9422324, 40.6694022],
      text: "Crown Heights",
      bbox: [40.6632994, -73.9643928, 40.681042, -73.9192388],
      types: ["neighborhood", "political"],
    },
    place_name: "Crown Heights, Brooklyn, NY",
  },
  {
    id: "oI4I0ODR9g08q2tMDSnx",
    state_short: "NY",
    components: {
      center: [-73.98153370000001, 40.7264773],
      types: ["neighborhood", "political"],
      bbox: [40.7187211, -73.99264099999999, 40.7344458, -73.9716661],
      text: "East Village",
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 520000000,
    },
    place_name: "East Village, New York, NY",
    county: "New York County",
    name: "East Village",
    state: "New York",
  },
  {
    id: "oTzlQDn1pbf0ACisSSsv",
    place_name: "Livingston, Essex County, NJ",
    county: "Essex County",
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
    components: {
      bbox: [40.757596, -74.377517, 40.818416, -74.280592],
      types: ["locality", "political"],
      text: "Livingston",
      center: [-74.3300842, 40.7862871],
    },
    name: "Livingston",
    state_short: "NJ",
  },
  {
    id: "oXy3oKy3wI9fFyDSs2KM",
    components: {
      text: "Bedford Park",
      center: [-73.8856912, 40.8700999],
      types: ["colloquial_area", "political"],
      bbox: [40.8687509197085, -73.8870401802915, 40.8714488802915, -73.8843422197085],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 817000000,
    },
    state_short: "NY",
    state: "New York",
    place_name: "Bedford Park, Bronx, NY ",
    name: "Bedford Park",
    county: "Bronx County",
  },
  {
    id: "ohradNUUivzjbIXEODIG",
    place_name: "East Orange, Essex County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 687000000,
    },
    components: {
      center: [-74.2048677, 40.767323],
      bbox: [40.7450939, -74.238349, 40.78812300000001, -74.1899889],
      types: ["locality", "political"],
      text: "East Orange",
    },
    county: "Essex County",
    name: "East Orange",
    state_short: "NJ",
    state: "New Jersey",
  },
  {
    id: "p6djuibcieWcPkEArLUc",
    components: {
      center: [-73.74679890000002, 40.6220487],
      types: ["locality", "political"],
      text: "Inwood",
      bbox: [40.609984, -73.7670231, 40.633387, -73.73152999999999],
    },
    state_short: "NY",
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 66000000,
    },
    place_name: "Inwood, NY",
    name: "Inwood",
    state: "New York",
  },
  {
    id: "pBACwiKWa40iq0RYfJvh",
    state: "New York",
    state_short: "NY",
    name: "Kew Gardens Hills",
    created: {
      seconds: 1725921195,
      nanoseconds: 521000000,
    },
    components: {
      bbox: [40.7156393, -73.83773819999999, 40.7434168, -73.8077757],
      center: [-73.8207637, 40.7246203],
      types: ["neighborhood", "political"],
      text: "Kew Gardens Hills",
    },
    place_name: "Kew Gardens Hills, Queens, NY",
    county: "Queens County",
  },
  {
    id: "pWJ8XsHFj5dCXnkvO1CR",
    state: "New Jersey",
    county: "Morris County",
    components: {
      text: "Randolph",
      center: [-74.5747248, 40.8478065],
      types: ["locality", "political"],
      bbox: [40.804714, -74.6458019, 40.883942, -74.524895],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 765000000,
    },
    state_short: "NJ",
    place_name: "Randolph, Morris County, NJ",
    name: "Randolph",
  },
  {
    id: "pakb6MjClz4uOhUoSxPS",
    components: {
      types: ["neighborhood", "political"],
      center: [-73.8257325, 40.6918929],
      bbox: [40.6812099, -73.8454095, 40.6973501, -73.8107438],
      text: "South Richmond Hill",
    },
    place_name: "South Richmond Hill, Queens, NY",
    county: "Queens County",
    created: {
      seconds: 1725921195,
      nanoseconds: 593000000,
    },
    state_short: "NY",
    state: "New York",
    name: "South Richmond Hill",
  },
  {
    id: "pbIkX2PqCDIHUvl6tApf",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 880000000,
    },
    components: {
      text: "Willingboro",
      bbox: [39.997706, -74.931589, 40.057733, -74.851841],
      types: ["locality", "political"],
      center: [-74.89164579999999, 40.028848],
    },
    place_name: "Willingboro, Burlington County, NJ",
    name: "Willingboro",
    state: "New Jersey",
    county: "Burlington County",
  },
  {
    id: "pjplwc9m6HKL6HoVy4Qh",
    county: "Sussex County",
    components: {
      bbox: [40.8991651, -74.992015, 41.3576614, -74.36729319999999],
      text: "Sussex County",
      center: [-74.6868815, 41.1288481],
      types: ["administrative_area_level_2", "political"],
    },
    state: "New Jersey",
    created: {
      seconds: 1725911238,
      nanoseconds: 972000000,
    },
    state_short: "NJ",
    place_name: "Sussex County, NJ",
    name: "Sussex County",
  },
  {
    id: "pjspER0pvuN3JopPFbJQ",
    place_name: "Camden, Camden County, NJ",
    name: "Camden",
    created: {
      seconds: 1725915869,
      nanoseconds: 897000000,
    },
    state_short: "NJ",
    county: "Camden County",
    components: {
      text: "Camden",
      bbox: [39.8986639, -75.13626789999999, 39.9694779, -75.06654610000001],
      types: ["locality", "political"],
      center: [-75.1196199, 39.9259463],
    },
    state: "New Jersey",
  },
  {
    id: "puAbKBnNwEbmm4gTYcDU",
    place_name: "Whitestone, Queens, NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 753000000,
    },
    name: "Whitestone",
    components: {
      bbox: [40.7730708, -73.8287876, 40.8010921, -73.78436219999999],
      center: [-73.8095574, 40.7920449],
      types: ["neighborhood", "political"],
      text: "Whitestone",
    },
    state: "New York",
    county: "Queens County",
    state_short: "NY",
  },
  {
    id: "pzPspYi2sM71woRdzvfa",
    place_name: "Stuyvesant Town-Peter Cooper Village, New York, NY",
    county: "New York County",
    state: "New York",
    components: {
      bbox: [40.7284215, -73.9825694, 40.7350027, -73.9734363],
      center: [-73.9778494, 40.7316903],
      types: ["neighborhood", "political"],
      text: "Stuyvesant Town-Peter Cooper Village",
    },
    name: "Stuyvesant Town-Peter Cooper Village",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 835000000,
    },
  },
  {
    id: "qMZqPzdekedHmUaTHc8h",
    place_name: "Sea Cliff, NY",
    state: "New York",
    name: "Sea Cliff",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 783000000,
    },
    county: "Nassau County",
    components: {
      bbox: [40.8345, -73.67107290000001, 40.859299, -73.632503],
      text: "Sea Cliff",
      types: ["locality", "political"],
      center: [-73.64485049999999, 40.8489887],
    },
  },
  {
    id: "r4gmoViVtftwlPhmIo8V",
    name: "Flemington",
    state_short: "NJ",
    components: {
      text: "Flemington",
      center: [-74.85933179999999, 40.5123258],
      bbox: [40.4977599, -74.8694941, 40.5191048, -74.850499],
      types: ["locality", "political"],
    },
    place_name: "Flemington, Hunterdon County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
    state: "New Jersey",
    county: "Hunterdon County",
  },
  {
    id: "r4mFnwOtYWqtPnQmwhwF",
    place_name: "Mariners Harbor, Staten Island, NY ",
    name: "Mariners Harbor",
    created: {
      seconds: 1725914391,
      nanoseconds: 922000000,
    },
    components: {
      text: "Mariners Harbor",
      center: [-74.1587547, 40.63677010000001],
      bbox: [40.63542111970851, -74.1601036802915, 40.63811908029151, -74.15740571970849],
      types: ["colloquial_area", "political"],
    },
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
  },
  {
    id: "rDMFTkpTigypQE3G4BGS",
    name: "Williamsbridge",
    state_short: "NY",
    county: "Bronx County",
    created: {
      seconds: 1725914391,
      nanoseconds: 869000000,
    },
    state: "New York",
    place_name: "Williamsbridge, Bronx, NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Williamsbridge",
      bbox: [40.8686779, -73.86708349999999, 40.8856222, -73.843003],
      center: [-73.85283559999999, 40.8787602],
    },
  },
  {
    id: "rIs7VoYrBZYx2X8owjnR",
    state: "New York",
    state_short: "NY",
    county: "Queens County",
    components: {
      center: [-73.8842219, 40.7016662],
      types: ["neighborhood", "political"],
      bbox: [40.68131899999999, -73.901844, 40.7133659, -73.8521734],
      text: "Glendale",
    },
    place_name: "Glendale, Queens, NY",
    name: "Glendale",
    created: {
      seconds: 1725914391,
      nanoseconds: 766000000,
    },
  },
  {
    id: "rOj2o9SjxHgou2UNV8Z6",
    components: {
      bbox: [40.83747899999999, -73.8859049, 40.84817899999999, -73.876704],
      types: ["neighborhood", "political"],
      center: [-73.8816001, 40.8430609],
      text: "West Farms",
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
    state: "New York",
    place_name: "West Farms, Bronx, NY ",
    name: "West Farms",
    county: "Bronx County",
    state_short: "NY",
  },
  {
    id: "rPsmOaxuzoxEMBLVEbom",
    created: {
      seconds: 1725915869,
      nanoseconds: 865000000,
    },
    state: "New Jersey",
    components: {
      center: [-74.4518188, 40.4862157],
      types: ["locality", "political"],
      bbox: [40.467221, -74.4895239, 40.509533, -74.39241179999999],
      text: "New Brunswick",
    },
    place_name: "New Brunswick, Middlesex County, NJ",
    name: "New Brunswick",
    state_short: "NJ",
    county: "Middlesex County",
  },
  {
    id: "rTHfYIO1CIBgYSl7z7QE",
    state: "New York",
    place_name: "Richmond, Staten Island, NY ",
    county: "Richmond County",
    components: {
      bbox: [40.5575398, -74.1522323, 40.57595999999999, -74.11983359999999],
      text: "Richmond",
      center: [-74.1360528, 40.56675449999999],
      types: ["neighborhood", "political"],
    },
    name: "Richmond",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 926000000,
    },
  },
  {
    id: "rjVk2DBx5Aagwmo1wGWZ",
    place_name: "Clifton, Staten Island, NY",
    state_short: "NY",
    components: {
      bbox: [40.613156, -74.0879471, 40.6269024, -74.06968119999999],
      text: "Clifton",
      types: ["neighborhood", "political"],
      center: [-74.0784785, 40.6189726],
    },
    state: "New York",
    county: "Richmond County",
    created: {
      seconds: 1725914391,
      nanoseconds: 868000000,
    },
    name: "Clifton",
  },
  {
    id: "rzkvQQceKCIVLYVw4TRK",
    name: "Lincoln Square",
    components: {
      bbox: [40.768483, -73.9963789, 40.781822, -73.97608989999999],
      center: [-73.98491179999999, 40.7741769],
      text: "Lincoln Square",
      types: ["neighborhood", "political"],
    },
    state: "New York",
    county: "New York County",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 558000000,
    },
    place_name: "Lincoln Square, New York, NY",
  },
  {
    id: "s5X2Vk1vGFVhkcRTjfEZ",
    created: {
      seconds: 1725914391,
      nanoseconds: 761000000,
    },
    state_short: "NY",
    place_name: "West Bronx, Bronx, NY",
    state: "New York",
    county: "Bronx County",
    components: {
      bbox: [40.8371615, -73.90364869999999, 40.861157, -73.88601],
      text: "West Bronx",
      center: [-73.8953403, 40.8501155],
      types: ["route"],
    },
    name: "West Bronx",
  },
  {
    id: "sPrN7yuIkAkmVlYqhqkZ",
    components: {
      bbox: [40.646001, -73.487036, 40.71827589999999, -73.4232531],
      center: [-73.4742914, 40.6806563],
      text: "Massapequa",
      types: ["locality", "political"],
    },
    county: "Nassau County",
    created: {
      seconds: 1725915361,
      nanoseconds: 69000000,
    },
    state_short: "NY",
    place_name: "Massapequa, NY",
    state: "New York",
    name: "Massapequa",
  },
  {
    id: "sd6M1RbgaPkr23R25O7G",
    county: "Nassau County",
    created: {
      seconds: 1725921859,
      nanoseconds: 766000000,
    },
    components: {
      text: "Old Westbury",
      center: [-73.5995717, 40.7887113],
      types: ["locality", "political"],
      bbox: [40.75654100000001, -73.6257859, 40.81619389999999, -73.564602],
    },
    state: "New York",
    name: "Old Westbury",
    state_short: "NY",
    place_name: "Old Westbury, NY",
  },
  {
    id: "sf9vKHPfnxY7RPMTP5f9",
    components: {
      types: ["neighborhood", "political"],
      text: "Elmhurst",
      center: [-73.8779431, 40.73940350000001],
      bbox: [40.728286, -73.8944508, 40.749152, -73.8644693],
    },
    place_name: "Elmhurst, Queens, NY",
    county: "Queens County",
    state_short: "NY",
    state: "New York",
    name: "Elmhurst",
    created: {
      seconds: 1725914391,
      nanoseconds: 818000000,
    },
  },
  {
    id: "sfHNxTv0jUzbpsUWJcuG",
    place_name: "Marble Hill, New York, NY",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 870000000,
    },
    state: "New York",
    components: {
      text: "Marble Hill",
      types: ["neighborhood", "political"],
      bbox: [40.871678, -73.915205, 40.8792779, -73.907005],
      center: [-73.9102628, 40.8761173],
    },
    county: "New York County",
    name: "Marble Hill",
  },
  {
    id: "soQociMRa6MT6oNeac6E",
    county: "Queens County",
    state_short: "NY",
    state: "New York",
    place_name: "Belle Harbor, Queens, NY ",
    name: "Belle Harbor",
    components: {
      bbox: [40.569517, -73.8606185, 40.5821715, -73.8438166],
      center: [-73.8507279, 40.5754846],
      text: "Belle Harbor",
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725921195,
      nanoseconds: 574000000,
    },
  },
  {
    id: "t2p5gg7N0GYvgCQbe8zS",
    name: "Vernon Township",
    components: {
      center: [-74.4828866, 41.1985786],
      bbox: [41.1037959, -74.5772329, 41.284709, -74.3658969],
      text: "Vernon Township",
      types: ["locality", "political"],
    },
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 723000000,
    },
    county: "Sussex County",
    state_short: "NJ",
    place_name: "Vernon Township, Sussex County, NJ",
  },
  {
    id: "t549v2uh1r0v4OqJ7U0X",
    state_short: "NY",
    county: "New York County",
    state: "New York",
    name: "Hamilton Heights",
    components: {
      text: "Hamilton Heights",
      center: [-73.94761390000001, 40.8252793],
      types: ["neighborhood", "political"],
      bbox: [40.81718, -73.95876109999999, 40.834443, -73.9413373],
    },
    created: {
      seconds: 1725923327,
      nanoseconds: 514000000,
    },
    place_name: "Hamilton Heights, New York, NY",
  },
  {
    id: "tDirwyf29OkR6I98Lmv1",
    created: {
      seconds: 1725915869,
      nanoseconds: 768000000,
    },
    state: "New Jersey",
    place_name: "Upper Deerfield Township, Cumberland County, NJ",
    components: {
      center: [-75.2245693, 39.5031256],
      types: ["locality", "political"],
      bbox: [39.4317141, -75.2707269, 39.55918800000001, -75.16322],
      text: "Upper Deerfield Township",
    },
    name: "Upper Deerfield Township",
    county: "Cumberland County",
    state_short: "NJ",
  },
  {
    id: "tJbr1kiocUsN9J9B538G",
    name: "Jamaica Hills",
    state: "New York",
    county: "Queens County",
    state_short: "NY",
    created: {
      seconds: 1725921195,
      nanoseconds: 581000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: [-73.796488, 40.7135548],
      text: "Jamaica Hills",
      bbox: [40.7077018, -73.8077757, 40.7198784, -73.7904736],
    },
    place_name: "Jamaica Hills, Queens, NY ",
  },
  {
    id: "tO9heyeL2rtcabU7jno6",
    created: {
      seconds: 1725915869,
      nanoseconds: 851000000,
    },
    state: "New Jersey",
    place_name: "High Bridge, Hunterdon County, NJ",
    components: {
      types: ["locality", "political"],
      bbox: [40.6556789, -74.91277989999999, 40.6807499, -74.8743929],
      center: [-74.8957231, 40.6670454],
      text: "High Bridge",
    },
    state_short: "NJ",
    name: "High Bridge",
    county: "Hunterdon County",
  },
  {
    id: "tPVdXGSbrvBjqYiVieyO",
    state: "New York",
    state_short: "NY",
    components: {
      center: [-73.55941279999999, 40.6546145],
      text: "Nassau County",
      bbox: [40.520505, -73.78019309999999, 40.9890091, -73.42327499999999],
      types: ["administrative_area_level_2", "political"],
    },
    name: "Nassau County",
    county: "Nassau County",
    created: {
      seconds: 1725911000,
      nanoseconds: 854000000,
    },
    place_name: "Nassau County, NY",
  },
  {
    id: "tS2jPJfuL8EQ5XJQAhda",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1725921859,
      nanoseconds: 783000000,
    },
    components: {
      types: ["locality", "political"],
      bbox: [40.75963489999999, -73.6663619, 40.7946224, -73.6237939],
      center: [-73.6473511, 40.7887117],
      text: "Roslyn Heights",
    },
    state_short: "NY",
    place_name: "Roslyn Heights, NY",
    name: "Roslyn Heights",
  },
  {
    id: "tfQU85y8lHaWKURO5y0G",
    state_short: "NJ",
    county: "Ocean County",
    place_name: "Barnegat Township, Ocean County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
    components: {
      types: ["locality", "political"],
      center: [-74.2693297, 39.76492229999999],
      text: "Barnegat Township",
      bbox: [39.6986069, -74.417164, 39.828045, -74.141795],
    },
    name: "Barnegat Township",
    state: "New Jersey",
  },
  {
    id: "toQfpZdJQLsgi4EMijJi",
    place_name: "Hell's Kitchen, New York, NY",
    state: "New York",
    created: {
      seconds: 1725922603,
      nanoseconds: 856000000,
    },
    state_short: "NY",
    name: "Hell's Kitchen",
    county: "New York County",
    components: {
      text: "Hell's Kitchen",
      bbox: [40.752223, -74.00473989999999, 40.773677, -73.9824487],
      types: ["neighborhood", "political"],
      center: [-73.9918181, 40.7637581],
    },
  },
  {
    id: "tyeudAraNOE8PYXoB6uu",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725911049,
      nanoseconds: 36000000,
    },
    county: "Richmond County",
    place_name: "Staten Island, NY",
    components: {
      types: ["political", "sublocality", "sublocality_level_1"],
      text: "Staten Island",
      bbox: [40.4773991, -74.25908989999999, 40.6518121, -74.0345471],
      center: [-74.1502007, 40.5795317],
    },
    name: "Staten Island",
  },
  {
    id: "u2Ys9Cy3j09CSQwLgTuf",
    created: {
      seconds: 1725923327,
      nanoseconds: 449000000,
    },
    name: "Hell's Kitchen",
    components: {
      center: [-73.9918181, 40.7637581],
      bbox: [40.752223, -74.00473989999999, 40.773677, -73.9824487],
      types: ["neighborhood", "political"],
      text: "Hell's Kitchen",
    },
    place_name: "Hell's Kitchen, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
  },
  {
    id: "uPxnB5pfMGiMXkKaXqJd",
    place_name: "Harrison, Hudson County, NJ",
    county: "Hudson County",
    created: {
      seconds: 1725915869,
      nanoseconds: 866000000,
    },
    name: "Harrison",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      bbox: [40.733501, -74.16608699999999, 40.7539659, -74.1374919],
      text: "Harrison",
      types: ["locality", "political"],
      center: [-74.1562551, 40.7464901],
    },
  },
  {
    id: "uVpNTNuGqwGXUfJxyfMh",
    name: "Bay Ridge",
    state_short: "NY",
    county: "Kings County",
    place_name: "Bay Ridge, Brooklyn, NY",
    components: {
      text: "Bay Ridge",
      bbox: [40.6184154, -74.04189339999999, 40.644948, -74.0146161],
      center: [-74.0298767, 40.6263732],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 746000000,
    },
    state: "New York",
  },
  {
    id: "ubIrJX9VbKNOME1RD7yc",
    components: {
      bbox: [40.509971, -74.2250749, 40.535301, -74.1779859],
      types: ["neighborhood", "political"],
      center: [-74.20032309999999, 40.5232804],
      text: "Prince's Bay",
    },
    county: "Richmond County",
    state_short: "NY",
    place_name: "Prince's Bay, Staten Island, NY",
    name: "Prince's Bay",
    state: "New York",
    created: {
      seconds: 1725914392,
      nanoseconds: 7000000,
    },
  },
  {
    id: "uscj4mFiDCkB3CagyYv9",
    name: "Island Park",
    created: {
      seconds: 1725915360,
      nanoseconds: 981000000,
    },
    place_name: "Island Park, NY ",
    state: "New York",
    county: "Nassau County",
    components: {
      center: [-73.65540779999999, 40.6042705],
      types: ["locality", "political"],
      bbox: [40.5964689, -73.66203600000001, 40.6170269, -73.6483205],
      text: "Island Park",
    },
    state_short: "NY",
  },
  {
    id: "v5zpBBro9Adff0uojoEJ",
    state: "New York",
    components: {
      center: [-73.8306716, 40.7647238],
      bbox: [40.7156393, -73.8391686, 40.7767411, -73.789424],
      types: ["neighborhood", "political"],
      text: "Flushing",
    },
    place_name: "Flushing, Queens, NY",
    county: "Queens County",
    name: "Flushing",
    created: {
      seconds: 1725914391,
      nanoseconds: 840000000,
    },
    state_short: "NY",
  },
  {
    id: "vCDQZZrxnIGjbxznM8VN",
    place_name: "Middlesex County, NJ",
    state: "New Jersey",
    name: "Middlesex County",
    created: {
      seconds: 1725911238,
      nanoseconds: 936000000,
    },
    components: {
      text: "Middlesex County",
      center: [-74.35874729999999, 40.4111363],
      bbox: [40.251122, -74.6310359, 40.608683, -74.2033603],
      types: ["administrative_area_level_2", "political"],
    },
    state_short: "NJ",
    county: "Middlesex County",
  },
  {
    id: "vGhF6DJZBMJXbpkLn7eo",
    name: "Lawrence",
    state_short: "NY",
    components: {
      text: "Lawrence",
      types: ["locality", "political"],
      center: [-73.72957629999999, 40.6156599],
      bbox: [40.590704, -73.745633, 40.6216156, -73.685975],
    },
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1725915361,
      nanoseconds: 59000000,
    },
    place_name: "Lawrence, NY ",
  },
  {
    id: "vYnYk4nG09asIfA16y8b",
    name: "Glassboro",
    components: {
      bbox: [39.678584, -75.168261, 39.73739, -75.05478500000001],
      text: "Glassboro",
      center: [-75.1118422, 39.70289229999999],
      types: ["locality", "political"],
    },
    state: "New Jersey",
    created: {
      seconds: 1725915869,
      nanoseconds: 920000000,
    },
    county: "Gloucester County",
    place_name: "Glassboro, Gloucester County, NJ",
    state_short: "NJ",
  },
  {
    id: "ve4KF4b1jsUP48m6AZpf",
    state: "New York",
    state_short: "NY",
    place_name: "Kingsbridge, Bronx, NY",
    name: "Kingsbridge",
    county: "Bronx County",
    components: {
      text: "Kingsbridge",
      types: ["neighborhood", "political"],
      center: [-73.9051185, 40.8833912],
      bbox: [40.8733781, -73.9114048, 40.889578, -73.896705],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 760000000,
    },
  },
  {
    id: "vnrXUouTuzuuhpuxIcZI",
    name: "Sutton Place",
    components: {
      text: "Sutton Place",
      center: [-73.961698, 40.7576281],
      bbox: [40.7544872, -73.9650803, 40.7598419, -73.9584941],
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
    place_name: "Sutton Place, New York, NY ",
    county: "New York County",
    state: "New York",
    created: {
      seconds: 1725923327,
      nanoseconds: 455000000,
    },
  },
  {
    id: "vnz9IqAaryMApN54qEM4",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 808000000,
    },
    county: "Bronx County",
    name: "Belmont",
    components: {
      types: ["neighborhood", "political"],
      text: "Belmont",
      center: [-73.88936819999999, 40.8534507],
      bbox: [40.8467737, -73.8975846, 40.8618781, -73.880505],
    },
    place_name: "Belmont, Bronx, NY",
  },
  {
    id: "vwD7CAD7oEzLTa5qZrCe",
    name: "Great Neck",
    state_short: "NY",
    created: {
      seconds: 1725921859,
      nanoseconds: 778000000,
    },
    place_name: "Great Neck, NY",
    state: "New York",
    county: "Nassau County",
    components: {
      center: [-73.7284647, 40.8006567],
      text: "Great Neck",
      types: ["locality", "political"],
      bbox: [40.794001, -73.7575112, 40.8139188, -73.7101138],
    },
  },
  {
    id: "wRp0bE2Sp6CuvVev9N8K",
    name: "Steinway",
    state: "New York",
    state_short: "NY",
    components: {
      types: ["colloquial_area", "political"],
      bbox: [40.7731969197085, -73.9050966802915, 40.7758948802915, -73.9023987197085],
      text: "Steinway",
      center: [-73.9037477, 40.7745459],
    },
    place_name: "Steinway, Queens, NY ",
    created: {
      seconds: 1725921195,
      nanoseconds: 562000000,
    },
    county: "Queens County",
  },
  {
    id: "wV9QIl65lkau6AXq8YD0",
    county: "Hudson County",
    place_name: "Hudson County, NJ",
    created: {
      seconds: 1725911238,
      nanoseconds: 988000000,
    },
    state_short: "NJ",
    components: {
      types: ["administrative_area_level_2", "political"],
      center: [-74.05351259999999, 40.7453199],
      text: "Hudson County",
      bbox: [40.6421489, -74.16608699999999, 40.82358199999999, -73.98479429999999],
    },
    name: "Hudson County",
    state: "New Jersey",
  },
  {
    id: "waUIQRssn1TgMy8AAZ2G",
    created: {
      seconds: 1725914391,
      nanoseconds: 745000000,
    },
    county: "Kings County",
    name: "Mill Basin",
    state_short: "NY",
    state: "New York",
    components: {
      text: "Mill Basin",
      center: [-73.9114293, 40.6096768],
      bbox: [40.602498, -73.92460609999999, 40.619831, -73.9015319],
      types: ["neighborhood", "political"],
    },
    place_name: "Mill Basin, Brooklyn, NY ",
  },
  {
    id: "wb01OhPHJhdkWu2PBm1a",
    state: "New York",
    place_name: "Bedford-Stuyvesant, Brooklyn, NY",
    name: "Bedford-Stuyvesant",
    components: {
      types: ["neighborhood", "political"],
      bbox: [40.6671133, -73.9619136, 40.7008292, -73.9000497],
      text: "Bedford-Stuyvesant",
      center: [-73.9417735, 40.6872176],
    },
    county: "Kings County",
    state_short: "NY",
    created: {
      seconds: 1725914391,
      nanoseconds: 833000000,
    },
  },
  {
    id: "wkuEiLpSEofAXTr2ff2a",
    components: {
      text: "Fort Lee",
      bbox: [40.8276, -73.991325, 40.86890289999999, -73.948385],
      center: [-73.9701381, 40.8509333],
      types: ["locality", "political"],
    },
    county: "Bergen County",
    place_name: "Fort Lee, Bergen County, NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 625000000,
    },
    state: "New Jersey",
    state_short: "NJ",
    name: "Fort Lee",
  },
  {
    id: "wlBN91S6VR7Zlm9FRuMX",
    components: {
      center: [-74.1048641, 40.5820492],
      bbox: [40.5807002197085, -74.10621308029151, 40.5833981802915, -74.1035151197085],
      text: "Grant City",
      types: ["colloquial_area", "political"],
    },
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    name: "Grant City",
    created: {
      seconds: 1725914391,
      nanoseconds: 939000000,
    },
    place_name: "Grant City, Staten Island, NY ",
  },
  {
    id: "wu06IlX5sgTNXaq2uefq",
    name: "Bethpage",
    state: "New York",
    state_short: "NY",
    place_name: "Bethpage, NY",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      bbox: [40.7314991, -73.50601, 40.76983999999999, -73.4685921],
      text: "Bethpage",
      center: [-73.4820687, 40.7442664],
    },
    created: {
      seconds: 1725915361,
      nanoseconds: 19000000,
    },
  },
  {
    id: "wvdFsRlgYzbQMM6lG5Oi",
    name: "Lakewood",
    place_name: "Lakewood, Ocean County, NJ",
    state: "New Jersey",
    components: {
      types: ["locality", "political"],
      center: [-74.2097014, 40.08212899999999],
      text: "Lakewood",
      bbox: [40.023834, -74.258899, 40.120394, -74.151111],
    },
    county: "Ocean County",
    created: {
      seconds: 1725915869,
      nanoseconds: 792000000,
    },
    state_short: "NJ",
  },
  {
    id: "wwlr3Vs8bZgWAcBffuMq",
    state: "New York",
    county: "Queens County",
    place_name: "Astoria, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Astoria",
      center: [-73.92346189999999, 40.7643574],
      bbox: [40.748964, -73.9508771, 40.7909358, -73.89113119999999],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 761000000,
    },
    state_short: "NY",
    name: "Astoria",
  },
  {
    id: "wxKm5WkWjTZUxOKSpHBU",
    components: {
      types: ["locality", "political"],
      center: [-73.63485090000002, 40.7584343],
      bbox: [40.753366, -73.640759, 40.7674109, -73.625338],
      text: "East Williston",
    },
    state_short: "NY",
    state: "New York",
    name: "East Williston",
    place_name: "East Williston, NY ",
    county: "Nassau County",
    created: {
      seconds: 1725921859,
      nanoseconds: 772000000,
    },
  },
  {
    id: "x09K3lrwu2fJbXjnZV38",
    place_name: "Manhattan, New York, NY",
    created: {
      seconds: 1725911049,
      nanoseconds: 28000000,
    },
    state: "New York",
    name: "Manhattan",
    county: "New York County",
    state_short: "NY",
    components: {
      types: ["political", "sublocality", "sublocality_level_1"],
      text: "New York County",
      center: [-73.98219379999999, 40.7685167],
      bbox: [40.6803955, -74.0472368, 40.882214, -73.907],
    },
  },
  {
    id: "xFDe3N7Z9zxWpyCQoh2j",
    name: "Murray Hill",
    components: {
      types: ["neighborhood", "political"],
      text: "Murray Hill",
      center: [-73.9756567, 40.7478792],
      bbox: [40.7437943, -73.98296359999999, 40.7528, -73.9698745],
    },
    state_short: "NY",
    created: {
      seconds: 1725923327,
      nanoseconds: 466000000,
    },
    state: "New York",
    place_name: "Murray Hill, New York, NY",
    county: "New York County",
  },
  {
    id: "xFuzRIvQR8Hi9Oqi4CSc",
    components: {
      text: "Sunset Park",
      bbox: [40.6311838, -74.03174709999999, 40.671745, -73.9830884],
      types: ["neighborhood", "political"],
      center: [-74.0091872, 40.6526345],
    },
    state_short: "NY",
    county: "Kings County",
    name: "Sunset Park",
    place_name: "Sunset Park, Brooklyn, NY",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 753000000,
    },
  },
  {
    id: "xZGuFHxfSv4VbMS0JkBp",
    created: {
      seconds: 1725921195,
      nanoseconds: 578000000,
    },
    name: "Edgemere",
    place_name: "Edgemere, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    components: {
      bbox: [40.5875757, -73.78947959999999, 40.6097987, -73.7637305],
      types: ["neighborhood", "political"],
      text: "Edgemere",
      center: [-73.7742616, 40.5942276],
    },
  },
  {
    id: "xkQ4bSqSHOATZ9xmx9zH",
    components: {
      bbox: [40.1682959, -74.40677699999999, 40.2954789, -74.22274999999999],
      text: "Freehold Township",
      center: [-74.3000878, 40.2136754],
      types: ["locality", "political"],
    },
    state: "New Jersey",
    name: "Freehold Township",
    created: {
      seconds: 1725915869,
      nanoseconds: 810000000,
    },
    state_short: "NJ",
    place_name: "Freehold Township, Monmouth County, NJ",
    county: "Monmouth County",
  },
  {
    id: "yFcjXxHTx6jUOpr2URKV",
    name: "Coney Island",
    components: {
      bbox: [40.5703742, -74.0126976, 40.5841992, -73.9311231],
      text: "Coney Island",
      types: ["establishment", "natural_feature"],
      center: [-73.9707016, 40.5755438],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 828000000,
    },
    state_short: "NY",
    state: "New York",
    county: "Kings County",
    place_name: "Coney Island, Brooklyn, NY",
  },
  {
    id: "yHqyS8hFxoKHGUUOGkBv",
    state_short: "NJ",
    created: {
      seconds: 1725915869,
      nanoseconds: 722000000,
    },
    name: "Union City",
    county: "Hudson County",
    state: "New Jersey",
    components: {
      text: "Union City",
      center: [-74.02375119999999, 40.7795455],
      bbox: [40.7506141, -74.0458598, 40.782873, -74.01479789999999],
      types: ["locality", "political"],
    },
    place_name: "Union City, Hudson County, NJ",
  },
  {
    id: "yJQHv8jX8XFxQsd0wJyE",
    components: {
      center: [-74.0931627, 40.57149],
      text: "Midland Beach",
      bbox: [40.566603, -74.11477, 40.5910057, -74.0764839],
      types: ["neighborhood", "political"],
    },
    name: "Midland Beach",
    created: {
      seconds: 1725914391,
      nanoseconds: 912000000,
    },
    county: "Richmond County",
    state: "New York",
    place_name: "Midland Beach, Staten Island, NY",
    state_short: "NY",
  },
  {
    id: "yMuRJINfrs043B9ttLYe",
    created: {
      seconds: 1725914391,
      nanoseconds: 860000000,
    },
    place_name: "Norwood, Bronx, NY",
    state: "New York",
    components: {
      center: [-73.878486, 40.8810341],
      bbox: [40.8682781, -73.88660469999999, 40.8869861, -73.8695982],
      types: ["neighborhood", "political"],
      text: "Norwood",
    },
    county: "Bronx County",
    state_short: "NY",
    name: "Norwood",
  },
  {
    id: "ybIJ4HWGYX8FzIoDXGOd",
    created: {
      seconds: 1725915869,
      nanoseconds: 869000000,
    },
    county: "Essex County",
    state: "New Jersey",
    place_name: "City of Orange, Essex County, NJ",
    state_short: "NJ",
    components: {
      bbox: [40.7518873, -74.2552731, 40.7897999, -74.2184851],
      types: ["locality", "political"],
      text: "City of Orange",
      center: [-74.2326478, 40.7706187],
    },
    name: "City of Orange",
  },
  {
    id: "ynKsf1D8ojguln3vWkkG",
    state: "New Jersey",
    name: "Denville",
    created: {
      seconds: 1725915869,
      nanoseconds: 685000000,
    },
    place_name: "Denville, Morris County, NJ",
    components: {
      types: ["locality", "political"],
      center: [-74.47737750000002, 40.8923212],
      bbox: [40.833144, -74.53357290000001, 40.935156, -74.4385679],
      text: "Denville",
    },
    county: "Morris County",
    state_short: "NJ",
  },
  {
    id: "yod3W7O9TF7IKMIFYARp",
    place_name: "Forest Park, Queens, NY",
    name: "Forest Park",
    components: {
      bbox: [40.69237529999999, -73.8701645, 40.7131402, -73.83590749999999],
      types: ["neighborhood", "political"],
      text: "Forest Park",
      center: [-73.8566087, 40.7000227],
    },
    created: {
      seconds: 1725914391,
      nanoseconds: 810000000,
    },
    state: "New York",
    county: "Queens County",
    state_short: "NY",
  },
  {
    id: "z69bWRmHa4wP7Aq1lcJV",
    county: "Bronx County",
    state_short: "NY",
    components: {
      bbox: [40.8579177197085, -73.8998183802915, 40.8606156802915, -73.8971204197085],
      text: "Fordham",
      center: [-73.8984694, 40.8592667],
      types: ["colloquial_area", "political"],
    },
    state: "New York",
    name: "Fordham",
    created: {
      seconds: 1725914391,
      nanoseconds: 752000000,
    },
    place_name: "Fordham, Bronx, NY ",
  },
  {
    id: "z6p4yWCHS47TsTicdkdw",
    county: "Middlesex County",
    name: "Perth Amboy",
    components: {
      bbox: [40.494507, -74.2922261, 40.54459900000001, -74.24606899999999],
      text: "Perth Amboy",
      center: [-74.2654234, 40.5067723],
      types: ["locality", "political"],
    },
    created: {
      seconds: 1725915869,
      nanoseconds: 868000000,
    },
    state_short: "NJ",
    state: "New Jersey",
    place_name: "Perth Amboy, Middlesex County, NJ",
  },
  {
    id: "zXSHUpBn7qQF6H4pvNra",
    state_short: "NJ",
    place_name: "Salem County, NJ",
    name: "Salem County",
    created: {
      seconds: 1725911238,
      nanoseconds: 939000000,
    },
    state: "New Jersey",
    components: {
      bbox: [39.35508900000001, -75.5635861, 39.7963681, -75.0618539],
      types: ["administrative_area_level_2", "political"],
      text: "Salem County",
      center: [-75.3878525, 39.5848777],
    },
    county: "Salem County",
  },
  {
    id: "zZJefkCbcZySCVAUkIgr",
    name: "Hudson Heights",
    county: "New York County",
    state: "New York",
    created: {
      seconds: 1725914391,
      nanoseconds: 867000000,
    },
    components: {
      center: [-73.9374517, 40.8534973],
      bbox: [40.8447879, -73.9433956, 40.8598011, -73.931358],
      types: ["neighborhood", "political"],
      text: "Hudson Heights",
    },
    place_name: "Hudson Heights, New York, NY",
    state_short: "NY",
  },
  {
    id: "zeAq9GKAmlVPStJBARtP",
    place_name: "Longwood, Bronx, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 863000000,
    },
    name: "Longwood",
    components: {
      bbox: [40.8234948197085, -73.89293648029151, 40.82619278029149, -73.89023851970849],
      types: ["neighborhood", "political"],
      center: [-73.8915875, 40.8248438],
      text: "Longwood",
    },
    state: "New York",
    county: "Bronx County",
    state_short: "NY",
  },
  {
    id: "ziUBkQ1doxryaGlArHz8",
    state_short: "NJ",
    county: "Ocean County",
    place_name: "Brick Township, Ocean County, NJ",
    components: {
      center: [-74.1371832, 40.0578384],
      types: ["locality", "political"],
      text: "Brick Township",
      bbox: [40.0012168, -74.1605699, 40.1370359, -74.0511199],
    },
    name: "Brick Township",
    created: {
      seconds: 1725915869,
      nanoseconds: 812000000,
    },
    state: "New Jersey",
  },
  {
    id: "zp9xHNdrqXortwpS0Duq",
    county: "Richmond County",
    state_short: "NY",
    place_name: "Fort Wadsworth, Staten Island, NY ",
    created: {
      seconds: 1725914391,
      nanoseconds: 920000000,
    },
    components: {
      bbox: [40.5935946, -74.0691076, 40.6093518, -74.053125],
      center: [-74.0579185, 40.6012117],
      text: "Fort Wadsworth",
      types: ["neighborhood", "political"],
    },
    name: "Fort Wadsworth",
    state: "New York",
  },
];

export const areas = [
  {
    id: "08Ryd2Skns3mfV8RrRuO",
    name: "Staten Island",
    place_name: "Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Staten Island",
      bbox: {
        south: 40.4773991,
        west: -74.25908989999999,
        north: 40.6518121,
        east: -74.0345471,
      },
      center: {
        lat: 40.5795317,
        lng: -74.1502007,
      },
      types: ["political", "sublocality", "sublocality_level_1"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 980000000,
    },
    type: "borough",
    types: ["political", "sublocality", "sublocality_level_1"],
  },
  {
    id: "08nQVFlNTDESwSCoM2i6",
    name: "North Corona",
    place_name: "North Corona, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "North Corona",
      bbox: {
        south: 40.7482832,
        west: -73.8777685,
        north: 40.7604018,
        east: -73.852603,
      },
      center: {
        lat: 40.7543725,
        lng: -73.8669188,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 852000000,
    },
  },
  {
    id: "0M1CWJrA1HqWP2m2WSYI",
    name: "The Heights",
    place_name: "The Heights, Jersey City, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    borough: "Suffolk County",
    components: {
      text: "The Heights",
      bbox: {
        south: 40.731101,
        west: -74.06228469999999,
        north: 40.7601592,
        east: -74.0375842,
      },
      center: {
        lat: 40.751106,
        lng: -74.05401359999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726212600,
      nanoseconds: 779000000,
    },
  },
  {
    id: "0YdbxB96GHvoJnqqEF7e",
    name: "Red Bank",
    place_name: "Red Bank, NJ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Red Bank",
      bbox: {
        south: 40.3362489,
        west: -74.090631,
        north: 40.363686,
        east: -74.0498909,
      },
      center: {
        lat: 40.3470543,
        lng: -74.0643065,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 571000000,
    },
  },
  {
    id: "0dh7rlT0FlQs61G4xQz7",
    name: "Fordham",
    place_name: "Fordham, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Fordham",
      bbox: {
        south: 40.8579177197085,
        west: -73.8998183802915,
        north: 40.8606156802915,
        east: -73.8971204197085,
      },
      center: {
        lat: 40.8592667,
        lng: -73.8984694,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 482000000,
    },
  },
  {
    id: "0dowhIoEKeF2j0rvs6Of",
    name: "Hillcrest",
    place_name: "Hillcrest, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Hillcrest",
      bbox: {
        south: 40.71580609999999,
        west: -73.8089956,
        north: 40.739025,
        east: -73.7902819,
      },
      center: {
        lat: 40.7229706,
        lng: -73.8001762,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 865000000,
    },
  },
  {
    id: "0hX9mTcmciJmSo8CnzWB",
    name: "Sound Beach",
    place_name: "Sound Beach, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Sound Beach",
      bbox: {
        south: 40.9448579,
        west: -72.987006,
        north: 40.966884,
        east: -72.9414488,
      },
      center: {
        lat: 40.9562099,
        lng: -72.9678811,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 553000000,
    },
  },
  {
    id: "0jsgoXTHBt1UFINAgg6S",
    name: "University Heights",
    place_name: "University Heights, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "University Heights",
      bbox: {
        south: 40.853748,
        west: -73.920006,
        north: 40.8628452,
        east: -73.9009693,
      },
      center: {
        lat: 40.8574661,
        lng: -73.90965,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 451000000,
    },
  },
  {
    id: "0lfZCweaNmvtgkicEIEK",
    name: "East Norwich",
    place_name: "East Norwich, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Norwich",
      bbox: {
        south: 40.838938,
        west: -73.54441709999999,
        north: 40.8629079,
        east: -73.5174309,
      },
      center: {
        lat: 40.8467657,
        lng: -73.53512450000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 865000000,
    },
  },
  {
    id: "0ljWl7bs4J6dI6b0NoRx",
    name: "Crotona Park East",
    place_name: "Crotona Park East, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Crotona Park East",
      bbox: {
        south: 40.8351854197085,
        west: -73.89469988029151,
        north: 40.8378833802915,
        east: -73.8920019197085,
      },
      center: {
        lat: 40.8365344,
        lng: -73.8933509,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 551000000,
    },
  },
  {
    id: "0qUbed1QV3HxujqvZLSb",
    name: "West Gilgo Beach",
    place_name: "West Gilgo Beach, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "West Gilgo Beach",
      bbox: {
        south: 40.61153041970851,
        west: -73.4209170802915,
        north: 40.61422838029151,
        east: -73.4182191197085,
      },
      center: {
        lat: 40.6128794,
        lng: -73.41956809999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 458000000,
    },
  },
  {
    id: "0qaxml1z7PF5A9jyRiW9",
    name: "Prospect Heights",
    place_name: "Prospect Heights, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Prospect Heights",
      bbox: {
        south: 40.6717057,
        west: -73.9774381,
        north: 40.6840388,
        east: -73.9626109,
      },
      center: {
        lat: 40.6774196,
        lng: -73.9668408,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 738000000,
    },
  },
  {
    id: "0teaX3mwOihsWD25VNgf",
    created: {
      seconds: 1726205338,
      nanoseconds: 76000000,
    },
    state: "New York",
    place_name: "The Rockaways, Queens, NY",
    state_short: "NY",
    county: "Queens County",
    name: "The Rockaways",
    components: {
      bbox: {
        east: -73.73710179999999,
        north: 40.6142804,
        south: 40.5421497,
        west: -73.9414834,
      },
      center: {
        lat: 40.592658,
        lng: -73.7977928,
      },
      text: "Rockaway Peninsula",
      types: ["establishment", "natural_feature"],
    },
    borough: "Queens",
  },
  {
    id: "0uoLY1EeNLyJSUTvaI68",
    name: "Hoboken",
    place_name: "Hoboken, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hoboken",
      bbox: {
        south: 40.7303209,
        west: -74.044117,
        north: 40.7590989,
        east: -74.0137841,
      },
      center: {
        lat: 40.7439905,
        lng: -74.0323626,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 566000000,
    },
  },
  {
    id: "13LCkURbaRlt9kwtuSzY",
    name: "Schuylerville",
    place_name: "Schuylerville, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Schuylerville",
      bbox: {
        south: 40.828179,
        west: -73.8429041,
        north: 40.845479,
        east: -73.82510309999999,
      },
      center: {
        lat: 40.83665389999999,
        lng: -73.83378909999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 475000000,
    },
  },
  {
    id: "1AG48comglPigfwxbIYW",
    name: "Essex County",
    place_name: "Essex County, NJ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Essex County",
      bbox: {
        south: 40.674368,
        west: -74.37740889999999,
        north: 40.9089699,
        east: -74.1127869,
      },
      center: {
        lat: 40.7947466,
        lng: -74.2648829,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 19000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "1K0OFsNDv1UexFRCYy0l",
    name: "Tribeca",
    place_name: "Tribeca, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Tribeca",
      bbox: {
        south: 40.7113379,
        west: -74.0137633,
        north: 40.72590780000001,
        east: -74.0018893,
      },
      center: {
        lat: 40.7162692,
        lng: -74.0086323,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 952000000,
    },
  },
  {
    id: "1LlsuSy7jiimnUWxP8Jo",
    name: "New Cassel",
    place_name: "New Cassel, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "New Cassel",
      bbox: {
        south: 40.749524,
        west: -73.581667,
        north: 40.772887,
        east: -73.5495528,
      },
      center: {
        lat: 40.7634735,
        lng: -73.555488,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 867000000,
    },
  },
  {
    id: "1ZiExLPqYq5bsOWjR8GV",
    name: "Port Washington North",
    place_name: "Port Washington North, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Port Washington North",
      bbox: {
        south: 40.834381,
        west: -73.7079581,
        north: 40.85067,
        east: -73.6904869,
      },
      center: {
        lat: 40.8448224,
        lng: -73.70179689999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 987000000,
    },
  },
  {
    id: "1ZoA0SgMcApZbXNUz3k9",
    county: "Richmond County",
    state: "New York",
    name: "Oakwood",
    created: {
      seconds: 1726208256,
      nanoseconds: 122000000,
    },
    place_name: "Oakwood, Staten Island, NY ",
    borough: "Staten Island",
    state_short: "NY",
    components: {
      text: "Oakwood",
      bbox: {
        east: -74.11295971970848,
        south: 40.5512563197085,
        north: 40.5653429802915,
        west: -74.1173243802915,
      },
      types: ["colloquial_area", "political"],
      center: {
        lat: 40.563994,
        lng: -74.1159754,
      },
    },
  },
  {
    id: "1dypALoWhU3f86BxqHcA",
    name: "Bridgeton",
    place_name: "Bridgeton, NJ ",
    county: "Cumberland County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bridgeton",
      bbox: {
        south: 39.4027581,
        west: -75.25303300000002,
        north: 39.455303,
        east: -75.20384,
      },
      center: {
        lat: 39.427337,
        lng: -75.2340768,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "1kCNUIsUWy65MjyVjJBb",
    name: "Selden",
    place_name: "Selden, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Selden",
      bbox: {
        south: 40.839873,
        west: -73.065058,
        north: 40.9032089,
        east: -73.0267039,
      },
      center: {
        lat: 40.8664873,
        lng: -73.0356625,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "1mDxN5RDWGiZcJ6TrgmE",
    county: "New York County",
    borough: "Manhattan",
    place_name: "West Harlem, New York, NY",
    name: "West Harlem",
    state_short: "NY",
    created: {
      seconds: 1726203137,
      nanoseconds: 927000000,
    },
    components: {
      bbox: {
        north: 40.834443,
        east: -73.9413373,
        south: 40.80185549999999,
        west: -73.9711901,
      },
      types: ["neighborhood", "political"],
      text: "West Harlem",
      center: {
        lat: 40.8189374,
        lng: -73.9522075,
      },
    },
    state: "New York",
  },
  {
    id: "1nuDUvjznrInQmcYgqiu",
    name: "Hamilton Heights",
    place_name: "Hamilton Heights, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Hamilton Heights",
      bbox: {
        south: 40.81718,
        west: -73.95876109999999,
        north: 40.834443,
        east: -73.9413373,
      },
      center: [-73.94761390000001, 40.8252793],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 871000000,
    },
  },
  {
    id: "1si4ftifPGzFBm9iS8Ch",
    name: "Saddle Rock",
    place_name: "Saddle Rock, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Saddle Rock",
      bbox: {
        south: 40.7895738,
        west: -73.754042,
        north: 40.8000199,
        east: -73.742949,
      },
      center: {
        lat: 40.794268,
        lng: -73.7484654,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 760000000,
    },
  },
  {
    id: "26dtPuWjy057S72lRgSX",
    name: "Ridgewood",
    place_name: "Ridgewood, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Ridgewood",
      bbox: {
        south: 40.6917298,
        west: -73.92405769999999,
        north: 40.7139974,
        east: -73.8888254,
      },
      center: {
        lat: 40.7043986,
        lng: -73.9018292,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 74000000,
    },
  },
  {
    id: "2Ai3CzdjgmB2BX7Jr8eI",
    name: "Camden",
    place_name: "Camden, NJ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Camden",
      bbox: {
        south: 39.8986639,
        west: -75.13626789999999,
        north: 39.9694779,
        east: -75.06654610000001,
      },
      center: {
        lat: 39.9259463,
        lng: -75.1196199,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "2AozOlYLWvPHrCIKAAlh",
    name: "Phillipsburg",
    place_name: "Phillipsburg, NJ ",
    county: "Warren County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Phillipsburg",
      bbox: {
        south: 40.66617189999999,
        west: -75.2039209,
        north: 40.7064979,
        east: -75.16020890000001,
      },
      center: {
        lat: 40.69370989999999,
        lng: -75.1901761,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 604000000,
    },
  },
  {
    id: "2BVHv21kZTwCjUvvtBCN",
    name: "Middle Village",
    place_name: "Middle Village, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Middle Village",
      bbox: {
        south: 40.7054529,
        west: -73.89090130000001,
        north: 40.7318485,
        east: -73.85962210000001,
      },
      center: {
        lat: 40.717372,
        lng: -73.87425,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 80000000,
    },
  },
  {
    id: "2QM29ucGMDIk5j4pANTF",
    borough: "Brooklyn",
    state: "New York",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      center: {
        lat: 40.6590529,
        lng: -73.8759245,
      },
      text: "East New York",
      bbox: {
        south: 40.6355521,
        east: -73.855516,
        west: -73.9039882,
        north: 40.679888,
      },
    },
    county: "Kings County",
    name: "East New York",
    place_name: "East New York, Brooklyn, NY",
    created: {
      seconds: 1726204075,
      nanoseconds: 738000000,
    },
  },
  {
    id: "2Qw5GvZMuCjhtvjc21PP",
    name: "West Islip",
    place_name: "West Islip, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "West Islip",
      bbox: {
        south: 40.683139,
        west: -73.31830409999999,
        north: 40.741467,
        east: -73.27330599999999,
      },
      center: {
        lat: 40.7062104,
        lng: -73.3062302,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 579000000,
    },
  },
  {
    id: "2Rt8ihFpVk0bP4Hdu4k0",
    name: "Fordham Heights",
    place_name: "Fordham Heights, Manhattan, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Fordham Heights",
      bbox: {
        south: 40.849379,
        west: -73.90710490000001,
        north: 40.8627482,
        east: -73.8911626,
      },
      center: {
        lat: 40.85790739999999,
        lng: -73.8992392,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 549000000,
    },
  },
  {
    id: "2cL2nVBUZGfXdfmMq0uF",
    name: "Forest Park",
    place_name: "Forest Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Forest Park",
      bbox: {
        south: 40.69237529999999,
        west: -73.8701645,
        north: 40.7131402,
        east: -73.83590749999999,
      },
      center: {
        lat: 40.7000227,
        lng: -73.8566087,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 19000000,
    },
  },
  {
    id: "2eJX9R4QjQZ7uCAc1I2j",
    name: "Salisbury",
    place_name: "Salisbury, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Salisbury",
      bbox: {
        south: 40.733793,
        west: -73.57965039999999,
        north: 40.7566779,
        east: -73.5429549,
      },
      center: {
        lat: 40.7456559,
        lng: -73.56012659999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 864000000,
    },
  },
  {
    id: "2er3q5Z15zZcVaAuVtoX",
    name: "Bergen-Lafayette",
    place_name: "Bergen-Lafayette, Jersey City, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    borough: "Suffolk County",
    components: {
      text: "Bergen-Lafayette",
      bbox: {
        south: 40.701402,
        west: -74.0837899,
        north: 40.7277665,
        east: -74.05574109999999,
      },
      center: {
        lat: 40.7111464,
        lng: -74.07407289999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726212600,
      nanoseconds: 767000000,
    },
  },
  {
    id: "2jTPor8YWenLO4zeixwt",
    name: "Malverne Park Oaks",
    place_name: "Malverne Park Oaks, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Malverne Park Oaks",
      bbox: {
        south: 40.678131,
        west: -73.67979009999999,
        north: 40.683659,
        east: -73.654876,
      },
      center: {
        lat: 40.6816495,
        lng: -73.6646581,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 857000000,
    },
  },
  {
    id: "2jfOQtgFIjuyzg4mkFnH",
    name: "Atlantic Beach",
    place_name: "Atlantic Beach, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Atlantic Beach",
      bbox: {
        south: 40.5801839,
        west: -73.7432369,
        north: 40.594229,
        east: -73.7169759,
      },
      center: {
        lat: 40.58899359999999,
        lng: -73.72902069999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 743000000,
    },
  },
  {
    id: "2mPznYjjNcPwHw5AivVW",
    name: "Central Park South",
    place_name: "Central Park S, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Central Park South",
      bbox: {
        south: 40.7641334,
        west: -73.981901,
        north: 40.7684723,
        east: -73.9727181,
      },
      center: {
        lat: 40.766188,
        lng: -73.977302,
      },
      types: ["route"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 342000000,
    },
  },
  {
    id: "2uVOyKcnW4rxbwbLYPfj",
    name: "Bloomfield",
    place_name: "Bloomfield, NJ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bloomfield",
      bbox: {
        south: 40.7680481,
        west: -74.21189,
        north: 40.848799,
        east: -74.162628,
      },
      center: {
        lat: 40.8067546,
        lng: -74.1854209,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "2wTtE38xLutKvJhOOzJG",
    name: "Yorkville",
    place_name: "Yorkville, NY",
    county: "Oneida County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Yorkville",
      bbox: {
        south: 43.104427,
        west: -75.288061,
        north: 43.120241,
        east: -75.2616911,
      },
      center: {
        lat: 43.1128475,
        lng: -75.2709991,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 378000000,
    },
  },
  {
    id: "2znQ2J1zpKvrebRjopao",
    name: "Middle Township",
    place_name: "Middle Township, NJ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Middle Township",
      bbox: {
        south: 38.9832701,
        west: -74.935323,
        north: 39.1791179,
        east: -74.7157399,
      },
      center: {
        lat: 39.1201616,
        lng: -74.8451915,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 474000000,
    },
  },
  {
    id: "31YCMDbmurSn8VUZ0Aqa",
    name: "Washington Township",
    place_name: "Washington Township, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Washington Township",
      bbox: {
        south: 39.7075988,
        west: -75.129509,
        north: 39.8049059,
        east: -75.0152531,
      },
      center: {
        lat: 39.7561387,
        lng: -75.07279559999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 485000000,
    },
  },
  {
    id: "34ut8m6qSxkcgSOVsFrI",
    name: "Hunterdon County",
    place_name: "Hunterdon County, NJ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hunterdon County",
      bbox: {
        south: 40.339427,
        west: -75.1954959,
        north: 40.787729,
        east: -74.70270289999999,
      },
      center: {
        lat: 40.56695149999999,
        lng: -74.92087719999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 67000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "36lQtfvQwLkwZbw1epax",
    name: "Floral Park",
    place_name: "Floral Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Floral Park",
      bbox: {
        south: 40.727227,
        west: -73.7176228,
        north: 40.7522482,
        east: -73.70039489999999,
      },
      center: {
        lat: 40.7379091,
        lng: -73.70556239999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 150000000,
    },
  },
  {
    id: "378W5iukMb9C7xyKs0xG",
    components: {
      text: "Manhasset",
      types: ["locality", "political"],
      center: {
        lat: 40.7978787,
        lng: -73.6995749,
      },
      bbox: {
        east: -73.667439,
        west: -73.715942,
        north: 40.811796,
        south: 40.7698909,
      },
    },
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1726208962,
      nanoseconds: 743000000,
    },
    name: "Manhasset",
    place_name: "Manhasset, NY",
    borough: "Nassau County",
    county: "Nassau County",
  },
  {
    id: "3Ee51Bk9H7GAF2fTitFL",
    name: "Mantua Township",
    place_name: "Mantua Township, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Mantua Township",
      bbox: {
        south: 39.714821,
        west: -75.230098,
        north: 39.798809,
        east: -75.11381390000001,
      },
      center: {
        lat: 39.7818322,
        lng: -75.1821597,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 531000000,
    },
  },
  {
    id: "3JM42KSRg36iPzSH0jKR",
    name: "Fieldston",
    place_name: "Fieldston, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Fieldston",
      bbox: {
        south: 40.8844781,
        west: -73.90814050000002,
        north: 40.9007517,
        east: -73.8964911,
      },
      center: {
        lat: 40.8964814,
        lng: -73.9023362,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 427000000,
    },
  },
  {
    id: "3PPpApOqIYeL8KsmaGJS",
    name: "Hunts Point",
    place_name: "Hunts Point, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Hunts Point",
      bbox: {
        south: 40.8005801,
        west: -73.9029856,
        north: 40.822979,
        east: -73.86840409999999,
      },
      center: {
        lat: 40.8094385,
        lng: -73.8803315,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 456000000,
    },
  },
  {
    id: "3RMIZ0NN5L8USGWKXZDR",
    name: "Roosevelt Island",
    place_name: "Roosevelt Island, New York, NY 10044",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Roosevelt Island",
      bbox: {
        south: 40.749613,
        west: -73.96154709999999,
        north: 40.772991,
        east: -73.94000720000001,
      },
      center: {
        lat: 40.76050310000001,
        lng: -73.9509934,
      },
      types: ["establishment", "natural_feature"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 887000000,
    },
  },
  {
    id: "3U2MDOaJaIXo5zAbySG3",
    name: "Glen Oaks",
    place_name: "Glen Oaks, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Glen Oaks",
      bbox: {
        south: 40.7409194,
        west: -73.72990519999999,
        north: 40.75958199999999,
        east: -73.7018208,
      },
      center: {
        lat: 40.7500305,
        lng: -73.7163805,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 22000000,
    },
  },
  {
    id: "3ZN0GF1yoKMzZxeGmRrP",
    name: "Salem County",
    place_name: "Salem County, NJ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Salem County",
      bbox: {
        south: 39.35508900000001,
        west: -75.5635861,
        north: 39.7963681,
        east: -75.0618539,
      },
      center: {
        lat: 39.5848777,
        lng: -75.3878525,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 8000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "3dYFTnlZvJoC8YldGuVn",
    name: "Elmont",
    place_name: "Elmont, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Elmont",
      bbox: {
        south: 40.685457,
        west: -73.7291761,
        north: 40.72087,
        east: -73.6850893,
      },
      center: {
        lat: 40.700936,
        lng: -73.712909,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 711000000,
    },
  },
  {
    id: "3pGr1iSpXE66Kr7lQ5eP",
    name: "Parkchester",
    place_name: "Parkchester, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Parkchester",
      bbox: {
        south: 40.833279,
        west: -73.8642174,
        north: 40.842379,
        east: -73.85164970000001,
      },
      center: {
        lat: 40.8382522,
        lng: -73.8566087,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 447000000,
    },
  },
  {
    id: "44fnUdGXj2R1IUQxWFzv",
    name: "Bohemia",
    place_name: "Bohemia, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Bohemia",
      bbox: {
        south: 40.7478461,
        west: -73.169686,
        north: 40.7925011,
        east: -73.08281989999999,
      },
      center: {
        lat: 40.7692649,
        lng: -73.1151117,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 471000000,
    },
  },
  {
    id: "47s2SvRNWuAaTnKg02KI",
    name: "Morningside Heights",
    place_name: "Morningside Heights, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Morningside Heights",
      bbox: {
        south: 40.80185549999999,
        west: -73.9711901,
        north: 40.818091,
        east: -73.9563131,
      },
      center: [-73.9620581, 40.8105443],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 880000000,
    },
  },
  {
    id: "48n3m1GzI0qwfprTvstB",
    name: "New Brighton",
    place_name: "New Brighton, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "New Brighton",
      bbox: {
        south: 40.635449,
        west: -74.0961691,
        north: 40.6474079,
        east: -74.0865498,
      },
      center: {
        lat: 40.6404369,
        lng: -74.090226,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 117000000,
    },
  },
  {
    id: "4K6HNDKNd6g7UhtHZXyw",
    name: "Heartland Village",
    place_name: "Heartland Village, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Heartland Village",
      bbox: {
        south: 40.5806124,
        west: -74.1675865,
        north: 40.59535959999999,
        east: -74.1458411,
      },
      center: {
        lat: 40.5908869,
        lng: -74.1585424,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "4h3Ok57lvQPV4KQkdo9o",
    county: "Queens County",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1726206181,
      nanoseconds: 891000000,
    },
    name: "South Jamaica",
    place_name: "South Jamaica, Queens, NY",
    borough: "Queens",
    components: {
      center: {
        lat: 40.6804703,
        lng: -73.7920918,
      },
      types: ["neighborhood", "political"],
      text: "South Jamaica",
      bbox: {
        west: -73.808984,
        north: 40.7325461,
        south: 40.6348399,
        east: -73.725055,
      },
    },
  },
  {
    id: "4k1sBUU0xMgdGkftK9gW",
    name: "East Elmhurst",
    place_name: "East Elmhurst, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "East Elmhurst",
      bbox: {
        south: 40.75420099999999,
        west: -73.9004941,
        north: 40.7881802,
        east: -73.8535792,
      },
      center: {
        lat: 40.7660427,
        lng: -73.8758704,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 94000000,
    },
  },
  {
    id: "4qXtLMVOoDY8DfuvYpvi",
    name: "Arden Heights",
    place_name: "Arden Heights, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Arden Heights",
      bbox: {
        south: 40.5406311,
        west: -74.1994792,
        north: 40.5670014,
        east: -74.1652654,
      },
      center: {
        lat: 40.556413,
        lng: -74.1735044,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 103000000,
    },
  },
  {
    id: "4tg8mpQRLfaQv2VZBaTt",
    name: "Throgs Neck",
    place_name: "Throgs Neck, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Throgs Neck",
      bbox: {
        south: 40.8041801,
        west: -73.8422038,
        north: 40.83637909999999,
        east: -73.7898019,
      },
      center: {
        lat: 40.818399,
        lng: -73.8213213,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 470000000,
    },
  },
  {
    id: "4xDvXTNt5y1HPPoENgeq",
    name: "Baychester",
    place_name: "Baychester, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Baychester",
      bbox: {
        south: 40.8587791,
        west: -73.84551700000002,
        north: 40.877524,
        east: -73.8187032,
      },
      center: {
        lat: 40.8693862,
        lng: -73.83308439999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 483000000,
    },
  },
  {
    id: "501YdySTIX2ikJ4R4Glu",
    name: "Whitestone",
    place_name: "Whitestone, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Whitestone",
      bbox: {
        south: 40.7730708,
        west: -73.8287876,
        north: 40.8010921,
        east: -73.78436219999999,
      },
      center: {
        lat: 40.7920449,
        lng: -73.8095574,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 2000000,
    },
  },
  {
    id: "56oIZHaYw29R3jAzq2dW",
    name: "Amityville",
    place_name: "Amityville, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Amityville",
      bbox: {
        south: 40.6516001,
        west: -73.426965,
        north: 40.692867,
        east: -73.4039269,
      },
      center: {
        lat: 40.6789893,
        lng: -73.4170673,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 436000000,
    },
  },
  {
    id: "589ZmxavfYY0cLDlWO0W",
    name: "Lynbrook",
    state_short: "NY",
    state: "New York",
    borough: "Nassau County",
    place_name: "Lynbrook, NY ",
    county: "Nassau County",
    components: {
      text: "Lynbrook",
      bbox: {
        east: -73.6556311,
        north: 40.671297,
        west: -73.69077589999999,
        south: 40.63375509999999,
      },
      center: {
        lng: -73.67179689999999,
        lat: 40.6548253,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 706000000,
    },
  },
  {
    id: "5BFMm9Ae0WoPjed9w0TU",
    name: "Astoria Heights",
    place_name: "Astoria Heights, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Astoria Heights",
      bbox: {
        south: 40.7662219,
        west: -73.9004941,
        north: 40.7777739,
        east: -73.88766269999999,
      },
      center: {
        lat: 40.7687747,
        lng: -73.8915777,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 153000000,
    },
  },
  {
    id: "5LskMATyJ1bfxxqbMR0L",
    name: "Lenox Hill",
    place_name: "Lenox Hill, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Lenox Hill",
      bbox: {
        south: 40.7586825,
        west: -73.9732511,
        north: 40.775726,
        east: -73.949107,
      },
      center: {
        lat: 40.7662315,
        lng: -73.9602312,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 336000000,
    },
  },
  {
    id: "5TkHcSqQsxDuwoXg32D1",
    name: "Dyker Heights",
    place_name: "Dyker Heights, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Dyker Heights",
      bbox: {
        south: 40.6067646,
        west: -74.0244341,
        north: 40.633812,
        east: -73.9973401,
      },
      center: {
        lat: 40.6167679,
        lng: -74.0183,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 742000000,
    },
  },
  {
    id: "5kuaiIxaxm0tc6O7GbQ3",
    name: "Paramus",
    place_name: "Paramus, NJ ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Paramus",
      bbox: {
        south: 40.9085321,
        west: -74.101378,
        north: 40.980635,
        east: -74.0388439,
      },
      center: {
        lat: 40.9445428,
        lng: -74.0754189,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 469000000,
    },
  },
  {
    id: "5rYgf0RQVS4WRXsWmFpw",
    name: "Upper Brookville",
    place_name: "Upper Brookville, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Upper Brookville",
      bbox: {
        south: 40.8167941,
        west: -73.58869100000001,
        north: 40.87225900000001,
        east: -73.5334161,
      },
      center: {
        lat: 40.8387103,
        lng: -73.5651256,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 862000000,
    },
  },
  {
    id: "5vqtASEEOiIr2NCXZAz6",
    name: "Atlantic City",
    place_name: "Atlantic City, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Atlantic City",
      bbox: {
        south: 39.335544,
        west: -74.501592,
        north: 39.4176778,
        east: -74.404054,
      },
      center: {
        lat: 39.3642834,
        lng: -74.4229266,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 441000000,
    },
  },
  {
    id: "5wgrAGYlb7a9VJxuNgwA",
    name: "South Setauket",
    place_name: "South Setauket, Centereach, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "South Setauket",
      bbox: {
        south: 40.8795024,
        west: -73.1049774,
        north: 40.9044435,
        east: -73.0675672,
      },
      center: {
        lat: 40.8886102,
        lng: -73.08127859999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 553000000,
    },
  },
  {
    id: "63P85Pf36LjxHXLuo01d",
    name: "East Rockaway",
    place_name: "East Rockaway, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Rockaway",
      bbox: {
        south: 40.63375509999999,
        west: -73.679406,
        north: 40.6531968,
        east: -73.6556311,
      },
      center: {
        lat: 40.6420477,
        lng: -73.6695747,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 853000000,
    },
  },
  {
    id: "6CZe76ToReqe35TI06Ix",
    name: "East Williamsburg",
    place_name: "East Williamsburg, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "East Williamsburg",
      bbox: {
        south: 40.7069479,
        west: -73.9420866,
        north: 40.7272913,
        east: -73.91985900000002,
      },
      center: {
        lat: 40.7141953,
        lng: -73.9316461,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 737000000,
    },
  },
  {
    id: "6QprEOZJnEuHg0uh1Mj6",
    county: "Kings County",
    borough: "Brooklyn",
    name: "Flatbush",
    state: "New York",
    created: {
      seconds: 1726204075,
      nanoseconds: 742000000,
    },
    components: {
      text: "Flatbush",
      center: {
        lat: 40.6414876,
        lng: -73.9593998,
      },
      bbox: {
        west: -73.9726081,
        east: -73.9082588,
        north: 40.665596,
        south: 40.628212,
      },
      types: ["neighborhood", "political"],
    },
    place_name: "Flatbush, Brooklyn, NY",
    state_short: "NY",
  },
  {
    id: "6UebVzvk2SRFZZ3PxyJ9",
    name: "Nesconset",
    place_name: "Nesconset, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Nesconset",
      bbox: {
        south: 40.8277731,
        west: -73.167109,
        north: 40.862125,
        east: -73.1273491,
      },
      center: {
        lat: 40.8520426,
        lng: -73.1540004,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 507000000,
    },
  },
  {
    id: "6ZR1Z8Hhj9xVFGpfYXnG",
    name: "Huntington Station",
    place_name: "Huntington Station, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Huntington Station",
      bbox: {
        south: 40.82819689999999,
        west: -73.432159,
        north: 40.8666449,
        east: -73.37039810000002,
      },
      center: {
        lat: 40.8534318,
        lng: -73.4115091,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 553000000,
    },
  },
  {
    id: "6a7FwnmOCIaO8gi0NKBe",
    name: "Woodhaven",
    place_name: "Woodhaven, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Woodhaven",
      bbox: {
        south: 40.6831835,
        west: -73.8690429,
        north: 40.6999388,
        east: -73.8441508,
      },
      center: {
        lat: 40.6901366,
        lng: -73.8566087,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 154000000,
    },
  },
  {
    id: "6chFqiB13dvCF4qobHv4",
    name: "Canarsie",
    place_name: "Canarsie, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Canarsie",
      bbox: {
        south: 40.6222886,
        west: -73.92165849999999,
        north: 40.6577511,
        east: -73.8770746,
      },
      center: {
        lat: 40.640248,
        lng: -73.9060662,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 700000000,
    },
  },
  {
    id: "6eBekSPjaUpvLFF6ARSe",
    name: "Cape May County",
    place_name: "Cape May County, NJ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Cape May County",
      bbox: {
        south: 38.788657,
        west: -75.1684619,
        north: 39.324375,
        east: -74.46493,
      },
      center: {
        lat: 39.105884,
        lng: -74.8273184,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 22000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "6hG748ZIoB1HfF5YNysw",
    name: "Vinegar Hill",
    place_name: "Vinegar Hill, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Vinegar Hill",
      bbox: {
        south: 40.7013741,
        west: -73.9847016,
        north: 40.7059655,
        east: -73.97909299999999,
      },
      center: {
        lat: 40.70371859999999,
        lng: -73.98226830000002,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 649000000,
    },
  },
  {
    id: "6ipd26aKmDKya61wm2yo",
    name: "Stuyvesant Town-Peter Cooper Village",
    place_name: "Stuyvesant Town-Peter Cooper Village, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Stuyvesant Town-Peter Cooper Village",
      bbox: {
        south: 40.7284215,
        west: -73.9825694,
        north: 40.7350027,
        east: -73.9734363,
      },
      center: {
        lat: 40.7316903,
        lng: -73.9778494,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 903000000,
    },
  },
  {
    id: "6kLRvYu3L81nYPRVH80i",
    name: "Brownsville",
    place_name: "Brownsville, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Brownsville",
      bbox: {
        south: 40.650415,
        west: -73.9252491,
        north: 40.676727,
        east: -73.8996379,
      },
      center: {
        lat: 40.665214,
        lng: -73.9125304,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 674000000,
    },
  },
  {
    id: "6tJEIlRqRVBcDCdGOqNv",
    name: "Saltaire",
    place_name: "Saltaire, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Saltaire",
      bbox: {
        south: 40.634282,
        west: -73.201861,
        north: 40.6431088,
        east: -73.18783490000001,
      },
      center: {
        lat: 40.636211,
        lng: -73.19678259999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "73LwSyzsLIRHB6f6gAYV",
    name: "Cedarhurst",
    place_name: "Cedarhurst, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Cedarhurst",
      bbox: {
        south: 40.617166,
        west: -73.73734499999999,
        north: 40.6338619,
        east: -73.7170939,
      },
      center: {
        lat: 40.6228819,
        lng: -73.7242983,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 828000000,
    },
  },
  {
    id: "74O4RZjuSM7KmWdxPeNo",
    name: "Long Island City",
    place_name: "Long Island City, Queens, NY",
    state: "New York",
    county: "Queens County",
    borough: "Queens",
    created: {
      seconds: 1726205338,
      nanoseconds: 153000000,
    },
    state_short: "NY",
    components: {
      text: "Long Island City",
      center: {
        lng: -73.9485424,
        lat: 40.744679,
      },
      bbox: {
        south: 40.727849,
        east: -73.9094829,
        north: 40.7630563,
        west: -73.9628577,
      },
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "7ENSfnxMcMmtv2VuPGP2",
    name: "Roslyn Estates",
    place_name: "Roslyn Estates, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Roslyn Estates",
      bbox: {
        south: 40.787521,
        west: -73.6690239,
        north: 40.799713,
        east: -73.6531141,
      },
      center: {
        lat: 40.7942674,
        lng: -73.6604069,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 980000000,
    },
  },
  {
    id: "7Ej589bubATpc1ChfcBt",
    name: "Elwood",
    place_name: "Elwood, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Elwood",
      bbox: {
        south: 40.8270829,
        west: -73.37039810000002,
        north: 40.864825,
        east: -73.3089629,
      },
      center: {
        lat: 40.8453761,
        lng: -73.3351179,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 470000000,
    },
  },
  {
    id: "7K75RQeZRVJz8MJbnzXN",
    name: "Gloucester County",
    place_name: "Gloucester County, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Gloucester County",
      bbox: {
        south: 39.5149669,
        west: -75.4290609,
        north: 39.888317,
        east: -74.87725999999999,
      },
      center: {
        lat: 39.7103023,
        lng: -75.1078332,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 70000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "7OmeT6I2CaW1nmftUg7p",
    name: "Cold Spring Harbor",
    place_name: "Cold Spring Harbor, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Cold Spring Harbor",
      bbox: {
        south: 40.8363853,
        west: -73.473466,
        north: 40.88451999999999,
        east: -73.429176,
      },
      center: {
        lat: 40.8714873,
        lng: -73.456788,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "7QpAFZSvaH1sZnRlDWxt",
    name: "Kings Point",
    place_name: "Kings Point, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Kings Point",
      bbox: {
        south: 40.7986793,
        west: -73.765301,
        north: 40.8404302,
        east: -73.7133223,
      },
      center: {
        lat: 40.8198231,
        lng: -73.7351316,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 761000000,
    },
  },
  {
    id: "7Wrf2p18vbT6YC4Oo0pO",
    name: "Greenpoint",
    place_name: "Greenpoint, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Greenpoint",
      bbox: {
        south: 40.7196156,
        west: -73.9623281,
        north: 40.7391349,
        east: -73.929304,
      },
      center: {
        lat: 40.7304701,
        lng: -73.95150319999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 686000000,
    },
  },
  {
    id: "7bMs0OSh7OtVRmH70fdc",
    name: "Wayne",
    place_name: "Wayne, NJ ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Wayne",
      bbox: {
        south: 40.88287100000001,
        west: -74.28918589999999,
        north: 41.014063,
        east: -74.1955409,
      },
      center: {
        lat: 40.92537249999999,
        lng: -74.2765441,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 607000000,
    },
  },
  {
    id: "7ki55WcPXhpwZkJUal0p",
    name: "Parsippany-Troy Hills",
    place_name: "Parsippany-Troy Hills, NJ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Parsippany-Troy Hills",
      bbox: {
        south: 40.824991,
        west: -74.533554,
        north: 40.9019109,
        east: -74.34088,
      },
      center: {
        lat: 40.8652865,
        lng: -74.41738769999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 501000000,
    },
  },
  {
    id: "7nHyo5ru53sQfTgmRRgA",
    name: "North Woodmere",
    place_name: "North Woodmere, South Valley Stream, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Woodmere",
      bbox: {
        south: 40.6489069197085,
        west: -73.72275238029151,
        north: 40.6516048802915,
        east: -73.72005441970849,
      },
      center: {
        lat: 40.6502559,
        lng: -73.7214034,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 762000000,
    },
  },
  {
    id: "7tBR1Cs33gUaZALUiyJX",
    name: "Hammels",
    place_name: "Hammels, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Hammels",
      bbox: {
        south: 40.5836079,
        west: -73.813869,
        north: 40.5935979,
        east: -73.80151750000002,
      },
      center: {
        lat: 40.5879465,
        lng: -73.80808689999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 888000000,
    },
  },
  {
    id: "7ticaE1JZlFe2EMu7d22",
    name: "Edison",
    place_name: "Edison, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Edison",
      bbox: {
        south: 40.470796,
        west: -74.4399981,
        north: 40.605503,
        east: -74.316052,
      },
      center: {
        lat: 40.5187154,
        lng: -74.4120953,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 564000000,
    },
  },
  {
    id: "80wafgKayyKHGkILQeJa",
    name: "The Bronx",
    place_name: "Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "The Bronx",
      bbox: {
        south: 40.7857429,
        west: -73.933808,
        north: 40.9175771,
        east: -73.74806,
      },
      center: {
        lat: 40.8447819,
        lng: -73.8648268,
      },
      types: ["political", "sublocality", "sublocality_level_1"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 984000000,
    },
    type: "borough",
    types: ["political", "sublocality", "sublocality_level_1"],
  },
  {
    id: "82qKuQc9k4gYQHW6HKxM",
    name: "Glenwood Landing",
    place_name: "Glenwood Landing, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Glenwood Landing",
      bbox: {
        south: 40.81818,
        west: -73.651787,
        north: 40.8371679,
        east: -73.6252588,
      },
      center: {
        lat: 40.8306556,
        lng: -73.6387393,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 765000000,
    },
  },
  {
    id: "8450yGI62kctNqklXh4W",
    name: "Sands Point",
    place_name: "Sands Point, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Sands Point",
      bbox: {
        south: 40.83287,
        west: -73.7406879,
        north: 40.8732729,
        east: -73.6630359,
      },
      center: {
        lat: 40.8517669,
        lng: -73.71874199999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 982000000,
    },
  },
  {
    id: "87l9AjPXCl1FGFa9TY5E",
    name: "Oakland Gardens",
    place_name: "Oakland Gardens, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Oakland Gardens",
      bbox: {
        south: 40.7355137,
        west: -73.7663401,
        north: 40.7515521,
        east: -73.7406635,
      },
      center: {
        lat: 40.7408584,
        lng: -73.758241,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 76000000,
    },
  },
  {
    id: "8BpMnHLjo2jHB0tDlATA",
    name: "Hackettstown",
    place_name: "Hackettstown, NJ ",
    county: "Warren County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hackettstown",
      bbox: {
        south: 40.8280519,
        west: -74.8504519,
        north: 40.8825498,
        east: -74.801917,
      },
      center: {
        lat: 40.8539879,
        lng: -74.8290555,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 604000000,
    },
  },
  {
    id: "8Ex6IujXt19YstaGGRkr",
    name: "Freeport",
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1726208962,
      nanoseconds: 705000000,
    },
    components: {
      text: "Freeport",
      center: {
        lat: 40.6576022,
        lng: -73.58318349999999,
      },
      bbox: {
        west: -73.603841,
        east: -73.564632,
        north: 40.6735478,
        south: 40.62337309999999,
      },
      types: ["locality", "political"],
    },
    borough: "Nassau County",
    county: "Nassau County",
    place_name: "Freeport, NY",
  },
  {
    id: "8HEd7xqg4eevAUuuzNbQ",
    name: "Lake Ronkonkoma",
    place_name: "Lake Ronkonkoma, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Lake Ronkonkoma",
      bbox: {
        south: 40.8069208,
        west: -73.1485758,
        north: 40.8533211,
        east: -73.081805,
      },
      center: {
        lat: 40.8320984,
        lng: -73.1053707,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "8INg8lSnCTvvM5yCkYEf",
    name: "Red Hook",
    place_name: "Red Hook, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Red Hook",
      bbox: {
        south: 40.6613687,
        west: -74.0202999,
        north: 40.68750470000001,
        east: -73.9950228,
      },
      center: {
        lat: 40.6733676,
        lng: -74.00831889999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 747000000,
    },
  },
  {
    id: "8OeOC0XCbSJ3pnOLlRrT",
    name: "Perth Amboy",
    place_name: "Perth Amboy, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Perth Amboy",
      bbox: {
        south: 40.494507,
        west: -74.2922261,
        north: 40.54459900000001,
        east: -74.24606899999999,
      },
      center: {
        lat: 40.5067723,
        lng: -74.2654234,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 489000000,
    },
  },
  {
    id: "8TD9sVbmAAF0knwNRCvi",
    name: "Jerome Park",
    place_name: "Jerome Park, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Jerome Park",
      bbox: {
        south: 40.86426669999999,
        west: -73.90590519999999,
        north: 40.885878,
        east: -73.8795037,
      },
      center: {
        lat: 40.8734044,
        lng: -73.8893661,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 437000000,
    },
  },
  {
    id: "8TNlPBEalFMpnOCw6fac",
    name: "Uniondale",
    place_name: "Uniondale, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Uniondale",
      bbox: {
        south: 40.684017,
        west: -73.6171008,
        north: 40.7475659,
        east: -73.574837,
      },
      center: {
        lat: 40.700611,
        lng: -73.5931669,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 786000000,
    },
  },
  {
    id: "8Xst55UQpgs492jUwQZo",
    name: "Baxter Estates",
    place_name: "Baxter Estates, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Baxter Estates",
      bbox: {
        south: 40.8303869,
        west: -73.700694,
        north: 40.8370184,
        east: -73.6891739,
      },
      center: {
        lat: 40.8348225,
        lng: -73.6954078,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 833000000,
    },
  },
  {
    id: "8YYcGyB2ntCQfUznq5Yx",
    name: "Roosevelt",
    place_name: "Roosevelt, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Roosevelt",
      bbox: {
        south: 40.6652289,
        west: -73.60152409999999,
        north: 40.691261,
        east: -73.56858,
      },
      center: {
        lat: 40.67871299999999,
        lng: -73.5890168,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 970000000,
    },
  },
  {
    id: "8fqSfCElCMPYzbg6NrRE",
    name: "Williamsburg",
    place_name: "Williamsburg, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Williamsburg",
      bbox: {
        south: 40.6979329,
        west: -73.96984499999999,
        north: 40.7251773,
        east: -73.9369427,
      },
      center: {
        lat: 40.7081156,
        lng: -73.9570696,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "8lZecVUxEx6Ll64NeEIt",
    name: "Greenlawn",
    place_name: "Greenlawn, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Greenlawn",
      bbox: {
        south: 40.83535,
        west: -73.390593,
        north: 40.886157,
        east: -73.337223,
      },
      center: {
        lat: 40.8689871,
        lng: -73.36511850000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 483000000,
    },
  },
  {
    id: "8sdb9eElEUbBdZCX26ld",
    name: "Morris Park",
    place_name: "Morris Park, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Morris Park",
      bbox: {
        south: 40.8429881,
        west: -73.865262,
        north: 40.857679,
        east: -73.8356029,
      },
      center: {
        lat: 40.850332,
        lng: -73.8513735,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 436000000,
    },
  },
  {
    id: "8st4o8uq9aMekcVRFNI2",
    name: "Two Bridges",
    place_name: "Two Bridges, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Two Bridges",
      bbox: {
        south: 40.70808419999999,
        west: -74.001267,
        north: 40.7144925,
        east: -73.9841008,
      },
      center: [-73.99696, 40.7107469],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 791000000,
    },
  },
  {
    id: "96j3Xif2w18k0GpUAOH7",
    name: "Hewlett Bay Park",
    place_name: "Hewlett Bay Park, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Hewlett Bay Park",
      bbox: {
        south: 40.626787,
        west: -73.7008829,
        north: 40.6410799,
        east: -73.689455,
      },
      center: {
        lat: 40.6331592,
        lng: -73.6954087,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 760000000,
    },
  },
  {
    id: "9L25WCbUneqHYGuw3I31",
    name: "Bayonne",
    place_name: "Bayonne, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bayonne",
      bbox: {
        south: 40.6421489,
        west: -74.1613741,
        north: 40.701072,
        east: -74.049594,
      },
      center: {
        lat: 40.6687141,
        lng: -74.1143091,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "9LLf0D9IYVlYF4dZZdi1",
    name: "Hell's Kitchen",
    place_name: "Hell's Kitchen, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Hell's Kitchen",
      bbox: {
        south: 40.752223,
        west: -74.00473989999999,
        north: 40.773677,
        east: -73.9824487,
      },
      center: [-73.9918181, 40.7637581],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 873000000,
    },
  },
  {
    id: "9RAreNHtm8fJAePVWUaU",
    name: "Hicksville",
    place_name: "Hicksville, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Hicksville",
      bbox: {
        south: 40.7431277,
        west: -73.5601421,
        north: 40.7930564,
        east: -73.499665,
      },
      center: {
        lat: 40.7684331,
        lng: -73.5251253,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 828000000,
    },
  },
  {
    id: "9WbszujEqhhYFojlTMT3",
    name: "Princeton",
    place_name: "Princeton, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Princeton",
      bbox: {
        south: 40.3048718,
        west: -74.722177,
        north: 40.3917548,
        east: -74.61750789999999,
      },
      center: {
        lat: 40.3572976,
        lng: -74.6672226,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "9twCk8UPKOYCsgmX9PbE",
    name: "Bergen County",
    place_name: "Bergen County, NJ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bergen County",
      bbox: {
        south: 40.76159,
        west: -74.272226,
        north: 41.1332421,
        east: -73.8939789,
      },
      center: {
        lat: 40.9262762,
        lng: -74.07701,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 8000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "9ukP1ZcPesKiEcSyXuKQ",
    name: "South Beach",
    place_name: "South Beach, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "South Beach",
      bbox: {
        south: 40.58903341970849,
        west: -74.0681565802915,
        north: 40.59173138029149,
        east: -74.0654586197085,
      },
      center: {
        lat: 40.5903824,
        lng: -74.06680759999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 116000000,
    },
  },
  {
    id: "A52lCrmyozsF8s7sByik",
    name: "Ocean County",
    place_name: "Ocean County, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Ocean County",
      bbox: {
        south: 39.475198,
        west: -74.5531051,
        north: 40.172402,
        east: -73.96541409999999,
      },
      center: {
        lat: 39.9652553,
        lng: -74.3118212,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 72000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "A9cwfzCDjxEgseAN67Lr",
    name: "Stapleton",
    place_name: "Stapleton, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Stapleton",
      bbox: {
        south: 40.62514391970849,
        west: -74.07899038029149,
        north: 40.62784188029149,
        east: -74.07629241970848,
      },
      center: {
        lat: 40.6264929,
        lng: -74.07764139999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 115000000,
    },
  },
  {
    id: "AAlGqW7dP5bH4ZqXIug0",
    name: "Woodsburgh",
    place_name: "Woodsburgh, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Woodsburgh",
      bbox: {
        south: 40.61432509999999,
        west: -73.7142449,
        north: 40.628985,
        east: -73.69911789999999,
      },
      center: {
        lat: 40.6239928,
        lng: -73.7084646,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 861000000,
    },
  },
  {
    id: "AOhARCl3WnDimkDXgjo3",
    name: "Eastchester",
    borough: "Bronx",
    county: "Bronx County",
    state_short: "NY",
    place_name: "Eastchester, Bronx, NY",
    state: "New York",
    created: {
      seconds: 1726207593,
      nanoseconds: 482000000,
    },
    components: {
      center: {
        lng: -73.82794710000002,
        lat: 40.8859837,
      },
      bbox: {
        south: 40.8747513,
        west: -73.850584,
        east: -73.80450429999999,
        north: 40.8942364,
      },
      text: "Eastchester",
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "AW8HgDZJrw1ZPA4Z3slT",
    name: "Castle Hill",
    place_name: "Castle Hill, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Castle Hill",
      bbox: {
        south: 40.8102789,
        west: -73.8572423,
        north: 40.8243909,
        east: -73.8398042,
      },
      center: {
        lat: 40.8176831,
        lng: -73.8507279,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 478000000,
    },
  },
  {
    id: "AZ4FtYu2BlOR9vOoWbMF",
    name: "Toms River",
    place_name: "Toms River, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Toms River",
      bbox: {
        south: 39.9330019,
        west: -74.270651,
        north: 40.0587159,
        east: -74.05857979999999,
      },
      center: {
        lat: 39.9537358,
        lng: -74.1979458,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 573000000,
    },
  },
  {
    id: "Aalk9wOL7SkBgluqaNp6",
    name: "Great Neck Gardens",
    place_name: "Great Neck Gardens, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Great Neck Gardens",
      bbox: {
        south: 40.7939439,
        west: -73.728331,
        north: 40.8002349,
        east: -73.7155141,
      },
      center: {
        lat: 40.7972222,
        lng: -73.72388889999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "ArQz5SsbS4gOKiTXZakP",
    name: "Hewlett Neck",
    place_name: "Hewlett Neck, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Hewlett Neck",
      bbox: {
        south: 40.6200351,
        west: -73.7056127,
        north: 40.62808280000001,
        east: -73.691642,
      },
      center: {
        lat: 40.623437,
        lng: -73.6981866,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 857000000,
    },
  },
  {
    id: "Ay3PWKuoHr0HSkB2oGye",
    name: "Cove Neck",
    place_name: "Cove Neck, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Cove Neck",
      bbox: {
        south: 40.8695139,
        west: -73.5121668,
        north: 40.8962979,
        east: -73.48716590000001,
      },
      center: {
        lat: 40.8823207,
        lng: -73.5059562,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 758000000,
    },
  },
  {
    id: "Aykkrz84dGTFCLbhK1jp",
    name: "Jackson Township",
    place_name: "Jackson Township, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Jackson Township",
      bbox: {
        south: 39.9990331,
        west: -74.469543,
        north: 40.17235489999999,
        east: -74.241539,
      },
      center: {
        lat: 40.0918895,
        lng: -74.35874729999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 580000000,
    },
  },
  {
    id: "AzeQqv9KcGOvnxLMWFRZ",
    name: "Carnegie Hill",
    place_name: "Carnegie Hill, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Carnegie Hill",
      bbox: {
        south: 40.7787704,
        west: -73.9605188,
        north: 40.787869,
        east: -73.9493608,
      },
      center: {
        lat: 40.7844653,
        lng: -73.9550857,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 384000000,
    },
  },
  {
    id: "B8hqYMXKlVJ9txhwaFHS",
    name: "North Merrick",
    place_name: "North Merrick, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Merrick",
      bbox: {
        south: 40.674292,
        west: -73.575946,
        north: 40.6990659,
        east: -73.5448837,
      },
      center: {
        lat: 40.6913859,
        lng: -73.5635312,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 803000000,
    },
  },
  {
    id: "BAGPb1cmplNAspPiI5q7",
    name: "Port Morris",
    place_name: "Port Morris, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Port Morris",
      bbox: {
        south: 40.79678,
        west: -73.9325059,
        north: 40.811779,
        east: -73.9021542,
      },
      center: {
        lat: 40.8022025,
        lng: -73.9166051,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 549000000,
    },
  },
  {
    id: "BHJwmCki7JociwM8MTbf",
    name: "Springfield Gardens",
    place_name: "Springfield Gardens, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Springfield Gardens",
      bbox: {
        south: 40.6669817,
        west: -73.7870145,
        north: 40.6783073,
        east: -73.7711542,
      },
      center: [-73.779798, 40.6715916],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 219000000,
    },
  },
  {
    id: "BKgWYr03Ew9PrtGUL6IK",
    name: "Little Italy",
    place_name: "Little Italy, New York, NY ",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Little Italy",
      bbox: {
        south: 40.7168155,
        west: -74.000548,
        north: 40.721577,
        east: -73.9944313,
      },
      center: {
        lat: 40.7191413,
        lng: -73.9973273,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 327000000,
    },
  },
  {
    id: "BPNO2TVj3wCVxGSCz2IT",
    name: "Columbia Street Waterfront District",
    place_name: "Columbia Street Waterfront District, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Columbia Street Waterfront District",
      bbox: {
        south: 40.679071,
        west: -74.00838379999999,
        north: 40.6923873,
        east: -73.99838299999999,
      },
      center: {
        lat: 40.6873691,
        lng: -74.0021019,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 673000000,
    },
  },
  {
    id: "BeJIkFUUiOpVvKTRuhci",
    name: "Morris Plains",
    place_name: "Morris Plains, NJ ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Morris Plains",
      bbox: {
        south: 40.8198129,
        west: -74.495925,
        north: 40.8530589,
        east: -74.460166,
      },
      center: {
        lat: 40.83959220000001,
        lng: -74.4818698,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 499000000,
    },
  },
  {
    id: "BjDKbxvQcG1p2rtR1JkN",
    name: "Concourse",
    borough: "Bronx",
    state_short: "NY",
    county: "Bronx County",
    components: {
      center: {
        lng: -73.9227554,
        lat: 40.8316761,
      },
      text: "Concourse",
      types: ["neighborhood", "political"],
      bbox: {
        north: 40.838479,
        south: 40.818436,
        west: -73.9320059,
        east: -73.90722889999999,
      },
    },
    state: "New York",
    place_name: "Concourse, Bronx, NY",
    created: {
      seconds: 1726207593,
      nanoseconds: 453000000,
    },
  },
  {
    id: "C8gbACIh8qNoDeCkRn5a",
    name: "Wading River",
    place_name: "Wading River, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Wading River",
      bbox: {
        south: 40.9109139,
        west: -72.8638041,
        north: 40.97034,
        east: -72.779881,
      },
      center: {
        lat: 40.9503762,
        lng: -72.8426016,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "CBAcqvl7LJAsb0exMTo4",
    name: "Laurelton",
    place_name: "Laurelton, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Laurelton",
      bbox: {
        south: 40.665535,
        west: -73.7601311,
        north: 40.6886451,
        east: -73.7262889,
      },
      center: [-73.7447853, 40.67764],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 222000000,
    },
  },
  {
    id: "CL4O9klx1ePGqfIGxQGA",
    name: "Suffolk County",
    place_name: "Suffolk County, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Suffolk County",
      bbox: {
        south: 40.534265,
        west: -73.49740489999999,
        north: 41.3103871,
        east: -71.777491,
      },
      center: {
        lat: 40.9848784,
        lng: -72.61511689999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 994000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "CNSEOJDkUey8uJJROlMj",
    borough: "Manhattan",
    components: {
      center: {
        lat: 40.8115504,
        lng: -73.9464769,
      },
      bbox: {
        east: -73.9286431,
        south: 40.7827839,
        north: 40.834443,
        west: -73.9623589,
      },
      text: "Harlem",
      types: ["neighborhood", "political"],
    },
    county: "New York County",
    state_short: "NY",
    name: "Harlem",
    created: {
      seconds: 1726188299,
      nanoseconds: 920000000,
    },
    state: "New York",
    place_name: "Harlem, New York, NY",
  },
  {
    id: "CPDkLKpfUPardOpHijss",
    name: "Williston Park",
    place_name: "Williston Park, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Williston Park",
      bbox: {
        south: 40.75119,
        west: -73.6605635,
        north: 40.7664949,
        east: -73.63902999999999,
      },
      center: {
        lat: 40.75649,
        lng: -73.6448513,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 990000000,
    },
  },
  {
    id: "CYpJpmQSJHciWctHI0bq",
    components: {
      center: {
        lng: -73.9970307,
        lat: 40.7157509,
      },
      text: "Chinatown",
      bbox: {
        east: -73.9841008,
        west: -74.0040592,
        north: 40.71936549999999,
        south: 40.70808419999999,
      },
      types: ["neighborhood", "political"],
    },
    county: "New York County",
    state: "New York",
    borough: "Manhattan",
    name: "Chinatown",
    state_short: "NY",
    created: {
      seconds: 1726203137,
      nanoseconds: 928000000,
    },
    place_name: "Chinatown, New York, NY",
  },
  {
    id: "CdplbZUGSjZcKTvRpPAl",
    name: "Lincoln Square",
    place_name: "Lincoln Square, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Lincoln Square",
      bbox: {
        south: 40.768483,
        west: -73.9963789,
        north: 40.781822,
        east: -73.97608989999999,
      },
      center: {
        lat: 40.7741769,
        lng: -73.98491179999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 341000000,
    },
  },
  {
    id: "CfQ8ZDorF23we1IZbxz4",
    name: "Dix Hills",
    place_name: "Dix Hills, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Dix Hills",
      bbox: {
        south: 40.7670869,
        west: -73.38287799999999,
        north: 40.838557,
        east: -73.293477,
      },
      center: {
        lat: 40.8048207,
        lng: -73.3362298,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "CjLIPSwI0NNp65o9vi29",
    name: "Gramercy Park",
    place_name: "Gramercy Park, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Gramercy Park",
      bbox: {
        south: 40.7313276,
        west: -73.98879459999999,
        north: 40.74018510000001,
        east: -73.9785298,
      },
      center: [-73.9844722, 40.7367783],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 886000000,
    },
  },
  {
    id: "Ckg1kX875y1gY3yrOy6X",
    name: "Ozone Park",
    place_name: "Ozone Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Ozone Park",
      bbox: {
        south: 40.6668285,
        west: -73.8662949,
        north: 40.69129059999999,
        east: -73.8334656,
      },
      center: {
        lat: 40.6806669,
        lng: -73.844659,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 885000000,
    },
  },
  {
    id: "CuS8Mv8IQAcce4LhEyYn",
    name: "Edgemere",
    place_name: "Edgemere, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Edgemere",
      bbox: {
        south: 40.5875757,
        west: -73.78947959999999,
        north: 40.6097987,
        east: -73.7637305,
      },
      center: {
        lat: 40.5942276,
        lng: -73.7742616,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 866000000,
    },
  },
  {
    id: "CyJo8UkLaMg0xesJew1s",
    name: "West Village",
    place_name: "West Village, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "West Village",
      bbox: {
        south: 40.7283442,
        west: -74.0106087,
        north: 40.7409452,
        east: -73.99685199999999,
      },
      center: {
        lat: 40.73468,
        lng: -74.0047554,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 330000000,
    },
  },
  {
    id: "CzFLp4rRtRXEfewjskdD",
    name: "Cambria Heights",
    place_name: "Cambria Heights, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Cambria Heights",
      bbox: {
        south: 40.6825921,
        west: -73.7473072,
        north: 40.703019,
        east: -73.7253021,
      },
      center: [-73.7330753, 40.692158],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 213000000,
    },
  },
  {
    id: "D2IY8yptQdssqHDUp5aH",
    name: "West Farms",
    place_name: "West Farms, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "West Farms",
      bbox: {
        south: 40.83747899999999,
        west: -73.8859049,
        north: 40.84817899999999,
        east: -73.876704,
      },
      center: {
        lat: 40.8430609,
        lng: -73.8816001,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 475000000,
    },
  },
  {
    id: "D2pBsuDycesfEDDiXKKC",
    name: "Readington Township",
    place_name: "Readington Township, NJ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Readington Township",
      bbox: {
        south: 40.5032519,
        west: -74.868607,
        north: 40.66846100000001,
        east: -74.702231,
      },
      center: {
        lat: 40.5687141,
        lng: -74.73766189999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 564000000,
    },
  },
  {
    id: "D2pS9Kx6s1E9YmK2vyyW",
    name: "Maspeth",
    place_name: "Maspeth, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Maspeth",
      bbox: {
        south: 40.7122838,
        west: -73.92880439999999,
        north: 40.7409005,
        east: -73.8848247,
      },
      center: {
        lat: 40.7294018,
        lng: -73.9065883,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 152000000,
    },
  },
  {
    id: "D5ayJT7gkXXYHzYli87Q",
    name: "Pleasant Plains",
    place_name: "Pleasant Plains, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Pleasant Plains",
      bbox: {
        south: 40.5226454197085,
        west: -74.21704888029149,
        north: 40.5253433802915,
        east: -74.21435091970848,
      },
      center: {
        lat: 40.5239944,
        lng: -74.21569989999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 123000000,
    },
  },
  {
    id: "D6NJBp0eKLIAfR8rgpfQ",
    name: "Searingtown",
    place_name: "Searingtown, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Searingtown",
      bbox: {
        south: 40.7598774,
        west: -73.6708394,
        north: 40.78164410000001,
        east: -73.649515,
      },
      center: {
        lat: 40.7748231,
        lng: -73.6556848,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 742000000,
    },
  },
  {
    id: "D9OAGDSMeyXqWTQQ4NYR",
    name: "Bayswater",
    place_name: "Bayswater, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Bayswater",
      bbox: {
        south: 40.59624,
        west: -73.77438769999999,
        north: 40.6143129,
        east: -73.75928669999999,
      },
      center: {
        lat: 40.6067815,
        lng: -73.7669075,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 88000000,
    },
  },
  {
    id: "DK6ZiT5OOID5NH6eO5xQ",
    name: "East Atlantic Beach",
    place_name: "East Atlantic Beach, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Atlantic Beach",
      bbox: {
        south: 40.579144,
        west: -73.718104,
        north: 40.5915041,
        east: -73.7010751,
      },
      center: {
        lat: 40.5849014,
        lng: -73.7095365,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 743000000,
    },
  },
  {
    id: "DPuMfi07ZTN7tYDnwM02",
    name: "Queens",
    place_name: "Queens County, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Queens",
      bbox: {
        south: 40.4897939,
        west: -74.0421119,
        north: 40.81171310000001,
        east: -73.700169,
      },
      center: [-73.7948516, 40.7282239],
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 290000000,
    },
  },
  {
    id: "DWVK0d6Q0IaIpAPx3twu",
    name: "Malverne",
    place_name: "Malverne, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Malverne",
      bbox: {
        south: 40.6661979,
        west: -73.686008,
        north: 40.6835571,
        east: -73.6581879,
      },
      center: {
        lat: 40.6789916,
        lng: -73.6740191,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "DbsvTxGE5SRNDC4en9Q2",
    name: "Captree Island",
    place_name: "Captree Island, Gilgo-Oak Beach-Captree, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Captree Island",
      bbox: {
        south: 40.6484541197085,
        west: -73.2632245802915,
        north: 40.6511520802915,
        east: -73.26052661970849,
      },
      center: {
        lat: 40.6498031,
        lng: -73.2618756,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 474000000,
    },
  },
  {
    id: "DpbTj6O4zNjizvdzQPZp",
    name: "Patchogue",
    place_name: "Patchogue, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Patchogue",
      bbox: {
        south: 40.7456138,
        west: -73.0379677,
        north: 40.778137,
        east: -73.002487,
      },
      center: {
        lat: 40.7659783,
        lng: -73.01934299999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 546000000,
    },
  },
  {
    id: "DuFE0rLF7M3LvyZTOTqB",
    name: "Mount Sinai",
    place_name: "Mt Sinai, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Mount Sinai",
      bbox: {
        south: 40.9056349,
        west: -73.04592889999999,
        north: 40.965122,
        east: -72.97955189999999,
      },
      center: {
        lat: 40.9470432,
        lng: -73.0295495,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "DypXixGosOTNPqRBGGDd",
    name: "Farmingdale",
    place_name: "Farmingdale, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Farmingdale",
      bbox: {
        south: 40.7234749,
        west: -73.4630585,
        north: 40.74001,
        east: -73.4329233,
      },
      center: {
        lat: 40.7325997,
        lng: -73.4454009,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 833000000,
    },
  },
  {
    id: "E4VSeq6Zk72QSlgbZgcm",
    name: "Weehawken",
    place_name: "Weehawken, NJ ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Weehawken",
      bbox: {
        south: 40.7566009,
        west: -74.03269879999999,
        north: 40.780685,
        east: -74.0013271,
      },
      center: {
        lat: 40.7663711,
        lng: -74.02537149999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 539000000,
    },
  },
  {
    id: "E4qlCZSDzRrNFAqQ3saS",
    name: "Sea Cliff",
    place_name: "Sea Cliff, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Sea Cliff",
      bbox: {
        south: 40.8345,
        west: -73.67107290000001,
        north: 40.859299,
        east: -73.632503,
      },
      center: {
        lat: 40.8489887,
        lng: -73.64485049999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "EE9DCa1sRczQKlvk5PdW",
    name: "Munsey Park",
    place_name: "Munsey Park, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Munsey Park",
      bbox: {
        south: 40.794617,
        west: -73.6936801,
        north: 40.803497,
        east: -73.668684,
      },
      center: {
        lat: 40.7987119,
        lng: -73.679852,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "EEisykb3PcBHX8mcUBBA",
    name: "Spuyten Duyvil",
    place_name: "Spuyten Duyvil, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Spuyten Duyvil",
      bbox: {
        south: 40.8755781,
        west: -73.92459819999999,
        north: 40.88624980000001,
        east: -73.9085051,
      },
      center: {
        lat: 40.88116369999999,
        lng: -73.9154069,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207783,
      nanoseconds: 835000000,
    },
  },
  {
    id: "EKkKvHgSqRgKqh2z1OkT",
    name: "Sparta",
    place_name: "Sparta, NJ ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Sparta",
      bbox: {
        south: 40.990047,
        west: -74.6884269,
        north: 41.1186109,
        east: -74.536002,
      },
      center: {
        lat: 41.0360345,
        lng: -74.63672559999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 539000000,
    },
  },
  {
    id: "EKuJHvYm4BKVel5WMR9B",
    name: "Westerleigh",
    place_name: "Westerleigh, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Westerleigh",
      bbox: {
        south: 40.610301,
        west: -74.14605399999999,
        north: 40.6263896,
        east: -74.13072489999999,
      },
      center: {
        lat: 40.616296,
        lng: -74.1386767,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 129000000,
    },
  },
  {
    id: "EOclsQi2KKXQ2h3QZUCr",
    name: "Kismet",
    place_name: "Kismet, Fire Island, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Kismet",
      bbox: {
        south: 40.6330552,
        west: -73.2081918,
        north: 40.638075,
        east: -73.2008705,
      },
      center: {
        lat: 40.6350653,
        lng: -73.2050221,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 479000000,
    },
  },
  {
    id: "EQDED3mhMc54TmB50I4n",
    name: "Point Lookout",
    place_name: "Point Lookout, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Point Lookout",
      bbox: {
        south: 40.5855369,
        west: -73.5834711,
        north: 40.59477709999999,
        east: -73.574906,
      },
      center: {
        lat: 40.5923255,
        lng: -73.58068399999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 759000000,
    },
  },
  {
    id: "ERXF6wgvq7Bn8ZqSsXfu",
    name: "Lighthouse Hill",
    place_name: "Lighthouse Hill, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Lighthouse Hill",
      bbox: {
        south: 40.5718931,
        west: -74.14622489999999,
        north: 40.586343,
        east: -74.1249769,
      },
      center: {
        lat: 40.5772702,
        lng: -74.1342726,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 193000000,
    },
  },
  {
    id: "EWAkkNCCRpWirw4dsZSm",
    name: "Hollis",
    place_name: "Hollis, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Hollis",
      bbox: {
        south: 40.703182,
        west: -73.7712562,
        north: 40.718672,
        east: -73.74987,
      },
      center: [-73.762495, 40.7112203],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 211000000,
    },
  },
  {
    id: "Eabbk1JSn5qR9mDTx3t2",
    name: "Co-op City",
    place_name: "Co-op City, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Co-op City",
      bbox: {
        south: 40.8725399197085,
        west: -73.83079338029152,
        north: 40.8752378802915,
        east: -73.82809541970852,
      },
      center: {
        lat: 40.8738889,
        lng: -73.82944440000001,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 479000000,
    },
  },
  {
    id: "EcVvY48NbfQwTlFmEdZh",
    name: "Brooklyn",
    place_name: "Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Brooklyn",
      bbox: {
        south: 40.551042,
        west: -74.05663,
        north: 40.739446,
        east: -73.8333651,
      },
      center: {
        lat: 40.6781784,
        lng: -73.9441579,
      },
      types: ["political", "sublocality", "sublocality_level_1"],
    },
    created: {
      seconds: 1726186395,
      nanoseconds: 10000000,
    },
    type: "borough",
    types: ["political", "sublocality", "sublocality_level_1"],
  },
  {
    id: "EejozniWnFJOs8JaeJNq",
    name: "Fort Wadsworth",
    place_name: "Fort Wadsworth, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Fort Wadsworth",
      bbox: {
        south: 40.5935946,
        west: -74.0691076,
        north: 40.6093518,
        east: -74.053125,
      },
      center: {
        lat: 40.6012117,
        lng: -74.0579185,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "EhZA7Vp9whzMbTYRZTqV",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    name: "Jersey City",
    created: {
      seconds: 1726211822,
      nanoseconds: 540000000,
    },
    place_name: "Jersey City, NJ",
    components: {
      text: "Jersey City",
      center: {
        lat: 40.7177545,
        lng: -74.0431435,
      },
      bbox: {
        south: 40.661622,
        west: -74.1170569,
        east: -74.020405,
        north: 40.768867,
      },
      types: ["locality", "political"],
    },
  },
  {
    id: "Em5DJfJx0VKAUh1v5euX",
    name: "Hewlett Harbor",
    place_name: "Hewlett Harbor, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Hewlett Harbor",
      bbox: {
        south: 40.6229851,
        west: -73.693073,
        north: 40.640983,
        east: -73.6733309,
      },
      center: {
        lat: 40.6362146,
        lng: -73.6815194,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 758000000,
    },
  },
  {
    id: "EvBHgFfVgODvNfTwVaTY",
    name: "Wakefield",
    place_name: "Wakefield, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Wakefield",
      bbox: {
        south: 40.881978,
        west: -73.8675269,
        north: 40.9101091,
        east: -73.8383028,
      },
      center: {
        lat: 40.89655,
        lng: -73.8507279,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 477000000,
    },
  },
  {
    id: "F4Ir7gYnMeipZFgjAYGC",
    name: "Elmhurst",
    place_name: "Elmhurst, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Elmhurst",
      bbox: {
        south: 40.728286,
        west: -73.8944508,
        north: 40.749152,
        east: -73.8644693,
      },
      center: {
        lat: 40.73940350000001,
        lng: -73.8779431,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 153000000,
    },
  },
  {
    id: "F5CBBCJ4847jai85lKw9",
    components: {
      center: {
        lng: -74.00827869999999,
        lat: 40.7077143,
      },
      bbox: {
        south: 40.7005852,
        west: -74.0193666,
        north: 40.7186805,
        east: -73.9993878,
      },
      text: "Financial District",
      types: ["neighborhood", "political"],
    },
    name: "Financial District",
    state: "New York",
    place_name: "Financial District, New York, NY",
    county: "New York County",
    borough: "Manhattan",
    state_short: "NY",
    created: {
      seconds: 1726188299,
      nanoseconds: 910000000,
    },
  },
  {
    id: "F5EUzw9dHBGQO3AFc0UR",
    name: "Woodbridge Township",
    place_name: "Woodbridge Township, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Woodbridge Township",
      bbox: {
        south: 40.4908049,
        west: -74.338493,
        north: 40.608903,
        east: -74.228289,
      },
      center: {
        lat: 40.5575986,
        lng: -74.2846022,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "FKyerho8f9YDgxhzlMUk",
    name: "Hackensack",
    place_name: "Hackensack, NJ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hackensack",
      bbox: {
        south: 40.8587798,
        west: -74.0679318,
        north: 40.9132537,
        east: -74.0248865,
      },
      center: {
        lat: 40.8859325,
        lng: -74.0434736,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 460000000,
    },
  },
  {
    id: "FMFSUwuW1S55ikW9nwvC",
    name: "South Valley Stream",
    place_name: "South Valley Stream, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "South Valley Stream",
      bbox: {
        south: 40.6444148,
        west: -73.72838899999999,
        north: 40.6646073,
        east: -73.708815,
      },
      center: {
        lat: 40.6559368,
        lng: -73.7176315,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 62000000,
    },
  },
  {
    id: "FMKQSJb54PMKkZFW8CWK",
    name: "Clearview",
    place_name: "Clearview, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Clearview",
      bbox: {
        south: 40.7730751,
        west: -73.8038993,
        north: 40.7879654,
        east: -73.78436219999999,
      },
      center: {
        lat: 40.7804859,
        lng: -73.7977928,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 88000000,
    },
  },
  {
    id: "FMWmtWl3SeNfslvhu2Sx",
    name: "Hamilton Township",
    place_name: "Hamilton Township, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hamilton Township",
      bbox: {
        south: 40.136843,
        west: -74.752766,
        north: 40.2709029,
        east: -74.587847,
      },
      center: {
        lat: 40.2115109,
        lng: -74.6796651,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 569000000,
    },
  },
  {
    id: "FMiQkDnnSYtOOJE605f0",
    name: "Carroll Gardens",
    place_name: "Carroll Gardens, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Carroll Gardens",
      bbox: {
        south: 40.671976,
        west: -74.00341019999999,
        north: 40.6864471,
        east: -73.9891435,
      },
      center: {
        lat: 40.6795331,
        lng: -73.9991637,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "FRywmOUNYtL1LP9kt804",
    name: "Copiague",
    place_name: "Copiague, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Copiague",
      bbox: {
        south: 40.6516176,
        west: -73.4174539,
        north: 40.6941275,
        east: -73.36446509999999,
      },
      center: {
        lat: 40.6814892,
        lng: -73.3998445,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "FU0EgNYMFLxUj5oRPysH",
    name: "Civic Center",
    place_name: "Civic Center, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Civic Center",
      bbox: {
        south: 40.7097756,
        west: -74.00873419999999,
        north: 40.71776149999999,
        east: -73.9982199,
      },
      center: {
        lat: 40.7140519,
        lng: -74.00283639999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 964000000,
    },
  },
  {
    id: "FVaygMt1vHF9JpiA5qea",
    name: "City Island",
    place_name: "City Island, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "City Island",
      bbox: {
        south: 40.8459736197085,
        west: -73.78787078029151,
        north: 40.8486715802915,
        east: -73.7851728197085,
      },
      center: {
        lat: 40.8473226,
        lng: -73.7865218,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 523000000,
    },
  },
  {
    id: "FW23qWvit3zqakOU8IUv",
    name: "East Flatbush",
    place_name: "East Flatbush, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "East Flatbush",
      bbox: {
        south: 40.63172460000001,
        west: -73.952518,
        north: 40.665596,
        east: -73.9082588,
      },
      center: {
        lat: 40.6481656,
        lng: -73.9299899,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 175000000,
    },
  },
  {
    id: "FXr9AUrW5rKI6uafIrb7",
    name: "Englewood",
    place_name: "Englewood, NJ ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Englewood",
      bbox: {
        south: 40.8655679,
        west: -73.9945719,
        north: 40.9134581,
        east: -73.949229,
      },
      center: {
        lat: 40.8928771,
        lng: -73.9726381,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 458000000,
    },
  },
  {
    id: "FYwyaqQ4cbIVtewAgxIB",
    components: {
      types: ["neighborhood", "political"],
      center: {
        lng: -73.92346189999999,
        lat: 40.7643574,
      },
      text: "Astoria",
      bbox: {
        west: -73.9508771,
        east: -73.89113119999999,
        north: 40.7909358,
        south: 40.748964,
      },
    },
    name: "Astoria",
    state_short: "NY",
    state: "New York",
    borough: "Queens",
    created: {
      seconds: 1726205338,
      nanoseconds: 113000000,
    },
    place_name: "Astoria, Queens, NY",
    county: "Queens County",
  },
  {
    id: "Fii9cgNG6C3kOUWgqgSf",
    name: "Quinton",
    place_name: "Quinton, NJ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Quinton",
      bbox: {
        south: 39.471523,
        west: -75.46026499999999,
        north: 39.5788,
        east: -75.3070089,
      },
      center: {
        lat: 39.5459869,
        lng: -75.4125645,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 505000000,
    },
  },
  {
    id: "FnPNaTHWUatzxjhu3pTl",
    name: "Jericho",
    place_name: "Jericho, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Jericho",
      bbox: {
        south: 40.771543,
        west: -73.5689913,
        north: 40.8030119,
        east: -73.51503199999999,
      },
      center: {
        lat: 40.79204410000001,
        lng: -73.5398476,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 819000000,
    },
  },
  {
    id: "FquRCi3uNvUSU5CbWcXn",
    name: "Jamaica Hills",
    place_name: "Jamaica Hills, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Jamaica Hills",
      bbox: {
        south: 40.7077018,
        west: -73.8077757,
        north: 40.7198784,
        east: -73.7904736,
      },
      center: {
        lat: 40.7135548,
        lng: -73.796488,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 862000000,
    },
  },
  {
    id: "FvsB8b4IoYC5i6XRgDmL",
    name: "Franklin Square",
    place_name: "Franklin Square, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Franklin Square",
      bbox: {
        south: 40.682874,
        west: -73.6911777,
        north: 40.7175799,
        east: -73.6610281,
      },
      center: {
        lat: 40.7073244,
        lng: -73.6759635,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 758000000,
    },
  },
  {
    id: "G84eLWlCOlD6nH5n958x",
    place_name: "Valley Stream, NY",
    state_short: "NY",
    state: "New York",
    borough: "Nassau County",
    created: {
      seconds: 1726208962,
      nanoseconds: 795000000,
    },
    name: "Valley Stream",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      center: {
        lat: 40.6642699,
        lng: -73.70846449999999,
      },
      bbox: {
        west: -73.72838899999999,
        south: 40.6444148,
        east: -73.679909,
        north: 40.6968629,
      },
      text: "Valley Stream",
    },
  },
  {
    id: "G8zOTK2oepZNOLryYwEI",
    name: "Centerport",
    place_name: "Centerport, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Centerport",
      bbox: {
        south: 40.8751509,
        west: -73.392089,
        north: 40.9206969,
        east: -73.3507131,
      },
      center: {
        lat: 40.8991963,
        lng: -73.3678388,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 475000000,
    },
  },
  {
    id: "GHl9DgqlybyKRv4Dutxt",
    name: "Fort Lee",
    place_name: "Fort Lee, NJ ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Fort Lee",
      bbox: {
        south: 40.8276,
        west: -73.991325,
        north: 40.86890289999999,
        east: -73.948385,
      },
      center: {
        lat: 40.8509333,
        lng: -73.9701381,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 464000000,
    },
  },
  {
    id: "GSROyjZrx12hHc0bARtq",
    name: "New Springville",
    place_name: "New Springville, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "New Springville",
      bbox: {
        south: 40.5708238,
        west: -74.17090689999999,
        north: 40.5966419,
        east: -74.1455981,
      },
      center: {
        lat: 40.5847957,
        lng: -74.16034840000002,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 171000000,
    },
  },
  {
    id: "GVkEfghtz6ZJs8ivOsKU",
    name: "Smithtown",
    place_name: "Smithtown, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Smithtown",
      bbox: {
        south: 40.82523,
        west: -73.27052599999999,
        north: 40.898738,
        east: -73.15855499999999,
      },
      center: {
        lat: 40.854906,
        lng: -73.1954295,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "GgRJsJqzcfFrGNgrhhdK",
    name: "Kew Gardens Hills",
    place_name: "Kew Gardens Hills, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Kew Gardens Hills",
      bbox: {
        south: 40.7156393,
        west: -73.83773819999999,
        north: 40.7434168,
        east: -73.8077757,
      },
      center: {
        lat: 40.7246203,
        lng: -73.8207637,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 858000000,
    },
  },
  {
    id: "GryRX2lanB4AJuYWwBQM",
    name: "South Floral Park",
    place_name: "South Floral Park, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "South Floral Park",
      bbox: {
        south: 40.710926,
        west: -73.70495679999999,
        north: 40.7164701,
        east: -73.6955672,
      },
      center: {
        lat: 40.714269,
        lng: -73.70013089999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "HF8AtYx7PNVVWPo7a6yx",
    name: "Centereach",
    place_name: "Centereach, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Centereach",
      bbox: {
        south: 40.8323169,
        west: -73.1094738,
        north: 40.9044859,
        east: -73.0517749,
      },
      center: {
        lat: 40.8584316,
        lng: -73.0995539,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 470000000,
    },
  },
  {
    id: "HFOBknGYG0WgY0MzgUEc",
    name: "Great Neck Estates",
    place_name: "Great Neck Estates, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Great Neck Estates",
      bbox: {
        south: 40.7759518,
        west: -73.74958,
        north: 40.7960779,
        east: -73.72938490000001,
      },
      center: {
        lat: 40.7870459,
        lng: -73.73679829999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "HM8j7Niu3et7SOEeGA5E",
    name: "McGinley Square",
    place_name: "McGinley Square, Jersey City, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    borough: "Suffolk County",
    components: {
      text: "McGinley Square",
      bbox: {
        south: 40.716702,
        west: -74.0772892,
        north: 40.7319946,
        east: -74.06392199999999,
      },
      center: {
        lat: 40.7241223,
        lng: -74.06966729999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726212600,
      nanoseconds: 831000000,
    },
  },
  {
    id: "HOIukHO1jrZBkTxPMn3E",
    components: {
      center: {
        lng: -73.9707016,
        lat: 40.5755438,
      },
      bbox: {
        west: -74.01288079999999,
        east: -73.9311231,
        north: 40.5841992,
        south: 40.5703742,
      },
      text: "Coney Island",
      types: ["establishment", "natural_feature"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
    county: "Kings County",
    place_name: "Coney Island, Brooklyn, NY",
    state_short: "NY",
    name: "Coney Island",
    borough: "Brooklyn",
    state: "New York",
  },
  {
    id: "HOjSZrc3gp3B0yQ7MHKn",
    name: "Middlesex County",
    place_name: "Middlesex County, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Middlesex County",
      bbox: {
        south: 40.251122,
        west: -74.6310359,
        north: 40.608683,
        east: -74.2033603,
      },
      center: {
        lat: 40.4111363,
        lng: -74.35874729999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 32000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "HZEjujhE0OgT1Sz4hKxI",
    name: "Melrose",
    place_name: "Melrose, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Melrose",
      bbox: {
        south: 40.8231960197085,
        west: -73.91176328029151,
        north: 40.8258939802915,
        east: -73.90906531970849,
      },
      center: {
        lat: 40.824545,
        lng: -73.9104143,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 444000000,
    },
  },
  {
    id: "HiCBqBzNKCtbpZCeLSP4",
    name: "Roslyn Heights",
    place_name: "Roslyn Heights, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Roslyn Heights",
      bbox: {
        south: 40.75963489999999,
        west: -73.6663619,
        north: 40.7946224,
        east: -73.6237939,
      },
      center: {
        lat: 40.7887117,
        lng: -73.6473511,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 983000000,
    },
  },
  {
    id: "IHDJnWjlXSGp2P7kza2x",
    name: "Kips Bay",
    place_name: "Kips Bay, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Kips Bay",
      bbox: {
        south: 40.734901,
        west: -73.98473369999999,
        north: 40.7463913,
        east: -73.9717143,
      },
      center: [-73.9800645, 40.74232920000001],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 863000000,
    },
  },
  {
    id: "IR9IAoctTJlzMQ4DiMtR",
    name: "St. George",
    place_name: "St. George, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "St. George",
      bbox: {
        south: 40.6357345,
        west: -74.0897129,
        north: 40.649101,
        east: -74.0689751,
      },
      center: {
        lat: 40.6427017,
        lng: -74.0799469,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 123000000,
    },
  },
  {
    id: "ITp2mztUBDMeUxmJURpu",
    name: "South Ozone Park",
    place_name: "South Ozone Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "South Ozone Park",
      bbox: {
        south: 40.659734,
        west: -73.84216649999999,
        north: 40.6938801,
        east: -73.8010314,
      },
      center: {
        lat: 40.6764003,
        lng: -73.8124984,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 886000000,
    },
  },
  {
    id: "Ie2Tfrf85Q91gXx2gphG",
    name: "Inwood",
    place_name: "Inwood, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Inwood",
      bbox: {
        south: 40.609984,
        west: -73.7670231,
        north: 40.633387,
        east: -73.73152999999999,
      },
      center: {
        lat: 40.6220487,
        lng: -73.74679890000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726188300,
      nanoseconds: 48000000,
    },
  },
  {
    id: "ItdXGotwJepXva0zJTEZ",
    name: "Halesite",
    place_name: "Halesite, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Halesite",
      bbox: {
        south: 40.87793200000001,
        west: -73.426773,
        north: 40.8961818,
        east: -73.4026849,
      },
      center: {
        lat: 40.8884315,
        lng: -73.4153975,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 474000000,
    },
  },
  {
    id: "J30JguOHdtr7bWyTBCVa",
    components: {
      center: {
        lat: 40.8006567,
        lng: -73.7284647,
      },
      types: ["locality", "political"],
      bbox: {
        east: -73.7101138,
        west: -73.7575112,
        north: 40.8139188,
        south: 40.7759518,
      },
      text: "Great Neck",
    },
    county: "Nassau County",
    place_name: "Great Neck, NY",
    created: {
      seconds: 1726208962,
      nanoseconds: 786000000,
    },
    state_short: "NY",
    borough: "Nassau County",
    state: "New York",
    name: "Great Neck",
  },
  {
    id: "J7aWo9nLpOQAtaI2CbdA",
    name: "Northport",
    place_name: "Northport, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Northport",
      bbox: {
        south: 40.8884719,
        west: -73.36106180000002,
        north: 40.919297,
        east: -73.3265491,
      },
      center: {
        lat: 40.9009313,
        lng: -73.3431727,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "J9BOFRygLYiwnN4QpeJN",
    name: "Bellerose",
    state_short: "NY",
    components: {
      bbox: {
        north: 40.7440232,
        east: -73.7105,
        west: -73.74830539999999,
        south: 40.7225804,
      },
      center: {
        lat: 40.73576389999999,
        lng: -73.7271909,
      },
      text: "Bellerose Manor",
      types: ["neighborhood", "political"],
    },
    place_name: "Bellerose, Queens, NY",
    county: "Queens County",
    state: "New York",
    created: {
      seconds: 1726205338,
      nanoseconds: 72000000,
    },
    borough: "Queens",
  },
  {
    id: "JMLwelIQGuEuF53TBnTU",
    name: "Melville",
    place_name: "Melville, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Melville",
      bbox: {
        south: 40.7517572,
        west: -73.448747,
        north: 40.815497,
        east: -73.36811399999999,
      },
      center: {
        lat: 40.7934322,
        lng: -73.41512139999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 552000000,
    },
  },
  {
    id: "JNN1CbC2oyaWrNvEaafH",
    name: "Bridgewater",
    place_name: "Bridgewater, NJ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bridgewater",
      bbox: {
        south: 40.542879,
        west: -74.6879051,
        north: 40.649311,
        east: -74.5121829,
      },
      center: {
        lat: 40.5939636,
        lng: -74.6049061,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 572000000,
    },
  },
  {
    id: "JODFfxhPDaWrn8DXN6Nw",
    name: "Andover",
    place_name: "Andover, NJ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Andover",
      bbox: {
        south: 40.9687061,
        west: -74.7513629,
        north: 41.00002,
        east: -74.731974,
      },
      center: {
        lat: 40.98593109999999,
        lng: -74.74210889999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 605000000,
    },
  },
  {
    id: "JUnBSVfKBh81qYxbit1g",
    name: "Somerset County",
    place_name: "Somerset County, NJ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Somerset County",
      bbox: {
        south: 40.373733,
        west: -74.79788099999999,
        north: 40.758288,
        east: -74.4017419,
      },
      center: {
        lat: 40.5292099,
        lng: -74.64004320000001,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 27000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "JZsE3gtdbLBcVaPX0JTJ",
    name: "Ronkonkoma",
    place_name: "Ronkonkoma, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Ronkonkoma",
      bbox: {
        south: 40.78192680000001,
        west: -73.17149479999999,
        north: 40.832977,
        east: -73.084153,
      },
      center: {
        lat: 40.80641869999999,
        lng: -73.1241488,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "JbjZAQdOyZXwuuRnqOSd",
    name: "New Dorp Beach",
    place_name: "New Dorp Beach, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "New Dorp Beach",
      bbox: {
        south: 40.5578222,
        west: -74.11610019999999,
        north: 40.5737193,
        east: -74.0906917,
      },
      center: {
        lat: 40.5657068,
        lng: -74.1049093,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208357,
      nanoseconds: 589000000,
    },
  },
  {
    id: "Jf1gikjb90nwUPtDfkXo",
    name: "Pelham Parkway",
    place_name: "Pelham Parkway, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Pelham Parkway",
      bbox: {
        south: 40.85030709999999,
        west: -73.8699323,
        north: 40.8569732,
        east: -73.857365,
      },
      center: {
        lat: 40.8553279,
        lng: -73.8639594,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 451000000,
    },
  },
  {
    id: "Jg4L7JWYJDpEAfYWVYVt",
    name: "Garden City Park",
    place_name: "Garden City Park, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Garden City Park",
      bbox: {
        south: 40.7330909,
        west: -73.67325799999999,
        north: 40.7532691,
        east: -73.65187990000001,
      },
      center: {
        lat: 40.7410315,
        lng: -73.6630251,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "JiBF2cLwPL8yJJGMUBp8",
    name: "Island Park",
    place_name: "Island Park, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Island Park",
      bbox: {
        south: 40.5964689,
        west: -73.66203600000001,
        north: 40.6170269,
        east: -73.6483205,
      },
      center: {
        lat: 40.6042705,
        lng: -73.65540779999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 776000000,
    },
  },
  {
    id: "JmZmh80t5HKYxLlWfrGC",
    name: "Bellerose Terrace",
    place_name: "Bellerose Terrace, Floral Park, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Bellerose Terrace",
      bbox: {
        south: 40.719167,
        west: -73.7303259,
        north: 40.725342,
        east: -73.72032209999999,
      },
      center: {
        lat: 40.7206581,
        lng: -73.7259648,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 754000000,
    },
  },
  {
    id: "JodovULvFhl1Q2Bju6n4",
    name: "Castleton Corners",
    place_name: "Castleton Corners, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Castleton Corners",
      bbox: {
        south: 40.6077239,
        west: -74.14724919999999,
        north: 40.6278229,
        east: -74.10361209999999,
      },
      center: {
        lat: 40.6130802,
        lng: -74.1225608,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 107000000,
    },
  },
  {
    id: "Jx56SBDK2amgw2ZtmUwU",
    name: "Kingsbridge",
    place_name: "Kingsbridge, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Kingsbridge",
      bbox: {
        south: 40.8733781,
        west: -73.9114048,
        north: 40.889578,
        east: -73.896705,
      },
      center: {
        lat: 40.8833912,
        lng: -73.9051185,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 480000000,
    },
  },
  {
    id: "K0lshQV4PvNGcAwh9nxV",
    name: "Flatlands",
    place_name: "Flatlands, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Flatlands",
      bbox: {
        south: 40.6113917,
        west: -73.9474364,
        north: 40.6334541,
        east: -73.9168823,
      },
      center: {
        lat: 40.6232714,
        lng: -73.9321664,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 671000000,
    },
  },
  {
    id: "KCC4TTm2eoHkfKF38dE0",
    name: "Haddonfield",
    place_name: "Haddonfield, NJ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Haddonfield",
      bbox: {
        south: 39.871918,
        west: -75.0514731,
        north: 39.9165149,
        east: -75.016322,
      },
      center: {
        lat: 39.8915022,
        lng: -75.03767069999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 542000000,
    },
  },
  {
    id: "KH1I2UWVbjYPfDxRZI5m",
    name: "Harding Park",
    place_name: "Harding Park, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Harding Park",
      bbox: {
        south: 40.8044801,
        west: -73.8705094,
        north: 40.8158555,
        east: -73.84740409999999,
      },
      center: {
        lat: 40.8096822,
        lng: -73.8551385,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 477000000,
    },
  },
  {
    id: "KU0qYHn22MXzKhKg1Cvl",
    name: "Islandia",
    place_name: "Islandia, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Islandia",
      bbox: {
        south: 40.7954779,
        west: -73.1972929,
        north: 40.817383,
        east: -73.150589,
      },
      center: {
        lat: 40.8042649,
        lng: -73.1690019,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 548000000,
    },
  },
  {
    id: "KUTAQ8ezVuW4XciB91gY",
    name: "Manorhaven",
    place_name: "Manorhaven, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Manorhaven",
      bbox: {
        south: 40.8314,
        west: -73.7226859,
        north: 40.847309,
        east: -73.702974,
      },
      center: {
        lat: 40.8431559,
        lng: -73.71485299999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 830000000,
    },
  },
  {
    id: "KXDaZC5XaHjCpmdux93W",
    name: "North Hempstead",
    place_name: "North Hempstead, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Hempstead",
      bbox: {
        south: 40.72783099999999,
        west: -73.78019309999999,
        north: 40.90305,
        east: -73.549553,
      },
      center: {
        lat: 40.7492264,
        lng: -73.6411162,
      },
      types: ["administrative_area_level_3", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 969000000,
    },
  },
  {
    id: "KbJB14fBmxNi0AzSlcQe",
    name: "Massapequa Park",
    place_name: "Massapequa Park, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Massapequa Park",
      bbox: {
        south: 40.650822,
        west: -73.464345,
        north: 40.705561,
        east: -73.4363169,
      },
      center: {
        lat: 40.6804893,
        lng: -73.4553119,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 52000000,
    },
  },
  {
    id: "KiKna9qg79GR97vqEXDc",
    name: "East Newark",
    place_name: "East Newark, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "East Newark",
      bbox: {
        south: 40.7474008,
        west: -74.1660689,
        north: 40.7547179,
        east: -74.1572569,
      },
      center: {
        lat: 40.7497019,
        lng: -74.16363129999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 537000000,
    },
  },
  {
    id: "KlEJK6pDicnqMoNlGqOB",
    name: "Grant City",
    place_name: "Grant City, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Grant City",
      bbox: {
        south: 40.5807002197085,
        west: -74.10621308029151,
        north: 40.5833981802915,
        east: -74.1035151197085,
      },
      center: {
        lat: 40.5820492,
        lng: -74.1048641,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 166000000,
    },
  },
  {
    id: "KlQ8Y5e10iiG7Vyu0jXQ",
    name: "Murray Hill",
    place_name: "Murray Hill, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Murray Hill",
      bbox: {
        south: 40.7437943,
        west: -73.98296359999999,
        north: 40.7528,
        east: -73.9698745,
      },
      center: [-73.9756567, 40.7478792],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 861000000,
    },
  },
  {
    id: "Ks2UFOAibyHmbI5xTfXv",
    name: "Rockville Centre",
    place_name: "Rockville Centre, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Rockville Centre",
      bbox: {
        south: 40.6474229,
        west: -73.6586839,
        north: 40.6900228,
        east: -73.617594,
      },
      center: {
        lat: 40.65871380000001,
        lng: -73.6412406,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 708000000,
    },
  },
  {
    id: "Ksv1N5nZBpfbl4wj1Elt",
    created: {
      seconds: 1726188299,
      nanoseconds: 909000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: {
        lng: -73.9842724,
        lat: 40.715033,
      },
      text: "Lower East Side",
      bbox: {
        south: 40.7091004,
        north: 40.7344458,
        west: -74.0040592,
        east: -73.9716661,
      },
    },
    state_short: "NY",
    place_name: "Lower East Side, New York, NY",
    state: "New York",
    name: "Lower East Side",
    county: "New York County",
    borough: "Manhattan",
  },
  {
    id: "KtJKy3jPmZWPtXcnqzuc",
    name: "Van Cortlandt Village",
    place_name: "Van Cortlandt Village, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Van Cortlandt Village",
      bbox: {
        south: 40.8813824,
        west: -73.8991185,
        north: 40.8859971,
        east: -73.8875995,
      },
      center: {
        lat: 40.8837203,
        lng: -73.89313899999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 553000000,
    },
  },
  {
    id: "KuZqphapQEsxvLQMvXKK",
    name: "Bloomfield",
    place_name: "Bloomfield, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Bloomfield",
      bbox: {
        south: 40.6112549197085,
        west: -74.1795484802915,
        north: 40.6139528802915,
        east: -74.1768505197085,
      },
      center: {
        lat: 40.6126039,
        lng: -74.17819949999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 104000000,
    },
  },
  {
    id: "L39fSMMe1IhEk2ICdxAj",
    name: "NoMad",
    place_name: "NoMad, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "NoMad",
      bbox: {
        south: 40.7407795,
        west: -73.99187909999999,
        north: 40.7472713,
        east: -73.9815661,
      },
      center: {
        lat: 40.7446072,
        lng: -73.9874105,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 346000000,
    },
  },
  {
    id: "L6QF6jIrTsojnodTiu2z",
    name: "Gilgo Beach",
    place_name: "Gilgo Beach, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Gilgo Beach",
      bbox: {
        south: 40.6170856197085,
        west: -73.39897188029151,
        north: 40.6197835802915,
        east: -73.3962739197085,
      },
      center: {
        lat: 40.6184346,
        lng: -73.3976229,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 466000000,
    },
  },
  {
    id: "L6VG7ATciWg0TQlpYvgF",
    name: "Pelham Gardens",
    place_name: "Pelham Gardens, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Pelham Gardens",
      bbox: {
        south: 40.8560789,
        west: -73.85659509999999,
        north: 40.8716631,
        east: -73.82680309999999,
      },
      center: {
        lat: 40.8627726,
        lng: -73.84343919999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 446000000,
    },
  },
  {
    id: "LDpl0NPiRaJAkxv9wx0M",
    name: "Old Westbury",
    place_name: "Old Westbury, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Old Westbury",
      bbox: {
        south: 40.75654100000001,
        west: -73.6257859,
        north: 40.81619389999999,
        east: -73.564602,
      },
      center: {
        lat: 40.7887113,
        lng: -73.5995717,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 980000000,
    },
  },
  {
    id: "LFNuGhBkqlBU9vcQj0FB",
    name: "Manhattan Valley",
    place_name: "Manhattan Valley, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Manhattan Valley",
      bbox: {
        south: 40.791684,
        west: -73.9718257,
        north: 40.8041882,
        east: -73.9582099,
      },
      center: {
        lat: 40.7966989,
        lng: -73.9684247,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 337000000,
    },
  },
  {
    id: "Lq4VTCHSRZSXG1n8pgvp",
    name: "Sunnyside",
    place_name: "Sunnyside, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Sunnyside",
      bbox: {
        south: 40.610163,
        west: -74.117004,
        north: 40.6206639,
        east: -74.097261,
      },
      center: {
        lat: 40.6128507,
        lng: -74.1026407,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "Lroh4sCWF07MZKvt1R0n",
    name: "Plainview",
    place_name: "Plainview, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Plainview",
      bbox: {
        south: 40.7615989,
        west: -73.50367010000001,
        north: 40.805436,
        east: -73.444096,
      },
      center: {
        lat: 40.7764882,
        lng: -73.4673454,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 787000000,
    },
  },
  {
    id: "MOfZwQjbmOPurskMI57f",
    name: "Woodbury",
    place_name: "Woodbury, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Woodbury",
      bbox: {
        south: 39.8240559,
        west: -75.17082289999999,
        north: 39.855007,
        east: -75.1350949,
      },
      center: {
        lat: 39.83816909999999,
        lng: -75.1526774,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 542000000,
    },
  },
  {
    id: "MX9C0DHt3tFuqUMOhRMf",
    name: "Windsor Terrace",
    place_name: "Windsor Terrace, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Windsor Terrace",
      bbox: {
        south: 40.6472954,
        west: -73.9862359,
        north: 40.662158,
        east: -73.9714193,
      },
      center: {
        lat: 40.6539346,
        lng: -73.9756567,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 629000000,
    },
  },
  {
    id: "McQ6RHQqGeSKbfCO1nZS",
    name: "Bellport",
    place_name: "Bellport, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Bellport",
      bbox: {
        south: 40.740417,
        west: -72.9562239,
        north: 40.7660547,
        east: -72.92768,
      },
      center: {
        lat: 40.75704289999999,
        lng: -72.9392727,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 471000000,
    },
  },
  {
    id: "MfMAiIduxTUbj2EIwO5C",
    name: "Arrochar",
    place_name: "Arrochar, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Arrochar",
      bbox: {
        south: 40.5880917,
        west: -74.0858662,
        north: 40.600585,
        east: -74.06184329999999,
      },
      center: {
        lat: 40.5967312,
        lng: -74.0704232,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 107000000,
    },
  },
  {
    id: "MuuQRjixGXOCaWU1X68t",
    name: "Sutton Place",
    place_name: "Sutton Place, New York, NY ",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Sutton Place",
      bbox: {
        south: 40.7544872,
        west: -73.9650803,
        north: 40.7598419,
        east: -73.9584941,
      },
      center: [-73.961698, 40.7576281],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 867000000,
    },
  },
  {
    id: "N0GXWuHYPPSxManTH165",
    name: "Shiloh",
    place_name: "Shiloh, NJ ",
    county: "Cumberland County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Shiloh",
      bbox: {
        south: 39.4528261,
        west: -75.3113329,
        north: 39.4660571,
        east: -75.27993099999999,
      },
      center: {
        lat: 39.4590032,
        lng: -75.2990798,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 488000000,
    },
  },
  {
    id: "N1dIg9fi77cqNDNOBHo9",
    name: "Park of Edgewater",
    place_name: "Park of Edgewater, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Park of Edgewater",
      bbox: {
        south: 40.8205971,
        west: -73.815465,
        north: 40.8256907,
        east: -73.80605469999999,
      },
      center: {
        lat: 40.8235967,
        lng: -73.81029269999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 439000000,
    },
  },
  {
    id: "NBymT3ZzXKIoJobobDVI",
    name: "Centre Island",
    place_name: "Centre Island, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Centre Island",
      bbox: {
        south: 40.885354,
        west: -73.5341638,
        north: 40.91831200000001,
        east: -73.5076729,
      },
      center: {
        lat: 40.9017651,
        lng: -73.5198453,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 760000000,
    },
  },
  {
    id: "NJ2fLCcerITsXmLkrJXs",
    name: "East Brunswick",
    place_name: "East Brunswick, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "East Brunswick",
      bbox: {
        south: 40.37110699999999,
        west: -74.48571679999999,
        north: 40.487319,
        east: -74.3558539,
      },
      center: {
        lat: 40.4316955,
        lng: -74.4049778,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 489000000,
    },
  },
  {
    id: "NPNNtkq28F7cTcziwr2S",
    name: "Davis Park",
    place_name: "Davis Park, Fire Island, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Davis Park",
      bbox: {
        south: 40.6833265,
        west: -73.00604489999999,
        north: 40.6865973,
        east: -73.0001892,
      },
      center: {
        lat: 40.6853682,
        lng: -73.0039985,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 547000000,
    },
  },
  {
    id: "NQslxPhOrpdCXvTw5Thg",
    name: "Vineland",
    place_name: "Vineland, NJ",
    county: "Cumberland County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Vineland",
      bbox: {
        south: 39.3777149,
        west: -75.0871289,
        north: 39.568643,
        east: -74.896626,
      },
      center: {
        lat: 39.4863773,
        lng: -75.02596369999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 474000000,
    },
  },
  {
    id: "NVnn4ZPficn7LFbBVyhz",
    name: "Cobble Hill",
    place_name: "Cobble Hill, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Cobble Hill",
      bbox: {
        south: 40.6829299,
        west: -74.0007037,
        north: 40.6915438,
        east: -73.9891732,
      },
      center: {
        lat: 40.686536,
        lng: -73.9962255,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "NbLX079prZrtETxAnE5S",
    name: "Amity Harbor",
    place_name: "Amity Harbor, Copiague, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Amity Harbor",
      bbox: {
        south: 40.6551617,
        west: -73.40457239999999,
        north: 40.668279,
        east: -73.39491029999999,
      },
      center: {
        lat: 40.6619534,
        lng: -73.398867,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 457000000,
    },
  },
  {
    id: "NmFDIFH4ciBKKCklv1Hj",
    name: "Central Harlem",
    place_name: "Central Harlem, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Central Harlem",
      bbox: {
        south: 40.7968871,
        west: -73.9595748,
        north: 40.8308904,
        east: -73.9338182,
      },
      center: {
        lat: 40.8089419,
        lng: -73.9482305,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 343000000,
    },
  },
  {
    id: "O1E4LMY8CTTku7IgU8Ph",
    state: "New York",
    name: "Westbury",
    county: "Nassau County",
    place_name: "Westbury, NY",
    borough: "Nassau County",
    state_short: "NY",
    components: {
      bbox: {
        east: -73.5624057,
        west: -73.6257859,
        south: 40.745723,
        north: 40.81619389999999,
      },
      text: "Westbury",
      center: {
        lng: -73.5876273,
        lat: 40.7556561,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 739000000,
    },
  },
  {
    id: "O5x2AY3XlsuRhgXoSjL1",
    name: "Evesham",
    place_name: "Evesham, NJ ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Evesham",
      bbox: {
        south: 39.7816311,
        west: -74.957223,
        north: 39.9198279,
        east: -74.8622779,
      },
      center: {
        lat: 39.8522951,
        lng: -74.88579890000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "O6BLPrWxIEzM0egMKZhr",
    name: "St. Albans",
    place_name: "St. Albans, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "St. Albans",
      bbox: {
        south: 40.6742488,
        west: -73.7869741,
        north: 40.7088107,
        east: -73.74410689999999,
      },
      center: [-73.76436880000001, 40.6895283],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 226000000,
    },
  },
  {
    id: "OEt3sRGitLYOf3goNr3T",
    name: "North Wantagh",
    place_name: "North Wantagh, Wantagh, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Wantagh",
      bbox: {
        south: 40.690782,
        west: -73.53187989999999,
        north: 40.707896,
        east: -73.48222510000001,
      },
      center: {
        lat: 40.6971013,
        lng: -73.5070637,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 759000000,
    },
  },
  {
    id: "OG5spMFiaQUirfWNGoTy",
    name: "Mapleton",
    place_name: "Mapleton, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Mapleton",
      bbox: {
        south: 40.6086071,
        west: -73.9900913,
        north: 40.63054,
        east: -73.9729419,
      },
      center: {
        lat: 40.6186359,
        lng: -73.9800679,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 673000000,
    },
  },
  {
    id: "OI9XPKBlXX19yEpao8QY",
    name: "Elm Park",
    place_name: "Elm Park, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Elm Park",
      bbox: {
        south: 40.62973751970849,
        west: -74.1482979802915,
        north: 40.63243548029149,
        east: -74.14560001970848,
      },
      center: {
        lat: 40.63108649999999,
        lng: -74.14694899999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 179000000,
    },
  },
  {
    id: "OQEjk9J2X2OJievP9SuK",
    name: "Glen Head",
    place_name: "Glen Head, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Glen Head",
      bbox: {
        south: 40.829958,
        west: -73.63571,
        north: 40.856978,
        east: -73.5902479,
      },
      center: {
        lat: 40.8353776,
        lng: -73.62373880000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 980000000,
    },
  },
  {
    id: "Oay6N6k8HpNLt4viCc5T",
    name: "Plandome Manor",
    place_name: "Plandome Manor, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Plandome Manor",
      bbox: {
        south: 40.809,
        west: -73.710021,
        north: 40.82083919999999,
        east: -73.68706379999999,
      },
      center: {
        lat: 40.8162116,
        lng: -73.6973524,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 864000000,
    },
  },
  {
    id: "OeY1a0NcgxQfUvTwGn4K",
    name: "Mount Hope",
    place_name: "Mount Hope, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Mount Hope",
      bbox: {
        south: 40.844579,
        west: -73.914206,
        north: 40.854079,
        east: -73.8997049,
      },
      center: {
        lat: 40.8488863,
        lng: -73.9051185,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 457000000,
    },
  },
  {
    id: "Oena2tDdCbayczN8bqCU",
    name: "East Tremont",
    place_name: "East Tremont, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "East Tremont",
      bbox: {
        south: 40.84402911970851,
        west: -73.89231828029149,
        north: 40.84672708029151,
        east: -73.88962031970848,
      },
      center: {
        lat: 40.8453781,
        lng: -73.8909693,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 467000000,
    },
  },
  {
    id: "OmzBSvUTjGc0bbAXCYHm",
    name: "Hunters Point",
    place_name: "Hunters Point, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Hunters Point",
      bbox: {
        south: 40.7375126,
        west: -73.9627433,
        north: 40.7545707,
        east: -73.9364179,
      },
      center: {
        lat: 40.7485587,
        lng: -73.94964639999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 868000000,
    },
  },
  {
    id: "P1MAoBXWkmir9yyYUmcq",
    name: "Woodstock",
    place_name: "Woodstock, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Woodstock",
      bbox: {
        south: 40.809679,
        west: -73.9176059,
        north: 40.824179,
        east: -73.8896512,
      },
      center: {
        lat: 40.8195143,
        lng: -73.9016685,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 422000000,
    },
  },
  {
    id: "P3nSbOTPtKl8BmYMA71q",
    name: "East Orange",
    place_name: "East Orange, NJ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "East Orange",
      bbox: {
        south: 40.7450939,
        west: -74.238349,
        north: 40.78812300000001,
        east: -74.1899889,
      },
      center: {
        lat: 40.767323,
        lng: -74.2048677,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 531000000,
    },
  },
  {
    id: "P57MuNNHhXtacouZ0eGz",
    name: "Little Falls",
    place_name: "Little Falls, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Little Falls",
      bbox: {
        south: 40.8596221,
        west: -74.2544759,
        north: 40.89264,
        east: -74.1906889,
      },
      center: {
        lat: 40.8690101,
        lng: -74.20822860000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 575000000,
    },
  },
  {
    id: "P8W4uwxcjJ4VvY6dkMno",
    name: "Oakwood Beach",
    place_name: "Oakwood Beach, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Oakwood Beach",
      bbox: {
        south: 40.5512563197085,
        west: -74.11565768029149,
        north: 40.5539542802915,
        east: -74.11295971970848,
      },
      center: {
        lat: 40.5526053,
        lng: -74.1143087,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208357,
      nanoseconds: 636000000,
    },
  },
  {
    id: "PEPwdz1piW15TlawYOFZ",
    name: "Brick Township",
    place_name: "Brick Township, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Brick Township",
      bbox: {
        south: 40.0012168,
        west: -74.1605699,
        north: 40.1370359,
        east: -74.0511199,
      },
      center: {
        lat: 40.0578384,
        lng: -74.1371832,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 501000000,
    },
  },
  {
    id: "PNHxXLfOsU1T3dfmSiQZ",
    name: "Eltingville",
    place_name: "Eltingville, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Eltingville",
      bbox: {
        south: 40.5309239,
        west: -74.1697863,
        north: 40.5610839,
        east: -74.1462731,
      },
      center: {
        lat: 40.5394463,
        lng: -74.156292,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "PWXnPNedmHNK4RFwAGln",
    name: "Woodmere",
    place_name: "Woodmere, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Woodmere",
      bbox: {
        south: 40.618403,
        west: -73.74670189999999,
        north: 40.653509,
        east: -73.6964701,
      },
      center: {
        lat: 40.6320482,
        lng: -73.71263139999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 799000000,
    },
  },
  {
    id: "Pecjv5y1I7htzg15mZk6",
    name: "Lakeview",
    place_name: "Lakeview, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Lakeview",
      bbox: {
        south: 40.6629379,
        west: -73.6625509,
        north: 40.690287,
        east: -73.6327369,
      },
      center: {
        lat: 40.6772526,
        lng: -73.6529475,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 970000000,
    },
  },
  {
    id: "Ph5pqgGh2p9OcFB3pbft",
    name: "Belle Harbor",
    place_name: "Belle Harbor, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Belle Harbor",
      bbox: {
        south: 40.569517,
        west: -73.8606185,
        north: 40.5821715,
        east: -73.8438166,
      },
      center: {
        lat: 40.5754846,
        lng: -73.8507279,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 889000000,
    },
  },
  {
    id: "PisHyvMYeSBJ5SH0MmCW",
    name: "Hudson Yards",
    place_name: "Hudson Yards, New York, NY ",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Hudson Yards",
      bbox: {
        south: 40.7526498197085,
        west: -74.00354268029152,
        north: 40.7553477802915,
        east: -74.0008447197085,
      },
      center: [-74.0022078, 40.754247],
      types: ["route"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 872000000,
    },
  },
  {
    id: "PkU7yPB732AE11M3Q7d5",
    name: "Jamaica",
    place_name: "Jamaica, Queens, NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Jamaica",
      bbox: {
        east: -73.77354679999999,
        west: -73.8662949,
        south: 40.6443744,
        north: 40.739025,
      },
      center: {
        lat: 40.7037439,
        lng: -73.7991953,
      },
    },
    state: "New York",
    state_short: "NY",
    county: "Queens County",
    created: {
      seconds: 1726205338,
      nanoseconds: 3000000,
    },
    borough: "Queens",
  },
  {
    id: "PvOlD9OhrBH8tNfJoBFR",
    name: "East Flushing",
    place_name: "East Flushing, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "East Flushing",
      bbox: {
        south: 40.7381951,
        west: -73.824737,
        north: 40.7614099,
        east: -73.7944472,
      },
      center: {
        lat: 40.7540534,
        lng: -73.8086418,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 76000000,
    },
  },
  {
    id: "PzuOshpCsSMLX7kmdIRe",
    name: "Briarwood",
    place_name: "Briarwood, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Briarwood",
      bbox: {
        south: 40.704042,
        west: -73.82595789999999,
        north: 40.7163223,
        east: -73.80278349999999,
      },
      center: {
        lat: 40.7109315,
        lng: -73.81356099999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 88000000,
    },
  },
  {
    id: "Q5nuUrsgTRRfWyJTHWoy",
    name: "Liberty Island",
    place_name: "Liberty Island, New York, NY 10004",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Liberty Island",
      bbox: {
        south: 40.6884842697085,
        west: -74.047238,
        north: 40.6911822302915,
        east: -74.0434787,
      },
      center: {
        lat: 40.6895453,
        lng: -74.0449292,
      },
      types: ["establishment", "natural_feature"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 904000000,
    },
  },
  {
    id: "QGsInlD5sZw31N6stJuL",
    name: "West Hempstead",
    place_name: "West Hempstead, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "West Hempstead",
      bbox: {
        south: 40.6825339,
        west: -73.6681579,
        north: 40.7127009,
        east: -73.63180000000001,
      },
      center: {
        lat: 40.7048242,
        lng: -73.65012949999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 969000000,
    },
  },
  {
    id: "QOa5jbrRbBSi0KLRKtRt",
    name: "Morris Heights",
    place_name: "Morris Heights, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Morris Heights",
      bbox: {
        south: 40.844679,
        west: -73.928106,
        north: 40.85630219999999,
        east: -73.90710490000001,
      },
      center: {
        lat: 40.8505298,
        lng: -73.91554289999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 548000000,
    },
  },
  {
    id: "Qgj2Wz4RZNLXK46hhDjR",
    name: "Cliffside Park",
    place_name: "Cliffside Park, NJ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Cliffside Park",
      bbox: {
        south: 40.806453,
        west: -73.9980001,
        north: 40.8343999,
        east: -73.9763821,
      },
      center: {
        lat: 40.8214894,
        lng: -73.9876388,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 459000000,
    },
  },
  {
    id: "QuUTOR6JPojq6Uhut0sc",
    name: "East Hills",
    place_name: "East Hills, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Hills",
      bbox: {
        south: 40.777991,
        west: -73.644058,
        north: 40.8124271,
        east: -73.608141,
      },
      center: {
        lat: 40.7937115,
        lng: -73.62707259999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "QzSpQ2scGAGdaBoREOCE",
    name: "Edgewater",
    place_name: "Edgewater, NJ ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Edgewater",
      bbox: {
        south: 40.7975169,
        west: -73.9944119,
        north: 40.8481,
        east: -73.9539819,
      },
      center: {
        lat: 40.8270448,
        lng: -73.97569399999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "R1jZwTcsgysDul0Sz8DC",
    name: "Prospect Park South",
    place_name: "Prospect Park South, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Prospect Park South",
      bbox: {
        south: 40.643528,
        west: -73.9704488,
        north: 40.649171,
        east: -73.9617019,
      },
      center: {
        lat: 40.6457741,
        lng: -73.96610609999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 190000000,
    },
  },
  {
    id: "RCsmUnBluSUJhvIOcBOY",
    name: "Baldwin Harbor",
    place_name: "Baldwin Harbor, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Baldwin Harbor",
      bbox: {
        south: 40.619403,
        west: -73.617009,
        north: 40.642478,
        east: -73.5856719,
      },
      center: {
        lat: 40.6260153,
        lng: -73.6006342,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 830000000,
    },
  },
  {
    id: "RHSH7hmASyEodTrviCCA",
    name: "Sussex County",
    place_name: "Sussex County, NJ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Sussex County",
      bbox: {
        south: 40.8991651,
        west: -74.992015,
        north: 41.3576614,
        east: -74.36729319999999,
      },
      center: {
        lat: 41.1288481,
        lng: -74.6868815,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211022,
      nanoseconds: 995000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "RO3NejwnmJyJHylzoUW9",
    name: "Levittown",
    place_name: "Levittown, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Levittown",
      bbox: {
        south: 40.7000169,
        west: -73.54568979999999,
        north: 40.743601,
        east: -73.48447390000001,
      },
      center: {
        lat: 40.7259336,
        lng: -73.51429209999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 709000000,
    },
  },
  {
    id: "RS43CU8jLxr0hPyvafLF",
    name: "Plandome Heights",
    place_name: "Plandome Heights, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Plandome Heights",
      bbox: {
        south: 40.79977909999999,
        west: -73.711567,
        north: 40.80497580000001,
        east: -73.69597689999999,
      },
      center: {
        lat: 40.8026009,
        lng: -73.7042972,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 761000000,
    },
  },
  {
    id: "RYBYybGFNiMc0bxyh4oy",
    name: "Dongan Hills",
    place_name: "Dongan Hills, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Dongan Hills",
      bbox: {
        south: 40.5847149,
        west: -74.1008021,
        north: 40.5970592,
        east: -74.0814632,
      },
      center: {
        lat: 40.5906641,
        lng: -74.088452,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 104000000,
    },
  },
  {
    id: "Rd6d8CRxOqCWvkEdxOze",
    name: "North Valley Stream",
    place_name: "North Valley Stream, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Valley Stream",
      bbox: {
        south: 40.675202,
        west: -73.726405,
        north: 40.6968629,
        east: -73.679909,
      },
      center: {
        lat: 40.6814623,
        lng: -73.7003817,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 989000000,
    },
  },
  {
    id: "RejS77c6pevvDr1Sll8j",
    name: "Sayville",
    place_name: "Sayville, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Sayville",
      bbox: {
        south: 40.7204659,
        west: -73.1131269,
        north: 40.769076,
        east: -73.0593619,
      },
      center: {
        lat: 40.73593169999999,
        lng: -73.08205559999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 548000000,
    },
  },
  {
    id: "RqWtxzsqRVP0GAUxeW5E",
    name: "Country Club",
    place_name: "Country Club, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Country Club",
      bbox: {
        south: 40.8378177197085,
        west: -73.82107118029151,
        north: 40.8405156802915,
        east: -73.8183732197085,
      },
      center: {
        lat: 40.8391667,
        lng: -73.8197222,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 553000000,
    },
  },
  {
    id: "RwkaHZv3KWk7D2lPcQkK",
    name: "Sayreville",
    place_name: "Sayreville, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Sayreville",
      bbox: {
        south: 40.416597,
        west: -74.3861581,
        north: 40.5093071,
        east: -74.243652,
      },
      center: {
        lat: 40.45940210000001,
        lng: -74.360846,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 565000000,
    },
  },
  {
    id: "RzokOHYdp7gLOXCh2a0d",
    name: "Medford",
    place_name: "Medford, NJ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Medford",
      bbox: {
        south: 39.7851501,
        west: -74.8632909,
        north: 39.9360579,
        east: -74.7542431,
      },
      center: {
        lat: 39.86618259999999,
        lng: -74.8390162,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 469000000,
    },
  },
  {
    id: "S6QwwIYoKMRRZZ4I3dbi",
    name: "Concord",
    place_name: "Concord, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Concord",
      bbox: {
        south: 40.6068108197085,
        west: -74.08565708029151,
        north: 40.6095087802915,
        east: -74.0829591197085,
      },
      center: {
        lat: 40.6081598,
        lng: -74.0843081,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 109000000,
    },
  },
  {
    id: "SBqZS3BanPBAwpwVSKKk",
    name: "Manhasset Hills",
    place_name: "Manhasset Hills, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Manhasset Hills",
      bbox: {
        south: 40.7518448,
        west: -73.694155,
        north: 40.7640581,
        east: -73.6672559,
      },
      center: {
        lat: 40.7587117,
        lng: -73.6815156,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 753000000,
    },
  },
  {
    id: "SDKVgcDZ24dc3yZct05k",
    name: "Merrick",
    place_name: "Merrick, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Merrick",
      bbox: {
        south: 40.624677,
        west: -73.576914,
        north: 40.6800699,
        east: -73.5272761,
      },
      center: {
        lat: 40.6628795,
        lng: -73.55151599999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 53000000,
    },
  },
  {
    id: "SHTBB5xxMj4E3GMnaWvc",
    created: {
      seconds: 1726205338,
      nanoseconds: 152000000,
    },
    components: {
      text: "Corona",
      bbox: {
        south: 40.7339983,
        east: -73.84582209999999,
        west: -73.8777685,
        north: 40.7604018,
      },
      center: {
        lat: 40.7449859,
        lng: -73.8642613,
      },
      types: ["neighborhood", "political"],
    },
    name: "Corona",
    borough: "Queens",
    county: "Queens County",
    state: "New York",
    place_name: "Corona, Queens, NY",
    state_short: "NY",
  },
  {
    id: "SSij1vJ0OgnTnH9468bX",
    name: "Bulls Head",
    place_name: "Bulls Head, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Bulls Head",
      bbox: {
        south: 40.5926407,
        west: -74.1734702,
        north: 40.621123,
        east: -74.14282899999999,
      },
      center: {
        lat: 40.6072329,
        lng: -74.162443,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 107000000,
    },
  },
  {
    id: "STXzoc2gd1Vg8kgPUli9",
    name: "Bushwick",
    place_name: "Bushwick, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Bushwick",
      bbox: {
        south: 40.679768,
        west: -73.9419668,
        north: 40.7093928,
        east: -73.9013609,
      },
      center: {
        lat: 40.6957755,
        lng: -73.9170604,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 734000000,
    },
  },
  {
    id: "SkoARF7fP5nLSRKiRJIs",
    name: "Hammonton",
    place_name: "Hammonton, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hammonton",
      bbox: {
        south: 39.5835199,
        west: -74.859134,
        north: 39.7299091,
        east: -74.67578689999999,
      },
      center: {
        lat: 39.6365056,
        lng: -74.8023853,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 460000000,
    },
  },
  {
    id: "SkzkHMWrbiOBpNxxDjE9",
    name: "Manhattan Beach",
    place_name: "Manhattan Beach, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Manhattan Beach",
      bbox: {
        south: 40.5742124,
        west: -73.9560503,
        north: 40.5831758,
        east: -73.93104129999999,
      },
      center: {
        lat: 40.57815799999999,
        lng: -73.93892129999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 740000000,
    },
  },
  {
    id: "Sozu3Ie6nMQFAeey0gDF",
    name: "Lake Success",
    place_name: "Lake Success, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Lake Success",
      bbox: {
        south: 40.7571809,
        west: -73.72940740000001,
        north: 40.782778,
        east: -73.6887781,
      },
      center: {
        lat: 40.7698453,
        lng: -73.70904279999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 787000000,
    },
  },
  {
    id: "SuSSDcJynt0TrGdhrFjK",
    name: "Midtown West",
    components: {
      center: {
        lat: 40.7584306,
        lng: -73.99264579999999,
      },
      types: ["neighborhood", "political"],
      bbox: {
        south: 40.7373582,
        north: 40.7741244,
        west: -74.0088629,
        east: -73.9734618,
      },
      text: "Midtown West",
    },
    county: "New York County",
    place_name: "Midtown West, New York, NY",
    state_short: "NY",
    created: {
      seconds: 1726203137,
      nanoseconds: 898000000,
    },
    borough: "Manhattan",
    state: "New York",
  },
  {
    id: "SueuAgG2ahjONsUlOMtt",
    name: "Marine Park",
    place_name: "Marine Park, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Marine Park",
      bbox: {
        south: 40.6016479,
        west: -73.944807,
        north: 40.622401,
        east: -73.91974909999999,
      },
      center: {
        lat: 40.6113817,
        lng: -73.93318649999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "T2xris1gbBLzoamxiwb3",
    name: "Travis - Chelsea",
    place_name: "Travis - Chelsea, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Travis - Chelsea",
      bbox: {
        south: 40.57709699999999,
        west: -74.2066383,
        north: 40.603491,
        east: -74.1755247,
      },
      center: {
        lat: 40.5890113,
        lng: -74.19151769999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 178000000,
    },
  },
  {
    id: "TAG5pxKUrCQW4dF7YFRe",
    borough: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726208962,
      nanoseconds: 720000000,
    },
    county: "Nassau County",
    name: "Port Washington",
    components: {
      text: "Port Washington",
      bbox: {
        east: -73.6496729,
        south: 40.8052198,
        west: -73.7406879,
        north: 40.8732729,
      },
      types: ["locality", "political"],
      center: {
        lat: 40.8256561,
        lng: -73.69818579999999,
      },
    },
    state_short: "NY",
    place_name: "Port Washington, NY",
  },
  {
    id: "TIsRmk100xmwvyo2uykU",
    name: "Westchester Square",
    place_name: "Westchester Square, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Westchester Square",
      bbox: {
        south: 40.8342087,
        west: -73.8554839,
        north: 40.84782089999999,
        east: -73.8390751,
      },
      center: {
        lat: 40.839815,
        lng: -73.8464802,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 451000000,
    },
  },
  {
    id: "TVHdyuXtUybH71pXgWRt",
    created: {
      seconds: 1726204075,
      nanoseconds: 660000000,
    },
    components: {
      types: ["neighborhood", "political"],
      text: "Bedford-Stuyvesant",
      center: {
        lng: -73.9417735,
        lat: 40.6872176,
      },
      bbox: {
        north: 40.7008292,
        east: -73.9000497,
        west: -73.9619136,
        south: 40.6671133,
      },
    },
    borough: "Brooklyn",
    state_short: "NY",
    place_name: "Bedford-Stuyvesant, Brooklyn, NY",
    state: "New York",
    name: "Bedford-Stuyvesant",
    county: "Kings County",
  },
  {
    id: "TWbJqxHNu8ZryDUIaKn8",
    name: "East Village",
    place_name: "East Village, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "East Village",
      bbox: {
        south: 40.7187211,
        west: -73.99264099999999,
        north: 40.7344458,
        east: -73.9716661,
      },
      center: {
        lat: 40.7264773,
        lng: -73.98153370000001,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 330000000,
    },
  },
  {
    id: "TYvcxYco0GbvtOPow9JM",
    name: "Washington",
    place_name: "Washington, NJ ",
    county: "Warren County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Washington",
      bbox: {
        south: 40.7446541,
        west: -74.9993811,
        north: 40.768524,
        east: -74.96210599999999,
      },
      center: {
        lat: 40.7584326,
        lng: -74.9793378,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 606000000,
    },
  },
  {
    id: "TbI1kK2SdyEVaixNc6rB",
    name: "North Bergen",
    place_name: "North Bergen, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "North Bergen",
      bbox: {
        south: 40.75737,
        west: -74.056895,
        north: 40.8235689,
        east: -73.9848829,
      },
      center: {
        lat: 40.8042674,
        lng: -74.012084,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 542000000,
    },
  },
  {
    id: "Td29yb8Uv5MNQHqnN6wI",
    name: "Fire Island Pines",
    place_name: "Fire Island Pines, Fire Island, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Fire Island Pines",
      bbox: {
        south: 40.6639486,
        west: -73.0726669,
        north: 40.6674137,
        east: -73.0668883,
      },
      center: {
        lat: 40.6653185,
        lng: -73.0693043,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "TqrlO4eXW9eYsICjFykd",
    created: {
      seconds: 1726208962,
      nanoseconds: 710000000,
    },
    place_name: "Garden City, NY",
    state_short: "NY",
    name: "Garden City",
    components: {
      text: "Garden City",
      bbox: {
        north: 40.7475659,
        east: -73.58000679999999,
        west: -73.682924,
        south: 40.705658,
      },
      center: {
        lat: 40.7267682,
        lng: -73.6342954,
      },
      types: ["locality", "political"],
    },
    state: "New York",
    borough: "Nassau County",
    county: "Nassau County",
  },
  {
    id: "UPdEQOEzvGN81lPAKrWM",
    name: "Pleasantville",
    place_name: "Pleasantville, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Pleasantville",
      bbox: {
        south: 39.356318,
        west: -74.5504638,
        north: 39.418938,
        east: -74.485844,
      },
      center: {
        lat: 39.3898388,
        lng: -74.5240422,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 445000000,
    },
  },
  {
    id: "URz7bhNcjPOkNvcltTFu",
    name: "Bellmore",
    state: "New York",
    borough: "Nassau County",
    county: "Nassau County",
    place_name: "Bellmore, NY",
    created: {
      seconds: 1726208962,
      nanoseconds: 709000000,
    },
    components: {
      types: ["locality", "political"],
      text: "Bellmore",
      center: {
        lat: 40.6687125,
        lng: -73.5270709,
      },
      bbox: {
        west: -73.576914,
        south: 40.624677,
        north: 40.706379,
        east: -73.51337,
      },
    },
    state_short: "NY",
  },
  {
    id: "UbmdV1enBpXzLhtfOJbm",
    name: "Van Nest",
    place_name: "Van Nest, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Van Nest",
      bbox: {
        south: 40.8395655,
        west: -73.87430390000002,
        north: 40.8520313,
        east: -73.85340380000001,
      },
      center: {
        lat: 40.8415796,
        lng: -73.8706949,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 442000000,
    },
  },
  {
    id: "UgYLfmRzwg1pKqVvNyWT",
    name: "Far Rockaway",
    place_name: "Far Rockaway, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Far Rockaway",
      bbox: {
        south: 40.590386,
        west: -73.76779100000002,
        north: 40.611778,
        east: -73.73710179999999,
      },
      center: {
        lat: 40.5998931,
        lng: -73.74484369999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 866000000,
    },
  },
  {
    id: "Uid1aUI4BbHOBgRitQ00",
    name: "Prospect Lefferts Gardens",
    place_name: "Prospect Lefferts Gardens, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Prospect Lefferts Gardens",
      bbox: {
        south: 40.6545285,
        west: -73.96272789999999,
        north: 40.66366499999999,
        east: -73.94663299999999,
      },
      center: {
        lat: 40.6592355,
        lng: -73.9533895,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 189000000,
    },
  },
  {
    id: "UkwJJorkOnBRCJwjx6rs",
    name: "Utopia",
    place_name: "Utopia, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Utopia",
      bbox: {
        south: 40.7257876,
        west: -73.7938815,
        north: 40.740383,
        east: -73.7849478,
      },
      center: {
        lat: 40.7309058,
        lng: -73.78995189999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 901000000,
    },
  },
  {
    id: "V46mEOHSNWHCV1E5SEQN",
    name: "East Meadow",
    place_name: "East Meadow, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Meadow",
      bbox: {
        south: 40.694497,
        west: -73.5890489,
        north: 40.749524,
        east: -73.52627,
      },
      center: {
        lat: 40.7139898,
        lng: -73.5590157,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 786000000,
    },
  },
  {
    id: "VLpq8cSaqPAe9pQd96iB",
    name: "Stewart Manor",
    place_name: "Stewart Manor, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Stewart Manor",
      bbox: {
        south: 40.7138519,
        west: -73.689129,
        north: 40.72534700000001,
        east: -73.6813289,
      },
      center: {
        lat: 40.71926879999999,
        lng: -73.6884637,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 756000000,
    },
  },
  {
    id: "VOp4ohYktqPAIewCiELm",
    name: "Holbrook",
    place_name: "Holbrook, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Holbrook",
      bbox: {
        south: 40.7644727,
        west: -73.09796589999999,
        north: 40.8323169,
        east: -73.0424,
      },
      center: {
        lat: 40.81232050000001,
        lng: -73.0784429,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 484000000,
    },
  },
  {
    id: "VPFvXs78ft1q4L2xTyJI",
    name: "Oceanside",
    place_name: "Oceanside, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Oceanside",
      bbox: {
        south: 40.6095029,
        west: -73.661943,
        north: 40.65739689999999,
        east: -73.6136729,
      },
      center: {
        lat: 40.6387141,
        lng: -73.6401296,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 709000000,
    },
  },
  {
    id: "VQ2AAbF1JlS02Uv63bj9",
    name: "Brentwood",
    place_name: "Brentwood, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Brentwood",
      bbox: {
        south: 40.7548436,
        west: -73.295676,
        north: 40.8071239,
        east: -73.209223,
      },
      center: {
        lat: 40.7812093,
        lng: -73.2462273,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 559000000,
    },
  },
  {
    id: "VXZF1p9zgW9zeZrVtbYz",
    name: "South Hempstead",
    place_name: "South Hempstead, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "South Hempstead",
      bbox: {
        south: 40.6713319,
        west: -73.6327989,
        north: 40.6907,
        east: -73.614353,
      },
      center: {
        lat: 40.6809892,
        lng: -73.6235244,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 970000000,
    },
  },
  {
    id: "VZZHTQBIYuTwyVTE02UV",
    name: "Wildwood",
    place_name: "Wildwood, NJ ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Wildwood",
      bbox: {
        south: 38.976884,
        west: -74.8346181,
        north: 38.99878289999999,
        east: -74.8018961,
      },
      center: {
        lat: 38.9917797,
        lng: -74.8148894,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "VcDGyqzvqO2v6pdIeF3a",
    name: "Warren County",
    place_name: "Warren County, OH",
    county: "Warren County",
    state: "Ohio",
    state_short: "OH",
    components: {
      text: "Warren County",
      bbox: {
        south: 39.2551119,
        west: -84.3652321,
        north: 39.589581,
        east: -83.976987,
      },
      center: {
        lat: 39.4241422,
        lng: -84.1857115,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 44000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "VicFvCVxyqcHdIFf6LIS",
    name: "North Babylon",
    place_name: "North Babylon, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "North Babylon",
      bbox: {
        south: 40.7105249,
        west: -73.343878,
        north: 40.7504579,
        east: -73.30647900000001,
      },
      center: {
        lat: 40.7164881,
        lng: -73.3217861,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 543000000,
    },
  },
  {
    id: "VsOVmYyfs0PYBq1X26v3",
    name: "Hillsborough Township",
    place_name: "Hillsborough Township, NJ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hillsborough Township",
      bbox: {
        south: 40.43138,
        west: -74.79784099999999,
        north: 40.5647619,
        east: -74.5721361,
      },
      center: {
        lat: 40.5081222,
        lng: -74.6374329,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 524000000,
    },
  },
  {
    id: "Vse13dWSdE3fULIpgJ4u",
    name: "Concourse Village",
    place_name: "Concourse Village, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Concourse Village",
      bbox: {
        south: 40.818436,
        west: -73.92658399999999,
        north: 40.8363789,
        east: -73.90722889999999,
      },
      center: {
        lat: 40.8255863,
        lng: -73.9184388,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207783,
      nanoseconds: 880000000,
    },
  },
  {
    id: "VtjV6XoD4fDMEAsil2YB",
    name: "North Massapequa",
    place_name: "North Massapequa, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Massapequa",
      bbox: {
        south: 40.681079,
        west: -73.4835059,
        north: 40.71827589999999,
        east: -73.4500281,
      },
      center: {
        lat: 40.70093370000001,
        lng: -73.4620685,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 63000000,
    },
  },
  {
    id: "VxGvjpZVSWQ3rR5bDIQS",
    name: "North Plainfield",
    place_name: "North Plainfield, NJ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "North Plainfield",
      bbox: {
        south: 40.6022161,
        west: -74.465242,
        north: 40.64177400000001,
        east: -74.413737,
      },
      center: {
        lat: 40.6301025,
        lng: -74.4273743,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 520000000,
    },
  },
  {
    id: "W38dyhrGH4VxRjTgKFZ9",
    name: "Muttontown",
    place_name: "Muttontown, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Muttontown",
      bbox: {
        south: 40.794809,
        west: -73.571467,
        north: 40.848607,
        east: -73.505749,
      },
      center: {
        lat: 40.8239883,
        lng: -73.5476252,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 740000000,
    },
  },
  {
    id: "W8fF6Y16vqmFhZWRU4o5",
    name: "Plainedge",
    place_name: "Plainedge, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Plainedge",
      bbox: {
        south: 40.7125765,
        west: -73.49766509999999,
        north: 40.738282,
        east: -73.4594791,
      },
      center: {
        lat: 40.7220026,
        lng: -73.4830147,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 830000000,
    },
  },
  {
    id: "W99wihatVMWhwJA5AFzE",
    name: "Willingboro",
    place_name: "Willingboro, NJ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Willingboro",
      bbox: {
        south: 39.997706,
        west: -74.931589,
        north: 40.057733,
        east: -74.851841,
      },
      center: {
        lat: 40.028848,
        lng: -74.89164579999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 469000000,
    },
  },
  {
    id: "WMnjvCuUsCVkDrNL9VRH",
    name: "Sunnyside",
    place_name: "Sunnyside, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Sunnyside",
      bbox: {
        south: 40.7279041,
        west: -73.93742,
        north: 40.75266329999999,
        east: -73.90885709999999,
      },
      center: {
        lat: 40.7432759,
        lng: -73.9196324,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 71000000,
    },
  },
  {
    id: "WRfB9RaC6VM2axkrnqqL",
    name: "Secaucus",
    place_name: "Secaucus, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Secaucus",
      bbox: {
        south: 40.748802,
        west: -74.09545,
        north: 40.8077021,
        east: -74.033791,
      },
      center: {
        lat: 40.7895453,
        lng: -74.05652979999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 531000000,
    },
  },
  {
    id: "WUc99VHRUdwFl4ACS3r1",
    name: "Barnegat Township",
    place_name: "Barnegat Township, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Barnegat Township",
      bbox: {
        south: 39.6986069,
        west: -74.417164,
        north: 39.828045,
        east: -74.141795,
      },
      center: {
        lat: 39.76492229999999,
        lng: -74.2693297,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 501000000,
    },
  },
  {
    id: "WX3MAGhD34zGRkCgdsoW",
    name: "Voorhees Township",
    place_name: "Voorhees Township, NJ ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Voorhees Township",
      bbox: {
        south: 39.809486,
        west: -75.0184739,
        north: 39.8738388,
        east: -74.9086069,
      },
      center: {
        lat: 39.8519447,
        lng: -74.961517,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 531000000,
    },
  },
  {
    id: "WZvtUyHN0iD9pmVtTajH",
    name: "Old Brookville",
    place_name: "Old Brookville, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Old Brookville",
      bbox: {
        south: 40.8118604,
        west: -73.625982,
        north: 40.855035,
        east: -73.57700799999999,
      },
      center: {
        lat: 40.8320441,
        lng: -73.6048493,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 987000000,
    },
  },
  {
    id: "WdQkpz33xwjS8GIOu5Pz",
    name: "Mount Eden",
    place_name: "Mount Eden, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Mount Eden",
      bbox: {
        south: 40.836279,
        west: -73.9228061,
        north: 40.8451791,
        east: -73.91110499999999,
      },
      center: {
        lat: 40.8407018,
        lng: -73.9168766,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 457000000,
    },
  },
  {
    id: "WenMJv1v4HtVeyJjH9sn",
    name: "Pennsauken Township",
    place_name: "Pennsauken Township, NJ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Pennsauken Township",
      bbox: {
        south: 39.9242009,
        west: -75.12382090000001,
        north: 39.9960808,
        east: -75.0101459,
      },
      center: {
        lat: 39.9562052,
        lng: -75.058009,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "WizQPQODNsTiAIRDAGHM",
    name: "Graniteville",
    place_name: "Graniteville, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Graniteville",
      bbox: {
        south: 40.608146,
        west: -74.16884800000001,
        north: 40.6272193,
        east: -74.1441759,
      },
      center: {
        lat: 40.6183926,
        lng: -74.15528119999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "WmVjCEg5uxgMZummz96k",
    name: "Flower Hill",
    place_name: "Flower Hill, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Flower Hill",
      bbox: {
        south: 40.79715590000001,
        west: -73.69539929999999,
        north: 40.817303,
        east: -73.652638,
      },
      center: {
        lat: 40.8073228,
        lng: -73.68124089999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 786000000,
    },
  },
  {
    id: "WmWLEJuqetKFPF25bwtg",
    name: "Clifton",
    place_name: "Clifton, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Clifton",
      bbox: {
        south: 40.613156,
        west: -74.0879471,
        north: 40.6269024,
        east: -74.06968119999999,
      },
      center: {
        lat: 40.6189726,
        lng: -74.0784785,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 105000000,
    },
  },
  {
    id: "Wsy9AhLMhMFqEFaamNLa",
    name: "Great River",
    place_name: "Great River, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Great River",
      bbox: {
        south: 40.694609,
        west: -73.187837,
        north: 40.748758,
        east: -73.125541,
      },
      center: {
        lat: 40.7212097,
        lng: -73.1576139,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 467000000,
    },
  },
  {
    id: "WyJRkCkewEiszbVVWr0Z",
    name: "Lawrence Township",
    place_name: "Lawrence Township, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Lawrence Township",
      bbox: {
        south: 40.23981089999999,
        west: -74.76239000000001,
        north: 40.3605019,
        east: -74.664039,
      },
      center: {
        lat: 40.2778055,
        lng: -74.7294313,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 566000000,
    },
  },
  {
    id: "XBxRfpbGCOt65tw1rZuA",
    name: "Highland Park",
    place_name: "Highland Park, Jackie Robinson Pkwy, Brooklyn, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Highland Park",
      bbox: {
        south: 40.6842567,
        west: -73.8897935,
        north: 40.6887278,
        east: -73.8822548,
      },
      center: {
        lat: 40.6887278,
        lng: -73.8862997,
      },
      types: ["establishment", "park", "point_of_interest", "tourist_attraction"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 673000000,
    },
  },
  {
    id: "XCoypyBelXQMGYsDJxQT",
    name: "Lloyd Harbor",
    place_name: "Lloyd Harbor, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Lloyd Harbor",
      bbox: {
        south: 40.875271,
        west: -73.49740489999999,
        north: 40.946929,
        east: -73.4287618,
      },
      center: {
        lat: 40.9034315,
        lng: -73.4598432,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "XHEBU88pJXVV22fThITj",
    name: "Mercer County",
    place_name: "Mercer County, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Mercer County",
      bbox: {
        south: 40.136771,
        west: -74.9429091,
        north: 40.424156,
        east: -74.48109509999999,
      },
      center: {
        lat: 40.3028533,
        lng: -74.7337069,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 63000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "XVwlhucNMEYrL5rhZtrc",
    name: "Clason Point",
    place_name: "Clason Point, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Clason Point",
      bbox: {
        south: 40.8044801,
        west: -73.880505,
        north: 40.82214099999999,
        east: -73.84740409999999,
      },
      center: {
        lat: 40.8144344,
        lng: -73.8624893,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 428000000,
    },
  },
  {
    id: "XZ1xJMRssQH6GCknsIhY",
    name: "Nassau County",
    place_name: "Nassau County, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Nassau County",
      bbox: {
        south: 40.520505,
        west: -73.78019309999999,
        north: 40.9890091,
        east: -73.42327499999999,
      },
      center: {
        lat: 40.6546145,
        lng: -73.55941279999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 971000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "XZWVhPgnQAGJea7r8DMU",
    name: "Matinecock",
    place_name: "Matinecock, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Matinecock",
      bbox: {
        south: 40.8447929,
        west: -73.604889,
        north: 40.87877710000001,
        east: -73.5602361,
      },
      center: {
        lat: 40.8594516,
        lng: -73.5770801,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 755000000,
    },
  },
  {
    id: "Xcqxnt9arnPvve8BGVn6",
    name: "Midwood",
    place_name: "Midwood, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Midwood",
      bbox: {
        south: 40.6086071,
        west: -73.97640899999999,
        north: 40.6318593,
        east: -73.94411819999999,
      },
      center: {
        lat: 40.6204388,
        lng: -73.95997779999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "Xf3ZbB7ztlykKJnOrR32",
    name: "Union City",
    place_name: "Union City, NJ ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Union City",
      bbox: {
        south: 40.7506141,
        west: -74.0458598,
        north: 40.782873,
        east: -74.01479789999999,
      },
      center: {
        lat: 40.7795455,
        lng: -74.02375119999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 488000000,
    },
  },
  {
    id: "Xg54433YPVCE1IAOv7IJ",
    name: "College Point",
    county: "Queens County",
    created: {
      seconds: 1726205338,
      nanoseconds: 88000000,
    },
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    place_name: "College Point, Queens, NY",
    components: {
      text: "College Point",
      center: {
        lat: 40.786395,
        lng: -73.8389657,
      },
      types: ["neighborhood", "political"],
      bbox: {
        south: 40.76573399999999,
        north: 40.79763210000001,
        west: -73.8591727,
        east: -73.8234794,
      },
    },
  },
  {
    id: "Xv7UeQHGaxj5KaBh10PP",
    name: "Teaneck",
    place_name: "Teaneck, NJ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Teaneck",
      bbox: {
        south: 40.8598989,
        west: -74.0373879,
        north: 40.917079,
        east: -73.9873898,
      },
      center: {
        lat: 40.8932469,
        lng: -74.0116536,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 458000000,
    },
  },
  {
    id: "Xznwe29itc0uoyI80iwb",
    name: "Shore Acres",
    place_name: "Shore Acres, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Shore Acres",
      bbox: {
        south: 40.607329,
        west: -74.0633767,
        north: 40.6117604,
        east: -74.0571581,
      },
      center: {
        lat: 40.6100225,
        lng: -74.06066659999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208357,
      nanoseconds: 645000000,
    },
  },
  {
    id: "Y9MZ974k26lAC1SDuPFB",
    name: "Bay Shore",
    place_name: "Bay Shore, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Bay Shore",
      bbox: {
        south: 40.7022421,
        west: -73.2849991,
        north: 40.7520118,
        east: -73.2234659,
      },
      center: {
        lat: 40.7250986,
        lng: -73.2453946,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 558000000,
    },
  },
  {
    id: "YDoXc6vfMDc8aDacnvk9",
    name: "Marble Hill",
    place_name: "Marble Hill, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Marble Hill",
      bbox: {
        south: 40.871678,
        west: -73.915205,
        north: 40.8792779,
        east: -73.907005,
      },
      center: {
        lat: 40.8761173,
        lng: -73.9102628,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 898000000,
    },
  },
  {
    id: "YHGOOTyr6EMemMZSFz65",
    name: "Atlantic County",
    place_name: "Atlantic County, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Atlantic County",
      bbox: {
        south: 39.2885581,
        west: -74.985271,
        north: 39.7297749,
        east: -74.232062,
      },
      center: {
        lat: 39.5333379,
        lng: -74.6868815,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 9000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "YIvWl0LaH1hQmPXhyRVP",
    state_short: "NY",
    state: "New York",
    created: {
      seconds: 1726188299,
      nanoseconds: 905000000,
    },
    borough: "Manhattan",
    components: {
      center: {
        lng: -73.9655834,
        lat: 40.7825547,
      },
      types: ["establishment", "park", "point_of_interest", "tourist_attraction"],
      text: "Central Park",
      bbox: {
        west: -73.98348510000001,
        north: 40.8031268,
        south: 40.7641334,
        east: -73.94972659999999,
      },
    },
    place_name: "Central Park, New York, NY",
    county: "New York County",
    name: "Central Park",
  },
  {
    id: "YVQFLNeqtlpJuNzbHQyz",
    county: "Nassau County",
    components: {
      types: ["locality", "political"],
      bbox: {
        south: 40.75963489999999,
        north: 40.828406,
        west: -73.6690239,
        east: -73.608141,
      },
      text: "Roslyn",
      center: {
        lng: -73.6509622,
        lat: 40.7998227,
      },
    },
    borough: "Nassau County",
    place_name: "Roslyn, NY",
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1726208962,
      nanoseconds: 742000000,
    },
    name: "Roslyn",
  },
  {
    id: "Yb2k5OstnzCBHV07GUEJ",
    name: "Hawthorne",
    place_name: "Hawthorne, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hawthorne",
      bbox: {
        south: 40.9343298,
        west: -74.1715881,
        north: 40.9838651,
        east: -74.141048,
      },
      center: {
        lat: 40.9492649,
        lng: -74.1537549,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 572000000,
    },
  },
  {
    id: "Yjwyb8Z5xFeSoVubBVHe",
    name: "West Orange",
    place_name: "West Orange, NJ ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "West Orange",
      bbox: {
        south: 40.75739189999999,
        west: -74.3050489,
        north: 40.826557,
        east: -74.22089,
      },
      center: {
        lat: 40.7985699,
        lng: -74.23908279999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "YrORoetCsN6kdDuzadGM",
    name: "Blue Point",
    place_name: "Blue Point, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Blue Point",
      bbox: {
        south: 40.7294119,
        west: -73.04239849999999,
        north: 40.776142,
        east: -73.0217966,
      },
      center: {
        lat: 40.7439872,
        lng: -73.03455389999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "YrwDfb0JYSxAK7TIyopP",
    name: "Great Neck Plaza",
    place_name: "Great Neck Plaza, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Great Neck Plaza",
      bbox: {
        south: 40.780713,
        west: -73.734674,
        north: 40.79130809999999,
        east: -73.71550289999999,
      },
      center: {
        lat: 40.7867681,
        lng: -73.7265202,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 62000000,
    },
  },
  {
    id: "Z6za1ILU3uPUaLjO4u2z",
    name: "Neptune Township",
    place_name: "Neptune Township, NJ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Neptune Township",
      bbox: {
        south: 40.18250279999999,
        west: -74.09443379999999,
        north: 40.234395,
        east: -73.99848109999999,
      },
      center: {
        lat: 40.2091219,
        lng: -74.0386271,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 502000000,
    },
  },
  {
    id: "ZAkMa9VVfoVi3Lzg8uVM",
    name: "Hewlett",
    place_name: "Hewlett, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Hewlett",
      bbox: {
        south: 40.631726,
        west: -73.7106191,
        north: 40.6508139,
        east: -73.677555,
      },
      center: {
        lat: 40.6431591,
        lng: -73.6956864,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 818000000,
    },
  },
  {
    id: "ZDg4icnavhAaLn6vUirr",
    name: "Passaic County",
    place_name: "Passaic County, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Passaic County",
      bbox: {
        south: 40.8199381,
        west: -74.50321199999999,
        north: 41.2037883,
        east: -74.106786,
      },
      center: {
        lat: 41.1231647,
        lng: -74.35874729999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 33000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "ZGtOXde7g9IyrIu4ebTU",
    name: "Clinton",
    place_name: "Clinton, NJ ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Clinton",
      bbox: {
        south: 40.62457089999999,
        west: -74.929054,
        north: 40.6449439,
        east: -74.8994629,
      },
      center: {
        lat: 40.6367682,
        lng: -74.90988999999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 505000000,
    },
  },
  {
    id: "ZN9wYJvLdmjNwuJqjzD8",
    name: "Robbinsville Township",
    place_name: "Robbinsville Twp, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Robbinsville Township",
      bbox: {
        south: 40.179679,
        west: -74.64963589999999,
        north: 40.2661173,
        east: -74.53645689999999,
      },
      center: {
        lat: 40.2162036,
        lng: -74.6195327,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 533000000,
    },
  },
  {
    id: "ZOSo564jFP1l1Tgu6ev0",
    name: "Historic Downtown",
    place_name: "Historic Downtown, Jersey City, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    borough: "Suffolk County",
    components: {
      text: "Historic Downtown",
      bbox: {
        south: 40.712102,
        west: -74.0620938,
        north: 40.736101,
        east: -74.040885,
      },
      center: {
        lat: 40.7219658,
        lng: -74.0512734,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726212600,
      nanoseconds: 778000000,
    },
  },
  {
    id: "ZX0uTnNbzLibuv2rzydd",
    name: "Syosset",
    place_name: "Syosset, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Syosset",
      bbox: {
        south: 40.7861629,
        west: -73.5280131,
        north: 40.8433731,
        east: -73.4797719,
      },
      center: {
        lat: 40.8262101,
        lng: -73.502068,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 820000000,
    },
  },
  {
    id: "ZY9imf2CXXaB72WfGVcx",
    name: "SoHo",
    place_name: "SoHo, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "SoHo",
      bbox: {
        south: 40.7189454,
        west: -74.0054619,
        north: 40.7283442,
        east: -73.9958967,
      },
      center: {
        lat: 40.7245908,
        lng: -74.00191740000001,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 980000000,
    },
  },
  {
    id: "aN5E9H1poqgigTHWWCDy",
    name: "Inwood",
    place_name: "Inwood, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Inwood",
      bbox: {
        south: 40.609984,
        west: -73.7670231,
        north: 40.633387,
        east: -73.73152999999999,
      },
      center: {
        lat: 40.6220487,
        lng: -73.74679890000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 787000000,
    },
  },
  {
    id: "aTI6BywZY5G7YZA0vL9d",
    name: "Harbor Isle",
    place_name: "Harbor Isle, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Harbor Isle",
      bbox: {
        south: 40.596086,
        west: -73.669409,
        north: 40.6072409,
        east: -73.66089190000001,
      },
      center: {
        lat: 40.60343719999999,
        lng: -73.6645748,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 758000000,
    },
  },
  {
    id: "aU8i5vvOYNwatgphNZXY",
    state_short: "NY",
    place_name: "New Hyde Park, NY",
    borough: "Nassau County",
    county: "Nassau County",
    name: "New Hyde Park",
    components: {
      bbox: {
        north: 40.7532691,
        east: -73.65187990000001,
        west: -73.69721,
        south: 40.7233181,
      },
      text: "New Hyde Park",
      center: {
        lat: 40.7351018,
        lng: -73.6879082,
      },
      types: ["locality", "political"],
    },
    state: "New York",
    created: {
      seconds: 1726208962,
      nanoseconds: 745000000,
    },
  },
  {
    id: "aZvVOhfgVQvDrXPoUaA8",
    name: "Bath Beach",
    place_name: "Bath Beach, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Bath Beach",
      bbox: {
        south: 40.594391,
        west: -74.02004710000001,
        north: 40.612493,
        east: -73.9938083,
      },
      center: {
        lat: 40.6038852,
        lng: -74.0062078,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 658000000,
    },
  },
  {
    id: "aazYtE4FBxNEVoNfHvJt",
    name: "Port Jefferson",
    place_name: "Port Jefferson, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Port Jefferson",
      bbox: {
        south: 40.9330519,
        west: -73.0840359,
        north: 40.9665369,
        east: -73.0377108,
      },
      center: {
        lat: 40.9464875,
        lng: -73.0692732,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 472000000,
    },
  },
  {
    id: "apE3iYBbgspejTHCvgO9",
    name: "Strathmore",
    place_name: "Strathmore, Manhasset, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Strathmore",
      bbox: {
        south: 40.79069631970851,
        west: -73.67870098029151,
        north: 40.79339428029151,
        east: -73.67600301970849,
      },
      center: {
        lat: 40.79204530000001,
        lng: -73.677352,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 760000000,
    },
  },
  {
    id: "b3eGTqaZUO58MKebU7Dk",
    name: "Todt Hill",
    place_name: "Todt Hill, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Todt Hill",
      bbox: {
        south: 40.5800168,
        west: -74.11487509999999,
        north: 40.6103779,
        east: -74.0859766,
      },
      center: {
        lat: 40.5975007,
        lng: -74.10189749999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 178000000,
    },
  },
  {
    id: "bPs38PKkhrK15OhjrZop",
    name: "Jamaica Estates",
    place_name: "Jamaica Estates, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Jamaica Estates",
      bbox: {
        south: 40.7107253,
        west: -73.79366,
        north: 40.7291933,
        east: -73.77354679999999,
      },
      center: {
        lat: 40.7179512,
        lng: -73.783822,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 858000000,
    },
  },
  {
    id: "bQvKOFHep0fOtPCnQ4cV",
    name: "Fair Harbor",
    place_name: "Fair Harbor, Fire Island, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Fair Harbor",
      bbox: {
        south: 40.637071,
        west: -73.188907,
        north: 40.6418967,
        east: -73.1802865,
      },
      center: {
        lat: 40.6391777,
        lng: -73.1846928,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 472000000,
    },
  },
  {
    id: "bYNwCAA4Tug9wI5HdD32",
    name: "Lindenhurst",
    place_name: "Lindenhurst, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Lindenhurst",
      bbox: {
        south: 40.6644999,
        west: -73.39520809999999,
        north: 40.708298,
        east: -73.3515,
      },
      center: {
        lat: 40.68676670000001,
        lng: -73.3734547,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 547000000,
    },
  },
  {
    id: "bgNGkaNUBelM65IlB1xh",
    name: "Rego Park",
    place_name: "Rego Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Rego Park",
      bbox: {
        south: 40.7108153,
        west: -73.8749546,
        north: 40.736372,
        east: -73.8517299,
      },
      center: [-73.8624893, 40.72557219999999],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 288000000,
    },
  },
  {
    id: "bil3CypJFmw8Y9Fneffr",
    name: "Clifton",
    place_name: "Clifton, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Clifton",
      bbox: {
        south: 40.8199619,
        west: -74.197734,
        north: 40.899398,
        east: -74.1178039,
      },
      center: {
        lat: 40.8584328,
        lng: -74.16375529999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 572000000,
    },
  },
  {
    id: "bkfNYBdNccbDKY93KD51",
    state_short: "NY",
    name: "Douglaston",
    state: "New York",
    county: "Queens County",
    created: {
      seconds: 1726205338,
      nanoseconds: 77000000,
    },
    borough: "Queens",
    components: {
      bbox: {
        south: 40.74389559999999,
        east: -73.71386269999999,
        north: 40.78285289999999,
        west: -73.7571859,
      },
      center: {
        lat: 40.76401509999999,
        lng: -73.7433727,
      },
      text: "Douglaston",
      types: ["neighborhood", "political"],
    },
    place_name: "Douglaston, Queens, NY",
  },
  {
    id: "bsc1CNx6e496mKY4juOw",
    name: "South Richmond Hill",
    place_name: "South Richmond Hill, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "South Richmond Hill",
      bbox: {
        south: 40.6812099,
        west: -73.8454095,
        north: 40.6973501,
        east: -73.8107438,
      },
      center: {
        lat: 40.6918929,
        lng: -73.8257325,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 865000000,
    },
  },
  {
    id: "bx9dnqTOU3nH48N4shCE",
    name: "East Massapequa",
    place_name: "East Massapequa, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Massapequa",
      bbox: {
        south: 40.650186,
        west: -73.454047,
        north: 40.7067062,
        east: -73.4232531,
      },
      center: {
        lat: 40.6687326,
        lng: -73.43512,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 987000000,
    },
  },
  {
    id: "by3CsM6J5OgTgYZbmU0F",
    components: {
      center: {
        lng: -73.4742914,
        lat: 40.6806563,
      },
      types: ["locality", "political"],
      text: "Massapequa",
      bbox: {
        south: 40.646001,
        east: -73.4232531,
        north: 40.71827589999999,
        west: -73.487036,
      },
    },
    state: "New York",
    borough: "Nassau County",
    county: "Nassau County",
    created: {
      seconds: 1726208962,
      nanoseconds: 743000000,
    },
    place_name: "Massapequa, NY",
    name: "Massapequa",
    state_short: "NY",
  },
  {
    id: "byYlJdg2CBRl9qwzQSki",
    name: "Tremont",
    place_name: "Tremont, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Tremont",
      bbox: {
        south: 40.8403803,
        west: -73.91110499999999,
        north: 40.85458240000001,
        east: -73.88378759999999,
      },
      center: {
        lat: 40.8477113,
        lng: -73.90096729999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 479000000,
    },
  },
  {
    id: "c05dvRoJ9ou94e7zJ740",
    name: "Stony Brook",
    place_name: "Stony Brook, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Stony Brook",
      bbox: {
        south: 40.8691492,
        west: -73.1535262,
        north: 40.9481107,
        east: -73.0964919,
      },
      center: {
        lat: 40.9256538,
        lng: -73.1409429,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 559000000,
    },
  },
  {
    id: "c41xUlk6lbtU8xVQcLWq",
    name: "Central Islip",
    place_name: "Central Islip, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Central Islip",
      bbox: {
        south: 40.7573157,
        west: -73.23124589999999,
        north: 40.8057608,
        east: -73.1542855,
      },
      center: {
        lat: 40.7906538,
        lng: -73.20178109999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 468000000,
    },
  },
  {
    id: "c8A3v9HO61vIDvQLFQ5C",
    name: "Queens",
    place_name: "Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Queens",
      bbox: {
        south: 40.4897939,
        west: -74.0421119,
        north: 40.8122421,
        east: -73.70027209999999,
      },
      center: {
        lat: 40.7282239,
        lng: -73.7948516,
      },
      types: ["political", "sublocality", "sublocality_level_1"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 984000000,
    },
    type: "borough",
    types: ["political", "sublocality", "sublocality_level_1"],
  },
  {
    id: "cHZiLP2q22p1Nln6uRjy",
    name: "Laurel Hollow",
    place_name: "Laurel Hollow, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Laurel Hollow",
      bbox: {
        south: 40.836927,
        west: -73.4944249,
        north: 40.8799979,
        east: -73.45526079999999,
      },
      center: {
        lat: 40.8567653,
        lng: -73.4695665,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 755000000,
    },
  },
  {
    id: "cIXezpZCptkXCcQKSpOK",
    name: "Manhattan",
    place_name: "Manhattan, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    components: {
      text: "Manhattan",
      bbox: {
        south: 40.698078,
        west: -74.03514899999999,
        north: 40.820045,
        east: -73.90331300000001,
      },
      center: {
        lat: 40.7685167,
        lng: -73.98219379999999,
      },
      types: ["political", "sublocality", "sublocality_level_1"],
    },
    created: {
      seconds: 1726186394,
      nanoseconds: 985000000,
    },
    type: "borough",
    types: ["political", "sublocality", "sublocality_level_1"],
  },
  {
    id: "cNnyWj10lcIo1JeivoZQ",
    name: "Grymes Hill",
    place_name: "Grymes Hill, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Grymes Hill",
      bbox: {
        south: 40.6173661197085,
        west: -74.0948240802915,
        north: 40.6200640802915,
        east: -74.0921261197085,
      },
      center: {
        lat: 40.6187151,
        lng: -74.09347509999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "cTO28pVjgkkSEkGL00en",
    name: "Rosedale",
    place_name: "Rosedale, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Rosedale",
      bbox: {
        south: 40.6348399,
        west: -73.7462031,
        north: 40.68221,
        east: -73.725055,
      },
      center: [-73.7330753, 40.6575584],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 226000000,
    },
  },
  {
    id: "caZXBcf4fWULEsCoYqip",
    name: "Crown Heights",
    place_name: "Crown Heights, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Crown Heights",
      bbox: {
        south: 40.6632994,
        west: -73.9643928,
        north: 40.681042,
        east: -73.9192388,
      },
      center: {
        lat: 40.6694022,
        lng: -73.9422324,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "ceDDhBqbzEZwvLL3OLbR",
    name: "Neponsit",
    place_name: "Neponsit, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Neponsit",
      bbox: {
        south: 40.5666656,
        west: -73.8663411,
        north: 40.5771459,
        east: -73.8563848,
      },
      center: {
        lat: 40.5707646,
        lng: -73.8610191,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 886000000,
    },
  },
  {
    id: "cwnMKYBlsqmTf6uLJDhG",
    name: "Cherry Grove",
    place_name: "Cherry Grove, Fire Island, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Cherry Grove",
      bbox: {
        south: 40.6577771,
        west: -73.0940911,
        north: 40.6622888,
        east: -73.0827279,
      },
      center: {
        lat: 40.6597917,
        lng: -73.0899622,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 597000000,
    },
  },
  {
    id: "cz8VRzZhXQwIv7rRMmvc",
    name: "Farmingville",
    place_name: "Farmingville, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Farmingville",
      bbox: {
        south: 40.82136,
        west: -73.078693,
        north: 40.859974,
        east: -73.0146961,
      },
      center: {
        lat: 40.8312096,
        lng: -73.029552,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 562000000,
    },
  },
  {
    id: "d6UgODx8gkY0iMev9bMP",
    state: "New York",
    place_name: "Fresh Meadows, Queens, NY",
    created: {
      seconds: 1726205338,
      nanoseconds: 21000000,
    },
    borough: "Queens",
    name: "Fresh Meadows",
    state_short: "NY",
    county: "Queens County",
    components: {
      types: ["neighborhood", "political"],
      bbox: {
        south: 40.7232788,
        north: 40.7433307,
        east: -73.77013260000001,
        west: -73.7938815,
      },
      center: {
        lat: 40.7335179,
        lng: -73.7801447,
      },
      text: "Fresh Meadows",
    },
  },
  {
    id: "d7XEHxuj2aPYlfPsCsAo",
    name: "Claremont",
    place_name: "Claremont, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Claremont",
      bbox: {
        south: 40.832279,
        west: -73.91580619999999,
        north: 40.84517900000001,
        east: -73.8963842,
      },
      center: {
        lat: 40.840047,
        lng: -73.90364869999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 467000000,
    },
  },
  {
    id: "d8JuOrKo3uQJiyaAhIUB",
    name: "Midtown East",
    state: "New York",
    created: {
      seconds: 1726203137,
      nanoseconds: 883000000,
    },
    county: "New York County",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      center: {
        lat: 40.7571432,
        lng: -73.9718815,
      },
      bbox: {
        west: -73.98879459999999,
        east: -73.9584941,
        south: 40.7313276,
        north: 40.7641613,
      },
      text: "Midtown East",
    },
    borough: "Manhattan",
    place_name: "Midtown East, New York, NY",
  },
  {
    id: "dCaH3a5Zmi0lRL2PN5dr",
    name: "Upper Deerfield Township",
    place_name: "Upper Deerfield Township, NJ",
    county: "Cumberland County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Upper Deerfield Township",
      bbox: {
        south: 39.4317141,
        west: -75.2707269,
        north: 39.55918800000001,
        east: -75.16322,
      },
      center: {
        lat: 39.5031256,
        lng: -75.2245693,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 540000000,
    },
  },
  {
    id: "dDYoRlJBsgYIP5Z2AwQP",
    name: "Morris County",
    place_name: "Morris County, NJ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Morris County",
      bbox: {
        south: 40.6484469,
        west: -74.8898189,
        north: 41.08611399999999,
        east: -74.2665911,
      },
      center: {
        lat: 40.8336038,
        lng: -74.54632819999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 24000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "dIwkuBEtWkZ59ctp7DXX",
    place_name: "Flushing, Queens, NY",
    name: "Flushing",
    created: {
      seconds: 1726205338,
      nanoseconds: 154000000,
    },
    state_short: "NY",
    borough: "Queens",
    state: "New York",
    county: "Queens County",
    components: {
      bbox: {
        east: -73.771669,
        west: -73.8391686,
        north: 40.7767411,
        south: 40.7156393,
      },
      text: "Flushing",
      center: {
        lng: -73.8306716,
        lat: 40.7647238,
      },
      types: ["neighborhood", "political"],
    },
  },
  {
    id: "dJnms2VVl4NTlrDue2it",
    name: "Kearny",
    place_name: "Kearny, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Kearny",
      bbox: {
        south: 40.710381,
        west: -74.1647991,
        north: 40.786491,
        east: -74.0752019,
      },
      center: {
        lat: 40.7684342,
        lng: -74.1454214,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 583000000,
    },
  },
  {
    id: "dOAwub5P8y514OsRCOCi",
    name: "Highbridge",
    place_name: "Highbridge, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Highbridge",
      bbox: {
        south: 40.82827899999999,
        west: -73.9334059,
        north: 40.845579,
        east: -73.9212058,
      },
      center: {
        lat: 40.836916,
        lng: -73.9271294,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 438000000,
    },
  },
  {
    id: "dQwoGBSBDaZxa8gH6Bny",
    name: "Russell Gardens",
    place_name: "Russell Gardens, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Russell Gardens",
      bbox: {
        south: 40.77668,
        west: -73.7307151,
        north: 40.7846649,
        east: -73.7190509,
      },
      center: {
        lat: 40.7812125,
        lng: -73.7245758,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 829000000,
    },
  },
  {
    id: "dXIm7Nj6poGck4tuTzc7",
    name: "Manhattanville",
    place_name: "Manhattanville, New York, NY ",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Manhattanville",
      bbox: {
        south: 40.81559531970849,
        west: -73.9571822802915,
        north: 40.81829328029149,
        east: -73.9544843197085,
      },
      center: [-73.9558333, 40.8169443],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 879000000,
    },
  },
  {
    id: "davzeKUaXfpOGxG6VYUf",
    name: "Brighton Beach",
    place_name: "Brighton Beach, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Brighton Beach",
      bbox: {
        south: 40.5731169,
        west: -73.96859239999999,
        north: 40.584843,
        east: -73.953411,
      },
      center: {
        lat: 40.5780706,
        lng: -73.9596565,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "dbV2GSBTppswTdQ9ZE1u",
    name: "Kings Park",
    place_name: "Kings Park, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Kings Park",
      bbox: {
        south: 40.8663929,
        west: -73.2795889,
        north: 40.909907,
        east: -73.2087251,
      },
      center: {
        lat: 40.88620900000001,
        lng: -73.2573369,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 547000000,
    },
  },
  {
    id: "dkfB1pA3g3IOLOIjRDSp",
    name: "Bayville",
    place_name: "Bayville, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Bayville",
      bbox: {
        south: 40.8990911,
        west: -73.5832339,
        north: 40.915782,
        east: -73.52803500000002,
      },
      center: {
        lat: 40.9106541,
        lng: -73.56206890000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 61000000,
    },
  },
  {
    id: "dl3xDzHGG1JwenuTLOVf",
    borough: "Manhattan",
    county: "New York County",
    state_short: "NY",
    components: {
      types: ["neighborhood", "political"],
      text: "Upper East Side",
      bbox: {
        west: -75.288061,
        east: -73.94221689999999,
        north: 43.120241,
        south: 40.7583091,
      },
      center: {
        lng: -73.9565551,
        lat: 40.7735649,
      },
    },
    place_name: "Upper East Side, New York, NY",
    name: "Upper East Side",
    created: {
      seconds: 1726188299,
      nanoseconds: 922000000,
    },
    state: "New York",
  },
  {
    id: "dv0l0SvwFboQMqQUZjgh",
    name: "North New Hyde Park",
    place_name: "North New Hyde Park, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North New Hyde Park",
      bbox: {
        south: 40.7303238,
        west: -73.71244899999999,
        north: 40.759242,
        east: -73.67095909999999,
      },
      center: {
        lat: 40.7431574,
        lng: -73.69318609999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 762000000,
    },
  },
  {
    id: "e72gDp8XDoIA8ufqhnYW",
    name: "Pompton Lakes",
    place_name: "Pompton Lakes, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Pompton Lakes",
      bbox: {
        south: 40.9788,
        west: -74.302993,
        north: 41.025173,
        east: -74.266713,
      },
      center: {
        lat: 41.0053753,
        lng: -74.2907041,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 508000000,
    },
  },
  {
    id: "eBlEDeR6vo6peLTpMOUz",
    name: "NoHo",
    place_name: "NoHo, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "NoHo",
      bbox: {
        south: 40.7241366,
        west: -73.9967609,
        north: 40.7300598,
        east: -73.9907527,
      },
      center: {
        lat: 40.7286587,
        lng: -73.9925526,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 916000000,
    },
  },
  {
    id: "eCZSUV2qMe0EhT69nGJC",
    name: "Trenton",
    place_name: "Trenton, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Trenton",
      bbox: {
        south: 40.183809,
        west: -74.819507,
        north: 40.248298,
        east: -74.728904,
      },
      center: {
        lat: 40.2205824,
        lng: -74.759717,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 485000000,
    },
  },
  {
    id: "eK8jGPc71AKLqWLdpjYW",
    name: "Asbury Park",
    place_name: "Asbury Park, NJ ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Asbury Park",
      bbox: {
        south: 40.21312500000001,
        west: -74.0272561,
        north: 40.2312219,
        east: -73.99468089999999,
      },
      center: {
        lat: 40.2203907,
        lng: -74.0120817,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 580000000,
    },
  },
  {
    id: "eMZcHcmIXkbK069PFJJr",
    name: "North Bellmore",
    place_name: "North Bellmore, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Bellmore",
      bbox: {
        south: 40.6741369,
        west: -73.5592219,
        north: 40.706379,
        east: -73.52109879999999,
      },
      center: {
        lat: 40.69148999999999,
        lng: -73.5334597,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 989000000,
    },
  },
  {
    id: "eNxLxX4wUKXP4tEU6Fod",
    name: "South Farmingdale",
    place_name: "South Farmingdale, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "South Farmingdale",
      bbox: {
        south: 40.7045199,
        west: -73.46851079999999,
        north: 40.7305039,
        east: -73.4299561,
      },
      center: {
        lat: 40.7206555,
        lng: -73.4401232,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "eOoJKePX6nx86CY15b4T",
    name: "Lindenwood",
    place_name: "Lindenwood, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Lindenwood",
      bbox: {
        south: 40.6572859,
        west: -73.8611605,
        north: 40.6740867,
        east: -73.8415564,
      },
      center: [-73.8498968, 40.6646149],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 289000000,
    },
  },
  {
    id: "eXho6nb3TDyB7uG6oYxI",
    name: "East Patchogue",
    place_name: "East Patchogue, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "East Patchogue",
      bbox: {
        south: 40.742385,
        west: -73.0099286,
        north: 40.7972914,
        east: -72.9508589,
      },
      center: {
        lat: 40.7670427,
        lng: -72.9962189,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "eY2pOevAxV0KFZTMaEZp",
    name: "Albertson",
    place_name: "Albertson, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Albertson",
      bbox: {
        south: 40.76283859999999,
        west: -73.6574157,
        north: 40.781797,
        east: -73.640759,
      },
      center: {
        lat: 40.7734341,
        lng: -73.6431844,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 828000000,
    },
  },
  {
    id: "eoUBKI75n4s5uI4H1xik",
    name: "Bensonhurst",
    place_name: "Bensonhurst, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Bensonhurst",
      bbox: {
        south: 40.601786,
        west: -74.01178449999999,
        north: 40.6275358,
        east: -73.97297309999999,
      },
      center: {
        lat: 40.6139307,
        lng: -73.9921833,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 629000000,
    },
  },
  {
    id: "erSkvE4oa0a5Wuuqs0zb",
    name: "Grasmere",
    place_name: "Grasmere, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Grasmere",
      bbox: {
        south: 40.60280931970851,
        west: -74.08511278029151,
        north: 40.6055072802915,
        east: -74.08241481970849,
      },
      center: {
        lat: 40.6041583,
        lng: -74.0837638,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 175000000,
    },
  },
  {
    id: "eyqMnzPjcspXxwdwOGPF",
    name: "Baldwin",
    place_name: "Baldwin, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Baldwin",
      bbox: {
        south: 40.6381839,
        west: -73.623694,
        north: 40.6874508,
        east: -73.5968359,
      },
      center: {
        lat: 40.6686336,
        lng: -73.6119129,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 836000000,
    },
  },
  {
    id: "f3XpiJMmywnc7eiBl92d",
    name: "Thomaston",
    place_name: "Thomaston, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Thomaston",
      bbox: {
        south: 40.7818981,
        west: -73.726742,
        north: 40.793048,
        east: -73.706391,
      },
      center: {
        lat: 40.7862123,
        lng: -73.71374209999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 760000000,
    },
  },
  {
    id: "f8PUD6ePGfcrlRb771Cg",
    name: "Sunset Park",
    place_name: "Sunset Park, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Sunset Park",
      bbox: {
        south: 40.6311838,
        west: -74.03174709999999,
        north: 40.671745,
        east: -73.9830884,
      },
      center: {
        lat: 40.6526345,
        lng: -74.0091872,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 635000000,
    },
  },
  {
    id: "fAvKfdRNGzYk16bW0gst",
    name: "Mott Haven",
    place_name: "Mott Haven, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Mott Haven",
      bbox: {
        south: 40.7990266,
        west: -73.9325985,
        north: 40.8194526,
        east: -73.9029856,
      },
      center: {
        lat: 40.8091068,
        lng: -73.9228881,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 551000000,
    },
  },
  {
    id: "fKi3qj4KvO3SxeLL9ygj",
    county: "Kings County",
    borough: "Brooklyn",
    state: "New York",
    state_short: "NY",
    name: "Kensington",
    components: {
      types: ["neighborhood", "political"],
      center: {
        lat: 40.63852019999999,
        lng: -73.97318729999999,
      },
      bbox: {
        west: -73.9883334,
        east: -73.95949399999999,
        south: 40.5736634,
        north: 40.6553803,
      },
      text: "Kensington",
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 657000000,
    },
    place_name: "Kensington, Brooklyn, NY",
  },
  {
    id: "fQHa5PHkt8Tw9CCYE3d1",
    name: "Uniondale",
    place_name: "Uniondale, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Uniondale",
      bbox: {
        south: 40.684017,
        west: -73.6171008,
        north: 40.7475659,
        east: -73.574837,
      },
      center: {
        lat: 40.700611,
        lng: -73.5931669,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 970000000,
    },
  },
  {
    id: "fbJ9hlsWs8Y9rjSGvhDx",
    name: "Woodstown",
    place_name: "Woodstown, NJ ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Woodstown",
      bbox: {
        south: 39.637336,
        west: -75.3362278,
        north: 39.6637621,
        east: -75.3146439,
      },
      center: {
        lat: 39.65150269999999,
        lng: -75.32824540000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 573000000,
    },
  },
  {
    id: "fh3ZGQAwMlzcl7TeuHLF",
    name: "Richmond Hill",
    place_name: "Richmond Hill, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Richmond Hill",
      bbox: {
        south: 40.6894584,
        west: -73.8491504,
        north: 40.70963589999999,
        east: -73.81396289999999,
      },
      center: {
        lat: 40.6999853,
        lng: -73.8310309,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 866000000,
    },
  },
  {
    id: "fwXQ2vjOhDKZbl9G8t4Y",
    name: "Holtsville",
    place_name: "Holtsville, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Holtsville",
      bbox: {
        south: 40.784886,
        west: -73.081805,
        north: 40.8363679,
        east: -73.00993489999999,
      },
      center: {
        lat: 40.8153762,
        lng: -73.0451085,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 553000000,
    },
  },
  {
    id: "g0laWGcJx9khlViKW8iH",
    name: "Hauppauge",
    place_name: "Hauppauge, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Hauppauge",
      bbox: {
        south: 40.801677,
        west: -73.2723019,
        north: 40.8504846,
        east: -73.1477819,
      },
      center: {
        lat: 40.8256536,
        lng: -73.2026138,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 558000000,
    },
  },
  {
    id: "g1Ol6kWDI4e43bN2pfPX",
    name: "Setauket- East Setauket",
    place_name: "Setauket- East Setauket, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Setauket- East Setauket",
      bbox: {
        south: 40.88612,
        west: -73.130214,
        north: 40.971411,
        east: -73.06494400000001,
      },
      center: {
        lat: 40.9293759,
        lng: -73.1025502,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 510000000,
    },
  },
  {
    id: "gdPNhVogbxnx311zz5h3",
    name: "Point Pleasant",
    place_name: "Point Pleasant, NJ ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Point Pleasant",
      bbox: {
        south: 40.0590148,
        west: -74.0916609,
        north: 40.097611,
        east: -74.047758,
      },
      center: {
        lat: 40.0831714,
        lng: -74.0681931,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 499000000,
    },
  },
  {
    id: "geQv2fYyxFZ2rVaiI1yF",
    name: "Pennsville Township",
    place_name: "Pennsville Township, NJ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Pennsville Township",
      bbox: {
        south: 39.56933,
        west: -75.5597921,
        north: 39.698813,
        east: -75.4633269,
      },
      center: {
        lat: 39.6552687,
        lng: -75.5206297,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 504000000,
    },
  },
  {
    id: "gepTJkuueL18XzGatJmi",
    name: "Auburndale",
    place_name: "Auburndale, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Auburndale",
      bbox: {
        south: 40.738024,
        west: -73.7972691,
        north: 40.7760443,
        east: -73.771669,
      },
      center: {
        lat: 40.7577672,
        lng: -73.78339609999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 858000000,
    },
  },
  {
    id: "gfG1SkYaZSatXAFmnD3r",
    name: "Lake Grove",
    place_name: "Lake Grove, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Lake Grove",
      bbox: {
        south: 40.83522689999999,
        west: -73.135058,
        north: 40.883124,
        east: -73.10148199999999,
      },
      center: {
        lat: 40.8528761,
        lng: -73.1151102,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 541000000,
    },
  },
  {
    id: "ggkKcIitPaHSekrlSY7S",
    place_name: "Allerton, Bronx, NY",
    borough: "Bronx",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    created: {
      seconds: 1726207593,
      nanoseconds: 483000000,
    },
    name: "Allerton",
    components: {
      text: "Allerton",
      center: {
        lat: 40.8631189,
        lng: -73.8616476,
      },
      types: ["neighborhood", "political"],
      bbox: {
        west: -73.8710054,
        south: 40.8559288,
        north: 40.8957399,
        east: -73.84996129999999,
      },
    },
  },
  {
    id: "ggtHkDIMRWzLZAKT8Vl0",
    name: "Long Beach",
    place_name: "Long Beach, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Long Beach",
      bbox: {
        south: 40.5746319,
        west: -73.7013991,
        north: 40.5958391,
        east: -73.6357019,
      },
      center: {
        lat: 40.5884373,
        lng: -73.65790799999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 706000000,
    },
  },
  {
    id: "gm9XHRSsxmyeOAUpb01X",
    name: "Fort Hamilton",
    place_name: "Fort Hamilton, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Fort Hamilton",
      bbox: {
        south: 40.6043089,
        west: -74.0421473,
        north: 40.6259521,
        east: -74.0157053,
      },
      center: {
        lat: 40.6094454,
        lng: -74.02854339999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 685000000,
    },
  },
  {
    id: "gqAYo0TdhUlnD4efzXGj",
    name: "Ocean City",
    place_name: "Ocean City, NJ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Ocean City",
      bbox: {
        south: 39.210927,
        west: -74.6549139,
        north: 39.310097,
        east: -74.5511509,
      },
      center: {
        lat: 39.2776156,
        lng: -74.5746001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 541000000,
    },
  },
  {
    id: "gu5hlxPrO5mdxxjkN0on",
    name: "Jackson Heights",
    place_name: "Jackson Heights, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Jackson Heights",
      bbox: {
        south: 40.7465327,
        west: -73.8997646,
        north: 40.7660969,
        east: -73.8694026,
      },
      center: {
        lat: 40.7556818,
        lng: -73.8830701,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 18000000,
    },
  },
  {
    id: "h7hwmvQGcrlYXTnOemE1",
    name: "Newark",
    place_name: "Newark, NJ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Newark",
      bbox: {
        south: 40.6739029,
        west: -74.2513239,
        north: 40.788664,
        east: -74.1127869,
      },
      center: {
        lat: 40.735657,
        lng: -74.1723667,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 482000000,
    },
  },
  {
    id: "hB0FlJh9ZMAydIvWGgES",
    name: "Salem",
    place_name: "Salem, NJ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Salem",
      bbox: {
        south: 39.5548741,
        west: -75.49496909999999,
        north: 39.584022,
        east: -75.452387,
      },
      center: {
        lat: 39.5717796,
        lng: -75.46714229999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 605000000,
    },
  },
  {
    id: "hCmRHiNwSSD3OGpdLTFz",
    name: "Oakdale",
    place_name: "Oakdale, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Oakdale",
      bbox: {
        south: 40.7233009,
        west: -73.1588017,
        north: 40.7549708,
        east: -73.1119579,
      },
      center: {
        lat: 40.7439872,
        lng: -73.13872420000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 561000000,
    },
  },
  {
    id: "hZdUxPGFTkdvxlpyc4w5",
    name: "Deer Park",
    place_name: "Deer Park, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Deer Park",
      bbox: {
        south: 40.74061810000001,
        west: -73.3543547,
        north: 40.7842969,
        east: -73.2949661,
      },
      center: {
        lat: 40.7617653,
        lng: -73.3292857,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 479000000,
    },
  },
  {
    id: "hhPnLZUHR0udrDymFrB0",
    name: "Woodrow",
    place_name: "Woodrow, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Woodrow",
      bbox: {
        south: 40.528459,
        west: -74.21840209999999,
        north: 40.5554959,
        east: -74.1747651,
      },
      center: {
        lat: 40.5394173,
        lng: -74.19151769999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 179000000,
    },
  },
  {
    id: "hjZtGtFBGNBZbUvhAID1",
    name: "Elmer",
    place_name: "Elmer, NJ ",
    county: "Salem County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Elmer",
      bbox: {
        south: 39.5837511,
        west: -75.1844211,
        north: 39.6021631,
        east: -75.164665,
      },
      center: {
        lat: 39.5951147,
        lng: -75.1701819,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 572000000,
    },
  },
  {
    id: "hlg4rLHfGJdmewaK26GS",
    name: "Clinton Hill",
    place_name: "Clinton Hill, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Clinton Hill",
      bbox: {
        south: 40.679558,
        west: -73.971446,
        north: 40.6979329,
        east: -73.95785099999999,
      },
      center: {
        lat: 40.6896834,
        lng: -73.9661144,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 742000000,
    },
  },
  {
    id: "hqz882fnRH5Ea04USB6k",
    name: "Bayport",
    place_name: "Bayport, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Bayport",
      bbox: {
        south: 40.722516,
        west: -73.0753479,
        north: 40.7738033,
        east: -73.037876,
      },
      center: {
        lat: 40.7384317,
        lng: -73.0506656,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 507000000,
    },
  },
  {
    id: "hsxGshER16ek6VYjmwI1",
    name: "Emerson Hill",
    place_name: "Emerson Hill, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Emerson Hill",
      bbox: {
        south: 40.6061035197085,
        west: -74.09766048029151,
        north: 40.6088014802915,
        east: -74.0949625197085,
      },
      center: {
        lat: 40.6074525,
        lng: -74.0963115,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 169000000,
    },
  },
  {
    id: "hsyJuNu6LTBoo0GIVuA0",
    county: "Richmond County",
    place_name: "Richmond, Staten Island, NY ",
    state_short: "NY",
    created: {
      seconds: 1726208256,
      nanoseconds: 133000000,
    },
    name: "Richmond",
    state: "New York",
    borough: "Staten Island",
    components: {
      text: "Richmond",
      types: ["neighborhood", "political"],
      bbox: {
        north: 40.6117604,
        west: -74.1522323,
        east: -74.0571581,
        south: 40.5575398,
      },
      center: {
        lat: 40.56675449999999,
        lng: -74.1360528,
      },
    },
  },
  {
    id: "htA8ETXzWNsuFkvA2p1s",
    name: "Harbor Hills",
    place_name: "Harbor Hills, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Harbor Hills",
      bbox: {
        south: 40.781898,
        west: -73.756187,
        north: 40.7930529,
        east: -73.7430629,
      },
      center: {
        lat: 40.7909348,
        lng: -73.74652080000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "huCXX1x6gsXDn2Ft0GcX",
    name: "Burlington County",
    place_name: "Burlington County, NJ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Burlington County",
      bbox: {
        south: 39.541198,
        west: -75.059913,
        north: 40.183651,
        east: -74.389708,
      },
      center: {
        lat: 39.8558488,
        lng: -74.6868815,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 20000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "hy2q9VrX1f2T46m2aTSW",
    name: "Guttenberg",
    place_name: "Guttenberg, NJ ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Guttenberg",
      bbox: {
        south: 40.7863009,
        west: -74.015659,
        north: 40.798891,
        east: -73.991618,
      },
      center: {
        lat: 40.7920454,
        lng: -74.0037505,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "i3w4O0I1REfpGcvEj4yc",
    name: "Lido Beach",
    place_name: "Lido Beach, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Lido Beach",
      bbox: {
        south: 40.574655,
        west: -73.637655,
        north: 40.5968699,
        east: -73.57441109999999,
      },
      center: {
        lat: 40.5889926,
        lng: -73.6254072,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 862000000,
    },
  },
  {
    id: "i9C7psvatZLvcAkCuEcg",
    name: "Oak Island",
    place_name: "Oak Island, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Oak Island",
      bbox: {
        south: 40.6420831197085,
        west: -73.29842978029151,
        north: 40.64478108029149,
        east: -73.29573181970851,
      },
      center: {
        lat: 40.6434321,
        lng: -73.2970808,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 468000000,
    },
  },
  {
    id: "iKRM8Au0rfOyjJccbXW1",
    name: "Saddle Rock Estates",
    place_name: "Saddle Rock Estates, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Saddle Rock Estates",
      bbox: {
        south: 40.79221251970849,
        west: -73.74301578029149,
        north: 40.79491048029149,
        east: -73.74031781970848,
      },
      center: {
        lat: 40.7935615,
        lng: -73.74166679999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 868000000,
    },
  },
  {
    id: "iTCSJt2ylxSRCgC2waGA",
    name: "Montclair",
    place_name: "Montclair, NJ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Montclair",
      bbox: {
        south: 40.788323,
        west: -74.23763989999999,
        north: 40.861847,
        east: -74.18268909999999,
      },
      center: {
        lat: 40.8259007,
        lng: -74.2090053,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "iTn1xd5iLqoJFcpY8q1a",
    name: "Randolph",
    place_name: "Randolph, NJ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Randolph",
      bbox: {
        south: 40.804714,
        west: -74.6458019,
        north: 40.883942,
        east: -74.524895,
      },
      center: {
        lat: 40.8478065,
        lng: -74.5747248,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 502000000,
    },
  },
  {
    id: "ib6J5UifvQAtjuCjsmT4",
    name: "Arverne",
    place_name: "Arverne, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Arverne",
      bbox: {
        south: 40.5852616,
        west: -73.8079119,
        north: 40.6037391,
        east: -73.7860539,
      },
      center: {
        lat: 40.592658,
        lng: -73.7977928,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 868000000,
    },
  },
  {
    id: "ig74PKKhJQpHtdH8HNcO",
    name: "Little Neck",
    place_name: "Little Neck, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Little Neck",
      bbox: {
        south: 40.7523233,
        west: -73.7449165,
        north: 40.7784413,
        east: -73.71386269999999,
      },
      center: {
        lat: 40.7662087,
        lng: -73.7315837,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 844000000,
    },
  },
  {
    id: "ipFikyUN819mJAR3wZSA",
    name: "Prince's Bay",
    place_name: "Prince's Bay, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Prince's Bay",
      bbox: {
        south: 40.509971,
        west: -74.2250749,
        north: 40.535301,
        east: -74.1779859,
      },
      center: {
        lat: 40.5232804,
        lng: -74.20032309999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 171000000,
    },
  },
  {
    id: "irKFIz1D02TuD4INg2dq",
    name: "New Lots",
    place_name: "New Lots, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "New Lots",
      bbox: {
        south: 40.6604721197085,
        west: -73.8944408802915,
        north: 40.6631700802915,
        east: -73.89174291970849,
      },
      center: {
        lat: 40.6618211,
        lng: -73.8930919,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 650000000,
    },
  },
  {
    id: "iztm9sr52WR6f52mfULX",
    name: "Mill Basin",
    place_name: "Mill Basin, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Mill Basin",
      bbox: {
        south: 40.602498,
        west: -73.92460609999999,
        north: 40.619831,
        east: -73.9015319,
      },
      center: {
        lat: 40.6096768,
        lng: -73.9114293,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 672000000,
    },
  },
  {
    id: "j96NWvjQHyoHG40Io2Mm",
    name: "Garden City South",
    place_name: "Garden City South, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Garden City South",
      bbox: {
        south: 40.705658,
        west: -73.6657069,
        north: 40.7175908,
        east: -73.651074,
      },
      center: {
        lat: 40.7123242,
        lng: -73.6609631,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 61000000,
    },
  },
  {
    id: "jCHuJAv5eRXsGFepBA76",
    name: "Sheepshead Bay",
    place_name: "Sheepshead Bay, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Sheepshead Bay",
      bbox: {
        south: 40.582148,
        west: -73.9666379,
        north: 40.6159111,
        east: -73.9256371,
      },
      center: {
        lat: 40.5953955,
        lng: -73.94575379999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 671000000,
    },
  },
  {
    id: "jfypRHRuMTKxIIHIxORN",
    name: "Malba",
    place_name: "Malba, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Malba",
      bbox: {
        south: 40.7845749,
        west: -73.83218579999999,
        north: 40.79763210000001,
        east: -73.8234794,
      },
      center: {
        lat: 40.7906659,
        lng: -73.8257325,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 843000000,
    },
  },
  {
    id: "jjdHt2PyCCFtq9x1PLu1",
    name: "Roslyn Harbor",
    place_name: "Roslyn Harbor, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Roslyn Harbor",
      bbox: {
        south: 40.8051109,
        west: -73.6490039,
        north: 40.828406,
        east: -73.6215856,
      },
      center: {
        lat: 40.8164891,
        lng: -73.63707269999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 24000000,
    },
  },
  {
    id: "jpJkvZNe0OxMDLOeihzI",
    name: "East Northport",
    place_name: "East Northport, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "East Northport",
      bbox: {
        south: 40.862415,
        west: -73.355184,
        north: 40.9000379,
        east: -73.28644,
      },
      center: {
        lat: 40.8767647,
        lng: -73.3245613,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 552000000,
    },
  },
  {
    id: "k3LWBAQr6UqB6zSKQUOz",
    borough: "Manhattan",
    place_name: "Midtown Manhattan, New York, NY",
    name: "Midtown",
    county: "New York County",
    components: {
      types: ["neighborhood", "political"],
      text: "Midtown Manhattan",
      bbox: {
        north: 40.7741244,
        east: -73.95889869999999,
        west: -74.0089488,
        south: 40.72686849999999,
      },
      center: {
        lat: 40.7549309,
        lng: -73.9840195,
      },
    },
    state_short: "NY",
    created: {
      seconds: 1726188299,
      nanoseconds: 977000000,
    },
    state: "New York",
  },
  {
    id: "k495sqzI8oKUd5pqZG0u",
    name: "Medford",
    place_name: "Medford, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Medford",
      bbox: {
        south: 40.7940389,
        west: -73.021326,
        north: 40.8506451,
        east: -72.9539348,
      },
      center: {
        lat: 40.8175985,
        lng: -73.0001068,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "kCHkKvQw91cgXrWJXYOJ",
    name: "Lattingtown",
    place_name: "Lattingtown, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Lattingtown",
      bbox: {
        south: 40.876556,
        west: -73.62102279999999,
        north: 40.9092949,
        east: -73.5687002,
      },
      center: {
        lat: 40.8953767,
        lng: -73.6009595,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 759000000,
    },
  },
  {
    id: "kCh2pKQ9W8Ke1eO9XzFC",
    name: "Bronxdale",
    place_name: "Bronxdale, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Bronxdale",
      bbox: {
        south: 40.8493068197085,
        west: -73.8678730802915,
        north: 40.8520047802915,
        east: -73.86517511970848,
      },
      center: {
        lat: 40.8506558,
        lng: -73.86652409999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 480000000,
    },
  },
  {
    id: "kWtstYzT9vIuokPFLUmp",
    name: "Coram",
    place_name: "Coram, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Coram",
      bbox: {
        south: 40.837688,
        west: -73.039154,
        north: 40.921093,
        east: -72.9724179,
      },
      center: {
        lat: 40.8687096,
        lng: -73.0014946,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 474000000,
    },
  },
  {
    id: "kfXUEj4rwazDFqTSBvXy",
    name: "Belmont",
    place_name: "Belmont, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Belmont",
      bbox: {
        south: 40.8467737,
        west: -73.8975846,
        north: 40.8618781,
        east: -73.880505,
      },
      center: {
        lat: 40.8534507,
        lng: -73.88936819999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 484000000,
    },
  },
  {
    id: "kfttLr4rKilRP4p89hSL",
    name: "Glassboro",
    place_name: "Glassboro, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Glassboro",
      bbox: {
        south: 39.678584,
        west: -75.168261,
        north: 39.73739,
        east: -75.05478500000001,
      },
      center: {
        lat: 39.70289229999999,
        lng: -75.1118422,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 473000000,
    },
  },
  {
    id: "kqLmiQ0pcWbPhJVEi2UL",
    name: "Huntington",
    place_name: "Huntington, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Huntington",
      bbox: {
        south: 40.856253,
        west: -73.45131789999999,
        north: 40.9080979,
        east: -73.3681309,
      },
      center: {
        lat: 40.8681539,
        lng: -73.425676,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 551000000,
    },
  },
  {
    id: "l0mFr7sZ9nTLrkAdYLAc",
    name: "Old Howard Beach",
    place_name: "Old Howard Beach, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Old Howard Beach",
      bbox: {
        south: 40.6473859,
        west: -73.8409566,
        north: 40.6664306,
        east: -73.8260038,
      },
      center: [-73.83455479999999, 40.6560742],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 285000000,
    },
  },
  {
    id: "l5rrfYiVHIcED68dlqN3",
    name: "Norwood",
    place_name: "Norwood, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Norwood",
      bbox: {
        south: 40.8682781,
        west: -73.88660469999999,
        north: 40.8869861,
        east: -73.8695982,
      },
      center: {
        lat: 40.8810341,
        lng: -73.878486,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 439000000,
    },
  },
  {
    id: "l9cx552EXpeqhQy7s5QU",
    name: "Huguenot",
    place_name: "Huguenot, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Huguenot",
      bbox: {
        south: 40.5359785197085,
        west: -74.19593738029151,
        north: 40.53867648029149,
        east: -74.1932394197085,
      },
      center: {
        lat: 40.5373275,
        lng: -74.1945884,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 170000000,
    },
  },
  {
    id: "lYEtck7pQ7gpUh6Ilhr6",
    name: "Williamsbridge",
    place_name: "Williamsbridge, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Williamsbridge",
      bbox: {
        south: 40.8686779,
        west: -73.86708349999999,
        north: 40.8856222,
        east: -73.843003,
      },
      center: {
        lat: 40.8787602,
        lng: -73.85283559999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 477000000,
    },
  },
  {
    id: "ld73KY6MRLfdVqphW77o",
    name: "Mount Laurel Township",
    place_name: "Mt Laurel Township, NJ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Mount Laurel Township",
      bbox: {
        south: 39.91428,
        west: -74.983717,
        north: 40.0007449,
        east: -74.8485501,
      },
      center: {
        lat: 39.934002,
        lng: -74.89099879999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 491000000,
    },
  },
  {
    id: "lknYjrsSQBroIHIWVkRY",
    name: "Bayside",
    place_name: "Bayside, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Bayside",
      bbox: {
        south: 40.7442366,
        west: -73.7846,
        north: 40.7821967,
        east: -73.7448264,
      },
      center: {
        lat: 40.7602604,
        lng: -73.7692026,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 109000000,
    },
  },
  {
    id: "lq0feE0D34xo2wfLuhRM",
    name: "North Wildwood",
    place_name: "North Wildwood, NJ ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "North Wildwood",
      bbox: {
        south: 38.9883979,
        west: -74.8189379,
        north: 39.0258648,
        east: -74.7861799,
      },
      center: {
        lat: 39.0006686,
        lng: -74.79933299999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "luNMw206jnyUcbjibawG",
    name: "Bay Park",
    place_name: "Bay Park, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Bay Park",
      bbox: {
        south: 40.6221641,
        west: -73.67740309999999,
        north: 40.637822,
        east: -73.6572691,
      },
      center: {
        lat: 40.633159,
        lng: -73.6704081,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "m2lP8bmLKcACFrZa9MAV",
    name: "Pomonok",
    place_name: "Pomonok, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Pomonok",
      bbox: {
        south: 40.7256542,
        west: -73.81522,
        north: 40.7385846,
        east: -73.80094629999999,
      },
      center: {
        lat: 40.732341,
        lng: -73.81077239999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 858000000,
    },
  },
  {
    id: "m9g561SJarjyXmUEbRXk",
    name: "Fort Greene",
    place_name: "Fort Greene, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Fort Greene",
      bbox: {
        south: 40.681683,
        west: -73.98149219999999,
        north: 40.6981962,
        east: -73.9683311,
      },
      center: {
        lat: 40.6920638,
        lng: -73.97418739999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 739000000,
    },
  },
  {
    id: "mbvQpaHouKLMheq47ce1",
    name: "Millville",
    place_name: "Millville, NJ ",
    county: "Cumberland County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Millville",
      bbox: {
        south: 39.3323881,
        west: -75.1490099,
        north: 39.44701,
        east: -74.950823,
      },
      center: {
        lat: 39.4020596,
        lng: -75.0393438,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 487000000,
    },
  },
  {
    id: "mdW2TsvYQcNqZetKxVEw",
    name: "Cape May",
    place_name: "Cape May, NJ ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Cape May",
      bbox: {
        south: 38.928519,
        west: -74.93880109999999,
        north: 38.9521469,
        east: -74.86870189999999,
      },
      center: {
        lat: 38.9351125,
        lng: -74.90600529999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 539000000,
    },
  },
  {
    id: "mfQZpjBsKTivNzJ8tPHW",
    name: "Harrison",
    place_name: "Harrison, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Harrison",
      bbox: {
        south: 40.733501,
        west: -74.16608699999999,
        north: 40.7539659,
        east: -74.1374919,
      },
      center: {
        lat: 40.7464901,
        lng: -74.1562551,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "mhwhpYwSFSMsHC4glS4A",
    name: "Manor Heights",
    place_name: "Manor Heights, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Manor Heights",
      bbox: {
        south: 40.5925175,
        west: -74.14423459999999,
        north: 40.61124849999999,
        east: -74.10954699999999,
      },
      center: {
        lat: 40.6042421,
        lng: -74.12662510000001,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 173000000,
    },
  },
  {
    id: "mnjMRvTDjBMeOfqIjo5p",
    name: "Laconia",
    place_name: "Laconia, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Laconia",
      bbox: {
        south: 40.862979,
        west: -73.8594174,
        north: 40.879078,
        east: -73.8392736,
      },
      center: {
        lat: 40.8716928,
        lng: -73.8486439,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 450000000,
    },
  },
  {
    id: "mqafiumW5ubkGDQuPQac",
    name: "Bedford Park",
    place_name: "Bedford Park, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Bedford Park",
      bbox: {
        south: 40.8687509197085,
        west: -73.8870401802915,
        north: 40.8714488802915,
        east: -73.8843422197085,
      },
      center: {
        lat: 40.8700999,
        lng: -73.8856912,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 484000000,
    },
  },
  {
    id: "muB0gL34WTaNee3i3y97",
    name: "Cherry Hill",
    place_name: "Cherry Hill, NJ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Cherry Hill",
      bbox: {
        south: 39.8593501,
        west: -75.06617109999999,
        north: 39.9541879,
        east: -74.926935,
      },
      center: {
        lat: 39.926813,
        lng: -75.0246312,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 538000000,
    },
  },
  {
    id: "n6aafxMo8ztMgidmXWXB",
    name: "Woodlawn Heights",
    place_name: "Woodlawn Heights, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Woodlawn Heights",
      bbox: {
        south: 40.8951779,
        west: -73.8770041,
        north: 40.90271,
        east: -73.8615768,
      },
      center: {
        lat: 40.8976347,
        lng: -73.8668996,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 471000000,
    },
  },
  {
    id: "nDuCeXZsnJXPEzleSHlf",
    name: "Nolita",
    place_name: "Nolita, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Nolita",
      bbox: {
        south: 40.719431,
        west: -73.9982402,
        north: 40.7250838,
        east: -73.9926194,
      },
      center: {
        lat: 40.7230413,
        lng: -73.99486069999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 323000000,
    },
  },
  {
    id: "nG3qMyWj6pXvdv745JEF",
    name: "Brooklyn Heights",
    place_name: "Brooklyn Heights, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Brooklyn Heights",
      bbox: {
        south: 40.68912359999999,
        west: -74.0051149,
        north: 40.7045029,
        east: -73.9896813,
      },
      center: {
        lat: 40.6959294,
        lng: -73.9955523,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 671000000,
    },
  },
  {
    id: "nLbmde9NbFBYIBbGlkR8",
    name: "Newton",
    place_name: "Newton, NJ ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Newton",
      bbox: {
        south: 41.038007,
        west: -74.77384909999999,
        north: 41.07139189999999,
        east: -74.7299929,
      },
      center: {
        lat: 41.0581527,
        lng: -74.7526651,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 523000000,
    },
  },
  {
    id: "ne7PiAgwWufvbp4SoXIi",
    name: "Weeksville",
    place_name: "Weeksville, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Weeksville",
      bbox: {
        south: 40.6724972197085,
        west: -73.92644858029149,
        north: 40.6751951802915,
        east: -73.92375061970849,
      },
      center: {
        lat: 40.6738462,
        lng: -73.9250996,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 130000000,
    },
  },
  {
    id: "ne7ovRw0KvciClhL92ic",
    name: "Kew Gardens",
    place_name: "Kew Gardens, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Kew Gardens",
      bbox: {
        south: 40.7013971,
        west: -73.8381015,
        north: 40.7154118,
        east: -73.81725709999999,
      },
      center: {
        lat: 40.705695,
        lng: -73.8272029,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 14000000,
    },
  },
  {
    id: "nkOpjFTeOiuGU2OVANxB",
    name: "Oyster Bay Cove",
    place_name: "Oyster Bay Cove, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Oyster Bay Cove",
      bbox: {
        south: 40.834029,
        west: -73.525616,
        north: 40.878881,
        east: -73.482631,
      },
      center: {
        lat: 40.8709319,
        lng: -73.5109566,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 62000000,
    },
  },
  {
    id: "nmk4XHbNBbTZBeEknzaq",
    name: "Journal Square",
    place_name: "Journal Square, Jersey City, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    borough: "Suffolk County",
    components: {
      text: "Journal Square",
      bbox: {
        south: 40.722902,
        west: -74.073071,
        north: 40.745501,
        east: -74.051085,
      },
      center: {
        lat: 40.7345715,
        lng: -74.0631544,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726212600,
      nanoseconds: 790000000,
    },
  },
  {
    id: "nrvpMULH9GH6QoiEXaMk",
    name: "Ocean Hill",
    place_name: "Ocean Hill, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Ocean Hill",
      bbox: {
        south: 40.6671133,
        west: -73.9242677,
        north: 40.69077,
        east: -73.9000497,
      },
      center: {
        lat: 40.6782737,
        lng: -73.9108212,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 115000000,
    },
  },
  {
    id: "nrzAi24BwkDGTyk20C3m",
    name: "Saint James",
    place_name: "St James, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Saint James",
      bbox: {
        south: 40.855136,
        west: -73.1765771,
        north: 40.9085619,
        east: -73.1311479,
      },
      center: {
        lat: 40.8754335,
        lng: -73.1526623,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 516000000,
    },
  },
  {
    id: "nwdg2uxmBKAtexNW08PO",
    name: "Rossville",
    place_name: "Rossville, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Rossville",
      bbox: {
        south: 40.5406409,
        west: -74.2221691,
        north: 40.561083,
        east: -74.1875175,
      },
      center: {
        lat: 40.5456634,
        lng: -74.21634279999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 171000000,
    },
  },
  {
    id: "o8d7Uj6CxNKGU6vo6Phl",
    name: "Morrisania",
    place_name: "Morrisania, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Morrisania",
      bbox: {
        south: 40.8204754,
        west: -73.9166379,
        north: 40.844582,
        east: -73.8928561,
      },
      center: {
        lat: 40.8310814,
        lng: -73.9058574,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 436000000,
    },
  },
  {
    id: "oCD4wP02TPYkxN0doL8t",
    name: "West Deptford",
    place_name: "West Deptford, NJ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "West Deptford",
      bbox: {
        south: 39.794947,
        west: -75.237218,
        north: 39.884502,
        east: -75.1303159,
      },
      center: {
        lat: 39.8318378,
        lng: -75.1895576,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 495000000,
    },
  },
  {
    id: "oCHObppnmA9YlwgIVyiR",
    name: "Mount Olive",
    place_name: "Mt Olive, NJ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Mount Olive",
      bbox: {
        south: 40.8055219,
        west: -74.82168109999999,
        north: 40.920823,
        east: -74.681705,
      },
      center: {
        lat: 40.8514881,
        lng: -74.73294109999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 501000000,
    },
  },
  {
    id: "oED3PyCuMuVOeHMjYQCB",
    name: "Margate City",
    place_name: "Margate City, NJ ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Margate City",
      bbox: {
        south: 39.31707110000001,
        west: -74.5229471,
        north: 39.3448859,
        east: -74.490633,
      },
      center: {
        lat: 39.327894,
        lng: -74.50348579999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 460000000,
    },
  },
  {
    id: "oFXybNY3IANT69YH7FpO",
    components: {
      center: {
        lng: -73.61876149999999,
        lat: 40.7060923,
      },
      types: ["administrative_area_level_3", "political"],
      bbox: {
        north: 40.90305,
        south: 40.520505,
        west: -73.78019309999999,
        east: -73.4815541,
      },
      text: "Hempstead",
    },
    name: "Hempstead",
    place_name: "Hempstead, NY",
    state_short: "NY",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726208962,
      nanoseconds: 709000000,
    },
    borough: "Nassau County",
  },
  {
    id: "oIwswiQZRkdLdIKAQRMA",
    name: "New Brunswick",
    place_name: "New Brunswick, NJ",
    county: "Middlesex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "New Brunswick",
      bbox: {
        south: 40.467221,
        west: -74.4895239,
        north: 40.509533,
        east: -74.39241179999999,
      },
      center: {
        lat: 40.4862157,
        lng: -74.4518188,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 565000000,
    },
  },
  {
    id: "oL4GUcvCoHlFcNqobgS0",
    name: "Somerville",
    place_name: "Somerville, NJ ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Somerville",
      bbox: {
        south: 40.5560339,
        west: -74.626395,
        north: 40.581697,
        east: -74.586259,
      },
      center: {
        lat: 40.5742696,
        lng: -74.60987999999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 568000000,
    },
  },
  {
    id: "oN0Jb3NUHMbTe9PlurEd",
    name: "Oxford",
    place_name: "Oxford, NJ ",
    county: "Warren County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Oxford",
      bbox: {
        south: 40.7814749,
        west: -75.025313,
        north: 40.832713,
        east: -74.953184,
      },
      center: {
        lat: 40.8132568,
        lng: -74.999813,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 521000000,
    },
  },
  {
    id: "oUgImssz3GnjG0f9LU0y",
    name: "Ewing Township",
    place_name: "Ewing Township, NJ",
    county: "Mercer County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Ewing Township",
      bbox: {
        south: 40.23283989999999,
        west: -74.8565078,
        north: 40.2922079,
        east: -74.748806,
      },
      center: {
        lat: 40.2599864,
        lng: -74.79091249999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 577000000,
    },
  },
  {
    id: "os9rJFQbJqeCMVc724fB",
    name: "Mariners Harbor",
    place_name: "Mariners Harbor, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Mariners Harbor",
      bbox: {
        south: 40.63542111970851,
        west: -74.1601036802915,
        north: 40.63811908029151,
        east: -74.15740571970849,
      },
      center: {
        lat: 40.63677010000001,
        lng: -74.1587547,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 119000000,
    },
  },
  {
    id: "otqPYvW7LIPHiuAFa89g",
    name: "Oak Beach",
    place_name: "Oak Beach, Gilgo-Oak Beach-Captree, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Oak Beach",
      bbox: {
        south: 40.637721,
        west: -73.2987794,
        north: 40.6405017,
        east: -73.28528179999999,
      },
      center: {
        lat: 40.6391456,
        lng: -73.2905413,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 463000000,
    },
  },
  {
    id: "p7IDXCwh13nO4Z8v7loV",
    state: "New York",
    created: {
      seconds: 1726188299,
      nanoseconds: 961000000,
    },
    place_name: "Greenwich Village, New York, NY",
    borough: "Manhattan",
    state_short: "NY",
    name: "Greenwich Village",
    components: {
      text: "Greenwich Village",
      center: {
        lat: 40.7335719,
        lng: -74.0027418,
      },
      bbox: {
        east: -73.9907956,
        south: 40.7253563,
        north: 40.742136,
        west: -74.0106087,
      },
      types: ["neighborhood", "political"],
    },
    county: "New York County",
  },
  {
    id: "pGlTszNONvz9G1udCLah",
    name: "Ridgewood",
    place_name: "Ridgewood, NJ ",
    county: "Bergen County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Ridgewood",
      bbox: {
        south: 40.955272,
        west: -74.1490879,
        north: 41.007844,
        east: -74.0775589,
      },
      center: {
        lat: 40.9792645,
        lng: -74.11653129999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 470000000,
    },
  },
  {
    id: "pMg7hrFbpVWnSYTpSRMk",
    name: "Lambertville",
    place_name: "Lambertville, NJ ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Lambertville",
      bbox: {
        south: 40.354963,
        west: -74.9541309,
        north: 40.385805,
        east: -74.93003709999999,
      },
      center: {
        lat: 40.36593939999999,
        lng: -74.94294479999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 541000000,
    },
  },
  {
    id: "pQjlF6JxDBnbU3c61uum",
    name: "Egg Harbor Township",
    place_name: "Egg Harbor Township, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Egg Harbor Township",
      bbox: {
        south: 39.2928469,
        west: -74.7238449,
        north: 39.466395,
        east: -74.4873219,
      },
      center: {
        lat: 39.3822537,
        lng: -74.6166192,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 444000000,
    },
  },
  {
    id: "pSbubMVe1IehbL03aU0Q",
    name: "Gerritsen Beach",
    place_name: "Gerritsen Beach, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Gerritsen Beach",
      bbox: {
        south: 40.5848739,
        west: -73.93507989999999,
        north: 40.6026799,
        east: -73.9171799,
      },
      center: {
        lat: 40.5870033,
        lng: -73.9227554,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 740000000,
    },
  },
  {
    id: "pT6XqFq7DuuklUnMvk9e",
    name: "Islip Terrace",
    place_name: "Islip Terrace, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Islip Terrace",
      bbox: {
        south: 40.7427266,
        west: -73.20143,
        north: 40.7581553,
        east: -73.1700341,
      },
      center: {
        lat: 40.7494306,
        lng: -73.18817810000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 555000000,
    },
  },
  {
    id: "pWcfE9w0JjucZwFNPZ5f",
    name: "Ditmars Steinway",
    place_name: "Ditmars Steinway, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Ditmars Steinway",
      bbox: {
        south: 40.7676198,
        west: -73.9278366,
        north: 40.7909358,
        east: -73.89113119999999,
      },
      center: {
        lat: 40.77796350000001,
        lng: -73.9084659,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 841000000,
    },
  },
  {
    id: "pYSnsv7UmJVRcmCUiHbw",
    name: "Battery Park City",
    place_name: "Battery Park City, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Battery Park City",
      bbox: {
        south: 40.7045626,
        west: -74.0193666,
        north: 40.7186805,
        east: -74.0128113,
      },
      center: {
        lat: 40.7115786,
        lng: -74.0158441,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 178000000,
    },
  },
  {
    id: "pb3vLycuGy5NSMvsEZMm",
    components: {
      bbox: {
        north: 40.9508209,
        west: -73.6944479,
        south: 40.829958,
        east: -73.5902479,
      },
      types: ["locality", "political"],
      text: "Glen Cove",
      center: {
        lng: -73.6337389,
        lat: 40.8623217,
      },
    },
    name: "Glen Cove",
    state_short: "NY",
    borough: "Nassau County",
    created: {
      seconds: 1726208962,
      nanoseconds: 738000000,
    },
    place_name: "Glen Cove, NY",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "pcbrCano0Tb4PRQ6Qy0N",
    name: "Stone Harbor",
    place_name: "Stone Harbor, NJ ",
    county: "Cape May County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Stone Harbor",
      bbox: {
        south: 39.017527,
        west: -74.7899429,
        north: 39.0659489,
        east: -74.747013,
      },
      center: {
        lat: 39.0464066,
        lng: -74.76436079999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 472000000,
    },
  },
  {
    id: "ppcxg4ZAbqBnsZQWZm1A",
    place_name: "Oyster Bay, NY",
    borough: "Nassau County",
    state_short: "NY",
    components: {
      text: "Oyster Bay",
      center: {
        lat: 40.8655816,
        lng: -73.5322797,
      },
      bbox: {
        east: -73.482631,
        west: -73.625982,
        north: 40.915782,
        south: 40.8118604,
      },
      types: ["locality", "political"],
    },
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726208962,
      nanoseconds: 744000000,
    },
    name: "Oyster Bay",
  },
  {
    id: "psHYTJ54MHj0g6ch5zGr",
    name: "Carle Place",
    place_name: "Carle Place, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Carle Place",
      bbox: {
        south: 40.7423181,
        west: -73.6245266,
        north: 40.7582661,
        east: -73.600084,
      },
      center: {
        lat: 40.7526008,
        lng: -73.6104058,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 784000000,
    },
  },
  {
    id: "q6wQ9VitTNwTGAWUdV6M",
    name: "Edenwald",
    place_name: "Edenwald, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Edenwald",
      bbox: {
        south: 40.8791381,
        west: -73.850584,
        north: 40.8942364,
        east: -73.8308066,
      },
      center: {
        lat: 40.8863969,
        lng: -73.84119869999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207783,
      nanoseconds: 796000000,
    },
  },
  {
    id: "qBO3YTLjUktEwSpEQU0t",
    name: "Silver Lake",
    place_name: "Silver Lake, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Silver Lake",
      bbox: {
        south: 40.6181259,
        west: -74.1075461,
        north: 40.63192979999999,
        east: -74.0830014,
      },
      center: {
        lat: 40.6245495,
        lng: -74.0916944,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 171000000,
    },
  },
  {
    id: "qO7A4ls1vyAYiBfH2IGs",
    name: "Rocky Point",
    place_name: "Rocky Point, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Rocky Point",
      bbox: {
        south: 40.9049122,
        west: -72.966528,
        north: 40.9662779,
        east: -72.9029048,
      },
      center: {
        lat: 40.9525987,
        lng: -72.9253805,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 552000000,
    },
  },
  {
    id: "qPjwypLYGGAMRVlbXloy",
    name: "Old Bethpage",
    place_name: "Old Bethpage, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Old Bethpage",
      bbox: {
        south: 40.7346217,
        west: -73.4733678,
        north: 40.7816059,
        east: -73.4350784,
      },
      center: {
        lat: 40.7625993,
        lng: -73.4531786,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 833000000,
    },
  },
  {
    id: "qVHAfIFXp5ofpf3gQ5sP",
    name: "Rego Park",
    place_name: "Rego Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Rego Park",
      bbox: {
        south: 40.7108153,
        west: -73.8749546,
        north: 40.736372,
        east: -73.8517299,
      },
      center: {
        lat: 40.72557219999999,
        lng: -73.8624893,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 77000000,
    },
  },
  {
    id: "qXjNLfvGap4UrUUMEwgV",
    name: "Midland Beach",
    place_name: "Midland Beach, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Midland Beach",
      bbox: {
        south: 40.566603,
        west: -74.11477,
        north: 40.5910057,
        east: -74.0764839,
      },
      center: {
        lat: 40.57149,
        lng: -74.0931627,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "qYwBxu6nn8BUxoc4sTuf",
    name: "Wantagh",
    place_name: "Wantagh, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Wantagh",
      bbox: {
        south: 40.630801,
        west: -73.53187989999999,
        north: 40.707896,
        east: -73.4827959,
      },
      center: {
        lat: 40.6837121,
        lng: -73.5101258,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 710000000,
    },
  },
  {
    id: "qb3inJ88lXLDW3cx3sis",
    name: "Downtown Brooklyn",
    place_name: "Downtown Brooklyn, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Downtown Brooklyn",
      bbox: {
        south: 40.6864741,
        west: -73.99172399999999,
        north: 40.7059085,
        east: -73.9785862,
      },
      center: {
        lat: 40.6960679,
        lng: -73.9845407,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 742000000,
    },
  },
  {
    id: "qcBK44XKgFvgrQZVceYa",
    name: "Breezy Point",
    place_name: "Breezy Point, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Breezy Point",
      bbox: {
        south: 40.5421497,
        west: -73.9414834,
        north: 40.5753043,
        east: -73.8632788,
      },
      center: {
        lat: 40.5608702,
        lng: -73.9155359,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 889000000,
    },
  },
  {
    id: "qdIX6xktld9OsYeu59R1",
    name: "Belvidere",
    place_name: "Belvidere, NJ ",
    county: "Warren County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Belvidere",
      bbox: {
        south: 40.8177829,
        west: -75.0888761,
        north: 40.840947,
        east: -75.059128,
      },
      center: {
        lat: 40.82982,
        lng: -75.07767470000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 606000000,
    },
  },
  {
    id: "qdkspHCb3rfK3pSbHkw7",
    name: "Hudson Heights",
    place_name: "Hudson Heights, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Hudson Heights",
      bbox: {
        south: 40.8447879,
        west: -73.9433956,
        north: 40.8598011,
        east: -73.931358,
      },
      center: {
        lat: 40.8534973,
        lng: -73.9374517,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 973000000,
    },
  },
  {
    id: "qiPeJbV6d0cOLh26PwO1",
    name: "Camden County",
    place_name: "Camden County, NJ",
    county: "Camden County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Camden County",
      bbox: {
        south: 39.6083518,
        west: -75.139832,
        north: 39.9960808,
        east: -74.7362161,
      },
      center: {
        lat: 39.8592439,
        lng: -75.0143824,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 32000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "qkQVCnIRtbMxCnonCqCu",
    name: "Starrett City",
    place_name: "Starrett City, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Starrett City",
      bbox: {
        south: 40.636111,
        west: -73.8912035,
        north: 40.6553846,
        east: -73.8694812,
      },
      center: {
        lat: 40.6484272,
        lng: -73.88236119999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 189000000,
    },
  },
  {
    id: "qpqevUJOn071qOjwYh4U",
    name: "Woodside",
    place_name: "Woodside, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Woodside",
      bbox: {
        south: 40.7350351,
        west: -73.9169745,
        north: 40.7673528,
        east: -73.89006069999999,
      },
      center: {
        lat: 40.7532704,
        lng: -73.9068858,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 70000000,
    },
  },
  {
    id: "r4ww9xyEukpGH3jrUTzi",
    name: "City of Orange",
    place_name: "City of Orange, NJ ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "City of Orange",
      bbox: {
        south: 40.7518873,
        west: -74.2552731,
        north: 40.7897999,
        east: -74.2184851,
      },
      center: {
        lat: 40.7706187,
        lng: -74.2326478,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 541000000,
    },
  },
  {
    id: "rKbSGJ3545DFlFwrmqvY",
    name: "Longwood",
    place_name: "Longwood, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Longwood",
      bbox: {
        south: 40.8234948197085,
        west: -73.89293648029151,
        north: 40.82619278029149,
        east: -73.89023851970849,
      },
      center: {
        lat: 40.8248438,
        lng: -73.8915875,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 449000000,
    },
  },
  {
    id: "rLxBK1jzKJB1HxrtDmyo",
    name: "Lakewood",
    place_name: "Lakewood, NJ ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Lakewood",
      bbox: {
        south: 40.023834,
        west: -74.258899,
        north: 40.120394,
        east: -74.151111,
      },
      center: {
        lat: 40.08212899999999,
        lng: -74.2097014,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 502000000,
    },
  },
  {
    id: "rM32vE4KwsbQK15Va8qE",
    name: "Steinway",
    place_name: "Steinway, Queens, NY ",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Steinway",
      bbox: {
        south: 40.7731969197085,
        west: -73.9050966802915,
        north: 40.7758948802915,
        east: -73.9023987197085,
      },
      center: {
        lat: 40.7745459,
        lng: -73.9037477,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 807000000,
    },
  },
  {
    id: "rMGW8clGTnAUGDiFX1Hz",
    name: "Locust Valley",
    place_name: "Locust Valley, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Locust Valley",
      bbox: {
        south: 40.8706289,
        west: -73.61455080000002,
        north: 40.88503,
        east: -73.5645689,
      },
      center: {
        lat: 40.8759324,
        lng: -73.5970707,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 54000000,
    },
  },
  {
    id: "rNweIn7Yd5ocZwEaWhjm",
    name: "Mineola",
    county: "Nassau County",
    state_short: "NY",
    borough: "Nassau County",
    created: {
      seconds: 1726208962,
      nanoseconds: 745000000,
    },
    state: "New York",
    components: {
      text: "Mineola",
      types: ["locality", "political"],
      center: {
        lat: 40.74921399999999,
        lng: -73.6411129,
      },
      bbox: {
        south: 40.73684790000001,
        north: 40.7674109,
        west: -73.6605635,
        east: -73.6207359,
      },
    },
    place_name: "Mineola, NY",
  },
  {
    id: "rirL70ysUvPRUeWoYIlE",
    name: "Woodbury",
    place_name: "Woodbury, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Woodbury",
      bbox: {
        south: 40.79490210000001,
        west: -73.491709,
        north: 40.84129009999999,
        east: -73.44964449999999,
      },
      center: {
        lat: 40.8256545,
        lng: -73.46762249999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 743000000,
    },
  },
  {
    id: "rog2bnmuy0FBZNtXS7sr",
    name: "East Garden City",
    place_name: "East Garden City, Uniondale, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Garden City",
      bbox: {
        south: 40.712882,
        west: -73.6171008,
        north: 40.7475659,
        east: -73.58000679999999,
      },
      center: {
        lat: 40.7304187,
        lng: -73.5980781,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 970000000,
    },
  },
  {
    id: "rqGCoddwMwLjJTjCk3r9",
    name: "Port Jefferson Station",
    place_name: "Port Jefferson Station, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Port Jefferson Station",
      bbox: {
        south: 40.909365,
        west: -73.0945979,
        north: 40.9364201,
        east: -73.029884,
      },
      center: {
        lat: 40.9253764,
        lng: -73.0473284,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 560000000,
    },
  },
  {
    id: "rv9RpHKH6qJXLaXXuuWA",
    name: "Bethpage",
    place_name: "Bethpage, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Bethpage",
      bbox: {
        south: 40.7314991,
        west: -73.50601,
        north: 40.76983999999999,
        east: -73.4685921,
      },
      center: {
        lat: 40.7442664,
        lng: -73.4820687,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 787000000,
    },
  },
  {
    id: "ryZokpzra8tM21sPWcOu",
    name: "Sewell",
    place_name: "Sewell, Mantua Township, NJ ",
    county: "Gloucester County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Sewell",
      bbox: {
        south: 39.7547381,
        west: -75.1534467,
        north: 39.774197,
        east: -75.13171109999999,
      },
      center: {
        lat: 39.7662167,
        lng: -75.1441926,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 532000000,
    },
  },
  {
    id: "s03bwmxUknZnEhxZ7a7r",
    name: "Bronxwood Avenue",
    place_name: "Bronxwood Ave, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Bronxwood Avenue",
      bbox: {
        south: 40.8559288,
        west: -73.8624893,
        north: 40.8957399,
        east: -73.8507279,
      },
      center: {
        lat: 40.8768626,
        lng: -73.8597013,
      },
      types: ["route"],
    },
    created: {
      seconds: 1726207783,
      nanoseconds: 759000000,
    },
  },
  {
    id: "s0Iug0xKjoiD5sXmsqLn",
    name: "Tottenville",
    place_name: "Tottenville, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Tottenville",
      bbox: {
        south: 40.496103,
        west: -74.2560881,
        north: 40.5216029,
        east: -74.222487,
      },
      center: {
        lat: 40.5083408,
        lng: -74.23554039999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "s3cvhPAqmL0l4OCvLbcV",
    name: "Bay Ridge",
    place_name: "Bay Ridge, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Bay Ridge",
      bbox: {
        south: 40.6184154,
        west: -74.04189339999999,
        north: 40.644948,
        east: -74.0146161,
      },
      center: {
        lat: 40.6263732,
        lng: -74.0298767,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 627000000,
    },
  },
  {
    id: "s4xIbpm4gj1OHkK9AdSV",
    name: "Park Hill",
    place_name: "Park Hill, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Park Hill",
      bbox: {
        south: 40.606177,
        west: -74.0906684,
        north: 40.6177211,
        east: -74.076366,
      },
      center: {
        lat: 40.6119566,
        lng: -74.0814154,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "sAYtY17bfBgELKTvoCLE",
    name: "Pelham Bay",
    place_name: "Pelham Bay, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Pelham Bay",
      bbox: {
        south: 40.8492066197085,
        west: -73.8346822802915,
        north: 40.8519045802915,
        east: -73.83198431970848,
      },
      center: {
        lat: 40.8505556,
        lng: -73.83333329999999,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 403000000,
    },
  },
  {
    id: "sHkmjSHbmFXndQQkhy0M",
    name: "Freehold Township",
    place_name: "Freehold Township, NJ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Freehold Township",
      bbox: {
        south: 40.1682959,
        west: -74.40677699999999,
        north: 40.2954789,
        east: -74.22274999999999,
      },
      center: {
        lat: 40.2136754,
        lng: -74.3000878,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 578000000,
    },
  },
  {
    id: "sOb2kLFssL6cyjVZ4cVz",
    name: "Beechhurst",
    place_name: "Beechhurst, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Beechhurst",
      bbox: {
        south: 40.7860574,
        west: -73.80805889999999,
        north: 40.79695,
        east: -73.79082079999999,
      },
      center: {
        lat: 40.7903214,
        lng: -73.8001946,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 76000000,
    },
  },
  {
    id: "sY1qOay5vJG4tWliOUqe",
    name: "West Brighton",
    place_name: "West Brighton, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "West Brighton",
      bbox: {
        south: 40.619447,
        west: -74.1261431,
        north: 40.6346369,
        east: -74.0971433,
      },
      center: {
        lat: 40.6270298,
        lng: -74.10931409999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "sZh501ebWwliEFCpC6ug",
    name: "Seaford",
    place_name: "Seaford, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Seaford",
      bbox: {
        south: 40.6449149,
        west: -73.50681,
        north: 40.7178689,
        east: -73.47688529999999,
      },
      center: {
        lat: 40.6659344,
        lng: -73.4881809,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 712000000,
    },
  },
  {
    id: "sdTnLZlScqNd5TnNgSgd",
    created: {
      seconds: 1726188299,
      nanoseconds: 911000000,
    },
    place_name: "Upper West Side, New York, NY",
    borough: "Manhattan",
    name: "Upper West Side",
    county: "New York County",
    state: "New York",
    components: {
      text: "Upper West Side",
      center: {
        lat: 40.7870106,
        lng: -73.9753676,
      },
      bbox: {
        north: 40.806098,
        east: -73.9582099,
        south: 40.768483,
        west: -73.9963789,
      },
      types: ["neighborhood", "political"],
    },
    state_short: "NY",
  },
  {
    id: "slYbSpnx4B3b5BuzXEfi",
    name: "Shoreham",
    place_name: "Shoreham, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Shoreham",
      bbox: {
        south: 40.94764989999999,
        west: -72.91391689999999,
        north: 40.9627871,
        east: -72.8986181,
      },
      center: {
        lat: 40.9573208,
        lng: -72.9076025,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 510000000,
    },
  },
  {
    id: "sodxTbWp4Rk5KXBzMo1K",
    name: "Broad Channel",
    place_name: "Broad Channel, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Broad Channel",
      bbox: {
        south: 40.5876711,
        west: -73.8460417,
        north: 40.639187,
        east: -73.7797028,
      },
      center: {
        lat: 40.6158335,
        lng: -73.8213213,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 153000000,
    },
  },
  {
    id: "svZecEJ2z5z4vBkR9QJY",
    county: "Richmond County",
    created: {
      seconds: 1726208256,
      nanoseconds: 179000000,
    },
    state: "New York",
    place_name: "New Dorp, Staten Island, NY ",
    borough: "Staten Island",
    components: {
      types: ["colloquial_area", "political"],
      bbox: {
        north: 40.57534268029149,
        west: -74.11732448029151,
        south: 40.5578222,
        east: -74.0906917,
      },
      center: {
        lng: -74.1159755,
        lat: 40.5739937,
      },
      text: "New Dorp",
    },
    name: "New Dorp",
    state_short: "NY",
  },
  {
    id: "sxAeQNmg927rxcoeMRon",
    name: "Mill Neck",
    place_name: "Mill Neck, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Mill Neck",
      bbox: {
        south: 40.8672116,
        west: -73.5750999,
        north: 40.9029619,
        east: -73.541245,
      },
      center: {
        lat: 40.8870431,
        lng: -73.5551246,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 54000000,
    },
  },
  {
    id: "t0CdPcMKQzCjh1nVhfDp",
    name: "North Hills",
    place_name: "North Hills, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Hills",
      bbox: {
        south: 40.762554,
        west: -73.7036389,
        north: 40.7932,
        east: -73.6610133,
      },
      center: {
        lat: 40.7747684,
        lng: -73.6711485,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 865000000,
    },
  },
  {
    id: "tNZct478rv82E6B0rmhI",
    name: "Plandome",
    place_name: "Plandome, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Plandome",
      bbox: {
        south: 40.8022989,
        west: -73.7114579,
        north: 40.811796,
        east: -73.68738300000001,
      },
      center: {
        lat: 40.8067675,
        lng: -73.7034639,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209376,
      nanoseconds: 53000000,
    },
  },
  {
    id: "tUHyVUvUalrX2HoRhKes",
    name: "Homecrest",
    place_name: "Homecrest, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Homecrest",
      bbox: {
        south: 40.5915969,
        west: -73.96186170000001,
        north: 40.6106414,
        east: -73.9501418,
      },
      center: {
        lat: 40.6004787,
        lng: -73.9565551,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "thpbbsLihBiI02j2Jg5O",
    name: "Seagate",
    place_name: "Seagate, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Seagate",
      bbox: {
        south: 40.570623,
        west: -74.01288079999999,
        north: 40.5819535,
        east: -74.002056,
      },
      center: {
        lat: 40.5768098,
        lng: -74.0079781,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 184000000,
    },
  },
  {
    id: "ti0UCwVdf711kwS5ufsu",
    name: "Ocean Beach",
    place_name: "Ocean Beach, NY ",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Ocean Beach",
      bbox: {
        south: 40.6426639,
        west: -73.1609519,
        north: 40.649808,
        east: -73.151945,
      },
      center: {
        lat: 40.6467664,
        lng: -73.1570589,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 481000000,
    },
  },
  {
    id: "tnj3i9hU6g5RVbtpEoOj",
    name: "Ocean Parkway",
    place_name: "Ocean Pkwy, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Ocean Parkway",
      bbox: {
        south: 40.5736634,
        west: -73.977126,
        north: 40.6553803,
        east: -73.95949399999999,
      },
      center: {
        lat: 40.6128767,
        lng: -73.9683977,
      },
      types: ["route"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 184000000,
    },
  },
  {
    id: "tt7CWm5rnIgPSkUnUyuI",
    name: "Gravesend",
    place_name: "Gravesend, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Gravesend",
      bbox: {
        south: 40.5797595,
        west: -73.9872413,
        north: 40.6100972,
        east: -73.9576462,
      },
      center: {
        lat: 40.5968007,
        lng: -73.9749909,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "txCQdMcSdt0xrRsrH22r",
    name: "Babylon",
    place_name: "Babylon, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Babylon",
      bbox: {
        south: 40.6731989,
        west: -73.34282809999999,
        north: 40.712168,
        east: -73.3131619,
      },
      center: {
        lat: 40.6958436,
        lng: -73.325737,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 467000000,
    },
  },
  {
    id: "txre0Bk6CcAgURvNjV6o",
    name: "Glendale",
    place_name: "Glendale, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Glendale",
      bbox: {
        south: 40.68131899999999,
        west: -73.901844,
        north: 40.7133659,
        east: -73.8521734,
      },
      center: {
        lat: 40.7016662,
        lng: -73.8842219,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 15000000,
    },
  },
  {
    id: "tzXcjeooN8l99XEITrx1",
    name: "North Lynbrook",
    place_name: "North Lynbrook, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "North Lynbrook",
      bbox: {
        south: 40.665633,
        west: -73.6760329,
        north: 40.672334,
        east: -73.6698741,
      },
      center: {
        lat: 40.668103,
        lng: -73.6734874,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 831000000,
    },
  },
  {
    id: "u1RugPPSEllw7R5ClzC3",
    name: "Morristown",
    place_name: "Morristown, NJ ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Morristown",
      bbox: {
        south: 40.7802309,
        west: -74.50144399999999,
        north: 40.81836089999999,
        east: -74.456181,
      },
      center: {
        lat: 40.79676670000001,
        lng: -74.4815438,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 497000000,
    },
  },
  {
    id: "u6bHaPb5s9S7iBkFLP5X",
    name: "Kingsbridge Heights",
    place_name: "Kingsbridge Heights, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Kingsbridge Heights",
      bbox: {
        south: 40.8675202,
        west: -73.90590519999999,
        north: 40.8859971,
        east: -73.8813235,
      },
      center: {
        lat: 40.8711235,
        lng: -73.8976328,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 445000000,
    },
  },
  {
    id: "u7b1IiDTtl6sx2hWaDJS",
    name: "Long Branch",
    place_name: "Long Branch, NJ ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Long Branch",
      bbox: {
        south: 40.260211,
        west: -74.01257989999999,
        north: 40.329783,
        east: -73.97166109999999,
      },
      center: {
        lat: 40.30427780000001,
        lng: -73.9923596,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 502000000,
    },
  },
  {
    id: "u8DHcR7o4RnMGnrKj8nF",
    name: "Port Richmond",
    place_name: "Port Richmond, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Port Richmond",
      bbox: {
        south: 40.62421579999999,
        west: -74.14622829999999,
        north: 40.64140099999999,
        east: -74.12126769999999,
      },
      center: {
        lat: 40.6339267,
        lng: -74.13606519999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 169000000,
    },
  },
  {
    id: "uAasydCSG7NMjnSXv5DJ",
    name: "Cumberland County",
    place_name: "Cumberland County, NC",
    county: "Cumberland County",
    state: "North Carolina",
    state_short: "NC",
    components: {
      text: "Cumberland County",
      bbox: {
        south: 34.834862,
        west: -79.1129211,
        north: 35.266914,
        east: -78.4946779,
      },
      center: {
        lat: 34.9542604,
        lng: -78.74762079999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 18000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "uH8fre2P7A2OWRrdjrlK",
    name: "Unionport",
    place_name: "Unionport, Bronx, NY ",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Unionport",
      bbox: {
        south: 40.8259739197085,
        west: -73.85148388029151,
        north: 40.8286718802915,
        east: -73.84878591970849,
      },
      center: {
        lat: 40.8273229,
        lng: -73.8501349,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 439000000,
    },
  },
  {
    id: "uJmWBc31HPrr7eMi3KT8",
    name: "Commack",
    place_name: "Commack, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Commack",
      bbox: {
        south: 40.803987,
        west: -73.32110899999999,
        north: 40.880126,
        east: -73.2388309,
      },
      center: {
        lat: 40.8428759,
        lng: -73.29289430000001,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 493000000,
    },
  },
  {
    id: "uMYRtKYyU5JQkaSdsDfl",
    name: "Borough Park",
    place_name: "Borough Park, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Borough Park",
      bbox: {
        south: 40.621966,
        west: -74.0082836,
        north: 40.6471082,
        east: -73.97709429999999,
      },
      center: {
        lat: 40.6350319,
        lng: -73.9921028,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 671000000,
    },
  },
  {
    id: "uX7z9ZrIJXmk3wUBTfbF",
    name: "Soundview",
    place_name: "Soundview, Bronx, NY",
    county: "Bronx County",
    state: "New York",
    state_short: "NY",
    borough: "Bronx",
    components: {
      text: "Soundview",
      bbox: {
        south: 40.813935,
        west: -73.88450499999999,
        north: 40.837298,
        east: -73.8568487,
      },
      center: {
        lat: 40.8247728,
        lng: -73.8695889,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726207593,
      nanoseconds: 511000000,
    },
  },
  {
    id: "ukXxiPNPQut6Tpnvfq4w",
    name: "Brookhaven",
    place_name: "Brookhaven, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Brookhaven",
      bbox: {
        south: 40.599407,
        west: -73.177505,
        north: 41.11337890000001,
        east: -72.702873,
      },
      center: {
        lat: 40.77926290000001,
        lng: -72.91535859999999,
      },
      types: ["administrative_area_level_3", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 470000000,
    },
  },
  {
    id: "uwnFbFkSu879mUcss00E",
    name: "Manchester Township",
    place_name: "Manchester Township, NJ",
    county: "Ocean County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Manchester Township",
      bbox: {
        south: 39.8548029,
        west: -74.495277,
        north: 40.055882,
        east: -74.2315431,
      },
      center: {
        lat: 39.9711696,
        lng: -74.33928209999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 575000000,
    },
  },
  {
    id: "vHa7PFQXZCnvQCpBpcOr",
    name: "Ventnor City",
    place_name: "Ventnor City, NJ",
    county: "Atlantic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Ventnor City",
      bbox: {
        south: 39.3229709,
        west: -74.5064309,
        north: 39.3584169,
        east: -74.45864100000001,
      },
      center: {
        lat: 39.3403942,
        lng: -74.4773735,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 458000000,
    },
  },
  {
    id: "vKLGHfoSK4LS7hqy1q2s",
    name: "Monmouth County",
    place_name: "Monmouth County, NJ",
    county: "Monmouth County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Monmouth County",
      bbox: {
        south: 40.07913,
        west: -74.614576,
        north: 40.52554,
        east: -73.88506,
      },
      center: {
        lat: 40.2589455,
        lng: -74.12399599999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 28000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "vL1wVZGU0XyalrE9uK6C",
    name: "East Williston",
    place_name: "East Williston, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "East Williston",
      bbox: {
        south: 40.753366,
        west: -73.640759,
        north: 40.7674109,
        east: -73.625338,
      },
      center: {
        lat: 40.7584343,
        lng: -73.63485090000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726209375,
      nanoseconds: 988000000,
    },
  },
  {
    id: "vMF8POd9DO1lWsrTn3Bb",
    name: "Greenvale",
    place_name: "Greenvale, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Greenvale",
      bbox: {
        south: 40.806967,
        west: -73.6337411,
        north: 40.8172719,
        east: -73.6202055,
      },
      center: {
        lat: 40.8106558,
        lng: -73.62846139999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 826000000,
    },
  },
  {
    id: "vNCKCEKREh604srYQu7S",
    name: "Murray Hill",
    place_name: "Murray Hill, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Murray Hill",
      bbox: {
        south: 40.7530824,
        west: -73.8246781,
        north: 40.771727,
        east: -73.789424,
      },
      center: {
        lat: 40.7634996,
        lng: -73.8073261,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 858000000,
    },
  },
  {
    id: "vWpK681WtWRDz1wAZrwX",
    name: "University Gardens",
    place_name: "University Gardens, NY",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "University Gardens",
      bbox: {
        south: 40.7662836,
        west: -73.7394559,
        north: 40.7829708,
        east: -73.7159781,
      },
      center: {
        lat: 40.7773236,
        lng: -73.7226313,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 821000000,
    },
  },
  {
    id: "veJrLKERsVdX6w9i2iV1",
    name: "Old Town",
    place_name: "Old Town, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Old Town",
      bbox: {
        south: 40.5959390197085,
        west: -74.0882376802915,
        north: 40.5986369802915,
        east: -74.08553971970849,
      },
      center: {
        lat: 40.5972845,
        lng: -74.08699519999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 175000000,
    },
  },
  {
    id: "vgZdbEYmNy7y8PIGbrKa",
    name: "Barnum Island",
    place_name: "Barnum Island, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Barnum Island",
      bbox: {
        south: 40.5953639,
        west: -73.6567501,
        north: 40.6178941,
        east: -73.63187289999999,
      },
      center: {
        lat: 40.604548,
        lng: -73.6440187,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 738000000,
    },
  },
  {
    id: "vjqB87qu6AYWTX3lWi75",
    name: "Hudson County",
    place_name: "Hudson County, NJ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hudson County",
      bbox: {
        south: 40.6421489,
        west: -74.16608699999999,
        north: 40.82358199999999,
        east: -73.98479429999999,
      },
      center: {
        lat: 40.7453199,
        lng: -74.05351259999999,
      },
      types: ["administrative_area_level_2", "political"],
    },
    created: {
      seconds: 1726211023,
      nanoseconds: 61000000,
    },
    type: "county",
    types: ["administrative_area_level_2", "political"],
  },
  {
    id: "vsE9j4w4LmjRL3uprGEx",
    name: "Queens Village",
    place_name: "Queens Village, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Queens Village",
      bbox: {
        south: 40.698445,
        west: -73.75671009999999,
        north: 40.7325461,
        east: -73.725606,
      },
      center: [-73.7419017, 40.7156628],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 214000000,
    },
  },
  {
    id: "vuHVv7SNzPl3ecJyswvc",
    name: "Tompkinsville",
    place_name: "Tompkinsville, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Tompkinsville",
      bbox: {
        south: 40.62866770000001,
        west: -74.0972187,
        north: 40.6382556,
        east: -74.0689751,
      },
      center: {
        lat: 40.63259559999999,
        lng: -74.0872892,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 170000000,
    },
  },
  {
    id: "vuPYLDRcZvNlsa137aFD",
    name: "Herricks",
    place_name: "Herricks, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Herricks",
      bbox: {
        south: 40.751493,
        west: -73.6736239,
        north: 40.761182,
        east: -73.6579871,
      },
      center: {
        lat: 40.7553791,
        lng: -73.6667963,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 823000000,
    },
  },
  {
    id: "vyBGfPMlzZUngfd1pV8S",
    name: "Great Kills",
    place_name: "Great Kills, Staten Island, NY",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Great Kills",
      bbox: {
        south: 40.5293601,
        west: -74.16271859999999,
        north: 40.5676703,
        east: -74.115186,
      },
      center: {
        lat: 40.5543273,
        lng: -74.156292,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 177000000,
    },
  },
  {
    id: "wHFeSPRFsA23fsssbvSW",
    name: "East Harlem",
    place_name: "East Harlem, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "East Harlem",
      bbox: {
        south: 40.7827839,
        west: -73.9558541,
        north: 40.8180871,
        east: -73.9286431,
      },
      center: {
        lat: 40.7957399,
        lng: -73.93892129999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 353000000,
    },
  },
  {
    id: "wL62e5ppXohPSMMJtTQ1",
    name: "Hamilton Beach",
    place_name: "Hamilton Beach, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Hamilton Beach",
      bbox: {
        south: 40.6486875,
        west: -73.8319231,
        north: 40.6566968,
        east: -73.8265371,
      },
      center: [-73.8294085, 40.6522454],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206364,
      nanoseconds: 276000000,
    },
  },
  {
    id: "wXN9O2BRxn8XkhRaV8hG",
    name: "Miller Place",
    place_name: "Miller Place, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Miller Place",
      bbox: {
        south: 40.9040869,
        west: -73.0215669,
        north: 40.9666099,
        east: -72.956664,
      },
      center: {
        lat: 40.9598212,
        lng: -72.99621479999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 484000000,
    },
  },
  {
    id: "wYZQU6caIMD4E0YecMEL",
    created: {
      seconds: 1726207593,
      nanoseconds: 477000000,
    },
    components: {
      types: ["neighborhood", "political"],
      center: {
        lng: -73.9088276,
        lat: 40.89961830000001,
      },
      text: "Riverdale",
      bbox: {
        east: -73.896705,
        west: -73.92459819999999,
        north: 40.9040101,
        south: 40.8755781,
      },
    },
    place_name: "Riverdale, Bronx, NY",
    state_short: "NY",
    borough: "Bronx",
    state: "New York",
    county: "Bronx County",
    name: "Riverdale",
  },
  {
    id: "woauWrBR2HHTUUcIfrev",
    name: "Stuyvesant Heights",
    place_name: "Stuyvesant Heights, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Stuyvesant Heights",
      bbox: {
        south: 40.6793896,
        west: -73.94430369999999,
        north: 40.685351,
        east: -73.9285708,
      },
      center: {
        lat: 40.6824166,
        lng: -73.9319933,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204644,
      nanoseconds: 114000000,
    },
  },
  {
    id: "wskn1H4xPhcPR0ibMB0D",
    name: "Dumbo",
    place_name: "Dumbo, Brooklyn, NY ",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Dumbo",
      bbox: {
        south: 40.7014434,
        west: -73.99454279999999,
        north: 40.70556690000001,
        east: -73.98450849999999,
      },
      center: {
        lat: 40.7033164,
        lng: -73.9881451,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 659000000,
    },
  },
  {
    id: "x5eyrIfKPSyxahH5eW4Y",
    name: "Chelsea",
    place_name: "Chelsea, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Chelsea",
      bbox: {
        south: 40.7373582,
        west: -74.0088629,
        north: 40.7570384,
        east: -73.9877916,
      },
      center: [-74.00137370000002, 40.7465004],
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726203548,
      nanoseconds: 875000000,
    },
  },
  {
    id: "xA2vKvoEMd6nJEeaE7Yl",
    name: "Islip",
    place_name: "Islip, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Islip",
      bbox: {
        south: 40.7000021,
        west: -73.2434249,
        north: 40.76318699999999,
        east: -73.1993449,
      },
      center: {
        lat: 40.7297786,
        lng: -73.2105665,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 548000000,
    },
  },
  {
    id: "xLFk5Vrov6y8SEjUMlpq",
    name: "Forest Hills",
    place_name: "Forest Hills, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Forest Hills",
      bbox: {
        south: 40.7043654,
        west: -73.85852179999999,
        north: 40.73990320000001,
        east: -73.8281504,
      },
      center: {
        lat: 40.718106,
        lng: -73.8448469,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726205338,
      nanoseconds: 88000000,
    },
  },
  {
    id: "xQI7e6qKWMJktPs1qAqT",
    name: "Hopatcong",
    place_name: "Hopatcong, NJ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Hopatcong",
      bbox: {
        south: 40.91085289999999,
        west: -74.697709,
        north: 40.9994808,
        east: -74.613751,
      },
      center: {
        lat: 40.9328762,
        lng: -74.6593281,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 603000000,
    },
  },
  {
    id: "xTte2OVMvfwdEatPwDls",
    name: "Park Slope",
    place_name: "Park Slope, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Park Slope",
      bbox: {
        south: 40.6574724,
        west: -73.9927269,
        north: 40.6848525,
        east: -73.9701749,
      },
      center: {
        lat: 40.6710672,
        lng: -73.98142279999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 670000000,
    },
  },
  {
    id: "xYK8wgvI0NahVlgFir3c",
    name: "Franklin Township",
    place_name: "Franklin Township, NJ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Franklin Township",
      bbox: {
        south: 40.37439690000001,
        west: -74.63746309999999,
        north: 40.554559,
        east: -74.4590419,
      },
      center: {
        lat: 40.4933734,
        lng: -74.5228916,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 580000000,
    },
  },
  {
    id: "xa65QAgrZv0GQYEPcysX",
    name: "Randalls and Wards Islands",
    place_name: "Randalls and Wards Islands, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Randalls and Wards Islands",
      bbox: {
        south: 40.7809301,
        west: -73.93605339999999,
        north: 40.80202,
        east: -73.9137308,
      },
      center: {
        lat: 40.7932271,
        lng: -73.92128579999999,
      },
      types: ["establishment", "natural_feature"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 907000000,
    },
  },
  {
    id: "xbhDf9947yYxVg2ryD8F",
    name: "West Babylon",
    place_name: "West Babylon, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "West Babylon",
      bbox: {
        south: 40.6674,
        west: -73.396878,
        north: 40.741693,
        east: -73.3286641,
      },
      center: {
        lat: 40.718155,
        lng: -73.354287,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 559000000,
    },
  },
  {
    id: "xeV7QJtZUzausFenXF0p",
    name: "Denville",
    place_name: "Denville, NJ",
    county: "Morris County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Denville",
      bbox: {
        south: 40.833144,
        west: -74.53357290000001,
        north: 40.935156,
        east: -74.4385679,
      },
      center: {
        lat: 40.8923212,
        lng: -74.47737750000002,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 577000000,
    },
  },
  {
    id: "xgAxM21Y1HUUbVDX2IOQ",
    place_name: "Howard Beach, Queens, NY",
    state_short: "NY",
    created: {
      seconds: 1726206181,
      nanoseconds: 884000000,
    },
    borough: "Queens",
    components: {
      text: "Howard Beach",
      center: {
        lat: 40.6571222,
        lng: -73.8429989,
      },
      bbox: {
        west: -74.0421119,
        north: 40.81171310000001,
        south: 40.4897939,
        east: -73.700169,
      },
      types: ["neighborhood", "political"],
    },
    name: "Howard Beach",
    county: "Queens County",
    state: "New York",
  },
  {
    id: "xldPeUlqhQtgBWkzoVKC",
    name: "Flemington",
    place_name: "Flemington, NJ ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Flemington",
      bbox: {
        south: 40.4977599,
        west: -74.8694941,
        north: 40.5191048,
        east: -74.850499,
      },
      center: {
        lat: 40.5123258,
        lng: -74.85933179999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "xpPz5KxbOEf44kDxR7kJ",
    name: "Moorestown",
    place_name: "Moorestown, NJ ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Moorestown",
      bbox: {
        south: 39.937802,
        west: -75.00550190000001,
        north: 40.013644,
        east: -74.8822259,
      },
      center: {
        lat: 39.9688817,
        lng: -74.948886,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 470000000,
    },
  },
  {
    id: "xylUfhojugpCuptAoZbF",
    name: "Rockaway Beach",
    place_name: "Rockaway Beach, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Rockaway Beach",
      bbox: {
        south: 40.5793794,
        west: -73.8311204,
        north: 40.593606,
        east: -73.8040302,
      },
      center: {
        lat: 40.5860224,
        lng: -73.81662589999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 939000000,
    },
  },
  {
    id: "yENo4hy9suqFWjpbTOQC",
    name: "Paterson",
    place_name: "Paterson, NJ",
    county: "Passaic County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Paterson",
      bbox: {
        south: 40.8886819,
        west: -74.2061969,
        north: 40.94159,
        east: -74.129233,
      },
      center: {
        lat: 40.9167654,
        lng: -74.17181099999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 502000000,
    },
  },
  {
    id: "yegRcH20bqJwC0oKeLQg",
    name: "East Islip",
    place_name: "East Islip, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "East Islip",
      bbox: {
        south: 40.698403,
        west: -73.204651,
        north: 40.7473382,
        east: -73.1636829,
      },
      center: {
        lat: 40.7320429,
        lng: -73.1856703,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 475000000,
    },
  },
  {
    id: "yl2P1qn3isELrS8BBArl",
    name: "Livingston",
    place_name: "Livingston, NJ ",
    county: "Essex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Livingston",
      bbox: {
        south: 40.757596,
        west: -74.377517,
        north: 40.818416,
        east: -74.280592,
      },
      center: {
        lat: 40.7862871,
        lng: -74.3300842,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "yoptPnREzq8HVYNfjxM8",
    name: "Willowbrook",
    place_name: "Willowbrook, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Willowbrook",
      bbox: {
        south: 40.6018108197085,
        west: -74.13982518029152,
        north: 40.6045087802915,
        east: -74.13712721970852,
      },
      center: {
        lat: 40.6031598,
        lng: -74.13847620000001,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 176000000,
    },
  },
  {
    id: "ythwNvmGjm5PEJXFm3ah",
    name: "Charleston",
    place_name: "Charleston, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Charleston",
      bbox: {
        south: 40.53542291970849,
        west: -74.23871618029149,
        north: 40.53812088029149,
        east: -74.23601821970848,
      },
      center: {
        lat: 40.5367719,
        lng: -74.2373672,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 110000000,
    },
  },
  {
    id: "yvZylXkfTNjvrkToF2VG",
    name: "High Bridge",
    place_name: "High Bridge, NJ",
    county: "Hunterdon County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "High Bridge",
      bbox: {
        south: 40.6556789,
        west: -74.91277989999999,
        north: 40.6807499,
        east: -74.8743929,
      },
      center: {
        lat: 40.6670454,
        lng: -74.8957231,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 534000000,
    },
  },
  {
    id: "z0hTrl4g33HZu5PhZLEA",
    name: "Gowanus",
    place_name: "Gowanus, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Gowanus",
      bbox: {
        south: 40.6654866,
        west: -73.9988853,
        north: 40.6839576,
        east: -73.9808178,
      },
      center: {
        lat: 40.6751161,
        lng: -73.9879753,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 664000000,
    },
  },
  {
    id: "z8aPTPYcfpN2bpKmBn6h",
    name: "Rockaway Park",
    place_name: "Rockaway Park, Queens, NY",
    county: "Queens County",
    state: "New York",
    state_short: "NY",
    borough: "Queens",
    components: {
      text: "Rockaway Park",
      bbox: {
        south: 40.5739587,
        west: -73.8484076,
        north: 40.58382770000001,
        east: -73.8299593,
      },
      center: {
        lat: 40.57978629999999,
        lng: -73.8372237,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726206181,
      nanoseconds: 889000000,
    },
  },
  {
    id: "zCLkE6Gr8bOAoU6L9RIU",
    name: "Bound Brook",
    place_name: "Bound Brook, NJ ",
    county: "Somerset County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Bound Brook",
      bbox: {
        south: 40.5551959,
        west: -74.557675,
        north: 40.581725,
        east: -74.5237021,
      },
      center: {
        lat: 40.56843629999999,
        lng: -74.53848889999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 602000000,
    },
  },
  {
    id: "zCZwpjEwAuyw0QewY71c",
    name: "Bay Terrace",
    place_name: "Bay Terrace, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Bay Terrace",
      bbox: {
        south: 40.5539288197085,
        west: -74.13551568029152,
        north: 40.5566267802915,
        east: -74.13281771970851,
      },
      center: {
        lat: 40.5552778,
        lng: -74.13416670000001,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 105000000,
    },
  },
  {
    id: "zEUeeUGzxeNYddkI2NPk",
    name: "West New York",
    place_name: "West New York, NJ ",
    county: "Hudson County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "West New York",
      bbox: {
        south: 40.774384,
        west: -74.0236139,
        north: 40.796557,
        east: -73.99288290000001,
      },
      center: {
        lat: 40.7878788,
        lng: -74.0143064,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 578000000,
    },
  },
  {
    id: "zFzIvwO7BVLLihIa5HZA",
    name: "Boerum Hill",
    place_name: "Boerum Hill, Brooklyn, NY",
    county: "Kings County",
    state: "New York",
    state_short: "NY",
    borough: "Brooklyn",
    components: {
      text: "Boerum Hill",
      bbox: {
        south: 40.680638,
        west: -73.9942932,
        north: 40.6910082,
        east: -73.97797469999999,
      },
      center: {
        lat: 40.6848689,
        lng: -73.9844722,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726204075,
      nanoseconds: 739000000,
    },
  },
  {
    id: "zIOipQcMTHd0BAjrSI5w",
    name: "Rosebank",
    place_name: "Rosebank, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Rosebank",
      bbox: {
        south: 40.61264421970849,
        west: -74.06760108029151,
        north: 40.61534218029149,
        east: -74.06490311970849,
      },
      center: {
        lat: 40.6139932,
        lng: -74.0662521,
      },
      types: ["colloquial_area", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 175000000,
    },
  },
  {
    id: "zJwUJIZH79bO3zWfsqiP",
    name: "Vernon Township",
    place_name: "Vernon Township, NJ",
    county: "Sussex County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Vernon Township",
      bbox: {
        south: 41.1037959,
        west: -74.5772329,
        north: 41.284709,
        east: -74.3658969,
      },
      center: {
        lat: 41.1985786,
        lng: -74.4828866,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 605000000,
    },
  },
  {
    id: "zL1YNu89JOmOZUE3ryJP",
    name: "Brightwaters",
    place_name: "Brightwaters, NY",
    county: "Suffolk County",
    state: "New York",
    state_short: "NY",
    borough: "Suffolk County",
    components: {
      text: "Brightwaters",
      bbox: {
        south: 40.7019309,
        west: -73.2752642,
        north: 40.7319514,
        east: -73.2468942,
      },
      center: {
        lat: 40.7209321,
        lng: -73.2673399,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726210341,
      nanoseconds: 550000000,
    },
  },
  {
    id: "zdv1Amjbw6VEDOQAjc7J",
    name: "Washington Heights",
    place_name: "Washington Heights, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Washington Heights",
      bbox: {
        south: 40.8281291,
        west: -73.9505678,
        north: 40.8595902,
        east: -73.9224529,
      },
      center: {
        lat: 40.8417082,
        lng: -73.9393554,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726188299,
      nanoseconds: 904000000,
    },
  },
  {
    id: "zkGTWOiXBpKQ8eMqJjRF",
    name: "Flatiron District",
    place_name: "Flatiron District, New York, NY",
    county: "New York County",
    state: "New York",
    state_short: "NY",
    borough: "Manhattan",
    components: {
      text: "Flatiron District",
      bbox: {
        south: 40.7383561,
        west: -73.9941752,
        north: 40.7448022,
        east: -73.9833605,
      },
      center: {
        lat: 40.740083,
        lng: -73.9903489,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726201287,
      nanoseconds: 327000000,
    },
  },
  {
    id: "znw05dVX55W7vDni2XVO",
    name: "Pemberton",
    place_name: "Pemberton, NJ ",
    county: "Burlington County",
    state: "New Jersey",
    state_short: "NJ",
    components: {
      text: "Pemberton",
      bbox: {
        south: 39.9660579,
        west: -74.69796,
        north: 39.977118,
        east: -74.67527179999999,
      },
      center: {
        lat: 39.97205779999999,
        lng: -74.6829363,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726211822,
      nanoseconds: 470000000,
    },
  },
  {
    id: "zqD2WgAsPeKQRbS29XB3",
    name: "Lawrence",
    place_name: "Lawrence, NY ",
    county: "Nassau County",
    state: "New York",
    state_short: "NY",
    borough: "Nassau County",
    components: {
      text: "Lawrence",
      bbox: {
        south: 40.590704,
        west: -73.745633,
        north: 40.6216156,
        east: -73.685975,
      },
      center: {
        lat: 40.6156599,
        lng: -73.72957629999999,
      },
      types: ["locality", "political"],
    },
    created: {
      seconds: 1726208962,
      nanoseconds: 744000000,
    },
  },
  {
    id: "zwyhUAHiDU6tbYxef10b",
    name: "Annadale",
    place_name: "Annadale, Staten Island, NY ",
    county: "Richmond County",
    state: "New York",
    state_short: "NY",
    borough: "Staten Island",
    components: {
      text: "Annadale",
      bbox: {
        south: 40.519603,
        west: -74.18658719999999,
        north: 40.544354,
        east: -74.1545591,
      },
      center: {
        lat: 40.5319911,
        lng: -74.17390569999999,
      },
      types: ["neighborhood", "political"],
    },
    created: {
      seconds: 1726208256,
      nanoseconds: 100000000,
    },
  },
];

export const zips = [
  {
    id: "00501",
    county: "Suffolk County",
    zip: "00501",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    state: "New York",
  },
  {
    id: "00544",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "00544",
  },
  {
    id: "06390",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    state: "New York",
    zip: "06390",
  },
  {
    id: "07001",
    state: "New Jersey",
    zip: "07001",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "07002",
    zip: "07002",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
  },
  {
    id: "07003",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "07003",
  },
  {
    id: "07004",
    state: "New Jersey",
    zip: "07004",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07005",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    zip: "07005",
    state: "New Jersey",
  },
  {
    id: "07006",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    state: "New Jersey",
    zip: "07006",
  },
  {
    id: "07007",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "07007",
    state: "New Jersey",
  },
  {
    id: "07008",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "07008",
  },
  {
    id: "07009",
    zip: "07009",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "07010",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
    zip: "07010",
  },
  {
    id: "07011",
    state: "New Jersey",
    zip: "07011",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "07012",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07012",
  },
  {
    id: "07013",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07013",
  },
  {
    id: "07014",
    state: "New Jersey",
    zip: "07014",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "07015",
    state: "New Jersey",
    zip: "07015",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "07016",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
    zip: "07016",
  },
  {
    id: "07017",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07017",
    state: "New Jersey",
  },
  {
    id: "07018",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07018",
  },
  {
    id: "07019",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07019",
    state: "New Jersey",
  },
  {
    id: "07020",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
    zip: "07020",
  },
  {
    id: "07021",
    state: "New Jersey",
    zip: "07021",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07022",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
    zip: "07022",
  },
  {
    id: "07023",
    zip: "07023",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
  },
  {
    id: "07024",
    state: "New Jersey",
    zip: "07024",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "07026",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "07026",
    state: "New Jersey",
  },
  {
    id: "07027",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    state: "New Jersey",
    zip: "07027",
  },
  {
    id: "07028",
    zip: "07028",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "07029",
    zip: "07029",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "07030",
    zip: "07030",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
  },
  {
    id: "07031",
    zip: "07031",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07032",
    zip: "07032",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
  },
  {
    id: "07033",
    zip: "07033",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "07034",
    state: "New Jersey",
    zip: "07034",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "07035",
    zip: "07035",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "07036",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "07036",
  },
  {
    id: "07039",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "07039",
  },
  {
    id: "07040",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "07040",
  },
  {
    id: "07041",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    state: "New Jersey",
    zip: "07041",
  },
  {
    id: "07042",
    zip: "07042",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07043",
    zip: "07043",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
  },
  {
    id: "07044",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
    zip: "07044",
  },
  {
    id: "07045",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07045",
    state: "New Jersey",
  },
  {
    id: "07046",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07046",
    state: "New Jersey",
  },
  {
    id: "07047",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "07047",
    state: "New Jersey",
  },
  {
    id: "07050",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07050",
    state: "New Jersey",
  },
  {
    id: "07051",
    zip: "07051",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    state: "New Jersey",
  },
  {
    id: "07052",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07052",
    state: "New Jersey",
  },
  {
    id: "07054",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07054",
    state: "New Jersey",
  },
  {
    id: "07055",
    state: "New Jersey",
    zip: "07055",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
  },
  {
    id: "07057",
    state: "New Jersey",
    zip: "07057",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
  },
  {
    id: "07058",
    state: "New Jersey",
    zip: "07058",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "07059",
    state: "New Jersey",
    zip: "07059",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07060",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07060",
    state: "New Jersey",
  },
  {
    id: "07061",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
    zip: "07061",
  },
  {
    id: "07062",
    state: "New Jersey",
    zip: "07062",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "07063",
    zip: "07063",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "07064",
    zip: "07064",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "07065",
    state: "New Jersey",
    zip: "07065",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "07066",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07066",
    state: "New Jersey",
  },
  {
    id: "07067",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "07067",
  },
  {
    id: "07068",
    zip: "07068",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "07070",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07070",
  },
  {
    id: "07071",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "07071",
  },
  {
    id: "07072",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "07072",
    state: "New Jersey",
  },
  {
    id: "07073",
    state: "New Jersey",
    zip: "07073",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "07074",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07074",
  },
  {
    id: "07075",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07075",
  },
  {
    id: "07076",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07076",
  },
  {
    id: "07077",
    state: "New Jersey",
    zip: "07077",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "07078",
    zip: "07078",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "07079",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "07079",
    state: "New Jersey",
  },
  {
    id: "07080",
    zip: "07080",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "07081",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    state: "New Jersey",
    zip: "07081",
  },
  {
    id: "07082",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "07082",
  },
  {
    id: "07083",
    state: "New Jersey",
    zip: "07083",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "07086",
    zip: "07086",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
  },
  {
    id: "07087",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "07087",
  },
  {
    id: "07088",
    zip: "07088",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "07090",
    zip: "07090",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07091",
    zip: "07091",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07092",
    zip: "07092",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07093",
    zip: "07093",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
  },
  {
    id: "07094",
    zip: "07094",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "07095",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
    zip: "07095",
  },
  {
    id: "07096",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "07096",
  },
  {
    id: "07097",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "07097",
    state: "New Jersey",
  },
  {
    id: "07098",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "07098",
    state: "New Jersey",
  },
  {
    id: "07099",
    zip: "07099",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07100",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "07100",
  },
  {
    id: "07101",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "07101",
  },
  {
    id: "07102",
    zip: "07102",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "07103",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "07103",
  },
  {
    id: "07104",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "07104",
  },
  {
    id: "07105",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "07105",
  },
  {
    id: "07106",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "07106",
  },
  {
    id: "07107",
    state: "New Jersey",
    zip: "07107",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "07108",
    zip: "07108",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
  },
  {
    id: "07109",
    state: "New Jersey",
    zip: "07109",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "07110",
    state: "New Jersey",
    zip: "07110",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07111",
    state: "New Jersey",
    zip: "07111",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07112",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
    zip: "07112",
  },
  {
    id: "07114",
    zip: "07114",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
  },
  {
    id: "07175",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07175",
  },
  {
    id: "07183",
    zip: "07183",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
  },
  {
    id: "07184",
    state: "New Jersey",
    zip: "07184",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07185",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
    zip: "07185",
  },
  {
    id: "07187",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07187",
    state: "New Jersey",
  },
  {
    id: "07188",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07188",
  },
  {
    id: "07189",
    zip: "07189",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07190",
    state: "New Jersey",
    zip: "07190",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07191",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
    zip: "07191",
  },
  {
    id: "07192",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07192",
    state: "New Jersey",
  },
  {
    id: "07193",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07193",
  },
  {
    id: "07194",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07194",
    state: "New Jersey",
  },
  {
    id: "07195",
    zip: "07195",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07197",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07197",
    state: "New Jersey",
  },
  {
    id: "07198",
    state: "New Jersey",
    zip: "07198",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "07199",
    zip: "07199",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
  },
  {
    id: "07200",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "07200",
  },
  {
    id: "07201",
    zip: "07201",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "07202",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "07202",
  },
  {
    id: "07203",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "07203",
    state: "New Jersey",
  },
  {
    id: "07204",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "07204",
  },
  {
    id: "07205",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "07205",
  },
  {
    id: "07206",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "07206",
    state: "New Jersey",
  },
  {
    id: "07207",
    zip: "07207",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "07208",
    zip: "07208",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
  },
  {
    id: "07215",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "07215",
  },
  {
    id: "07216",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "07216",
  },
  {
    id: "07300",
    zip: "07300",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07302",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "07302",
  },
  {
    id: "07303",
    zip: "07303",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07304",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "07304",
  },
  {
    id: "07305",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
    zip: "07305",
  },
  {
    id: "07306",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
    zip: "07306",
  },
  {
    id: "07307",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
    zip: "07307",
  },
  {
    id: "07308",
    state: "New Jersey",
    zip: "07308",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07309",
    zip: "07309",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
  },
  {
    id: "07310",
    zip: "07310",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
  },
  {
    id: "07311",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "07311",
    state: "New Jersey",
  },
  {
    id: "07399",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "07399",
  },
  {
    id: "07401",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "07401",
  },
  {
    id: "07403",
    zip: "07403",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
  },
  {
    id: "07405",
    zip: "07405",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    state: "New Jersey",
  },
  {
    id: "07407",
    zip: "07407",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "07410",
    zip: "07410",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07416",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    state: "New Jersey",
    zip: "07416",
  },
  {
    id: "07417",
    zip: "07417",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "07418",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "07418",
  },
  {
    id: "07419",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "07419",
    state: "New Jersey",
  },
  {
    id: "07420",
    zip: "07420",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "07421",
    state: "New Jersey",
    zip: "07421",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "07422",
    state: "New Jersey",
    zip: "07422",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "07423",
    state: "New Jersey",
    zip: "07423",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07424",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07424",
    state: "New Jersey",
  },
  {
    id: "07428",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "07428",
    state: "New Jersey",
  },
  {
    id: "07430",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "07430",
  },
  {
    id: "07432",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "07432",
    state: "New Jersey",
  },
  {
    id: "07435",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    state: "New Jersey",
    zip: "07435",
  },
  {
    id: "07436",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07436",
    state: "New Jersey",
  },
  {
    id: "07438",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07438",
  },
  {
    id: "07439",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07439",
  },
  {
    id: "07440",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "07440",
    state: "New Jersey",
  },
  {
    id: "07442",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "07442",
  },
  {
    id: "07444",
    zip: "07444",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "07446",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "07446",
  },
  {
    id: "07450",
    state: "New Jersey",
    zip: "07450",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "07451",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "07451",
  },
  {
    id: "07452",
    state: "New Jersey",
    zip: "07452",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "07456",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "07456",
  },
  {
    id: "07457",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07457",
    state: "New Jersey",
  },
  {
    id: "07458",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
    zip: "07458",
  },
  {
    id: "07460",
    zip: "07460",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
  },
  {
    id: "07461",
    state: "New Jersey",
    zip: "07461",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "07462",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "07462",
    state: "New Jersey",
  },
  {
    id: "07463",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "07463",
  },
  {
    id: "07465",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "07465",
  },
  {
    id: "07470",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07470",
  },
  {
    id: "07474",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "07474",
    state: "New Jersey",
  },
  {
    id: "07477",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "07477",
  },
  {
    id: "07480",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "07480",
  },
  {
    id: "07481",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
    zip: "07481",
  },
  {
    id: "07495",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "07495",
  },
  {
    id: "07498",
    zip: "07498",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
  },
  {
    id: "07500",
    zip: "07500",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    state: "New Jersey",
  },
  {
    id: "07501",
    state: "New Jersey",
    zip: "07501",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
  },
  {
    id: "07502",
    state: "New Jersey",
    zip: "07502",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
  },
  {
    id: "07503",
    zip: "07503",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
  },
  {
    id: "07504",
    zip: "07504",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
  },
  {
    id: "07505",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07505",
  },
  {
    id: "07506",
    zip: "07506",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
  },
  {
    id: "07507",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07507",
    state: "New Jersey",
  },
  {
    id: "07508",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "07508",
  },
  {
    id: "07509",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
    zip: "07509",
  },
  {
    id: "07510",
    zip: "07510",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
  },
  {
    id: "07511",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07511",
    state: "New Jersey",
  },
  {
    id: "07512",
    state: "New Jersey",
    zip: "07512",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
  },
  {
    id: "07513",
    zip: "07513",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
  },
  {
    id: "07514",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
    zip: "07514",
  },
  {
    id: "07522",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07522",
  },
  {
    id: "07524",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07524",
  },
  {
    id: "07530",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07530",
    state: "New Jersey",
  },
  {
    id: "07533",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
    zip: "07533",
  },
  {
    id: "07538",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07538",
  },
  {
    id: "07543",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "07543",
  },
  {
    id: "07544",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
    zip: "07544",
  },
  {
    id: "07601",
    zip: "07601",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
  },
  {
    id: "07602",
    state: "New Jersey",
    zip: "07602",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "07603",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    zip: "07603",
  },
  {
    id: "07604",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "07604",
  },
  {
    id: "07605",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "07605",
  },
  {
    id: "07606",
    zip: "07606",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "07607",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "07607",
  },
  {
    id: "07608",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "07608",
  },
  {
    id: "07620",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
    zip: "07620",
  },
  {
    id: "07621",
    zip: "07621",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "07624",
    zip: "07624",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
  },
  {
    id: "07626",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "07626",
    state: "New Jersey",
  },
  {
    id: "07627",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07627",
  },
  {
    id: "07628",
    zip: "07628",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
  },
  {
    id: "07630",
    zip: "07630",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
  },
  {
    id: "07631",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "07631",
  },
  {
    id: "07632",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "07632",
    state: "New Jersey",
  },
  {
    id: "07640",
    zip: "07640",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
  },
  {
    id: "07641",
    state: "New Jersey",
    zip: "07641",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "07642",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
    zip: "07642",
  },
  {
    id: "07643",
    state: "New Jersey",
    zip: "07643",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "07644",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07644",
  },
  {
    id: "07645",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07645",
    state: "New Jersey",
  },
  {
    id: "07646",
    zip: "07646",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "07647",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07647",
  },
  {
    id: "07648",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "07648",
  },
  {
    id: "07649",
    zip: "07649",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    state: "New Jersey",
  },
  {
    id: "07650",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07650",
    state: "New Jersey",
  },
  {
    id: "07652",
    zip: "07652",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    state: "New Jersey",
  },
  {
    id: "07653",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07653",
    state: "New Jersey",
  },
  {
    id: "07656",
    zip: "07656",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    state: "New Jersey",
  },
  {
    id: "07657",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "07657",
  },
  {
    id: "07660",
    zip: "07660",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "07661",
    zip: "07661",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "07662",
    zip: "07662",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "07663",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
    zip: "07663",
  },
  {
    id: "07666",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "07666",
  },
  {
    id: "07670",
    state: "New Jersey",
    zip: "07670",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "07675",
    zip: "07675",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07676",
    zip: "07676",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "07688",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "07688",
  },
  {
    id: "07701",
    zip: "07701",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
  },
  {
    id: "07702",
    state: "New Jersey",
    zip: "07702",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "07703",
    state: "New Jersey",
    zip: "07703",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "07704",
    state: "New Jersey",
    zip: "07704",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07709",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "07709",
    state: "New Jersey",
  },
  {
    id: "07710",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "07710",
    state: "New Jersey",
  },
  {
    id: "07711",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    state: "New Jersey",
    zip: "07711",
  },
  {
    id: "07712",
    zip: "07712",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
  },
  {
    id: "07713",
    zip: "07713",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
  },
  {
    id: "07715",
    zip: "07715",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
  },
  {
    id: "07716",
    state: "New Jersey",
    zip: "07716",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "07717",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
    zip: "07717",
  },
  {
    id: "07718",
    state: "New Jersey",
    zip: "07718",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "07719",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "07719",
  },
  {
    id: "07720",
    zip: "07720",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    state: "New Jersey",
  },
  {
    id: "07721",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07721",
    state: "New Jersey",
  },
  {
    id: "07722",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
    zip: "07722",
  },
  {
    id: "07723",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "07723",
    state: "New Jersey",
  },
  {
    id: "07724",
    zip: "07724",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
  },
  {
    id: "07726",
    state: "New Jersey",
    zip: "07726",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07727",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "07727",
    state: "New Jersey",
  },
  {
    id: "07728",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "07728",
  },
  {
    id: "07730",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
    zip: "07730",
  },
  {
    id: "07731",
    state: "New Jersey",
    zip: "07731",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07732",
    zip: "07732",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    state: "New Jersey",
  },
  {
    id: "07733",
    zip: "07733",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
  },
  {
    id: "07734",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "07734",
    state: "New Jersey",
  },
  {
    id: "07735",
    zip: "07735",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "07737",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "07737",
  },
  {
    id: "07738",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07738",
    state: "New Jersey",
  },
  {
    id: "07739",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07739",
    state: "New Jersey",
  },
  {
    id: "07740",
    zip: "07740",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "07746",
    state: "New Jersey",
    zip: "07746",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "07747",
    zip: "07747",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
  },
  {
    id: "07748",
    zip: "07748",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
  },
  {
    id: "07750",
    zip: "07750",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
  },
  {
    id: "07751",
    state: "New Jersey",
    zip: "07751",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07752",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07752",
    state: "New Jersey",
  },
  {
    id: "07753",
    state: "New Jersey",
    zip: "07753",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07754",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07754",
    state: "New Jersey",
  },
  {
    id: "07755",
    zip: "07755",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07756",
    zip: "07756",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07757",
    zip: "07757",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "07758",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "07758",
  },
  {
    id: "07760",
    state: "New Jersey",
    zip: "07760",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "07762",
    state: "New Jersey",
    zip: "07762",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "07763",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "07763",
  },
  {
    id: "07764",
    zip: "07764",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07765",
    state: "New Jersey",
    zip: "07765",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07777",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "07777",
    state: "New Jersey",
  },
  {
    id: "07799",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "07799",
  },
  {
    id: "07801",
    zip: "07801",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "07802",
    zip: "07802",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "07803",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "07803",
    state: "New Jersey",
  },
  {
    id: "07806",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "07806",
  },
  {
    id: "07820",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "07820",
  },
  {
    id: "07821",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
    zip: "07821",
  },
  {
    id: "07822",
    zip: "07822",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "07823",
    zip: "07823",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "07825",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    zip: "07825",
  },
  {
    id: "07826",
    state: "New Jersey",
    zip: "07826",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "07827",
    state: "New Jersey",
    zip: "07827",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07828",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "07828",
    state: "New Jersey",
  },
  {
    id: "07829",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "07829",
    state: "New Jersey",
  },
  {
    id: "07830",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "07830",
  },
  {
    id: "07831",
    zip: "07831",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
  },
  {
    id: "07832",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
    zip: "07832",
  },
  {
    id: "07833",
    zip: "07833",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
  },
  {
    id: "07834",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07834",
  },
  {
    id: "07836",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
    zip: "07836",
  },
  {
    id: "07837",
    state: "New Jersey",
    zip: "07837",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "07838",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "07838",
  },
  {
    id: "07839",
    zip: "07839",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
  },
  {
    id: "07840",
    zip: "07840",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
  },
  {
    id: "07842",
    zip: "07842",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
  },
  {
    id: "07843",
    state: "New Jersey",
    zip: "07843",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07844",
    zip: "07844",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
  },
  {
    id: "07845",
    zip: "07845",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "07846",
    zip: "07846",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
  },
  {
    id: "07847",
    zip: "07847",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
  },
  {
    id: "07848",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
    zip: "07848",
  },
  {
    id: "07849",
    zip: "07849",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "07850",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
    zip: "07850",
  },
  {
    id: "07851",
    zip: "07851",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
  },
  {
    id: "07852",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07852",
  },
  {
    id: "07853",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "07853",
    state: "New Jersey",
  },
  {
    id: "07855",
    state: "New Jersey",
    zip: "07855",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
  },
  {
    id: "07856",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "07856",
    state: "New Jersey",
  },
  {
    id: "07857",
    zip: "07857",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "07860",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "07860",
  },
  {
    id: "07863",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "07863",
  },
  {
    id: "07865",
    state: "New Jersey",
    zip: "07865",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "07866",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07866",
  },
  {
    id: "07869",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "07869",
  },
  {
    id: "07870",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "07870",
  },
  {
    id: "07871",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "07871",
    state: "New Jersey",
  },
  {
    id: "07874",
    zip: "07874",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    state: "New Jersey",
  },
  {
    id: "07875",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    zip: "07875",
  },
  {
    id: "07876",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    zip: "07876",
  },
  {
    id: "07877",
    state: "New Jersey",
    zip: "07877",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "07878",
    zip: "07878",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
  },
  {
    id: "07879",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "07879",
    state: "New Jersey",
  },
  {
    id: "07880",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "07880",
  },
  {
    id: "07881",
    zip: "07881",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
  },
  {
    id: "07882",
    state: "New Jersey",
    zip: "07882",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "07885",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "07885",
    state: "New Jersey",
  },
  {
    id: "07890",
    state: "New Jersey",
    zip: "07890",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "07901",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "07901",
  },
  {
    id: "07902",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    zip: "07902",
    state: "New Jersey",
  },
  {
    id: "07920",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    zip: "07920",
  },
  {
    id: "07921",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
    zip: "07921",
  },
  {
    id: "07922",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "07922",
  },
  {
    id: "07924",
    zip: "07924",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "07926",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "07926",
    state: "New Jersey",
  },
  {
    id: "07927",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
    zip: "07927",
  },
  {
    id: "07928",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07928",
  },
  {
    id: "07930",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "07930",
  },
  {
    id: "07931",
    zip: "07931",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07932",
    zip: "07932",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "07933",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "07933",
  },
  {
    id: "07934",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "07934",
    state: "New Jersey",
  },
  {
    id: "07935",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "07935",
  },
  {
    id: "07936",
    zip: "07936",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
  },
  {
    id: "07938",
    state: "New Jersey",
    zip: "07938",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "07939",
    state: "New Jersey",
    zip: "07939",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "07940",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "07940",
    state: "New Jersey",
  },
  {
    id: "07945",
    state: "New Jersey",
    zip: "07945",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "07946",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "07946",
    state: "New Jersey",
  },
  {
    id: "07950",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "07950",
  },
  {
    id: "07960",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
    zip: "07960",
  },
  {
    id: "07961",
    zip: "07961",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "07962",
    zip: "07962",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
  },
  {
    id: "07964",
    zip: "07964",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07970",
    zip: "07970",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "07974",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "07974",
  },
  {
    id: "07976",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "07976",
  },
  {
    id: "07977",
    zip: "07977",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
  },
  {
    id: "07978",
    zip: "07978",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "07979",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "07979",
  },
  {
    id: "07980",
    state: "New Jersey",
    zip: "07980",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "07981",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "07981",
    state: "New Jersey",
  },
  {
    id: "07999",
    zip: "07999",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08001",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "08001",
  },
  {
    id: "08002",
    zip: "08002",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
  },
  {
    id: "08003",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
    zip: "08003",
  },
  {
    id: "08004",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    zip: "08004",
    state: "New Jersey",
  },
  {
    id: "08005",
    zip: "08005",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
  },
  {
    id: "08006",
    zip: "08006",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "08007",
    zip: "08007",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "08008",
    zip: "08008",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
  },
  {
    id: "08009",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "08009",
  },
  {
    id: "08010",
    zip: "08010",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "08011",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "08011",
  },
  {
    id: "08012",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    zip: "08012",
    state: "New Jersey",
  },
  {
    id: "08014",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08014",
    state: "New Jersey",
  },
  {
    id: "08015",
    state: "New Jersey",
    zip: "08015",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "08016",
    state: "New Jersey",
    zip: "08016",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "08018",
    zip: "08018",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08019",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "08019",
    state: "New Jersey",
  },
  {
    id: "08020",
    state: "New Jersey",
    zip: "08020",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "08021",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "08021",
  },
  {
    id: "08022",
    state: "New Jersey",
    zip: "08022",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "08023",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "08023",
  },
  {
    id: "08025",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "08025",
    state: "New Jersey",
  },
  {
    id: "08026",
    zip: "08026",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08027",
    state: "New Jersey",
    zip: "08027",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08028",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "08028",
  },
  {
    id: "08029",
    state: "New Jersey",
    zip: "08029",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08030",
    zip: "08030",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    state: "New Jersey",
  },
  {
    id: "08031",
    zip: "08031",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "08032",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "08032",
  },
  {
    id: "08033",
    zip: "08033",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "08034",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "08034",
  },
  {
    id: "08035",
    state: "New Jersey",
    zip: "08035",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "08036",
    zip: "08036",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "08037",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    zip: "08037",
    state: "New Jersey",
  },
  {
    id: "08038",
    zip: "08038",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "08039",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
    zip: "08039",
  },
  {
    id: "08040",
    state: "New Jersey",
    zip: "08040",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08041",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "08041",
  },
  {
    id: "08042",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "08042",
    state: "New Jersey",
  },
  {
    id: "08043",
    zip: "08043",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "08045",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08045",
    state: "New Jersey",
  },
  {
    id: "08046",
    zip: "08046",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
  },
  {
    id: "08048",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "08048",
  },
  {
    id: "08049",
    state: "New Jersey",
    zip: "08049",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "08050",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "08050",
  },
  {
    id: "08051",
    zip: "08051",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "08052",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "08052",
  },
  {
    id: "08053",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "08053",
  },
  {
    id: "08054",
    zip: "08054",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "08055",
    state: "New Jersey",
    zip: "08055",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "08056",
    zip: "08056",
    created: {
      seconds: 1726179334,
      nanoseconds: 242000000,
    },
    state: "New Jersey",
  },
  {
    id: "08057",
    state: "New Jersey",
    zip: "08057",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "08059",
    zip: "08059",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "08060",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "08060",
  },
  {
    id: "08061",
    zip: "08061",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
  },
  {
    id: "08062",
    zip: "08062",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    state: "New Jersey",
  },
  {
    id: "08063",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 245000000,
    },
    zip: "08063",
  },
  {
    id: "08064",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08064",
  },
  {
    id: "08065",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
    zip: "08065",
  },
  {
    id: "08066",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "08066",
    state: "New Jersey",
  },
  {
    id: "08067",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "08067",
    state: "New Jersey",
  },
  {
    id: "08068",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    zip: "08068",
    state: "New Jersey",
  },
  {
    id: "08069",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
    zip: "08069",
  },
  {
    id: "08070",
    state: "New Jersey",
    zip: "08070",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "08071",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08071",
  },
  {
    id: "08072",
    zip: "08072",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08073",
    state: "New Jersey",
    zip: "08073",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08074",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "08074",
  },
  {
    id: "08075",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
    zip: "08075",
  },
  {
    id: "08077",
    zip: "08077",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "08078",
    state: "New Jersey",
    zip: "08078",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "08079",
    zip: "08079",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "08080",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    state: "New Jersey",
    zip: "08080",
  },
  {
    id: "08081",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08081",
  },
  {
    id: "08083",
    state: "New Jersey",
    zip: "08083",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08084",
    state: "New Jersey",
    zip: "08084",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08085",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    zip: "08085",
    state: "New Jersey",
  },
  {
    id: "08086",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08086",
  },
  {
    id: "08087",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "08087",
    state: "New Jersey",
  },
  {
    id: "08088",
    state: "New Jersey",
    zip: "08088",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08089",
    state: "New Jersey",
    zip: "08089",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "08090",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "08090",
  },
  {
    id: "08091",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "08091",
  },
  {
    id: "08092",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "08092",
  },
  {
    id: "08093",
    zip: "08093",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "08094",
    zip: "08094",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "08095",
    state: "New Jersey",
    zip: "08095",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
  },
  {
    id: "08096",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    zip: "08096",
  },
  {
    id: "08097",
    zip: "08097",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
  },
  {
    id: "08098",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
    state: "New Jersey",
    zip: "08098",
  },
  {
    id: "08100",
    zip: "08100",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08101",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "08101",
    state: "New Jersey",
  },
  {
    id: "08102",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
    zip: "08102",
  },
  {
    id: "08103",
    state: "New Jersey",
    zip: "08103",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08104",
    zip: "08104",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
  },
  {
    id: "08105",
    zip: "08105",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08106",
    state: "New Jersey",
    zip: "08106",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "08107",
    state: "New Jersey",
    zip: "08107",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08108",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "08108",
    state: "New Jersey",
  },
  {
    id: "08109",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    zip: "08109",
  },
  {
    id: "08110",
    state: "New Jersey",
    zip: "08110",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08201",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 223000000,
    },
    zip: "08201",
  },
  {
    id: "08202",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    zip: "08202",
  },
  {
    id: "08203",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    state: "New Jersey",
    zip: "08203",
  },
  {
    id: "08204",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
    zip: "08204",
  },
  {
    id: "08205",
    zip: "08205",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08210",
    zip: "08210",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
    state: "New Jersey",
  },
  {
    id: "08212",
    zip: "08212",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 229000000,
    },
  },
  {
    id: "08213",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "08213",
  },
  {
    id: "08214",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "08214",
  },
  {
    id: "08215",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "08215",
  },
  {
    id: "08217",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "08217",
    state: "New Jersey",
  },
  {
    id: "08218",
    state: "New Jersey",
    zip: "08218",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "08219",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "08219",
  },
  {
    id: "08220",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08220",
    state: "New Jersey",
  },
  {
    id: "08221",
    state: "New Jersey",
    zip: "08221",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "08222",
    zip: "08222",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "08223",
    zip: "08223",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
  },
  {
    id: "08224",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "08224",
    state: "New Jersey",
  },
  {
    id: "08225",
    zip: "08225",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "08226",
    state: "New Jersey",
    zip: "08226",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "08227",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08227",
  },
  {
    id: "08230",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "08230",
    state: "New Jersey",
  },
  {
    id: "08231",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "08231",
    state: "New Jersey",
  },
  {
    id: "08232",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
    zip: "08232",
  },
  {
    id: "08233",
    state: "New Jersey",
    zip: "08233",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "08234",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "08234",
    state: "New Jersey",
  },
  {
    id: "08240",
    zip: "08240",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
  },
  {
    id: "08241",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "08241",
    state: "New Jersey",
  },
  {
    id: "08242",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "08242",
  },
  {
    id: "08243",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "08243",
  },
  {
    id: "08244",
    zip: "08244",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08245",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08245",
    state: "New Jersey",
  },
  {
    id: "08246",
    zip: "08246",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08247",
    zip: "08247",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
  },
  {
    id: "08248",
    zip: "08248",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
  },
  {
    id: "08250",
    state: "New Jersey",
    zip: "08250",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08251",
    zip: "08251",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "08252",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "08252",
    state: "New Jersey",
  },
  {
    id: "08260",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "08260",
  },
  {
    id: "08270",
    state: "New Jersey",
    zip: "08270",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
  },
  {
    id: "08302",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08302",
  },
  {
    id: "08310",
    state: "New Jersey",
    zip: "08310",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "08311",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
    zip: "08311",
  },
  {
    id: "08312",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    zip: "08312",
    state: "New Jersey",
  },
  {
    id: "08313",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "08313",
  },
  {
    id: "08314",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
    zip: "08314",
  },
  {
    id: "08315",
    zip: "08315",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "08316",
    zip: "08316",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
  },
  {
    id: "08317",
    zip: "08317",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "08318",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    zip: "08318",
  },
  {
    id: "08319",
    zip: "08319",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "08320",
    zip: "08320",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
  },
  {
    id: "08321",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "08321",
    state: "New Jersey",
  },
  {
    id: "08322",
    zip: "08322",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08323",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    zip: "08323",
  },
  {
    id: "08324",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08324",
  },
  {
    id: "08326",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "08326",
    state: "New Jersey",
  },
  {
    id: "08327",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "08327",
  },
  {
    id: "08328",
    zip: "08328",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
  },
  {
    id: "08329",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "08329",
  },
  {
    id: "08330",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "08330",
  },
  {
    id: "08332",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "08332",
  },
  {
    id: "08340",
    zip: "08340",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    state: "New Jersey",
  },
  {
    id: "08341",
    zip: "08341",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    state: "New Jersey",
  },
  {
    id: "08342",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "08342",
  },
  {
    id: "08343",
    state: "New Jersey",
    zip: "08343",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
  },
  {
    id: "08344",
    state: "New Jersey",
    zip: "08344",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "08345",
    state: "New Jersey",
    zip: "08345",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
  },
  {
    id: "08346",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "08346",
  },
  {
    id: "08347",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 247000000,
    },
    zip: "08347",
  },
  {
    id: "08348",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08348",
    state: "New Jersey",
  },
  {
    id: "08349",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08349",
    state: "New Jersey",
  },
  {
    id: "08350",
    state: "New Jersey",
    zip: "08350",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08352",
    zip: "08352",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "08353",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08353",
    state: "New Jersey",
  },
  {
    id: "08358",
    zip: "08358",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
  },
  {
    id: "08360",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08360",
  },
  {
    id: "08370",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "08370",
  },
  {
    id: "08400",
    state: "New Jersey",
    zip: "08400",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "08401",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    zip: "08401",
  },
  {
    id: "08402",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
    zip: "08402",
  },
  {
    id: "08403",
    state: "New Jersey",
    zip: "08403",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "08404",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    zip: "08404",
    state: "New Jersey",
  },
  {
    id: "08405",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    zip: "08405",
  },
  {
    id: "08406",
    zip: "08406",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
  },
  {
    id: "08411",
    zip: "08411",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "08501",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    zip: "08501",
    state: "New Jersey",
  },
  {
    id: "08502",
    zip: "08502",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "08504",
    zip: "08504",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
  },
  {
    id: "08505",
    state: "New Jersey",
    zip: "08505",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
  },
  {
    id: "08510",
    zip: "08510",
    created: {
      seconds: 1726179334,
      nanoseconds: 230000000,
    },
    state: "New Jersey",
  },
  {
    id: "08511",
    state: "New Jersey",
    zip: "08511",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "08512",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "08512",
    state: "New Jersey",
  },
  {
    id: "08514",
    zip: "08514",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
  },
  {
    id: "08515",
    state: "New Jersey",
    zip: "08515",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "08518",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
    zip: "08518",
  },
  {
    id: "08520",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
    zip: "08520",
  },
  {
    id: "08525",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
    zip: "08525",
  },
  {
    id: "08526",
    zip: "08526",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "08527",
    state: "New Jersey",
    zip: "08527",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "08528",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    state: "New Jersey",
    zip: "08528",
  },
  {
    id: "08530",
    state: "New Jersey",
    zip: "08530",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08533",
    state: "New Jersey",
    zip: "08533",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "08534",
    created: {
      seconds: 1726179334,
      nanoseconds: 250000000,
    },
    state: "New Jersey",
    zip: "08534",
  },
  {
    id: "08535",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08535",
    state: "New Jersey",
  },
  {
    id: "08536",
    zip: "08536",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "08540",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "08540",
    state: "New Jersey",
  },
  {
    id: "08541",
    state: "New Jersey",
    zip: "08541",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08542",
    state: "New Jersey",
    zip: "08542",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08543",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "08543",
  },
  {
    id: "08544",
    zip: "08544",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08550",
    state: "New Jersey",
    zip: "08550",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08551",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "08551",
  },
  {
    id: "08553",
    zip: "08553",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "08554",
    zip: "08554",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "08555",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "08555",
    state: "New Jersey",
  },
  {
    id: "08556",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    zip: "08556",
  },
  {
    id: "08557",
    zip: "08557",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    state: "New Jersey",
  },
  {
    id: "08558",
    state: "New Jersey",
    zip: "08558",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08559",
    state: "New Jersey",
    zip: "08559",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08560",
    zip: "08560",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08561",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    zip: "08561",
  },
  {
    id: "08562",
    state: "New Jersey",
    zip: "08562",
    created: {
      seconds: 1726179334,
      nanoseconds: 258000000,
    },
  },
  {
    id: "08570",
    zip: "08570",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    state: "New Jersey",
  },
  {
    id: "08600",
    zip: "08600",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "08601",
    state: "New Jersey",
    zip: "08601",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08602",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "08602",
  },
  {
    id: "08603",
    zip: "08603",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "08604",
    zip: "08604",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08605",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "08605",
  },
  {
    id: "08606",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
    zip: "08606",
  },
  {
    id: "08607",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
    zip: "08607",
  },
  {
    id: "08608",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
    zip: "08608",
  },
  {
    id: "08609",
    zip: "08609",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
  },
  {
    id: "08610",
    created: {
      seconds: 1726179334,
      nanoseconds: 236000000,
    },
    state: "New Jersey",
    zip: "08610",
  },
  {
    id: "08611",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08611",
    state: "New Jersey",
  },
  {
    id: "08618",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
    zip: "08618",
  },
  {
    id: "08619",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
    zip: "08619",
  },
  {
    id: "08620",
    state: "New Jersey",
    zip: "08620",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "08625",
    zip: "08625",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08628",
    zip: "08628",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "08629",
    state: "New Jersey",
    zip: "08629",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "08638",
    zip: "08638",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
  },
  {
    id: "08640",
    zip: "08640",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    state: "New Jersey",
  },
  {
    id: "08641",
    state: "New Jersey",
    zip: "08641",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "08645",
    state: "New Jersey",
    zip: "08645",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08646",
    zip: "08646",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
  },
  {
    id: "08647",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
    zip: "08647",
  },
  {
    id: "08648",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    state: "New Jersey",
    zip: "08648",
  },
  {
    id: "08650",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08650",
    state: "New Jersey",
  },
  {
    id: "08666",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
    zip: "08666",
  },
  {
    id: "08677",
    zip: "08677",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    state: "New Jersey",
  },
  {
    id: "08690",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08690",
    state: "New Jersey",
  },
  {
    id: "08691",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08691",
    state: "New Jersey",
  },
  {
    id: "08695",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 256000000,
    },
    zip: "08695",
  },
  {
    id: "08701",
    state: "New Jersey",
    zip: "08701",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08720",
    zip: "08720",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
  },
  {
    id: "08721",
    zip: "08721",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
  },
  {
    id: "08722",
    zip: "08722",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
  },
  {
    id: "08723",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08723",
    state: "New Jersey",
  },
  {
    id: "08724",
    state: "New Jersey",
    zip: "08724",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "08730",
    zip: "08730",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
  },
  {
    id: "08731",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "08731",
    state: "New Jersey",
  },
  {
    id: "08732",
    state: "New Jersey",
    zip: "08732",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
  },
  {
    id: "08733",
    state: "New Jersey",
    zip: "08733",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08734",
    zip: "08734",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08735",
    zip: "08735",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
  },
  {
    id: "08736",
    zip: "08736",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
  },
  {
    id: "08738",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "08738",
  },
  {
    id: "08739",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "08739",
  },
  {
    id: "08740",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    zip: "08740",
  },
  {
    id: "08741",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08741",
    state: "New Jersey",
  },
  {
    id: "08742",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    zip: "08742",
  },
  {
    id: "08750",
    zip: "08750",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "08751",
    zip: "08751",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "08752",
    state: "New Jersey",
    zip: "08752",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "08753",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08753",
  },
  {
    id: "08754",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08754",
  },
  {
    id: "08755",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08755",
  },
  {
    id: "08756",
    state: "New Jersey",
    zip: "08756",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08757",
    zip: "08757",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
  },
  {
    id: "08758",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "08758",
  },
  {
    id: "08759",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "08759",
    state: "New Jersey",
  },
  {
    id: "08801",
    zip: "08801",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
    state: "New Jersey",
  },
  {
    id: "08802",
    state: "New Jersey",
    zip: "08802",
    created: {
      seconds: 1726179334,
      nanoseconds: 225000000,
    },
  },
  {
    id: "08803",
    created: {
      seconds: 1726179334,
      nanoseconds: 226000000,
    },
    state: "New Jersey",
    zip: "08803",
  },
  {
    id: "08804",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "08804",
  },
  {
    id: "08805",
    created: {
      seconds: 1726179334,
      nanoseconds: 227000000,
    },
    state: "New Jersey",
    zip: "08805",
  },
  {
    id: "08807",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08807",
  },
  {
    id: "08808",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08808",
  },
  {
    id: "08809",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
    zip: "08809",
    state: "New Jersey",
  },
  {
    id: "08810",
    zip: "08810",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 231000000,
    },
  },
  {
    id: "08812",
    zip: "08812",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    state: "New Jersey",
  },
  {
    id: "08816",
    zip: "08816",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
  },
  {
    id: "08817",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 232000000,
    },
    zip: "08817",
  },
  {
    id: "08818",
    state: "New Jersey",
    zip: "08818",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "08820",
    state: "New Jersey",
    zip: "08820",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
  },
  {
    id: "08821",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "08821",
  },
  {
    id: "08822",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "08822",
    state: "New Jersey",
  },
  {
    id: "08823",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "08823",
  },
  {
    id: "08824",
    state: "New Jersey",
    zip: "08824",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
  },
  {
    id: "08825",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
    zip: "08825",
  },
  {
    id: "08826",
    state: "New Jersey",
    zip: "08826",
    created: {
      seconds: 1726179334,
      nanoseconds: 235000000,
    },
  },
  {
    id: "08827",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    zip: "08827",
  },
  {
    id: "08828",
    zip: "08828",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
  },
  {
    id: "08829",
    zip: "08829",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
  },
  {
    id: "08830",
    zip: "08830",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    state: "New Jersey",
  },
  {
    id: "08831",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 238000000,
    },
    zip: "08831",
  },
  {
    id: "08832",
    created: {
      seconds: 1726179334,
      nanoseconds: 239000000,
    },
    zip: "08832",
    state: "New Jersey",
  },
  {
    id: "08833",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08833",
  },
  {
    id: "08834",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08834",
    state: "New Jersey",
  },
  {
    id: "08835",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    zip: "08835",
    state: "New Jersey",
  },
  {
    id: "08836",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
    state: "New Jersey",
    zip: "08836",
  },
  {
    id: "08837",
    created: {
      seconds: 1726179334,
      nanoseconds: 233000000,
    },
    state: "New Jersey",
    zip: "08837",
  },
  {
    id: "08840",
    zip: "08840",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 241000000,
    },
  },
  {
    id: "08844",
    zip: "08844",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
  },
  {
    id: "08846",
    zip: "08846",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 242000000,
    },
  },
  {
    id: "08848",
    zip: "08848",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
  },
  {
    id: "08850",
    zip: "08850",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    state: "New Jersey",
  },
  {
    id: "08852",
    created: {
      seconds: 1726179334,
      nanoseconds: 244000000,
    },
    zip: "08852",
    state: "New Jersey",
  },
  {
    id: "08853",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08853",
  },
  {
    id: "08854",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    zip: "08854",
  },
  {
    id: "08855",
    zip: "08855",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "08857",
    created: {
      seconds: 1726179334,
      nanoseconds: 228000000,
    },
    zip: "08857",
    state: "New Jersey",
  },
  {
    id: "08858",
    state: "New Jersey",
    zip: "08858",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
  },
  {
    id: "08859",
    zip: "08859",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 249000000,
    },
  },
  {
    id: "08861",
    zip: "08861",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "08862",
    zip: "08862",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "08863",
    created: {
      seconds: 1726179334,
      nanoseconds: 234000000,
    },
    zip: "08863",
    state: "New Jersey",
  },
  {
    id: "08865",
    created: {
      seconds: 1726179334,
      nanoseconds: 224000000,
    },
    state: "New Jersey",
    zip: "08865",
  },
  {
    id: "08867",
    zip: "08867",
    created: {
      seconds: 1726179334,
      nanoseconds: 251000000,
    },
    state: "New Jersey",
  },
  {
    id: "08868",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    zip: "08868",
    state: "New Jersey",
  },
  {
    id: "08869",
    zip: "08869",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
  },
  {
    id: "08870",
    created: {
      seconds: 1726179334,
      nanoseconds: 252000000,
    },
    state: "New Jersey",
    zip: "08870",
  },
  {
    id: "08871",
    zip: "08871",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
    state: "New Jersey",
  },
  {
    id: "08872",
    zip: "08872",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 253000000,
    },
  },
  {
    id: "08873",
    state: "New Jersey",
    zip: "08873",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08875",
    zip: "08875",
    created: {
      seconds: 1726179334,
      nanoseconds: 242000000,
    },
    state: "New Jersey",
  },
  {
    id: "08876",
    zip: "08876",
    created: {
      seconds: 1726179334,
      nanoseconds: 248000000,
    },
    state: "New Jersey",
  },
  {
    id: "08877",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08877",
  },
  {
    id: "08878",
    zip: "08878",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    state: "New Jersey",
  },
  {
    id: "08879",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 240000000,
    },
    zip: "08879",
  },
  {
    id: "08880",
    zip: "08880",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08882",
    zip: "08882",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08884",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
    zip: "08884",
  },
  {
    id: "08885",
    state: "New Jersey",
    zip: "08885",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08886",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08886",
  },
  {
    id: "08887",
    created: {
      seconds: 1726179334,
      nanoseconds: 255000000,
    },
    state: "New Jersey",
    zip: "08887",
  },
  {
    id: "08888",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
    zip: "08888",
  },
  {
    id: "08889",
    zip: "08889",
    created: {
      seconds: 1726179334,
      nanoseconds: 257000000,
    },
    state: "New Jersey",
  },
  {
    id: "08890",
    zip: "08890",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 254000000,
    },
  },
  {
    id: "08899",
    state: "New Jersey",
    zip: "08899",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "08901",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "08901",
  },
  {
    id: "08902",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08902",
  },
  {
    id: "08903",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08903",
  },
  {
    id: "08904",
    zip: "08904",
    created: {
      seconds: 1726179334,
      nanoseconds: 237000000,
    },
    state: "New Jersey",
  },
  {
    id: "08905",
    zip: "08905",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "08906",
    state: "New Jersey",
    zip: "08906",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "08922",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08922",
  },
  {
    id: "08933",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    state: "New Jersey",
    zip: "08933",
  },
  {
    id: "08988",
    zip: "08988",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
  },
  {
    id: "08989",
    state: "New Jersey",
    created: {
      seconds: 1726179334,
      nanoseconds: 246000000,
    },
    zip: "08989",
  },
  {
    id: "10001",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    county: "Manhattan",
    zip: "10001",
    state: "New York",
  },
  {
    id: "10002",
    county: "Manhattan",
    state: "New York",
    zip: "10002",
    created: {
      seconds: 1726180049,
      nanoseconds: 158000000,
    },
  },
  {
    id: "10003",
    zip: "10003",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10004",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    zip: "10004",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10005",
    state: "New York",
    zip: "10005",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    county: "Manhattan",
  },
  {
    id: "10006",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    county: "Manhattan",
    zip: "10006",
    state: "New York",
  },
  {
    id: "10007",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    zip: "10007",
  },
  {
    id: "10008",
    zip: "10008",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
  },
  {
    id: "10009",
    county: "Manhattan",
    zip: "10009",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
  },
  {
    id: "10010",
    zip: "10010",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
  },
  {
    id: "10011",
    zip: "10011",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
  },
  {
    id: "10012",
    county: "Manhattan",
    state: "New York",
    zip: "10012",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
  },
  {
    id: "10013",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    zip: "10013",
    county: "Manhattan",
  },
  {
    id: "10014",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    state: "New York",
    zip: "10014",
  },
  {
    id: "10015",
    zip: "10015",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    county: "Manhattan",
  },
  {
    id: "10016",
    zip: "10016",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10017",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    state: "New York",
    zip: "10017",
  },
  {
    id: "10018",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10018",
  },
  {
    id: "10019",
    state: "New York",
    zip: "10019",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    county: "Manhattan",
  },
  {
    id: "10020",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    zip: "10020",
    state: "New York",
  },
  {
    id: "10021",
    state: "New York",
    county: "Manhattan",
    zip: "10021",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
  },
  {
    id: "10022",
    state: "New York",
    zip: "10022",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
  },
  {
    id: "10023",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    zip: "10023",
  },
  {
    id: "10024",
    zip: "10024",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 158000000,
    },
  },
  {
    id: "10025",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 157000000,
    },
    zip: "10025",
  },
  {
    id: "10026",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10026",
  },
  {
    id: "10027",
    created: {
      seconds: 1726180049,
      nanoseconds: 158000000,
    },
    state: "New York",
    zip: "10027",
    county: "Manhattan",
  },
  {
    id: "10028",
    zip: "10028",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
  },
  {
    id: "10029",
    state: "New York",
    county: "Manhattan",
    zip: "10029",
    created: {
      seconds: 1726180049,
      nanoseconds: 158000000,
    },
  },
  {
    id: "10030",
    zip: "10030",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10031",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    zip: "10031",
    county: "Manhattan",
  },
  {
    id: "10032",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    zip: "10032",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10033",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    zip: "10033",
  },
  {
    id: "10034",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    state: "New York",
    zip: "10034",
    county: "Manhattan",
  },
  {
    id: "10035",
    zip: "10035",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    state: "New York",
  },
  {
    id: "10036",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    state: "New York",
    zip: "10036",
    county: "Manhattan",
  },
  {
    id: "10037",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    zip: "10037",
  },
  {
    id: "10038",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10038",
  },
  {
    id: "10039",
    zip: "10039",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10040",
    created: {
      seconds: 1726180049,
      nanoseconds: 160000000,
    },
    county: "Manhattan",
    zip: "10040",
    state: "New York",
  },
  {
    id: "10041",
    zip: "10041",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
  },
  {
    id: "10043",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    state: "New York",
    zip: "10043",
  },
  {
    id: "10044",
    zip: "10044",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
  },
  {
    id: "10045",
    county: "Manhattan",
    zip: "10045",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    state: "New York",
  },
  {
    id: "10046",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    state: "New York",
    zip: "10046",
    county: "Manhattan",
  },
  {
    id: "10047",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    zip: "10047",
    county: "Manhattan",
  },
  {
    id: "10048",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    zip: "10048",
    state: "New York",
  },
  {
    id: "10055",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    zip: "10055",
    county: "Manhattan",
  },
  {
    id: "10060",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    state: "New York",
    zip: "10060",
    county: "Manhattan",
  },
  {
    id: "10065",
    state: "New York",
    zip: "10065",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
  },
  {
    id: "10069",
    zip: "10069",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10072",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10072",
  },
  {
    id: "10075",
    state: "New York",
    zip: "10075",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 161000000,
    },
  },
  {
    id: "10079",
    zip: "10079",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10080",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
    zip: "10080",
    state: "New York",
  },
  {
    id: "10081",
    zip: "10081",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
  },
  {
    id: "10082",
    zip: "10082",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    state: "New York",
  },
  {
    id: "10087",
    zip: "10087",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10090",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    zip: "10090",
    county: "Manhattan",
  },
  {
    id: "10094",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    state: "New York",
    zip: "10094",
    county: "Manhattan",
  },
  {
    id: "10095",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    zip: "10095",
  },
  {
    id: "10096",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    zip: "10096",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10098",
    zip: "10098",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10099",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    zip: "10099",
    county: "Manhattan",
  },
  {
    id: "10101",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10101",
  },
  {
    id: "10102",
    state: "New York",
    zip: "10102",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10103",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10103",
  },
  {
    id: "10104",
    zip: "10104",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10105",
    state: "New York",
    zip: "10105",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    county: "Manhattan",
  },
  {
    id: "10106",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    zip: "10106",
    state: "New York",
  },
  {
    id: "10107",
    zip: "10107",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    county: "Manhattan",
  },
  {
    id: "10108",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    zip: "10108",
  },
  {
    id: "10109",
    zip: "10109",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    county: "Manhattan",
  },
  {
    id: "10110",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10110",
  },
  {
    id: "10111",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    zip: "10111",
    state: "New York",
  },
  {
    id: "10112",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10112",
  },
  {
    id: "10113",
    state: "New York",
    zip: "10113",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    county: "Manhattan",
  },
  {
    id: "10114",
    zip: "10114",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10115",
    county: "Manhattan",
    state: "New York",
    zip: "10115",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10116",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10116",
  },
  {
    id: "10117",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10117",
  },
  {
    id: "10118",
    state: "New York",
    zip: "10118",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10119",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    zip: "10119",
  },
  {
    id: "10120",
    county: "Manhattan",
    zip: "10120",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    state: "New York",
  },
  {
    id: "10121",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    state: "New York",
    county: "Manhattan",
    zip: "10121",
  },
  {
    id: "10122",
    zip: "10122",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10123",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    zip: "10123",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10124",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
    zip: "10124",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10125",
    state: "New York",
    zip: "10125",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 167000000,
    },
  },
  {
    id: "10126",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    state: "New York",
    zip: "10126",
  },
  {
    id: "10128",
    created: {
      seconds: 1726180049,
      nanoseconds: 159000000,
    },
    zip: "10128",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10129",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    zip: "10129",
    county: "Manhattan",
  },
  {
    id: "10130",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    zip: "10130",
  },
  {
    id: "10131",
    state: "New York",
    zip: "10131",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    county: "Manhattan",
  },
  {
    id: "10132",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    zip: "10132",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10133",
    zip: "10133",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10138",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    state: "New York",
    zip: "10138",
    county: "Manhattan",
  },
  {
    id: "10149",
    zip: "10149",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10150",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    county: "Manhattan",
    zip: "10150",
    state: "New York",
  },
  {
    id: "10151",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    zip: "10151",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10152",
    zip: "10152",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    county: "Manhattan",
  },
  {
    id: "10153",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
    zip: "10153",
    state: "New York",
  },
  {
    id: "10154",
    state: "New York",
    zip: "10154",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
  },
  {
    id: "10155",
    state: "New York",
    zip: "10155",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
  },
  {
    id: "10156",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    state: "New York",
    zip: "10156",
    county: "Manhattan",
  },
  {
    id: "10157",
    county: "Manhattan",
    zip: "10157",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    state: "New York",
  },
  {
    id: "10158",
    zip: "10158",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
  },
  {
    id: "10159",
    zip: "10159",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
  },
  {
    id: "10160",
    zip: "10160",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10161",
    state: "New York",
    county: "Manhattan",
    zip: "10161",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
  },
  {
    id: "10162",
    county: "Manhattan",
    state: "New York",
    zip: "10162",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
  },
  {
    id: "10163",
    created: {
      seconds: 1726180049,
      nanoseconds: 168000000,
    },
    county: "Manhattan",
    zip: "10163",
    state: "New York",
  },
  {
    id: "10164",
    county: "Manhattan",
    state: "New York",
    zip: "10164",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10165",
    zip: "10165",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
  },
  {
    id: "10166",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
    zip: "10166",
  },
  {
    id: "10167",
    zip: "10167",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10168",
    zip: "10168",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
  },
  {
    id: "10169",
    county: "Manhattan",
    zip: "10169",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    state: "New York",
  },
  {
    id: "10170",
    state: "New York",
    zip: "10170",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
  },
  {
    id: "10171",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
    zip: "10171",
    state: "New York",
  },
  {
    id: "10172",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    county: "Manhattan",
    zip: "10172",
    state: "New York",
  },
  {
    id: "10173",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    state: "New York",
    zip: "10173",
  },
  {
    id: "10174",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
    zip: "10174",
    state: "New York",
  },
  {
    id: "10175",
    zip: "10175",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    state: "New York",
  },
  {
    id: "10176",
    zip: "10176",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
  },
  {
    id: "10177",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    zip: "10177",
    county: "Manhattan",
  },
  {
    id: "10178",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    zip: "10178",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10179",
    zip: "10179",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    county: "Manhattan",
  },
  {
    id: "10184",
    state: "New York",
    zip: "10184",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    county: "Manhattan",
  },
  {
    id: "10185",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    zip: "10185",
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10196",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    zip: "10196",
    state: "New York",
  },
  {
    id: "10197",
    zip: "10197",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
  },
  {
    id: "10199",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    zip: "10199",
  },
  {
    id: "10203",
    state: "New York",
    county: "Manhattan",
    zip: "10203",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10211",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    zip: "10211",
    state: "New York",
  },
  {
    id: "10212",
    county: "Manhattan",
    zip: "10212",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    state: "New York",
  },
  {
    id: "10213",
    zip: "10213",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    state: "New York",
  },
  {
    id: "10242",
    zip: "10242",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    state: "New York",
  },
  {
    id: "10249",
    state: "New York",
    zip: "10249",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    county: "Manhattan",
  },
  {
    id: "10256",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    zip: "10256",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10257",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    zip: "10257",
    state: "New York",
  },
  {
    id: "10258",
    zip: "10258",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10259",
    county: "Manhattan",
    state: "New York",
    zip: "10259",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10260",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    zip: "10260",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10261",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    county: "Manhattan",
    zip: "10261",
    state: "New York",
  },
  {
    id: "10265",
    zip: "10265",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10268",
    zip: "10268",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
    state: "New York",
  },
  {
    id: "10269",
    zip: "10269",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 169000000,
    },
  },
  {
    id: "10270",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10270",
  },
  {
    id: "10271",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 164000000,
    },
    county: "Manhattan",
    zip: "10271",
  },
  {
    id: "10272",
    county: "Manhattan",
    zip: "10272",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    state: "New York",
  },
  {
    id: "10273",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    county: "Manhattan",
    zip: "10273",
    state: "New York",
  },
  {
    id: "10274",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    county: "Manhattan",
    state: "New York",
    zip: "10274",
  },
  {
    id: "10275",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    state: "New York",
    zip: "10275",
  },
  {
    id: "10276",
    zip: "10276",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
  },
  {
    id: "10277",
    zip: "10277",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
    state: "New York",
  },
  {
    id: "10278",
    created: {
      seconds: 1726180049,
      nanoseconds: 166000000,
    },
    zip: "10278",
    state: "New York",
    county: "Manhattan",
  },
  {
    id: "10279",
    created: {
      seconds: 1726180049,
      nanoseconds: 163000000,
    },
    county: "Manhattan",
    zip: "10279",
    state: "New York",
  },
  {
    id: "10280",
    zip: "10280",
    state: "New York",
    county: "Manhattan",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
  },
  {
    id: "10281",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    state: "New York",
    zip: "10281",
    county: "Manhattan",
  },
  {
    id: "10282",
    zip: "10282",
    created: {
      seconds: 1726180049,
      nanoseconds: 162000000,
    },
    county: "Manhattan",
    state: "New York",
  },
  {
    id: "10285",
    county: "Manhattan",
    state: "New York",
    zip: "10285",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
  },
  {
    id: "10286",
    zip: "10286",
    county: "Manhattan",
    state: "New York",
    created: {
      seconds: 1726180049,
      nanoseconds: 170000000,
    },
  },
  {
    id: "10292",
    created: {
      seconds: 1726180049,
      nanoseconds: 165000000,
    },
    county: "Manhattan",
    zip: "10292",
    state: "New York",
  },
  {
    id: "10301",
    created: {
      seconds: 1726180295,
      nanoseconds: 30000000,
    },
    state: "New York",
    zip: "10301",
    county: "Staten Island",
  },
  {
    id: "10302",
    state: "New York",
    zip: "10302",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    county: "Staten Island",
  },
  {
    id: "10303",
    state: "New York",
    county: "Staten Island",
    zip: "10303",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
  },
  {
    id: "10304",
    zip: "10304",
    state: "New York",
    county: "Staten Island",
    created: {
      seconds: 1726180295,
      nanoseconds: 30000000,
    },
  },
  {
    id: "10305",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 30000000,
    },
    zip: "10305",
    county: "Staten Island",
  },
  {
    id: "10306",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 30000000,
    },
    zip: "10306",
    county: "Staten Island",
  },
  {
    id: "10307",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    state: "New York",
    zip: "10307",
    county: "Staten Island",
  },
  {
    id: "10308",
    county: "Staten Island",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    zip: "10308",
  },
  {
    id: "10309",
    county: "Staten Island",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    zip: "10309",
  },
  {
    id: "10310",
    state: "New York",
    county: "Staten Island",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    zip: "10310",
  },
  {
    id: "10311",
    zip: "10311",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
    state: "New York",
    county: "Staten Island",
  },
  {
    id: "10312",
    county: "Staten Island",
    zip: "10312",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 30000000,
    },
  },
  {
    id: "10313",
    state: "New York",
    zip: "10313",
    county: "Staten Island",
    created: {
      seconds: 1726180295,
      nanoseconds: 31000000,
    },
  },
  {
    id: "10314",
    county: "Staten Island",
    state: "New York",
    created: {
      seconds: 1726180295,
      nanoseconds: 29000000,
    },
    zip: "10314",
  },
  {
    id: "10451",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    county: "Bronx",
    state: "New York",
    zip: "10451",
  },
  {
    id: "10452",
    county: "Bronx",
    state: "New York",
    zip: "10452",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
  },
  {
    id: "10453",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
    county: "Bronx",
    state: "New York",
    zip: "10453",
  },
  {
    id: "10454",
    state: "New York",
    zip: "10454",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
  },
  {
    id: "10455",
    zip: "10455",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
    county: "Bronx",
  },
  {
    id: "10456",
    zip: "10456",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
    county: "Bronx",
  },
  {
    id: "10457",
    county: "Bronx",
    zip: "10457",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
    state: "New York",
  },
  {
    id: "10458",
    state: "New York",
    zip: "10458",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
    county: "Bronx",
  },
  {
    id: "10459",
    zip: "10459",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    county: "Bronx",
  },
  {
    id: "10460",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    zip: "10460",
    county: "Bronx",
  },
  {
    id: "10461",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    state: "New York",
    county: "Bronx",
    zip: "10461",
  },
  {
    id: "10462",
    zip: "10462",
    state: "New York",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
  },
  {
    id: "10463",
    zip: "10463",
    state: "New York",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
  },
  {
    id: "10464",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
    state: "New York",
    zip: "10464",
  },
  {
    id: "10465",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    zip: "10465",
    county: "Bronx",
    state: "New York",
  },
  {
    id: "10466",
    zip: "10466",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    state: "New York",
  },
  {
    id: "10467",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 119000000,
    },
    county: "Bronx",
    zip: "10467",
  },
  {
    id: "10468",
    created: {
      seconds: 1726180245,
      nanoseconds: 120000000,
    },
    zip: "10468",
    state: "New York",
    county: "Bronx",
  },
  {
    id: "10469",
    zip: "10469",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    state: "New York",
  },
  {
    id: "10470",
    county: "Bronx",
    state: "New York",
    zip: "10470",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
  },
  {
    id: "10471",
    zip: "10471",
    county: "Bronx",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
  },
  {
    id: "10472",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    county: "Bronx",
    state: "New York",
    zip: "10472",
  },
  {
    id: "10473",
    state: "New York",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    zip: "10473",
    county: "Bronx",
  },
  {
    id: "10474",
    zip: "10474",
    county: "Bronx",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
    state: "New York",
  },
  {
    id: "10475",
    county: "Bronx",
    zip: "10475",
    created: {
      seconds: 1726180245,
      nanoseconds: 121000000,
    },
    state: "New York",
  },
  {
    id: "10499",
    zip: "10499",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
    county: "Bronx",
    state: "New York",
  },
  {
    id: "10803",
    created: {
      seconds: 1726180245,
      nanoseconds: 122000000,
    },
    zip: "10803",
    state: "New York",
    county: "Bronx",
  },
  {
    id: "11001",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    zip: "11001",
    county: "Queens",
    state: "New York",
  },
  {
    id: "11002",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 792000000,
    },
    zip: "11002",
    county: "Nassau County",
  },
  {
    id: "11003",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 793000000,
    },
    zip: "11003",
    county: "Nassau County",
  },
  {
    id: "11004",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11004",
  },
  {
    id: "11005",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    zip: "11005",
    state: "New York",
    county: "Queens",
  },
  {
    id: "11010",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 793000000,
    },
    zip: "11010",
  },
  {
    id: "11020",
    created: {
      seconds: 1726179792,
      nanoseconds: 793000000,
    },
    zip: "11020",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11021",
    zip: "11021",
    created: {
      seconds: 1726179792,
      nanoseconds: 794000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11022",
    county: "Nassau County",
    state: "New York",
    zip: "11022",
    created: {
      seconds: 1726179792,
      nanoseconds: 794000000,
    },
  },
  {
    id: "11023",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 794000000,
    },
    zip: "11023",
    county: "Nassau County",
  },
  {
    id: "11024",
    zip: "11024",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 794000000,
    },
  },
  {
    id: "11025",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 794000000,
    },
    state: "New York",
    zip: "11025",
  },
  {
    id: "11026",
    zip: "11026",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 795000000,
    },
  },
  {
    id: "11027",
    state: "New York",
    zip: "11027",
    created: {
      seconds: 1726179792,
      nanoseconds: 795000000,
    },
    county: "Nassau County",
  },
  {
    id: "11030",
    zip: "11030",
    created: {
      seconds: 1726179792,
      nanoseconds: 795000000,
    },
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11040",
    created: {
      seconds: 1726179792,
      nanoseconds: 796000000,
    },
    state: "New York",
    zip: "11040",
    county: "Nassau County",
  },
  {
    id: "11041",
    zip: "11041",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 796000000,
    },
  },
  {
    id: "11042",
    county: "Nassau County",
    zip: "11042",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 796000000,
    },
  },
  {
    id: "11043",
    state: "New York",
    county: "Nassau County",
    zip: "11043",
    created: {
      seconds: 1726179792,
      nanoseconds: 796000000,
    },
  },
  {
    id: "11044",
    zip: "11044",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 796000000,
    },
    state: "New York",
  },
  {
    id: "11050",
    county: "Nassau County",
    zip: "11050",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
    state: "New York",
  },
  {
    id: "11051",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
    county: "Nassau County",
    zip: "11051",
  },
  {
    id: "11052",
    state: "New York",
    county: "Nassau County",
    zip: "11052",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
  },
  {
    id: "11053",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
    zip: "11053",
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11054",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
    state: "New York",
    zip: "11054",
    county: "Nassau County",
  },
  {
    id: "11055",
    zip: "11055",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 797000000,
    },
  },
  {
    id: "11096",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    zip: "11096",
    county: "Queens",
    state: "New York",
  },
  {
    id: "11099",
    state: "New York",
    zip: "11099",
    created: {
      seconds: 1726179792,
      nanoseconds: 798000000,
    },
    county: "Nassau County",
  },
  {
    id: "11101",
    state: "New York",
    county: "Queens",
    zip: "11101",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
  },
  {
    id: "11102",
    county: "Queens",
    zip: "11102",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    state: "New York",
  },
  {
    id: "11103",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    zip: "11103",
    county: "Queens",
  },
  {
    id: "11104",
    zip: "11104",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
  },
  {
    id: "11105",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    zip: "11105",
    county: "Queens",
  },
  {
    id: "11106",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11106",
  },
  {
    id: "11109",
    county: "Queens",
    state: "New York",
    zip: "11109",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
  },
  {
    id: "11120",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    zip: "11120",
    county: "Queens",
    state: "New York",
  },
  {
    id: "11201",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    county: "Brooklyn",
    zip: "11201",
  },
  {
    id: "11202",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    county: "Brooklyn",
    zip: "11202",
  },
  {
    id: "11203",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
    county: "Brooklyn",
    zip: "11203",
    state: "New York",
  },
  {
    id: "11204",
    zip: "11204",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
    state: "New York",
  },
  {
    id: "11205",
    zip: "11205",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11206",
    zip: "11206",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
  },
  {
    id: "11207",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
    zip: "11207",
    state: "New York",
  },
  {
    id: "11208",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 153000000,
    },
    zip: "11208",
    state: "New York",
  },
  {
    id: "11209",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    county: "Brooklyn",
    zip: "11209",
  },
  {
    id: "11210",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    zip: "11210",
    county: "Brooklyn",
  },
  {
    id: "11211",
    county: "Brooklyn",
    state: "New York",
    zip: "11211",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
  },
  {
    id: "11212",
    zip: "11212",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    state: "New York",
    county: "Brooklyn",
  },
  {
    id: "11213",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    county: "Brooklyn",
    state: "New York",
    zip: "11213",
  },
  {
    id: "11214",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
    county: "Brooklyn",
    state: "New York",
    zip: "11214",
  },
  {
    id: "11215",
    zip: "11215",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    state: "New York",
    county: "Brooklyn",
  },
  {
    id: "11216",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    zip: "11216",
  },
  {
    id: "11217",
    state: "New York",
    zip: "11217",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11218",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
    zip: "11218",
    state: "New York",
    county: "Brooklyn",
  },
  {
    id: "11219",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
    zip: "11219",
    state: "New York",
  },
  {
    id: "11220",
    state: "New York",
    county: "Brooklyn",
    zip: "11220",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
  },
  {
    id: "11221",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
    state: "New York",
    zip: "11221",
    county: "Brooklyn",
  },
  {
    id: "11222",
    county: "Brooklyn",
    zip: "11222",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11223",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
    state: "New York",
    zip: "11223",
  },
  {
    id: "11224",
    zip: "11224",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11225",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    zip: "11225",
  },
  {
    id: "11226",
    created: {
      seconds: 1726180121,
      nanoseconds: 154000000,
    },
    county: "Brooklyn",
    zip: "11226",
    state: "New York",
  },
  {
    id: "11228",
    county: "Brooklyn",
    state: "New York",
    zip: "11228",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11229",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
    zip: "11229",
  },
  {
    id: "11230",
    zip: "11230",
    county: "Brooklyn",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
  },
  {
    id: "11231",
    county: "Brooklyn",
    zip: "11231",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
    state: "New York",
  },
  {
    id: "11232",
    state: "New York",
    county: "Brooklyn",
    zip: "11232",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
  },
  {
    id: "11233",
    state: "New York",
    zip: "11233",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
    county: "Brooklyn",
  },
  {
    id: "11234",
    county: "Brooklyn",
    zip: "11234",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 155000000,
    },
  },
  {
    id: "11235",
    state: "New York",
    county: "Brooklyn",
    zip: "11235",
    created: {
      seconds: 1726180121,
      nanoseconds: 156000000,
    },
  },
  {
    id: "11236",
    zip: "11236",
    created: {
      seconds: 1726180121,
      nanoseconds: 154000000,
    },
    state: "New York",
    county: "Brooklyn",
  },
  {
    id: "11237",
    zip: "11237",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
    county: "Brooklyn",
    state: "New York",
  },
  {
    id: "11238",
    zip: "11238",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    state: "New York",
    county: "Brooklyn",
  },
  {
    id: "11239",
    state: "New York",
    zip: "11239",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
  },
  {
    id: "11240",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    county: "Brooklyn",
    zip: "11240",
  },
  {
    id: "11241",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    zip: "11241",
    county: "Brooklyn",
  },
  {
    id: "11242",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    zip: "11242",
    county: "Brooklyn",
  },
  {
    id: "11243",
    zip: "11243",
    state: "New York",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
  },
  {
    id: "11244",
    county: "Brooklyn",
    zip: "11244",
    created: {
      seconds: 1726180121,
      nanoseconds: 157000000,
    },
    state: "New York",
  },
  {
    id: "11245",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    state: "New York",
    zip: "11245",
    county: "Brooklyn",
  },
  {
    id: "11247",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    state: "New York",
    zip: "11247",
    county: "Brooklyn",
  },
  {
    id: "11248",
    state: "New York",
    zip: "11248",
    county: "Brooklyn",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
  },
  {
    id: "11249",
    state: "New York",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
    zip: "11249",
    county: "Brooklyn",
  },
  {
    id: "11251",
    state: "New York",
    zip: "11251",
    created: {
      seconds: 1726180121,
      nanoseconds: 159000000,
    },
    county: "Brooklyn",
  },
  {
    id: "11252",
    created: {
      seconds: 1726180121,
      nanoseconds: 160000000,
    },
    state: "New York",
    county: "Brooklyn",
    zip: "11252",
  },
  {
    id: "11254",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
    county: "Brooklyn",
    zip: "11254",
    state: "New York",
  },
  {
    id: "11255",
    county: "Brooklyn",
    state: "New York",
    zip: "11255",
    created: {
      seconds: 1726180121,
      nanoseconds: 158000000,
    },
  },
  {
    id: "11256",
    created: {
      seconds: 1726180121,
      nanoseconds: 160000000,
    },
    state: "New York",
    zip: "11256",
    county: "Brooklyn",
  },
  {
    id: "11351",
    zip: "11351",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
  },
  {
    id: "11352",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    county: "Queens",
    zip: "11352",
  },
  {
    id: "11354",
    state: "New York",
    zip: "11354",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    county: "Queens",
  },
  {
    id: "11355",
    zip: "11355",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 928000000,
    },
  },
  {
    id: "11356",
    county: "Queens",
    state: "New York",
    zip: "11356",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
  },
  {
    id: "11357",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    zip: "11357",
  },
  {
    id: "11358",
    zip: "11358",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    state: "New York",
    county: "Queens",
  },
  {
    id: "11359",
    zip: "11359",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    state: "New York",
  },
  {
    id: "11360",
    county: "Queens",
    zip: "11360",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
  },
  {
    id: "11361",
    zip: "11361",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
  },
  {
    id: "11362",
    zip: "11362",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
  },
  {
    id: "11363",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    county: "Queens",
    zip: "11363",
  },
  {
    id: "11364",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    state: "New York",
    zip: "11364",
  },
  {
    id: "11365",
    zip: "11365",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    state: "New York",
    county: "Queens",
  },
  {
    id: "11366",
    state: "New York",
    zip: "11366",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    county: "Queens",
  },
  {
    id: "11367",
    zip: "11367",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
  },
  {
    id: "11368",
    county: "Queens",
    state: "New York",
    zip: "11368",
    created: {
      seconds: 1726180190,
      nanoseconds: 926000000,
    },
  },
  {
    id: "11369",
    zip: "11369",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    county: "Queens",
    state: "New York",
  },
  {
    id: "11370",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    zip: "11370",
  },
  {
    id: "11371",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    zip: "11371",
  },
  {
    id: "11372",
    zip: "11372",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
  },
  {
    id: "11373",
    county: "Queens",
    zip: "11373",
    created: {
      seconds: 1726180190,
      nanoseconds: 928000000,
    },
    state: "New York",
  },
  {
    id: "11374",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    zip: "11374",
    state: "New York",
    county: "Queens",
  },
  {
    id: "11375",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    county: "Queens",
    zip: "11375",
    state: "New York",
  },
  {
    id: "11377",
    county: "Queens",
    zip: "11377",
    created: {
      seconds: 1726180190,
      nanoseconds: 928000000,
    },
    state: "New York",
  },
  {
    id: "11378",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    county: "Queens",
    zip: "11378",
  },
  {
    id: "11379",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    county: "Queens",
    zip: "11379",
  },
  {
    id: "11380",
    county: "Queens",
    zip: "11380",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
  },
  {
    id: "11381",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    zip: "11381",
    state: "New York",
  },
  {
    id: "11385",
    county: "Queens",
    zip: "11385",
    created: {
      seconds: 1726180190,
      nanoseconds: 927000000,
    },
    state: "New York",
  },
  {
    id: "11386",
    zip: "11386",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    state: "New York",
    county: "Queens",
  },
  {
    id: "11390",
    state: "New York",
    county: "Queens",
    zip: "11390",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
  },
  {
    id: "11405",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
    zip: "11405",
  },
  {
    id: "11411",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11411",
  },
  {
    id: "11412",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11412",
  },
  {
    id: "11413",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    state: "New York",
    county: "Queens",
    zip: "11413",
  },
  {
    id: "11414",
    state: "New York",
    county: "Queens",
    zip: "11414",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
  },
  {
    id: "11415",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    zip: "11415",
    state: "New York",
    county: "Queens",
  },
  {
    id: "11416",
    zip: "11416",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    county: "Queens",
    state: "New York",
  },
  {
    id: "11417",
    zip: "11417",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
  },
  {
    id: "11418",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11418",
  },
  {
    id: "11419",
    county: "Queens",
    zip: "11419",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
  },
  {
    id: "11420",
    state: "New York",
    zip: "11420",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    county: "Queens",
  },
  {
    id: "11421",
    county: "Queens",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
    zip: "11421",
  },
  {
    id: "11422",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    state: "New York",
    county: "Queens",
    zip: "11422",
  },
  {
    id: "11423",
    created: {
      seconds: 1726180190,
      nanoseconds: 931000000,
    },
    state: "New York",
    county: "Queens",
    zip: "11423",
  },
  {
    id: "11424",
    county: "Queens",
    state: "New York",
    zip: "11424",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
  },
  {
    id: "11425",
    zip: "11425",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
    state: "New York",
    county: "Queens",
  },
  {
    id: "11426",
    zip: "11426",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    state: "New York",
    county: "Queens",
  },
  {
    id: "11427",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11427",
  },
  {
    id: "11428",
    zip: "11428",
    state: "New York",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
  },
  {
    id: "11429",
    zip: "11429",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    state: "New York",
  },
  {
    id: "11430",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    zip: "11430",
    state: "New York",
    county: "Queens",
  },
  {
    id: "11431",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11431",
  },
  {
    id: "11432",
    zip: "11432",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    state: "New York",
  },
  {
    id: "11433",
    state: "New York",
    county: "Queens",
    zip: "11433",
    created: {
      seconds: 1726180190,
      nanoseconds: 930000000,
    },
  },
  {
    id: "11434",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    state: "New York",
    zip: "11434",
  },
  {
    id: "11435",
    zip: "11435",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    county: "Queens",
    state: "New York",
  },
  {
    id: "11436",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    county: "Queens",
    state: "New York",
    zip: "11436",
  },
  {
    id: "11437",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
    county: "Queens",
    zip: "11437",
  },
  {
    id: "11439",
    state: "New York",
    zip: "11439",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
  },
  {
    id: "11451",
    zip: "11451",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    county: "Queens",
    state: "New York",
  },
  {
    id: "11499",
    zip: "11499",
    created: {
      seconds: 1726180190,
      nanoseconds: 935000000,
    },
    county: "Queens",
    state: "New York",
  },
  {
    id: "11501",
    zip: "11501",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 798000000,
    },
  },
  {
    id: "11507",
    zip: "11507",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11509",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    zip: "11509",
  },
  {
    id: "11510",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    state: "New York",
    zip: "11510",
  },
  {
    id: "11514",
    zip: "11514",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
  },
  {
    id: "11516",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    zip: "11516",
    county: "Nassau County",
  },
  {
    id: "11518",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    state: "New York",
    county: "Nassau County",
    zip: "11518",
  },
  {
    id: "11520",
    county: "Nassau County",
    zip: "11520",
    created: {
      seconds: 1726179792,
      nanoseconds: 799000000,
    },
    state: "New York",
  },
  {
    id: "11530",
    zip: "11530",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
  },
  {
    id: "11531",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
    zip: "11531",
    county: "Nassau County",
  },
  {
    id: "11535",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
    state: "New York",
    county: "Nassau County",
    zip: "11535",
  },
  {
    id: "11536",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
    zip: "11536",
  },
  {
    id: "11542",
    zip: "11542",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11545",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 800000000,
    },
    county: "Nassau County",
    zip: "11545",
  },
  {
    id: "11547",
    county: "Nassau County",
    zip: "11547",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
  },
  {
    id: "11548",
    zip: "11548",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
    state: "New York",
  },
  {
    id: "11549",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
    county: "Nassau County",
    state: "New York",
    zip: "11549",
  },
  {
    id: "11550",
    county: "Nassau County",
    zip: "11550",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
    state: "New York",
  },
  {
    id: "11551",
    zip: "11551",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11552",
    created: {
      seconds: 1726179792,
      nanoseconds: 801000000,
    },
    zip: "11552",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11553",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
    county: "Nassau County",
    zip: "11553",
  },
  {
    id: "11554",
    zip: "11554",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
  },
  {
    id: "11555",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
    state: "New York",
    zip: "11555",
  },
  {
    id: "11556",
    state: "New York",
    zip: "11556",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
    county: "Nassau County",
  },
  {
    id: "11557",
    zip: "11557",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
    state: "New York",
  },
  {
    id: "11558",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
    state: "New York",
    zip: "11558",
    county: "Nassau County",
  },
  {
    id: "11559",
    state: "New York",
    county: "Nassau County",
    zip: "11559",
    created: {
      seconds: 1726179792,
      nanoseconds: 802000000,
    },
  },
  {
    id: "11560",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
    zip: "11560",
    state: "New York",
  },
  {
    id: "11561",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
    state: "New York",
    zip: "11561",
  },
  {
    id: "11563",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
    county: "Nassau County",
    zip: "11563",
  },
  {
    id: "11565",
    state: "New York",
    county: "Nassau County",
    zip: "11565",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
  },
  {
    id: "11566",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
    zip: "11566",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11568",
    created: {
      seconds: 1726179792,
      nanoseconds: 803000000,
    },
    state: "New York",
    county: "Nassau County",
    zip: "11568",
  },
  {
    id: "11569",
    state: "New York",
    county: "Nassau County",
    zip: "11569",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
  },
  {
    id: "11570",
    zip: "11570",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11571",
    county: "Nassau County",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
    zip: "11571",
  },
  {
    id: "11572",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
    state: "New York",
    zip: "11572",
    county: "Nassau County",
  },
  {
    id: "11575",
    county: "Nassau County",
    zip: "11575",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
  },
  {
    id: "11576",
    zip: "11576",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
  },
  {
    id: "11577",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 804000000,
    },
    zip: "11577",
    state: "New York",
  },
  {
    id: "11579",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
    state: "New York",
    zip: "11579",
    county: "Nassau County",
  },
  {
    id: "11580",
    zip: "11580",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11581",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
    zip: "11581",
    state: "New York",
  },
  {
    id: "11582",
    state: "New York",
    zip: "11582",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
  },
  {
    id: "11590",
    county: "Nassau County",
    state: "New York",
    zip: "11590",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
  },
  {
    id: "11592",
    zip: "11592",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11594",
    zip: "11594",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 805000000,
    },
    state: "New York",
  },
  {
    id: "11595",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
    county: "Nassau County",
    zip: "11595",
    state: "New York",
  },
  {
    id: "11596",
    state: "New York",
    county: "Nassau County",
    zip: "11596",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
  },
  {
    id: "11597",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
    county: "Nassau County",
    state: "New York",
    zip: "11597",
  },
  {
    id: "11598",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
    zip: "11598",
  },
  {
    id: "11599",
    state: "New York",
    zip: "11599",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
    county: "Nassau County",
  },
  {
    id: "11690",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    state: "New York",
    zip: "11690",
    county: "Queens",
  },
  {
    id: "11691",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 929000000,
    },
    zip: "11691",
    state: "New York",
  },
  {
    id: "11692",
    county: "Queens",
    state: "New York",
    zip: "11692",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
  },
  {
    id: "11693",
    state: "New York",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    zip: "11693",
    county: "Queens",
  },
  {
    id: "11694",
    created: {
      seconds: 1726180190,
      nanoseconds: 932000000,
    },
    state: "New York",
    county: "Queens",
    zip: "11694",
  },
  {
    id: "11695",
    county: "Queens",
    zip: "11695",
    created: {
      seconds: 1726180190,
      nanoseconds: 934000000,
    },
    state: "New York",
  },
  {
    id: "11697",
    county: "Queens",
    created: {
      seconds: 1726180190,
      nanoseconds: 933000000,
    },
    zip: "11697",
    state: "New York",
  },
  {
    id: "11701",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    zip: "11701",
  },
  {
    id: "11702",
    zip: "11702",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11703",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    zip: "11703",
    state: "New York",
  },
  {
    id: "11704",
    state: "New York",
    zip: "11704",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11705",
    county: "Suffolk County",
    zip: "11705",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
  },
  {
    id: "11706",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 302000000,
    },
    zip: "11706",
  },
  {
    id: "11707",
    county: "Suffolk County",
    zip: "11707",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    state: "New York",
  },
  {
    id: "11708",
    county: "Suffolk County",
    zip: "11708",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    state: "New York",
  },
  {
    id: "11709",
    created: {
      seconds: 1726179792,
      nanoseconds: 806000000,
    },
    state: "New York",
    zip: "11709",
    county: "Nassau County",
  },
  {
    id: "11710",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 807000000,
    },
    zip: "11710",
  },
  {
    id: "11713",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    zip: "11713",
    county: "Suffolk County",
  },
  {
    id: "11714",
    county: "Nassau County",
    state: "New York",
    zip: "11714",
    created: {
      seconds: 1726179792,
      nanoseconds: 807000000,
    },
  },
  {
    id: "11715",
    zip: "11715",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
  },
  {
    id: "11716",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    zip: "11716",
  },
  {
    id: "11717",
    zip: "11717",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11718",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    zip: "11718",
  },
  {
    id: "11719",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    zip: "11719",
  },
  {
    id: "11720",
    state: "New York",
    zip: "11720",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
  },
  {
    id: "11721",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    zip: "11721",
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11722",
    zip: "11722",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    state: "New York",
  },
  {
    id: "11724",
    zip: "11724",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    state: "New York",
  },
  {
    id: "11725",
    zip: "11725",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11726",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    county: "Suffolk County",
    zip: "11726",
    state: "New York",
  },
  {
    id: "11727",
    zip: "11727",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    state: "New York",
  },
  {
    id: "11729",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    county: "Suffolk County",
    zip: "11729",
    state: "New York",
  },
  {
    id: "11730",
    zip: "11730",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11731",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    state: "New York",
    zip: "11731",
    county: "Suffolk County",
  },
  {
    id: "11732",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 807000000,
    },
    county: "Nassau County",
    zip: "11732",
  },
  {
    id: "11733",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11733",
  },
  {
    id: "11735",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11735",
  },
  {
    id: "11736",
    zip: "11736",
    created: {
      seconds: 1726179792,
      nanoseconds: 807000000,
    },
    state: "New York",
    county: "Nassau County",
  },
  {
    id: "11737",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    zip: "11737",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11738",
    state: "New York",
    zip: "11738",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
  },
  {
    id: "11739",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
    zip: "11739",
  },
  {
    id: "11740",
    zip: "11740",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
  },
  {
    id: "11741",
    state: "New York",
    zip: "11741",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
  },
  {
    id: "11742",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11742",
  },
  {
    id: "11743",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    zip: "11743",
  },
  {
    id: "11746",
    created: {
      seconds: 1726179918,
      nanoseconds: 301000000,
    },
    zip: "11746",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11747",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11747",
  },
  {
    id: "11749",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    zip: "11749",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11750",
    state: "New York",
    zip: "11750",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11751",
    county: "Suffolk County",
    zip: "11751",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11752",
    zip: "11752",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    state: "New York",
  },
  {
    id: "11753",
    state: "New York",
    zip: "11753",
    created: {
      seconds: 1726179792,
      nanoseconds: 807000000,
    },
    county: "Nassau County",
  },
  {
    id: "11754",
    county: "Suffolk County",
    zip: "11754",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    state: "New York",
  },
  {
    id: "11755",
    state: "New York",
    zip: "11755",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11756",
    state: "New York",
    county: "Nassau County",
    zip: "11756",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
  },
  {
    id: "11757",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11757",
  },
  {
    id: "11758",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
    county: "Nassau County",
    zip: "11758",
  },
  {
    id: "11760",
    county: "Suffolk County",
    state: "New York",
    zip: "11760",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
  },
  {
    id: "11762",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
    state: "New York",
    zip: "11762",
  },
  {
    id: "11763",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    zip: "11763",
    state: "New York",
  },
  {
    id: "11764",
    county: "Suffolk County",
    zip: "11764",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
  },
  {
    id: "11765",
    state: "New York",
    zip: "11765",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
    county: "Nassau County",
  },
  {
    id: "11766",
    zip: "11766",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    state: "New York",
  },
  {
    id: "11767",
    zip: "11767",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11768",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    zip: "11768",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11769",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    zip: "11769",
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11770",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    zip: "11770",
  },
  {
    id: "11771",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
    county: "Nassau County",
    zip: "11771",
    state: "New York",
  },
  {
    id: "11772",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    zip: "11772",
    county: "Suffolk County",
  },
  {
    id: "11773",
    zip: "11773",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
  },
  {
    id: "11774",
    created: {
      seconds: 1726179792,
      nanoseconds: 808000000,
    },
    state: "New York",
    county: "Nassau County",
    zip: "11774",
  },
  {
    id: "11775",
    county: "Suffolk County",
    state: "New York",
    zip: "11775",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
  },
  {
    id: "11776",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    zip: "11776",
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11777",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    zip: "11777",
  },
  {
    id: "11778",
    zip: "11778",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11779",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    zip: "11779",
  },
  {
    id: "11780",
    zip: "11780",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
  },
  {
    id: "11782",
    zip: "11782",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11783",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    state: "New York",
    zip: "11783",
  },
  {
    id: "11784",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    state: "New York",
    zip: "11784",
    county: "Suffolk County",
  },
  {
    id: "11786",
    zip: "11786",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11787",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 303000000,
    },
    zip: "11787",
  },
  {
    id: "11788",
    zip: "11788",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11789",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    state: "New York",
    county: "Suffolk County",
    zip: "11789",
  },
  {
    id: "11790",
    zip: "11790",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
  },
  {
    id: "11791",
    zip: "11791",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    state: "New York",
  },
  {
    id: "11792",
    state: "New York",
    zip: "11792",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
  },
  {
    id: "11793",
    county: "Nassau County",
    state: "New York",
    zip: "11793",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
  },
  {
    id: "11794",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11794",
  },
  {
    id: "11795",
    zip: "11795",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11796",
    county: "Suffolk County",
    state: "New York",
    zip: "11796",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
  },
  {
    id: "11797",
    zip: "11797",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    state: "New York",
  },
  {
    id: "11798",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    zip: "11798",
    county: "Suffolk County",
  },
  {
    id: "11801",
    county: "Nassau County",
    zip: "11801",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
  },
  {
    id: "11802",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    state: "New York",
    zip: "11802",
    county: "Nassau County",
  },
  {
    id: "11803",
    zip: "11803",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11804",
    state: "New York",
    created: {
      seconds: 1726179792,
      nanoseconds: 809000000,
    },
    county: "Nassau County",
    zip: "11804",
  },
  {
    id: "11815",
    state: "New York",
    county: "Nassau County",
    zip: "11815",
    created: {
      seconds: 1726179792,
      nanoseconds: 810000000,
    },
  },
  {
    id: "11819",
    created: {
      seconds: 1726179792,
      nanoseconds: 810000000,
    },
    zip: "11819",
    county: "Nassau County",
    state: "New York",
  },
  {
    id: "11853",
    state: "New York",
    county: "Nassau County",
    zip: "11853",
    created: {
      seconds: 1726179792,
      nanoseconds: 810000000,
    },
  },
  {
    id: "11854",
    created: {
      seconds: 1726179792,
      nanoseconds: 810000000,
    },
    state: "New York",
    county: "Nassau County",
    zip: "11854",
  },
  {
    id: "11855",
    zip: "11855",
    state: "New York",
    county: "Nassau County",
    created: {
      seconds: 1726179792,
      nanoseconds: 810000000,
    },
  },
  {
    id: "11901",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    state: "New York",
    zip: "11901",
    county: "Suffolk County",
  },
  {
    id: "11930",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    state: "New York",
    county: "Suffolk County",
    zip: "11930",
  },
  {
    id: "11931",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    zip: "11931",
  },
  {
    id: "11932",
    zip: "11932",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11933",
    zip: "11933",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    state: "New York",
  },
  {
    id: "11934",
    county: "Suffolk County",
    zip: "11934",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    state: "New York",
  },
  {
    id: "11935",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    zip: "11935",
    county: "Suffolk County",
  },
  {
    id: "11937",
    county: "Suffolk County",
    zip: "11937",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
    state: "New York",
  },
  {
    id: "11939",
    state: "New York",
    zip: "11939",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11940",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    zip: "11940",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11941",
    state: "New York",
    zip: "11941",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11942",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    state: "New York",
    zip: "11942",
    county: "Suffolk County",
  },
  {
    id: "11944",
    zip: "11944",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
  },
  {
    id: "11946",
    county: "Suffolk County",
    zip: "11946",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
    state: "New York",
  },
  {
    id: "11947",
    county: "Suffolk County",
    state: "New York",
    zip: "11947",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
  },
  {
    id: "11948",
    zip: "11948",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
  },
  {
    id: "11949",
    zip: "11949",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11950",
    state: "New York",
    county: "Suffolk County",
    zip: "11950",
    created: {
      seconds: 1726179918,
      nanoseconds: 305000000,
    },
  },
  {
    id: "11951",
    zip: "11951",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11952",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
    zip: "11952",
    state: "New York",
  },
  {
    id: "11953",
    zip: "11953",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 306000000,
    },
  },
  {
    id: "11954",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    zip: "11954",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11955",
    state: "New York",
    zip: "11955",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11956",
    zip: "11956",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11957",
    state: "New York",
    zip: "11957",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    county: "Suffolk County",
  },
  {
    id: "11958",
    county: "Suffolk County",
    zip: "11958",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    state: "New York",
  },
  {
    id: "11959",
    zip: "11959",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
  },
  {
    id: "11960",
    county: "Suffolk County",
    zip: "11960",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    state: "New York",
  },
  {
    id: "11961",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    state: "New York",
    zip: "11961",
  },
  {
    id: "11962",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    zip: "11962",
    state: "New York",
  },
  {
    id: "11963",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    state: "New York",
    zip: "11963",
  },
  {
    id: "11964",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    state: "New York",
    zip: "11964",
    county: "Suffolk County",
  },
  {
    id: "11965",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    state: "New York",
    county: "Suffolk County",
    zip: "11965",
  },
  {
    id: "11967",
    created: {
      seconds: 1726179918,
      nanoseconds: 304000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11967",
  },
  {
    id: "11968",
    zip: "11968",
    created: {
      seconds: 1726179918,
      nanoseconds: 307000000,
    },
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11969",
    state: "New York",
    county: "Suffolk County",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    zip: "11969",
  },
  {
    id: "11970",
    zip: "11970",
    created: {
      seconds: 1726179918,
      nanoseconds: 311000000,
    },
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11971",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    state: "New York",
    zip: "11971",
    county: "Suffolk County",
  },
  {
    id: "11972",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    zip: "11972",
    county: "Suffolk County",
    state: "New York",
  },
  {
    id: "11973",
    county: "Suffolk County",
    state: "New York",
    zip: "11973",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
  },
  {
    id: "11975",
    created: {
      seconds: 1726179918,
      nanoseconds: 310000000,
    },
    county: "Suffolk County",
    state: "New York",
    zip: "11975",
  },
  {
    id: "11976",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
    zip: "11976",
    state: "New York",
    county: "Suffolk County",
  },
  {
    id: "11977",
    zip: "11977",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 309000000,
    },
  },
  {
    id: "11978",
    zip: "11978",
    county: "Suffolk County",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
  },
  {
    id: "11980",
    zip: "11980",
    state: "New York",
    created: {
      seconds: 1726179918,
      nanoseconds: 308000000,
    },
    county: "Suffolk County",
  },
];

export const areasLayer = [
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Financial District",
    subareas: "Battery Park City",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Civic Center",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Tribeca",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "SoHo",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "NoHo",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Greenwich Village",
    subareas: "West Village",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Lower East Side",
    subareas: "Chinatown",
    lower_subareas: "Two Bridges",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Lower East Side",
    subareas: "Little Italy",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Lower East Side",
    subareas: "Nolita",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Lower East Side",
    subareas: "East Village",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "NoMad",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Flatiron District",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Kips Bay",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Murray Hill",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Sutton Place",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Grammercy Park",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Hell's Kitchen",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Hudson Yards",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Chelsea",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Stuyvesant Town-PCV",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Upper West Side",
    subareas: "Lincoln Square",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Upper West Side",
    subareas: "Manhattan Valley",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Upper East Side",
    subareas: "Lenox Hill",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Upper East Side",
    subareas: "Carnegie Hill",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Upper East Side",
    subareas: "Yorkville",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Harlem",
    subareas: "East Harlem",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Harlem",
    subareas: "Central Harlem",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Hamilton Heights",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Manhattanville",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Morningside Heights",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Washington Heights",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Inwood",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Marble Hill",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Hudson Heights",
  },
  {
    region: "New York City",
    county: "Manhattan",
    areas: "Central Park",
    subareas: "Central Park South",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "East New York",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Greenpoint",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Williamsburg",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Bushwick",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Gowanus",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Red Hook",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Brooklyn Heights",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Dumbo",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Vinegar Hill",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Downtown Brooklyn",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Cobble Hill",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Carroll Gardens",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Boerum Hill",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Fort Greene",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Clinton Hill",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Bedford-Stuyvesant",
    subareas: "Ocean Hill",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Crown Heights",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Prospect Heights",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Borough Park",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Midwood",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Kensington",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Flatbush",
    subareas: "East Flatbush",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Flatbush",
    subareas: "Ditmas Park",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Park Slope",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Windsor Terrace",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Sunset Park",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Bay Ridge",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Dyker Heights",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Bensonhurst",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Gravesend",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Sheepshead Bay",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Manhattan Beach",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Brighton Beach",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Coney Island",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Marine Park",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Mill Basin",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Canarsie",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Brownsville",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Bath Beach",
  },
  {
    region: "New York City",
    county: "Brooklyn",
    areas: "Flatlands",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Astoria",
    subareas: "Ditmars Steinway",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Astoria",
    subareas: "Steinway",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Long Island City",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Sunnyside",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Woodside",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Woodhaven",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jackson Heights",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Elmhurst",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Corona",
    subareas: "North Corona",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Fresh Meadow",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Flushing",
    subareas: "Murray Hill",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Flushing",
    subareas: "Kew Gardens Hills",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Flushing",
    subareas: "Pomonok",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "College Point",
    subareas: "Malba",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Whitestone",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Bayside",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Douglaston",
    subareas: "Little Neck",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Forest Hills",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Rego Park",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Kew Gardens",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Briarwood",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Jamaica Estates",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Jamaica Hills",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Hillcrest",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Richmond Hill",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Richmond Hill",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Ozone Park",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Ozone Park",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "Howard Beach",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Hollis",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Cambria Heights",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Queens Village",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Rosedale",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Springfield Gardens",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Laurelton",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "St. Albans",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Arverne",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Edgemere",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Far Rockaway",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Rockaway Beach",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Rockaway Park",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Belle Harbor",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Breezy Point",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "The Rockaways",
    subareas: "Neponsit",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Glen Oaks",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Bellerose",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Floral Park",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Glendale",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Maspeth",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Middle Village",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Ridgewood",
  },
  {
    region: "New York City",
    county: "Queens",
    areas: "Forest Park",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Riverdale",
    subareas: "Spuyten Duyvil",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Fieldston",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Kingsbridge",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Kingsbridge Heights",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Van Cortlandt Village",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Bedford Park",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Norwood",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "University Heights",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Fordham",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Belmont",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Jerome Park",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Mount Eden",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Mount Hope",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Tremont",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "East Tremont",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "West Farms",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Bathgate",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Fordham Heights",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Mott Haven",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Melrose",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "The Hub",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Port Morris",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Throggs Neck",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Country Club",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Pelham Bay",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "City Island",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Pelham Parkway",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Morris Park",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "The Bronxdale",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Van Nest",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Westchester Square",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Castle Hill",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Unionport",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Soundview",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Harding Park",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Clason Point",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Hunts Point",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Longwood",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Concourse",
    subareas: "Concourse Village",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Woodland",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Morrisania",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Crotona Park East",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Co-op City",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Pelham Gardens",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Allerton",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Williamsbridge",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Baychester",
  },
  {
    region: "New York City",
    county: "The Bronx",
    areas: "Wakefield",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "St. George",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Tompkinsville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Stapleton",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Clifton",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Rosebank",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Grymes Hill",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Silver Lake",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Sunnyside",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Westerleigh",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Port Richmond",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Midland Beach",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Grant City",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "New Dorp",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Oakwood",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Richmondtown",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Dongan Hills",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Todt Hill",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "South Beach",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Arrochar",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Grasmere",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Concord",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Great Kills",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Eltingville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Annadale",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Huguenot",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Woodrow",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Rossville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Arden Heights",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Prince's Bay",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Pleasant Plains",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Tottenville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Old Town",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Fort Wadsworth",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "New Brighton",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "West Brighton",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Mariners Harbor",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Elm Park",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Graniteville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Willowbrook",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Castleton Corners",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "New Springville",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Bulls Head",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Travis",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Chelsea",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Bloomfield",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Heartland Village",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Bay Terrace",
  },
  {
    region: "New York City",
    county: "Staten Island",
    areas: "Charleston",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lynbrook",
    subareas: "East Rockaway",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Rockville Centre",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Long Beach",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Island Park",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "West Hempstead",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Uniondale",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Roosevelt",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Lakeview",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Port Washington",
    subareas: "Sands Point",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Port Washington",
    subareas: "Port Washington North",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Elmont",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Garden City",
    subareas: "East Garden City",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Garden City",
    subareas: "Garden City South",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bellmore",
    subareas: "North Bellmore",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bellmore",
    subareas: "Merrick",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Wantagh",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Seaford",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Levittown",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oceanside",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Meadow",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Merrick",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manhasset",
    subareas: "Munsey Park",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manhasset",
    subareas: "Plandome",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Estates",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Plaza",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Gardens",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Village",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Heights",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Estates",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Harbor",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "East Hills",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Westbury",
    subareas: "Old Westbury",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Carle Place",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Mineola",
    subareas: "East Williston",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Mineola",
    subareas: "Williston Park",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "New Hyde Park",
    subareas: "Garden City Park",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lake Success",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Flower Hill",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Searingtown",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Syosset",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hicksville",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Plainview",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Woodbury",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bethpage",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "Massapequa Park",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "East Massapequa",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "North Massapequa",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Levittown",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Meadow",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Jericho",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Muttontown",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Long Beach",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Valley Stream",
    subareas: "North Valley Stream",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Valley Stream",
    subareas: "South Valley Stream",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Atlantic Beach",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Baldwin",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Uniondale",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Glen Cove",
    subareas: "Sea Cliff",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Glen Cove",
    subareas: "Glen Head",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hewlett",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Inwood",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Woodmere",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lawrence",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Atlantic Beach",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Oyster Bay Cove",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Bayville",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Locust Valley",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Mill Neck",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Old Brookville",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Fort Lee",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Hackensack",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Teaneck",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Paramus",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Englewood",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Ridgewood",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Newark",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Montclair",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "East Orange",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "West Orange",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Livingston",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Orange",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Bloomfield",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Hoboken",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Union City",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Bayonne",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Weehawken",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Harrison",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Paterson",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Clifton",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Wayne",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Little Falls",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Hawthorne",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Pompton Lakes",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Morristown",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Parsippany-Troy Hills",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Morris Plains",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Randolph",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Denville",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Mount Olive Township",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Washington",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Hackettstown",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Phillipsburg",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Belvidere",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Oxford",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "New Brunswick",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Edison",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Woodbridge",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Perth Amboy",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Sayreville",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "East Brunswick",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Bridgewater",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Somerville",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Franklin Township",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "North Plainfield",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Hillsborough Township",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Bound Brook",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Trenton",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Hamilton",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Princeton",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Ewing",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Robbinsville Township",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Lawrence Township",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Freehold",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Red Bank",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Asbury Park",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Long Branch",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Neptune",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Toms River",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Lakewood",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Jackson",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Brick",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Barnegat",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Manchester",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Point Pleasant",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Camden",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Cherry Hill",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Voorhees",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Haddonfield",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Pennsauken",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Glassboro",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Mount Laurel",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Moorestown",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Evesham",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Willingboro",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Pemberton",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Medford",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Woodbury",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Washington Township",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Sewell",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Glassboro",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Mantua",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "West Deptford",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Atlantic City",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Egg Harbor Township",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Pleasantville",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Ventnor City",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Hammonton",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Margate City",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Cape May",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Wildwood",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Ocean City",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Stone Harbor",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Middle Township",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "North Wildwood",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Vineland",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Bridgeton",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Millville",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Upper Deerfield Township",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Shiloh",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Flemington",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Clinton",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "High Bridge",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Lambertville",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Readington",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Salem",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Pennsville",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Elmer",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Quinton",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Woodstown",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Newton",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Hopatcong",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Vernon",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Sparta",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Andover",
    subareas: "",
    lower_subareas: "",
  },
];

const ManhattanLayer = [
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Financial District",
    subareas: "Battery Park City",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Civic Center",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Tribeca",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "SoHo",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "NoHo",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Greenwich Village",
    subareas: "West Village",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Lower East Side",
    subareas: "Chinatown",
    lower_subareas: "Two Bridges",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Lower East Side",
    subareas: "Little Italy",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Lower East Side",
    subareas: "Nolita",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Lower East Side",
    subareas: "East Village",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "NoMad",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Flatiron District",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Kips Bay",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Murray Hill",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Sutton Place",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown East",
    lower_subareas: "Grammercy Park",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Hell's Kitchen",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Hudson Yards",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Midtown",
    subareas: "Midtown West",
    lower_subareas: "Chelsea",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Stuyvesant Town-PCV",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Upper West Side",
    subareas: "Lincoln Square",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Upper West Side",
    subareas: "Manhattan Valley",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Upper East Side",
    subareas: "Lenox Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Upper East Side",
    subareas: "Carnegie Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Upper East Side",
    subareas: "Yorkville",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Harlem",
    subareas: "East Harlem",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Harlem",
    subareas: "Central Harlem",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Hamilton Heights",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Manhattanville",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Harlem",
    subareas: "West Harlem",
    lower_subareas: "Morningside Heights",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Washington Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Inwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Marble Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Hudson Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Central Park",
    subareas: "Central Park South",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Liberty Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Randalls Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Roosevelt Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Manhattan (New York County)",
    areas: "Stuyvesant Town-PCV",
    subareas: "",
    lower_subareas: "",
  },
];

const BrooklynLayer = [
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bath Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bay Ridge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bedford-Stuyvesant",
    subareas: "Ocean Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bedford-Stuyvesant",
    subareas: "Stuyvesant Heights",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bedford-Stuyvesant",
    subareas: "Weeksville",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bensonhurst",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Boerum Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Borough Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Brighton Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Brooklyn Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Brownsville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Bushwick",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Canarsie",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Carroll Gardens",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Clinton Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Cobble Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Columbia Street Waterfront District",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Coney Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Coney Island",
    subareas: "Seagate",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Crown Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Downtown Brooklyn",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Dumbo",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Dyker Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "East New York",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "East New York",
    subareas: "Starrett City",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "East Williamsburg",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Flatbush",
    subareas: "East Flatbush",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Flatbush",
    subareas: "Prospect Lefferts Gardens",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Flatbush",
    subareas: "Prospect Park South",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Flatlands",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Fort Greene",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Fort Hamilton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Gerritsen Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Gowanus",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Gravesend",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Greenpoint",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Highland Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Homecrest",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Kensington",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Kensington",
    subareas: "Ocean Parkway",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Manhattan Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Mapleton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Marine Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Midwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Mill Basin",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "New Lots",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Park Slope",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Prospect Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Red Hook",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Sheepshead Bay",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Sunset Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Vinegar Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Williamsburg",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Brooklyn (Kings County)",
    areas: "Windsor Terrace",
    subareas: "",
    lower_subareas: "",
  },
];

const QueensLayer = [
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Astoria",
    subareas: "Ditmars Steinway",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Astoria",
    subareas: "Steinway",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Astoria Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Bayside",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Bayswater",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Beechhurst",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Bellerose",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Briarwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Broad Channel",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Clearview",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "College Point",
    subareas: "Malba",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Corona",
    subareas: "North Corona",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Douglaston",
    subareas: "Little Neck",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "East Elmhurst",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "East Flushing",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Elmhurst",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Floral Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Flushing",
    subareas: "Murray Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Flushing",
    subareas: "Kew Gardens Hills",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Flushing",
    subareas: "Pomonok",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Flushing",
    subareas: "Auburndale",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Forest Hills",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Forest Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Fresh Meadows",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Fresh Meadows",
    subareas: "Utopia",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Glen Oaks",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Glendale",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jackson Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Jamaica Estates",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Jamaica Hills",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Hillcrest",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Richmond Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Richmond Hill",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Ozone Park",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Ozone Park",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Hollis",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Cambria Heights",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Queens Village",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Rosedale",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Springfield Gardens",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "Laurelton",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "South Jamaica",
    lower_subareas: "St. Albans",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Howard Beach",
    lower_subareas: "Hamilton Beach",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Howard Beach",
    lower_subareas: "Lindenwood",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Howard Beach",
    lower_subareas: "Old Howard Beach",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Howard Beach",
    lower_subareas: "Ramblersville",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Jamaica",
    subareas: "Howard Beach",
    lower_subareas: "Rockwood Park",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Kew Gardens",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Long Island City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Long Island City",
    subareas: "Hunters Point",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Maspeth",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Middle Village",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Oakland Gardens",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Rego Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Ridgewood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Sunnyside",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Arverne",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Edgemere",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Far Rockaway",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Rockaway Beach",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Rockaway Park",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Belle Harbor",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Breezy Point",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Neponsit",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "The Rockaways",
    subareas: "Hammels",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Whitestone",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Woodhaven",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Queens (Queens County)",
    areas: "Woodside",
    subareas: "",
    lower_subareas: "",
  },
];

const BronxLayer = [
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Allerton",
    subareas: "Bronxwood",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Baychester",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Bedford Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Belmont",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Bronxdale",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Castle Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "City Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Claremont",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Clason Point",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Co-op City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Concourse",
    subareas: "Concourse Village",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Country Club",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Crotona Park East",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Eastchester",
    subareas: "Edenwald",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "East Tremont",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Eastchester",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Park of Edgewater",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Fieldston",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Fordham",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Fordham Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Harding Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Highbridge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Hunts Point",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Jerome Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Kingsbridge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Kingsbridge Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Laconia",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Longwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Melrose",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Morris Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Morris Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Morrisania",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Mott Haven",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Mount Eden",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Mount Hope",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Norwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Parkchester",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Pelham Bay",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Pelham Gardens",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Pelham Parkway",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Port Morris",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Riverdale",
    subareas: "Spuyten Duyvil",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Schuylerville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Soundview",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Throgs Neck",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Tremont",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Unionport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "University Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Van Cortlandt Village",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Van Nest",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Wakefield",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "West Farms",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Westchester Square",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Williamsbridge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Woodlawn Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "The Bronx (Bronx County)",
    areas: "Woodstock",
    subareas: "",
    lower_subareas: "",
  },
];

const StatenLayer = [
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Annadale",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Arden Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Arrochar",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Bay Terrace",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Bloomfield",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Bulls Head",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Castleton Corners",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Charleston",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Clifton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Concord",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Dongan Hills",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Elm Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Eltingville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Emerson Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Fort Wadsworth",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Graniteville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Grant City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Grasmere",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Great Kills",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Grymes Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Heartland Village",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Huguenot",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Lighthouse Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Manor Heights",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Mariners Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Midland Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "New Brighton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "New Dorp",
    subareas: "New Dorp Beach",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "New Springville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Oakwood",
    subareas: "Oakwood Beach",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Old Town",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Park Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Pleasant Plains",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Port Richmond",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Prince's Bay",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Richmond",
    subareas: "Shore Acres",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Rosebank",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Rossville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Silver Lake",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "South Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "St. George",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Stapleton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Sunnyside",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Todt Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Tompkinsville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Tottenville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Travis - Chelsea",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "West Brighton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Westerleigh",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Willowbrook",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New York City",
    county: "Staten Island (Richmond County)",
    areas: "Woodrow",
    subareas: "",
    lower_subareas: "",
  },
];

const NassauLayer = [
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Freeport",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lynbrook",
    subareas: "East Rockaway",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Rockville Centre",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Long Beach",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Island Park",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "West Hempstead",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "North Hempstead",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "South Hempstead",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Uniondale",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Roosevelt",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hempstead",
    subareas: "Lakeview",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Elmont",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Garden City",
    subareas: "East Garden City",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Garden City",
    subareas: "Garden City South",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bellmore",
    subareas: "North Bellmore",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bellmore",
    subareas: "Merrick",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Wantagh",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Seaford",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Levittown",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oceanside",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Meadow",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Merrick",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Port Washington",
    subareas: "Port Washington North",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Port Washington",
    subareas: "Sands Point",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manhasset",
    subareas: "Munsey Park",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manhasset",
    subareas: "Plandome",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Estates",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Plaza",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Great Neck",
    subareas: "Great Neck Gardens",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Heights",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Estates",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "Roslyn Harbor",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Roslyn",
    subareas: "East Hills",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Westbury",
    subareas: "Old Westbury",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Carle Place",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Mineola",
    subareas: "East Williston",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Mineola",
    subareas: "Williston Park",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "New Hyde Park",
    subareas: "Garden City Park",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lake Success",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Flower Hill",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Searingtown",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Syosset",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hicksville",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Plainview",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Woodbury",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bethpage",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "Massapequa Park",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "East Massapequa",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Massapequa",
    subareas: "North Massapequa",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Levittown",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Meadow",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Jericho",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Muttontown",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Long Beach",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Valley Stream",
    subareas: "North Valley Stream",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Valley Stream",
    subareas: "South Valley Stream",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Atlantic Beach",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Baldwin",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Uniondale",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Glen Cove",
    subareas: "Sea Cliff",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Glen Cove",
    subareas: "Glen Head",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hewlett",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Inwood",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Woodmere",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lawrence",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Atlantic Beach",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Oyster Bay Cove",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Bayville",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Locust Valley",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Mill Neck",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Oyster Bay",
    subareas: "Old Brookville",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Albertson",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Baldwin Harbor",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Barnum Island",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Baxter Estates",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bay Park",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Bellerose Terrace",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Cedarhurst",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Centre Island",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Cove Neck",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "East Norwich",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Farmingdale",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Franklin Square",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Glenwood Landing",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Greenvale",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Harbor Hills",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Harbor Isle",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Herricks",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hewlett Bay Park",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hewlett Harbor",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Hewlett Neck",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Kings Point",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lattingtown",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Laurel Hollow",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Lido Beach",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Malverne",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Malverne Park Oaks",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manhasset Hills",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Manorhaven",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Matinecock",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "New Cassel",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Hills",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Lynbrook",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North New Hyde Park",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Wantagh",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "North Woodmere",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Old Bethpage",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Plainedge",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Plandome Heights",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Plandome Manor",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Point Lookout",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Russell Gardens",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Saddle Rock",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Saddle Rock Estates",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Salisbury",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "South Farmingdale",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "South Floral Park",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Stewart Manor",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Strathmore",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Thomaston",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "University Gardens",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Upper Brookville",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
  {
    region: "Long Island",
    county: "Nassau County",
    areas: "Woodsburgh",
    subareas: "",
    lower_subareas: "",
    "": "",
  },
];

const SuffolkLayer = [
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Amityville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Amity Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Captree Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Gilgo Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Oak Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Oak Island",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "West Gilgo Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "North Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "West Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Bayport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Fair Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Kismet",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Bay Shore",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Saltaire",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "West Babylon",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Amityville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Bellport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Blue Point",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Bohemia",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Brentwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Brightwaters",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Brookhaven",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "South Setauket",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Centereach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Centerport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Central Islip",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Cold Spring Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Commack",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Copiague",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Coram",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Deer Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "East Islip",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Elwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "East Northport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Setauket- East Setauket",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Farmingville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Great River",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Greenlawn",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Holbrook",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Holtsville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Halesite",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Huntington",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Lloyd Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Dix Hills",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Huntington Station",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Huntington Station",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Melville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Central Islip",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Hauppauge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Ronkonkoma",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Islandia",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Islip",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Islip Terrace",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Kings Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Lake Grove",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Lindenhurst",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Islandia",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Medford",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Miller Place",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Mount Sinai",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Nesconset",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Northport",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Oakdale",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Ocean Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Davis Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "East Patchogue",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Patchogue",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Port Jefferson Station",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Port Jefferson",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Rocky Point",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Lake Ronkonkoma",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Ronkonkoma",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Saint James",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Cherry Grove",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Fire Island Pines",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Sayville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Selden",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Shoreham",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Smithtown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Hauppauge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Smithtown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Sound Beach",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Stony Brook",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "Wading River",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "Long Island",
    county: "Suffolk County",
    areas: "West Islip",
    subareas: "",
    lower_subareas: "",
  },
];

const NJLayer = [
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Atlantic City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Egg Harbor Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Pleasantville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Ventnor City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Hammonton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Atlantic County",
    areas: "Margate City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Fort Lee",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Hackensack",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Teaneck",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Paramus",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Englewood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Ridgewood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Cliffside Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Bergen County",
    areas: "Edgewater",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Mount Laurel Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Moorestown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Evesham",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Willingboro",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Pemberton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Medford",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Burlington County",
    areas: "Pemberton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Camden",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Cherry Hill",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Voorhees Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Haddonfield",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Pennsauken Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Camden County",
    areas: "Glassboro",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Cape May",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Wildwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Ocean City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Stone Harbor",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "Middle Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cape May County",
    areas: "North Wildwood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Vineland",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Bridgeton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Millville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Upper Deerfield Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Cumberland County",
    areas: "Shiloh",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Newark",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Montclair",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "East Orange",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "West Orange",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Livingston",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "Bloomfield",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Essex County",
    areas: "City of Orange",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Woodbury",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Washington Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Sewell",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Glassboro",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "Mantua Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Gloucester County",
    areas: "West Deptford",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Hoboken",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Union City",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Bayonne",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Weehawken",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Harrison",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "East Newark",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Guttenberg",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Kearny",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "North Bergen",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Secaucus",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "West New York",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "Bergen-Lafayette",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "Historic Downtown",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "Journal Square",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "McGinley Square",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hudson County",
    areas: "Jersey City",
    subareas: "The Heights",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Flemington",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Clinton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "High Bridge",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Lambertville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Hunterdon County",
    areas: "Readington Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Trenton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Hamilton Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Princeton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Ewing Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Robbinsville Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Mercer County",
    areas: "Lawrence Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "New Brunswick",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Edison",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Woodbridge Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Perth Amboy",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "Sayreville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Middlesex County",
    areas: "East Brunswick",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Freehold Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Red Bank",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Asbury Park",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Long Branch",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Monmouth County",
    areas: "Neptune Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Morristown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Parsippany-Troy Hills",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Morris Plains",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Randolph",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Denville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Morris County",
    areas: "Mount Olive",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Toms River",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Lakewood",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Brick Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Barnegat Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Manchester Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Point Pleasant",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Ocean County",
    areas: "Jackson Township",
    subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Paterson",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Clifton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Wayne",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Little Falls",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Hawthorne",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Passaic County",
    areas: "Pompton Lakes",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Salem",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Pennsville Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Elmer",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Quinton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Salem County",
    areas: "Woodstown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Bridgewater",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Somerville",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Franklin Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "North Plainfield",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Hillsborough Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Somerset County",
    areas: "Bound Brook",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Newton",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Hopatcong",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Vernon Township",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Sparta",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Andover",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Sussex County",
    areas: "Andover",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Washington",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Hackettstown",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Phillipsburg",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Belvidere",
    subareas: "",
    lower_subareas: "",
  },
  {
    region: "New Jersey",
    county: "Warren County",
    areas: "Oxford",
    subareas: "",
    lower_subareas: "",
  },
];

export const locationLayer = [
  ...ManhattanLayer,
  ...BrooklynLayer,
  ...QueensLayer,
  ...BronxLayer,
  ...StatenLayer,
  ...NassauLayer,
  ...SuffolkLayer,
  ...NJLayer,
];

export const getLocation = (type, county, state = "") => {
  let data = [];
  if (state) {
    data = locationLayer.filter((d) => d.region === state || d.region.includes(state));
  } else {
    data = locationLayer.filter((d) => d.county === county || d.county.includes(county));
  }
  let dataSet = new Set();
  data.forEach((d) => {
    if (d.type !== "") {
      dataSet.add(d[type]);
    }
  });
  let finalData = [];
  dataSet.forEach((d) => {
    let obj = data.find((d1) => d1[type] === d);
    if (obj) {
      finalData.push(obj);
    }
  });
  return finalData;
};

export const getAllLocations = (type, value) => {
  let data = areas.filter((d) => d[type] === value);
  return data.sort((a, b) => a.name.localeCompare(b.name));
};
