import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { firestore } from "src/firebase";
import { Colors } from "src/theme/Colors";
import { priceFormat } from "src/utils/strings";

const ListingDetailsPriceHistoryTable = ({
  listingId,
}) => {

  const [priceHistory, setPriceHistory] = useState()

  const loadPriceHistory = () => {
    getDocs(collection(firestore, `listing/${listingId}/prices`)).then(docsSnapshot => {
      let prices = []
      docsSnapshot.forEach(doc => {
        prices.push(doc.data())
      })
      setPriceHistory(prices.sort((a, b) => Number(a.at) - Number(b.at)))
    })
  }

  useEffect(() => {
    if (listingId) {
      loadPriceHistory()
    }
  }, [listingId])

  return (
    <Flexbox style={styles.container}>
      <Flexbox row style={styles.row}>
        <Flexbox style={styles.dateColumn}>
          <TypeFace semiBold size={11}>
            DATE
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.priceColumn}>
          <TypeFace semiBold size={11}>
            PRICE
          </TypeFace>
        </Flexbox>
      </Flexbox>
      {priceHistory?.map((priceHistoryItem, index) => (
        <Flexbox row key={`price-${index}`} style={{
          ...styles.row,
          backgroundColor: index % 2 === 0 ? 'transparent' : Colors.optionBack,
        }}>
          <Flexbox style={styles.dateColumn}>
            <TypeFace medium size={12}>
              {moment(new Date(Number(priceHistoryItem.at))).format('M/D/YYYY')}
            </TypeFace>
          </Flexbox>
          <Flexbox style={styles.priceColumn}>
            <TypeFace semiBold size={12}>
              ${priceFormat(priceHistoryItem.price)}
            </TypeFace>
          </Flexbox>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default ListingDetailsPriceHistoryTable;

const styles = {
  container: {
    marginTop: 10,
    width: 300,
    borderTop: '1px solid #dfdfdf',
    borderRight: '1px solid #dfdfdf',
    borderLeft: '1px solid #dfdfdf',
  },
  row: {
    width: '100%',
    backgroundColor: Colors.gray3,
    height: 50,
    alignItems: 'center',
    borderBottom: '1px solid #dfdfdf'
  },
  dateColumn: {
    width: 100,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 20
  },
  priceColumn: {
    width: 200,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 20
  }
}