import { useEffect, useState } from "react"
import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"
import { list } from "firebase/storage"

const EditiListingSectionBar = (props) => {

  const { isMobile } = useMediaQuery()

  const [current, setCurrent] = useState(props.selectedIndex)

  const sectionNames = ["MANAGEMENT", "MEET NOW", "BUILDING", "UNIT", "PHOTOS & MEDIA", "MARKETING"]

  const sectionNamesNew = ["MANAGEMENT", "MEET NOW", "BUILDING", "INTERIOR", "PHOTOS & MEDIA", "MARKETING"]

  useEffect(() => {
    setCurrent(props.selectedIndex)
  }, [props.selectedIndex])

  return (
    <Flexbox row style={{
      ...styles.sectionsBar,
      marginTop: isMobile ? 0 : 20,
    }}>
     {(props.listing?.building?.type === 'single-family' || props.listing?.building?.type === 'multi-family' || props.listing?.building?.type === 'townHouse') ? 
     sectionNamesNew.map((sectionNamesNew, index) => (
        <div key={index} style={styles.sectionItem}>
          <TypeFace
            key={index}
            bold
            size={16}
            style={{
              cursor: 'pointer',
              paddingBottom: 10,
              borderBottom: current === index ? '4px solid #3151B7' : 'none',
            }}
            onClick={() => props.onSelectTab(index)}
          >
          {sectionNamesNew}
          </TypeFace>
        </div>
      ))
      :
      sectionNames.map((sectionNames, index) => (
        <div key={index} style={styles.sectionItem}>
          <TypeFace
            key={index}
            bold
            size={16}
            style={{
              cursor: 'pointer',
              paddingBottom: 10,
              borderBottom: current === index ? '4px solid #3151B7' : 'none',
            }}
            onClick={() => props.onSelectTab(index)}
          >
            {sectionNames}
          </TypeFace>
        </div>
      ))
      }
    </Flexbox>
  )
}

export default EditiListingSectionBar

const styles = {
  sectionsBar: {
    // backgroundColor: Colors.primary,
    height: 74,
    width: '100%',
    marginTop: 20,
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    borderBottom: '1px solid #dfdfdf',
  },
  sectionItem: {
    marginRight: 30,
    cursor: 'pointer',
    display: 'flex'
  }
}