export const STORE_USER_NAME = "STORE_USER_NAME"
export const STORE_LASTEST_UPDATED_DATE_TIME = "STORE_LASTEST_UPDATED_DATE_TIME"
export const STORE_GRANT_TOKEN = "STORE_GRANT_TOKEN"

export const storeUserName = (userName) => {
  return {
    type: STORE_USER_NAME,
    payload: userName
  }
}

export const storeGrantToken = (grantToken) => {
  return {
    type: STORE_GRANT_TOKEN,
    payload: grantToken
  }
}


export const storeLatestUpdatedDateTime = (lastestUpdateDateTime) => {
  return {
    type: STORE_LASTEST_UPDATED_DATE_TIME,
    payload: lastestUpdateDateTime
  }
}