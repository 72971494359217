import React from "react"
import TypeFace from "../typography/Typefaces"
import HomeSearchBox from "./HomeSearchBox"
import homeBg2 from "src/assets/images/bg/bg-home2.png"
import MeetNowButton from "../button/MeetNowButton"
import Flexbox from "../wrapper/Flexbox"
import HomeSpecialActionsPanel from "./HomeSpecialActionsPanel"
import HomeBuildProfilePanel from "./HomeBuildProfilePanel"
import useMediaQuery from "src/hooks/useMediaQuery"

const HomePanel2 = () => {

  const { isMobile } = useMediaQuery()

  return (
    <Flexbox style={{
      ...styles.container,
      // height: isMobile ? 'unset' : Math.min(1440, window.innerWidth) / 1440 * 899,
    }}>
      <Flexbox style={styles.middleBoxing}>
        <HomeSpecialActionsPanel />
        {!isMobile && (<HomeBuildProfilePanel />)}
      </Flexbox>
    </Flexbox>
  )
}

export default HomePanel2

const styles = {
  container: {
    zIndex: 0,
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    // height: Math.min(1440, window.innerWidth) / 1440 * 899,
    backgroundImage: `url(${homeBg2})`,
    backgroundSize: 'cover'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  homeSearchBox: {
    display: 'flex',
    marginTop: 56,
    marginBottom: 64,
  },
  middleBoxing: {
    width: '90%',
    maxWidth: 1178,
    borderRadius: 16,
    alignItems: 'flex-start',
    padding: '0px 20px 20px',
  },
}