import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router";

const { Text } = Typography;

const AuthLayout = ({ children, title, headerRight = null, gap = 20, back }) => {
  const navigate = useNavigate();
  return (
    <Flex gap={gap} vertical justify="flex-start" align="flex-start">
      <Flex row align="center" justify="space-between" style={{ width: "100%" }}>
        <Flex align="center">
          {back && <Button size="large" type="link" onClick={() => navigate(-1)} icon={<ArrowLeftOutlined />} />}
          <Text strong style={{ textTransform: "uppercase", fontSize: 20 }}>
            {title}
          </Text>
        </Flex>
        <>{headerRight && headerRight}</>
      </Flex>

      {children}
    </Flex>
  );
};

export default AuthLayout;
