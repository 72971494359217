import React, { useEffect, useState } from "react";
import { Colors } from "src/theme/Colors";
import Button from "../button/Button";
import TextInput from "../input/TextInput";
import TypeFace from "../typography/Typefaces";
import Flexbox from "../wrapper/Flexbox";
import Modal from "react-modal";
import { validateEmailAddress } from "src/utils/strings";
import { auth, firestore } from "src/firebase";
import {
  EmailAuthProvider,
  linkWithCredential,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  PhoneAuthProvider,
  linkWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { useAlert } from "src/hooks/alert/AlertContext";
import Loading from "react-fullscreen-loading";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import ReactSelect from "react-select";
import useMediaQuery from "src/hooks/useMediaQuery";
import { doc, updateDoc } from "firebase/firestore";
import { userRoles } from "src/model/types";
import { useNavigate } from "react-router-dom";
import VerificationModal from "./VerificationModal";

const ConnectProfileModal = (props) => {
  var recaptchaWrapperRef;
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();

  const { userProfile } = useFirestore();

  const { showAlert } = useAlert();
  const [name, setName] = useState(userProfile?.name ?? "");
  const [emailAddress, setEmailAddress] = useState(userProfile?.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [accountVerified, setAccountVerified] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  const [isVerificationModalOpen, setIsVerificationModalOpen] = React.useState(false);
  const [confirmResult, setConfirmResult] = useState("");
  function afterOpenModal() {
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    props.closeModal();
    document.body.style.overflow = "auto";
  }

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
      },
      auth
    );

    window.recaptchaVerifier.verify();
  };

  const sendDataToParent = (uid) => {
    updateDoc(doc(firestore, `profiles/${uid}`), {
      profileCreated: true,
      name,
      nameL: name.toLowerCase(),
      emailL: emailAddress.toLowerCase(),
      email: emailAddress,
      profileCreatedAt: new Date().getTime(),
    });
    closeModal();
  };
  const handleLogin = () => {
    if (emailAddress === "") {
      setError("Please enter your email address");
    } else if (!validateEmailAddress(emailAddress)) {
      // && emailAddress.includes("@")
      setError("Please enter a valid email address");
    } else if (password === "" && phoneNumber === "") {
      setError("Please enter your items");
    } else if (password !== confirmPassword && phoneNumber === "") {
      setError("Password does not match");
    } else {
      setError("");
      setProcessing(true);
      //if(emailAddress.includes('@')) {
      const credentials = EmailAuthProvider.credential(emailAddress, password);
      linkWithCredential(auth.currentUser, credentials)
        .then((usercred) => {
          updateDoc(doc(firestore, `profiles/${usercred.user.uid}`), {
            profileCreated: true,
            name,
            nameL: name.toLowerCase(),
            emailL: emailAddress.toLowerCase(),
            email: emailAddress,
            profileCreatedAt: new Date().getTime(),
          });
          setProcessing(false);
          sendEmailVerification(auth.currentUser).then(() => {
            //auth.signOut()
            showAlert(true, "Please check your inbox to verify your account", [
              {
                title: "Ok",
                onClick: () => {
                  showAlert(false);
                  closeModal();
                  navigate("/");
                },
              },
            ]);
          });
        })
        .catch((err) => {
          console.log("Error => ", err);
          showAlert(true, "Email exists. Please log in with your credentials.");
          setProcessing(false);
          closeModal();
        });
      /* }
      else {
        // Create Profile With PHONE NUMBER
        createProfileWithPhoneNumber()
      } */
    }
  };

  const createProfileWithPhoneNumber = () => {
    fetch("https://us-central1-listmeetdev.cloudfunctions.net/checkPhoneNumberExists", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("resPHONEExist=", res);
        if (res.phoneNumberExists === false) {
          setUpRecaptcha();
          const appVerifier = await window.recaptchaVerifier;
          console.log("appVerifier", appVerifier);
          linkWithPhoneNumber(auth.currentUser, phoneNumber, appVerifier)
            .then((usercred) => {
              setProcessing(false);
              setConfirmResult(usercred);
              console.log(usercred.verificationId);
              setIsVerificationModalOpen(true);
              if (appVerifier) {
                appVerifier.clear();
                document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
              }
            })
            .catch((err) => {
              console.log(err);
              setIsVerificationModalOpen(false);
              setError("Please enter valid phone number to signup");
              if (appVerifier) {
                appVerifier.clear();
                document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
              }
              setProcessing(false);
            });
        } else {
          setProcessing(false);
          setError("Phone number already in use. Please login instead.");
        }
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim()?.replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
  };

  const onChangeEmailPhone = (e) => {
    const emailPhone = e.target.value;
    if (emailPhone.length === 0) {
      setPhoneNumber("");
      setEmailAddress("");
      setError("");
    }
    if (emailPhone.includes("@") === false) {
      if (/^\d+$/.test(emailPhone)) {
        if (emailPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(emailPhone);
          const phoneOnly = emailPhone?.replace("(", "")?.replace(")", "")?.replace("-", "")?.replace(" ", "");
          const countryCodetargetValue = "+1" + phoneOnly;
          console.log("countryCodetargetValue=", countryCodetargetValue);
          setPhoneNumber(countryCodetargetValue);
          setEmailAddress(targetValue);
        } else if (emailAddress.length > 10) {
          setEmailAddress(emailAddress);
        } else {
          setEmailAddress(emailPhone);
        }
      } else {
        if (emailAddress.includes("(") && emailPhone.length > 14) {
          setEmailAddress(emailPhone.slice(0, 14));
        } else {
          setEmailAddress(emailPhone);
        }
      }
    } else {
      setEmailAddress(emailPhone);
    }
  };

  useEffect(() => {
    setEmailAddress(userProfile?.email ?? "");
    setName(userProfile?.name ?? "");
  }, [userProfile]);

  return (
    <>
      <div ref={(ref) => (recaptchaWrapperRef = ref)} id="recaptchaWrapper">
        <div id="recaptcha-container"></div>
      </div>

      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="mymodal"
        overlayClassName="myoverlay"
      >
        <Flexbox style={styles.container}>
          <TypeFace bold size={18}>
            Create ListMeet Account
          </TypeFace>
          <TextInput
            style={styles.emailInput}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            style={styles.passwordInput}
            placeholder="Email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            //onChange={onChangeEmailPhone}
          />
          {phoneNumber === "" && (
            <TextInput
              style={styles.passwordInput}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {phoneNumber === "" && (
            <TextInput
              style={styles.passwordInput}
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
          <Flexbox style={{ width: "100%", gap: 10, marginTop: isMobile ? 5 : 10 }}>
            <TypeFace style={{ alignSelf: "flex-start" }}>I am a</TypeFace>
            <div style={{ width: isMobile ? 200 : 300 }}>
              <ReactSelect
                options={userRoles}
                value={{
                  value: "customer",
                  label: "Renter / Buyer",
                }}
                isSearchable={false}
                isDisabled
              />
            </div>
          </Flexbox>
          {!!error && (
            <TypeFace size={11} color="red">
              {error}
            </TypeFace>
          )}
          <Flexbox row style={{ ...styles.buttons, marginTop: 40 }}>
            <Button primary onClick={handleLogin} style={styles.loginButton}>
              Create Account
            </Button>
          </Flexbox>
          {/* <Flexbox row style={styles.signup}>
          <TypeFace size={11}>
            If you have account,&nbsp;
            <TypeFace onClick={() => {
              setConfirmPassword("")
              setPassword("");
              setName("")
              setEmailAddress("");
            }} medium size={11} color={Colors.primary} style={{ cursor: 'pointer' }}>
              Log in
            </TypeFace>
          </TypeFace>
        </Flexbox> */}
        </Flexbox>
        {processing && <Loading loading background="#0005" loaderColor="white" />}
      </Modal>
      <VerificationModal
        modalIsOpen={isVerificationModalOpen}
        phoneNumber={phoneNumber}
        sendDataToParent={sendDataToParent}
        confirmationResult={confirmResult}
        closeModal={() => setIsVerificationModalOpen(false)}
      />
    </>
  );
};

export default ConnectProfileModal;

const styles = {
  container: {
    width: 300,
    padding: "40px 30px 30px",
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: "100%",
    marginTop: 10,
    justifyContent: "space-between",
    gap: 20,
  },
  signup: {
    width: "100%",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  forgotPasswordButton: {
    height: 40,
    alignSelf: "flex-end",
  },
  socialButtons: {
    width: 300,
    height: 44,
  },
  socialButtonsPanel: {
    marginTop: 30,
    gap: 15,
  },
  emailInput: {
    width: "100%",
    marginTop: 30,
  },
  passwordInput: {
    width: "100%",
    marginTop: 10,
  },
};
