import React, { useState } from 'react';
import Button from '../button/Button';
import TextInput from '../input/TextInput';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { useAlert } from 'src/hooks/alert/AlertContext';
import Loading from 'react-fullscreen-loading';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';

const SubmitAgencyInformationModal = (props) => {

  const { userProfile } = useFirestore()

  const navigate = useNavigate()
  const {showAlert} = useAlert()

  const [licenseNumber, setLicenseNumber] = useState("")
  const [licenseName, setLicenseName] = useState("")
  const [error, setError] = useState("")

  const [processing, setProcessing] = useState(false)

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleSubmit = () => {   
    if (licenseNumber === '') {
      setError("Agency ID (License #) must be submitted.")
    } else if (licenseName === '') {
      setError("Agency name must be submitted.")
    } else {
      setError('')
      setProcessing(true)
      setDoc(doc(firestore, 'agencies', licenseNumber), {
        id: licenseNumber,
        name: licenseName,
        creator: userProfile.userId,
        createdAt: new Date().getTime()
      }).then(() => {
        setProcessing(false)
        updateDoc(doc(firestore, 'profiles', userProfile.userId), {
          agency: licenseNumber
        })
        closeModal()
      }).catch(err => {
        setProcessing(false)
        setError('Failed to submit agency')
      })
    }
  }

  const handleCancel = () => {
    setLicenseNumber('')
    setLicenseName('')
    closeModal()
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={styles.container}>
        <TypeFace bold size={18}>
          Create new agency
        </TypeFace>
        {/* <TypeFace size={14}>
          Submit your request to create your listings
        </TypeFace> */}
        <TypeFace bold size={11} style={{ marginTop: 20 }}>
          AGENCY ID
        </TypeFace>
        <TextInput 
          style={styles.licenseNumberInput}
          placeholder='#'
          value={licenseNumber}
          onChange={e => setLicenseNumber(e.target.value)}
        />
        <TypeFace bold size={11} style={{ marginTop: 15 }}>
          AGENCY NAME
        </TypeFace>
        <TextInput 
          style={styles.licenseNumberInput}
          placeholder='Enter a name'
          value={licenseName}
          onChange={e => setLicenseName(e.target.value)}
        />
        {!!error && (
          <TypeFace color='red' size={11}>
            {error}
          </TypeFace>
        )}
        <Flexbox row style={styles.buttons}>
          <Button primary onClick={handleSubmit} style={styles.loginButton}>
            Submit
          </Button>
          <Button secondary onClick={handleCancel} style={styles.loginButton}>
            Cancel
          </Button>
        </Flexbox>
      </Flexbox>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Modal>
  )
}

export default SubmitAgencyInformationModal

const styles = {
  container: {
    width: 300,
    padding: '40px 30px 30px',
    alignItems: 'flex-start',
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: '100%',
    marginTop: 30,
    justifyContent: 'space-between',
    gap: 20,
  },
  signup: {
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  forgotPasswordButton: {
    height: 40,
    alignSelf: 'flex-end',
  },
  socialButtons: {
    width: 300,
    height: 44,
  },
  socialButtonsPanel: {
    marginTop: 30,
    gap: 15,
  },
  licenseNumberInput: {
    width: '100%',
    marginTop: 5,
  },
  passwordInput: {
    width: '100%',
    marginTop: 10,
  }
}