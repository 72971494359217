import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import Button from "src/components/button/Button"
import HomeBlueMenuBar from "src/components/home/HomeBlueMenuBar"
import HomeFooter from "src/components/home/HomeFooter"
import HomeHeaderDesktop from "src/components/home/HomeHeaderDesktop"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"

const stripePromise = loadStripe(process.env.REACT_APP_stripe_api_key);

const PaymentStatus = ({
  clientSecret
}) => {

  const stripe = useStripe();
  
  const [message, setMessage] = useState("")

  const process = (intentClientSecret) => {
    stripe
      .retrievePaymentIntent(intentClientSecret)
      .then(({paymentIntent}) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage("Payment was successful. Thank you for your payment!")
            break;
          case 'processing':
            setMessage("Payment is being processed.")
            break;
          case 'requires_payment_method':
            setMessage("Payment failed. Payment method is not validated.")
            break;
          default:
            setMessage("Payment failed. Try again later")
            break;
        }
      });
  }

  useEffect(() => {

    if (!stripe || !clientSecret) {
      return;
    }

    process(clientSecret)
  }, [clientSecret, stripe])

  return (
    <TypeFace semiBold size={24}>
      {message}
    </TypeFace>
  )
}

const CustomerPaymentCompleted = () => {

  const navigate = useNavigate()

  const searchParams = new URLSearchParams(document.location.search) 
  const [clientSecret, setClientSecret] = useState()

  useEffect(() => {
    const paymentIntent = searchParams.get("payment_intent")
    const sec = searchParams.get("payment_intent_client_secret")

    if (sec) {
      setClientSecret(sec)
    }
  }, [searchParams])

  if (!clientSecret) {
    return null
  }

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <HomeBlueMenuBar />
          <Flexbox style={{ width: '100%', marginBottom: 80 }}>
            <Flexbox style={styles.container}>
              {clientSecret && (
                <Elements stripe={stripePromise} options={{
                  clientSecret
                }}>
                  <PaymentStatus clientSecret={clientSecret} />    
                </Elements>
              )}
              <Button secondary onClick={() => navigate(-1)} style={{
                height: 45,
                marginTop: 30,
              }}>
                Back to listing
              </Button>
            </Flexbox>
          </Flexbox>
          <HomeFooter />
        </div>
      </div>
    </div>
  )
}

export default CustomerPaymentCompleted

const styles = {
  container: {
    width: 500,
    height: 300,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
}