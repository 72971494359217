import React, { Children, useEffect, useRef, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "./carousel-customize.css"
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from '../typography/Typefaces';
import { Colors } from 'src/theme/Colors';

const ListingPhotosCarousel = (props) => {
  const photos = props.photos
  const isSold = props.isSold
  const isRented = props.isRented

  const { isMobile } = useMediaQuery()

  const [selectedPhotoItem, setSelectedPhotoItem] = useState(0)

  useEffect(() => {
    setSelectedPhotoItem(0)
  }, [photos])
  
  return (
    <Carousel
      emulateTouch
      showArrows
      selectedItem={selectedPhotoItem}
      onChange={(index, item) => setSelectedPhotoItem(item)}
      renderThumbs={(children) => {
        return photos?.map((photo, index) => (
          <div key={`photo-${index}`} style={{ 
            width: 80,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 0,
          }}>
            {photo.type === 'floor' ? (
              <TypeFace color={Colors.primary} style={{ marginLeft: 4}} size={11} medium>FLOOR PLAN</TypeFace>
            ) : (
              <img
                alt=""
                src={photo.url}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            )}
          </div>  
        ))
      }}
    >
      {photos?.map((photo, index) => (
        <div key={`photo-${index}`} style={{ width: isMobile ? '100%' : 800, height: 400 }}>
          {isSold && 
            <div style={styles.soldInfo}>
              <TypeFace bold color="red">
                    SOLD
              </TypeFace>
            </div>
          }
          {isRented && 
            <div style={styles.soldInfo}>
              <TypeFace bold color="red">
                    RENTED
              </TypeFace>
            </div>
          }
          <img
            alt=""
            src={photo.url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </div>  
      ))}
    </Carousel>
  )
}

export default ListingPhotosCarousel

const styles = {
  soldInfo: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
}


{/* <Slider
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
      >
        <div>
          <img
            alt=""
            src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
            width={800}
            height={400}
          />
        </div>
        <div>
          <img alt="" src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
          width={800} height={400} />
        </div>
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={2}
        swipeToSlide={true}
        focusOnSelect={true}
        adaptiveHeight
      >
        <div style={{ width: 100, height: 80}}>
          <img
            alt=""
            src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
            width={100}
            height={80}
          />
        </div>
        <div style={{ width: 100, height: 80}}>
          <img
            alt=""
            src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
            width={100}
            height={80}
          />
        </div>
      </Slider> */}
      {/* <Slider {...mainSettings}>
        <div>
          <img
            alt=""
            src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
            width={800}
            height={400}
          />
        </div>
        <div>
          <img alt="" src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
          width={800} height={400} />
        </div>
      </Slider>
      <div style={{ width: '100%', backgroundColor: 'green'}}>
        <Slider {...thumbsSettings}>
          <div>
            <img
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
              width={100}
              height={80}
            />
          </div>
          <div>
            <img alt=""
              src="https://firebasestorage.googleapis.com/v0/b/listmeet-80000.appspot.com/o/photos%2Ftz0oUaOwBB8uVESLHSoG%2Fphoto%2F5x0A8cPSv?alt=media&token=e7487025-df25-4a8a-9f74-623621f16dbd"
              width={100}
              height={80} />
          </div>
        </Slider>
      </div> */}