import { useParams } from "react-router-dom"

const StripeAccountRefresh = () => {
  const params = useParams()

  console.log("refresh with params => ", params)

  return (
    <div />
  )
}

export default StripeAccountRefresh