import { SET_DRAFT_VALUE, STORE_LAST_PASSED_TIME, UPDATE_DRAFT_VALUE } from "./actions"

const initialState = {
  currentDraftValue: null,
  lastPassed: null
}

const draftReducer = (state = initialState, action) => {
  switch (action.type) {
  case STORE_LAST_PASSED_TIME:
    console.log("last passed time storing")
    return { ...state, lastPassed: new Date().getTime()}
  case SET_DRAFT_VALUE:
    return { ...state, currentDraftValue: action.payload }
  case UPDATE_DRAFT_VALUE:
    if (Object.keys(action.payload).length > 0) {
      const key = Object.keys(action.payload)[0]
      if (key.includes(".")) {
        const keys = key.split(".")
        if (keys[0] === "unitInfo") {
          return { ...state, currentDraftValue: {
            ...state.currentDraftValue,
            unitInfo: {
              ...state.currentDraftValue.unitInfo,
              [keys[1]]: action.payload[key]
            }
          }}  
        } else if (keys[0] === 'building') {
          console.log("++++++++", action.payload)
          return { ...state, currentDraftValue: {
            ...state.currentDraftValue,
            building: {
              ...state.currentDraftValue.building,
              [keys[1]]: action.payload[key]
            }
          }}  
        } else {
          console.log("Unexpected key")
        }
      } else {
        return { ...state, currentDraftValue: {
          ...state.currentDraftValue,
          ...action.payload
        }}  
      }
    } else {
      return { ...state, currentDraftValue: {
        ...state.currentDraftValue,
        ...action.payload
      }}
    }
  default:
    return state
  }
}

export default draftReducer