import moment from "moment"

const TimeSelect = (props) => {

  const timeValue = (index) => {
    // return `${String((Math.floor(index / 4) + 8) % 12).padStart(2, 0)}:${String((index % 4) * 15).padStart(2, 0)}${Math.floor(index / 4) + 8 < 13 ? 'am' : 'pm'}`
    return moment(`${Math.floor(index / 4) + 8}:${String((index % 4) * 15).padStart(2, 0)}`, 'H:mm').format('h:mm a')
  }

  return (
    <select defaultValue="" value={props.value} style={props.style} onChange={e => {
      const value = e.target.value
      props.onChangeValue(value)
      props.onChangeHourMinute(Math.floor(value / 4) + 8, (value % 4) * 15)
    }}>
      <option value=""></option>
      {new Array(52).fill(0).map((_, index) => (
        <option value={index}>{timeValue(index)}</option>
      ))}
    </select>
  )
}

export default TimeSelect