import { Table } from "antd";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { firestore } from "src/firebase";
import AuthLayout from "src/layouts/AuthLayout";
import { roleLabelFromValue } from "src/model/types";
import styles from "../../admin.module.scss";

const AdminReleasedAgents = () => {
  const unmountedRef = useRef(false);
  const [agencies, setAgencies] = useState();

  useEffect(() => {
    unmountedRef.current = false;
    const snapshotListener = onSnapshot(
      query(
        collection(firestore, "profiles"),
        orderBy("releasedDate", "desc"),
        where("role", "in", ["agent", "salesperson"]),
        where("agencyReleased", "==", true)
      ),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            console.log("doc=", doc.data());
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setAgencies(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      snapshotListener();
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "License Number",
      dataIndex: "licenseNumber",
    },
    {
      title: "License Name",
      dataIndex: "licenseName",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (_, record) => roleLabelFromValue(record.role),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_) => "Released",
    },
    {
      title: "Released Date",
      dataIndex: "releasedDate",
      render: (_, record) => moment(record.releasedDate).format("MMM DD, yyyy"),
    },
  ];

  return (
    <AuthLayout title="Released Agents">
      <Table
        dataSource={agencies}
        columns={columns}
        style={{ width: "100%" }}
        loading={false}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
      />
    </AuthLayout>
  );
};

export default AdminReleasedAgents;
