import Flexbox from "src/components/wrapper/Flexbox"
import IconStarFill from "src/assets/images/icons/icon-star-fill.svg"
import IconStarNone from "src/assets/images/icons/icon-star-none.svg"
import { useState } from "react"

const StarRate = ({ value, size = 48, onChange }) => {
  const [maxStar] = useState(5)

  const stars = []
  for (let i = 1; i <= maxStar; i++) {
    const star = i <= value ? <img src={IconStarFill} width={size} height={size} /> : <img src={IconStarNone} width={size} height={size} />
    stars.push(
      onChange
        ? <Flexbox key={i} activeOpacity={0.7} onClick={onChange ? () => onChange && onChange(i) : undefined}>{star}</Flexbox>
        : <Flexbox key={i}>{star}</Flexbox>
    )
  }
  return (
    <Flexbox style={styles.root}>
      <Flexbox style={styles.stars}>{stars}</Flexbox>
    </Flexbox>
  )
}

export default StarRate

const styles = {
  root: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  },
  stars: {
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 4,
  },
}