import React from 'react'
import ListingDetailsAgencyInformationPanel from './ListingDetailsAgencyInformationPanel'

const ListingDetailsSideInformationView = ({
  isMyListing,
  isMyAgencyListing,
  agency,
  teamMembers,
  isMeetLaterAlreadySetup,
  meetLaterWhen,
  messagingThreadId,
  handleOpenMessageRoom,
  handleOpenMeetLater
}) => {
  return (
    <>
      {!isMyListing && (
        <>
          <ListingDetailsAgencyInformationPanel
            withMessageButton={isMyAgencyListing}
            agency={agency}
            teams={teamMembers}
            isMeetLaterAlreadySetup={isMeetLaterAlreadySetup}
            meetLaterWhen={meetLaterWhen}
            messagingThreadId={messagingThreadId}
            handleOpenMessageRoom={handleOpenMessageRoom}
            handleOpenMeetLater={handleOpenMeetLater}
          />
        </>
      )}
    </>
  )
}

export default ListingDetailsSideInformationView
