import React, { useEffect, useState } from "react";
import HomeHeaderDesktop from "src/components/home/HomeHeaderDesktop";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import ManageSideMenu from "./ManageSideMenu";
import MyProperties from "./myProperties/MyProperties";
import Settings from "./settings/Settings";
import Agents from "./agents/Agents";
import Messages from "../messages/Messages";
import Schedules from "../schedules/Schedules";
import useMediaQuery from "src/hooks/useMediaQuery";
import Sidebar from "src/components/sidebar/Sidebar";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { useAlert } from "src/hooks/alert/AlertContext";
import { UserRoleValue } from "src/model/types";
import { Flex } from "antd";

const Manage = () => {
  const params = useParams();
  const { userProfile } = useFirestore();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const { showAlert } = useAlert();
  const { isMobile } = useMediaQuery();
  let subPage = (params["*"] ?? "settings").split("/")[0];
  const navigate = useNavigate();
  const location = useLocation();

  const handlePage = () => {
    setMobileMenuOpened(false);
    if (subPage !== "settings") {
      if (userProfile?.profileBlocked) {
        if (userProfile?.role === UserRoleValue.ManageMent) {
          showAlert(
            true,
            "Your company has been blocked, contact Listmeet support (support@listmeet.com) to reinstate your profile."
          );
        } else {
          showAlert(
            true,
            "Your license has been blocked. Contact Listmeet support (support@listmeet.com) to reinstate your profile."
          );
        }
      } else {
        if (userProfile?.role === UserRoleValue.ManageMent) {
          if (userProfile.profileStatus === undefined) {
            showAlert(true, "Listmeet has not approved your company yet.");
          } else if (userProfile.profileStatus !== "approved") {
            showAlert(true, "Listmeet has not approved your company yet.");
          }
        } else {
          if (userProfile?.agencyApproved === false) {
            if (userProfile?.role === UserRoleValue.Broker) {
              showAlert(true, "Listmeet has not approved your brokerage yet.");
            } else {
              showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.');
            }
          } else if (userProfile?.suspended === true) {
            if (userProfile?.role === UserRoleValue.Broker) {
              showAlert(true, "Your brokerage has been suspended.");
            } else {
              showAlert(true, "Contact your broker to reinstate your account.");
            }
          }
        }
      }
      navigate("/manage/" + subPage);
    }
  };
  useEffect(() => {
    handlePage();
  }, [subPage]);

  const routes = [
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/listings",
      element: <MyProperties />,
    },
    {
      path: "/agents",
      element: <Agents />,
    },
    {
      path: "/schedules",
      element: <Schedules />,
    },
    {
      path: "/messages",
      element: <Messages />,
    },
    {
      path: "/messages/:messageId",
      element: <Messages />,
    },
  ];

  return (
    <div className="App">
      {isMobile && (
        <Sidebar
          isOpen={mobileMenuOpened}
          pageWrapId={"page-wrap"}
          outerContainerId={"App"}
          onStateChange={(state) => setMobileMenuOpened(state.isOpen)}
        />
      )}
      <div id="page-wrap">
        <HomeHeaderDesktop />
        <Flex justify="center">
          <div style={styles.content}>
            {!isMobile && <ManageSideMenu />}
            {!isMobile && <div style={styles.vertSeparator} />}
            <div style={{ ...styles.contentArea, padding: isMobile ? 20 : 30 }}>
              <Routes>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Routes>
            </div>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default Manage;

const styles = {
  content: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 70px)",
    width: "100%",
    maxWidth: 1178,
    borderTop: "1px solid #dfdfdf",
  },
  contentArea: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 30,
  },
  vertSeparator: {
    width: 1,
    height: "100%",
    backgroundColor: "#DFDFDF",
  },
};
