import debounce from "lodash.debounce";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import TextInput from "../input/TextInput";
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery";

const EditListingMeetNow = ({
  highlightErrorFields,
  listing,
  onUpdate
}) => {

  const { id } = useParams()
  const { isMobile } = useMediaQuery()

  const changePaymentAmount = (event) => {
    const changedValue = event.target.value
    onUpdate({
      showingPaymentAmount: changedValue
    })
  }

  const debouncePaymentAmount = useMemo(() => {
    return debounce(changePaymentAmount, 500);
  }, []);

 

  useEffect(() => {
    if (listing?.showingPaymentAmount) {
      document.getElementById('input-showing-payment-amount').value = listing.showingPaymentAmount
    }
  }, [listing])

  return (
    <Flexbox style={styles.container}>
      <Flexbox row>
        <TypeFace for="available-to-meet" medium size={14} style={{ marginRight: 20 }}>
          Meet Now Settings
        </TypeFace>
      </Flexbox>
      <Flexbox row style={{ marginBottom: 20 }}>
        <TypeFace light size={14} style={{ marginTop: 16 }} color={(highlightErrorFields && listing?.meetEnabled === undefined) ? 'red' : 'black'}>
          Do you want to enable Meet Now feature for this property? *
        </TypeFace>
       </Flexbox>
       <Flexbox row style={{ marginBottom: 20 }}>
         <input type="checkbox" id="available-to-meet" checked={listing?.meetEnabled} onChange={e => {
          onUpdate({ meetEnabled: true })
         }} />
         <TypeFace light size={14} style={{marginLeft: 5, marginRight: 20}}>
          Yes
        </TypeFace>
        <input type="checkbox" id="available-to-meet" checked={!listing?.meetEnabled} onChange={e => {
          onUpdate({ meetEnabled: false,
            showingPaymentAmount: 0
          })
         }}  />
         <TypeFace light size={14} style={{marginLeft: 5}}>
          No
        </TypeFace>
       </Flexbox>
      
      <Flexbox row style={{ alignItems: 'flex-end'}}>
        <TypeFace medium size={isMobile ? 12 : 14} color={(highlightErrorFields && listing?.meetEnabled && !listing.showingPaymentAmount) ? "red" : "black"}>
          Payment to showing <TypeFace size={isMobile ? 14 : 16}>*</TypeFace>
        </TypeFace>
        <TextInput
          id='input-showing-payment-amount'
          style={{
            ...styles.input,
            width: isMobile ? 160 : 200,
            borderColor: (highlightErrorFields && listing?.meetEnabled && !listing.showingPaymentAmount) ? 'red' : 'black'
          }}
          placeholder=">$25"
          onChange={debouncePaymentAmount}
          onKeyDown={(event) => {
            if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
        />
      </Flexbox>
      <TypeFace size={12} style={{ marginTop: 10, textAlign: 'left' }}>
        Minimum $25. Showing agent will see this amount when they accept showing.
      </TypeFace>

    </Flexbox>
  )
}

export default EditListingMeetNow

const styles = {
  container: {
    padding: "30px 0",
    alignItems: 'flex-start'
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  input: {
    width: 200,
    height: 30,
    marginLeft: 30,
    borderRadius: 0,
  },
  teamMemberRow: {
    width: '100%',
    height: 44,
    paddingLeft: 20,
    borderLeft: '2px solid #ccc',
    marginTop: 10,
  },
  internalnotes: {
    width: '100%',
    height: 120,
  }
}