import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Button from '../button/Button';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import Modal from 'react-modal';
import ReactDatePicker from "react-datepicker"
import TextInput from '../input/TextInput';
import TimeSelect from '../time/TimeSelect';
import useMediaQuery from 'src/hooks/useMediaQuery';

const AddOpenHouseModal = (props) => {

  const { isMobile } = useMediaQuery()

  const [date, setDate] = useState(props.schedule?.date);
  const [startTime, setStartTime] = useState(props.schedule ? (props.schedule.start.h - 8) * 4 + (props.schedule.start.m / 15) : undefined);
  const [startHour, setStartHour] = useState(props.schedule?.start.h);
  const [startMinute, setStartMinute] = useState(props.schedule?.start.m);
  const [endTime, setEndTime] = useState(props.schedule ? (props.schedule.end.h - 8) * 4 + (props.schedule.end.m / 15) : undefined);
  const [endHour, setEndHour] = useState(props.schedule?.end.h);
  const [endMinute, setEndMinute] = useState(props.schedule?.end.m);

  const [byAppointmentOnly, setByAppointmentOnly] = useState(props.schedule?.appointmentOnly)

  useEffect(() => {
    setDate(props.schedule?.date ? new Date(props.schedule.date) : new Date())
    setStartTime(props.schedule ? (props.schedule.start.h - 8) * 4 + (props.schedule.start.m / 15) : undefined)
    setStartHour(props.schedule?.start.h)
    setStartMinute(props.schedule?.start.m)
    setEndTime(props.schedule ? (props.schedule.end.h - 8) * 4 + (props.schedule.end.m / 15) : undefined)
    setEndHour(props.schedule?.end.h)
    setEndMinute(props.schedule?.end.m)
    setByAppointmentOnly(props.schedule?.appointmentOnly)
  }, [props.schedule])

  const DateCustomInput = forwardRef(({ value, style, onClick }, ref) => (
    <TextInput
      value={value}
      onClick={onClick}
      ref={ref}
      style={style}
      placeholder="MM/DD/YYYY"
    />
  ));

  const createButtonEnabled = useMemo(() => {
    if (date) {
      if (startHour !== undefined && startMinute !== undefined && endHour !== undefined && endMinute !== undefined) {
        if (startHour * 60 + startMinute < endHour * 60 + endMinute) {
          return true
        }
      }
    }

    return false
  }, [startHour, startMinute, endHour, endMinute, date])

  function afterOpenModal() {
    document.body.style.overflow = 'hidden'
  }


  const closeModal = () => {
    setDate()
    setStartTime()
    setStartHour()
    setStartMinute()
    setEndTime()
    setEndHour()
    setEndMinute()
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleCreate = () => {
    if (props.schedule) {
      props.onEditOpenHouse(props.schedule.id, date, startHour, startMinute, endHour, endMinute, byAppointmentOnly)
    } else {
      props.onAddOpenHouse(date, startHour, startMinute, endHour, endMinute, byAppointmentOnly)
    }
    closeModal()
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      onAfterOpen={afterOpenModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{ 
        ...styles.container,
        width: isMobile ? 'calc(100vw - 80px)' : 600, 
        padding: isMobile ? '20px 16px 16px' : '40px 30px 30px'}}
      >
        <TypeFace bold size={18}>
          {props.schedule ? 'Edit schedule' : 'Add Open House'}
        </TypeFace>

        <Flexbox row style={{...styles.calendars, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center'}}>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace semiBold size={11}>
              DATE
            </TypeFace>
            <ReactDatePicker
              placeholderText='MM/DD/YYYY'
              customInput={<DateCustomInput style={{ marginTop: 5, borderRadius: 0, width: 240, height: 40 }} />}
              selected={date}
              onChange={(date) => setDate(date)}
              onChangeRaw={(event) => {
                
              }}
            />
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace semiBold size={11}>
              START TIME
            </TypeFace>
            <TimeSelect
              style={styles.dropdown}
              value={startTime}
              onChangeValue={setStartTime}
              onChangeHourMinute={(hour, min) => {
                setStartHour(hour)
                setStartMinute(min)
              }}
            />
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace semiBold size={11}>
              END TIME
            </TypeFace>
            <TimeSelect
              style={styles.dropdown}
              value={endTime}
              onChangeValue={setEndTime}
              onChangeHourMinute={(hour, min) => {
                console.log("hour=",hour)
                console.log("min=",min)
                setEndHour(hour)
                setEndMinute(min)
              }}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox row style={styles.appointmentOnlyRow}>
          <input
            id="appointment_only"
            type="checkbox"
            name="appointment_only"
            checked={byAppointmentOnly}
            onChange={e => setByAppointmentOnly(e.target.checked)}
          />
          <TypeFace for="appointment_only" medium size={12} style={{ marginLeft: 10 }}>
            By Appointment Only
          </TypeFace>
        </Flexbox>
        
        <Flexbox row style={styles.buttons}>
          <Button textSize={isMobile ? 12 : 14} primary disabled={!createButtonEnabled} onClick={handleCreate} style={styles.createButton}>
            {props.schedule ? 'UPDATE NOW' : 'ADD NOW'}
          </Button>
          <Button textSize={isMobile ? 12 : 14} onClick={closeModal} style={styles.cancelButton}>
            CANCEL
          </Button>
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default AddOpenHouseModal

const styles = {
  container: {
    width: 600,
    padding: '40px 30px 30px',
  },
  buttons: {
    width: '100%',
    height: 50,
    marginTop: 150,
    gap: 30
  },
  createButton: {
    flex: 1,
    height: 50,
  },
  cancelButton: {
    height: 50,
    flex: 1,
    border: 'none'
  },
  calendars: {
    marginTop: 40,
    width: '100%',
    gap: 16,
  },
  dropdown: {
    height: 40,
    width: 150,
    marginTop: 5,
    paddingLeft: 10,
  },
  appointmentOnlyRow: {
    marginTop: 20,
    paddingLeft: 20,
    width: '100%'
  }
}