import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "src/hooks/useMediaQuery";
import { storeSearchObject } from "src/redux/search/actions";
import { selectSearchObject } from "src/redux/search/selectors";
import { Colors } from "src/theme/Colors";
import TypeFace from "../typography/Typefaces";
import Flexbox from "../wrapper/Flexbox";
import HomeDownloadAppPanel from "src/components/home/HomeDownloadAppPanel";
import { areas, getAllLocations, getLocation } from "src/data/AreasData";

const HomeFooter = (props) => {
  const { isMobile } = useMediaQuery();

  // const { areas, subAreas } = useAreas()

  const areasData = [
    {
      name: "Manhattan",
      subAreas: getAllLocations("borough", "Manhattan"),
    },
    {
      name: "Brooklyn",
      subAreas: getAllLocations("borough", "Brooklyn"),
    },
    {
      name: "Queens",
      subAreas: getAllLocations("borough", "Queens"),
    },
    {
      name: "Bronx",
      subAreas: getAllLocations("borough", "Bronx"),
    },
    {
      name: "Staten Island",
      subAreas: getAllLocations("borough", "Staten Island"),
    },
    {
      name: "Nassau County",
      subAreas: getAllLocations("county", "Nassau County"),
    },
    {
      name: "Suffolk County",
      subAreas: getAllLocations("county", "Suffolk County"),
    },
    {
      name: "New Jersey",
      subAreas: getAllLocations("state", "New Jersey"),
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentSearchObject = useSelector(selectSearchObject);

  const [areasExpanded, setAreasExpanded] = useState([]);

  const handleSubAreaSearch = (subArea) => {
    dispatch(
      storeSearchObject({
        ...currentSearchObject,
        query: [
          {
            ...subArea,
            label: subArea.place_name,
            value: subArea.name,
            type: "area",
          },
        ],
      })
    );
    navigate("/search");
  };

  const showSubs = (area) => {
    if (areasExpanded.includes(area.name)) {
      return area.subAreas;
    } else {
      return area.subAreas.slice(0, 10);
    }
  };

  function handleSeeMore(area) {
    if (areasExpanded.includes(area)) {
      setAreasExpanded(areasExpanded.filter((a) => a !== area));
    } else {
      setAreasExpanded([...areasExpanded, area]);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentBox}>
        <div style={styles.content}>
          {areasData?.map((area, index) => (
            <div
              key={`footer-${index}`}
              style={{ ...styles.list, width: !isMobile ? "calc(20% - 16px)" : "calc(50% - 20px)" }}
            >
              <TypeFace bold size={18} color="black" style={{ textAlign: "left", textTransform: "uppercase" }}>
                {area.name}
              </TypeFace>
              {showSubs(area).map((subArea, itemIndex) => (
                <label
                  onClick={() => handleSubAreaSearch(subArea)}
                  key={`item-${index}-${itemIndex}`}
                  className="subarea-text"
                >
                  {subArea.name}
                </label>
              ))}
              <label className={`subarea-text see-more`} onClick={() => handleSeeMore(area.name)}>
                {areasExpanded.includes(area.name) ? "- See less" : "+ See More"}
              </label>
            </div>
          ))}
        </div>
        {props.isHome ? (
          <Flexbox row style={styles.download}>
            <img src={require("src/assets/images/appstore.png")} style={styles.button} />
            <img src={require("src/assets/images/googleplay.png")} style={styles.button} />
          </Flexbox>
        ) : (
          <HomeDownloadAppPanel />
        )}
        <img
          src={require("src/assets/images/bg/img-home-footer.jpg")}
          style={{
            ...styles.imageFooter,
            width: isMobile ? "100%" : "75%",
            height: isMobile ? 120 : 240,
          }}
        />
      </div>
    </div>
  );
};

export default HomeFooter;

const styles = {
  container: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    paddingBottom: 30,
    alignItems: "center",
  },
  contentBox: {
    width: "90%",
    maxWidth: 1178,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    shadowColor: Colors.primary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.6,
    shadowRadius: 6,
    borderRadius: 10,
  },
  imageFooter: {
    marginTop: 30,
    width: "75%",
    height: 240,
    objectFit: "contain",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    rowGap: 50,
    columnGap: 20,
    marginTop: 50,
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  lastPart: {
    width: 160,
    display: "flex",
    flexDirection: "column",
  },
  download: {
    marginTop: 60,
    justifyContent: "space-between",
  },
  button: {
    width: "48%",
    maxWidth: 204,
    // height: 59
  },
};
