import { collection, doc, getDocs, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
// import Button from "src/components/button/Button";
import { firestore } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Button, Typography, Flex, Table, Drawer, Form, Input, Select, App, notification, Popconfirm } from "antd";
import styles from "../../admin.module.scss";
import { BASE_URL } from "src/utils/constants";

const { Text } = Typography;

const AdminList = () => {
  const unmountedRef = useRef(false);

  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [addForm] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  function handleDelete(uid) {
    setLoading(true);
    fetch(`${BASE_URL}/deleteAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid }),
    })
      .then((res) => {
        if (res.ok) {
          loadAdmins();
          api.success({
            message: "Success",
            description: "Admin User deleted successfully!",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        api.error({
          message: "Error",
          description: "Oops!! Something went wrong!" + err,
        });
        setLoading(false);
      });
  }

  async function handleFinish(values) {
    try {
      await addForm.validateFields();
      setLoading(true);
      setOpen(false);
      let data = {
        ...values,
        emailVerified: true,
        disabled: false,
      };
      let res = await fetch(`${BASE_URL}/createAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log(res);
      if (res.ok) {
        loadAdmins();
        api.success({
          message: "Success",
          description: "Admin User created successfully!",
        });
        addForm.resetFields();
      } else {
        let message = await res.json();
        setLoading(false);
        api.error({
          message: "Error",
          description: message.message || "Oops!! Something went wrong!",
        });
      }
    } catch (err) {}
  }
  const loadAdmins = () => {
    setLoading(true);
    let q = query(
      collection(firestore, "profiles"),
      where("role", "in", ["admin", "manager"]),
      orderBy("created", "desc")
    );

    getDocs(q).then((querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setCustomers(d);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    loadAdmins();
  }, []);

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return (
          <Flex gap={5}>
            <Text>{record.name}</Text>
            <Text>{record.username && `(${record.username})`}</Text>
          </Flex>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (_, record) => moment(record.created).format("MMM DD, YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        if (record.email === "ede@listmeet.com") return "";
        return (
          <Popconfirm
            title="Delete the admin user?"
            description="Are you sure to delete this admin user?"
            onConfirm={() => handleDelete(record.userId)}
            okText="Delete"
            cancelText="Cancel"
            okType="danger"
          >
            <Button size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const headerRight = (
    <Button type="primary" onClick={showDrawer}>
      Create New Admin
    </Button>
  );

  return (
    <AdminLayout title="Admins" headerRight={headerRight}>
      {contextHolder}
      <Table
        dataSource={customers}
        columns={columns}
        style={{ width: "100%" }}
        loading={loading}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
      />

      <Drawer title="Create New Admin" onClose={onClose} open={open}>
        <Form
          layout="vertical"
          form={addForm}
          style={{ width: "100%", gap: 15, display: "flex", flexDirection: "column" }}
          onFinish={handleFinish}
        >
          <Form.Item label="Full Name" rules={[{ required: true, min: 6 }]} name="displayName">
            <Input placeholder="eg: John Doe" />
          </Form.Item>

          <Form.Item label="Username" rules={[{ required: true, min: 6 }]} name="username">
            <Input placeholder="eg: admin123" />
          </Form.Item>

          <Form.Item label="Email" rules={[{ required: true, type: "email" }]} name="email">
            <Input placeholder="eg: admin@listmeet.com" />
          </Form.Item>

          <Form.Item label="Password" rules={[{ required: true, min: 6 }]} name="password">
            <Input.Password placeholder="enter your password" />
          </Form.Item>

          <Form.Item label="Role" name="role">
            <Select placeholder="select role">
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="manager">Manager</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button size="large" htmlType="submit" style={{ width: "100%", marginTop: 15 }}>
              Create New Admin
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </AdminLayout>
  );
};

export default AdminList;
