import React, { useState } from "react";
import { Colors } from "src/theme/Colors";
import TypeFace from "../typography/Typefaces";
import IconSearch from "src/assets/images/icons/icon-search-white.svg";
import Flexbox from "../wrapper/Flexbox";
import HomeAdvancedSearchFilterPopup from "./search/HomeAdvancedSearchFilterPopup";
import RoomsSelectBox from "./search/RoomsSelectBox";
import PriceRangeSelectBox from "./search/PriceRangeSelectBox";
import ReactSelect from "react-select/async";
import useAreas from "src/hooks/data/useAreas";
import IconMapNeighborhoods from "src/assets/images/icons/icon-map-neighborhoods-2.svg";
import SelectNeighborhoodsModal from "./search/SelectNeighborhoodsModal";
import { useDispatch, useSelector } from "react-redux";
import { selectSearchObject } from "src/redux/search/selectors";
import { storeSearchObject } from "src/redux/search/actions";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "src/hooks/useMediaQuery";
import SelectNeighborhoodsModalMobile from "./search/SelectNeighborhoodsModalMobile";
import Button from "../button/Button";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import ConnectProfileModal from "../auth/ConnectProfileModal";
import LoginModal from "../auth/LoginModal";
import { useAlert } from "src/hooks/alert/AlertContext";
import { get } from "lodash";
import TagsInput from "../input/TagsInput";
import { Loader } from "@googlemaps/js-api-loader";
import Script from "react-load-script";
import localStorage from "redux-persist/es/storage";
import { areas, zips } from "src/data/AreasData";
import { searchProperties } from "src/algolia";
import debounce from "lodash.debounce";

const HomeSearchBox = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isMobile } = useMediaQuery();

  const currentSearchObject = useSelector(selectSearchObject);

  const [isOpeningAdvancedFilters, setIsOpeningAdvancedFilters] = useState(false);

  let reactSelectStyles = {
    placeholder: (base) => ({
      ...base,
      textAlign: "start",
    }),
    valueContainer: (base) => ({
      ...base,
      textAlign: "start",
      border: "none",
      boxShadow: "none",
    }),
    control: (base) => ({
      ...base,
      border: "none",
      borderRadius: 0,
    }),
    option: (base) => ({
      ...base,
      textAlign: "start",
    }),
    input: (base) => ({
      ...base,
      border: "none",
    }),
    container: (base) => ({
      ...base,
      border: "1px solid black",
      boxShadow: "none",
    }),
    groupHeading: (base) => ({
      ...base,
      display: "flex",
      marginBottom: "10px",
      alignItems: "center",
      fontWeight: "bold",
    }),
  };

  const [value, setValue] = useState("");

  const handleAdvancedOptions = () => {
    document.body.style.overflow = "hidden";
    setIsOpeningAdvancedFilters(true);
  };

  const handleSearch = () => {
    //console.log('AFTER-DELETION=',userSelectedLocation)
    //handleLocationClick()
    //console.log("currentSearchObject-HOME=",currentSearchObject)
    navigate("/search");
  };

  async function loadOptions(input, callback) {
    let properties = [];
    let areasData = areas
      .filter((v) => v.name.toLowerCase().search(input.toLowerCase()) !== -1)
      .sort((a, b) => a?.name?.search(input) - b?.name?.search(input))
      .slice(0, 5)
      .map((a) => ({ ...a, type: "area", label: a.place_name, value: a.name }));

    let zipData = zips
      .filter((v) => v.zip.search(input) !== -1)
      .sort((a, b) => a?.zip?.search(input) - b?.zip?.search(input))
      .slice(0, 3)
      .map((a) => ({ ...a, type: "zip", label: a.zip, value: a.zip }));

    if (areasData.length === 0) {
      let data = await searchProperties(
        input,
        {
          filters: `status:Active AND type: ${currentSearchObject?.type} `,
          hitsPerPage: 3,
        },
        "newest"
      );

      properties = [
        {
          label: "Properties",
          options: [...data.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId }))],
        },
      ];
    }

    callback([
      {
        label: "Areas",
        options: [...areasData],
      },
      {
        label: "Zips",
        options: [...zipData],
      },
      ...properties,
      // ...properties.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId })),
      // ...properties.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId })),
    ]);
  }

  // const handleInputChange = (inputValue, actionMeta) => {
  //   console.log(actionMeta, inputValue);
  //   if (actionMeta.action !== "input-blur" && actionMeta.action !== "menu-close") {
  //     setValue(inputValue);
  //   }
  // };

  function handleChange(value, action) {
    if (action.action === "remove-value" || action.action === "pop-value") {
      let query = currentSearchObject?.query.filter((v) => v.id !== action.removedValue.id);
      dispatch(storeSearchObject({ ...currentSearchObject, query }));
    }
    if (action.action === "select-option") {
      if (action.option.type === "property") {
        navigate(`/listing/${action.option.dataId}`);
      } else {
        dispatch(
          storeSearchObject({
            ...currentSearchObject,
            query: [...currentSearchObject.query, action.option],
          })
        );
      }
    }
  }

  return (
    <Flexbox
      style={{
        ...styles.container,
        ...props.style,
        width: isMobile ? "80%" : "90%",
      }}
    >
      <div style={styles.buttons}>
        <Button
          textSize={16}
          primary={currentSearchObject?.type === "rental"}
          onClick={() => dispatch(storeSearchObject({ ...currentSearchObject, type: "rental" }))}
        >
          Rent
        </Button>
        <Button
          textSize={16}
          primary={currentSearchObject?.type === "sale"}
          onClick={() => dispatch(storeSearchObject({ ...currentSearchObject, type: "sale" }))}
          style={{ marginLeft: 20 }}
        >
          Buy
        </Button>
      </div>
      <Flexbox
        style={{
          ...styles.mainRow,
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
          gap: isMobile ? 20 : 10,
        }}
      >
        <div style={isMobile ? styles.locationForMobile : styles.location}>
          <TypeFace size={20} semiBold>
            Location
          </TypeFace>
          <div style={styles.locationInput}>
            <ReactSelect
              id="location-input"
              loadOptions={loadOptions}
              placeholder={"Search for address, neighborhood, or zip"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              style={{ width: "100%" }}
              isMulti
              onChange={handleChange}
              value={currentSearchObject?.query || []}
              styles={reactSelectStyles}
              formatOptionLabel={(option, { context }) => {
                return context === "menu" ? option.place_name || option.label : option.name || option.label;
              }}
            />
          </div>
        </div>
        <Flexbox
          style={{
            alignItems: "flex-start",
            marginLeft: isMobile ? 0 : 10,
            marginRight: 5,
            width: isMobile ? "100%" : "unset",
          }}
        >
          <TypeFace size={20} semiBold>
            Price Range
          </TypeFace>
          <PriceRangeSelectBox
            isForSale={currentSearchObject?.type === "sale"}
            minValue={currentSearchObject?.price?.from}
            maxValue={currentSearchObject?.price?.to}
            onValueChanged={(min, max) => {
              const priceValues = {};
              if (min) {
                priceValues.from = min;
              }
              if (max) {
                priceValues.to = max;
              }

              dispatch(
                storeSearchObject({
                  ...currentSearchObject,
                  price: priceValues,
                })
              );
            }}
          />
        </Flexbox>
        <Flexbox style={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
          <TypeFace size={20} semiBold>
            Bedrooms
          </TypeFace>
          <RoomsSelectBox
            lastValue={5}
            selected={currentSearchObject?.bedRooms ?? []}
            onUpdateSelection={(rooms) => {
              dispatch(
                storeSearchObject({
                  ...currentSearchObject,
                  bedRooms: rooms,
                })
              );
            }}
          />
        </Flexbox>
        <div
          onClick={handleSearch}
          style={{
            ...styles.searchButton,
            marginLeft: isMobile ? 0 : 20,
            width: isMobile ? "100%" : 50,
            alignSelf: "flex-end",
          }}
        >
          <img src={IconSearch} width={20} height={20} alt="search-icon" />
        </div>
      </Flexbox>
      {!isMobile && (
        <Flexbox
          style={{
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 5 : 0,
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <Flexbox style={{ flex: 1 }} />
          <TypeFace semiBold size={isMobile ? 14 : 18} onClick={handleAdvancedOptions} style={{ cursor: "pointer" }}>
            + Advanced Options
          </TypeFace>
        </Flexbox>
      )}

      <HomeAdvancedSearchFilterPopup
        modalIsOpen={isOpeningAdvancedFilters}
        filters={currentSearchObject}
        closeModal={() => setIsOpeningAdvancedFilters(false)}
      />
    </Flexbox>
  );
};

export default HomeSearchBox;

const styles = {
  container: {
    width: "90%",
    maxWidth: 1178,
    borderRadius: 16,
    backgroundColor: "white",
    boxShadow: "0 0 6px #aaaaaa",
    alignItems: "flex-start",
    padding: "0px 20px 20px",
  },
  mainRow: {
    width: "100%",
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  location: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  locationForMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  locationInput: {
    marginTop: 5,
    width: "100%",
  },
  textInputLocation: {
    position: "absolute",
    left: 0,
    right: 0,
    height: "100%",
    marginLeft: 0,
    paddingLeft: 5,
    border: "1px solid #ccc",
    borderRadius: 3,
  },
  price: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  textInputPrice: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    height: 36,
    shadowColor: Colors.gray1,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    border: "1px solid #000000",
  },
  searchButton: {
    display: "flex",
    backgroundColor: Colors.primary,
    borderRadius: 5,
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  mapIconInLocation: {
    cursor: "pointer",
    display: "flex",
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 20,
  },
  tagInputContainer: {
    border: "1px solid black",
    padding: ".5em",
    borderRadius: "3px",
    width: "min(80vw, 600px)",
    //marginTop: '1em',
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: ".5em",
    fontSize: "12px",
  },
  tagItem: {
    backgroundColor: "#F1F1F1",
    display: "inline-block",
    padding: ".5em .75em",
    //border: '1px solid black',
    // boxShadow: 'none'
  },
  tagItemClose: {
    height: "10px",
    width: "10px",
    fontWeight: "bold",
    color: "#000",
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: ".5em",
    fontSize: "15px",
    cursor: "pointer",
  },
  tagsInput: {
    flexGrow: 1,
    padding: ".5em 0",
    border: "none",
    outline: "none",
    fontSize: "14px",
  },
};
