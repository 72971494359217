import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import thunk from "redux-thunk"
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from "redux-persist"
import draftReducer from "./draft/reducers"
import areasReducer from "./areas/reducers";
import searchReducer from "./search/reducers";

const rootReducer = combineReducers({
  draft: persistReducer({
    key: "draft",
    storage,
  }, draftReducer),
  areas: persistReducer({
    key: "areas",
    storage,
  }, areasReducer),
  search: persistReducer({
    key: "search",
    storage,
  }, searchReducer),
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: false,
  middleware: [thunk]
})

export const persistor = persistStore(store)
