import debounce from "lodash.debounce";
import { useEffect, useMemo, useState, forwardRef } from "react";
import { salesMarketAsTypeOptions, salesTypeOptions } from "src/model/types";
import TextInput from "../input/TextInput";
import TypeFace from "../typography/Typefaces";
import Flexbox from "../wrapper/Flexbox";
import ReactDatePicker from "react-datepicker";
import { updateListing } from "src/data/createListing";
import { useParams } from "react-router-dom";
import { ListingStatusValues } from "src/model/types";

const EditListingUnitSaleInformation = ({
  highlightErrorFields,
  listing,
  onUpdateRootFields,
  onUpdateUnitInfoFields,
  onMarketAsChanged,
  soldSelected,
}) => {
  const [saleType, setSaleType] = useState("");
  const [marketAs, setMarketAs] = useState("");
  const [soldDate, setSoldDate] = useState();
  const [otherMarketAsType, setOtherMarketAsType] = useState("");
  const [unitNumber, setUnitNumber] = useState("");

  const { id } = useParams();

  const DateCustomInput = forwardRef(({ value, style, onClick }, ref) => (
    <TextInput value={value} onClick={onClick} ref={ref} style={style} />
  ));

  const changeUnitNumber = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      unit: changedValue,
    });
  };

  const debouncedChangeUnitHandler = useMemo(() => {
    return debounce(changeUnitNumber, 500);
  }, []);

  const changeSoldPrice = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      soldPrice: changedValue,
    });
  };

  const debouncedChangeSoldPriceHandler = useMemo(() => {
    return debounce(changeSoldPrice, 500);
  }, []);

  const changePrice = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      price: changedValue,
    });
  };

  const debouncedChangePriceHandler = useMemo(() => {
    return debounce(changePrice, 500);
  }, []);

  const changeMaintenance = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      maintenance: changedValue,
    });
  };

  const debouncedMaintenanceHandler = useMemo(() => {
    return debounce(changeMaintenance, 500);
  }, []);

  const changeCommonCharges = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      commonCharges: changedValue,
    });
  };

  const debouncedCommonChargesHandler = useMemo(() => {
    return debounce(changeCommonCharges, 500);
  }, []);

  const changeMaxFinancing = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      maxFinancing: changedValue,
    });
  };

  const debouncedChangeMaxFinancingHandler = useMemo(() => {
    return debounce(changeMaxFinancing, 500);
  }, []);

  const changeMonthlyTax = (event) => {
    const changedValue = event.target.value;
    onUpdateRootFields({
      monthlyTax: changedValue,
    });
  };

  const debouncedMonthlyTaxHandler = useMemo(() => {
    return debounce(changeMonthlyTax, 500);
  }, []);

  useEffect(() => {
    if (listing) {
      setSaleType(listing.unitInfo?.saleType);
      setMarketAs(listing.unitInfo?.marketAs);
      setSoldDate(listing.soldDate);

      if (listing.unitInfo?.marketAs === "other") {
        setOtherMarketAsType(listing.unitInfo?.otherMarketAs);
      }
      document.getElementById("input-price").value = listing.price ?? "";
      if (listing.soldPrice !== undefined && listing.status === "Sold") {
        document.getElementById("input-sold-price").value = listing.soldPrice ?? "";
      }
      document.getElementById("input-max-financing").value = listing.maxFinancing ?? "";
      setTimeout(() => {
        const marketAs = listing.unitInfo?.marketAs;
        if (listing.unitInfo?.marketAs === "condo") {
          document.getElementById("input-common-charges").value = listing.commonCharges ?? "";
        }
        /* if (listing.unitInfo?.marketAs === 'coop' || listing.unitInfo?.marketAs === 'condop') { */
        if (listing.unitInfo?.marketAs === "coop") {
          document.getElementById("input-maintenance").value = listing.maintenance ?? "";
        }
        if (
          marketAs === "building" ||
          marketAs === "condo" ||
          marketAs === "singleFamily" ||
          marketAs === "multiFamily" ||
          marketAs === "townHouse"
        ) {
          document.getElementById("input-monthly-tax").value = listing.monthlyTax ?? "";
        }
        if (marketAs === "condo" || marketAs === "coop" || marketAs === "building") {
          document.getElementById("input-unit").value = listing.unit ?? "";
        }
        //console.log('soldDate=',soldDate)
      }, 500);
    }
  }, [listing]);

  return (
    <Flexbox style={{ width: "100%", alignItems: "flex-start" }}>
      <TypeFace medium size={20}>
        Sale Information
      </TypeFace>
      <Flexbox row style={{ alignItems: "flex-start", marginTop: 10 }}>
        <Flexbox style={{ alignItems: "flex-start" }}>
          <TypeFace bold size={12} color={highlightErrorFields && !saleType ? "red" : "black"}>
            SALE TYPE<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: "flex-start", marginTop: 5 }}>
            <select
              value={saleType}
              style={{ ...styles.dropdown, borderColor: highlightErrorFields && !saleType ? "red" : "black" }}
              onChange={(e) => {
                setSaleType(e.target.value);
                onUpdateUnitInfoFields({
                  saleType: e.target.value,
                });
              }}
            >
              <option value=""></option>
              {salesTypeOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Flexbox>
        </Flexbox>
        <div style={{ width: 30 }} />
        <Flexbox style={{ alignItems: "flex-start" }}>
          <TypeFace bold size={12} color={highlightErrorFields && !marketAs ? "red" : "black"}>
            MARKET AS<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: "flex-start", marginTop: 5 }}>
            <select
              value={marketAs}
              style={{ ...styles.dropdown, borderColor: highlightErrorFields && !marketAs ? "red" : "black" }}
              onChange={(e) => {
                console.log(e.target.value);
                setMarketAs(e.target.value);
                onMarketAsChanged(e.target.value);
                onUpdateUnitInfoFields({
                  marketAs: e.target.value,
                });
              }}
            >
              <option value=""></option>
              {salesMarketAsTypeOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {marketAs === "other" && (
              <TextInput
                style={{
                  ...styles.manualInput,
                  borderColor: highlightErrorFields && !otherMarketAsType ? "red" : "black",
                }}
                placeholder="Enter market as type"
                value={otherMarketAsType}
                onChange={(e) => {
                  setOtherMarketAsType(e.target.value);
                  onUpdateUnitInfoFields({
                    otherMarketAs: e.target.value,
                  });
                }}
              />
            )}
          </Flexbox>
        </Flexbox>
        {(marketAs === "building" || marketAs === "condo" || marketAs === "coop") && (
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace
              bold
              size={12}
              color={highlightErrorFields && !listing.unit ? "red" : "black"}
              style={{ marginLeft: 20 }}
            >
              UNIT #<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <Flexbox row style={{ alignItems: "flex-start", marginTop: 5 }}>
              <TextInput
                id="input-unit"
                style={{
                  ...styles.unitInput,
                  borderColor: highlightErrorFields && !listing.unit ? "red" : "black",
                }}
                placeholder="Unit"
                onChange={debouncedChangeUnitHandler}
              />
            </Flexbox>
          </Flexbox>
        )}
      </Flexbox>
      <Flexbox row style={{ alignItems: "flex-start", gap: 30 }}>
        <Flexbox style={{ alignItems: "flex-start" }}>
          <TypeFace
            bold
            size={12}
            style={{ marginTop: 20 }}
            color={highlightErrorFields && !listing.price ? "red" : "black"}
          >
            PRICE<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <TextInput
            id="input-price"
            style={{ ...styles.input, borderColor: highlightErrorFields && !listing.price ? "red" : "black" }}
            placeholder="$"
            onChange={debouncedChangePriceHandler}
            onKeyDown={(event) => {
              if (
                event.key !== "Tab" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "Backspace" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Delete" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
          />
        </Flexbox>
        {marketAs === "condo" && (
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace
              bold
              size={12}
              style={{ marginTop: 20 }}
              color={highlightErrorFields && !listing.commonCharges ? "red" : "black"}
            >
              COMMON CHARGES (HOA fees)<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput
              id="input-common-charges"
              style={{ ...styles.input, borderColor: highlightErrorFields && !listing.commonCharges ? "red" : "black" }}
              placeholder="$"
              onChange={debouncedCommonChargesHandler}
              onKeyDown={(event) => {
                if (
                  event.key !== "Tab" &&
                  event.key !== "." &&
                  event.key !== "Backspace" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight" &&
                  event.key !== "Delete" &&
                  !/[0-9]/.test(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Flexbox>
        )}
        {marketAs === "coop" && (
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace
              bold
              size={12}
              style={{ marginTop: 20 }}
              color={highlightErrorFields && !listing.maintenance ? "red" : "black"}
            >
              MAINTENANCE/TAXES<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput
              id="input-maintenance"
              style={{ ...styles.input, borderColor: highlightErrorFields && !listing.maintenance ? "red" : "black" }}
              placeholder="$"
              onChange={debouncedMaintenanceHandler}
              onKeyDown={(event) => {
                if (
                  event.key !== "Tab" &&
                  event.key !== "." &&
                  event.key !== "Backspace" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight" &&
                  event.key !== "Delete" &&
                  !/[0-9]/.test(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Flexbox>
        )}
      </Flexbox>
      {soldSelected === true && (
        <Flexbox row style={{ alignItems: "flex-start", gap: 30, marginTop: 20 }}>
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace
              bold
              size={12}
              style={{ marginTop: 0 }}
              color={highlightErrorFields && !listing.soldPrice ? "red" : "black"}
            >
              SOLD PRICE<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput
              id="input-sold-price"
              style={{ ...styles.input, borderColor: highlightErrorFields && !listing.soldPrice ? "red" : "black" }}
              placeholder="$"
              onChange={debouncedChangeSoldPriceHandler}
              onKeyDown={(event) => {
                if (
                  event.key !== "Tab" &&
                  event.key !== "Tab" &&
                  event.key !== "." &&
                  event.key !== "Backspace" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight" &&
                  event.key !== "Delete" &&
                  !/[0-9]/.test(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Flexbox>
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace bold size={12} color={highlightErrorFields && !soldDate ? "red" : "black"}>
              SOLD DATE <TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <ReactDatePicker
              id="input-sold-date"
              placeholderText="MM/DD/YYYY"
              style={{ ...styles.smallInput, borderColor: highlightErrorFields && !listing.soldDate ? "red" : "black" }}
              selected={soldDate}
              customInput={
                <DateCustomInput
                  style={{
                    ...styles.smallInput,
                    marginTop: 5,
                    width: 150,
                    borderColor: highlightErrorFields && !soldDate ? "red" : "black",
                  }}
                />
              }
              onChange={(date) => {
                onUpdateRootFields({
                  soldDate: date.getTime(),
                });
                setSoldDate(date);
              }}
            />
          </Flexbox>
        </Flexbox>
      )}

      <Flexbox row style={{ alignItems: "flex-start", gap: 80, marginTop: 40 }}>
        <Flexbox style={{ alignItems: "flex-start" }}>
          <TypeFace bold size={12}>
            MAX FINANCING
          </TypeFace>
          <TextInput
            id="input-max-financing"
            style={styles.smallInput}
            placeholder="%"
            onChange={debouncedChangeMaxFinancingHandler}
            onKeyDown={(event) => {
              if (
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "Backspace" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Delete" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
          />
        </Flexbox>
        {(marketAs === "building" ||
          marketAs === "condo" ||
          marketAs === "singleFamily" ||
          marketAs === "multiFamily" ||
          marketAs === "townHouse") && (
          <Flexbox style={{ alignItems: "flex-start" }}>
            <TypeFace bold size={12} color={highlightErrorFields && !listing.monthlyTax ? "red" : "black"}>
              {marketAs === "building" ||
              marketAs === "singleFamily" ||
              marketAs === "multiFamily" ||
              marketAs === "townHouse"
                ? "TAXES"
                : "MONTHLY TAXES"}
              <TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput
              id="input-monthly-tax"
              style={{ ...styles.input, borderColor: highlightErrorFields && !listing.monthlyTax ? "red" : "black" }}
              placeholder="$"
              onChange={debouncedMonthlyTaxHandler}
              onKeyDown={(event) => {
                if (
                  event.key !== "Tab" &&
                  event.key !== "." &&
                  event.key !== "Backspace" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight" &&
                  event.key !== "Delete" &&
                  !/[0-9]/.test(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Flexbox>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export default EditListingUnitSaleInformation;

const styles = {
  container: {
    padding: "30px 0",
    alignItems: "flex-start",
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: "#AFAFAF",
    marginTop: 30,
  },
  input: {
    width: 150,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  unitInput: {
    width: 150,
    height: 36,
    marginLeft: 20,
    borderRadius: 0,
  },
  smallInput: {
    width: 60,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  manualInput: {
    width: 200,
    height: 40,
    borderRadius: 0,
    marginLeft: 20,
  },
};
