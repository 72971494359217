import { useEffect, useMemo, useState } from "react"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { Colors } from "src/theme/Colors"

const RoomsSelectBox = (props) => {

  const [selectedRooms, setSelectedRooms] = useState(props.selected ?? [])

  const handleClickRoom = (room) => {
    let newSelection = []
    if (selectedRooms.includes(room)) {
      newSelection = [...selectedRooms.filter(bedRoom => bedRoom !== room)]
    } else {
      newSelection = [...selectedRooms, room]
    }
    setSelectedRooms(newSelection)
    props.onUpdateSelection?.(newSelection)
  }

  const availableRooms = useMemo(() => {
    let values = []
    for (let idx = 0; idx < props.lastValue; idx++) {
      values.push(idx + 1)
    }
    return values
  }, [props.lastValue])

  useEffect(() => {
    setSelectedRooms(props.selected ?? [])
  }, [props.selected])

  return (
    <Flexbox row style={styles.bedRoomButtons}>
      {availableRooms.map((room, index) => (
        <Flexbox
          key={index}
          onClick={() => handleClickRoom(room)}
          style={{
            ...styles.bedRoomButton,
            backgroundColor: selectedRooms.includes(room) ? Colors.optionBack : 'transparent'
          }}
        >
          <TypeFace size={14} semiBold color={Colors.gray1} style={{ cursor: 'pointer' }}>
            {room}{room === (props.lastValue) ? '+' : ''}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default RoomsSelectBox

const styles = {
  bedRoomButtons: {
    marginTop: 5,
    height: 40,
    gap: 5,
  },
  bedRoomButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    border: '1px solid #afafaf',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}