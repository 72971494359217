import React, { useEffect, useState, useRef } from "react";
import { Colors } from "src/theme/Colors";
import Button from "../button/Button";
import TextInput from "../input/TextInput";
import TypeFace from "../typography/Typefaces";
import Flexbox from "../wrapper/Flexbox";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { validateEmailAddress, titleCase } from "src/utils/strings";
import { auth, firestore } from "src/firebase";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  sendEmailVerification,
} from "firebase/auth";
import { useAlert } from "src/hooks/alert/AlertContext";
import Loading from "react-fullscreen-loading";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import ReactSelect from "react-select";
import useMediaQuery from "src/hooks/useMediaQuery";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { UserRoleValue, userRoles } from "src/model/types";
import VerificationModal from "./VerificationModal";

const LoginModal = (props) => {
  var recaptchaWrapperRef;
  const { isMobile } = useMediaQuery();

  const { userProfile } = useFirestore();

  const navigate = useNavigate();

  // If null, no SMS has been sent
  const [confirm, setConfirm] = useState(null);

  // verification code (OTP - One-Time-Passcode)
  const [code, setCode] = useState("");

  const { showAlert } = useAlert();
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [selectedRoleOption, setSelectedRoleOption] = useState();
  const [sentResetPassword, setSentResetPassword] = useState(false);
  const [license, setLicense] = useState("");
  const [brokerageName, setBrokerageName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyContactName, setCompanyContactName] = useState("");
  const [companyContactEmail, setCompanyContactEmail] = useState("");
  const [companyContactPhone, setCompanyContactPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // mode 0: Login, 1: Signup, 2: Reset password
  const [mode, setMode] = useState(props.isSignup ? 1 : 0);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = React.useState(false);
  const [confirmResult, setConfirmResult] = useState("");
  const [accountVerified, setAccountVerified] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  useEffect(() => {
    //setUpRecaptcha();
  }, []);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
      },
      auth
    );

    window.recaptchaVerifier.verify();
  };

  function closeModal() {
    setEmailAddress("");
    setPhoneNumber("");
    setName("");
    setPassword("");
    setConfirmPassword("");
    setLicense("");
    setBrokerageName("");
    setCompanyPhone("");
    setCompanyContactName("");
    setCompanyContactEmail("");
    setCompanyContactPhone("");
    setError("");
    setProcessing(false);
    setSelectedRoleOption(undefined);
    setMode(props.isSignup ? 1 : 0);
    setAccountVerified(false);
    setEmailSent(false);
    props.closeModal();
    document.body.style.overflow = "auto";
  }

  const handleCreateProfile = async () => {
    //setIsVerificationModalOpen(true)
    //if(phoneNumber === '') {
    if (selectedRoleOption === undefined) {
      showAlert(true, "Please select your user role.");
    } else if (name === "") {
      {
        selectedRoleOption.value === UserRoleValue.ManageMent
          ? setError("Please enter your company name")
          : setError("Please enter your full name");
      }
    } else if (emailAddress === "") {
      /*  else if (companyPhone === '' && selectedRoleOption.value === UserRoleValue.ManageMent) {
        setError('Please enter your company phone number')
      }  */
      {
        selectedRoleOption.value === UserRoleValue.ManageMent
          ? setError("Please enter your company email address")
          : setError("Please enter your email address");
      }
    } else if (!validateEmailAddress(emailAddress)) {
      //&& emailAddress.includes('@')
      setError("Please enter a valid email address");
    } else if (companyContactName === "" && selectedRoleOption.value === UserRoleValue.ManageMent) {
      setError("Please enter point of contact full name");
    } else if (companyContactPhone === "" && selectedRoleOption.value === UserRoleValue.ManageMent) {
      setError("Please enter point of contact phone number");
    } else if (companyContactEmail === "" && selectedRoleOption.value === UserRoleValue.ManageMent) {
      setError("Please enter point of contact email address");
    } else if (!validateEmailAddress(companyContactEmail) && selectedRoleOption.value === UserRoleValue.ManageMent) {
      setError("Please enter a valid point of contact email address");
    } else if (brokerageName === "" && selectedRoleOption.value === UserRoleValue.Broker) {
      setError("Please enter your brokerage name");
    } else if (
      license === "" &&
      (selectedRoleOption.value === UserRoleValue.Broker ||
        selectedRoleOption.value === UserRoleValue.SalesPerson ||
        selectedRoleOption.value === UserRoleValue.Agent)
    ) {
      setError("Please enter your license#");
    } else if (password === "") {
      setError("Please enter your items");
    } else if (password.length < 6) {
      setError("Password must be at least 6 characters");
    } else if (password !== confirmPassword) {
      setError("Password does not match.");
    } else {
      setError("");
      setProcessing(true);

      if (userProfile && userProfile.role === "customer") {
        signOut(auth);
      }
      if (selectedRoleOption.value === UserRoleValue.Broker) {
        setProcessing(true);
        getDoc(doc(firestore, `agencies/${license}`)).then((docSnap) => {
          if (docSnap.exists()) {
            setProcessing(false);
            showAlert(true, "Broker already registered with the same number.");
          } else {
            createUserWithEmailAndPassword(auth, emailAddress, password)
              .then(async (res) => {
                const createdUserId = res.user.uid;
                const profileDoc = doc(firestore, "profiles", createdUserId);
                const userProfileData = {
                  userId: createdUserId,
                  created: new Date().getTime(),
                  name,
                  email: emailAddress,
                  profileCreated: true,
                  nameL: name.toLowerCase(),
                  emailL: emailAddress.toLowerCase(),
                  role: selectedRoleOption.value,
                  agency: license,
                  agencyApproved: false,
                };
                await setDoc(profileDoc, userProfileData)
                  .then((res) => {
                    submitAgency(createdUserId);
                    setProcessing(false);
                    closeModal();
                    sendEmailVerification(auth.currentUser).then(() => {
                      auth.signOut();
                      showAlert(true, "Please check your inbox to verify your account", [
                        {
                          title: "Ok",
                          onClick: () => {
                            showAlert(false);
                            navigate("/");
                          },
                        },
                      ]);
                    });
                    //navigate('/manage')
                  })
                  .catch((err) => {
                    console.log("sign up error => ", err);
                    showAlert(true, "Account registration failed. Please try again later.");
                    setProcessing(false);
                  });
              })
              .catch((err) => {
                setProcessing(false);
                if (err.code === "auth/email-already-in-use") {
                  showAlert(true, "Email already in use. Please login instead.");
                } else {
                  showAlert(true, "Create account failed. Please try again later.");
                }
              });
          }
        });
      } else {
        createUserWithEmailAndPassword(auth, emailAddress, password)
          .then((res) => {
            const createdUserId = res.user.uid;
            const profileDoc = doc(firestore, "profiles", createdUserId);
            const userProfileData = {
              userId: createdUserId,
              created: new Date().getTime(),
              name,
              email: emailAddress,
              profileCreated: true,
              nameL: name.toLowerCase(),
              emailL: emailAddress.toLowerCase(),
              role: selectedRoleOption.value,
            };
            const contact = {
              name: companyContactName,
              email: companyContactEmail,
              number: companyContactPhone,
            };
            if (selectedRoleOption.value === UserRoleValue.ManageMent) {
              userProfileData.companyApproved = false;
              userProfileData.phone = companyPhone;
              userProfileData.contact = contact;
            }

            if (
              selectedRoleOption.value === UserRoleValue.SalesPerson ||
              selectedRoleOption.value === UserRoleValue.Agent
            ) {
              userProfileData.licenseNumber = license;
              userProfileData.agencyApproved = false;
            }
            setDoc(profileDoc, userProfileData)
              .then((res) => {
                setProcessing(false);
                closeModal();
                sendEmailVerification(auth.currentUser).then(() => {
                  auth.signOut();
                  showAlert(true, "Please check your inbox to verify your account", [
                    {
                      title: "Ok",
                      onClick: () => {
                        showAlert(false);
                        navigate("/");
                      },
                    },
                  ]);
                });

                /*  if (selectedRoleOption.value === 'customer') {
                navigate('/')
              } else {
                navigate('/manage')
              } */
              })
              .catch((err) => {
                console.log("sign up error => ", err);
                showAlert(true, "Account registration failed. Please try again later.");
                setProcessing(false);
              });
          })
          .catch((err) => {
            console.log("err=", err);
            setProcessing(false);
            if (err.code === "auth/email-already-in-use") {
              showAlert(true, "Email already in use. Please login instead.");
            } else {
              showAlert(true, "Create account failed. Please try again later.");
            }
          });
      }
    }
    /* }
    else {
      // Sign in With PHONE NUMBER
      console.log('ZIVA')
      if (selectedRoleOption === undefined)  {
        showAlert(true, "Please select your user role.")
      }
      else if (name === '') {
        {selectedRoleOption.value === UserRoleValue.ManageMent ? setError('Please enter your company name') : setError('Please enter your full name')}
      }
      else {
        if (selectedRoleOption.value === UserRoleValue.Broker) {
          setProcessing(true)
          getDoc(doc(firestore, `agencies/${license}`)).then(async docSnap => {
            if (docSnap.exists()) {
              setProcessing(false)
              showAlert(true, "Broker already registered with the same number.")
            }
            else {
              signInUserWithPhoneNumber()
            }
          })
        }
        else {
          setProcessing(true)
          signInUserWithPhoneNumber()
        }
        
        }
      } */
  };

  function signInUserWithPhoneNumber() {
    fetch("https://us-central1-listmeetdev.cloudfunctions.net/checkPhoneNumberExists", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("resPHONEExist=", res);
        if (res.phoneNumberExists === false) {
          setUpRecaptcha();
          const appVerifier = await window.recaptchaVerifier;
          console.log("appVerifier", appVerifier);
          try {
            const response = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
            setProcessing(false);
            setConfirmResult(response);
            console.log(response.verificationId);
            setIsVerificationModalOpen(true);
            if (appVerifier) {
              appVerifier.clear();
              document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
              //recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
          } catch (e) {
            console.log(e);
            setIsVerificationModalOpen(false);
            setError("Please enter valid phone number to signup");
            if (appVerifier) {
              appVerifier.clear();
              document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
              //recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
            setProcessing(false);
          }
        } else {
          setProcessing(false);
          setError("Phone number already in use. Please login instead.");
        }
      })
      .catch((err) => {
        setProcessing(false);
      });
  }

  function submitAgency(createdUserId) {
    console.log("licencse-createdUserId=", createdUserId);
    setDoc(doc(firestore, `agencies/${license}`), {
      id: license,
      name: titleCase(brokerageName),
      creator: createdUserId,
      status: "submitted",
      brokerName: titleCase(name),
      brokerEmail: emailAddress,
      createdAt: new Date().getTime(),
    })
      .then(() => {
        console.log("res");
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
        console.log("resERROR");
        console.log("agency submit error => ", err);
      });
  }

  const handleResendEmail = async () => {
    signInWithEmailAndPassword(auth, emailAddress, password)
      .then((authCredential) => {
        sendEmailVerification(auth.currentUser);
        setEmailSent(true);
      })
      .catch((err) => {
        setProcessing(false);
      });
    setTimeout(() => {
      auth.signOut();
    }, 4000);
  };

  const onChangeCompanyContactPhone = (e) => {
    const contactPhone = e.target.value;
    if (contactPhone.length === 0) {
      setCompanyContactPhone("");
      setError("");
    }
    if (contactPhone.includes("@") === false) {
      if (/^\d+$/.test(contactPhone)) {
        if (contactPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(contactPhone);
          setCompanyContactPhone(targetValue);
        } else if (companyContactPhone.length > 10) {
          setCompanyContactPhone(companyContactPhone);
        } else {
          setCompanyContactPhone(contactPhone);
        }
      } else {
        if (companyContactPhone.includes("(") && contactPhone.length > 14) {
          setCompanyContactPhone(contactPhone.slice(0, 14));
        } else {
          setCompanyContactPhone(contactPhone);
        }
      }
    } else {
      setCompanyContactPhone(contactPhone);
    }
  };

  const handleLogin = () => {
    //if(phoneNumber === '') {
    if (emailAddress === "") {
      setError("Please enter your email address");
    } else if (!validateEmailAddress(emailAddress)) {
      setError("Please enter a valid email address");
    } else if (password === "") {
      setError("Please enter your items");
    } else {
      setError("");
      setProcessing(true);
      if (userProfile && userProfile.role === "customer") {
        signOut(auth);
      }

      signInWithEmailAndPassword(auth, emailAddress, password)
        .then((authCredential) => {
          console.log("authCredential=", authCredential.user.emailVerified);
          // rollback to true when testing done for other issues
          if (authCredential.user.emailVerified === true) {
            getDoc(doc(firestore, `profiles/${authCredential.user.uid}`)).then((profileDoc) => {
              setAccountVerified(true);
              const myProfile = profileDoc.data();
              setProcessing(false);
              setEmailAddress("");
              setPhoneNumber("");
              setPassword("");
              closeModal();
              console.log("myProfile.role=", myProfile.role);
              if (myProfile.role === "admin") {
                navigate("/admin/board/dashboard");
              } else if (myProfile.role !== UserRoleValue.Customer) {
                navigate("/manage/settings");
              }
            });
          } else {
            setProcessing(false);
            auth.signOut();
            setAccountVerified(false);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(JSON.stringify(err));
          if (err.code === "auth/wrong-password") {
            setError("Wrong password");
          } else if (err.code === "auth/user-not-found") {
            setError("User not found. please sign up.");
          } else {
            showAlert(true, "Login failed.");
          }
          setProcessing(false);
        });
    }
    /* }
    else {
      // HERE is Login With Phone Code
      if (emailAddress === '') {
        setError('Please enter your email address / phone number')
      } else {
        setError('')
        setProcessing(true)
  
        if (userProfile && userProfile.role === 'customer') {
          signOut(auth)
        }

        fetch('https://us-central1-listmeetdev.cloudfunctions.net/checkPhoneNumberExists', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              phoneNumber: phoneNumber,
            })
          })
          .then(response => response.json())
          .then(async res => {
            console.log('resPHONEExist=',res)
            if(res.phoneNumberExists === true) {
              logInUserWithPhoneNumber()
            }
            else {
                setProcessing(false)
                setError("User not found. please sign up.")
            }
            
          })
          .catch(err => {
            setProcessing(false)
          })
        

      }
    } */
  };

  async function logInUserWithPhoneNumber() {
    setUpRecaptcha();
    const appVerifier = await window.recaptchaVerifier;
    console.log("appVerifier", appVerifier);
    try {
      const response = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setProcessing(false);
      setConfirmResult(response);
      console.log(response.verificationId);
      setIsVerificationModalOpen(true);
      if (appVerifier) {
        appVerifier.clear();
        document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
      }
    } catch (e) {
      console.log(e);
      setIsVerificationModalOpen(false);
      setError("Please enter valid phone number to signup");
      if (appVerifier) {
        appVerifier.clear();
        document.getElementById("recaptchaWrapper").innerHTML = `<div id="recaptcha-container"></div>`;
      }
      setProcessing(false);
    }
  }

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
  };

  const onChangeEmailPhone = (e) => {
    const emailPhone = e.target.value;
    if (emailPhone.length === 0) {
      setPhoneNumber("");
      setEmailAddress("");
      setError("");
    }
    if (emailPhone.includes("@") === false) {
      if (/^\d+$/.test(emailPhone)) {
        if (emailPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(emailPhone);
          const phoneOnly = emailPhone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
          const countryCodetargetValue = "+1" + phoneOnly;
          console.log("countryCodetargetValue=", countryCodetargetValue);
          setPhoneNumber(countryCodetargetValue);
          setEmailAddress(targetValue);
        } else if (emailAddress.length > 10) {
          setEmailAddress(emailAddress);
        } else {
          setEmailAddress(emailPhone);
        }
      } else {
        if (emailAddress.includes("(") && emailPhone.length > 14) {
          setEmailAddress(emailPhone.slice(0, 14));
        } else {
          setEmailAddress(emailPhone);
        }
      }
    } else {
      setEmailAddress(emailPhone);
    }
  };

  const handleResetPassword = () => {
    if (emailAddress === "") {
      setError("Please enter your email address");
    } else if (!validateEmailAddress(emailAddress)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
      setProcessing(true);

      if (userProfile && userProfile.role === "customer") {
        signOut(auth);
      }

      sendPasswordResetEmail(auth, emailAddress)
        .then(() => {
          setProcessing(false);
          setSentResetPassword(true);
        })
        .catch((err) => {
          console.log(err);
          console.log(JSON.stringify(err));
          if (err.code === "auth/user-not-found") {
            setError("User not found. please sign up.");
          } else {
            showAlert(true, "Reset password failed.");
          }
          setProcessing(false);
        });
    }
  };
  const sendDataToParent = (uid) => {
    if (selectedRoleOption.value === UserRoleValue.Broker) {
      saveBrokerUserToDatabase(uid);
    } else {
      saveUserToDatabase(uid);
    }
  };

  const getUserProfileData = (uid) => {
    setProcessing(true);
    getDoc(doc(firestore, `profiles/${uid}`)).then((profileDoc) => {
      const myProfile = profileDoc.data();
      setProcessing(false);
      setEmailAddress("");
      setPhoneNumber("");
      setPassword("");
      closeModal();
      if (myProfile.role === "admin") {
        navigate("/admin/board/dashboard");
      } else if (myProfile.role !== UserRoleValue.Customer) {
        navigate("/manage/settings");
      }
    });
  };

  const saveBrokerUserToDatabase = async (createdUserId) => {
    const profileDoc = doc(firestore, "profiles", createdUserId);
    const userProfileData = {
      userId: createdUserId,
      created: new Date().getTime(),
      name,
      email: emailAddress,
      profileCreated: true,
      nameL: name.toLowerCase(),
      emailL: emailAddress.toLowerCase(),
      role: selectedRoleOption.value,
      agency: license,
      agencyApproved: false,
    };
    await setDoc(profileDoc, userProfileData)
      .then((res) => {
        submitAgency(createdUserId);
        setProcessing(false);
        closeModal();
        navigate("/manage");
      })
      .catch((err) => {
        console.log("sign up error => ", err);
        showAlert(true, "Account registration failed. Please try again later.");
        setProcessing(false);
      });
  };
  const saveUserToDatabase = (createdUserId) => {
    const profileDoc = doc(firestore, "profiles", createdUserId);
    const userProfileData = {
      userId: createdUserId,
      created: new Date().getTime(),
      name,
      email: emailAddress,
      profileCreated: true,
      nameL: name.toLowerCase(),
      emailL: emailAddress.toLowerCase(),
      role: selectedRoleOption.value,
    };
    const contact = {
      name: companyContactName,
      email: companyContactEmail,
      number: companyContactPhone,
    };
    if (selectedRoleOption.value === UserRoleValue.ManageMent) {
      userProfileData.companyApproved = false;
      userProfileData.phone = companyPhone;
      userProfileData.contact = contact;
    }

    if (selectedRoleOption.value === UserRoleValue.SalesPerson || selectedRoleOption.value === UserRoleValue.Agent) {
      userProfileData.licenseNumber = license;
      userProfileData.agencyApproved = false;
    }
    setDoc(profileDoc, userProfileData)
      .then((res) => {
        setProcessing(false);
        closeModal();
        if (selectedRoleOption.value === "customer") {
          navigate("/");
        } else {
          navigate("/manage");
        }
      })
      .catch((err) => {
        console.log("sign up error => ", err);
        showAlert(true, "Account registration failed. Please try again later.");
        setProcessing(false);
      });
  };

  useEffect(() => {
    setMode(props.isSignup ? 1 : 0);
  }, [props.isSignup]);

  return (
    <>
      {/* <div id="recaptcha-container"></div> */}
      <div ref={(ref) => (recaptchaWrapperRef = ref)} id="recaptchaWrapper">
        <div id="recaptcha-container"></div>
      </div>
      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="mymodal"
        overlayClassName="myoverlay"
      >
        {mode === 0 && (
          <Flexbox
            style={{
              width: isMobile ? "calc(100vw - 80px)" : 320,
              padding: isMobile ? "20px 16px 16px" : "40px 30px 30px",
            }}
          >
            <TypeFace bold size={18}>
              Login to ListMeet
            </TypeFace>
            <TypeFace size={14}>Take full advantage of ListMeet's features</TypeFace>
            <TextInput
              style={styles.emailInput}
              placeholder="Email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              //onChange={onChangeEmailPhone}
            />
            {phoneNumber === "" && (
              <TextInput
                style={styles.passwordInput}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            )}
            {!!error && (
              <TypeFace size={11} color="red">
                {error}
              </TypeFace>
            )}
            {phoneNumber === "" && (
              <Button
                textSize={11}
                textColor={Colors.primary}
                style={styles.forgotPasswordButton}
                onClick={() => {
                  setMode(2);
                  setSentResetPassword(false);
                }}
              >
                Forgot password
              </Button>
            )}

            <Flexbox row style={styles.buttons}>
              <Button primary onClick={handleLogin} style={styles.loginButton}>
                Log in
              </Button>
            </Flexbox>

            {accountVerified === false && emailAddress !== "" && (
              <Flexbox style={styles.resendButton}>
                <Button
                  textSize={13}
                  textColor={Colors.primary}
                  style={styles.resendButton}
                  onClick={handleResendEmail}
                  disabled={emailSent}
                >
                  Resend Verification Email
                </Button>
              </Flexbox>
            )}
            <Flexbox row style={styles.signup}>
              <TypeFace size={11}>
                If you dont have account,&nbsp;
                <TypeFace
                  onClick={() => {
                    setEmailAddress("");
                    setPassword("");
                    setPhoneNumber("");
                    setMode(1);
                  }}
                  medium
                  size={11}
                  color={Colors.primary}
                  style={{ cursor: "pointer" }}
                >
                  Sign up
                </TypeFace>
              </TypeFace>
            </Flexbox>
          </Flexbox>
        )}
        {mode === 1 && (
          <Flexbox
            style={{
              width: isMobile ? "calc(100vw - 80px)" : 320,
              padding: isMobile ? "20px 16px 16px" : "40px 30px 30px",
            }}
          >
            <TypeFace bold size={18}>
              Create ListMeet Account
            </TypeFace>
            <Flexbox style={{ width: "100%", gap: 10, marginTop: isMobile ? 5 : 10 }}>
              {/*<TypeFace style={{ alignSelf: 'flex-start' }}>I am {selectedRoleOption?.value === UserRoleValue.Agent ? 'an' : 'a'}</TypeFace> */}
              <div style={{ width: isMobile ? "100%" : 320 }}>
                <ReactSelect
                  options={userRoles}
                  placeholder="Select User Role"
                  value={selectedRoleOption}
                  isSearchable={false}
                  onChange={(v) => {
                    setSelectedRoleOption(v);
                  }}
                />
              </div>
            </Flexbox>
            <TextInput
              style={styles.emailInput}
              placeholder={
                selectedRoleOption?.value === UserRoleValue.ManageMent
                  ? "Company name"
                  : "Full name (first name, last name)"
              }
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/* {selectedRoleOption?.value === UserRoleValue.ManageMent &&
          <TextInput 
            style={styles.phoneInput}
            placeholder='Company phone number'
            value={companyPhone}
            onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            onChange={e => setCompanyPhone(e.target.value)}
          /> } */}
            <TextInput
              style={styles.passwordInput}
              placeholder={selectedRoleOption?.value === UserRoleValue.ManageMent ? "Company email" : "Email address"}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              //onChange={onChangeEmailPhone}
            />
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.companynameInput}
                placeholder="Point of contact full name"
                value={companyContactName}
                onChange={(e) => setCompanyContactName(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.phoneInput}
                placeholder="Point of contact phone number"
                value={companyContactPhone}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Tab" &&
                    event.key !== "Backspace" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "Delete" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeCompanyContactPhone}
                //onChange={e => setCompanyContactPhone(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.companyemailInput}
                placeholder="Point of contact email"
                value={companyContactEmail}
                onChange={(e) => setCompanyContactEmail(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.Broker && (
              <TextInput
                style={styles.brokerageInput}
                placeholder="Brokerage name"
                value={brokerageName}
                onChange={(e) => setBrokerageName(e.target.value)}
              />
            )}
            {(selectedRoleOption?.value === UserRoleValue.Broker ||
              selectedRoleOption?.value === UserRoleValue.Agent ||
              selectedRoleOption?.value === UserRoleValue.SalesPerson) && (
              <TextInput
                style={styles.licenseInput}
                placeholder="License#"
                onKeyDown={(event) => {
                  if (
                    event.key !== "Tab" &&
                    event.key !== "Backspace" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "Delete" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                value={license}
                onChange={(e) => setLicense(e.target.value)}
              />
            )}
            {phoneNumber === "" && (
              <>
                <TextInput
                  style={styles.passwordInput}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(event) => {
                    console.log(event.key);
                  }}
                />
                <TextInput
                  style={styles.passwordInput}
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </>
            )}
            {!!error && (
              <TypeFace size={11} color="red">
                {error}
              </TypeFace>
            )}
            <TextInput
              style={styles.passwordInput}
              placeholder={selectedRoleOption?.value === UserRoleValue.ManageMent ? "Company email" : "Email address"}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.companynameInput}
                placeholder="Point of contact full name"
                value={companyContactName}
                onChange={(e) => setCompanyContactName(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.phoneInput}
                placeholder="Point of contact phone number"
                value={companyContactPhone}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Tab" &&
                    event.key !== "Backspace" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "Delete" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setCompanyContactPhone(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.ManageMent && (
              <TextInput
                style={styles.companyemailInput}
                placeholder="Point of contact email"
                value={companyContactEmail}
                onChange={(e) => setCompanyContactEmail(e.target.value)}
              />
            )}
            {selectedRoleOption?.value === UserRoleValue.Broker && (
              <TextInput
                style={styles.brokerageInput}
                placeholder="Brokerage name"
                value={brokerageName}
                onChange={(e) => setBrokerageName(e.target.value)}
              />
            )}
            {(selectedRoleOption?.value === UserRoleValue.Broker ||
              selectedRoleOption?.value === UserRoleValue.Agent ||
              selectedRoleOption?.value === UserRoleValue.SalesPerson) && (
              <TextInput
                style={styles.licenseInput}
                placeholder="License#"
                onKeyDown={(event) => {
                  if (
                    event.key !== "Tab" &&
                    event.key !== "Backspace" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "Delete" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                value={license}
                onChange={(e) => setLicense(e.target.value)}
              />
            )}
            <TextInput
              style={styles.passwordInput}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(event) => {
                console.log(event.key);
              }}
            />
            <TextInput
              style={styles.passwordInput}
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {!!error && (
              <TypeFace size={11} color="red">
                {error}
              </TypeFace>
            )}
            <Flexbox row style={{ ...styles.buttons, marginTop: 40 }}>
              <Button primary onClick={handleCreateProfile} style={styles.loginButton}>
                Create Account
              </Button>
            </Flexbox>
            <Flexbox row style={styles.signup}>
              <TypeFace size={11}>
                If you have account,&nbsp;
                <TypeFace
                  onClick={() => {
                    setConfirmPassword("");
                    setPassword("");
                    setName("");
                    setEmailAddress("");
                    setPhoneNumber("");
                    setMode(0);
                  }}
                  medium
                  size={11}
                  color={Colors.primary}
                  style={{ cursor: "pointer" }}
                >
                  Log in
                </TypeFace>
              </TypeFace>
            </Flexbox>
          </Flexbox>
        )}
        {mode === 2 && (
          <Flexbox
            style={{
              width: isMobile ? "calc(100vw - 80px)" : 320,
              padding: isMobile ? "20px 16px 16px" : "40px 30px 30px",
            }}
          >
            <TypeFace bold size={18}>
              Reset Password
            </TypeFace>
            <TypeFace size={14}>Send a password reset email</TypeFace>
            <TextInput
              style={styles.emailInput}
              placeholder="Email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            {!!error && (
              <TypeFace size={11} color="red">
                {error}
              </TypeFace>
            )}
            <Flexbox row style={styles.buttons}>
              <Button primary onClick={handleResetPassword} style={styles.loginButton}>
                {sentResetPassword ? "Send Again" : "Send"}
              </Button>
            </Flexbox>
            <Flexbox row style={styles.signup}>
              <TypeFace size={11}>
                If you have account,&nbsp;
                <TypeFace
                  onClick={() => {
                    setEmailAddress("");
                    setPhoneNumber("");
                    setMode(0);
                  }}
                  medium
                  size={11}
                  color={Colors.primary}
                  style={{ cursor: "pointer" }}
                >
                  Login
                </TypeFace>
              </TypeFace>
            </Flexbox>
          </Flexbox>
        )}
        {processing && <Loading loading background="#0005" loaderColor="white" />}
      </Modal>
      <VerificationModal
        modalIsOpen={isVerificationModalOpen}
        phoneNumber={phoneNumber}
        sendDataToParent={sendDataToParent}
        getUserProfileData={getUserProfileData}
        isLogin={mode}
        confirmationResult={confirmResult}
        //isSignup={openingModalWithSignupMode}
        closeModal={() => setIsVerificationModalOpen(false)}
        // onSignup={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default LoginModal;

const styles = {
  container: {
    width: 320,
    padding: "40px 30px 30px",
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: "100%",
    marginTop: 10,
    justifyContent: "space-between",
    gap: 20,
  },
  signup: {
    width: "100%",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  forgotPasswordButton: {
    height: 40,
    alignSelf: "flex-end",
  },
  socialButtons: {
    width: 300,
    height: 44,
  },
  socialButtonsPanel: {
    marginTop: 30,
    gap: 15,
  },
  emailInput: {
    width: "100%",
    marginTop: 30,
  },
  passwordInput: {
    width: "100%",
    marginTop: 10,
  },
  brokerageInput: {
    width: "100%",
    marginTop: 10,
  },
  licenseInput: {
    width: "100%",
    marginTop: 10,
  },
  phoneInput: {
    width: "100%",
    marginTop: 10,
  },
  companyemailInput: {
    width: "100%",
    marginTop: 10,
  },
  companynameInput: {
    width: "100%",
    marginTop: 10,
  },
  resendButton: {
    height: 40,
    alignSelf: "center",
    marginTop: 5,
    cursor: "pointer",
  },
};
