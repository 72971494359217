import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'src/hooks/alert/AlertContext';
import Loading from 'react-fullscreen-loading';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import TextInput from 'src/components/input/TextInput';
import { validateEmailAddress } from 'src/utils/strings';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import useMediaQuery from 'src/hooks/useMediaQuery';

const HomeAgencyModal = ({
  modalIsOpen,
  onCloseModal,
}) => {

  const { userProfile } = useFirestore()
  const { isMobile } = useMediaQuery()
  const {showAlert} = useAlert()

  const [name, setName] = useState(userProfile?.name ?? "")
  const [email, setEmail] = useState(userProfile?.email ?? "")
  const [address, setAddress] = useState("")
  const [comment, setComment] = useState("")
  const [error, setError] = useState("")

  const [processing, setProcessing] = useState(false)

  const [isRental, setIsRental] = useState(false)
  const [isSale, setIsSale] = useState(false)

  const submitEnabled = useMemo(() => {
    return name !== "" && email !== "" && address !== ""
  }, [name, email, address])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    document.body.style.overflow = 'hidden'
  }

  function closeModal() {
    document.body.style.overflow = 'auto'
    onCloseModal()
  }

  const handleSubmit = async () => {
    if (!validateEmailAddress(email)) {
      setError("Enter a valid email address")
      return
    }

    if (!isRental && !isSale) {
      setError("Please select rental or sale for agency type")
      return
    }

    setProcessing(true)

    getDocs(query(collection(firestore, 'agencyRequests'), where('email', '==', email.toLowerCase()))).then(docs => {
      if (!docs.empty) {
        setProcessing(false)
        showAlert(true, 'Already submitted agency request.')
      } else {
        addDoc(collection(firestore, "agencyRequests"), {
          email: email.toLowerCase(),
          name,
          address,
          isRental,
          isSale,
          comment,
          created: new Date().getTime()
        })
        .then(() => {
          setProcessing(false)
          closeModal()
        })
        .catch(err => {
          console.log("Error => ", error)
          setProcessing(false)
          closeModal()
        })
      }
    })

    
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{
        ...styles.container,
        width: isMobile ? 320 : 360,
      }}>
        <Flexbox style={styles.content}>
          <TypeFace bold size={18}>
            Agency
          </TypeFace>
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Name
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={name}
            placeholder="First, last name"
            onChange={e => setName(e.target.value)}
          />
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Email
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={email}
            placeholder="jondae@email.com"
            onChange={e => setEmail(e.target.value)}
          />
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Address
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={address}
            placeholder="123 Dumont Ave"
            onChange={e => setAddress(e.target.value)}
          />
          <Flexbox row style={{ justifyContent: 'flex-start', width: '100%', marginTop: 20 }}>
            <input type="checkbox" id="rental" checked={isRental} name="type" onChange={e => {
              setIsRental(e.target.checked)
            }} />
            <TypeFace for="rental" semiBold size={16} style={{ marginLeft: 3 }}>
              Rental
            </TypeFace>
            <input type="checkbox" id="sale" checked={isSale} name="type" onChange={e => {
              setIsSale(e.target.checked)
            }} style={{ marginLeft: 20 }} />
            <TypeFace for="sale" semiBold size={16} style={{ marginLeft: 3 }}>
              Sale
            </TypeFace>
          </Flexbox>
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Comment
          </TypeFace>
          <textarea
            style={styles.commentInput}
            placeholder="Type message here"
            onChange={(e) => setComment(e.target.value)}
          />
          {!!error && (
            <TypeFace color='red' size={11}>
              {error}
            </TypeFace>
          )}
          <Flexbox row style={styles.buttons}>
            <Button disabled={!submitEnabled} primary onClick={handleSubmit} style={styles.loginButton}>
              Submit
            </Button>
            <Button secondary onClick={handleCancel} style={styles.loginButton}>
              Cancel
            </Button>
          </Flexbox>
        </Flexbox>
        
      </Flexbox>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Modal>
  )
}

export default HomeAgencyModal

const styles = {
  container: {
    width: 360,
    height: 600,
    overflow: 'auto',
    padding: '30px 30px 30px',
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: '100%',
    marginTop: 30,
    justifyContent: 'space-between',
    gap: 20,
  },
  licenseNumberInput: {
    width: '100%',
    marginTop: 5,
  },
  textInput: {
    marginTop: 5,
    width: '100%',
    borderRadius: 0,
    border: '1px solid #000',
    height: 44,
  },
  commentInput: {
    width: 'calc(100% - 20px)',
    height: 120,
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  }
}