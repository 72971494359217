import React from 'react'
import useMediaQuery from 'src/hooks/useMediaQuery';
import { Colors } from 'src/theme/Colors';
import Button from '../button/Button';
import TypeFace from '../typography/Typefaces';

const HomeSpecialActionCard = ({
  title,
  desc,
  buttonText,
  onAction,
  style,
}) => {

  const { isMobile } =  useMediaQuery()

  return (
    <div style={{...styles.container, ...style}}>
      <TypeFace bold size={19} style={{ marginTop: 44 }}>
        {title}
      </TypeFace>
      <div style={styles.descPanel}>
        <TypeFace semiBold size={19} color={'black'} style={{ textAlign: 'center' }}>
          {desc}
        </TypeFace>
      </div>
      <Button secondary textSize={16} style={{ height: 44, marginBottom: 44 }} onClick={onAction}>
        {buttonText}
      </Button>
    </div>
  )
}

export default HomeSpecialActionCard

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 16,
    alignItems: 'center',
    boxShadow: '1px 1px 3px #0000002f',
  },
  descPanel: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}