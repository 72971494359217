import { useEffect, useMemo, useState } from "react"
import Button from "src/components/button/Button"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { useFirestore } from "src/hooks/firestore/FirestoreContext"
import { createAccountOnboardingLink, createLoginLink, createStripeAccount, retrieveAccount, retrieveBalance } from "src/service/stripe/stripes"
import Loading from 'react-fullscreen-loading';
import { doc, updateDoc } from "firebase/firestore"
import { firestore } from "src/firebase"
import { stripeCurrencyDisplayFormat } from "src/utils/strings"

const StripeBoard = () => {
  const { userProfile } = useFirestore()

  const [processing, setProcessing] = useState(false)
  const [stripeAccount, setStripeAccount] = useState()
  const [stripeBalance, setStripeBalance] = useState()

  const handleCompleteStripeSetup = () => {
    setProcessing(true)
    const stripeAccountId = userProfile.stripeAccountId
    console.log("Stripe account id => ", userProfile.stripeAccountId)
    createAccountOnboardingLink({
      accountId: stripeAccountId,
      callback: (accountLink) => {
        setProcessing(false)
        console.log("Account Link created => ", accountLink)
        window.open(accountLink.url, '_blank', 'noreferrer');
      },
      errorCallback: (err) => {
        setProcessing(false)
        console.log("Failed accountLink creationg => ", err)
      }
    })
  }

  const handleOpenStripeDashboard = () => {
    setProcessing(true)
    createLoginLink({
      accountId: userProfile.stripeAccountId,
      callback: (loginLink) => {
        setProcessing(false)
        console.log("LoginLink created => ", loginLink)
        window.open(loginLink.url, '_blank', 'noreferrer');
      },
      errorCallback: (err) => {
        setProcessing(false)
        console.log("Failed to create login link => ", err)
      }
    })
  }

  const handleStripeConnect = () => {
    setProcessing(true)
    // Step 1: Create stripe account
    createStripeAccount({
      email: userProfile.email,
      callback: (account) => {
        console.log("Created stripe account => ", account)
        console.log("Account Id => ", account.id)

        updateDoc(doc(firestore, 'profiles', userProfile.userId), {
          stripeAccountId: account.id
        })
        
        createAccountOnboardingLink({
          accountId: account.id,
          callback: (accountLink) => {
            setProcessing(false)
            console.log("Account Link created => ", accountLink)
            window.open(accountLink.url, '_blank', 'noreferrer');
          },
          errorCallback: (err) => {
            setProcessing(false)
            console.log("Failed accountLink creationg => ", err)
          }
        })
      },
      errorCallback: err => {
        setProcessing(false)
        console.log("Failed Created stripe account => ", err)
      }
    })
  }

  useEffect(() => {
    if (userProfile?.stripeAccountId) {
      if (!processing) {
        retrieveAccount({
          accountId: userProfile.stripeAccountId,
          callback: (account) => {
            setStripeAccount(account)
          },
          errorCallback: (err) => {
          }
        })
      }
    }
  }, [userProfile, processing])

  useEffect(() => {
    if (stripeAccount && stripeAccount.charges_enabled && stripeAccount.payouts_enabled) {
      retrieveBalance({
        accountId: stripeAccount.id,
        callback: balance => {
          setStripeBalance(balance)
        },
        errorCallback: err => {
          console.log("Balance error => ", err)
        }
      })
    }
  }, [stripeAccount])

  const stripeBalanceAvailable = useMemo(() => {
    if (stripeBalance) {
      return stripeBalance.available.reduce((acc, obj) => acc + obj.amount, 0)
    }
  }, [stripeBalance])

  const stripeBalancePending = useMemo(() => {
    if (stripeBalance) {
      return stripeBalance.pending.reduce((acc, obj) => acc + obj.amount, 0)
    }
  }, [stripeBalance])

  return (
    <Flexbox style={{ width: '100%', gap: 10, alignItems: 'flex-start' }}>
      <TypeFace semiBold size={14} color='#5f5f5f'>
        Payment Details
      </TypeFace>
      {userProfile?.stripeAccountId !== undefined ? (
        <>
          {(stripeAccount && stripeAccount.details_submitted) ? (
            <>
              {stripeBalance && (
                <>
                  <TypeFace>
                    Available: <TypeFace bold>{stripeCurrencyDisplayFormat(stripeBalanceAvailable)}</TypeFace>
                  </TypeFace>
                  <TypeFace style={{ marginBottom: 20 }}>
                    Pending: <TypeFace bold>{stripeCurrencyDisplayFormat(stripeBalancePending)}</TypeFace>
                  </TypeFace>
                </>
              )}
              <Button onClick={handleOpenStripeDashboard} primary style={styles.connectStripeButton}>
                Open Stripe Dashboard
              </Button>  
            </>
          ) : (
            <Button onClick={handleCompleteStripeSetup} primary style={styles.connectStripeButton}>
              Complete Setup
            </Button>
          )}
        </>
      ) : (
        <Button onClick={handleStripeConnect} primary style={styles.connectStripeButton}>
          Connect
        </Button>
      )}
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Flexbox>
  )
}

export default StripeBoard

const styles = {
  connectStripeButton: {
    height: 44,
    width: 250,
    borderRadius: 5
  }
}