import React, { useEffect, useState } from 'react';
import LoginModal from 'src/components/auth/LoginModal';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Flexbox from 'src/components/wrapper/Flexbox';
import useMediaQuery from 'src/hooks/useMediaQuery';
import LoaderGif from 'src/assets/images/loader3.gif';
import TypeFace from 'src/components/typography/Typefaces';
import { Colors } from 'src/theme/Colors';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import CustomerScheduleEntry from './components/CustomerScheduleEntry';
import HomeBlueMenuBar from 'src/components/home/HomeBlueMenuBar';

const CustomerSchedules = () => {

  const { isMobile } =  useMediaQuery()
  const { userProfile } = useFirestore()

  const [isLoading, setIsLoading] = useState(false)
  const [schedules, setSchedules] = useState([])

  const loadCustomerSchedules = () => {
    const q = query(collection(firestore, 'schedules'), where("customer.id", "==", userProfile.userId))
    getDocs(q).then(docsSnapshot => {
      let schedulesList = []
      docsSnapshot.forEach(doc => {
        schedulesList.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setSchedules(schedulesList)
    })
  }

  useEffect(() => {
    if (userProfile?.userId) {
      loadCustomerSchedules()
    }
  }, [userProfile?.userId])

  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {!isMobile && <HomeBlueMenuBar />}
          <Flexbox style={{ width: '100%' }}>
            {isLoading ? (
              <img src={LoaderGif} style={styles.loader} />
            ) : (
              <Flexbox style={{
                width: '90%',
                maxWidth: 1178,
                paddingTop: 30,
                alignItems: 'center'
              }}>
                <TypeFace semiBold size={14} style={styles.title}>
                  Meeting Schedules
                </TypeFace>
                <div style={styles.separator} />
                {(schedules?.length ?? 0) === 0 ? (
                  <Flexbox style={{
                    ...styles.propertiesContainer,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}>
                    No meeting schedules
                  </Flexbox>  
                ) : (
                  <Flexbox style={{
                    ...styles.propertiesContainer,
                    flexDirection: 'column'
                  }}>
                    {schedules.map((schedule, index) => (
                      <CustomerScheduleEntry key={`schedule-${index}`} schedule={schedule} />
                    ))}
                  </Flexbox>
                )}
              </Flexbox>
            )}
          </Flexbox>
        </div>
      </div>
    </div>
  )
}

export default CustomerSchedules

const styles = {
  profileContainer: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 60,
    alignSelf: 'center'
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.gray3
  },
  loader: {
    width: 30, height: 30, marginTop: 40
  },
  title: {
    marginTop: 20, marginBottom: 10, alignSelf: 'flex-start'
  },
  propertiesContainer: {
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 20,
  }
}
