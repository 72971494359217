import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect, createContext } from "react";
import { auth } from "src/firebase";
import AuthContext from "./AuthContext";
import Flexbox from "src/components/wrapper/Flexbox";
import TextInput from "src/components/input/TextInput";
import TypeFace from "src/components/typography/Typefaces";
import { useDispatch, useSelector } from "react-redux";
import { selectLastPassedTime } from "src/redux/draft/selectors";
import { storeLastPassedTime } from "src/redux/draft/actions";
import { store } from "src/redux/store";
import useMediaQuery from "../useMediaQuery";

const PasscodeInputForm = ({ onPass }) => {
  const { isMobile } = useMediaQuery();

  return (
    <Flexbox style={{ width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Flexbox style={{ alignItems: "flex-start" }}>
        <TypeFace style={{ marginBottom: 10 }}>Enter passcode</TypeFace>
        <TextInput
          type="password"
          onChange={(e) => {
            console.log(e.target.value);
            if (e.target.value === "ListMeet456") {
              onPass();
            }
          }}
          style={{
            textAlign: "center",
            fontSize: 30,
            width: isMobile ? "calc(100vw - 40px)" : 400,
            height: 50,
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

const AppProtectContext = createContext();

export const AppProtectProvider = ({ children }) => {
  const lastPassedTime = useSelector(selectLastPassedTime);
  const [passed, setPassed] = useState(false);

  const handlePass = () => {
    store.dispatch(storeLastPassedTime({}));
  };

  useEffect(() => {
    if (lastPassedTime) {
      if (new Date().getTime() - lastPassedTime < 24 * 3600 * 1000) {
        setPassed(true);
      }
    }
  }, [lastPassedTime]);

  return (
    <AppProtectContext.Provider value={{}}>
      {passed ? children : <PasscodeInputForm onPass={handlePass} />}
    </AppProtectContext.Provider>
  );
};
