export const neighboursList = [
  {
    city: 'Manhattan',
    amount: 4500,
    image: require('src/assets/images/places/9.jpg'),
  },
  {
    city: 'Brooklyn',
    amount: 3542,
    image: require('src/assets/images/places/10.jpg'),
  },
  {
    city: 'Queens',
    amount: 2475,
    image: require('src/assets/images/places/11.jpg'),
  },
  {
    city: 'New Jersey',
    amount: 3547,
    image: require('src/assets/images/places/12.jpg'),
  },
  {
    city: 'Staten Island',
    amount: 3485,
    image: require('src/assets/images/places/13.jpg'),
  },
  {
    city: 'Bronx',
    amount: 1548,
    image: require('src/assets/images/places/10.jpg'),
  },
]