export function validateEmailAddress(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validateYoutubeURL(link) {
  if (link !== "" && link !== undefined) {
    const youtubeIdRegex =
      /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?<=\/|v\/|u\/|embed\/|shorts\/|watch\?v=)(?<!\/user\/)(?<id>[\w\-]{11})(?=\?|&|$)/;

    return link.match(youtubeIdRegex)?.groups?.id || false;
  } else {
    return false;
  }
}

export const sendPushNotificationForChat = async (strTitle, strMessage, strDeviceToken, strMessageType) => {
  const response = await fetch("https://us-central1-listmeetdev.cloudfunctions.net/sendPushNotification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: strTitle,
      body: strMessage,
      messageType: strMessageType,
      token: strDeviceToken,
    }),
  });

  const res = await response.json();
  // console.log('res-pushNotification=',res)
};

export function titleCase(string) {
  let normalCaseString = string?.replace(string[0], string[0].toUpperCase());
  return normalCaseString;
}

export const scheduleFromToString = (schedule) => {
  const startH = schedule.start.h;
  const startM = schedule.start.m;

  const endH = schedule.end.h;
  const endM = schedule.end.m;

  return `${String(startH > 12 ? startH - 12 : startH).padStart(2, 0)}:${String(startM).padStart(2, 0)} ${
    startH < 12 ? "AM" : "PM"
  } ~ ${String(endH > 12 ? endH - 12 : endH).padStart(2, 0)}:${String(endM).padStart(2, 0)} ${endH < 12 ? "AM" : "PM"}`;
};

export const priceFormat = (price, isFullFormat = false) => {
  let priceNumber = price;
  if (typeof price === "string") {
    priceNumber = Number(price);
  }
  if (isFullFormat) {
    return priceNumber.toLocaleString("en-US");
  } else {
    if (priceNumber < 10000) {
      return priceNumber.toLocaleString("en-US");
    } else if (priceNumber < 1000000) {
      return `${priceNumber / 1000}K`;
    } else {
      return `${priceNumber / 1000000}M`;
    }
  }
};

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 1) + "...";
  } else {
    return str;
  }
};

export const stripeCurrencyDisplayFormat = (priceInCents) => {
  return `$${(priceInCents / 100).toFixed(2)}`;
};
