import React, { useEffect, useState } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { Document, Page, pdfjs } from "react-pdf";
import "./index.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FairHousing = () => {
  const { isMobile } =  useMediaQuery()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
            <Document file="./fair-housing-notice.pdf">
              <Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1} />
            </Document>
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default FairHousing

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "10px 0",
    textAlign: "left",
    lineHeight: 1.45
  }
}