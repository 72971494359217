import { STORE_GRANT_TOKEN, STORE_LASTEST_UPDATED_DATE_TIME, STORE_USER_NAME } from "./actions"

const initialState = {
  areas: []
}

const areasReducer = (state = initialState, action) => {
  switch (action.type) {
  case STORE_USER_NAME:
    return { ...state, userName: action.payload }
  case STORE_LASTEST_UPDATED_DATE_TIME:
    return { ...state, lastestUpdateDateTime: action.payload }
  case STORE_GRANT_TOKEN:
    return { ...state, grantToken: action.payload }
  default:
    return state
  }
}

export default areasReducer