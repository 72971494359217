import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "src/theme/Colors";
import TypeFace from "../typography/Typefaces";
import Flexbox from "../wrapper/Flexbox";
import { storeSearchObject } from "src/redux/search/actions";
import { selectSearchObject } from "src/redux/search/selectors";
import Button from "../button/Button";
import HomeAdvancedSearchFilterPopup from "../home/search/HomeAdvancedSearchFilterPopup";
import useMediaQuery from "src/hooks/useMediaQuery";
import IconFilter from "src/assets/images/icons/icon-filter-blue.svg";
import PriceRangeSelectBox from "../home/search/PriceRangeSelectBox";
import { searchProperties } from "src/algolia";
import { useNavigate } from "react-router";
import { areas, areas as areasData, zips } from "src/data/AreasData";
import ReactSelectAsync from "react-select/async";

const SearchOptionsBar = () => {
  const dispatch = useDispatch();
  const currentSearchObject = useSelector(selectSearchObject);
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const [isOpeningAdvancedFilters, setIsOpeningAdvancedFilters] = useState(false);
  const [value, setValue] = useState("");

  let reactSelectStyles = {
    placeholder: (base) => ({
      ...base,
      textAlign: "start",
    }),
    valueContainer: (base) => ({
      ...base,
      textAlign: "start",
      border: "none",
      boxShadow: "none",
    }),
    control: (base) => ({
      ...base,
      border: "none",
      borderRadius: 0,
    }),
    option: (base) => ({
      ...base,
      textAlign: "start",
    }),
    input: (base) => ({
      ...base,
      border: "none",
    }),
    container: (base) => ({
      ...base,
      border: "1px solid black",
      boxShadow: "none",
    }),
    groupHeading: (base) => ({
      ...base,
      display: "flex",
      marginBottom: "10px",
      alignItems: "center",
      fontWeight: "bold",
    }),
  };

  async function loadOptions(input, callback) {
    let properties = [];
    let areasData = areas
      .filter((v) => v.name.toLowerCase().search(input.toLowerCase()) !== -1)
      .sort((a, b) => a?.name?.search(input) - b?.name?.search(input))
      .slice(0, 5)
      .map((a) => ({ ...a, type: "area", label: a.place_name, value: a.name }));

    let zipData = zips
      .filter((v) => v.zip.search(input) !== -1)
      .sort((a, b) => a?.zip?.search(input) - b?.zip?.search(input))
      .slice(0, 3)
      .map((a) => ({ ...a, type: "zip", label: a.zip, value: a.zip }));

    if (areasData.length === 0) {
      let data = await searchProperties(
        input,
        {
          filters: `status:Active AND type: ${currentSearchObject?.type} `,
          hitsPerPage: 3,
        },
        "newest"
      );

      properties = [
        {
          label: "Properties",
          options: [...data.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId }))],
        },
      ];
    }

    callback([
      {
        label: "Areas",
        options: [...areasData],
      },
      {
        label: "Zips",
        options: [...zipData],
      },
      ...properties,
      // ...properties.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId })),
      // ...properties.hits.map((v) => ({ ...v, type: "property", label: v.address.full, value: v.dataId })),
    ]);
  }

  function handleChange(value, action) {
    console.log('action=',action);
    if (action.action === "remove-value" || action.action === "pop-value") {
      let query = currentSearchObject?.query.filter((v) => v.id !== action.removedValue.id);
      dispatch(storeSearchObject({ ...currentSearchObject, query }));
    }
    if (action.action === "select-option") {
      if (action.option.type === "property") {
        navigate(`/listing/${action.option.dataId}`);
      } else {
        console.log("action.option=",action.option);
        console.log("currentSearchObject=",currentSearchObject);
        dispatch(
          storeSearchObject({
            ...currentSearchObject,
            query: [...currentSearchObject.query, action.option],
          })
        );
      }
    }
  }

  const handleAdvancedOptions = () => {
    setIsOpeningAdvancedFilters(true);
  };

  return (
    <Flexbox style={styles.container}>
      <Flexbox style={{ width: "90%", maxWidth: 1178 }}>
        <Flexbox
          row
          style={{
            ...styles.content,
            alignItems: "flex-end",
          }}
        >
          <Flexbox style={{ alignItems: "flex-start", flex: 1, maxWidth: 460 }}>
            <TypeFace size={18} semiBold>
              Location
            </TypeFace>
            <div style={{ ...styles.locationInput, width: "100%", position: "relative", zIndex: 9 }}>
              <ReactSelectAsync
                id="location-input2"
                loadOptions={loadOptions}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder={"Search for address, neighborhood, or zip"}
                style={{ width: "100%" }}
                isMulti
                onChange={handleChange}
                value={currentSearchObject?.query || []}
                styles={reactSelectStyles}
                formatOptionLabel={(option, { context }) => {
                  return context === "menu" ? option.place_name || option.label : option.name || option.label;
                }}
              />
            </div>
          </Flexbox>
          {!isMobile && (
            <>
              {currentSearchObject?.price && (
                <Flexbox style={{ alignItems: "flex-start" }}>
                  <TypeFace size={18} semiBold>
                    Price Range
                  </TypeFace>
                  <PriceRangeSelectBox
                    isForSale={currentSearchObject?.type === "sale"}
                    minValue={currentSearchObject?.price?.from}
                    maxValue={currentSearchObject?.price?.to}
                    optionWidth={140}
                    onValueChanged={(min, max) => {
                      const priceValues = {};
                      if (min) {
                        priceValues.from = min;
                      }
                      if (max) {
                        priceValues.to = max;
                      }

                      dispatch(
                        storeSearchObject({
                          ...currentSearchObject,
                          price: priceValues,
                        })
                      );
                    }}
                  />
                </Flexbox>
              )}
            </>
          )}
          {isMobile ? (
            <img src={IconFilter} width={30} height={30} onClick={handleAdvancedOptions} style={{ marginBottom: 5 }} />
          ) : (
            <Button
              secondary
              icon={<img src={IconFilter} width={20} height={20} />}
              onClick={handleAdvancedOptions}
              style={{ width: 97, height: 40, borderRadius: 0 }}
            >
              Filter
            </Button>
          )}
        </Flexbox>
      </Flexbox>
      <HomeAdvancedSearchFilterPopup
        modalIsOpen={isOpeningAdvancedFilters}
        filters={currentSearchObject}
        closeModal={() => setIsOpeningAdvancedFilters(false)}
      />
    </Flexbox>
  );
};

export default SearchOptionsBar;

const styles = {
  container: {
    width: "100%",
    position: "relative",
    borderBottom: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    width: "100%",
    height: "100%",
    gap: 10,
    alignItems: "flex-end",
  },
  optionBox: {
    marginTop: 5,
    width: 200,
  },
  locationInput: {
    marginTop: 5,
    width: 260,
    shadowColor: Colors.gray1,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    borderRadius: 5,
    //backgroundColor: 'red',
  },
  mapIconContainer: {
    cursor: "pointer",
    display: "flex",
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  tagInputContainer: {
    border: "1px solid black",
    padding: ".5em",
    borderRadius: "3px",
    width: "95%",
    //width: 'min(80vw, 600px)',
    //marginTop: '1em',
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: ".5em",
    fontSize: "12px",
  },
  tagItem: {
    backgroundColor: "#F1F1F1",
    display: "inline-block",
    padding: ".5em .75em",
    //border: '1px solid black',
    // boxShadow: 'none'
  },
  tagItemClose: {
    height: "10px",
    width: "10px",
    fontWeight: "bold",
    color: "#000",
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: ".5em",
    fontSize: "15px",
    cursor: "pointer",
  },
  tagsInput: {
    flexGrow: 1,
    padding: ".5em 0",
    border: "none",
    outline: "none",
    fontSize: "14px",
  },
};
