import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import TextInput from "../input/TextInput";
import Flexbox from "../wrapper/Flexbox";
import { extractAddressComponent, getComponents } from "src/utils/geoUtils";

const PlacesAutoCompleteComponent = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "US",
      },
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (suggestion) => async () => {
    console.log("Suggestion");
    console.log(suggestion);
    const { description } = suggestion;
    setValue(description, false);
    clearSuggestions();
    props.onStartLoading?.();

    let addressComponents = await getComponents(description);

    // props.onSelectAddress?.({
    //   address: description,
    //   lat: addressComponents.geo.lat,
    //   lng: addressComponents.geo.lng,
    //   mainText: suggestion.structured_formatting.main_text,
    //   components: addressComponents.components,
    // });

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);

      let mapBoxApiUrl = "";

      if (props.isReverseGeo) {
        mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
      } else {
        if (props.isAreaInput) {
          if (suggestion.terms && suggestion.terms.length > 1) {
            const matchingText = `${suggestion.terms[0].value},${suggestion.terms[1].value}`;
            mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${matchingText?.replaceAll(
              " ",
              "%20"
            )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
          } else {
            mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${suggestion.structured_formatting.main_text?.replaceAll(
              " ",
              "%20"
            )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
          }
        } else {
          mapBoxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${description?.replaceAll(
            " ",
            "%20"
          )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
        }
      }

      console.log("Loading mapbox geo => ", mapBoxApiUrl);

      fetch(mapBoxApiUrl)
        .then((res) => res.json())
        .then((result) => {
          const components = extractAddressComponent(results[0].address_components, result?.features);
          props.onSelectAddress?.({
            address: description,
            lat,
            lng,
            mainText: suggestion.structured_formatting.main_text,
            components,
          });
        });
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <Flexbox
          style={{
            textAlign: "start",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            cursor: "pointer",
          }}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <Flexbox style={{ height: 36, flexDirection: "row", justifyContent: "flex-start", marginLeft: 20 }}>
            <strong>{main_text}&nbsp;</strong>
            <small>{secondary_text}</small>
          </Flexbox>
          <div style={{ width: "100%", height: 1, backgroundColor: "#dfdfdf" }} />
        </Flexbox>
      );
    });

  return (
    <Flexbox ref={ref} style={props.style}>
      <TextInput
        style={{
          width: "100%",
        }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter address"
      />
      {status === "OK" && <Flexbox style={{ width: "100%", alignItems: "flex-start" }}>{renderSuggestions()}</Flexbox>}
    </Flexbox>
  );
};

export default PlacesAutoCompleteComponent;
