import React from 'react'
import Button from 'src/components/button/Button'
import MeetNowButton from 'src/components/button/MeetNowButton'
import Flexbox from 'src/components/wrapper/Flexbox'

const MobileListingDetailsBottomSticker = ({
  isMeetLaterAlreadySetup,
  handleOpenMeetLater
}) => {
  return (
    <Flexbox row style={styles.stickyContainer}>
      <MeetNowButton
        smallMode
        style={{ width: 'unset', flex: 1.4 }}
      />
      {!isMeetLaterAlreadySetup && (
        <Button secondary onClick={handleOpenMeetLater} style={styles.meetLaterButton} textSize={16}>
          Meet Later
        </Button>
      )}
    </Flexbox>
  )
}

export default MobileListingDetailsBottomSticker

const styles = {
  stickyContainer: {
    width: 'calc(100% - 20px)',
    gap: 10,
    backgroundColor: 'white',
    padding: '10px 10px',
    position: 'sticky',
    bottom: 0,
    borderTop: '1px solid #cdcdcd',
  },
  meetLaterButton: {
    flex: 1,
    width: 'unset',
    height: 45,
  }
}