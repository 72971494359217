import IconAgentsGray from "src/assets/images/icons/menu/icon-agents-gray.svg";
import IconAgentsBlue from "src/assets/images/icons/menu/icon-agents-blue.svg";
import IconPropertyGray from "src/assets/images/icons/menu/icon-properties-gray.svg";
import IconPropertyBlue from "src/assets/images/icons/menu/icon-properties-blue.svg";
import IconSettingsGray from "src/assets/images/icons/menu/icon-profile-gray.svg";
import IconSettingsBlue from "src/assets/images/icons/menu/icon-profile-blue.svg";
import IconChatsGray from "src/assets/images/icons/menu/icon-chats-gray.svg";
import IconChatsBlue from "src/assets/images/icons/menu/icon-chats-blue.svg";
import IconScheduleGray from "src/assets/images/icons/menu/icon-schedule-gray.svg";
import IconScheduleBlue from "src/assets/images/icons/icon-calendar.svg";
import IconLogout from "src/assets/images/icons/icon-menu-logout.svg";
import { auth } from "src/firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useRef, useMemo } from "react";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { get } from "lodash";
import { UserRoleValue } from "src/model/types";
import { Badge, Flex, Typography } from "antd";

const { Text } = Typography;

const ManageSideMenu = (props) => {
  const { userProfile, myAgencyAgentsList } = useFirestore();
  const navigate = useNavigate();
  const unmountedRef = useRef(false);

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const messages = get(userProfile, "notifications.message", []);
  const schedules = get(userProfile, "notifications.schedule", []);

  const agentsList = useMemo(() => {
    return myAgencyAgentsList?.filter(
      (agent) =>
        (agent.role === UserRoleValue.Agent || agent.role === UserRoleValue.SalesPerson) &&
        agent.agencyApproved === false &&
        (agent.agencyDeclined === false || agent.agencyDeclined === undefined)
    );
  }, [myAgencyAgentsList]);

  const routes = [
    {
      title: "My Profile",
      selectedIcon: IconSettingsBlue,
      icon: IconSettingsGray,
      path: "/manage/settings",
    },

    {
      title: "Listings",
      selectedIcon: IconPropertyBlue,
      icon: IconPropertyGray,
      path: "/manage/listings",
    },
    {
      title: "Schedules",
      selectedIcon: IconScheduleBlue,
      icon: IconScheduleGray,
      path: "/manage/schedules",
      badge: schedules?.length,
    },
    {
      title: "Messages",
      selectedIcon: IconChatsBlue,
      icon: IconChatsGray,
      path: "/manage/messages",
      badge: messages?.length,
    },
  ];

  return (
    <Flex vertical gap={5} style={{ padding: 5, width: 200 }}>
      {routes.map((link, index) => (
        <NavLink key={index} className={"nav-link"} to={link.path}>
          <img className="icon" src={link.icon} width={25} height={25} />
          <img className="selected-icon" src={link.selectedIcon} width={25} height={25} />
          <Text>{link.title}</Text>
          {link.badge > 0 && <Badge count={link.badge} />}
        </NavLink>
      ))}
      {userProfile?.role === "broker" && (
        <NavLink className={"nav-link"} to={"/manage/agents"}>
          <img className="icon" src={IconAgentsGray} width={25} height={25} />
          <img className="selected-icon" src={IconAgentsBlue} width={25} height={25} />
          <Text>Agents</Text>
          {agentsList?.length > 0 && <Badge count={agentsList?.length} />}
        </NavLink>
      )}
      <a className={"nav-link logout"} onClick={handleLogout}>
        <img className="icon" src={IconLogout} width={25} height={25} />
        <Text type="danger">Logout</Text>
      </a>
    </Flex>
  );
};

export default ManageSideMenu;
