import useGoogleMapsLoader from "./useGoogleMapsLoader";
import PlacesAutoCompleteComponent from "./PlaceAutoCompleteComponent";

const PlacesAutocomplete = (props) => {

  const { isLoaded } = useGoogleMapsLoader();

  if (!isLoaded) {
    return null
  }

  return <PlacesAutoCompleteComponent {...props} />
};


export default PlacesAutocomplete