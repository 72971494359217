export const STORE_SEARCH_OBJECT = "STORE_SEARCH_OBJECT"
export const ADD_OBJECT_ID_TO_SAVED = "ADD_OBJECT_ID_TO_SAVED"
export const REMOVE_OBJECT_ID_FROM_SAVED = "REMOVE_OBJECT_ID_FROM_SAVED"

export const storeSearchObject = (searchObject) => {
  return {
    type: STORE_SEARCH_OBJECT,
    payload: searchObject
  }
}

export const addObjectIdToSaved = (objectId) => {
  return {
    type: ADD_OBJECT_ID_TO_SAVED,
    payload: objectId
  } 
}

export const removeObjectIdFromSaved = (objectId) => {
  return {
    type: REMOVE_OBJECT_ID_FROM_SAVED,
    payload: objectId
  } 
}

