import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useState } from "react";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router";
import Button from "src/components/button/Button";
import TextInput from "src/components/input/TextInput";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { auth } from "src/firebase";
import { useAlert } from "src/hooks/alert/AlertContext";
import useMediaQuery from "src/hooks/useMediaQuery";

export default function ChangePassword() {
  const { isMobile } = useMediaQuery();
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState("⠀");
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  async function handlePasswordChange() {
    try {
      setError("⠀");
      setLoading(true);
      const credential = EmailAuthProvider.credential(auth.currentUser.email, form.currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, form.newPassword);
      setLoading(false);
      setForm({ newPassword: "", confirmPassword: "" });
      showAlert(true, "Password updated successfully. Please login again.");
      auth.signOut();
      navigate("/");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("The current password is incorrect. Please try again.");
      } else {
        setError("Failed to update password. Please try again.");
      }
      setLoading(false);
    }
  }
  return (
    <Flexbox style={{ marginTop: 0, gap: 20, alignItems: "flex-start", width: "100%" }}>
      <TypeFace semiBold size={14} color="#5f5f5f">
        Change Password
      </TypeFace>
      <Flexbox
        row
        style={{
          gap: isMobile ? 10 : 20,
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <TypeFace bold size={11} style={{ width: isMobile ? "unset" : 180, textAlign: "start" }}>
          Current Password
        </TypeFace>
        <TextInput
          style={{ width: isMobile ? "calc(100vw - 80px)" : 240 }}
          value={form.currentPassword}
          onChange={(e) => setForm({ ...form, currentPassword: e.target.value })}
          type="password"
          placeholder="Enter new password"
        />
      </Flexbox>
      <Flexbox
        row
        style={{
          gap: isMobile ? 10 : 20,
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <TypeFace bold size={11} style={{ width: isMobile ? "unset" : 180, textAlign: "start" }}>
          New Password
        </TypeFace>
        <TextInput
          style={{ width: isMobile ? "calc(100vw - 80px)" : 240 }}
          value={form.newPassword}
          onChange={(e) => setForm({ ...form, newPassword: e.target.value })}
          type="password"
          placeholder="Enter new password again"
        />
      </Flexbox>
      <TypeFace size={11} color="red">
        {error}
      </TypeFace>
      <Button onClick={handlePasswordChange} primary style={{ height: 45, borderRadius: 5, width: 160 }}>
        Update
      </Button>
      {loading && <Loading loading background="#0005" loaderColor="white" />}
    </Flexbox>
  );
}
