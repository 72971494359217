import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { firestore } from "src/firebase";
import { useAuth } from "../auth/AuthContext";
import FirestoreContext from "./FirestoreContext";

export const FirestoreProvider = ({ children }) => {
  const { user } = useAuth();

  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [myAgency, setMyAgency] = useState();
  const [agentsList, setAgentsList] = useState();
  const [savedPropertyIds, setSavedPropertyIds] = useState();

  const profileSnapshotRef = useRef();
  const agencySnapshotRef = useRef();
  const agentsSnapshotRef = useRef();
  const savedPropertiesSnapshotRef = useRef();

  useEffect(() => {
    if (user !== undefined && user !== null) {
      if (profileSnapshotRef.current) {
        profileSnapshotRef.current();
      }
      setIsProfileLoading(true);
      profileSnapshotRef.current = onSnapshot(doc(firestore, "profiles", user.uid), (doc) => {
        setIsProfileLoading(false);
        setUserProfile(doc.data());
        setIsAuthInitialized(true);
      });

      savedPropertiesSnapshotRef.current?.();

      savedPropertiesSnapshotRef.current = onSnapshot(
        collection(firestore, `profiles/${user.uid}/favorites`),
        (docs) => {
          let array = [];
          docs.forEach((doc) => {
            array.push(doc.data());
          });
          setSavedPropertyIds(array);
        }
      );

      return;
    } else if (user === null) {
      setIsAuthInitialized(true);
    }

    profileSnapshotRef.current?.();
    savedPropertiesSnapshotRef.current?.();
    setUserProfile(null);
    setSavedPropertyIds(null);
  }, [user]);

  useEffect(() => {
    if (userProfile && userProfile.agency) {
      if (agencySnapshotRef.current) {
        agencySnapshotRef.current?.();
      }
      agencySnapshotRef.current = onSnapshot(doc(firestore, "agencies", userProfile?.agency), (doc) => {
        console.log(doc.data());
        setMyAgency(doc.data());
      });
    } else {
      setMyAgency();
    }
  }, [userProfile?.agency]);

  useEffect(() => {
    if (myAgency) {
      if (agentsSnapshotRef.current) {
        agentsSnapshotRef.current();
      }
      agentsSnapshotRef.current = onSnapshot(
        query(collection(firestore, "profiles"), where("agency", "==", myAgency.id)),
        (querySnapshot) => {
          let agents = [];
          querySnapshot.docs.forEach((doc) => {
            const profileData = doc.data();
            agents.push(profileData);
          });
          setAgentsList(agents);
        }
      );
    }
  }, [myAgency]);

  useEffect(() => {
    return () => {
      profileSnapshotRef.current?.();
      agentsSnapshotRef.current?.();
      agencySnapshotRef.current?.();
    };
  }, []);

  return (
    <FirestoreContext.Provider
      value={{
        userProfile,
        myAgency,
        isProfileLoading,
        isAuthInitialized,
        savedPropertyIds,
        myAgencyAgentsList: agentsList,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};
