import moment from "moment"

export const socialTimeString = (timeStamp) => {
  const now = new Date()
  const time = new Date(timeStamp)
  if (moment(now).format("yyyy-MM-DD") === moment(time).format("yyyy-MM-DD")) {
    return moment(time).format("h:mm a")
  } else {
    return moment(time).format("h:mm a, MMM D")
  }
}