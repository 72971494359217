import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from 'src/components/typography/Typefaces';

const PrivacyApp = () => {
  const { isMobile } =  useMediaQuery()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                PRIVACY POLICY
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.subTitle}>
                Welcome to ListMeet!
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our databases and, if necessary, with whom we share it.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By using the website and services, you are accepting the practices described in this privacy policy. Use of the website and services is also subject to our <a href="/terms">terms and conditions</a>. In this privacy policy, the words &quot;website&quot; refers to the ListMeet website, &quot;we&quot;, &quot;us&quot;, &quot;our&quot; and “ListMeet” refers to ListMeet and &quot;you&quot; and “user&quot; refers to you, the List user.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This privacy policy may change from time to time. Your continued use of the website and services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. This privacy policy has been developed and is maintained in accordance with all applicable state and federal laws and regulations.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                1. GENERAL INFORMATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The personal data of the users that are collected and processed through the website:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; www.ListMeet.com
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Will be under responsibility and in charge of:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; ListMeet
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; support@ListMeet.com
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                2. TYPES OF INFORMATION GATHERED
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The information we collect from our users helps us to personalize and continually improve your website experience and provide our services. Here are the types of information we gather:
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Information You Give Us. <TypeFace>You provide information when you register as a user, use our services, post a real estate listing, make a transaction within the web site and/or communicate with us through our contact information. As a result of those actions, you might supply us with the following information:</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; First and last name
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Email address
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Phone number
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Address
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; User content
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Any additional information relating to you that you provide to us directly or indirectly through our website and services.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet will not collect any personally identifiable information about you unless you provide it.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Information Collected Automatically: <TypeFace>By accessing and using the website you automatically provide us with the following information:</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Your IP address
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Referring URLs
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you access the website through a mobile phone, we will collect the following information:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Mobile device ID
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; IP address
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Payment Information: <TypeFace>Your payment data will be processed by the payment processors available on this website (Stripe), which will process and store your data securely and for the sole purpose of processing transactions within the website. ListMeet reserves the right to contract any payment platform available on the market.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                View Stripe&#39;s privacy policy here:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; <a href="https://stripe.com/privacy" target='_blank' rel="noreferrer">https://stripe.com/privacy</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Contact information: <TypeFace>We may access some personal information about the user, such as name and email address, when the user or any third party communicates with us through our contact information. Personal information provided through our contact information is not stored on any List server and will be stored on the respective server of our email service.</TypeFace>
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                3. HOW LONG WE KEEP YOUR DATA
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Personal data provided by users through the website and services will be retained for as long as necessary to provide our services, publish listings, facilitate transactions between users or until the user closes the user account or requests deletion of the data. ListMeet may be allowed to retain personal data for a longer period whenever the user has given consent to such processing, as long as such consent is not withdrawn. Furthermore, ListMeet may be obliged to retain personal data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once the retention period expires, personal data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                4. HOW WE USE YOUR INFORMATION.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In general, we use the information we collect primarily to provide, maintain, protect, and improve our current website and services. We use personal information collected through our website as described below:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; User registration.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Provide our services.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Publish real estate listings.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Facilitate transactions within the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Process transactions within the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Facilitate communication between users.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Understand and enhance your experience using our website and services.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Respond to your comments or questions through our contact information.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Send you communications relating to ListMeet and our services.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Marketing purposes of ListMeet.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                5. HOW WE OBTAIN YOUR CONSENT
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By registering as a user, using our services and website, posting real estate listings on the website, conducting transactions within the website, contacting us through our contact details and providing us with personal information so that we can contact you, you consent to our collection, storage, and use of your information on the terms contained in this privacy policy. You may withdraw your consent by sending us your request via the contact information or the contact page.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                6. HOW WE SHARE YOUR INFORMATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The personal information of our customers and users is an important and fundamental part of our business. Under no circumstances will we sell or share information with third parties that has not been previously authorized by the user, customer, or owner of the personal data. We share user and customer information only and exclusively as described below.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Third-Party Service Providers. <TypeFace>Providers. We use third-party services to perform certain functions on our website. For example: creating and hosting the website, processing payments (Stripe), sending emails, analyzing data, providing marketing services, and providing search results.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                These third-party services and tools may have access to personal information needed to perform their functions but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy. Please refer to the privacy policy of these third-party services.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Marketing: <TypeFace>Providers. By using the website and engaging our services you consent to the use of your personal information, including name and email address for the sole purpose of sending you marketing communications about our services. ListMeet respects your privacy and does not share this information with unaffiliated third parties. If you wish to stop receiving marketing communications, you may unsubscribe at any time by using the &quot;unsubscribe&quot; option available in the same marketing emails or by submitting your request through our contact information.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Business Transfers. <TypeFace>In the event that ListMeet creates, merges with, or is acquired by another entity or company, your information will likely be transferred. ListMeet will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy and the transfer and handling of your private information will comply with all applicable requirements.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Protection of ListMeet and others. <TypeFace>We release personal information when we believe release is appropriate to comply with the law, enforce or apply our <a href="/terms">terms and conditions</a> and other agreements, or protect the rights, property, or safety of ListMeet, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                With Your Consent. <TypeFace>Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Anonymous Information. <TypeFace>ListMeet uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the Website. We may also use aggregated anonymous information to provide information about the Website to potential business partners and other unaffiliated entities. This information is not personally identifiable.</TypeFace>
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                7. PROTECTING YOUR INFORMATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information and to those persons you have authorized to have access to that information. ListMeet follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once ListMeet receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while ListMeet strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                8. RIGHTS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users who provide information through our website, as data subjects and data owners, have the right to access, rectify, download, or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply generally, others apply only in certain limited circumstances. We describe these rights below:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Access and portability: <TypeFace>to access and know what information is stored in our servers, you can send us your request through our contact information.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Rectify, Restrict, Limit and/or Delete: <TypeFace>You can also rectify, restrict, limit, or delete much of your information.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Right to be informed: <TypeFace>Users of our website will be informed, upon request, about what data we collect, how it is used, how long it is retained and whether it is shared with third parties.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Object: <TypeFace>When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Revoke consent: <TypeFace>Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Complaint: <TypeFace>If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Rights related to automated decision-making, including profiling: <TypeFace>website users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users or holders of personal data provided through the website and our services may exercise these rights over their personal data at any time and without limitation by sending their request through our contact information or contact form. The request to exercise their rights will be attended and answered within a maximum period of 3 working days.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                9. DATA BREACH NOTIFICATIONS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In the event of a security breach that compromises the confidentiality of our users&#39; personal data, ListMeet undertakes to notify those affected in a timely manner. This notification will be made through the means of contact that have been provided by the user on our platform. We will take all reasonable measures to protect the information and remedy any situation that jeopardizes the security of your data.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                10. INTERNATIONAL DATA TRANSFER
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By using our services, you agree that your personal data may be transferred and processed outside the United States, where data protection laws may differ from those in your country. ListMeet is committed to taking the necessary steps to ensure that your data is treated in accordance with applicable privacy protection standards and is adequately protected during any international transfer.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                11. CHILDREN’S ONLINE PRIVACY PROTECTION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                We comply with national and international regulations on the protection of children&#39;s personal data. The services and the website are intended for persons over 18 years of age. We do not collect information from children under the age of 18. If we learn that a child under the age of 18 has provided us with personal information, we will take immediate steps to delete that information.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                12. THIRD PARTIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Except as otherwise expressly included in this privacy policy, this document addresses only the use and disclosure of information ListMeet collects from you. If you disclose your information to others, whether other users or suppliers on ListMeet, different rules may apply to their use or disclosure of the information you disclose to them. ListMeet does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. ListMeet is not responsible for the privacy or security practices of other websites on the Internet, even those linked to or from the Listmeet website. Please review the privacy policies of third-party websites or services that you access through the ListMeet website.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                13. CONTACT US
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you have questions or concerns about this privacy policy and the handling and security of your data, please contact us through our contact forms or by using the contact information below:
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                ListMeet.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                <a href="mailto:support@ListMeet.com">support@ListMeet.com</a>
              </TypeFace>
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default PrivacyApp

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "16px 0",
    textAlign: "left",
    lineHeight: 1.6
  },
  inline: {
    paddingLeft: 16,
  }
}