import React, { useEffect, useMemo, useState, useRef } from "react";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import ReactSelect from "react-select";
import SubmitAgencyInformationModal from "src/components/account/SubmitAgencyInformationModal";
import { firestore } from "src/firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  where,
  orderBy,
} from "firebase/firestore";
import { Colors } from "src/theme/Colors";
import TextInput from "src/components/input/TextInput";
import Button from "src/components/button/Button";
import Loading from "react-fullscreen-loading";
import { useAlert } from "src/hooks/alert/AlertContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { UserRoleValue } from "src/model/types";
import { titleCase } from "src/utils/strings";
import { query } from "firebase/firestore";

const AccountAgency = () => {
  const agencySnapshotRef = useRef();
  const [myAgency1, setMyAgency1] = useState();

  const { userProfile, myAgency } = useFirestore();
  const { showAlert } = useAlert();
  const { isMobile } = useMediaQuery();

  const [existingAgencies, setExistingAgencies] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [createAgencyModalOpened, setCreateAgencyModalOpened] = useState(false);

  const [licenseName, setLicenseName] = useState(userProfile?.name);
  const [licenseNumber, setLicenseNumber] = useState(userProfile?.licenseNumber);

  const [brokerageName, setBrokerageName] = useState("");
  const [brokerageNumber, setBrokerageNumber] = useState("");

  const [processing, setProcessing] = useState(false);

  const [agencyInput, setAgencyInput] = useState("");

  const handleSubmitAgencySelect = () => {
    if (selectedAgency && userProfile) {
      updateDoc(doc(firestore, "profiles", userProfile.userId), {
        agency: selectedAgency,
        licenseName,
        licenseNumber,
        agencyApproved: false,
        agencyDeclined: false,
      });

      setDoc(doc(firestore, `profiles/${userProfile.userId}/agencyHistory/${selectedAgency}`), {
        agency: selectedAgency,
        licenseNumber,
        submitted: new Date().getTime(),
      });
    }
  };

  const handleSubmitAgency = () => {
    setProcessing(true);
    getDoc(doc(firestore, `agencies/${brokerageNumber}`)).then((docSnap) => {
      if (docSnap.exists()) {
        setProcessing(false);
        showAlert(true, "Agency already registered with the same number.");
      } else {
        setDoc(doc(firestore, `agencies/${brokerageNumber}`), {
          id: brokerageNumber,
          name: titleCase(brokerageName),
          creator: userProfile.userId,
          status: "submitted",
          brokerName: titleCase(userProfile.name),
          brokerEmail: userProfile.email,
          createdAt: new Date().getTime(),
        })
          .then(() => {
            setProcessing(false);
            updateDoc(doc(firestore, "profiles", userProfile.userId), {
              agency: brokerageNumber,
            });
          })
          .catch((err) => {
            setProcessing(false);
            console.log("agency submit error => ", err);
          });
      }
    });
  };

  const handleUpdateAgency = () => {
    setProcessing(true);
    if (myAgency.id === brokerageNumber) {
      updateDoc(doc(firestore, `agencies/${brokerageNumber}`), {
        name: titleCase(brokerageName),
        brokerName: titleCase(userProfile.name),
        brokerEmail: userProfile.email,
      })
        .then(() => {
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          console.log("agency submit error => ", err);
        });
    } else if (myAgency.id !== brokerageNumber) {
      getDoc(doc(firestore, `agencies/${brokerageNumber}`)).then((docSnap) => {
        if (docSnap.exists()) {
          setProcessing(false);
          showAlert(true, "Agency already registered with the same number.");
        } else {
          deleteDoc(doc(firestore, `agencies/${myAgency.id}`));
          setDoc(doc(firestore, `agencies/${brokerageNumber}`), {
            id: brokerageNumber,
            name: titleCase(brokerageName),
            creator: userProfile.userId,
            status: "submitted",
            brokerName: titleCase(userProfile.name),
            brokerEmail: userProfile.email,
            createdAt: new Date().getTime(),
          })
            .then(() => {
              setProcessing(false);
              updateDoc(doc(firestore, "profiles", userProfile.userId), {
                agency: brokerageNumber,
                agencyApproved: false,
              });
            })
            .catch((err) => {
              setProcessing(false);
              console.log("agency submit error => ", err);
            });
        }
      });
    }
  };

  const agencyOptions = useMemo(() => {
    if (existingAgencies) {
      return existingAgencies.map((agency) => ({
        label: `${agency.name} - ${agency.id}`,
        value: agency.id,
      }));
    } else {
      return [];
    }
  }, [existingAgencies]);

  useEffect(() => {
    if (myAgency !== undefined) {
      console.log("myAgency");
      setBrokerageName(myAgency?.name);
      setBrokerageNumber(myAgency?.id);
    }
    const unsub = onSnapshot(collection(firestore, `agencies`), (docs) => {
      let data = [];
      docs.forEach((doc) => {
        if (doc.data().status == "approved") {
          data.push(doc.data());
        }
      });

      data = data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
      });

      setExistingAgencies(data);
    });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (myAgency === undefined) {
      const userProfileID = localStorage.getItem("userProfile-Id");

      if (userProfileID !== undefined) {
        console.log("userProfile?.agency-COMP=", userProfileID);
        const agencyRequestsSnapshotListener = onSnapshot(
          query(collection(firestore, "agencies"), where("id", "==", userProfileID)),
          (querySnapshot) => {
            const d = [];
            querySnapshot.forEach((doc) => {
              d.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.log("-doc=", d);

            if (d.length > 0) {
              console.log("docd", d[0].name);
              setBrokerageName(d[0].name);
              setBrokerageNumber(d[0].id);
            }
          }
        );
        return () => {
          agencyRequestsSnapshotListener();
        };
      }
    }
  }, []);

  const renderMsgUI = () => {
    if (myAgency?.status === "blocked") {
      if (userProfile?.role === UserRoleValue.Broker) {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            Your license has been blocked. Contact Listmeet support (support@listmeet.com) to reinstate your profile.
          </TypeFace>
        );
      } else {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            Your license has been blocked. Contact Listmeet support (support@listmeet.com) to reinstate your profile.
          </TypeFace>
        );
      }
    } else if (userProfile.agencyDeclined && userProfile.agencyApproved === false) {
      return (
        <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
          Your license has been blocked. Contact Listmeet support (support@listmeet.com) to reinstate your profile.
        </TypeFace>
      );
    } else if (myAgency?.status === "submitted") {
      if (userProfile?.role === UserRoleValue.Broker) {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            {userProfile?.agency === undefined || userProfile?.agency === ""
              ? "You've released from agency"
              : "Your brokerage has been submitted. Waiting for approval from ListMeet"}
          </TypeFace>
        );
      } else {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            {userProfile?.agency === undefined || userProfile?.agency === ""
              ? "You've released from agency"
              : "Your brokerage has been submitted. Waiting for approval from ListMeet"}
          </TypeFace>
        );
      }
    } else if (userProfile.agencyApproved === false) {
      if (userProfile?.role === UserRoleValue.Broker) {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            {userProfile?.agency === undefined || userProfile?.agency === ""
              ? "You've released from agency"
              : "Waiting on your brokerage to approve your license."}
          </TypeFace>
        );
      } else {
        return (
          <TypeFace medium size={12} style={{ textAlign: "start", marginTop: 15 }}>
            {userProfile?.agency === undefined || userProfile?.agency === ""
              ? "You've released from agency"
              : "Waiting on your brokerage to approve your license."}
          </TypeFace>
        );
      }
    }
  };

  return (
    <Flexbox style={{ width: "100%", gap: 10, alignItems: "flex-start" }}>
      {((userProfile?.agencyDeclined && !userProfile?.agency) || userProfile?.agency === "") && (
        <TypeFace medium size={12} color="red" style={{ textAlign: "start", marginTop: 0 }}>
          Your brokerage has declined your license, please try again.
        </TypeFace>
      )}
      <TypeFace semiBold size={14} color="#5f5f5f" style={{ marginTop: 10 }}>
        {userProfile?.role === UserRoleValue.Broker ? "My Brokerage" : "My Agency"}
      </TypeFace>
      {!userProfile?.agency || userProfile.agency === "" ? (
        <>
          <TypeFace medium size={14} color="#5f5f5f" style={{ textAlign: "start" }}>
            {userProfile?.role === "broker" ? "Please set up your agency." : "Select up your agency"}
          </TypeFace>
          {userProfile?.role === "broker" ? (
            <Flexbox style={{ marginTop: 30, gap: 20, alignItems: "flex-start" }}>
              <Flexbox
                row
                style={{
                  gap: isMobile ? 10 : 20,
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <TypeFace bold size={11} style={{ width: isMobile ? "unset" : 120, textAlign: "start" }}>
                  Agency ID / Brokerage Number
                </TypeFace>
                <TextInput
                  style={{ width: isMobile ? "calc(100vw - 80px)" : 240 }}
                  placeholder="#"
                  value={brokerageNumber}
                  onChange={(e) => setBrokerageNumber(e.target.value)}
                  onKeyDown={(event) => {
                    if (
                      event.key !== "Tab" &&
                      event.key !== "Backspace" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight" &&
                      event.key !== "Delete" &&
                      !/[0-9]/.test(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Flexbox>
              <Flexbox
                row
                style={{
                  gap: isMobile ? 10 : 20,
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <TypeFace bold size={11} style={{ width: isMobile ? "unset" : 120, textAlign: "start" }}>
                  Agency Name
                </TypeFace>
                <TextInput
                  style={{ width: isMobile ? "calc(100vw - 80px)" : 240 }}
                  value={brokerageName}
                  onChange={(e) => setBrokerageName(e.target.value)}
                />
              </Flexbox>
              <Button
                onClick={handleSubmitAgency}
                disabled={brokerageName === "" || brokerageNumber === ""}
                primary
                style={{ height: 45, borderRadius: 5, width: 160 }}
              >
                Submit
              </Button>
            </Flexbox>
          ) : (
            <Flexbox
              style={{
                marginTop: 30,
                gap: isMobile ? 10 : 20,
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
              }}
            >
              <TypeFace medium>Brokerage</TypeFace>
              <div style={{ width: isMobile ? 320 : 360 }}>
                <ReactSelect
                  //options={agencyOptions}
                  options={agencyInput == "" ? [] : agencyOptions}
                  placeholder={"Enter agency name or license number"}
                  onInputChange={(v) => {
                    console.log("text-input=", v);
                    setAgencyInput(v);
                  }}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  onChange={(v) => {
                    setSelectedAgency(v.value);
                    setLicenseName(userProfile?.name);
                  }}
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      textAlign: "start",
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      textAlign: "start",
                    }),
                    option: (base) => ({
                      ...base,
                      textAlign: "start",
                    }),
                  }}
                />
              </div>
            </Flexbox>
          )}
          {userProfile?.role !== "broker" && (
            <TypeFace
              size={12}
              style={{
                textAlign: isMobile ? "start" : "center",
              }}
            >
              If your agency not listed, please contact the administrator to set up agency first
            </TypeFace>
          )}
          {selectedAgency && (
            <>
              <Flexbox
                style={{
                  gap: isMobile ? 10 : 20,
                  marginTop: 15,
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <TypeFace medium size={14} style={{ width: isMobile ? "unset" : 140 }}>
                  License name
                </TypeFace>
                <TextInput
                  value={licenseName}
                  onChange={(e) => setLicenseName(e.target.value)}
                  style={{
                    width: isMobile ? 300 : 200,
                  }}
                />
              </Flexbox>
              <Flexbox
                style={{
                  gap: isMobile ? 10 : 20,
                  marginTop: isMobile ? 16 : 5,
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <TypeFace medium size={14} style={{ width: isMobile ? "unset" : 140 }}>
                  License number
                </TypeFace>
                <TextInput
                  value={licenseNumber}
                  onKeyDown={(event) => {
                    if (
                      event.key !== "Tab" &&
                      event.key !== "Backspace" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight" &&
                      event.key !== "Delete" &&
                      !/[0-9]/.test(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setLicenseNumber(e.target.value)}
                  style={{
                    width: isMobile ? 300 : 200,
                  }}
                />
              </Flexbox>
              <Button
                primary
                disabled={licenseName === "" || licenseNumber === ""}
                onClick={handleSubmitAgencySelect}
                color={Colors.primary}
                semiBold
                size={16}
                style={{ cursor: "pointer", marginTop: 20, height: 45, width: 250, borderRadius: 5 }}
              >
                Submit
              </Button>
            </>
          )}
        </>
      ) : userProfile.agency !== "" &&
        userProfile.agencyApproved === true &&
        userProfile?.role === UserRoleValue.Broker ? (
        <>
          <Flexbox style={{ marginTop: 30, gap: 20, alignItems: "flex-start" }}>
            <Flexbox
              row
              style={{
                gap: isMobile ? 10 : 20,
                flexDirection: isMobile ? "column" : "column",
                alignItems: isMobile ? "flex-start" : "center",
              }}
            >
              {/* <TypeFace bold size={11}  style={{ width: isMobile ? 'unset' : 120, textAlign: 'start' }}> */}
              <TypeFace bold size={11} style={{ textAlign: "start" }}>
                Brokerage Name -{" "}
                <TypeFace semiBold size={12} color="black">
                  {brokerageName}
                </TypeFace>
              </TypeFace>
              {/*  <TextInput
                  style={{ width: isMobile ? 'calc(100vw - 80px)' : 240 }}
                  value={brokerageName}
                  onChange={e => setBrokerageName(e.target.value)}
                /> */}
            </Flexbox>
            <Flexbox
              row
              style={{
                gap: isMobile ? 10 : 20,
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
              }}
            >
              {/*  <TypeFace bold size={11} style={{ width: isMobile ? 'unset' : 120, textAlign: 'start' }}> */}
              <TypeFace bold size={11} style={{ textAlign: "start" }}>
                Agency ID / Brokerage Number -{" "}
                <TypeFace semiBold size={12} color="black">
                  {brokerageNumber}
                </TypeFace>
              </TypeFace>
              {/* <TextInput
                  style={{ width: isMobile ? 'calc(100vw - 80px)' : 240 }}
                  placeholder='#'
                  value={brokerageNumber}
                  onChange={e => setBrokerageNumber(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                /> */}
            </Flexbox>
            {/*  <Button onClick={handleUpdateAgency}  primary style={{ height: 45, borderRadius: 5, width: 160}}>
                Update
              </Button> */}
          </Flexbox>
        </>
      ) : (
        <>
          <TypeFace bold size={14} style={{ textAlign: "start", marginTop: 15 }}>
            {myAgency?.name}
          </TypeFace>
          <TypeFace semiBold size={12} color={Colors.gray1} style={{ textAlign: "start", marginTop: 0 }}>
            Brokerage number -{" "}
            <TypeFace semiBold size={12} color="black">
              {myAgency?.id}
            </TypeFace>
          </TypeFace>
          {renderMsgUI()}
          {/* {(myAgency?.status === 'blocked' || userProfile.agencyDeclined) ? (
            <TypeFace medium size={12} style={{ textAlign: 'start', marginTop: 15 }}>
              Agency declined your license
            </TypeFace>
          ) : (
            <>
              {(myAgency?.status === 'submitted' || userProfile.agencyApproved === false) && (
                <TypeFace medium size={12} style={{ textAlign: 'start', marginTop: 15 }}>
                  {(userProfile?.agency === undefined || userProfile?.agency === "") ? "You've released from agency" : 'Your brokerage has been submitted. Waiting for approval from ListMeet'}
                </TypeFace>
              )}
            </>  
          )} */}
        </>
      )}
      <SubmitAgencyInformationModal
        modalIsOpen={createAgencyModalOpened}
        closeModal={() => setCreateAgencyModalOpened(false)}
      />
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Flexbox>
  );
};

export default AccountAgency;

const styles = {
  licenseNumberInput: {
    width: 240,
  },
};
