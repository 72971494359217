import React from 'react'
import { Colors } from 'src/theme/Colors'
import TypeFace from '../typography/Typefaces'
import useMediaQuery from 'src/hooks/useMediaQuery'

const Button = (props) => {

  const { isMobile } = useMediaQuery()

  const containerStyle = props.primary ? styles.primaryContainer : props.secondary ? styles.secondaryContainer : props.third ? styles.thirdContainer : styles.noOutlinedContainer
  const text = props.children
  const disabledStyle = props.disabled ? (props.primary ? {backgroundColor: '#afafaf'} : {opacity: 0.5}) : {}

  return (
    <button
      style={{
        ...styles.commonContainer,
        gap: isMobile ? 5 : 10,
        ...containerStyle,
        ...disabledStyle,
        ...props.style
      }}
      disabled={props.disabled ?? false}
      onClick={props.onClick}
    >
      {props.icon}
      {!!text && (
        <TypeFace
          semiBold
          color={props.textColor ? props.textColor : props.primary ? 'white' : props.secondary ? Colors.primary : 'black'}
          style={{ cursor: 'pointer' }}
          size={props.textSize ? props.textSize : props.secondary ? 16 : 18}
        >
          {text}
        </TypeFace>
      )}
    </button>
  )
}

export default Button

const styles = {
  commonContainer: {
    cursor: 'pointer',
    borderRadius: 0,
    display: 'flex',
    padding: '3px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  primaryContainer: {
    backgroundColor: Colors.primary,
    border: 'none',
    boxShadow: 'none'
  },
  secondaryContainer: {
    borderRadius: 4,
    backgroundColor: 'white',
    border: '2px solid #3151B7',
  },
  thirdContainer: {
    borderRadius: 0,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '1px 1px 3px #0002',
  },
  noOutlinedContainer: {
    backgroundColor: 'white',
    border: 'none',
  }
}