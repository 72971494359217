import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { Colors } from 'src/theme/Colors'
import { scheduleFromToString } from 'src/utils/strings'
import IconCalendar from "src/assets/images/icons/icon-calendar.svg"
import { useNavigate, useParams } from 'react-router'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from 'src/firebase'


const ListingDetailsCreatorView = ({
  openHouseSchedules,
  handleEditListing
}) => {

  const { id } = useParams()
  const { userProfile } = useFirestore()
  const navigate = useNavigate()

  const [laterSchedules, setLaterSchedules] = useState()
  
  const loadSchedules = () => {
    const q = query(collection(firestore, 'schedules'), where("listingAgentId", "==", userProfile.userId), where("listingId", "==", id))
    getDocs(q).then(docs => {
      let schedulesList = []
      docs.forEach(doc => {
        schedulesList.push({
          ...doc.data(),
          id: doc.id
        })
      })
    
      setLaterSchedules(schedulesList.filter(schedule => schedule.customer.when > new Date().getTime()))
      
    })
  }

  useEffect(() => {
    if (id && userProfile?.userId) {
      loadSchedules()
    }
  }, [id, userProfile])

  return (
    <Flexbox style={styles.schedules}>
      <TypeFace semiBold size={11} color={Colors.primary}>
        Open House Schedules
      </TypeFace>
      {openHouseSchedules?.map((openHouseSchedule, index) => (
        <Flexbox row style={{...styles.openHouseRow, marginTop: index === 0 ? 20 : 10}} key={index}>
          <img src={IconCalendar} width={16} height={16} />
          <Flexbox style={{ alignItems: 'flex-start' }}>
            <TypeFace medium size={11}>
              {moment(new Date(openHouseSchedule.date)).format('ddd, MMM D')}
            </TypeFace>
            <TypeFace size={11}>
              {scheduleFromToString(openHouseSchedule)}
            </TypeFace>
          </Flexbox>
        </Flexbox>
      ))}
      {(laterSchedules && laterSchedules.length > 0) && (
        <>
          <TypeFace semiBold size={11} color={Colors.primary} style={{ marginTop: 16 }}>
            Meeting schedules
          </TypeFace>
          {laterSchedules.map((schedule, index) => (
            <Flexbox row style={{...styles.openHouseRow, marginTop: 5}} key={`schedule-${index}`}>
              <img src={IconCalendar} width={16} height={16} />
              <Flexbox style={{ alignItems: 'flex-start' }}>
                <TypeFace bold size={11}>
                  {schedule.customer.name}
                </TypeFace>
                <TypeFace medium size={11}>
                  {moment(new Date(schedule.customer.when)).format('h:mm a, M/D/YYYY')}
                </TypeFace>
              </Flexbox>
            </Flexbox>
          ))}
          <Button onClick={() => navigate('/manage/schedules')} secondary style={{...styles.editListingButton, marginTop: 10}}>
            Open my schedules
          </Button>
        </>
      )}
      <Button primary style={styles.editListingButton} onClick={handleEditListing}>
        Edit Listing
      </Button>
    </Flexbox>
  )
}

export default ListingDetailsCreatorView

const styles = {
  schedules: {
    width: 'calc(100% - 30px)',
    boxShadow: '0 0 3px #3151B73F',
    padding: 15,
    borderRadius: 5,
    alignItems: 'flex-start'
  },
  openHouseRow: {
    width: 'calc(100% - 20px)',
    height: 40,
    borderRadius: 0,
    border: '1px solid #ccc',
    paddingLeft: 10,
    paddingRight: 10,
    gap: 10,
  },
  editListingButton: {
    width: '100%',
    height: 44,
    marginTop: 20,
  },
}