import React, { useState, useMemo } from "react";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import TextInput from "src/components/input/TextInput";
import Button from "src/components/button/Button";
import { useAlert } from "src/hooks/alert/AlertContext";
import { validateEmailAddress } from "src/utils/strings";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "src/firebase";
import useMediaQuery from "src/hooks/useMediaQuery";
import { Colors } from "src/theme/Colors";

const AccountCompanyPointOfContact = () => {
  const { userProfile } = useFirestore();
  const { showAlert } = useAlert();
  const { isMobile } = useMediaQuery();

  const [name, setName] = useState(userProfile?.contact?.name ?? "");
  const [email, setEmail] = useState(userProfile?.contact?.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.contact?.number ?? "");

  const updateContactEnabled = useMemo(() => {
    return (
      (userProfile?.contact?.name ?? "") !== name ||
      (userProfile?.contact?.number ?? "") !== phoneNumber ||
      (userProfile?.contact?.email ?? "") !== email
    );
  }, [userProfile, name, phoneNumber, email]);

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim()?.replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
  };

  const onPhoneNumberChange = (e) => {
    const contactPhone = e.target.value;
    if (contactPhone.length === 0) {
      setPhoneNumber("");
    }
    if (contactPhone.includes("@") === false) {
      if (/^\d+$/.test(contactPhone)) {
        if (contactPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(contactPhone);
          setPhoneNumber(targetValue);
        } else if (phoneNumber.length > 10) {
          setPhoneNumber(phoneNumber);
        } else {
          setPhoneNumber(contactPhone);
        }
      } else {
        if (phoneNumber.includes("(") && contactPhone.length > 14) {
          setPhoneNumber(contactPhone.slice(0, 14));
        } else {
          setPhoneNumber(contactPhone);
        }
      }
    } else {
      setPhoneNumber(contactPhone);
    }
  };

  const handleSubmitPointOfContact = () => {
    if (!validateEmailAddress(email)) {
      showAlert(true, "Please enter a valid email address");
      return;
    }

    updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
      contact: {
        name,
        email,
        number: phoneNumber,
      },
    });
  };
  const renderReadOnlyScreenUI = () => {
    return (
      <>
        <TypeFace semiBold size={12} color={Colors.gray1} style={{ textAlign: "start", marginTop: 10 }}>
          Contact Name -{" "}
          <TypeFace semiBold size={12} color="black">
            {name}
          </TypeFace>
        </TypeFace>
        <TypeFace semiBold size={12} color={Colors.gray1} style={{ textAlign: "start", marginTop: 10 }}>
          Contact Email -{" "}
          <TypeFace semiBold size={12} color="black">
            {email}
          </TypeFace>
        </TypeFace>
        <TypeFace semiBold size={12} color={Colors.gray1} style={{ textAlign: "start", marginTop: 10 }}>
          Contact Number -{" "}
          <TypeFace semiBold size={12} color="black">
            {phoneNumber}
          </TypeFace>
        </TypeFace>
        <TypeFace style={{ marginTop: 20 }}>
          {userProfile?.profileStatus === "blocked"
            ? "Your company has been blocked, contact Listmeet support (support@listmeet.com) to reinstate your profile"
            : "Listmeet has not approved your company yet"}
        </TypeFace>
      </>
    );
  };
  const renderEditableScreenUI = () => {
    return (
      <Flexbox style={{ marginTop: 30, gap: 20, alignItems: "flex-start" }}>
        <Flexbox row style={{ alignItems: "center", gap: 20 }}>
          <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: "start" }}>
            Contact Name
          </TypeFace>
          <TextInput style={styles.textInput} value={name} onChange={(e) => setName(e.target.value)} />
        </Flexbox>
        <Flexbox row style={{ alignItems: "center", gap: 20 }}>
          <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: "start" }}>
            Contact Email
          </TypeFace>
          <TextInput style={styles.textInput} value={email} onChange={(e) => setEmail(e.target.value)} />
        </Flexbox>
        <Flexbox row style={{ alignItems: "center", gap: 20 }}>
          <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: "start" }}>
            Contact Number
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            //onChange={e => setPhoneNumber(e.target.value)}
            onKeyDown={(event) => {
              if (
                event.key !== "Tab" &&
                event.key !== "Backspace" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Delete" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
          />
        </Flexbox>
        {userProfile?.contact === undefined ? (
          <Button
            onClick={handleSubmitPointOfContact}
            disabled={name === "" || phoneNumber === "" || email === ""}
            primary
            style={styles.submitButton}
          >
            Submit
          </Button>
        ) : (
          <>
            {userProfile?.profileStatus === "approved" ? (
              <>
                <Button
                  onClick={handleSubmitPointOfContact}
                  disabled={!updateContactEnabled}
                  primary
                  style={styles.submitButton}
                >
                  Update
                </Button>
                {/*  <TypeFace>
              Approved management company profile
            </TypeFace> */}
              </>
            ) : (
              <TypeFace>
                {userProfile?.profileStatus === "blocked"
                  ? "Your company has been blocked, contact Listmeet support (support@listmeet.com) to reinstate your profile"
                  : "Listmeet has not approved your company yet"}
              </TypeFace>
            )}
          </>
        )}
      </Flexbox>
    );
  };
  return (
    <Flexbox style={{ width: "100%", gap: 10, alignItems: "flex-start" }}>
      <TypeFace semiBold size={14} color="#5f5f5f">
        Point of Contact
      </TypeFace>
      {userProfile?.profileStatus === "blocked" || userProfile?.profileStatus === undefined
        ? renderReadOnlyScreenUI()
        : renderEditableScreenUI()}
      {/* <Flexbox style={{ marginTop: 30, gap: 20, alignItems: 'flex-start' }}>
      <Flexbox row style={{ alignItems: 'center', gap: 20 }}>
        <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: 'start' }}>
          Contact Name
        </TypeFace>
        <TextInput
          style={styles.textInput}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </Flexbox>
      <Flexbox row style={{ alignItems: 'center', gap: 20 }}>
        <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: 'start' }}>
          Contact Email
        </TypeFace>
        <TextInput
          style={styles.textInput}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Flexbox>
      <Flexbox row style={{ alignItems: 'center', gap: 20 }}>
        <TypeFace bold size={11} style={{ width: isMobile ? 60 : 120, textAlign: 'start' }}>
          Contact Number
        </TypeFace>
        <TextInput
          style={styles.textInput}
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          onKeyDown={(event) => {
            if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
        />
      </Flexbox>
      {userProfile?.contact === undefined ? (
        <Button
          onClick={handleSubmitPointOfContact}
          disabled={name === "" || phoneNumber === "" || email===""}
          primary
          style={styles.submitButton}
        >
          Submit
        </Button>
      ) : (
        <>
          {userProfile?.profileStatus === "approved" ? (
            <TypeFace>
              Approved management company profile
            </TypeFace>
          ) : (
            <TypeFace>
              {userProfile?.profileStatus === 'blocked' ? "Your profile has been blocked, contact Listmeet support (support@listmeet.com) to reinstate your profile" : "Waiting for approval from Listmeet"}
            </TypeFace>
          )}
        </>
      )}
    </Flexbox> */}
    </Flexbox>
  );
};

export default AccountCompanyPointOfContact;

const styles = {
  textInput: {
    width: 240,
  },
  submitButton: { height: 45, borderRadius: 5, width: 240 },
};
