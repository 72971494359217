import { collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { updateAlogliaObject } from "src/algolia";
import { firestore } from "src/firebase";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Button, Flex, Input, Table, Tooltip, Typography } from "antd";
import styles from "../../admin.module.scss";
import { render } from "@testing-library/react";
import { BankOutlined, CheckCircleOutlined, CloseCircleOutlined, TeamOutlined } from "@ant-design/icons";
import { checkingListingErrors } from "src/utils/business";

const { Text, Link } = Typography;

const statusLabels = {
  submitted: "Waiting for approve",
  approved: "Active",
  blocked: "Blocked",
};

const AdminAgenciesList = () => {
  const unmountedRef = useRef(false);

  const navigate = useNavigate();

  const [agencies, setAgencies] = useState();
  const [agencyListings, setAgencyListings] = useState();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const { Search } = Input;

  const handleApprove = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: "approved",
    });

    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: true,
      agencyDeclined: false,
      profileBlocked: false,
      agencyReleased: false,
      releasedDate: "",
      activatedDate: new Date().getTime(),
    });

    const q = query(collection(firestore, `listing`), where("by", "==", brokerId));
    getDocs(q).then((docs) => {
      docs.forEach((docData) => {
        const listingData = docData.data();
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: false,
        });

        updateAlogliaObject(listingData.algoliaID, {
          status: listingData.status,
        });
      });
    });
  };

  const handleBlock = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: "blocked",
    });
    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: false,
      agencyDeclined: true,
      profileBlocked: true,
      activatedDate: "",
    });

    const q = query(collection(firestore, `listing`), where("by", "==", brokerId));
    getDocs(q).then((docs) => {
      docs.forEach((docData) => {
        const listingData = docData.data();
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: true,
        });

        updateAlogliaObject(listingData.algoliaID, {
          status: "Blocked",
        });
      });
    });
  };

  const handleViewAgency = (agencyId) => {
    navigate(`/admin/agencies/${agencyId}`);
  };

  const handleViewListing = (creatorId) => {
    navigate(`/admin/listings/${creatorId}`);
  };

  function getData(query = "") {
    if (query === "") return setAgencyListings(agencies);
    const res = agencies.filter((obj) => {
      if (obj?.name?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.brokerEmail?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.id.toLowerCase() === query?.toLowerCase()) return true;
      if (obj?.brokerName?.toLowerCase().includes(query?.toLowerCase())) return true;
      return false;
    });
    setAgencyListings(res);
  }

  useEffect(() => {
    setLoading(true);
    unmountedRef.current = false;
    const snapshotListener = onSnapshot(
      query(collection(firestore, "agencies"), orderBy("createdAt", "desc")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setAgencies(d);
          setAgencyListings(d);
          setLoading(false);
        }
      }
    );

    return () => {
      unmountedRef.current = true;
      snapshotListener();
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <Tooltip title={statusLabels[record.status]} placement="left">
            <Flex gap={5} style={{ cursor: "pointer" }}>
              <Text>{record.name}</Text>
              {statusLabels[record.status] === "Active" ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <CloseCircleOutlined style={{ color: "red" }} />
              )}
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      title: "License number",
      dataIndex: "id",
      key: "licenseNumber",
    },
    {
      title: "Broker contact",
      key: "brokerName",
      render: (_, record) => {
        return (
          <Flex vertical gap={5}>
            <Text>{record.brokerName}</Text>
            <Link href={`mailto:${record.brokerEmail}`}>{record.brokerEmail}</Link>
          </Flex>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => moment(record.createdAt).format("MMM DD, YYYY"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "brokerName",
      render: (_, agency) => {
        if (!agency.status || agency.status === "" || agency.status === "submitted") {
          return (
            <Flex row style={{ gap: 10 }}>
              <Button onClick={() => handleApprove(agency.id, agency.creator)}>Approve</Button>
              <Button danger onClick={() => handleBlock(agency.id, agency.creator)}>
                Decline
              </Button>
            </Flex>
          );
        }
        if (agency.status === "approved") {
          return (
            <Flex row style={{ gap: 10 }}>
              <Button danger onClick={() => handleBlock(agency.id, agency.creator)}>
                Block
              </Button>
              <Tooltip title="View Agents">
                <Button size="default" onClick={() => handleViewAgency(agency.id)}>
                  <TeamOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="View Listings">
                <Button size="default" onClick={() => handleViewListing(agency.creator)}>
                  <BankOutlined />
                </Button>
              </Tooltip>
            </Flex>
          );
        }
        if (agency.status === "blocked") {
          return (
            <Flex row style={{ gap: 10 }}>
              <Button size="default" onClick={() => handleApprove(agency.id, agency.creator)}>
                Approve
              </Button>
              <Tooltip title="View Agents">
                <Button size="default" onClick={() => handleViewAgency(agency.id)}>
                  <TeamOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="View Listings">
                <Button size="default" onClick={() => handleViewListing(agency.creator)}>
                  <BankOutlined />
                </Button>
              </Tooltip>
            </Flex>
          );
        }
        return null;
      },
    },
  ];

  const headerRight = (
    <div>
      <Search
        placeholder="Search for agency name, email, license number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={(value) => {
          getData(value);
        }}
        style={{
          width: 300,
        }}
        onReset={() => {
          setSearch("");
          setAgencyListings(agencies);
        }}
        allowClear
        size="large"
      />
    </div>
  );

  return (
    <AdminLayout title="Agencies" headerRight={headerRight}>
      <Table
        dataSource={agencyListings}
        columns={columns}
        style={{ width: "100%" }}
        loading={loading}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
        key={(agency) => agency.id}
      />
    </AdminLayout>
  );
};

export default AdminAgenciesList;
