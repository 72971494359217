import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from 'src/components/typography/Typefaces';

const TermsApp = () => {
  const { isMobile } =  useMediaQuery()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                TERMS AND CONDITIONS
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.subTitle}>
                Welcome to ListMeet!
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                These are the terms and conditions for:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; (www.ListMeet.com)
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By using the website and services, you agree to be bound by these terms and conditions and our <a href="/appprivacy">privacy policy</a>. In these terms and conditions, the words &quot;website&quot; refers to the ListMeet website, &quot;we&quot;, &quot;us&quot;, &quot;our&quot; and “ListMeet” refers to ListMeet and &quot;you&quot; “customer” and “user&quot; refers to you, the LisMeet user or customer.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The following terms and conditions apply to the website and services offered by ListMeet. This includes any version of ListMeet accessible via desktop, mobile, tablet, social media, or other devices.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OR OBTAINING ANY INFORMATION OR SERVICES FROM THE WEBSITE.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                1. ACCEPTANCE OF TERMS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This agreement sets forth legally binding terms for your use of ListMeet. By using the website and services, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use the website and services. We may modify this agreement from time to time, and such modification shall be effective upon posting on the website. You agree to be bound by any modifications to these terms and conditions when you use the website and the services offered on the website after such modification is posted on the website; therefore, it is important that you review this agreement regularly.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The website and services are not directed to persons under the age of 18. ListMeet does not knowingly collect information from persons under the age of 18. It is the responsibility of parents and legal guardians to determine whether use of the website or any of the content and functionality available on the website is appropriate for their child or ward.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet may, in its sole discretion, refuse to offer the services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the website is revoked in such jurisdictions.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By using the website and services, you represent and warrant that you have the full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into this agreement.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                2. NOTIFICATIONS AND NEWSLETTER
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By providing ListMeet with your e-mail address, you agree that we may use your e-mail address to send you important notifications and communications about our services, news, and special content. If you do not wish to receive these e-mails, you may opt-out of receiving them by sending us your request through our contact information or by using the &quot;unsubscribe&quot; option in the e-mails themselves. This option may prevent you from receiving emails about our services, important news and special content.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                3. ACCOUNT
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In order to access the services and functionalities of the website, users must register and create an account. The registration process requires the user to provide truthful and updated information, including, but not limited to, name, email address, and, depending on the type of user, additional details specific to the user&#39;s role in the real estate industry.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to immediately notify ListMeet of any unauthorized use of your password or account or any other breach of security, and to ensure that you log out of your account at the end of each session. You may never use another user&#39;s account without ListMeet&#39;s prior authorization.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Once registered, users can be classified into the following categories, each with different levels of access and functionality:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Renter/Buyer: <TypeFace>Users who are looking for properties to rent or buy. These users can access listings, schedule viewings and communicate with agents.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Broker: <TypeFace>Real estate professionals who represent owners in the sale or rental of their properties. Agents can post listings, respond to inquiries and manage transactions.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Associate Broker: <TypeFace>Individuals or entities that collaborate with real estate agents to facilitate transactions, such as photographers, appraisers, etc. They have limited access to the site to provide their specific services.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Licensed Real Estate Agent: <TypeFace>Agents licensed and authorized to operate in the real estate market. They have full access to all functions of the site, including posting listings and handling transactions.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Management Companies: <TypeFace>Entities that manage properties on behalf of owners. They can manage listings, lease agreements and property maintenance.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Each category of user has different levels of access and functionality on the website, depending on their role in the real estate market. Users must use the platform in an ethical and professional manner. They are expected to maintain the accuracy of their information and update their profile as necessary. ListMeet reserves the right to verify the accuracy of the information provided and to suspend or terminate accounts that do not comply with these terms and conditions or applicable laws.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users may request cancellation of their account at any time. To cancel an account, the user must follow the procedure established on the website, which generally includes a written request through the platform or by email. Once the request is received, ListMeet will proceed to deactivate the account and delete or archive the associated data, as permitted by applicable laws and regulations. ListMeet reserves the right to retain certain information to comply with legal obligations or resolve disputes. Account termination does not relieve the user of any outstanding obligations or liabilities incurred prior to account deactivation.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                4. REAL ESTATE LISTINGS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Registered users of the website are authorized to post real estate properties for rent or sale. When posting a property, the user must provide detailed and accurate information about the property, including location, features, price, and terms of sale or lease. The user warrants that he or she has the legal right to sell or rent the property and that the information provided is truthful and current.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users are solely responsible for the truthfulness and legality of the information they post. ListMeet is not responsible for the accuracy or legality of listings and is not involved in any buyer/seller or landlord/tenant transactions. Users must ensure that they comply with all applicable laws and regulations regarding the sale or rental of property.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By posting content on the website, including photographs and property descriptions, the user grants the website a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, copy, modify, distribute, publish and process such content without any additional payment or consent. User represents and warrants that it owns or has the necessary rights to the content it posts.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                User may modify or remove its listing at any time. ListMeet reserves the right to remove or modify listings that do not comply with the site policies or applicable laws.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet does not guarantee the sale or rental of listed properties and is not responsible for transactions or interactions between users. In addition, ListMeet is not responsible for any loss of data or damage resulting from the use of the listing service.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet reserves the right to modify this listing clause at any time. Changes will become effective immediately upon posting on the website. Users are advised to periodically review these terms and conditions to be aware of any changes.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                5. TRANSACTIONS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This website facilitates real estate transactions between different types of users, including tenants/buyers, brokers, associate brokers, licensed real estate agents and management companies. Each transaction must be conducted in accordance with the type of contract agreed upon between the parties involved.
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Renters/Buyers and Other Users: <TypeFace>Renters or Buyers may transact with brokers, real estate agents, associate agents and management companies, according to the type of property and agreement established.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Brokers Among Themselves: <TypeFace>Brokers may negotiate and transact with each other for the sale, purchase or lease of properties.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Management Companies and Brokers: <TypeFace>Management companies may enter into transactions with brokers to facilitate property management.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Brokers with Associate Agents and Real Estate Agents: <TypeFace>Brokers can negotiate and collaborate with associate brokers and real estate agents in completing transactions.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Transactions made through the website must be processed using the payment methods available on the platform. The Website offers several payment options, and users should choose the method that best suits their needs. ListMeet is not responsible for failed transactions or problems related to the chosen payment method.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users are responsible for complying with all terms and conditions of the contract agreed between the parties. The website acts only as a facilitator and is not a party to the transactions. Users must ensure that all transactions are conducted in a legal and ethical manner, in compliance with applicable laws and regulations.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The website implements security measures to protect users&#39; transactions and financial information. However, users should take additional precautions and verify the veracity and reliability of the parties with whom they transact.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In the event of transaction-related disputes, users should resolve them among themselves. ListMeet can aid in mediating disputes but assumes no legal responsibility for transactions between users.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                6. PAYMENTS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Transactions and payments within the website may be made by the following payment methods:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Credit/Debit Card (Visa, Mastercard, Discover, Amex, Diners, etc.).
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The corresponding payment or transaction will be charged to the credit/debit card immediately after the corresponding transaction is made. Once the transaction is processed, we will send an electronic receipt of the transaction to the user&#39;s email address.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you find any inconsistencies in your billing, please contact us through our contact details or you can make the claim through the customer service of the relevant payment processor.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If your card is declined, you will receive an error message. No payment will be charged to your card and no transaction will be processed. There may be a pending transaction on your account until your card issuing bank withdraws the authorization. This usually takes 2 to 5 business days. Your card may be declined for a number of reasons, such as insufficient funds, AVS (Address Verification System) mismatch, or you have entered an incorrect security code.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If your payment is declined, you will need to provide an alternative payment method or provide another card on which the payment can be charged and processed.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Your payment details will be treated and held securely and for the sole purpose of processing transactions within the website. ListMeet reserves the right to contract any payment processor available in the market.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                7. USER CONTENT
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users may provide and upload content and information through the publication of real estate listings. Users retain any copyright or other rights they may have in the content and information they provide through the website. ListMeet is not responsible for the accuracy, safety or legality of the content posted on the website by users. The user is solely and exclusively responsible for its content and the consequences of its publication. By providing content and information through the website, the user grants ListMeet a worldwide, non-exclusive, royalty-free, fully paid-up right and license to host, store, transfer, perform, reproduce, and modify the provider&#39;s content for the sole purpose of using and displaying it within the platform and to the public.  
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet disclaims all liability in connection with the user&#39;s content. User is solely and exclusively responsible for its content and the consequences of providing content through the website. By providing and uploading content through the website, you affirm, represent and warrant that:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                a) <TypeFace>You own the content you provide through the website, or have the necessary licenses, rights, consents, and permissions to authorize ListMeet to publish and display your content through the website.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                b) <TypeFace>Your content does not (i) infringe, violate or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, right of privacy, right of publicity or any other intellectual property or proprietary right; (ii) defame, libel, slander, libel or invade the privacy, publicity or other proprietary rights of any other person; or (iii) cause List to violate any law or regulation.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                c) <TypeFace>Your content may not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful or otherwise inappropriate.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                d) <TypeFace>Your content does not and will not contain content that incites hatred, threats of physical harm or harassment.</TypeFace>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet may, at any time and without notice, filter, remove, edit, or block any user content that, in our sole judgment, violates these terms or is otherwise objectionable. If a user or content owner notifies us that user content allegedly does not conform to these terms, we may investigate the allegation and determine in our sole discretion whether to remove the user content, which we reserve the right to do at any time and without notice. You acknowledge and agree that ListMeet reserves the right to, and may from time to time, monitor any and all information transmitted or received through the Service for operational and other purposes.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                8. LIMITATION OF LIABILITY
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet provides a platform to publish and search real estate listings, as well as to facilitate communication and transactions between users. ListMeet&#39;s liability is limited as follows:
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet is not responsible for the properties posted by users nor for the accuracy or legality of such listings. Users are responsible for ensuring that they have the right to post properties and that all information provided is truthful and complies with applicable laws.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet is not involved in or a party to any agreements made between users for the properties listed. It is not responsible for the terms, conditions, performance or enforcement of such agreements.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Although ListMeet seeks to ensure that the information in the listings is accurate and current, it does not guarantee the truthfulness, accuracy or completeness of the information provided in the listings.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet is not responsible for the actions of users, the quality, safety, or legality of the properties listed, nor for the ability of users to sell, rent or buy properties.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet, its directors, employees, or agents, shall not be liable for direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use the service.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Users agree to indemnify and hold ListMeet and its affiliates harmless from any claims or damages arising from use of the website, violation of these terms and conditions, or violation of third-party rights.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By visiting the website and accessing the content available on the website (Blog), you accept personal responsibility for the results of the use of the information and content available on the website. You agree that ListMeet does not guarantee the results of actions advised or not advised by this website and the content available on the website. ListMeet provides resources and content for informational purposes only. You acknowledge and agree that your ultimate success or failure in using the information and content available on the website will be the result of your own efforts, your particular situation and a number of other circumstances that are beyond ListMeet’s control.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                9. COPYRIGHT
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                All materials on ListMeet, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software, and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by ListMeet or by third parties that have licensed or otherwise provided their material to the website. You acknowledge and agree that all materials on ListMeet are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without ListMeet prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize ListMeet or any part of the material for any purpose other than its intended purposes is strictly prohibited.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                10. COPYRIGHT INFRINGEMENT
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet will respond to all inquiries, complaints, and claims regarding alleged infringement for failure to comply with or violation of the provisions contained in the Digital Millennium Copyright Act (DMCA). ListMeet respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on or in connection with the website infringes your copyright or other intellectual property right, please send us your copyright infringement request pursuant to Section 512 of the Digital Millennium Copyright Act (DMCA), via our contact information, with the following information:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; A statement that specifically identifies the location of the infringing material, in sufficient detail so that List can find it on the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Your name, address, telephone number and email address.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner&#39;s behalf.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                11. CHAT AND MESSAGING
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The website contains chat areas or messaging services to enable communication between users. The user may only use the chat or messaging service to send and receive messages that are relevant and specific to the purposes of the website. The user may NOT use the chat or messaging service available on the website to do any of the following:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Send messages that are defamatory, violent, profane, harassing, obscene, threatening or messages that otherwise violate the legal rights of users or third parties, including List staff.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Post messages of a sexual or pornographic nature.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Post messages that promote illegal activities.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Conduct or forward surveys, contests, pyramid schemes or chain letters.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Impersonate another person or user or permit another person or entity to use your account to send messages or otherwise use your account.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Repeatedly send the same message. Spamming is strictly prohibited.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Imply or assert that any statement you make is endorsed by ListMeet, without ListMeet&#39;s prior written consent.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Post offensive and/or harmful messages, including, but not limited to, content that advocates, supports, condones or promotes racism, bigotry, hatred or physical harm of any kind against any individual or group of individuals.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Use the website to solicit any other business, platform or service, or contact users for employment, recruitment or any other purpose unrelated to the use of the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Use the website to collect personal data from users.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Any breach or violation of the provisions contained in these terms and conditions may result in the suspension of the offending user&#39;s account, with or without notice and without the right to compensation.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                12. USER RESPONSIBILITIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                When registering and using the website, the user must comply with the following responsibilities:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                1. Accuracy of Information: <TypeFace>Users are responsible for ensuring that all information they provide, whether in the registration process, in posting listings or in communicating with other users, is accurate, complete and up-to-date. This includes, but is not limited to, personal information, details of properties listed and transaction terms.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                2. Legality of Actions: <TypeFace>Users must ensure that all of their actions within the site, including posting listings and conducting transactions, comply with all applicable local, national and international laws and regulations.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                3. Respect for Intellectual Property Rights: <TypeFace>Users must respect the intellectual property rights of third parties. This implies not publishing copyrighted material, such as photographs or descriptions, without proper permission.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                4. Communication and Transactions: <TypeFace>Users are responsible for maintaining clear, respectful and honest communication with other users on the site. Transactions should be conducted in good faith, with honesty and transparency.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                5. Confidentiality and Account Security: <TypeFace>Users are responsible for maintaining the confidentiality of their login information and all activities that occur under their account. Any unauthorized use of the account must be reported immediately to the website.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                6. Non-Discrimination: <TypeFace>Users must refrain from any form of discrimination or harassment towards other users based on race, gender, religion, national origin, disability, sexual orientation, age or any other characteristic protected by law.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                7. Reporting Suspicious Activity: <TypeFace>Users must report to the website any suspicious or apparently illegal activity they observe on the platform.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                8. Indemnification: <TypeFace>Users agree to indemnify and hold harmless the website and its affiliates, directors, employees and agents, from any claims, damages, losses, liabilities or expenses arising out of their use of the website or violation of these responsibilities.</TypeFace>
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                13. PERSONAL DATA
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Any personal information you submit in connection with the use of the website will be used in accordance with our <a href="/appprivacy">privacy policy</a>. By using the website, you agree that we may collect and store your personal information. Please see our <a href="/appprivacy">privacy policy</a>.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                14. PROHIBITED ACTIVITIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The content and information available on the website (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons, or other material), as well as the infrastructure used to provide such Content and information, is proprietary to ListMeet or licensed to the ListMeet by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or services obtained from or through the website. In addition, the following activities are prohibited:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Deep-link to any portion of the services for any purpose without our express written permission.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; &quot;Frame&quot;, &quot;mirror&quot; or otherwise incorporate any part of the website into any other websites or service without our prior written authorization.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by ListMeet in connection with the services.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Circumvent, disable or otherwise interfere with security-related features of the website or features that prevent or restrict use or copying of any content.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Download any content unless it’s expressly made available for download by ListMeet.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                15. DISCLAIMER OF WARRANTIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Because of the nature of the Internet ListMeet provides and maintains the website on an &quot;as is&quot;, &quot;as available&quot; basis and makes no promise that use of the website will be uninterrupted or entirely error free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Our website may from time to time contain links to other websites which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those sites.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                You will be responsible for any breach of these terms by you and if you use the website in breach of these terms you will be liable to and will reimburse List for any loss or damage caused as a result.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet shall not be liable in any amount for any failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control, including, without limitation, Internet outages, communications outages, fire, flood, war, or any uncontrollable act of nature.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                These terms do not affect your statutory rights as a consumer which are available to you.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Subject as aforesaid, to the maximum extent permitted by law, ListMeet excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect, or consequential loss whether or not such arises out of any problem you notify to ListMeet and ListMeet shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Any incorrect or inaccurate information on the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the website or service purchased through the website.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Any loss or damage resulting from your use or the inability to use the website or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user&#39;s computer equipment, software, data, or other property caused by persons accessing or using content from the website or from transmissions via emails or attachments received from ListMeet.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; All representations, warranties, conditions and other terms which but for this notice would have effect.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                16. ELECTRONIC COMMUNICATIONS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                ListMeet will accept no liability for failed, partial or garbled computer transmissions, for any breakdown, failure, connection, availability of computer, telephone, cable, network, electronic or Internet hardware or software, for the acts or omissions of any user or third party, for the accessibility or availability of the Internet or for traffic congestion or unauthorized human action, including errors or mistakes.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                17. INDEMNIFICATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                You agree to defend and indemnify ListMeet from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Your breach of this agreement or the documents referenced herein.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Your violation of any law or the rights of a third party.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                &#8226; Your use of the services.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                18. CHANGES AND TERMINATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                We may modify the website and these terms at any time, at our sole discretion and without notice. You are responsible for keeping yourself informed of these terms. Your continued use of the website constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified, all changes to these terms apply to all users and customers. In addition, we may terminate our agreement with you under these terms at any time by notifying you in writing (including by email) or without notice.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                19. NO PARTNERSHIP
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                You agree that no joint venture, partnership, employment, or agency relationship exists between you and ListMeet as a result of these terms or your use of the services.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                20. INTEGRATION CLAUSE
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This agreement together with the <a href="/appprivacy">privacy policy</a> and any other legal notices published by ListMeet, shall constitute the entire agreement between you and List concerning and governs your use of the website and the services.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                21. DISPUTES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The user agrees that any dispute, claim, or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation, or validity thereof or the use of the services, shall be resolved by binding arbitration between the user and ListMeet, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In the event of a dispute arising in connection with the use of the services or the breach of these conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the website and services.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The courts of the United States, specifically the courts located in the State of New York, shall have jurisdiction over any dispute, controversy or claim relating to ListMeet and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United States, specifically the courts located in the State of New York.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                22. FINAL PROVISIONS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                These terms and conditions are governed by the laws of the United States. Use of the website and services are not authorized in any jurisdiction that does not give effect to all of the provisions of these terms.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Our performance of these terms is subject to existing laws and legal process, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our website or information provided to or gathered by us with respect to such use.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If any part of these terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Any rights not expressly granted herein are reserved.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                23. CONTACT INFORMATION
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you have questions or concerns about these terms, please contact us through our contact forms or by using the contact information below:
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                ListMeet.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                <a href="mailto:support@ListMeet.com">support@ListMeet.com</a>
              </TypeFace>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default TermsApp

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "16px 0",
    textAlign: "left",
    lineHeight: 1.6
  },
  inline: {
    paddingLeft: 16,
  }
}