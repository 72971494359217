import React, { useEffect, useState } from "react";
import HomeHeaderDesktop from "src/components/home/HomeHeaderDesktop";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "src/hooks/useMediaQuery";
import AdminSideMenu from "./AdminSideMenu";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import AdminAgencyRequests from "./components/agencyRequests/AdminAgencyRequests";
import AdminManagementCompanies from "./components/managementCompanies/AdminManagementCompanies";
import AdminListingReports from "./components/reports/AdminListingReports";
import AdminListingReviews from "./components/reviews/AdminListingReviews";
import AdminAgenciesList from "./components/adminAgencies/AdminAgenciesList";
import AdminAgencyDetails from "./components/adminAgencies/AdminAgencyDetails";
import AdminCustomerList from "./components/customers/AdminCustomerList";
import AdminListings from "./components/listings/AdminListings";
import AdminListingDetails from "./components/listings/AdminListingDetails";
import AdminPayments from "./components/payments/AdminPayments";
import AdminReleasedAgents from "./components/releasedAgents/AdminReleasedAgents";
import AdminList from "./components/admins/AdminList";

import styles from "./admin.module.scss";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { Result } from "antd";
import AdminListingsAgents from "./components/listings/AdminListingsAgents";

const AdminBoard = () => {
  const { page } = useParams();
  const { userProfile } = useFirestore();

  const [section, setSection] = useState(page ?? "dashboard");

  const { isMobile } = useMediaQuery();

  const adminRoutes = [
    {
      element: <Navigate to="/admin/dashboard" replace />,
      link: "/",
    },
    {
      element: <AdminDashboard />,
      link: "/dashboard",
    },
    {
      element: <AdminAgencyRequests />,
      link: "/agency-requests",
    },
    {
      element: <AdminManagementCompanies />,
      link: "/management-companies",
    },
    {
      element: <AdminReleasedAgents />,
      link: "/released-agents",
    },
    {
      element: <AdminListingReports />,
      link: "/reports",
    },
    {
      element: <AdminListingReviews />,
      link: "/reviews",
    },
    {
      element: <AdminCustomerList />,
      link: "/customers",
    },
    {
      element: <AdminListings />,
      link: "/listings",
    },
    {
      element: <AdminListingsAgents />,
      link: "/listings/:creatorId",
    },
    {
      element: <AdminAgenciesList />,
      link: "/agencies",
    },
    {
      element: <AdminAgencyDetails />,
      link: "/agencies/:agencyId",
    },
  ];

  const fullRoutes =
    userProfile?.role === "admin"
      ? [
          ...adminRoutes,
          { element: <AdminPayments />, link: "/payments" },
          {
            element: <AdminList />,
            link: "/list",
          },
        ]
      : adminRoutes;

  return (
    <div className="App">
      {/* {isMobile && (
        <Sidebar
          isOpen={mobileMenuOpened}
          pageWrapId={"page-wrap"}
          outerContainerId={"App"}
          selected={section}
          onSelect={handleSelectSubPage}
          onStateChange={(state) => setMobileMenuOpened(state.isOpen)}
        />
      )} */}
      <div id="page-wrap">
        <HomeHeaderDesktop />
        <div className={styles.container}>
          <div className={styles.content}>
            {!isMobile && <AdminSideMenu selected={section} />}
            <div className={styles.vertSeparator} />
            <div className={styles.contentArea}>
              <Routes>
                {fullRoutes.map((route, i) => (
                  <Route key={i} path={route.link} element={route.element} />
                ))}
                <Route
                  path="*"
                  element={<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBoard;
