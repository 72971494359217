import { Typography } from "antd";

export const theme = {
  token: {
    colorPrimary: "#3151b7",
    fontSize: 14,
  },
  components: {
    Button: {
      contentFontSizeSM: 12,
    },
    Statistic: {
      titleFontSize: 12,
    },
    Form: {
      itemMarginBottom: 0,
    },
    Table: {
      fontSize: 12,
    },
    Typography: {
      fontSize: 12,
    },
  },
};
