import { arrayRemove, collection, doc, getDocs, query, updateDoc, where, deleteField } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import TypeFace from "src/components/typography/Typefaces";
import { firestore } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import { Colors } from "src/theme/Colors";
import { truncateString } from "src/utils/strings";
import { socialTimeString } from "src/utils/timeUtils";
import IconDelete from "src/assets/images/icons/icon-delete-red.svg";
import { getListing } from "src/data/dbWrappers";
import { extractStreetAddressFromFullAddress } from "src/utils/geoUtils";

const { default: Flexbox } = require("src/components/wrapper/Flexbox");

const MessageThreadCell = ({ messageThread, selected, onSelect, onDeleted }) => {
  const { userProfile } = useFirestore();
  const [senderProfiles, setSenderProfiles] = useState();
  const [listing, setListing] = useState();

  const threadName = useMemo(() => {
    if (senderProfiles) {
      return senderProfiles.map((profile) => profile.name).join(",");
    }
  }, [senderProfiles]);

  const handleDelete = () => {
    updateDoc(doc(firestore, `messages/${messageThread.id}`), {
      [`deleted.${userProfile.userId}`]: true,
      [`unreads.${userProfile.userId}`]: deleteField(),
    });
    updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
      "notifications.message": arrayRemove(messageThread.id),
    });
    onDeleted?.(messageThread.id);
  };

  const unreadMessagesCount = useMemo(() => {
    if (messageThread) {
      if (userProfile.userId) {
        if (messageThread.unreads && messageThread.unreads[userProfile.userId]) {
          return messageThread.unreads[userProfile.userId].length;
        }
      }
    }
    return 0;
  }, [messageThread, userProfile?.userId]);

  useEffect(() => {
    if (messageThread?.senders) {
      let senderIds = messageThread.senders.filter((snder) => snder !== userProfile?.userId);
      const q = query(collection(firestore, "profiles"), where("userId", "in", senderIds));
      getDocs(q).then((docs) => {
        let users = [];
        docs.forEach((doc) => {
          users.push(doc.data());
        });
        setSenderProfiles(users);
      });
      getListing(messageThread?.listing).then((docSnap) => {
        const listingDoc = docSnap.data();
        setListing(listingDoc);
      });
    }
  }, [messageThread?.senders, userProfile?.userId]);

  return (
    <Flexbox
      style={{
        width: "100%",
        cursor: "pointer",
        backgroundColor: selected ? Colors.background1 : "transparent",
      }}
      onClick={() => onSelect(messageThread)}
    >
      <Flexbox row style={styles.container}>
        <Flexbox style={styles.leftContent}>
          <TypeFace semiBold size={13} style={{ cursor: "pointer" }}>
            {listing?.unit !== "" && <TypeFace semiBold>Unit {listing?.unit},&nbsp;</TypeFace>}
            <TypeFace semiBold>{extractStreetAddressFromFullAddress(listing?.address?.full)}</TypeFace>
          </TypeFace>
          <TypeFace semiBold size={12} style={{ cursor: "pointer" }} color={Colors.gray1}>
            {threadName}
          </TypeFace>
          <TypeFace size={11} style={{ cursor: "pointer" }}>
            {truncateString(messageThread.lm.msg, 30)}
          </TypeFace>
        </Flexbox>
        <Flexbox style={{ alignItems: "flex-end" }}>
          <Flexbox row>
            {unreadMessagesCount > 0 && (
              <Flexbox style={styles.unreadBadge}>
                <TypeFace bold size={11} color="white" style={{ cursor: "pointer" }}>
                  {unreadMessagesCount}
                </TypeFace>
              </Flexbox>
            )}
          </Flexbox>
          <TypeFace medium size={11} style={{ cursor: "pointer" }}>
            {socialTimeString(messageThread.lm.at)}
          </TypeFace>
        </Flexbox>
        <img
          src={IconDelete}
          width={14}
          height={14}
          onClick={handleDelete}
          style={{
            marginLeft: 5,
            cursor: "pointer",
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

export default MessageThreadCell;

const styles = {
  container: {
    width: "calc(100% - 16px)",
    paddingTop: 10,
    paddingBottom: 10,
    position: "relative",
    borderBottom: "1px solid #efefef",
  },
  leftContent: {
    flex: 1,
    alignItems: "flex-start",
  },
  unreadBadge: {
    width: 20,
    height: 20,
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: "red",
  },
};
