import { ADD_OBJECT_ID_TO_SAVED, REMOVE_OBJECT_ID_FROM_SAVED, STORE_SEARCH_OBJECT } from "./actions";

const initialState = {
  searchObject: {
    type: "rental",
    price: {},
    query: [],
  },
  savedObjectIds: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SEARCH_OBJECT:
      return { ...state, searchObject: action.payload };
    case ADD_OBJECT_ID_TO_SAVED:
      return { ...state, savedObjectIds: [...state.savedObjectIds, action.payload] };
    case REMOVE_OBJECT_ID_FROM_SAVED:
      return { ...state, savedObjectIds: [...state.savedObjectIds.filter((id) => id !== action.payload)] };
    default:
      return state;
  }
};

export default searchReducer;
