import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  arrayUnion,
  deleteField,
  arrayRemove,
} from "firebase/firestore";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "src/components/button/Button";
import TypeFace from "src/components/typography/Typefaces";
import { firestore, storage } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import IconAvatarPlaceholder from "src/assets/images/icons/icon-avatar-placeholder-gray.svg";
import { roleLabelFromValue } from "src/model/types";
import MessageChatCell from "./MessageChatCell";
import IconSend from "src/assets/images/icons/icon-send.svg";
import IconAttachment from "src/assets/images/icons/icon-attachment.svg";
import shortid from "shortid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Loading from "react-fullscreen-loading";
import { get } from "lodash";
import { Fragment } from "react";
import { sendPushNotificationForChat } from "src/utils/strings";

const { default: Flexbox } = require("src/components/wrapper/Flexbox");

const MessageRoom = ({ threadId, onPayToAgency }) => {
  const unmountedRef = useRef(false);
  const chatSnapshotRef = useRef();
  const messageThreadRef = useRef();
  const chatRoomDivRef = useRef();
  const reviewSnapshotRef = useRef();

  const { userProfile } = useFirestore();

  const [messageThread, setMessageThread] = useState();
  const [senderProfiles, setSenderProfiles] = useState();
  const [chats, setChats] = useState();
  const [myUserRoleInRoom, setMyUserRoleInRoom] = useState();
  const [threadListingData, setThreadListingData] = useState();
  const [review, setReview] = useState();

  const [inputMessage, setInputMessage] = useState("");

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const messageUserProfiles = useMemo(() => {
    return senderProfiles?.filter((profile) => profile?.userId !== userProfile?.userId);
  }, [senderProfiles, userProfile]);

  const sendMessage = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(e.target.value);
    }
  };

  const handleSendMessage = (message) => {
    const opponentSenders = messageThread?.senders?.filter((sender) => sender !== userProfile?.userId);

    const newMessage = {
      at: new Date().getTime(),
      by: userProfile?.userId,
      msg: message,
      unread: opponentSenders,
      extra: {
        type: "text",
      },
    };
    addDoc(collection(firestore, `messages/${threadId}/chat`), newMessage).then((chatDocRef) => {
      chatRoomDivRef.current.scrollIntoView({ behavior: "smooth" });

      updateDoc(doc(firestore, `messages/${threadId}`), {
        deleted: deleteField(),
        lm: {
          ...newMessage,
          id: chatDocRef.id,
        },
      });
      opponentSenders.forEach((opponentSender) => {
        updateDoc(doc(firestore, `messages/${threadId}`), {
          [`unreads.${opponentSender}`]: arrayUnion(chatDocRef.id),
        });
        updateDoc(doc(firestore, `profiles/${opponentSender}`), {
          "notifications.message": arrayUnion(threadId),
        });

        // send push notification on message sent
        getDoc(doc(firestore, `profiles/${opponentSender}`)).then((profileDoc) => {
          const myProfile = profileDoc.data();
          //console.log('myProfile.deviceToken=',myProfile.deviceToken)
          sendPushNotificationForChat(
            "Message Received",
            "You have received a new message",
            myProfile.deviceToken,
            "NewChat"
          );
        });
      });
    });

    setInputMessage("");
  };

  const messageThreadIsActive = useMemo(() => {
    // return !(messageThread?.status === 'completed' || messageThread?.status === 'cancelled')
    return messageThread !== null && messageThread !== undefined;
  }, [messageThread]);

  const handleMarkAsComplete = () => {
    const opponentSenders = messageThread?.senders?.filter((sender) => sender !== userProfile?.userId);

    const newMessage = {
      at: new Date().getTime(),
      by: userProfile?.userId,
      msg: "Showing completed",
      unread: opponentSenders,
      extra: {
        type: "complete",
      },
    };

    addDoc(collection(firestore, `messages/${threadId}/chat`), newMessage).then((chatDocRef) => {
      chatRoomDivRef.current.scrollIntoView({ behavior: "smooth" });
      updateDoc(doc(firestore, `messages/${threadId}`), {
        status: "completed",
        deleted: deleteField(),
        lm: {
          ...newMessage,
          id: chatDocRef.id,
        },
      });
      opponentSenders.forEach((opponentSender) => {
        updateDoc(doc(firestore, `messages/${threadId}`), {
          [`unreads.${opponentSender}`]: arrayUnion(chatDocRef.id),
        });
        updateDoc(doc(firestore, `profiles/${opponentSender}`), {
          "notifications.message": arrayUnion(threadId),
          "notifications.schedule": arrayRemove(threadId),
        });
        updateDoc(doc(firestore, `profiles/${userProfile?.userId}`), {
          "notifications.schedule": arrayRemove(threadId),
        });

        // Send Push Notification when mark as complete
        getDoc(doc(firestore, `profiles/${opponentSender}`)).then((profileDoc) => {
          const myProfile = profileDoc.data();
          sendPushNotificationForChat(
            "Message Received",
            "The meet now showing has been completed",
            myProfile.deviceToken,
            "NewChat"
          );
        });
      });

      if (messageThread.lm.extra.type === "schedule") {
        console.log("a");
        updateDoc(doc(firestore, `schedules/${threadId}`), {
          completed: true,
        });
      }
    });

    document.getElementById("message-input").value = "";
  };

  const handleCancelMeeting = () => {
    const opponentSenders = messageThread?.senders?.filter((sender) => sender !== userProfile?.userId);

    const newChatMessage = {
      at: new Date().getTime(),
      by: userProfile.userId,
      msg: "Showing canceled",
      extra: {
        type: "cancel",
      },
    };

    addDoc(collection(firestore, `messages/${threadId}/chat`), newChatMessage).then((chatDocRef) => {
      chatRoomDivRef.current.scrollIntoView({ behavior: "smooth" });
      updateDoc(doc(firestore, `messages/${threadId}`), {
        status: "cancelled",
        deleted: deleteField(),
        lm: {
          ...newChatMessage,
          id: chatDocRef.id,
        },
      });
      opponentSenders.forEach((opponentSender) => {
        updateDoc(doc(firestore, `messages/${threadId}`), {
          [`unreads.${opponentSender}`]: arrayUnion(chatDocRef.id),
        });
        updateDoc(doc(firestore, `profiles/${opponentSender}`), {
          "notifications.message": arrayUnion(threadId),
          "notifications.schedule": arrayRemove(threadId),
        });
        updateDoc(doc(firestore, `profiles/${userProfile?.userId}`), {
          "notifications.schedule": arrayRemove(threadId),
        });
        // Send Push Notification when meeting canceled
        getDoc(doc(firestore, `profiles/${opponentSender}`)).then((profileDoc) => {
          const myProfile = profileDoc.data();
          sendPushNotificationForChat(
            "Message Received",
            "Your showing has been canceled",
            myProfile.deviceToken,
            "NewChat"
          );
        });
      });

      if (messageThread.lm.extra.type === "meeting") {
        updateDoc(doc(firestore, `meetings/${threadId}`), {
          canceled: true,
          canceledBy: userProfile.userId,
        });
      } else {
        updateDoc(doc(firestore, `schedules/${threadId}`), {
          canceled: true,
          canceledBy: userProfile.userId,
        });
      }
    });
    document.getElementById("message-input").value = "";
  };

  const handlePayToAgency = () => {
    onPayToAgency?.();
  };

  const handleReview = () => {};

  const attachmentFileSelectHandler = (e) => {
    const file = e.target.files[0];
    console.log("File => ", file.type);

    setIsUploadingFile(true);
    const fileId = shortid.generate();
    const storageRef = ref(storage, `/messages/${threadId}/${fileId}/`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log("uploading file => ", percent);
      },
      (err) => {
        console.log("uploading file error => ", err);
        setIsUploadingFile(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            const opponentSenders = messageThread?.senders?.filter((sender) => sender !== userProfile?.userId);

            const newMessage = {
              at: new Date().getTime(),
              by: userProfile?.userId,
              msg: "Sent attachment",
              unread: opponentSenders,
              attachment: url,
              extra: {
                type: "file",
                fileType: file.type,
                fileName: file.name,
              },
            };
            addDoc(collection(firestore, `messages/${threadId}/chat`), newMessage)
              .then((chatDocRef) => {
                chatRoomDivRef.current?.scrollIntoView({ behavior: "smooth" });

                updateDoc(doc(firestore, `messages/${threadId}`), {
                  deleted: deleteField(),
                  lm: {
                    ...newMessage,
                    id: chatDocRef.id,
                  },
                });
                opponentSenders.forEach((opponentSender) => {
                  updateDoc(doc(firestore, `messages/${threadId}`), {
                    [`unreads.${opponentSender}`]: arrayUnion(chatDocRef.id),
                  });
                  updateDoc(doc(firestore, `profiles/${opponentSender}`), {
                    "notifications.message": arrayUnion(threadId),
                  });

                  // send push notification on message sent
                  getDoc(doc(firestore, `profiles/${opponentSender}`)).then((profileDoc) => {
                    const myProfile = profileDoc.data();
                    sendPushNotificationForChat(
                      "Message Received",
                      "You have received a new message",
                      myProfile.deviceToken,
                      "NewChat"
                    );
                  });
                });
                setIsUploadingFile(false);
              })
              .catch((err) => {
                console.log("add chat doc failed: ", err);
                setIsUploadingFile(false);
              })
              .catch((err) => {
                console.log("add chat doc failed: ", err);
                setIsUploadingFile(false);
              });
          })
          .catch((err) => {
            console.log("File link configuration failed => ", err);
            setIsUploadingFile(false);
          });
      }
    );
  };

  const clearNotifications = () => {
    // clear unread notification optimization?
    updateDoc(doc(firestore, `messages/${threadId}`), {
      [`unreads.${userProfile.userId}`]: deleteField(),
    });
    updateDoc(doc(firestore, `profiles/${userProfile?.userId}`), {
      "notifications.message": arrayRemove(threadId),
    });
  };

  useEffect(() => {
    if (threadId) {
      console.log("threadId=", threadId);
      messageThreadRef.current?.();
      messageThreadRef.current = onSnapshot(doc(firestore, `messages/${threadId}`), (doc) => {
        if (!unmountedRef.current) {
          const messageDoc = doc.data();
          if (!get(messageDoc, `deleted.${userProfile?.userId}`, false)) {
            setMessageThread(doc.data());
            clearNotifications();
          } else {
            setMessageThread(undefined);
          }
        }
      });

      chatSnapshotRef.current?.();
      chatSnapshotRef.current = onSnapshot(
        query(collection(firestore, `messages/${threadId}/chat`), orderBy("at"), limit(100)),
        (docsSnapshot) => {
          const d = [];
          docsSnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          if (!unmountedRef.current) {
            setChats(d);
            setTimeout(() => {
              chatRoomDivRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 500);
          }
        }
      );

      reviewSnapshotRef.current?.();
      reviewSnapshotRef.current = onSnapshot(doc(firestore, `reviews/${threadId}`), (doc) => {
        if (!unmountedRef.current) {
          setReview(doc.data());
        }
      });
    }
  }, [threadId]);

  useEffect(() => {
    if (messageThread?.senders && userProfile?.userId) {
      let senderIds = messageThread.senders.filter((snder) => snder !== userProfile?.userId);
      const q = query(collection(firestore, "profiles"), where("userId", "in", senderIds));
      getDocs(q).then((docs) => {
        let users = [];
        docs.forEach((doc) => {
          users.push(doc.data());
        });
        if (!unmountedRef.current) {
          setSenderProfiles(users);
        }
      });
    }
  }, [messageThread?.senders, userProfile?.userId]);

  useEffect(() => {
    if (userProfile?.role === "customer") {
      setMyUserRoleInRoom("customer");
    } else {
      if (messageThread?.listing && userProfile?.userId) {
        getDoc(doc(firestore, `listing/${messageThread.listing}`)).then((listingDoc) => {
          const listingData = listingDoc.data();
          if (!unmountedRef.current) {
            setThreadListingData(listingData);
          }

          if (listingData?.by === userProfile?.userId) {
            setMyUserRoleInRoom("listing");
          } else {
            setMyUserRoleInRoom("showing");
          }
        });
      }
    }
  }, [messageThread, userProfile]);

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
      chatSnapshotRef.current?.();
      messageThreadRef.current?.();
      reviewSnapshotRef.current?.();
    };
  }, []);

  return messageThread ? (
    <Flexbox style={styles.container}>
      <Flexbox row style={styles.topInfoView}>
        {messageUserProfiles?.map((profile, index) => (
          <Flexbox row key={`profile-${index}`} style={{ gap: 10 }}>
            {!!profile?.photo?.url ? (
              <img src={profile.photo.url} style={styles.avatar} />
            ) : (
              <img src={IconAvatarPlaceholder} style={styles.avatar} />
            )}
            <Flexbox style={{ alignItems: "flex-start" }}>
              <TypeFace semiBold size={12}>
                {profile.name}
              </TypeFace>
              <TypeFace size={12}>{roleLabelFromValue(profile.role)}</TypeFace>
            </Flexbox>
          </Flexbox>
        ))}
      </Flexbox>
      <div style={styles.chatRoomsDiv}>
        {chats?.map((chat, idx) => (
          <MessageChatCell
            key={idx}
            chats={chats}
            chat={chat}
            idx={idx}
            threadId={threadId}
            senderProfiles={senderProfiles}
            review={review}
            onReview={handleReview}
          />
        ))}
        <div ref={chatRoomDivRef} />
      </div>
      {(messageThread?.status === "completed" || messageThread?.status === "cancelled") && (
        <Flexbox row style={styles.threadStatusBar}>
          <TypeFace>{messageThread.status === "completed" ? "Meeting completed" : "Meeting cancelled"}</TypeFace>
          {userProfile?.role === "customer" && messageThread.status === "completed" && !messageThread.paid && (
            <Button secondary textSize={10} onClick={handlePayToAgency}>
              Pay Commission
            </Button>
          )}
        </Flexbox>
      )}
      <Flexbox row style={styles.inputContainer}>
        <input
          type="text"
          disabled={!messageThreadIsActive}
          placeholder="Type your message here..."
          id="message-input"
          style={styles.inputBox}
          onKeyDown={sendMessage}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        <Flexbox
          style={{
            ...styles.inputMessageButtons,
            opacity: !messageThreadIsActive || inputMessage === "" ? 0.3 : 1,
          }}
          disabled
          onClick={() => {
            if (!!inputMessage && messageThreadIsActive) {
              handleSendMessage(inputMessage);
            }
          }}
        >
          <img src={IconSend} width={20} height={20} />
        </Flexbox>
        <Flexbox
          style={{
            ...styles.inputMessageButtons,
            opacity: !messageThreadIsActive ? 0.3 : 1,
          }}
          onClick={() => {
            if (messageThreadIsActive) {
              document.getElementById("sendAttachment").click();
            }
          }}
        >
          <input
            id="sendAttachment"
            hidden
            type="file"
            onChange={attachmentFileSelectHandler}
            accept="image/jpeg, image/png, application/pdf, application/vnd.ms-excel"
          />
          <img src={IconAttachment} width={20} height={20} />
        </Flexbox>
      </Flexbox>
      {messageThread?.status !== "completed" && messageThread?.status !== "cancelled" && (
        /*&& (myUserRoleInRoom === 'customer' || myUserRoleInRoom === 'showing')*/ <Flexbox
          row
          style={styles.actionButtonsContainer}
        >
          <Button onClick={handleMarkAsComplete} primary style={styles.actionButtons} textSize={12}>
            Mark as complete
          </Button>
          <Button onClick={handleCancelMeeting} secondary style={styles.actionButtons} textSize={12}>
            Cancel meeting
          </Button>
        </Flexbox>
      )}
      {isUploadingFile && <Loading loading background="#0005" loaderColor="white" />}
    </Flexbox>
  ) : (
    <Fragment></Fragment>
  );
};

export default MessageRoom;

const styles = {
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  },
  chatRoomsDiv: {
    justifyContent: "flex-start",
    overflow: "scroll",
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    width: "calc(100% - 20px)",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#f9f9f9",
  },
  inputBox: {
    height: 30,
    flex: 1,
    border: "none",
    backgroundColor: "transparent",
  },
  chatContent: {
    width: "calc(100% - 40px)",
    marginLeft: 20,
    marginRight: 20,
  },
  actionButtonsContainer: {
    width: "calc(100% - 40px)",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    gap: 10,
    justifyContent: "flex-end",
  },
  actionButtons: {
    height: 30,
  },
  threadStatusBar: {
    width: "100%",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#EFEFEF",
    gap: 16,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: "cover",
    position: "relative",
  },
  topInfoView: {
    width: "calc(100% - 40px)",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
    borderBottom: "1px solid #EFEFEF",
  },
  inputMessageButtons: {
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
  },
};
