import React, { useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'src/hooks/alert/AlertContext';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import Flexbox from 'src/components/wrapper/Flexbox';
import Button from 'src/components/button/Button';
import 'react-calendar/dist/Calendar.css';
import './MeetLaterProcessModal.css'
import { validateYoutubeURL } from 'src/utils/strings';
import useMediaQuery from 'src/hooks/useMediaQuery';
import IconClose from 'src/assets/images/icons/icon-close-black.svg'


const VideoTourModal = ({
  modalIsOpen,
  closeModal,
  videoURL,
}) => {

  const { isMobile } = useMediaQuery()
  const { userProfile } = useFirestore()

  const {showAlert} = useAlert()

  const [meetLaterDate, setMeetLaterDate] = useState(null)
  const [meetLaterTime, setMeetLaterTime] = useState(null)
  const [name, setName] = useState(userProfile?.name ?? "")
  const [email, setEmail] = useState(userProfile?.email ?? "")
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phone ?? "")
  const [error, setError] = useState("")


  const [processing, setProcessing] = useState(false)

  const refContainer = useRef(null)

  const submitEnabled = useMemo(() => {
    return meetLaterDate && meetLaterTime && name !== "" && email !== ""
  }, [name, email, meetLaterDate, meetLaterTime])

  
  function getId(url) {
    if(url !== undefined && url !== '') {
      let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      return regex.exec(url)[3];
    }
    
  }

  function onCloseModal() {
    closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleClose = () => {
    onCloseModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
       <Flexbox>
          <Button onClick={handleClose} icon={<img src={IconClose} width={20} height={20} alt="close-icon" />} style={styles.closeButton}>
          </Button>
         
        </Flexbox>
      <Flexbox  style={{
        width: isMobile ? 'calc(100vw - 40px)' : (window.innerWidth - 400),
        height: isMobile ? 'calc(100vh - 220px)' :(window.innerHeight - 400),
      }}>
          <iframe className='video'
          style={styles.videoBlock}
          title='Youtube player'
          sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
          src={`https://youtube.com/embed/${validateYoutubeURL(videoURL)}?autoplay=0`}>
          </iframe>
       </Flexbox>
        
        
    </Modal>
  )
}

export default VideoTourModal

const styles = {
  container: {
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: '100%',
    marginTop: 20,
    justifyContent: 'space-between',
    gap: 20,
  },
  licenseNumberInput: {
    width: '100%',
    marginTop: 5,
  },
  textInput: {
    marginTop: 5,
    width: '100%',
    borderRadius: 0,
    border: '1px solid #000',
    height: 40,
  },
  passwordInput: {
    width: '100%',
    marginTop: 10,
  },
  videoBlock: {
    width: '90%',
    height: '90%',
    marginTop: 25,
  },
  closeButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 0,
    top: 5,
  },
}