import { useState } from 'react';
import AlertContext from "./AlertContext";

export const AlertProvider = ({ children }) => {

  const [alertShown, setAlertShown] = useState(false);
  const [alertTitle, setAlertTitle] = useState('')
  const [alertDesc, setAlertDesc] = useState('')
  const [alertButtons, setAlertButtons] = useState([])
  const [alertButtonsDirection, setAlertButtonsDirection] = useState(0) // 0 : row, 1 : column

  const showAlert = (showing, title, buttons = [], direction = 0, desc) => {
    if (showing) {
      setAlertTitle(title)
      setAlertButtons(buttons)
      setAlertDesc(desc)
      setAlertButtonsDirection(direction)
    }
    setAlertShown(showing)
  }

  return (
    <AlertContext.Provider value={{ 
      alertShown,
      showAlert,
      alertTitle,
      alertDesc,
      alertButtons,
      alertButtonsDirection
     }}>
      {children}
    </AlertContext.Provider>
  );
};

