import React, { useEffect, useState } from 'react';
import LoginModal from 'src/components/auth/LoginModal';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Flexbox from 'src/components/wrapper/Flexbox';
import useMediaQuery from 'src/hooks/useMediaQuery';
import LoaderGif from 'src/assets/images/loader3.gif';
import TypeFace from 'src/components/typography/Typefaces';
import { Colors } from 'src/theme/Colors';
import CardSearchResultItem from 'src/components/home/search/CardSearchResultItem';
import { useSelector } from 'react-redux';
import { selectSavedObjectIds } from 'src/redux/search/selectors';
import { getAlgoliaObjects } from 'src/algolia';
import HomeBlueMenuBar from 'src/components/home/HomeBlueMenuBar';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';

const SavedProperties = () => {

  const { isMobile } =  useMediaQuery()

  const { savedPropertyIds } = useFirestore()

  const savedObjectIds = useSelector(selectSavedObjectIds)

  const [isLoading, setIsLoading] = useState(false)

  const [savedHits, saveSavedHits] = useState()

  const loadSavedObjects = () => {
    if (savedPropertyIds) {
      getAlgoliaObjects(savedPropertyIds.map(data => data.algoliaID)).then(res => {
        saveSavedHits(res.results)
      })
    } else {
      saveSavedHits([])
    }
  }

  useEffect(() => {
    loadSavedObjects()
  }, [savedPropertyIds])

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {!isMobile && <HomeBlueMenuBar />}
          <Flexbox style={{ width: '100%' }}>
            {isLoading ? (
              <img src={LoaderGif} style={styles.loader} />
            ) : (
              <Flexbox style={{
                width: '90%',
                maxWidth: 1178,
                paddingTop: 30,
                alignItems: 'center'
              }}>
                <TypeFace semiBold size={14} style={styles.title}>
                  Saved properties
                </TypeFace>
                <div style={styles.separator} />
                {(savedHits?.length ?? 0) === 0 ? (
                  <Flexbox style={{
                    ...styles.propertiesContainer,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}>
                    No saved properties
                  </Flexbox>  
                ) : (
                  <Flexbox style={{
                    ...styles.propertiesContainer,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}>
                    {savedHits?.map(hit => <CardSearchResultItem hit={hit}/>)}
                  </Flexbox>
                )}
              </Flexbox>
            )}
          </Flexbox>
        </div>
      </div>
    </div>
  )
}

export default SavedProperties

const styles = {
  profileContainer: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 60,
    alignSelf: 'center'
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.gray3
  },
  loader: {
    width: 30, height: 30, marginTop: 40
  },
  title: {
    marginTop: 20, marginBottom: 10, alignSelf: 'flex-start'
  },
  propertiesContainer: {
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 20,
  }
}
