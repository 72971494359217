import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { firestore } from "src/firebase";
import { Link, useNavigate } from "react-router-dom";
import AdminLayout from "../../../../layouts/AuthLayout";
import styles from "../../admin.module.scss";
import { Flex, Rate, Table, Typography } from "antd";

const { Text } = Typography;

const AdminListingReviews = () => {
  const unmountedRef = useRef(false);
  const [reviews, setReviews] = useState();

  useEffect(() => {
    unmountedRef.current = false;
    const reviewsSnapshotsListner = onSnapshot(
      query(collection(firestore, "reviews"), orderBy("when", "desc")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setReviews(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      reviewsSnapshotsListner();
    };
  }, []);

  const columns = [
    {
      title: "Property",
      dataIndex: "listingId",
      key: "listingId",
      render: (_, review) => (
        <Link to={`/listing/${review.listing.id}`} style={{ textDecoration: "none" }}>
          {review.listing.address}
          {review.listing.unit && `, Unit ${review.listing.unit}`}
        </Link>
      ),
    },
    {
      title: "Customer",
      dataIndex: "userId",
      key: "userId",
      render: (_, review) => <Text>{review?.customer.name}</Text>,
    },
    {
      title: "Agent",
      dataIndex: "agentId",
      key: "agentId",
      render: (_, review) => <Text>{review?.agent.name}</Text>,
    },
    {
      title: "Rating",
      dataIndex: "mark",
      key: "mark",
      render: (text) => <Rate style={{ fontSize: 14 }} disabled defaultValue={text} />,
    },
    {
      title: "Reviewed At",
      dataIndex: "when",
      key: "when",
      render: (text) => moment(text).format("MMM DD, YYYY"),
    },
  ];

  const expandable = {
    expandedRowRender: (review) => {
      return (
        <Flex vertical gap={10} style={{ width: "50%" }}>
          <Text strong>Comments:</Text>
          <Text>{review.comment}</Text>
        </Flex>
      );
    },
  };

  return (
    <AdminLayout title="Reviews">
      <Table
        expandable={expandable}
        dataSource={reviews}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
        rowKey={(_, index) => index}
      />
    </AdminLayout>
  );
};

export default AdminListingReviews;
