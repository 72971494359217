import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import PlacesAutocomplete from 'src/components/placeAutoComplete/PlaceAutoComplete';
import { useEffect, useRef, useState } from "react";
import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import { firestore } from "src/firebase";
import Button from "src/components/button/Button";
import IconEdit from "src/assets/images/icons/icon-edit.svg"
import IconDelete from "src/assets/images/icons/icon-delete.svg"
import Modal from 'react-modal';
import { useAlert } from "src/hooks/alert/AlertContext";
import { checkBoundingBoxContainsIn } from "src/utils/geoUtils";

import importingAreas from "./areas.json"
import importingSubAreas from "./subareas.json"


const TempAreasInput = () => {

  const { showAlert } = useAlert()

  const [areas, setAreas] = useState()
  const [currentArea, setCurrentArea] = useState()
  const [subAreas, setSubAreas] = useState()

  const [modalOpened, setModalOpened] = useState()
  const [modalPart, setModalPart] = useState()
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadedData, setModalLoadedData] = useState()

  const subAreasSnapshotRef = useRef()

  const closeModal = () => {
    setModalOpened(false)
  }

  const handleDeleteSubArea = (subArea) => {
    deleteDoc(doc(firestore, `areas/${currentArea.name}/subs/${subArea.name}`));
  }

  const handleDeleteArea = (area) => {
    if (subAreas.length > 0) {
      showAlert(true, "Please delete all sub areas first")
    } else {
      deleteDoc(doc(firestore, `areas/${area.name}`))
    }
  }

  const handleAddNew = () => {
    closeModal()
    const areaName = modalLoadedData.mainText
    if (modalPart === 'area') {
      if (areas.some(area => area.name.toLowerCase() === areaName.toLowerCase())) {
        showAlert(true, "Already added.")
      } else {
        const addressComponent = modalLoadedData.components.components.find(comp => comp.text.toLowerCase() === areaName.toLowerCase())
        if (!addressComponent || addressComponent.bbox === undefined) {
          console.log("invalid area input")
          console.log(modalLoadedData.components.components)
          console.log(areaName)
          showAlert(true, "Invalid input : Reason : A")
        } else {
          let order = 0
          if (areas && areas.length > 0) {
            order = areas[areas.length - 1].order + 1
          }
          const data = {
            local: areaName,
            order
          }
          data.state = modalLoadedData.components.state.short
          if (areaName.toLowerCase() === modalLoadedData.components.state.long.toLowerCase()
            || areaName.toLowerCase() === modalLoadedData.components.state.short.toLowerCase()) {
            data.isState = true
          }
          data.component = addressComponent
          setDoc(doc(firestore, `areas/${areaName}`), data).then(() => {
          })
        }
      }
    } else {
      if (currentArea.state !== modalLoadedData.components.state.short) {
        showAlert(true, "Invalid input : Reason : A1")
      } else {
        const addressComponent = modalLoadedData.components.components.find(comp => comp.text.toLowerCase() === areaName.toLowerCase())
        if (!addressComponent || addressComponent.bbox === undefined) {
          console.log("invalid area input : bbox undefined", addressComponent)
          showAlert(true, "Invalid input : Reason : A2")
        } else {
          if (subAreas.some(subArea => subArea.name.toLowerCase() === areaName.toLowerCase())) {
            showAlert(true, "Already added")
          } else {
            // const boundingBox = addressComponent.bbox
            // if (!checkBoundingBoxContainsIn(currentArea.component.bbox, boundingBox)) {
            //   console.log(currentArea.component.bbox, boundingBox)
            //   showAlert(true, `Invalid subarea in ${currentArea.name}`)
            // } else {
            let order = 0
            if (subAreas && subAreas.length > 0) {
              order = subAreas[subAreas.length - 1].order + 1
            }
            setDoc(doc(firestore, `areas/${currentArea.name}/subs/${areaName}`), {
              order,
              component: addressComponent
            })
            // }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (currentArea) {
      if (subAreasSnapshotRef.current) {
        subAreasSnapshotRef.current()
      }
      setSubAreas()

      subAreasSnapshotRef.current = onSnapshot(
        query(collection(firestore, `areas/${currentArea.name}/subs`), orderBy("order")),
        docsSnapshot => {
          const d = [];
          docsSnapshot.forEach((doc) => {
            d.push({
              name: doc.id,
              ...doc.data()
            });
          });
          setSubAreas(d)
        }
      )
    }
  }, [currentArea])

  useEffect(() => {
    if (areas && areas.length > 0) {
      setCurrentArea(areas[areas.length - 1])
    }
  }, [areas?.length])

  useEffect(() => {
    const areasSnap = onSnapshot(
      query(collection(firestore, "areas"), orderBy("order")),
      docsSnapshot => {
        const d = [];
        docsSnapshot.forEach((doc) => {
          d.push({
            name: doc.id,
            ...doc.data()
          });
        });
        setAreas(d)
        setCurrentArea(d[0])
      }
    )

    return () => {
      areasSnap()
      subAreasSnapshotRef.current?.()
    }
  }, [])


  const handleImport = () => {
    // console.log("+++++++ importing area +++++++")
    importingAreas.forEach(importingArea => {
      setDoc(doc(firestore, `areas/${importingArea.name}`), importingArea).then(() => {
        console.log("Done => ", importingArea.name)
      })
    })

    console.log("+++++++ importing subarea +++++++")
    Object.keys(importingSubAreas).forEach(importingArea => {
      importingSubAreas[importingArea].forEach(importingSubArea => {
        setDoc(doc(firestore, `areas/${importingArea}/subs/${importingSubArea.name}`), importingSubArea)
      })
    })
    importingAreas.forEach(importingArea => {
      setDoc(doc(firestore, `areas/${importingArea.name}`), importingArea).then(() => {
        console.log("Done => ", importingArea.name)
      })
    })
  }

  return (
    <div className="App">
      <Flexbox style={styles.container}>
        <TypeFace semiBold style={{ marginTop: 30, marginLeft: 30, marginBottom: 30 }} onClick={handleImport}>
          This is temporal page to input areas & subareas
        </TypeFace>
        <div style={styles.horDivider} />
        <Flexbox row style={styles.contents}>
          <Flexbox style={styles.areaPart}>
            {areas?.map(area => (
              <Flexbox
                row
                key={area.name}
                style={{
                  ...styles.rowView,
                  backgroundColor: area.name === currentArea.name ? '#00f1' : '#0000'
                }}
                onClick={() => {
                  setCurrentArea(area)
                }}
              >
                <TypeFace style={{ cursor: 'pointer' }}>{area.name}</TypeFace>
                <div style={{ flex: 1 }} />
                {area.name === currentArea.name && (
                  <Button onClick={() => handleDeleteArea(area)} style={styles.rowButtons}>
                    <img src={IconDelete} width={12} height={12} />
                  </Button>
                )}
                <TypeFace></TypeFace>
              </Flexbox>
            ))}
            <Flexbox
              row
              style={styles.rowView}
              onClick={() => {
                setModalLoadedData()
                setModalPart('area')
                setModalOpened(true)
              }}
            >
              <TypeFace semiBold style={{ cursor: 'pointer' }}>
                + Add New
              </TypeFace>
            </Flexbox>
          </Flexbox>
          <div style={styles.vertDivider} />
          <Flexbox style={styles.subAreaPart}>
            {subAreas?.map(area => (
              <Flexbox row area key={area.name} style={styles.rowView}>
                <TypeFace style={{ cursor: 'pointer' }}>{area.name}</TypeFace>
                <div style={{ flex: 1 }} />
                {/* <Button style={styles.rowButtons}>
                  <img src={IconEdit} width={12} height={12} />
                </Button> */}
                <Button onClick={() => {
                  handleDeleteSubArea(area)
                }} style={styles.rowButtons}>
                  <img src={IconDelete} width={12} height={12} />
                </Button>
              </Flexbox>
            ))}
            <Flexbox
              row
              style={styles.rowView}
              onClick={() => {
                setModalLoadedData()
                setModalPart('subarea')
                setModalOpened(true)
              }}
            >
              <TypeFace semiBold style={{ cursor: 'pointer' }}>
                + Add New
              </TypeFace>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <Modal
        isOpen={modalOpened}
        onRequestClose={closeModal}
        className="mymodal"
        overlayClassName="myoverlay"
      >
        <Flexbox style={styles.modalContainer}>
          <TypeFace bold size={18}>
            Add new {modalPart} {modalPart === 'subarea' ? `in ${currentArea.name}` : ""}
          </TypeFace>
          <PlacesAutocomplete
            style={styles.modalInput}
            isAreaInput
            onStartLoading={() => {
              setModalLoading(true)
              console.log("Loading started")
            }}
            onSelectAddress={data => {
              setModalLoading(false)
              console.log("Adding => ", data.mainText, data.components.state.short)
              setModalLoadedData(data)
            }}
          />
          <Flexbox row style={styles.modalButtons}>
            <Button disabled={modalLoading || modalLoadedData === undefined} primary onClick={handleAddNew} style={styles.modalButton}>
              Add
            </Button>
            <Button secondary onClick={closeModal} style={styles.loginButton} style={styles.modalButton}>
              Cancel
            </Button>
          </Flexbox>
        </Flexbox>
      </Modal>
    </div>
  )
}

export default TempAreasInput

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    alignItems: 'flex-start'
  },
  horDivider: {
    height: 1,
    width: '100%',
    backgroundColor: '#dcdcdc',
  },
  vertDivider: {
    height: '100%',
    width: 1,
    backgroundColor: '#dcdcdc',
  },
  contents: {
    flex: 1,
    width: '100%',
  },
  areaPart: {
    width: 500,
    height: '100%',
    justifyContent: 'flex-start'
  },
  subAreaPart: {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start'
  },
  areaPlaceInput: {
    width: '100%',
  },
  subAreaPlaceInput: {
    width: '100%',
  },
  rowView: {
    width: 'calc(100% - 40px)',
    paddingLeft: 20,
    paddingRight: 20,
    height: 44,
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #dfdfdf',
    cursor: 'pointer'
  },
  rowButtons: {
    width: 40,
    height: 40,
    backgroundColor: '#0000'
  },
  modalContainer: {
    width: 600,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 40
  },
  modalInput: {
    marginTop: 20,
    width: '100%',
  },
  modalButtons: {
    marginTop: 50,
    width: '100%',
    gap: 10
  },
  modalButton: {
    height: 44,
    flex: 1,
  }
}