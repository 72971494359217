import Flexbox from 'src/components/wrapper/Flexbox'

const ListingDetailsDownloadView = () => {
  return (
    <Flexbox row style={styles.container}>
      <img src={require('src/assets/images/appstore.png')} style={styles.button} alt="app store" />
      <img src={require('src/assets/images/googleplay.png')} style={styles.button} alt="google play store" />
    </Flexbox>
  )
}

export default ListingDetailsDownloadView

const styles = {
  container: {
    width: "100%",
    marginTop: 20,
    justifyContent: "space-between"
  },
  button: {
    width: "48%",
    cursor: "pointer"
  }
}