import React from 'react';

const TextInput = React.forwardRef((props, ref) => {

  const {style, ...rest} = props

  return (
    <input
      ref={ref}
      style={{
        height: 44,
        backgroundColor: 'white',
        border: '1px solid #cfcfcf',
        padding: '0 12px',
        borderRadius: 5,
        boxSizing: 'border-box',
        ...style,
      }}
      {...rest}
    />
  )
})

export default TextInput