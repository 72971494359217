import React from 'react'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { Colors } from 'src/theme/Colors'
import IconAvatarPlaceholder from 'src/assets/images/icons/icon-avatar-placeholder.svg'
import { useNavigate } from 'react-router-dom'
import MeetNowButton from 'src/components/button/MeetNowButton'
import { roleLabelFromValue } from 'src/model/types'
import IconCalendarGray from 'src/assets/images/icons/icon-calendar-gray.svg'
import moment from 'moment'
import useMediaQuery from 'src/hooks/useMediaQuery'
import { userRoles,UserRoleValue } from 'src/model/types'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import { useAlert } from 'src/hooks/alert/AlertContext';

const ListingDetailsAgencyInformationPanel = ({
  agency,
  teams,
  withMessageButton,
  isMeetLaterAlreadySetup,
  meetLaterWhen,
  messagingThreadId,
  handleOpenMessageRoom,
  handleOpenMeetLater
}) => {

  const { isMobile } = useMediaQuery()
  const navigate = useNavigate()
  const { userProfile } = useFirestore()
  const { showAlert } = useAlert()

  const handleOpenMessage = () => {
    if (userProfile?.profileBlocked) {
      showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile.')
    }
    else {
      if (userProfile?.role === UserRoleValue.ManageMent) {
        if (userProfile.profileStatus !== 'approved') {
          showAlert(true, 'Listmeet has not approved your company yet.')
        }
        else {
          return (
            navigate('/manage/messages')
          )
        }
      }
      else {
        if (userProfile?.agencyApproved === false) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Listmeet has not approved your brokerage yet.')
          }
          else {
            showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.')
          }
        } else if (userProfile?.suspended === true) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Your brokerage has been suspended.')
          }
          else {
            showAlert(true, 'Contact your broker to reinstate your account.')
          }
        }
        else {
          return (
            navigate('/manage/messages')
          )
        }
      }
    }

  }

  return (
    <Flexbox style={styles.rightInfoPanel}>
      <TypeFace semiBold size={12}>
        Listed by
      </TypeFace>
      {teams?.map((creator, index) => (
        <Flexbox key={creator.userId} row style={{ marginTop: 10, gap: 10 }} onClick={() => {
          navigate(`/profile/${creator.userId}`)
        }}>
          {!!creator?.photo?.url ? (
            <img src={creator.photo.url} style={styles.avatar} alt="avatar" />
          ) : (
            <img src={IconAvatarPlaceholder} width={50} height={50} alt="avatar-placeholder" />
          )}
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace semiBold size={11} color={Colors.primary}>
              {creator?.name}
            </TypeFace>
            <TypeFace medium size={11} style={{ textAlign: 'start' }}>
              {roleLabelFromValue(creator?.role)}
            </TypeFace>
            <TypeFace semiBold size={14} color={Colors.gray1}>
              {agency?.name}
            </TypeFace>
          </Flexbox>
        </Flexbox>
      ))}
      <Flexbox style={{ width: '100%', gap: 10, marginTop: 10, flexDirection: isMobile ? 'row' : 'column' }}>
         <MeetNowButton
          smallMode
          style={{
            width: isMobile ? 'unset' : '100%',
            flex: isMobile ? 1.4 : 'unset',
          }}
        />
        {!isMeetLaterAlreadySetup && (
          <Button secondary onClick={handleOpenMeetLater} style={{
            flex: isMobile ? 1 : 'unset',
            width: isMobile ? 'unset' : '100%',
            height: isMobile ? 45 : 50,
          }} textSize={16}>
            Meet Later
          </Button>
        )}
      </Flexbox>
      {isMeetLaterAlreadySetup && (
        <>
          {meetLaterWhen && (
            <>
              <TypeFace size={12} style={{ marginTop: 20, alignSelf: 'flex-start' }}>
                Schedule for a later day {moment(new Date(meetLaterWhen)).format('h:mm a, M/D/YYYY').toUpperCase()}
              </TypeFace>
              <Flexbox row style={{ width: 'calc(100% - 32px)', height: 72, border: '1px solid #D9D9D9', marginTop: 10, gap: 10, alignItems: 'center', paddingLeft: 16, paddingRight: 16 }}>
                <img src={IconCalendarGray} width={24} height={24} alt="calendar-icon" />
                <Flexbox style={{ alignItems: 'flex-start' }}>
                  <TypeFace bold size={14}>
                    {moment(new Date(meetLaterWhen)).format('ddd, MMM D').toUpperCase()}
                  </TypeFace>
                  <TypeFace medium size={14}>
                    {moment(new Date(meetLaterWhen)).format('h:mm a').toUpperCase()}
                  </TypeFace>
                </Flexbox>
                <div style={{ flex: 1 }} />
                <Button disabled={messagingThreadId === undefined} secondary style={styles.messageButton} textSize={12} onClick={handleOpenMessageRoom}>
                  Message
                </Button>
              </Flexbox>
            </>
          )}
        </>
      )}
      {withMessageButton && (
        <Button onClick={handleOpenMessage} secondary style={styles.contactButton} textColor={Colors.primary}>
          MESSAGE
        </Button>
      )}
    </Flexbox>
  )
}

export default ListingDetailsAgencyInformationPanel

const styles = {
  rightInfoPanel: {
    width: 'calc(100% - 30px)',
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 5,
    alignItems: 'flex-start',
    boxShadow: '0px 1px 9px #0004'
  },
  contactButton: {
    marginTop: 20,
    width: '100%',
    height: 50
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  meetLater: {
    height: 50,
    flex: 1,
  },
  messageButton: {
    height: 40,
    paddingLeft:10,
    paddingRight: 10,
  },
}