import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { validateEmailAddress } from "src/utils/strings";
import { auth, firestore } from "src/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useAlert } from "src/hooks/alert/AlertContext";
import Loading from "react-fullscreen-loading";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { UserRoleValue, userRoles } from "src/model/types";
import { Alert, Button, Card, Flex, Form, Input, InputNumber, Select, Typography } from "antd";
import Logo from "src/assets/images/lm-lg-logo.png";
import {
  AuditOutlined,
  FontSizeOutlined,
  LockOutlined,
  LoginOutlined,
  MailOutlined,
  MobileOutlined,
  NumberOutlined,
  PhoneOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const Login = () => {
  const { userProfile } = useFirestore();
  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mode, setMode] = useState(0);

  const [selectedRoleOption, setSelectedRoleOption] = useState();
  const [sentResetPassword, setSentResetPassword] = useState(false);

  async function handleCreateProfile(values) {
    setLoading(true);
    try {
      if (userProfile && userProfile.role === "customer") {
        signOut(auth);
      }
      if (selectedRoleOption === UserRoleValue.Broker) {
        let docSnap = await getDoc(doc(firestore, `brokers/${values.license}`));
        if (docSnap.exists()) {
          setError("Broker with this license already exists");
          setLoading(false);
          return;
        } else {
          let res = await createUserWithEmailAndPassword(auth, values.email, values.password);
          const createdUserId = res.user.uid;
          const profileDoc = doc(firestore, "profiles", createdUserId);
          const userProfileData = {
            userId: createdUserId,
            created: new Date().getTime(),
            name: values.name,
            email: values.email,
            profileCreated: true,
            nameL: values.name.toLowerCase(),
            emailL: values.email.toLowerCase(),
            role: selectedRoleOption,
            agency: values.license,
            agencyApproved: false,
          };
          await setDoc(profileDoc, userProfileData);

          const agencyDoc = {
            id: values.license,
            name: values.brokeragename,
            creator: createdUserId,
            status: "submitted",
            brokerName: values.name,
            brokerEmail: values.email,
            createdAt: new Date().getTime(),
          };
          await setDoc(doc(firestore, `agencies/${values.license}`), agencyDoc);
          setLoading(false);
          navigate("/manage/settings");
        }
      } else {
        let res = await createUserWithEmailAndPassword(auth, values.email, values.password);
        const createdUserId = res.user.uid;
        const profileDoc = doc(firestore, "profiles", createdUserId);
        const userProfileData = {
          userId: createdUserId,
          created: new Date().getTime(),
          name: values.name,
          email: values.email,
          profileCreated: true,
          nameL: values.name.toLowerCase(),
          emailL: values.email.toLowerCase(),
          role: selectedRoleOption,
        };

        if (selectedRoleOption === UserRoleValue.ManageMent) {
          await setDoc(profileDoc, {
            ...userProfileData,
            contact: {
              name: values.companycontactname,
              email: values.companycontactemail,
              number: values.companycontactphone,
            },
            companyApproved: false,
            phone: values.phone,
          });
        } else if (selectedRoleOption == UserRoleValue.SalesPerson || selectedRoleOption == UserRoleValue.Agent) {
          await setDoc(profileDoc, {
            ...userProfileData,
            licenseNumber: values.license,
            agencyApproved: false,
          });
        } else {
          await setDoc(profileDoc, userProfileData);
        }

        setLoading(false);
        if (selectedRoleOption === UserRoleValue.Customer) {
          navigate("/");
        } else {
          navigate("/manage");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.code === "auth/email-already-in-use") {
        setError("Email already in use. Please login instead.");
      } else {
        setError("Create account failed. Please try again later.");
      }
    }
  }

  async function handleLogin(values) {
    setLoading(true);
    try {
      if (!validateEmailAddress(values.identifier)) {
        let q = query(collection(firestore, "profiles"), where("username", "==", values.identifier));
        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        if (data.length > 0) {
          values.email = data[0].email;
        } else {
          setLoading(false);
          setError("The username/email or password is incorrect. Please try again.");
          return false;
        }
      } else {
        values.email = values.identifier;
      }
      let authCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      if (authCredential) {
        let profileDoc = await getDoc(doc(firestore, `profiles/${authCredential.user.uid}`));
        const myProfile = profileDoc.data();
        setLoading(false);
        if (myProfile.role === "admin" || myProfile.role === "manager") {
          navigate("/admin/dashboard");
        } else if (myProfile.role !== UserRoleValue.Customer) {
          navigate("/manage/settings");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      setError("Oops! There was a problem logging in. Please try again.");
      setLoading(false);
    }
  }

  const handleResetPassword = ({ email }) => {
    setLoading(true);
    if (userProfile && userProfile.role === "customer") {
      signOut(auth);
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setLoading(false);
        setSentResetPassword(true);
      })
      .catch((err) => {
        if (err.code === "auth/user-not-found") {
          setError("User not found. please sign up.");
        } else {
          setError("Reset password failed. Please try again later.");
        }
        setLoading(false);
      });
  };

  const requiredRules = (string) => {
    return [
      {
        required: true,
        message: `Please enter your ${string}`,
      },
    ];
  };

  const emailRules = [
    {
      required: true,
      message: "Please enter your email address",
    },
    {
      type: "email",
      message: "Please enter a valid email address",
    },
  ];

  const passwordRules = [
    {
      required: true,
      message: "Please enter your password",
    },
  ];

  if (userProfile) {
    if (userProfile.role === "admin" || userProfile.role === "manager") {
      if (location.state?.from) {
        return <Navigate to={location.state.from} />;
      } else {
        return <Navigate to="/admin/dashboard" />;
      }
    } else if (userProfile.role !== UserRoleValue.Customer) {
      if (location.state?.from) {
        return <Navigate to={location.state.from} />;
      } else {
        return <Navigate to="/manage/settings" />;
      }
    } else {
      return <Navigate to="/" />;
    }
  }

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{
        height: "100vh",
        width: "100vw",
        background: "url('/background.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      gap={20}
    >
      <Link to="/" style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
        <img src={Logo} height={40} />
      </Link>
      <Card style={{ padding: 10 }}>
        {mode === 0 && (
          <Form autoComplete="off" onFinish={handleLogin} style={{ width: "100%" }}>
            <Flex vertical gap={10} style={{ width: 300 }}>
              <Flex vertical style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 20 }}>Welcome Back </Text>
                <Text>Login and take full advantage of ListMeet's features </Text>
              </Flex>
              {error && <Alert message={error} type="error" />}
              <Form.Item name="identifier" rules={requiredRules("email address or username")}>
                <Input
                  size="large"
                  placeholder="Email Address or Username"
                  prefix={<UserOutlined />}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item name="password" rules={passwordRules}>
                <Input.Password size="large" placeholder="Password" prefix={<LockOutlined />} disabled={loading} />
              </Form.Item>
              <Flex flex={1} justify="flex-end">
                <Button
                  type="link"
                  onClick={() => {
                    setMode(2);
                    setSentResetPassword(false);
                  }}
                >
                  Forgot password ?
                </Button>
              </Flex>
              <Form.Item style={{ marginBottom: 10 }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  icon={<LoginOutlined />}
                  loading={loading}
                >
                  LOGIN
                </Button>
              </Form.Item>
              <Flex align="center" justify="flex-end">
                <Text type="secondary">Don't have account ?</Text>
                <Button
                  type="link"
                  onClick={() => {
                    setMode(1);
                  }}
                >
                  Create Account
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
        {mode === 1 && (
          <Form autoComplete="off" onFinish={handleCreateProfile} style={{ width: "100%" }}>
            <Flex vertical gap={10} style={{ width: 300 }}>
              <Flex vertical style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 20 }}>Create your account</Text>
                <Text>Let's get your profile set up.</Text>
              </Flex>
              {error && <Alert message={error} type="error" />}
              <Form.Item name="role" rules={requiredRules("name")} style={{ marginBottom: 20 }}>
                <Select
                  size="large"
                  placeholder="Select a Role"
                  disabled={loading}
                  options={userRoles}
                  onChange={(v) => setSelectedRoleOption(v)}
                />
              </Form.Item>

              <Form.Item name="name" rules={requiredRules("name")}>
                <Input
                  size="large"
                  placeholder={selectedRoleOption === UserRoleValue.ManageMent ? "Company name" : "Full name"}
                  prefix={<UserOutlined />}
                  disabled={loading}
                />
              </Form.Item>

              <Form.Item name="email" rules={emailRules}>
                <Input
                  size="large"
                  placeholder={selectedRoleOption === UserRoleValue.ManageMent ? "Company email" : "Email address"}
                  prefix={<MailOutlined />}
                  disabled={loading}
                />
              </Form.Item>

              {selectedRoleOption === UserRoleValue.Broker && (
                <Form.Item name="brokeragename" rules={requiredRules("Brokager Name")}>
                  <Input size="large" placeholder={"Brokager Name"} prefix={<AuditOutlined />} disabled={loading} />
                </Form.Item>
              )}

              {(selectedRoleOption === UserRoleValue.Broker ||
                selectedRoleOption === UserRoleValue.Agent ||
                selectedRoleOption === UserRoleValue.SalesPerson) && (
                <Form.Item name="license" rules={requiredRules("License Number")}>
                  <InputNumber
                    size="large"
                    type="number"
                    style={{ width: "100%" }}
                    placeholder={"License Number"}
                    prefix={<NumberOutlined />}
                    disabled={loading}
                  />
                </Form.Item>
              )}

              {selectedRoleOption === UserRoleValue.ManageMent && (
                <>
                  <Form.Item name="phone" rules={requiredRules("phone")}>
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      size="large"
                      placeholder="Company phone number"
                      prefix={<PhoneOutlined />}
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item name="companycontactname" rules={requiredRules("name")}>
                    <Input
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Point of Contact Full Name"
                      prefix={<FontSizeOutlined />}
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item name="companycontactemail" rules={emailRules}>
                    <Input
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Point of Contact Email"
                      prefix={<MailOutlined />}
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item name="companycontactphone" rules={requiredRules("phone")}>
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      type="number"
                      placeholder="Point of Contact Phone"
                      prefix={<MobileOutlined />}
                      disabled={loading}
                    />
                  </Form.Item>
                </>
              )}

              <Form.Item name="password" rules={passwordRules}>
                <Input.Password size="large" placeholder="Password" prefix={<LockOutlined />} disabled={loading} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 10 }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  icon={<LoginOutlined />}
                  loading={loading}
                >
                  Create Account
                </Button>
              </Form.Item>
              <Flex align="center" justify="flex-end">
                <Text type="secondary">Already have account ?</Text>
                <Button
                  type="link"
                  onClick={() => {
                    setMode(0);
                  }}
                >
                  Login
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
        {mode === 2 && (
          <Form autoComplete="off" onFinish={handleResetPassword} style={{ width: "100%" }}>
            <Flex vertical gap={10} style={{ width: 300 }}>
              <Flex vertical style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 20 }}>Reset Password </Text>
                <Text>Send a password reset email</Text>
              </Flex>
              {error && <Alert message={error} type="error" />}
              <Form.Item name="email" rules={emailRules}>
                <Input size="large" placeholder="Email Address" prefix={<UserOutlined />} disabled={loading} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 10 }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  icon={<SendOutlined />}
                  loading={loading}
                >
                  {sentResetPassword ? "Send Again" : "Send"}
                </Button>
              </Form.Item>
              <Flex align="center" justify="flex-end">
                <Text type="secondary">Already have an account?</Text>
                <Button
                  type="link"
                  onClick={() => {
                    setMode(0);
                  }}
                >
                  Login
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}

        {loading && <Loading loading background="#0005" loaderColor="white" />}
      </Card>
    </Flex>
  );
};

export default Login;
