import { Fragment, useEffect, useState } from "react"
import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"

const OptionSelectButton = (props) => {

  const [selected, setSelected] = useState(props.selected)
  const { isMobile } = useMediaQuery()
  const options = props.options ?? ["No", "Yes"]

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  return (
    <Flexbox row style={{ ...styles.container,  ...props.style }}>
      {options.map((option, index) => (
        <Fragment key={index}>
          <Flexbox onClick={() => {
            setSelected(index)
            props.onSelected(index)
          }} style={{
            ...styles.item,
            backgroundColor: selected === index ? Colors.optionBack : 'white'
          }}>
            <TypeFace size={isMobile ? 12 : 14}>
              {option}
            </TypeFace>
          </Flexbox>
          {index < options.length - 1 && <div key={`sep-${index}`} style={styles.separator} />}
        </Fragment>
      ))}</Flexbox>
  )
}

export default OptionSelectButton

const styles = {
  container: {
    height: 40,
    border: '1px solid #dfdfdf',
    borderRadius: 0
  },
  item: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px'
  },
  separator: {
    backgroundColor: '#dfdfdf',
    width: 1,
    height: '100%'
  }
}