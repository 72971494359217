import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from 'src/components/typography/Typefaces';

const About = () => {
  const { isMobile } =  useMediaQuery()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                About ListMeet
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Welcome to ListMeet, where innovation meets convenience in the world of real estate! We redefine the home-buying experience by introducing on-demand showings, putting the power of exploring your dream home right at your fingertips.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                At ListMeet, we understand that your time is valuable, and the traditional process of scheduling showings can be both time-consuming and limiting. That&#39;s why we&#39;ve revolutionized the way you experience property tours. With our on-demand showings, you have the flexibility to explore homes at your own pace, whenever it suits you.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Here&#39;s how it works: simply browse through our extensive listings of exceptional properties. When you find a home that catches your eye, request an on-demand showing with just a few taps on your device. Our dedicated team of real estate professionals will swiftly arrange a convenient time for you to explore the property in person within 10 minutes.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Our commitment to excellence extends beyond the innovative technology we employ. We take pride in curating a diverse portfolio of properties, ensuring that every listing meets the highest standards of quality and appeal. From cozy starter homes to luxurious estates, we have a home to match every taste and lifestyle.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Join us at ListMeet and embrace a new era of home exploration. Discover the ease and convenience of on-demand showings, making your journey to finding the perfect home an experience like never before.
              </TypeFace>              
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default About

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "16px 0",
    textAlign: "left",
    lineHeight: 1.6
  },
}