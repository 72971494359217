import React, { useState } from "react"
import { useNavigate } from 'react-router-dom';
import TypeFace from "./Typefaces"

const Link = ({ url, style, children, onMouseEnter, onMouseLeave, onClick, ...otherProps }) => {
  const navigate = useNavigate()
  
  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => {
    setHover(true)
    onMouseEnter && onMouseEnter()
  }

  const handleMouseLeave = () => {
    setHover(false)
    onMouseLeave && onMouseLeave()
  }

  const handleClick = () => {
    if (url) navigate(url)
    onClick && onClick()
  }

  const newStyle = {
    ...style,
    cursor: "pointer"
  }

  if (hover)
    newStyle.color = "rgb(102, 146, 175)"

  return (
    <TypeFace
      {...otherProps}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={newStyle}
    >
      {children}
    </TypeFace>
  )
}

export default Link