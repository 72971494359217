import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import { useEffect, useRef, useState } from "react"
import MyDraftPropertyCell from "./MyDraftPropertyCell"
import Button from "../button/Button"

const MyDraftProperties = ({
  draftListings,
  section
}) => {

  const [showListings, setShowListings] = useState(false)
  const unsubscribeRef = useRef()

  useEffect(() => {
    return () => {
      unsubscribeRef.current?.()
    }
  }, []);

  return (
    <Flexbox style={styles.container}>
      <Flexbox row style={styles.statusRow}>
        <Flexbox style={styles.draftBadge}>
          <TypeFace bold size={11}>
            DRAFT
          </TypeFace>
        </Flexbox>
        <TypeFace>
          <TypeFace bold>
            {draftListings?.length}
          </TypeFace>
          {' '}draft {section === 0 ? 'sale' : section === 1 ? 'rental' : 'listing'}{draftListings?.length > 1 ? 's' : ''}
        </TypeFace>
        <div style={{ flex: 1 }} />
        <Button onClick={() => setShowListings(!showListings)}>
          {showListings ? 'Hide' : 'Show'}
        </Button>
      </Flexbox>
      <div style={styles.horSeparator} />
      {showListings && (
        <Flexbox style={styles.contentRow}>
          {draftListings?.map((listing, index) => (
            <MyDraftPropertyCell
              listing={listing}
              key={`draft-${index}-${listing.id}`}
              editAvailable={section !== 2}
            />
          ))}
        </Flexbox>
      )}
    </Flexbox>
  )
}

export default MyDraftProperties

const styles = {
  container: {
    boxShadow: '0 0 3px #3151B73F',
    backgroundColor: Colors.background1,
    width: '100%',
    marginBottom: 20,
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  },
  statusRow: {
    width: '100%',
    height: 60,
    backgroundColor: 'white',
    gap: 20
  },
  contentRow: {
    width: 'calc(100% - 40px)',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  draftBadge: {
    backgroundColor: Colors.gray2,
    width: 60,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowButtons: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  address: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 40
  },
  schedules: {
    width: '100%',
    alignItems: 'flex-start',
  },
  scheduleRow: {
    alignItems: 'center',
    height: 44,
    marginLeft: 20,
    gap: 10
  }
}