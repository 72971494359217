import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Flexbox from 'src/components/wrapper/Flexbox';
import useMediaQuery from 'src/hooks/useMediaQuery';
import LoaderGif from 'src/assets/images/loader3.gif';
import TypeFace from 'src/components/typography/Typefaces';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, firestore, storage } from 'src/firebase';
import IconAvatarPlaceholder from 'src/assets/images/icons/icon-avatar-placeholder.svg'
import { Colors } from 'src/theme/Colors';
import { getAlgoliaObjects } from 'src/algolia';
import CardSearchResultItem from 'src/components/home/search/CardSearchResultItem';
import HomeFooter from 'src/components/home/HomeFooter';
import Button from 'src/components/button/Button';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import HomeBlueMenuBar from 'src/components/home/HomeBlueMenuBar';
import CustomerScheduleEntry from '../schedules/components/CustomerScheduleEntry';
import TextInput from 'src/components/input/TextInput';
import shortid from 'shortid';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useAlert } from 'src/hooks/alert/AlertContext';
import ConnectProfileModal from 'src/components/auth/ConnectProfileModal';

const CustomerProfile = () => {

  const { isMobile } =  useMediaQuery()
  const { userProfile, savedPropertyIds } = useFirestore()
  const { showAlert } = useAlert()
  const { mode } = useParams()
  
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [profileAgency, setProfileAgency] = useState()
  const [selectedSection, setSelectedSection] = useState(mode === 'saved' ? 1 : 0)
  const [schedules, setSchedules] = useState()
  const [savedHits, saveSavedHits] = useState()

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)
  const [isEditingMode, setIsEditingMode] = useState(false)

  const [newName, setNewName] = useState(userProfile?.name ?? '')
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phone ?? '')

  const [isCreatingProfile, setIsCreatingProfile] = useState(false)
  
  const handleSignout = () => {
    showAlert(true, 'Are you sure you want to sign out?', [
      {
        title: "Sign out",
        onClick: () => {
          showAlert(false)
          auth.signOut().then(() => {
            navigate('/')
          })    
        }
      },
      {
        title: "Cancel",
        onClick: () => {
          showAlert(false)
        }
      },
    ],
    0,
    userProfile?.profileCreated !== true ? 'You will lose your schedules and saved properties once you sign out. Please create profile to save your data before sign out.' : "")
  }

  
  const handleEdit = () => {
    setIsEditingMode(true)
  }

  const handleSaveChange = () => {
    if (newName === "") {
      showAlert("Please enter your name")
      return
    }

    updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
      name: newName,
      phone: phoneNumber,
      updated: new Date().getTime()
    }).then(() => {
      setIsEditingMode(false)
    })
  }

  const loadCustomerSchedules = () => {
    const q = query(collection(firestore, 'schedules'), where("customer.id", "==", userProfile.userId))
    getDocs(q).then(docsSnapshot => {
      let schedulesList = []
      docsSnapshot.forEach(doc => {
        schedulesList.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setSchedules(schedulesList)
    })
  }

  const uploadFileProcess = (file, kind) => {
    setIsUploadingPhoto(true)
    const fileId = shortid.generate()
    const storageRef = ref(storage,`/profiles/${userProfile.userId}/${fileId}/`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        console.log("uploading file => ", percent)
      },
      err => {
        console.log("uploading file error => ", err)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(url => {
          const photoData = {
            id: fileId,
            url,
          }
          updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
            photo: photoData
          })
          setIsUploadingPhoto(false)
        })
      }
    )
  }

  const photoFileSelectHandler = (e) => {
    const file = e.target.files[0]
    uploadFileProcess(file, 'photo')
  }

  const loadSavedObjects = () => {
    if (savedPropertyIds) {
      getAlgoliaObjects(savedPropertyIds.map(data => data.algoliaID)).then(res => {
        saveSavedHits(res.results)
      })
    } else {
      saveSavedHits([])
    }
  }

  const handleCreateProfile = () => {
    setIsCreatingProfile(true)
  }

  const upcomingSchedules = useMemo(() => {
    return schedules?.filter(schedule => {
      return schedule.customer.when > new Date().getTime()
    }).sort((schedule1, schedule2) => schedule1.customer.when - schedule2.customer.when)
  }, [schedules])

  const pastSchedules = useMemo(() => {
    return schedules?.filter(schedule => schedule.customer.when < new Date().getTime()).sort((schedule1, schedule2) => schedule2.customer.when - schedule1.customer.when)
  }, [schedules])

  useEffect(() => {
    if (userProfile?.userId) {
      loadCustomerSchedules()
    }
  }, [userProfile?.userId])

  useEffect(() => {
    loadSavedObjects()
  }, [savedPropertyIds])

  useEffect(() => {
    setNewName(userProfile?.name ?? "")
    setPhoneNumber(userProfile?.phone ?? "")
  }, [userProfile])

  useEffect(() => {
    setSelectedSection(mode === 'saved' ? 1 : 0)
  }, [mode])

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {!isMobile && <HomeBlueMenuBar />}
          <Flexbox style={{ width: '100%', marginBottom: isMobile ? 20 : 80 }}>
            {isLoading ? (
              <img src={LoaderGif} style={{ width: 30, height: 30, marginTop: 40 }} />
            ) : (
              <Flexbox style={styles.container}>
                <Flexbox style={{
                  ...styles.profileContainer,
                  flexDirection: isMobile ? 'column' : 'row'
                }}>
                  <Flexbox>
                    {!!userProfile?.photo?.url ? (
                      <img src={userProfile.photo.url} style={{
                        ...styles.avatar,
                        width: isMobile ? 60 : 200,
                        height: isMobile ? 60 : 200
                      }}/>
                    ) : (
                      <img src={IconAvatarPlaceholder} width={isMobile ? 60 : 200} height={isMobile ? 60 : 200} style={{
                        ...styles.avatar,
                        width: isMobile ? 60 : 200,
                        height: isMobile ? 60 : 200
                      }} />
                    )}
                    {isEditingMode && (
                      <Flexbox row style={{ gap: 10, marginTop: 20 }}>
                        <input 
                          id='selectPhoto'
                          hidden
                          type="file"
                          onChange={photoFileSelectHandler}
                          accept="image/jpeg, image/png"
                        />
                        <Button secondary style={styles.avatarButtons} onClick={() => {
                          if (isUploadingPhoto) {
                            return
                          }
                          document.getElementById("selectPhoto").click()
                        }}>
                          UPLOAD
                        </Button>
                        <Button secondary style={styles.avatarButtons} onClick={() => {
                          updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
                            photo: {
                              id: "",
                              url: ""
                            }
                          })
                        }}>
                          REMOVE
                        </Button>
                      </Flexbox>
                    )}
                  </Flexbox>
                  
                  <Flexbox style={{ alignItems: 'flex-start', width: 300 }}>
                    {!isEditingMode ? (
                      <TypeFace size={isMobile ? 28 : 21}>
                        {userProfile?.name}
                      </TypeFace>
                    ) : (
                      <>
                        <TypeFace semiBold size={12}>
                          FULL NAME
                        </TypeFace>
                        <TextInput
                          value={newName}
                          onChange={e => setNewName(e.target.value)}
                          style={styles.textInputBasic}
                        />
                      </>
                    )}
                    {!isEditingMode ? (
                      <TypeFace size={isMobile ? 24 : 18}  style={{ marginTop: 10 }}>
                        {userProfile?.email}
                      </TypeFace>
                    ) : (
                      <>
                        <div style={{ height: 20 }} />
                        <TypeFace semiBold size={12}>
                          EMAIL
                        </TypeFace>
                        <TextInput
                          disabled
                          value={userProfile?.email}
                          style={styles.textInputBasic}
                        />
                      </>
                    )}
                    {!isEditingMode ? (
                      <>
                        <TypeFace bold size={isMobile ? 23 : 18} style={{ marginTop: 10 }}>
                          {userProfile?.role === 'broker' ? 'Broker' : userProfile?.role === 'customer' ? 'Buyer / Renter' : 'Associate Broker/Licensed Real Estate Salesperson'}
                        </TypeFace>
                        
                        {userProfile?.profileCreated !== true && (
                          <>
                            <TypeFace size={12} style={{ marginTop: 10, textAlign: 'left' }}>
                              Please create a profile to save your schedules and favorite properties safely.
                            </TypeFace>
                            <Button primary style={{ marginTop: 10 }} onClick={handleCreateProfile}>
                              Create profile
                            </Button>
                          </>
                        )}
                        {isMobile && !isEditingMode && !userProfile?.profileBlocked && (
                          <Button secondary style={{ marginTop: 20 }} onClick={handleEdit}>
                            Edit
                          </Button>
                        )}
                        <Button secondary style={{ marginTop: 30 }} onClick={handleSignout}>
                          Sign out
                        </Button>
                        {userProfile?.profileCreated !== true && (
                          <>
                            <TypeFace size={14} style={{ marginTop: 10, textAlign: 'left' }}>
                              You will loose data if you sign out without creating profile.
                            </TypeFace>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ height: 20 }} />
                        <TypeFace semiBold size={12}>
                          PHONE NUMBER
                        </TypeFace>
                        <TextInput
                          value={phoneNumber}
                          onChange={e => setPhoneNumber(e.target.value)}
                          style={styles.textInputBasic}
                          onKeyDown={(event) => {
                            if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                        />
                        <Flexbox row style={{ marginTop: 20, gap: 20 }}>
                          <Button primary style={styles.editProfileActionButton} onClick={handleSaveChange}>
                            SAVE
                          </Button>
                          <Button secondary style={styles.editProfileActionButton} onClick={() => setIsEditingMode(false)}>
                            CANCEL
                          </Button>
                        </Flexbox>
                      </>
                    )}
                  </Flexbox>
                  {!isMobile && !isEditingMode && !userProfile?.profileBlocked && (
                    <Button secondary style={{ marginTop: 20 }} onClick={handleEdit}>
                      Edit
                    </Button>
                  )}
                </Flexbox>
                
                {userProfile?.profileBlocked ? (
                  <>
                    <TypeFace color="red">
                      Your profile is under review, contact support@listmeet.com to reinstate your profile
                    </TypeFace>
                  </>
                ) : (
                  <>
                    <Flexbox row style={styles.contentTab}>
                    <Flexbox style={{ height: 50 }} onClick={() => {
                      setSelectedSection(0)
                    }}>
                      <TypeFace bold size={18}>
                        Schedules
                      </TypeFace>
                      {selectedSection === 0 && (
                        <div style={styles.tabSelectedIndicator} />
                      )}
                    </Flexbox>
                    <Flexbox style={{ height: 50 }} onClick={() => {
                      setSelectedSection(1)
                    }}>
                      <TypeFace bold size={18}>
                        Saved properties
                      </TypeFace>
                      {selectedSection === 1 && (
                        <div style={styles.tabSelectedIndicator} />
                      )}
                    </Flexbox>
                  </Flexbox>
                  {selectedSection === 1 ? (
                    <>
                      {(savedHits?.length ?? 0) === 0 ? (
                      <Flexbox style={{
                        ...styles.propertiesContainer,
                        flexDirection: isMobile ? 'column' : 'row'
                      }}>
                        No saved properties
                      </Flexbox>  
                    ) : (
                      <Flexbox style={{
                        ...styles.propertiesContainer,
                        flexDirection: isMobile ? 'column' : 'row'
                      }}>
                        {savedHits?.map(hit => <CardSearchResultItem hit={hit}/>)}
                      </Flexbox>
                    )}
                    </>
                  ) : (
                    <>
                      {(schedules?.length ?? 0) === 0 ? (
                        <Flexbox style={{
                          ...styles.propertiesContainer,
                          flexDirection: isMobile ? 'column' : 'row'
                        }}>
                          No meeting schedules
                        </Flexbox>  
                      ) : (
                        <Flexbox style={{
                          ...styles.propertiesContainer,
                          flexDirection: 'column'
                        }}>
                          {upcomingSchedules.map((schedule, index) => (
                            <CustomerScheduleEntry key={`schedule-${index}`} schedule={schedule} />
                          ))}
                          <div style={{ height: 80 }} />
                          {pastSchedules.map((schedule, index) => (
                            <CustomerScheduleEntry key={`schedule-${index}`} schedule={schedule} isPast />
                          ))}
                        </Flexbox>
                      )}
                    </>
                  )}
                  </>
                )}
              </Flexbox>
            )}
          </Flexbox>
          <HomeFooter />
        </div>
      </div>
      <ConnectProfileModal
        modalIsOpen={isCreatingProfile}
        closeModal={() => {
          setIsCreatingProfile(false)
        }}
      />
    </div>
  )
}

export default CustomerProfile

const styles = {
  container: {
    width: '90%',
    maxWidth: 1178,
    paddingTop: 30,
    alignItems: 'center'
  },
  profileContainer: {
    gap: 30,
    alignSelf: 'flex-start',
    alignItems: 'flex-start'
  },
  avatar: {
    width: 200,
    height: 200,
    borderRadius: 100,
    alignSelf: 'center'
  },
  avatarButtons: {
    height: 32,
  },
  propertiesContainer: {
    width: '100%',
    alignItems: 'center',
  },
  contentTab: {
    width: '100%',
    height: 105,
    gap: 30,
    justifyContent: 'flex-start',
  },
  tabSelectedIndicator: {
    width: "100%",
    height: 4,
    backgroundColor: Colors.primary,
    marginTop: 10,
  },
  textInputBasic: {
    width: '100%',
    marginTop: 5,
  },
  editProfileActionButton: {
    width: 120,
    height: 36,
    borderRadius: 5,
  },
  bioInput: {
    width: '100%',
    marginTop: 5,
    height: 200
  },
}
