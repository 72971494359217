import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from 'src/components/typography/Typefaces';

const Procedure = () => {
  const { isMobile } =  useMediaQuery()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                ListMeet Standard Operating Procedure
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Please be advised that prior to showing a purchaser a property:
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                1. We do not require identification from a prospective purchaser.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                2. We do not require a purchaser to sign an exclusive brokerage agreement.
              </TypeFace>
              <TypeFace type="div" style={{ ...styles.text, ...styles.inline }}>
                3. We do not require a pre-approval for a mortgage loan in order to show a purchaser properties.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                * Although we may not require such information, a seller of real estate may require this information prior to showing the property and/or as part of any purchase offer.
              </TypeFace>
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default Procedure

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "10px 0",
    textAlign: "left",
    lineHeight: 1.45
  },
  inline: {
    paddingLeft: 16,
  }
}