import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { searchObjects } from "src/algolia";
import HomeBlueMenuBar from "src/components/home/HomeBlueMenuBar";
import HomeHeaderDesktop from "src/components/home/HomeHeaderDesktop";
import SearchOptionsBar from "src/components/search/SearchOptionsBarV2";
import TypeFace from "src/components/typography/Typefaces";
import Flexbox from "src/components/wrapper/Flexbox";
import { algoliaFilterTextFromSearchObject } from "src/data/algolia-data-adjust";
import { selectSearchObject } from "src/redux/search/selectors";
import LoaderGif from "src/assets/images/loader3.gif";
import CardSearchResultItem from "src/components/home/search/CardSearchResultItem";
import useMediaQuery from "src/hooks/useMediaQuery";
import ReactSelect from "react-select";
import HomeFooter from "src/components/home/HomeFooter";
import HomeOtherLinks from "src/components/home/HomeOtherLinks";
import HomeBlueCopyRightFooter from "src/components/home/HomeBlueCopyRightFooter";
import { uniqBy } from "lodash";
import { Flex, Pagination } from "antd";
import { useAlert } from "src/hooks/alert/AlertContext";

const sortOptions = [
  { label: "Newest", value: "newest" },
  { label: "Highest price", value: "price-high" },
  { label: "Lowest price", value: "price-low" },
];

const SearchResults = () => {
  const { showAlert } = useAlert();

  var userLocation = "";
  var locationAccess = "No";
  //const [userLocation, setUserLocation] = useState("");

  const { isMobile } = useMediaQuery();
  const currentSearchObject = useSelector(selectSearchObject);

  const [searchResults, setSearchResults] = useState();

  const [isSearching, setIsSearching] = useState(false);

  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const showOffLocationAlert = (test = "obj") => {
    showAlert(true, "Enable location services to search by your current location.", [
      {
        title: "Ok",
        onClick: () => {
          showAlert(false);
        },
      },
    ]);
  };

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    userLocation = latitude + "," + longitude;
    locationAccess = "Yes";
    searchProperties();
  }
  function error() {
    locationAccess = "No";
    searchProperties();
    showOffLocationAlert();
  }

  function handleLocationClick() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error);
      } else {
        console.log("Geolocation not supported");
      }
    } catch (err) {
      error();
    } finally {
      searchProperties();
    }
  }

  const searchProperties = () => {
    let filters = algoliaFilterTextFromSearchObject(currentSearchObject);

    let query = currentSearchObject.query ? currentSearchObject.query : "";

    var queryFilters = "";

    if (query) {
      queryFilters = query
        .map((q) => {
          if (q.type === "zip") return `address.zip: ${q.value}`;
          if (q.type === "area") return `address.components: '${q.value}'`;
        })
        .join(" OR ");

      if (queryFilters) filters = `${filters} AND (${queryFilters})`;
    }

    let userRadius = 1610;
    let userLoc = locationAccess === "Yes" ? userLocation : "00.000,-00.0000";

    let args = ["", filters, selectedSortOption.value, undefined];

    let pg = page - 1;

    if (queryFilters === "") {
      args = [...args, userLoc, userRadius, pg, pageSize];
    } else {
      args = [...args, undefined, undefined, pg, pageSize];
    }
    searchObjects(...args).then((res) => {
      setIsSearching(false);
      setSearchResults(res.hits);
      setTotal(res.nbHits);
    });
  };

  const searchResultsText = useMemo(() => {
    let strMessage = "";
    if (searchResults) {
      if (total === 0) {
        if (currentSearchObject.type === "rental") {
          return currentSearchObject?.neighborhoods?.length === 0
            ? "No rentals found near your area"
            : "No rentals found";
        } else {
          return currentSearchObject?.neighborhoods?.length === 0 ? "No sales found near your area" : "No sales found";
        }
      } else {
        if (currentSearchObject.type === "rental") {
          if (total > 1) {
            return `${total} Rentals listed`;
          } else {
            return `${total} Rental listed`;
          }
        } else {
          if (total > 1) {
            return `${total} Sales listed`;
          } else {
            return `${total} Sales listed`;
          }
        }
      }
    } else {
      if (searchResults !== undefined) showOffLocationAlert("search");
    }
  }, [searchResults, currentSearchObject]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsSearching(true);
    if (currentSearchObject?.query?.length === 0) {
      handleLocationClick();
    } else {
      searchProperties();
    }
  }, [currentSearchObject, selectedSortOption, page, pageSize]);

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {/* {isMobile ? <BlueMenuBarWithBackButton /> : <HomeBlueMenuBar />} */}
          <HomeBlueMenuBar />
          <SearchOptionsBar />
          <Flexbox style={{ width: "100%" }}>
            {isSearching ? (
              <img src={LoaderGif} style={{ width: 30, height: 30, marginTop: 40 }} />
            ) : (
              <Flexbox
                style={{
                  width: "90%",
                  maxWidth: 1178,
                  alignItems: "flex-start",
                }}
              >
                <Flexbox row style={{ marginTop: 20, marginBottom: 20, gap: 20 }}>
                  <TypeFace bold>{searchResultsText}</TypeFace>
                  <Flexbox row style={{ gap: 10 }}>
                    <TypeFace medium size={12}>
                      Sort by
                    </TypeFace>
                    <div style={{ width: 160, position: "relative", zIndex: 1 }}>
                      <ReactSelect
                        isSearchable={false}
                        options={sortOptions}
                        value={selectedSortOption}
                        onChange={(value) => {
                          setSelectedSortOption(value);
                        }}
                        menuPortalTarget={document.body}
                        menuContainerStyle={{ zIndex: 0 }}
                      />
                    </div>
                  </Flexbox>
                </Flexbox>
                <Flexbox
                  style={{
                    width: "100%",
                    flexWrap: "wrap",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  {searchResults?.map((hit, index) => (
                    <CardSearchResultItem key={index} hit={hit} />
                  ))}
                </Flexbox>
                {total && (
                  <Flexbox style={{ alignItems: "flex-end", justifyContent: "center", marginTop: 20, width: "100%" }}>
                    <Pagination
                      total={total}
                      pageSize={pageSize}
                      showSizeChanger
                      pageSizeOptions={["12", "24", "48"]}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} results`}
                      current={page}
                      onChange={(page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                      }}
                    />
                  </Flexbox>
                )}
              </Flexbox>
            )}
          </Flexbox>
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
