import debounce from "lodash.debounce"
import { useEffect, useMemo, useState } from "react"
import TextInput from "../input/TextInput"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import EditListingUnitRentInformation from "./EditListingUnitRentInformation"
import EditListingUnitSaleInformation from "./EditListingUnitSaleInformation"
import { ListingStatusValues, UnitACTypes, UnitAttics, UnitBasements, UnitFeatures, UnitFireplaces, UnitHeatingFuels, UnitHeatingTypes, UnitOutspaces } from "src/model/types"
import useMediaQuery from "src/hooks/useMediaQuery"

const EditListingUnit = ({
  highlightErrorFields,
  listing,
  onUpdateRootFields,
  onUpdateUnitInfoFields,
  soldSelected,
  rentSelected
}) => {

  // const { id } = useParams()
  const { isMobile } = useMediaQuery()

  // const [listing, setListing] = useState()
  const [outdoorSpaces, setOutdoorSpaces] = useState([])
  const [features, setFeatures] = useState([])
  const [acTypes, setACTypes] = useState()
  const [heatingTypes, setHeatingTypes] = useState([])
  const [fireplace, setFireplace] = useState([])
  const [views, setViews] = useState([])

  const [basements, setBasements] = useState([])
  const [attics, setAttics] = useState([])
  const [heatingFuels, setHeatingFuels] = useState([])

  const [marketAs, setMarketAs] = useState('')

  const changeUnitDescription = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "desc": changedValue
    })
  }

  const debouncedUnitDescriptionChangeHandler = useMemo(() => {
    return debounce(changeUnitDescription, 500);
  }, []);

  const changeSize = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "size": changedValue
    })
  }

  const debouncedSizeChangeHandler = useMemo(() => {
    return debounce(changeSize, 500);
  }, []);

  const changeLotSize = (event) => {
    const changedValue = event.target.value
    let inputValid = false
    let lotSizeCalculated = 0
    if (changedValue) {
      let values = changedValue.split('x')
      if (values.length === 2) {
        lotSizeCalculated = values[0] * values[1]
        inputValid = true
      } else {
        values = changedValue.split('X')
        if (values.length === 2) {
          lotSizeCalculated = values[0] * values[1]
          inputValid = true
        } else {
          values = changedValue.split('*')
          if (values.length === 2) {
            lotSizeCalculated = values[0] * values[1]
            inputValid = true
          }
        }
      }
    }

    if (inputValid && lotSizeCalculated > 0) {
      document.getElementById("lot-size-calculated").value = `${lotSizeCalculated}`
      onUpdateUnitInfoFields({
        "lotSize": lotSizeCalculated,
        "lotSizeText": changedValue,
      })
    }
  }

  const debouncedLotSizeChangeHandler = useMemo(() => {
    return debounce(changeLotSize, 500);
  }, []);

  const changeLegalBeds = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "legalBeds": changedValue
    })
  }

  const debouncedLegalBedsChangeHandler = useMemo(() => {
    return debounce(changeLegalBeds, 500);
  }, []);

  const changeFullBaths = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "fullBaths": changedValue
    })
  }

  const debouncedFullBathsChangeHandler = useMemo(() => {
    return debounce(changeFullBaths, 500);
  }, []);

  const changeHalfBaths = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "halfBaths": changedValue
    })
  }

  const debouncedHalfBathsChangeHandler = useMemo(() => {
    return debounce(changeHalfBaths, 500);
  }, []);

  const changeTotalRooms = (event) => {
    const changedValue = event.target.value
    onUpdateUnitInfoFields({
      "totalRooms": changedValue
    })
  }

  const debouncedTotalRoomsChangeHandler = useMemo(() => {
    return debounce(changeTotalRooms, 500);
  }, []);

  const RadioItemsRow = ({
    name,
    value,
    setValue,
    items,
    valueKey,
  }) => (
    <Flexbox style={{ marginTop: 10, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row', gap: 20, marginLeft: 20 }}>
      {items.map((item, index) => (
        <Flexbox row style={{ alignItems: 'center' }}>
          <input key={`${valueKey}-${index}`} type="radio" id={`${valueKey}-${item.key}`} name={name} checked={value === item.key} onChange={e => {
            if (e.target.checked) {
              onUpdateUnitInfoFields({
                [`${valueKey}`]: item.key
              })
              setValue(item.key)
            }
          }} />
          <TypeFace key={`${valueKey}-${index}-label`} for={`${valueKey}-${item.key}`} medium size={12} style={{ marginLeft: 3 }}>
            {item.label}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
  
  const CheckboxItemsRow = ({
    name,
    values,
    setValues,
    items,
    valueKey,
  }) => {
    return (
      <Flexbox style={{ marginTop: 10, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row', gap: 20, marginLeft: 20 }}>
        {items.map((item, index) => (
          <Flexbox key={index} row style={{ alignItems: 'center' }}>
            <input key={`${valueKey}-${index}`} type="checkbox" id={`${valueKey}-${item.key}`} name={name} checked={values.includes(item.key)} onChange={e => {
              if (e.target.checked) {
                onUpdateUnitInfoFields({
                  [`${valueKey}`]: [...values, item.key]
                })
                setValues([...values, item.key])
              } else {
                onUpdateUnitInfoFields({
                  [`${valueKey}`]: [...values.filter(i => i !== item.key)]
                })
                setValues([...values.filter(i => i !== item.key)])
              }
            }} />
            <TypeFace key={`${valueKey}-${index}-label`} for={`${valueKey}-${item.key}`} medium size={12} style={{ marginLeft: 3 }}>
              {item.label}
            </TypeFace>
          </Flexbox>
        ))}
      </Flexbox>
    )
  }  

  useEffect(() => {
    console.log('this-DAta=',rentSelected)
    if (listing) {
      setOutdoorSpaces(listing.unitInfo?.outdoor ?? [])
      setFeatures(listing.unitInfo?.features ?? [])
      setFireplace(listing.unitInfo?.fireplace ?? [])
      setViews(listing.unitInfo?.views ?? [])
      setACTypes(listing.unitInfo?.acTypes)
      setHeatingTypes(listing.unitInfo?.heatingTypes ?? [])

      setBasements(listing.unitInfo?.basements ?? [])
      setAttics(listing.unitInfo?.attics ?? [])
      setHeatingFuels(listing.unitInfo?.heatingFuels ?? [])

      setMarketAs(listing.unitInfo?.marketAs)

      
      document.getElementById("input-unit-description").value = listing.unitInfo?.desc ?? ""
      document.getElementById("input-size").value = listing.unitInfo?.size ?? ""
      document.getElementById("input-legal-beds").value = listing.unitInfo?.legalBeds ?? ""
      document.getElementById("input-full-baths").value = listing.unitInfo?.fullBaths ?? ""
      document.getElementById("input-half-baths").value = listing.unitInfo?.halfBaths ?? ""
      document.getElementById("input-total-rooms").value = listing.unitInfo?.totalRooms ?? ""

      if (listing.unitInfo?.marketAs === 'singleFamily' || listing.unitInfo?.marketAs === 'multiFamily' || listing.unitInfo?.marketAs === 'townHouse') {
        setTimeout(() => {
          if ((listing.unitInfo?.lotSize ?? 0) !== 0) {
            document.getElementById("lot-size-calculated").value = `${listing.unitInfo?.lotSize ?? 0}`
            document.getElementById("input-lot-size").value = `${listing.unitInfo?.lotSizeText ?? ""}`
          }
        }, 300)
      }
    }
  }, [listing])

  return (
    <Flexbox style={styles.container}>
      {listing?.type === 'sale' ? (
        <EditListingUnitSaleInformation
          highlightErrorFields={highlightErrorFields}
          listing={listing}
          onUpdateRootFields={onUpdateRootFields}
          onUpdateUnitInfoFields={onUpdateUnitInfoFields}
          onMarketAsChanged={setMarketAs}
          soldSelected={soldSelected}
        />
      ) : (
        <EditListingUnitRentInformation
          highlightErrorFields={highlightErrorFields}
          listing={listing}
          onUpdateRootFields={onUpdateRootFields}
          onUpdateUnitInfoFields={onUpdateUnitInfoFields}
          onMarketAsChanged={setMarketAs}
          rentedSelected={rentSelected}
        />
      )}
      
      <TypeFace medium size={20} style={{ marginTop: 60 }}>
        Rooms and Description{listing.soldPrice}
      </TypeFace>
      <Flexbox row style={{ alignItems: 'flex-start', marginTop: 20, flexDirection: isMobile ? 'column' : 'row', gap: 15 }}>
        <Flexbox row style={{ alignItems: 'center', gap: 15 }}>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace bold size={12} color={highlightErrorFields && !listing.unitInfo?.legalBeds ? 'red' : 'black'}>
              LEGAL BEDS<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput id='input-legal-beds' style={{...styles.mediumInput, borderColor: highlightErrorFields && !listing.unitInfo?.legalBeds ? 'red' : 'black'}} onChange={debouncedLegalBedsChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}/>
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace bold size={12} color={highlightErrorFields && !listing.unitInfo?.fullBaths ? 'red' : 'black'}>
              FULL BATHS<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput id="input-full-baths" style={{...styles.mediumInput, borderColor: highlightErrorFields && !listing.unitInfo?.fullBaths ? 'red' : 'black'}} onChange={debouncedFullBathsChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }} />
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace bold size={12} color={highlightErrorFields && !listing.unitInfo?.halfBaths ? 'red' : 'black'}>
              HALF BATHS<TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <TextInput id="input-half-baths" style={{ ...styles.mediumInput, borderColor: highlightErrorFields && !listing.unitInfo?.halfBaths ? 'red' : 'black'}} onChange={debouncedHalfBathsChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }} />
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start' }}>
            <TypeFace bold size={12} color={'black'}>
              TOTAL ROOMS
            </TypeFace>
            <TextInput id="input-total-rooms" style={{ ...styles.mediumInput, borderColor: 'black' }} onChange={debouncedTotalRoomsChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }} />
          </Flexbox>
          <Flexbox style={{ alignItems: 'flex-start'}}>
            <TypeFace bold size={12} color='black'>
              DWELLING SQ FT
            </TypeFace>
            <TextInput id={'input-size'} style={{ ...styles.mediumInput, borderColor: 'black'}} placeholder="ft²" onChange={debouncedSizeChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "." && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}/>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      {(marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
        <Flexbox style={{ alignItems: isMobile ? 'flex-start' : 'center', marginTop: 30, gap: isMobile ? 20 : 60, flexDirection: isMobile ? 'column' : 'row' }}>
          <Flexbox row style={{ alignItems: 'center', gap: 20}}>
          <TypeFace semiBold size={14} color={highlightErrorFields && !listing.unitInfo?.lotSizeText? 'red' : 'black'}>
              Lot Size<TypeFace>*</TypeFace>
            </TypeFace>
            <TextInput id={'input-lot-size'} style={{ ...styles.lotSizeInput, borderColor: highlightErrorFields && !listing.unitInfo?.lotSizeText ? 'red' : 'black'}} placeholder="28.5x100" onChange={debouncedLotSizeChangeHandler} onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "x" && event.key !== "." && event.key !== "X" && event.key !== "*" && event.key !== " " && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}/>
          </Flexbox>
          <Flexbox row style={{ alignItems: 'center', gap: 20}}>
            <TypeFace semiBold size={14}>
              Lot Sq Footage<TypeFace>*</TypeFace>
            </TypeFace>
            <TextInput disabled id={'lot-size-calculated'} style={styles.lotCalculatedSizeInput} placeholder="2850" />
          </Flexbox>
        </Flexbox>
      )}
      <TypeFace bold size={12} style={{ marginTop: 30 }}>
        UNIT DESCRIPTION
      </TypeFace>
      <textarea
        id="input-unit-description"
        style={{
          width: isMobile ? '90%' : '100%',
          height: 120,
        }}
        onChange={debouncedUnitDescriptionChangeHandler}
      />

      <TypeFace medium size={20} style={{ marginTop: 60 }}>
        Unit Amenities
      </TypeFace>
      {!(marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
        <>
        <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
        Outdoor Space
      </TypeFace>
      <CheckboxItemsRow
        name="outdoor_space"
        setValues={setOutdoorSpaces}
        values={outdoorSpaces}
        valueKey="outdoor"
        items={UnitOutspaces.map(item => ({...item, label: item.value}))}
        isDraftStatus={listing?.status === ListingStatusValues.Draft}
      />
      <div style={styles.separator} />
      </>)}
      {/* {(marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
        <>
          <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
            Basement<TypeFace>*</TypeFace>
          </TypeFace>
          <CheckboxItemsRow
            name="basements"
            setValues={setBasements}
            values={basements}
            valueKey="basements"
            items={UnitBasements}
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
          />
          <div style={styles.separator} />
          <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
            Attic<TypeFace>*</TypeFace>
          </TypeFace>
          <CheckboxItemsRow
            name="attics"
            setValues={setAttics}
            values={attics}
            valueKey="attics"
            items={UnitAttics}
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
          />
          <div style={styles.separator} />
        </>
      )} */}
      <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
        Features
      </TypeFace>
      {(marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
        <>
          <TypeFace semiBold size={11} style={{ marginTop: 20, marginLeft: 20 }} color={highlightErrorFields && !acTypes ? 'red' : 'black'}>
          A/C TYPE<TypeFace>{listing?.type === 'sale' ? "*" : "*"}</TypeFace>
          </TypeFace>
          <RadioItemsRow
            name="ac"
            setValue={setACTypes}
            value={acTypes}
            valueKey="acTypes"
            items={UnitACTypes}
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
          />
          <TypeFace semiBold size={11} style={{ marginTop: 30, marginLeft: 20 }} color={highlightErrorFields && (!heatingTypes || heatingTypes.length === 0) ? 'red' : 'black'}>
          HEATING TYPE<TypeFace>{listing?.type === 'sale' ? "*" : "*"}</TypeFace>
          </TypeFace>
          <CheckboxItemsRow
            name="heating"
            setValues={setHeatingTypes}
            values={heatingTypes}
            valueKey="heatingTypes"
            items={UnitHeatingTypes}
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
          />
        </>
      )}
      {(marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
        <>
          <TypeFace semiBold size={11} style={{ marginTop: 30, marginLeft: 20 }} color={highlightErrorFields && (!heatingFuels || heatingFuels.length === 0) ? 'red' : 'black'}>
            HEATING FUEL<TypeFace>*</TypeFace>
          </TypeFace>
          <CheckboxItemsRow
            name="heatingFuels"
            setValues={setHeatingFuels}
            values={heatingFuels}
            valueKey="heatingFuels"
            items={UnitHeatingFuels}
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
          />
        </>
      )}
      <TypeFace semiBold size={11} style={{ marginTop: 30, marginLeft: 20 }}>
        UNIT FEATURES
      </TypeFace>
      <CheckboxItemsRow
        name="features"
        setValues={setFeatures}
        values={features}
        valueKey="features"
        items={UnitFeatures.map(item => ({...item, label: item.value}))}
        isDraftStatus={listing?.status === ListingStatusValues.Draft}
      />
      <TypeFace semiBold size={11} style={{ marginTop: 30, marginLeft: 20 }}>
        FIREPLACE
      </TypeFace>
      <CheckboxItemsRow
        name="fireplace"
        setValues={setFireplace}
        values={fireplace}
        valueKey="fireplace"
        items={UnitFireplaces.map(item => ({...item, label: item.value}))}
        isDraftStatus={listing?.status === ListingStatusValues.Draft}
      />
      
      {!(listing.building?.type === 'single-family' || listing.building?.type === 'multi-family' || listing.building?.type === 'townHouse') && (
        <>
        <div style={styles.separator} />
          <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
            View
          </TypeFace>
          <CheckboxItemsRow
            name="view"
            setValues={setViews}
            values={views}
            valueKey="views"
            isDraftStatus={listing?.status === ListingStatusValues.Draft}
            items={[
              {key: 'city', label: 'City'},
              {key: 'garden-view', label: 'Garden'},
              {key: 'park', label: 'Park'},
              {key: 'skyline', label: 'Skyline'},
              {key: 'water', label: 'Water'},
            ]}
          />
        </>
      )}
    </Flexbox>
  )
}

export default EditListingUnit

const styles = {
  container: {
    padding: "30px 0",
    alignItems: 'flex-start'
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#AFAFAF',
    marginTop: 30,
  },
  input: {
    width: 150,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  smallInput: {
    width: 60,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  mediumInput: {
    width: 100,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  lotSizeInput: {
    width: 160,
    height: 36,
    borderRadius: 0,
  },
  lotCalculatedSizeInput: {
    width: 100,
    height: 36,
    borderRadius: 0,
  }
}