import { arrayRemove, doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useMemo } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import IconPDF from 'src/assets/images/icons/icon-pdf.svg'
import { UserRoleValue } from 'src/model/types'
import StarRate from '../admin/components/reviews/StarRate'
import { Colors } from 'src/theme/Colors'

const MessageChatCell = ({
  chats,
  chat,
  idx,
  threadId,
  senderProfiles,
  review,
  onReview
}) => {

  const { userProfile } = useFirestore()

  const { needShowHeader, dayBar, dayString } = useMemo(() => {

    let needShowHeader = false
    if (idx === 0) {
      needShowHeader = true
    } else {
      if (chats[idx - 1].by !== chat.by) {
        needShowHeader = true
      } else {
        if ((chat.at - chats[idx - 1].at) / 1000 > 180) {
          needShowHeader = true
        }
      }
    }

    let dayBar = false
    let dayString = ""
    const chatMoment = moment(new Date(chat.at))
    const today = moment(new Date())
    if (today.format('YYYY-MM-DD') === chatMoment.format('YYYY-MM-DD')) {
      dayString = "Today"
    } else {
      dayString = chatMoment.format('MMM d, yyyy')
    }
    if (idx === 0) {
      dayBar = true
      
    } else {
      const date1 = moment(new Date(chat.at)).format('YYYY-MM-DD')
      const date2 = moment(new Date(chats[idx - 1].at)).format('YYYY-MM-DD')
      dayBar = date1 !== date2
    }

    return { needShowHeader, dayBar, dayString }
  }, [chat, idx, chats])

  // if (threadId && userProfile?.userId && chat.unread?.includes(userProfile?.userId)) {

  //   updateDoc(doc(firestore, `messages/${threadId}`), {
  //     [`unreads.${userProfile.userId}`]: arrayRemove(chat.id)
  //   })

  //   updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
  //     "notifications.messages": arrayRemove(chat.id)
  //   })

  //   updateDoc(doc(firestore, `messages/${threadId}/chat/${chat.id}`), {
  //     unread: arrayRemove(userProfile.userId)
  //   })
  // }

  return (
    <div style={{ width: '100%' }} key={`${idx}`}>
      <Flexbox style={{
        ...styles.chatContent,
        marginTop: (idx === 0) ? 15 : (needShowHeader ? 10 : 0),
        marginBottom: idx === (chats.length - 1) ? 15 : 5,
        alignItems: chat.by === userProfile?.userId ? 'flex-end' : 'flex-start'
      }}>
        {dayBar && (
          <Flexbox row style={styles.dayLineContainer}>
            <div style={{ flex: 1, height: 1, backgroundColor: '#dfdfdf'}} />
            <TypeFace semiBold size={11} style={{ marginLeft: 10, marginRight: 10 }}>
              {dayString}
            </TypeFace>
            <div style={{ flex: 1, height: 1, backgroundColor: '#dfdfdf'}} />
          </Flexbox>
        )}
        {needShowHeader && (
          <Flexbox row>
            {chat.by !== userProfile?.userId && (
              <TypeFace semiBold size={12}>
                {senderProfiles?.find(profile => profile.userId === chat.by)?.name}
              </TypeFace>
            )}
            <TypeFace medium size={11} color="#7f7f7f" style={{ marginLeft: 5 }}>
              {moment(new Date(chat.at)).format("h:mm a")}
            </TypeFace>
          </Flexbox>
        )}
        <Flexbox style={styles.messageContainer}>
          {chat.extra?.type === 'file' ? (
            <>
              {(chat.extra?.fileType === 'image/png' || chat.extra?.fileType === 'image/jpeg') ? (
                <img src={chat.attachment} style={{
                  width: '100%',
                  borderRadius: 5,
                }} />
              ) : (
                <>
                  {chat.extra?.fileType === 'application/pdf' ? (
                    <Flexbox>
                      <img src={IconPDF} width={120} height={120} style={{ cursor: 'pointer' }} onClick={() => {
                        window.open(chat.attachment, '_blank');
                      }} />
                      <TypeFace size={11} style={{ textAlign: 'start', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                        window.open(chat.attachment, '_blank');
                      }}>
                        {chat.extra?.fileName}
                      </TypeFace>
                    </Flexbox>
                  ) : (
                    <TypeFace size={13} style={{ textAlign: 'start', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                      window.open(chat.attachment, '_blank');
                    }}>
                      {chat.extra?.fileName}
                    </TypeFace>
                  )}
                </>
              )}
            </>
          ) : (
            <TypeFace size={13} style={{ textAlign: 'start' }}>
              {chat.msg}
            </TypeFace>
          )}
          {(chat.extra?.type === 'complete' && userProfile?.role === UserRoleValue.Customer) && (
            <Flexbox style={styles.reviewContainer}>
              <Flexbox style={styles.divider}></Flexbox>
              {!review ? (
                <Flexbox onPress={onReview}>
                  <StarRate value={0} size={32} />
                </Flexbox>
              ) : (
                <StarRate value={review.mark} size={32} />
              )}
              <TypeFace size={13} style={{ textAlign: 'start' }}>
                {!review ? "How was your meeting?" : review.comment}
              </TypeFace>
            </Flexbox>
          )}
        </Flexbox>
      </Flexbox>
    </div>
  )
}

export default MessageChatCell

const styles = {
  chatContent: {
    width: 'calc(100% - 40px)',
    marginLeft: 20,
    marginRight:20,
  }, 
  messageContainer: {
    maxWidth: '60%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #efefef',
    alignItems: 'flex-start',
    borderRadius: 5,
  },
  dayLineContainer: {
    width: 'calc(100% - 20px)',
    marginTop: 5,
    marginBottom: 5,
  },
  reviewContainer: {
    position: "relative",
    paddingTop: 16,
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 1,
    top: 8,
    backgroundColor: Colors.gray1,
  }
}