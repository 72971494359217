import React from "react";
import Modal from 'react-modal';
import Flexbox from "src/components/wrapper/Flexbox";
import useMediaQuery from "src/hooks/useMediaQuery";
import MessageRoom from "./MessageRoom";

const MessageRoomModal = ({
  isModalOpened,
  onCloseModal,
  messagingThreadId,
  handlePayToAgency,
}) => {

  const { isMobile } = useMediaQuery()

  function afterOpenModal() {
    document.body.style.overflow = 'hidden'
  }

  function closeModal() {
    onCloseModal()
    document.body.style.overflow = 'auto'
  }

  return (
    <Modal
      isOpen={isModalOpened}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{
        ...styles.messageRoomContainer,
        width: isMobile ? 'calc(100vw - 60px)' : 600,
        height: isMobile ? 'calc(100vh - 60px)' : 600,
      }}>
        <MessageRoom threadId={messagingThreadId} onPayToAgency={handlePayToAgency} />
      </Flexbox>
    </Modal>
  )
}

export default MessageRoomModal

const styles = {
  messageRoomContainer: {
    width: 600,
    height: 600,
    border: '1px solid #cfcfcf'
  },
}