import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import TypeFace from 'src/components/typography/Typefaces';
import { useRef } from 'react';
import Button from 'src/components/button/Button';
import { Colors } from 'src/theme/Colors'

const Support = () => {

    const myRef = useRef<HTMLElement | null>(null);

  const { isMobile } =  useMediaQuery()

  const handleContactUs = () => {
    var link = "mailto:support@listmeet.com"
    window.location.href = link
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                Welcome to ListMeet Support Page
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
              We&#39;re here to help you navigate and make the most out of our platform, ListMeet. Below, you&#39;ll
              find answers to common questions, guides, and contact information for further assistance.
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitle}>
              Sections
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('getting-started-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                1. Getting Started
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('managing-your-account-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                2. Managing Your Account
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('listing-properties-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                3. Listing Properties
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('searching-for-properties-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                4. Searching for Properties
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('contacting-sellers-buyers-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                5. Contacting Sellers/Buyers
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('payment-and-billing-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                6. Payment and Billing
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('technical-issues-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                7. Technical Issues
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('privacy-and-security-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                8. Privacy and Security
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.sectionText, ...styles.inline }} onClick={() => {
                    const element = document.getElementById('contact-us-section');
                    element?.scrollIntoView({
                        behavior: 'smooth'
                    }); 
                    }}>
                9. Contact Us
              </TypeFace>
             
             <div id='getting-started-section'>
             <TypeFace type="div" bold style={styles.subTitle}>
              1. Getting Started
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I create an account?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Click on the &#39;Sign Up&#39; button on the homepage. Fill in your details, verify your email,
                and you’re ready to go.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I log in?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Click on the &#39;Log In&#39; button on the homepage and enter your email and password.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: I forgot my password. What should I do?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Click on &#39;Forgot Password&#39; on the login page, enter your email, and follow the
                instructions to reset your password.</TypeFace>
              </TypeFace>
             </div>
             <div id='managing-your-account-section'>
             <TypeFace type="div" bold style={styles.subTitle}>
              2. Managing Your Account
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I update my profile information?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Go to &#39;Account Settings&#39; and click on &#39;Edit Profile&#39;. Update your information and save
                changes.</TypeFace>
              </TypeFace>
             </div>
             <div id='listing-properties-section'>
             <TypeFace type="div" bold style={styles.subTitle}>
              3. Listing Properties
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I list a property?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Click on &#39;Add Listing&#39;, fill in the property details, upload photos, and click &#39;Submit&#39;.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: Can I edit my property listing?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Yes, go to &#39;My Listings&#39;, select the listing you want to edit, and make the necessary
                changes.</TypeFace>
              </TypeFace>
             </div>
            <div id='searching-for-properties-section'>
            <TypeFace type="div" bold style={styles.subTitle}>
              4. Searching for Properties
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I search for properties?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Use the search bar on the homepage. You can filter results by location, price, property
                type, and more.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I save a search?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: After performing a search, click &#39;Save Search&#39; to receive updates on new listings that
                match your criteria.</TypeFace>
              </TypeFace>
             </div>
            <div id='contacting-sellers-buyers-section'>
            <TypeFace type="div" bold style={styles.subTitle}>
              5. Contacting Sellers/Buyers
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I contact a seller?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Click on the “Meet Later” button on the property listing page and fill in your details.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I respond to inquiries from buyers?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Go to &#39;My Listings&#39;, select the property, and view messages from interested buyers.</TypeFace>
              </TypeFace>
             </div>
            <div id='payment-and-billing-section'>
            <TypeFace type="div" bold style={styles.subTitle}>
              6. Payment and Billing
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: What payment methods do you accept?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: We accept credit cards payments through Stripe.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do I view my billing history?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Go to &#39;Account Settings&#39; on Stripe and click on &#39;Billing&#39;. You can view your payment
                history and download invoices.</TypeFace>
              </TypeFace>
             </div>
             <div id="technical-issues-section">
              <TypeFace type="div" bold style={styles.subTitle} >
              7. Technical Issues
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: The website is not loading correctly. What should I do?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Try clearing your browser’s cache and cookies. If the problem persists, try using a
                different browser or device.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: I found a bug. How do I report it?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Go to &#39;Contact Us&#39; and send us an email with the subject section being: &#39;Report a Bug&#39;.
                Provide details of the issue, and our technical team will investigate.</TypeFace>
              </TypeFace>
              </div>
            <div id='privacy-and-security-section'>
            <TypeFace type="div" bold style={styles.subTitle}>
              8. Privacy and Security
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How do you protect my personal information?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: We use advanced security measures to protect your data. Please refer to our Privacy
                Policy for more details.</TypeFace>
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How can I ensure my account is secure?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: Use a strong password, enable two-factor authentication, and never share your login
                details with anyone.</TypeFace>
              </TypeFace>
            </div>
            <div id='contact-us-section'>
            <TypeFace type="div" bold style={styles.subTitle}>
              9. Contact Us
              </TypeFace>
              <TypeFace type="div" bold style={styles.subTitleQuest}>
              Q: How can I get in touch with customer support?
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; &nbsp;&nbsp;&nbsp;<TypeFace>A: You can reach us via email at <a href="mailto:support@ListMeet.com">support@ListMeet.com</a>.Our support team is available
                Monday to Friday, 9 AM to 6 PM.</TypeFace>
              </TypeFace>
            </div>

              
             
             
              
             
              
             
              
              
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default Support

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  subTitleQuest: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "left",
  },
  sectionText: {
    fontSize: 15,
    margin: "10px 0",
    textAlign: "left",
    lineHeight: 1.05,
    cursor: 'pointer',
    color: Colors.primary,
  },
  text: {
    fontSize: 16,
    margin: "10px 0",
    textAlign: "left",
    lineHeight: 1.45
  },
  inline: {
    paddingLeft: 16,
  }
}