import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { getFirestoreDocs, getFirestoreListingDoc, getFirestoreProfileDoc } from 'src/data/dbWrappers'
import { firestore } from 'src/firebase'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import useMediaQuery from 'src/hooks/useMediaQuery'
import MessageRoomModal from 'src/pages/messages/MessageRoomModal'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'

const CustomerScheduleEntry = ({
  schedule,
  isPast,
}) => {

  const { isMobile } = useMediaQuery()
  const { userProfile } = useFirestore()
  const navigate = useNavigate()
  
  const [listing, setListing] = useState()
  const [photos, setPhotos] = useState()
  const [creatorProfile, setCreatorProfile] = useState()
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const [messagingRoomOpened, setMessagingRoomOpened] = useState(false)

  const messageThreadSnapshotListener = useRef()

  const unmountedRef = useRef(false)

  const loadListing = (listingId) => {
    getFirestoreListingDoc(listingId).then(listingDoc => {
      if (!unmountedRef.current) {
        const listingData = listingDoc.data()
        setListing(listingData)

        if (listingData.by) {
          getFirestoreProfileDoc(listingData.by).then(creatorProfileData => {
            if (!unmountedRef.current) {
              setCreatorProfile(creatorProfileData.data())
            }
          })
        }
      }
    })

    getFirestoreDocs(collection(firestore, `listing/${listingId}/photos`)).then(collectionData => {
      let photosList = []
      collectionData.forEach(doc => {
        photosList.push(doc.data())
      })
      photosList.sort((a, b) => a.index - b.index)
      if (!unmountedRef.current) {
        setPhotos(photosList)
      }
    })
  }

  const handleOpenListing = () => {
    window.open(`/listing/${schedule.listingId}`, '_blank', 'noreferrer')
  }

  const handleOpenMessage = async () => {
    setMessagingRoomOpened(true)
  }

  useEffect(() => {
    if (schedule?.listingId) { 
      loadListing(schedule.listingId)
    }
  }, [schedule])

  useEffect(() => {
    if (userProfile?.userId) {
      messageThreadSnapshotListener.current?.()
      messageThreadSnapshotListener.current = onSnapshot(doc(firestore, `messages/${schedule.id}`), (messageDoc) => {
        const messageThreadData = messageDoc.data()
        if (!unmountedRef.current) {
          setUnreadMessagesCount(messageThreadData.unreads[userProfile.userId]?.length ?? 0)
        }
      })
    }
  }, [schedule.id, userProfile?.userId])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
      messageThreadSnapshotListener.current?.()
    }
  }, [])

  return (
    <Flexbox row style={{
      ...styles.container,
      width: 'calc(100% - 30px)',
      flexDirection: isMobile ? 'column' : 'row',
      gap: 20,
      opacity: isPast ? 0.5 : 1
    }}>
      {photos && photos.length > 0 ? (
        <img 
          src={photos[0].url}
          width={isMobile ? '100%' : 200} 
          height={200}
        />
      ) : (
        <Flexbox style={{ width: isMobile ? '100%' : 200, height: 200}} />
      )}
      <Flexbox style={{
        flex: isMobile ? 'unset' : 1,
        width: isMobile ? '100%' : 'unset',
        alignItems: 'flex-start'
      }}>
        {listing?.unit !== '' && 
        <TypeFace medium size={isMobile ? 21 : 15}>
          {listing?.unit}{', '}
        </TypeFace>
        }
         <TypeFace medium size={isMobile ? 21 : 15}>
          {extractStreetAddressFromFullAddress(listing?.address?.full)}
        </TypeFace>
        <TypeFace size={15} style={{ marginTop: 10 }}>
          Scheduled at{' '}
          <TypeFace bold size={15}>
            {moment(new Date(schedule.customer.when)).format('h:mm a, MMM D').toUpperCase()}
          </TypeFace>
        </TypeFace>
        <TypeFace size={15} style={{ marginTop: 10 }}>
          Listing by {creatorProfile?.name}
        </TypeFace>
        <Flexbox row style={styles.buttons}>
          <Button secondary style={styles.button} onClick={() => {
            navigate(`/listing/${schedule.listingId}`)
          }}>
            Open Listing
          </Button>
          <Button secondary style={styles.button} onClick={handleOpenMessage}>
            Message
          </Button>
          {unreadMessagesCount > 0 && (
            <Flexbox style={styles.unreadBadge}>
              <TypeFace bold size={11} color="white" style={{ cursor: 'pointer' }}>
                {unreadMessagesCount}
              </TypeFace>
            </Flexbox>
          )}
        </Flexbox>
      </Flexbox>
      <MessageRoomModal
        isModalOpened={messagingRoomOpened}
        onCloseModal={() => setMessagingRoomOpened(false)}
        messagingThreadId={schedule.id}
        handlePayToAgency={() => {}}
      />
    </Flexbox>
  )
}

export default CustomerScheduleEntry

const styles = {
  container: {
    width: 600,
    border: '1px solid #ccc',
    borderRadius: 10,
    padding: 15,
    marginTop: 10
  },
  dateTime: {
    width: 100,
    height: 100,
    border: '1px solid #ccc',
    borderRadius: 10,
    marginRight: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    marginTop: 30,
    gap: 10,
    position: 'relative'
  },
  button: {
    height: 30,
  },
  rightButtons: {
    gap: 10,
  },
  rightButton: {
    padding: 0,
    width: 100,
    height: 30,
  },
  content: {
    flex: 1,
    alignItems: 'flex-start'
  },
  unreadBadge: {
    position: 'absolute',
    top: -5,
    right: 0,
    width: 20,
    height: 20,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: 'red'
  }
}