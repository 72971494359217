import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import { auth } from "src/firebase";
import AuthContext from './AuthContext'

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(undefined);

  useEffect(() => {
    onAuthStateChanged(auth,(user) => {
      setUser(user)
    })
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );
};

