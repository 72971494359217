import React from "react"
import TypeFace from "../typography/Typefaces"
import HomeSearchBox from "./HomeSearchBox"
import homeBg1 from "src/assets/images/bg/bg-homepanel1.jpg"
import MeetNowButton from "../button/MeetNowButton"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"

const HomePanel1 = () => {

  const { isMobile } = useMediaQuery()

  return (
    <Flexbox style={{
      ...styles.container,
      // height: isMobile ? 'unset' : Math.min(1440, window.innerWidth) / 1440 * 922,
      backgroundImage: false ? 'unset' : `url(${homeBg1})`,
      backgroundSize: isMobile ? 'cover' : '100% 100%',
      backgroundRepeat: 'no-repeat'
    }}>
      <HomeSearchBox style={styles.homeSearchBox}/>
      <Flexbox style={{
        ...styles.middleBoxing,
        alignItems: isMobile ? 'center' : 'flex-start',
      }}>
        <Flexbox style={{ alignItems: 'center', marginTop: isMobile ? 20 : 40 }}>
          <TypeFace bold size={isMobile ? 28 : 48}>
            Find Your Dream Home
          </TypeFace>
           <TypeFace semiBold size={isMobile ? 18 : 24} style={{ marginTop: 10 }}>
            Embrace Effortless On Demand Showings<br /> With Our "Meet Now" Feature
          </TypeFace> 
          <MeetNowButton /> 
          <Flexbox style={{
            flexDirection: isMobile ? 'column' : 'row',
            height: isMobile ? 'unset' : 300,
            gap: 40,
            paddingTop: isMobile ? 30 : 0,
            paddingBottom: isMobile ? 30 : 0,
          }}>
            <Flexbox>
              <TypeFace bold size={28}>
                Let ListMeet be your Companion<br />on the Go
              </TypeFace>
              <TypeFace semiBold color={'black'} size={20} style={{ marginTop: 20 }}>
                Download iOS or Android application
              </TypeFace>
            </Flexbox>
            <Flexbox>
              <img src={require('src/assets/images/appstore.png')} style={{ width: 204, height: 59, cursor: 'pointer' }} />
              <img src={require('src/assets/images/googleplay.png')} style={{ width: 204, height: 59, marginTop: 30, cursor: 'pointer' }} />
            </Flexbox>
          </Flexbox>
        </Flexbox>
        {!isMobile && (
          <img
            src={require("src/assets/images/img-home-phone.png")}
            style={styles.phoneImage}
          />
        )} 
      </Flexbox>
    </Flexbox>
  )
}

export default HomePanel1

const styles = {
  container: {
    zIndex: 1,
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    // height: Math.min(1440, window.innerWidth) / 1440 * 922,
    backgroundImage: `url(${homeBg1})`,
    backgroundSize: '100% 100%'
  },
  homeSearchBox: {
    display: 'flex',
    marginTop: 56,
    marginBottom: 64,
  },
  middleBoxing: {
    position: 'relative',
    width: '90%',
    overflow: 'visible',
    maxWidth: 1178,
    borderRadius: 16,
    alignItems: 'flex-start',
    padding: '0px 20px 20px',
  },
  // phoneImage: {
  //   position: 'absolute',
  //   top: 150,
  //   right: 120,
  //   width: 323,
  //   height: 359,
  //   zIndex: 300,
  // }
  phoneImage: {
    position: 'absolute',
    top: 80,
    right: 30,
    width: 405,
    height: 450,
    // zIndex: 300,
  }
}