import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
// import Button from "src/components/button/Button";

import { firestore } from "src/firebase";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Button, Checkbox, Flex, Table } from "antd";
import styles from "../../admin.module.scss";

const AdminCustomerList = () => {
  const { userProfile } = useFirestore();
  const unmountedRef = useRef(false);
  const navigate = useNavigate();
  const [showAnonymous, setShowAnonymous] = useState(false);
  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(true);

  const handleApprove = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: "approved",
    });

    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: true,
      agencyDeclined: false,
      agencyReleased: false,
      releasedDate: "",
    });
  };

  const handleBlock = (profileId) => {
    updateDoc(doc(firestore, `profiles/${profileId}`), {
      profileBlocked: true,
      blockedBy: userProfile?.userId,
      blockedAt: new Date().getTime(),
    }).then(() => {
      const customerIndex = customers.findIndex((c) => c.userId === profileId);
      customers[customerIndex].profileBlocked = true;
      customers[customerIndex].blockedBy = userProfile?.userId;
      customers[customerIndex].blockedAt = new Date().getTime();
      setCustomers([...customers]);
    });
  };

  const handleUnblock = (profileId) => {
    updateDoc(doc(firestore, `profiles/${profileId}`), {
      profileBlocked: false,
      unblockedBy: userProfile?.userId,
      unblockedAt: new Date().getTime(),
    }).then(() => {
      const customerIndex = customers.findIndex((c) => c.userId === profileId);
      customers[customerIndex].profileBlocked = false;
      customers[customerIndex].unblockedBy = userProfile?.userId;
      customers[customerIndex].unblockedAt = new Date().getTime();
      setCustomers([...customers]);
    });
  };

  const loadCustomers = () => {
    setLoading(true);
    let q;
    if (showAnonymous) {
      q = query(
        collection(firestore, "profiles"),
        where("role", "==", "customer"),
        orderBy("created", "desc"),
        limit(50)
      );
    } else {
      q = query(
        collection(firestore, "profiles"),
        where("role", "==", "customer"),
        where("profileCreated", "==", true),
        orderBy("created", "desc"),
        limit(50)
      );
    }
    getDocs(q).then((querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setCustomers(d);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    loadCustomers();
  }, [showAnonymous]);

  useEffect(() => {
    unmountedRef.current = false;
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (_, record) => (record.phone ? record.phone : "-"),
    },
    {
      title: "Anonymous",
      dataIndex: "profileCreated",
      render: (_, record) => (record.profileCreated ? "No" : "Yes"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (record.profileBlocked ? "Blocked" : "Active"),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (_, record) => moment(record.created).format("MMM DD, YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        if (record.profileBlocked) {
          return (
            <Button size="small" onClick={() => handleUnblock(record.userId)}>
              Unblock
            </Button>
          );
        }
        return (
          <Button size="small" danger onClick={() => handleBlock(record.userId)}>
            Block
          </Button>
        );
      },
    },
  ];

  let cols = showAnonymous ? columns : columns.filter((c) => c.dataIndex !== "profileCreated");

  const headerRight = (
    <Checkbox onChange={(e) => setShowAnonymous(e.target.checked)}>Show anonymous customers</Checkbox>
  );

  return (
    <AdminLayout title="Customers" headerRight={headerRight}>
      <Table
        dataSource={customers}
        columns={cols}
        style={{ width: "100%" }}
        loading={loading}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
      />
    </AdminLayout>
  );
};

export default AdminCustomerList;
