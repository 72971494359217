import { collection, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { firestore } from "src/firebase";
import AdminLayout from "../../../../layouts/AuthLayout";
import styles from "../../admin.module.scss";
import { Button, Flex, Input, Table, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Search } = Input;

const AdminManagementCompanies = () => {
  const unmountedRef = useRef(false);

  const navigate = useNavigate();

  const [managementCompanies, setManagementCompanies] = useState();
  const [mgListings, setMgListings] = useState();
  const [search, setSearch] = useState("");

  const handleApproveCompany = (company) => {
    updateDoc(doc(firestore, `profiles/${company.userId}`), {
      profileStatus: "approved",
      profileBlocked: false,
      activatedDate: new Date().getTime(),
    });
  };

  const handleBlockCompany = (company) => {
    updateDoc(doc(firestore, `profiles/${company.userId}`), {
      profileStatus: "blocked",
      profileBlocked: true,
      activatedDate: "",
    });
  };

  const handleViewListing = (creatorId) => {
    navigate(`/admin/listings/${creatorId}`);
  };

  function getData(query = "") {
    if (query === "") return setMgListings(managementCompanies);
    const res = managementCompanies.filter((obj) => {
      if (obj?.name?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.email?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.phone == query) return true;
      return false;
    });
    setMgListings(res);
  }

  useEffect(() => {
    unmountedRef.current = false;
    const managementCompaniesSnapshotListner = onSnapshot(
      query(collection(firestore, "profiles"), orderBy("created", "desc"), where("role", "==", "management")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setManagementCompanies(d);
          setMgListings(d);
          console.log(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      managementCompaniesSnapshotListner();
    };
  }, []);

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        let status = record.profileStatus?.toUpperCase();
        return (
          <Tooltip title={status}>
            <Flex row gap={10}>
              <Text strong>{record.name}</Text>
              {record.profileStatus === "approved" && <CheckCircleOutlined style={{ color: "green" }} />}
              {record.profileStatus === "blocked" && <CloseCircleOutlined style={{ color: "red" }} />}
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (_, record) => (record.phone ? record.phone : "-"),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (_, record) => moment(record.created).format("MMM DD, YYYY"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, agencyRequest) => {
        if (!agencyRequest.profileStatus || agencyRequest.profileStatus === "") {
          return (
            <Flex row gap={10}>
              <Button size="small" onClick={() => handleApproveCompany(agencyRequest)}>
                Approve
              </Button>
              <Button size="small" danger onClick={() => handleBlockCompany(agencyRequest)}>
                Decline
              </Button>
            </Flex>
          );
        }
        if (agencyRequest.profileStatus === "approved") {
          return (
            <Flex row gap={10}>
              <Button size="small" danger onClick={() => handleBlockCompany(agencyRequest)}>
                Block
              </Button>
              <Button size="small" onClick={() => handleViewListing(agencyRequest.userId)}>
                View Listing
              </Button>
            </Flex>
          );
        }
        if (agencyRequest.profileStatus === "blocked") {
          return (
            <Flex row gap={10}>
              <Button size="small" type="primary" onClick={() => handleApproveCompany(agencyRequest)}>
                Approve
              </Button>
              <Button size="small" onClick={() => handleViewListing(agencyRequest.userId)}>
                View Listing
              </Button>
            </Flex>
          );
        }
        return null;
      },
    },
  ];

  const expandable = {
    expandedRowRender: (agencyRequest) => {
      return (
        <Flex row gap={10}>
          <Flex vertical gap={10} style={{ width: "50%" }}>
            <Text strong>Point of Contact:</Text>
            <Text>
              <b>Name:</b> {agencyRequest.contact?.name}
            </Text>
            <Text>
              <b>Email:</b> {agencyRequest.contact?.email}
            </Text>
            <Text>
              <b>Number:</b> {agencyRequest.contact?.number}
            </Text>
          </Flex>
          <Flex vertical gap={10} style={{ width: "50%" }}>
            <Text strong>Bio:</Text>
            <Text>{agencyRequest.bio ? agencyRequest.bio : "-"}</Text>
          </Flex>
        </Flex>
      );
    },
  };

  const headerRight = (
    <div>
      <Search
        placeholder="Search for company name, email, phone number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={(value) => {
          getData(value);
        }}
        style={{
          width: 300,
        }}
        onReset={() => {
          setSearch("");
          setManagementCompanies(managementCompanies);
        }}
        allowClear
        size="large"
      />
    </div>
  );

  return (
    <AdminLayout title="Management Companies" headerRight={headerRight}>
      <Table
        dataSource={mgListings}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
        expandable={expandable}
        rowKey={(_, index) => index}
      />
    </AdminLayout>
  );
};

export default AdminManagementCompanies;
