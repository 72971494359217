export const SET_DRAFT_VALUE = 'SET_DRAFT_VALUE';
export const UPDATE_DRAFT_VALUE = 'UPDATE_DRAFT_VALUE';
export const STORE_LAST_PASSED_TIME = 'STORE_LAST_PASSED_TIME';

export const setDraftValue = (data) => {
  return {
    type: SET_DRAFT_VALUE,
    payload: data
  }
}

export const updateDraftValue = (data) => {
  return {
    type: UPDATE_DRAFT_VALUE,
    payload: data
  }
}

export const storeLastPassedTime = (data) => {
  return {
    type: STORE_LAST_PASSED_TIME,
    payload: data
  }
}