import { collection, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { firestore } from "src/firebase";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Flex, Table, Typography } from "antd";
import styles from "../../admin.module.scss";

const { Text } = Typography;

const AdminAgencyRequests = () => {
  const unmountedRef = useRef(false);

  const [agenciesList, setAgenciesList] = useState();

  useEffect(() => {
    unmountedRef.current = false;
    const agencyRequestsSnapshotListener = onSnapshot(
      query(collection(firestore, "agencyRequests"), orderBy("created", "desc")),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setAgenciesList(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      agencyRequestsSnapshotListener();
    };
  }, []);

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_, record) => (
        <Flex vertical gap={5}>
          {record.isRental && <Text>☑ Rental</Text>}
          {record.isSale && <Text>☑ Sale</Text>}
        </Flex>
      ),
    },
    {
      title: "Requested On",
      dataIndex: "created",
      key: "created",
      render: (_, record) => <Text>{moment(record.created).format("MMM DD, YYYY")}</Text>,
    },
  ];

  const expandable = {
    expandedRowRender: (record) => {
      return (
        <Flex vertical gap={10} style={{ width: "50%" }}>
          <Text strong>Comments:</Text>
          <Text>{record.comments}</Text>
        </Flex>
      );
    },
  };

  return (
    <AdminLayout title="Sales/Rental Leads">
      <Table
        expandable={expandable}
        dataSource={agenciesList}
        columns={columns}
        style={{ width: "100%" }}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark)}
      />
    </AdminLayout>
  );
};

export default AdminAgencyRequests;
