import React from 'react'
import { Colors } from 'src/theme/Colors'
import Link from '../typography/Link'
import useMediaQuery from 'src/hooks/useMediaQuery'
import TypeFace from '../typography/Typefaces'
import { useMemo } from 'react'
import "./homeotherlinks.css"

const HomeOtherLinks = (props) => {

  const { isMobile } = useMediaQuery()

  const handleContactUs = () => {
    var link = "mailto:support@listmeet.com"
    window.location.href = link
  }

  const styles = useMemo(() => {
    return isMobile
      ? {
          container: {
            paddingTop: 8,
            backgroundColor: Colors.primary,
          },
          link: {
            display: "inline-block",
            marginBottom: 4,
          },
          divider: {
            display: "none",
          }
        }
      : {
          container: {
            display: 'flex',
            paddingTop: 8,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.primary,
          },
          link: {

          },
          divider: {
            margin: "0 8px",
          }
        }
  }, [isMobile])

  return (
    <div style={styles.container}>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/about-us">
        ABOUT US
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/cookie-policy">
        COOKIE POLICY
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/privacy-policy">
        PRIVACY POLICY
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/terms">
        TERMS & CONDITIONS
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/fair-housing">
        FAIR HOUSING
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/procedures">
        STANDARD OPERATING PROCEDURES
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} url="/support">
        SUPPORT
      </Link>
      <TypeFace medium size={14} color={'white'} style={styles.divider}>|</TypeFace>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link} onClick={handleContactUs}>
        CONTACT US
      </Link>
      <Link className="other-links" medium size={14} color={'white'} style={styles.link}></Link>
    </div>
  )
}

export default HomeOtherLinks