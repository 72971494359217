import React, { useEffect, useMemo, useState } from 'react';
import HomeBlueMenuBar, { BlueMenuBarWithBackButton } from 'src/components/home/HomeBlueMenuBar';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import { useParams } from 'react-router-dom';
import ListingPhotosCarousel from 'src/components/listing/ListingPhotosCarousel';
import Flexbox from 'src/components/wrapper/Flexbox';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { auth, firestore } from 'src/firebase';
import { getStaticMapUrl } from 'src/utils/geoUtils';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import Button from 'src/components/button/Button';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import LoaderGif from 'src/assets/images/loader3.gif';
import IconBack from 'src/assets/images/icons/icon-chevron-left.svg';
import { useNavigate } from 'react-router-dom';
import ListingSimilarProperties from './components/ListingSimilarProperties';
import { signInAnonymously } from 'firebase/auth';
import ListingDetailsInformationView from './components/ListingDetailsInformationView';
import ListingDetailsCreatorView from './components/ListingDetailsCreatorView';
import 'rc-time-picker/assets/index.css'
import TypeFace from 'src/components/typography/Typefaces';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import IconHeartWhite from 'src/assets/images/icons/icon-heart-blue.svg'
import IconShare from 'src/assets/images/icons/icon-share.svg'
import IconFilledHeartWhite from 'src/assets/images/icons/icon-heart-fill-blue.svg'
import ListingPhotos from './components/ListingPhotos';
import MeetLaterProcessModal from './components/MeetLaterProcessModal';
import PaymentSheet from './components/PaymentSheet';
import { getFirestoreAgencyDoc, getFirestoreDocs } from 'src/data/dbWrappers';
import MessageRoomModal from '../messages/MessageRoomModal';
import ListingDetailsReportModal from './components/ListingDetailsReportModal';
import { UserRoleValue, userRoles } from 'src/model/types';
import { useAlert } from 'src/hooks/alert/AlertContext';
import ListingDetailsSideInformationView from './components/ListingDetailsSideInformationView';
import MobileListingDetailsBottomSticker from './components/MobileListingDetailsBottomSticker';
import ListingDetailsDownloadView from './components/ListingDetailsDownloadView';
import { format } from 'date-fns'

const ListingDetails = () => {

  const { id } = useParams()
  const { userProfile, savedPropertyIds } = useFirestore()
  const { isMobile } = useMediaQuery()
  const { showAlert } = useAlert()

  const navigate = useNavigate()

  const [listingData, setListingData] = useState()
  const [photos, setPhotos] = useState()
  const [floorPlans, setFloorPlans] = useState()
  const [agency, setAgency] = useState()
  const [teamMembers, setTeamMembers] = useState()
  const [isMyListing, setIsMyListing] = useState(false)
  const [isMyAgencyListing, setIsMyAgencyListing] = useState(false)
  const [openHouseSchedules, setOpenHouseSchedules] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [isMeetLaterAlreadySetup, setIsMeetLaterAlreadySetup] = useState(false)
  const [meetLaterWhen, setMeetLaterWhen] = useState()
  const [shareButtonsShowing, setShareButtonsShowing] = useState(false)
  const [messagingRoomOpened, setMessagingRoomOpened] = useState(false)
  const [messagingThreadId, setMessagingThreadId] = useState()
  const [paymentSheetOpening, setPaymentSheetOpening] = useState(false)
  const [meetLaterModalIsOpening, setMeetLaterModalIsOpening] = useState(false)
  const [reportModalOpening, setReportModalOpening] = useState(false)

  const carouselPhotos = useMemo(() => {

    let res = []

    if (photos) {
      res = [...photos]
    }

    if (floorPlans) {
      res = [...res, ...(floorPlans.map(floor => ({ ...floor, type: 'floor' })))]
    }

    if (listingData) {
      res.push({ url: getStaticMapUrl(listingData?.address.geo, isMobile ? window.innerWidth : 800, 400) })
    }

    return res
  }, [listingData, photos, floorPlans, isMobile])

  const handleSave = () => {
    if (!savedPropertyIds?.some(data => data.algoliaID === listingData?.algoliaID)) {
      if (!userProfile) {
        signInAnonymously(auth).then(userCredential => {
          console.log("Signed in anonymously correctly")
          const userId = userCredential.user.uid
          const profileDoc = doc(firestore, 'profiles', userId)
          setDoc(profileDoc, {
            userId,
            created: new Date().getTime(),
            role: "customer",
            profileCreated: false,
          }).then(res => {
            console.log("Set basic profile for anonymous user")
            setDoc(doc(firestore, `profiles/${userId}/favorites/${listingData?.id}`), {
              algoliaID: listingData?.algoliaID,
              listingId: listingData?.id
            }).then(() => {
              console.log("Stored favorite object")
            }).catch(err => {
              console.log("Store favorite object error => ", err)
            })
          }).catch(err => {
            console.log("Profile set error => ", err)
          })
        })
      } else {
        console.log("Storing favorite => ", userProfile.userId, listingData?.id, listingData?.algoliaID)
        setDoc(doc(firestore, `profiles/${userProfile.userId}/favorites/${listingData?.id}`), {
          algoliaID: listingData?.algoliaID,
          listingId: listingData?.id
        }).then(() => {
          console.log("SET FAVORITE Succeeded")
        }).catch(err => {
          console.log("Error => ", err)
        })
      }
    } else {
      deleteDoc(doc(firestore, `profiles/${userProfile.userId}/favorites/${listingData?.id}`))
    }
  }

  const handleOpenMeetLater = () => {
    if (userProfile?.profileBlocked) {
      showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile.')
    }
    else {
      if (userProfile?.role === UserRoleValue.ManageMent) {
        if (userProfile.profileStatus !== 'approved') {
          showAlert(true, 'Listmeet has not approved your company yet.')
        }
        else {
          return (
            setMeetLaterModalIsOpening(true)
          )
        }
      }
      else {
        if (userProfile?.agencyApproved === false) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Listmeet has not approved your brokerage yet.')
          }
          else {
            showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.')
          }
        } else if (userProfile?.suspended === true) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Your brokerage has been suspended.')
          }
          else {
            showAlert(true, 'Contact your broker to reinstate your account.')
          }
        }
        else {
          return (
            setMeetLaterModalIsOpening(true)
          )
        }
      }
    }
  }

  const handleOpenMessageRoom = () => {
    if (userProfile?.profileBlocked) {
      showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile.')
    }
    else {
      if (userProfile?.role === UserRoleValue.ManageMent) {
        if (userProfile.profileStatus !== 'approved') {
          showAlert(true, 'Listmeet has not approved your company yet.')
        }
        else {
          return (
            setMessagingRoomOpened(true)
          )
        }
      }
      else {
        if (userProfile?.agencyApproved === false) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Listmeet has not approved your brokerage yet.')
          }
          else {
            showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.')
          }
        } else if (userProfile?.suspended === true) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Your brokerage has been suspended.')
          }
          else {
            showAlert(true, 'Contact your broker to reinstate your account.')
          }
        }
        else {
          return (
            setMessagingRoomOpened(true)
          )
        }
      }
    }
  }

  const loadData = () => {
    setIsLoading(true)
    getDoc(doc(firestore, `listing/${id}`)).then(document => {

      setIsLoading(false)
      const listing = document.data()
      console.log("document.data()=", document.data())
      setListingData(document.data())

      if (listing?.agency) {

        getFirestoreAgencyDoc(listing?.agency).then(agencyDoc => {
          setAgency(agencyDoc.data())
        })
      }

      if (listing?.by) {
        if (listing.by === userProfile?.userId) {
          setIsMyListing(true)
          getDocs(collection(firestore, `listing/${listing.id}/schedules`)).then(querySnapshot => {
            let schedules = []
            querySnapshot.forEach(doc => {

              const scheduledDateTime = new Date(doc.data().scheduleDateTime);
              if (scheduledDateTime >= new Date()) {
                schedules.push(doc.data())
              }

            })
            setOpenHouseSchedules(schedules)
          })
        } else {

          setIsMyListing(false)

          const teamMembers = listing.team
          console.log('listing.team=', listing.team)
          const q = query(collection(firestore, 'profiles'), where('userId', 'in', teamMembers))
          getFirestoreDocs(q, false).then(results => {
            let teamMemberProfilesDoc = []
            results.forEach(doc => {
              const memberData = doc.data()
              //console.log('memberData=',memberData)
              //console.log('ROLE=',memberData.role)
              if (memberData.role === UserRoleValue.ManageMent) {
                if (memberData.userId === listing?.agency) {
                  teamMemberProfilesDoc.push(doc.data())
                }
              }
              else {
                if (memberData?.agency === listing?.agency) {
                  teamMemberProfilesDoc.push(doc.data())
                }
              }
            })
            setTeamMembers(teamMemberProfilesDoc.sort((a1, a2) => {
              if (a1.role === UserRoleValue.Broker && a2.role !== UserRoleValue.Broker) {
                return -1
              }
              if (a2.role === UserRoleValue.Broker && a1.role !== UserRoleValue.Broker) {
                return 1
              }

              return a1.name - a2.name
            }))
          })

          if (userProfile !== undefined && userProfile?.agency === listing?.agency) {
            setIsMyAgencyListing(true)
          }

          if (userProfile?.role === 'customer') {
            const q = query(collection(firestore, "schedules"), where('customer.id', "==", userProfile?.userId), where('listingId', "==", listing.id))
            getDocs(q).then(snap => {
              if (!snap.empty) {

                const data = snap.docs[0].data()

                if (data.customer.when > new Date().getTime() && !data.canceled && !data.deleted) {
                  setIsMeetLaterAlreadySetup(true)
                  setMeetLaterWhen(data.customer.when)

                  const messageThreadQuery = query(collection(firestore, 'messages'), where('senders', 'array-contains', userProfile.userId))
                  getDocs(messageThreadQuery).then(messageThreadsSnap => {
                    if (!messageThreadsSnap.empty) {
                      let docId = null
                      messageThreadsSnap.forEach(doc => {

                        const docData = doc.data()

                        if (docData.listing === listing.id && docData.senders.includes(listing.by)) {
                          console.log(docData.senders, listing.by, listing.id, docData.listing)
                          docId = doc.id
                        }
                      })

                      if (docId !== null) {
                        setMessagingThreadId(docId)
                      }
                    }
                  })
                } else {
                  setIsMeetLaterAlreadySetup(false)
                }
              } else {
                setIsMeetLaterAlreadySetup(false)
              }
            })
          }
        }
      }
    })

    getDocs(collection(firestore, `listing/${id}/photos`)).then(collectionData => {
      let photosList = []
      collectionData.forEach(doc => {
        photosList.push(doc.data())
      })
      photosList.sort((a, b) => a.index - b.index)
      setPhotos(photosList)
    })

    getDocs(collection(firestore, `listing/${id}/floor`)).then(collectionData => {
      let photosList = []
      collectionData.forEach(doc => {
        photosList.push(doc.data())
      })
      photosList.sort((a, b) => a.index - b.index)
      setFloorPlans(photosList)
    })

  }

  const handleEditListing = () => {
    if (userProfile?.profileBlocked) {
      showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile.')
    }
    else {
      if (userProfile?.role === UserRoleValue.ManageMent) {
        if (userProfile.profileStatus !== 'approved') {
          showAlert(true, 'Listmeet has not approved your company yet.')
        }
        else {
          return (
            navigate(`/edit/listing/${id}`)
          )
        }
      }
      else {
        if (userProfile?.agencyApproved === false) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Listmeet has not approved your brokerage yet.')
          }
          else {
            showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.')
          }
        } else if (userProfile?.suspended === true) {
          if (userProfile?.role === UserRoleValue.Broker) {
            showAlert(true, 'Your brokerage has been suspended.')
          }
          else {
            showAlert(true, 'Contact your broker to reinstate your account.')
          }
        }
        else {
          return (
            navigate(`/edit/listing/${id}`)
          )
        }
      }
    }

  }

  const handleClickShare = () => {
    setShareButtonsShowing(true)
  }

  const handleClickReport = () => {
    setReportModalOpening(true)
  }

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0)
      loadData()
    }

  }, [id, userProfile])

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {/* {isMobile ? <BlueMenuBarWithBackButton /> : <HomeBlueMenuBar />} */}
          <HomeBlueMenuBar />
          <Flexbox style={{ width: '100%', justifyContent: 'center' }}>
            <Flexbox style={{ width: '90%', maxWidth: 1178, position: isMobile ? 'unset' : 'relative' }}>
              <Flexbox row style={{ width: '100%', alignItems: 'center', height: 64, marginTop: 20 }}>
                <Button style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => navigate(-1)} icon={<img src={IconBack} width={16} height={16} alt="back-icon" />}>
                  Go Back
                </Button>
                <div style={{ flex: 1 }} />
                <Flexbox row style={styles.rightActionButtons}>
                  <Button style={{ width: !isMobile && 110, height: 30 }} icon={<img src={IconShare} alt="share-icon" />} secondary onClick={handleClickShare}>
                    {isMobile ? "" : "Share"}
                  </Button>
                  <Button
                    style={{ width: !isMobile && 110, height: 30 }}
                    secondary
                    onClick={handleSave}
                    icon={<img src={savedPropertyIds?.some(data => data.algoliaID === listingData?.algoliaID) ? IconFilledHeartWhite : IconHeartWhite} width={16} height={16} alt="favorite" />}
                  >
                    {isMobile ? "" : savedPropertyIds?.some(data => data.algoliaID === listingData?.algoliaID) ? 'Saved' : 'Save'}
                  </Button>
                </Flexbox>
              </Flexbox>
              {shareButtonsShowing && (
                <Flexbox row style={styles.shareButtons}>
                  <FacebookShareButton
                    url={window.location.href}
                    quote="Property in ListMeet"
                    hashtag='ListMeet'
                  >
                    <FacebookIcon round size={32} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title="Property in ListMeet"
                  >
                    <TwitterIcon round size={32} />
                  </TwitterShareButton>
                  <EmailShareButton
                    url={window.location.href}
                    subject='Property in ListMeet'
                  >
                    <EmailIcon round size={32} />
                  </EmailShareButton>
                </Flexbox>
              )}
              {isMobile ? <ListingPhotosCarousel photos={carouselPhotos} isSold={listingData?.status === 'Sold' ? true : false} isRented={listingData?.status === 'Rented' ? true : false} /> : <ListingPhotos photos={carouselPhotos} isSold={listingData?.status === 'Sold' ? true : false} isRented={listingData?.status === 'Rented' ? true : false} />}
              <Flexbox style={{ width: '100%', marginTop: 16 }}>
                {isLoading ? (
                  <img src={LoaderGif} style={{ width: 30, height: 30, marginTop: 40 }} alt="loader" />
                ) : (
                  <Flexbox style={{
                    width: '100%',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? 20 : 50
                  }}>
                    <ListingDetailsInformationView
                      listingData={listingData}
                      isMyListing={isMyListing}
                      isMyAgencyListing={isMyAgencyListing}
                      agency={agency}
                      teamMembers={teamMembers}
                      isMeetLaterAlreadySetup={isMeetLaterAlreadySetup}
                      meetLaterWhen={meetLaterWhen}
                      messagingThreadId={messagingThreadId}
                      handleOpenMessageRoom={handleOpenMessageRoom}
                      handleOpenMeetLater={handleOpenMeetLater}
                    />
                    <Flexbox style={{ width: isMobile ? '100%' : 360 }}>
                      {isMyListing && (
                        <ListingDetailsCreatorView
                          openHouseSchedules={openHouseSchedules}
                          handleEditListing={handleEditListing}
                        />
                      )}
                      {!isMobile && (
                        <ListingDetailsSideInformationView
                          isMyListing={isMyListing}
                          isMyAgencyListing={isMyAgencyListing}
                          agency={agency}
                          teamMembers={teamMembers}
                          isMeetLaterAlreadySetup={isMeetLaterAlreadySetup}
                          meetLaterWhen={meetLaterWhen}
                          messagingThreadId={messagingThreadId}
                          handleOpenMessageRoom={handleOpenMessageRoom}
                          handleOpenMeetLater={handleOpenMeetLater}
                        />
                      )}
                      <TypeFace semiBold size={11} color="red" style={{ cursor: 'pointer', alignSelf: 'flex-end' }} onClick={handleClickReport}>
                        Report listing
                      </TypeFace>
                      <div style={styles.mapStaticImageContainer}>
                        <img
                          src={getStaticMapUrl(listingData?.address.geo, 431, 320)}
                          width='100%'
                          height='100%'
                          alt="map-static"
                        />
                      </div>
                      <ListingDetailsDownloadView />
                    </Flexbox>
                  </Flexbox>
                )}
              </Flexbox>
            </Flexbox>
          </Flexbox>

          <div style={{ height: 100 }} />
          <ListingSimilarProperties type={listingData?.type} listingId={listingData?.id} location={listingData?.address.geo} />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />

          {isMobile && (
            <MobileListingDetailsBottomSticker
              isMeetLaterAlreadySetup={isMeetLaterAlreadySetup}
              handleOpenMeetLater={handleOpenMeetLater}
            />
          )}

          <MeetLaterProcessModal
            modalIsOpen={meetLaterModalIsOpening}
            onCloseModal={() => setMeetLaterModalIsOpening(false)}
            listingData={listingData}
            onSubmitted={({
              meetTime,
              messageThreadId: newMeetLaterMessageThreadId
            }) => {
              setMeetLaterWhen(meetTime)
              setMessagingThreadId(newMeetLaterMessageThreadId)
              setIsMeetLaterAlreadySetup(true)
            }}
          />
          <MessageRoomModal
            isModalOpened={messagingRoomOpened}
            onCloseModal={() => setMessagingRoomOpened(false)}
            messagingThreadId={messagingThreadId}
            handlePayToAgency={() => setPaymentSheetOpening(true)}
          />
          <ListingDetailsReportModal
            modalIsOpen={reportModalOpening}
            onCloseModal={() => setReportModalOpening(false)}
            listingId={id}
            propertyAddress={listingData?.address?.full}
            propertyUnit={listingData?.unit}
          />
          <PaymentSheet
            isOpened={paymentSheetOpening}
            listingAgencyName={agency?.name}
            onClose={() => setPaymentSheetOpening(false)}
            listingId={id}
            listingAgentId={listingData?.by}
            messageThreadId={messagingThreadId}
          />
        </div>
      </div>
    </div>
  )
}

export default ListingDetails

const styles = {
  meetNow: {
    marginTop: 20,
    height: 50,
    width: '100%',
  },
  rowButtons: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10
  },
  rightActionButtons: {
    gap: 10,
  },
  mapStaticImageContainer: {
    width: '100%',
    height: 320,
    marginTop: 10
  },
  shareButtons: {
    gap: 10,
    width: '100%',
    marginRight: 180,
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  messageButton: {
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
  }
}
