import { useEffect, useMemo, useRef, useState } from "react"
import { Colors } from "src/theme/Colors"
import TextInput from "../input/TextInput"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import AddOpenHouseModal from "./AddOpenHouseModal"
import IconCalendar from "src/assets/images/icons/icon-calendar.svg"
import IconDelete from "src/assets/images/icons/icon-delete.svg"
import IconEdit from "src/assets/images/icons/icon-edit.svg"
import moment from "moment"
import { useParams } from "react-router-dom"
import { addDoc, collection, doc, getDoc, onSnapshot, updateDoc, deleteDoc} from "firebase/firestore"
import { firestore } from "src/firebase"
import Loading from 'react-fullscreen-loading';
import { scheduleFromToString } from "src/utils/strings"
import debounce from "lodash.debounce"
import useMediaQuery from "src/hooks/useMediaQuery"
import { format } from 'date-fns'

const EditListingMarketing = ({
  listing,
  onUpdateRootFields,
  onForceSave
}) => {

  const { id } = useParams()
  const { isMobile } = useMediaQuery()

  const [processing, setProcessing] = useState(false)
  const [addOpenHouseOpening, setAddOpenHouseOpening] = useState(false)
  const [editingSchedule, setEditingSchedule] = useState()
  const [openHouseSchedules, setOpenHouseSchedules] = useState([])
  
  const unsubscribeSchedulesRef = useRef()

  const changeWebsiteUrl = (event) => {
    const changedValue = event.target.value
    onUpdateRootFields({
      website: changedValue
    })
  }

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeWebsiteUrl, 500);
  }, []);

  const handleAddOpenHouse = async (date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    setProcessing(true)
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }

    const docRef = await addDoc(collection(firestore, `listing/${id}/schedules`), record)
    updateDoc(docRef, { id: docRef.id })
    onForceSave(true)
    record.id= docRef.id

    setOpenHouseSchedules([...openHouseSchedules, record])
    setProcessing(false)
  }

  const handleEditOpenHouse = async (scheduleId, date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    setProcessing(true)
   
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }
    updateDoc(doc(firestore, `listing/${id}/schedules/${scheduleId}`), record)
    onForceSave(true)
    
    setProcessing(false)
  }

  const handleDeleteOpenHouseSchedule = (openHouseScheduleID) => {
    deleteDoc(doc(firestore, `listing/${listing.id}/schedules/${openHouseScheduleID}`))
  }

  useEffect(() => {
    unsubscribeSchedulesRef.current = onSnapshot(collection(firestore, `listing/${id}/schedules`), (snapshot) => {
      let schedules = []
      snapshot.forEach(doc => {
        //schedules.push(doc.data())
        const scheduledDateTime = new Date(doc.data().scheduleDateTime); 
          if (scheduledDateTime >= new Date()) {
            schedules.push(doc.data())
          }
      })
      setOpenHouseSchedules(schedules)
    })
  }, [id])

  /* useEffect(() => {
    document.getElementById('website-url-input').value = listing.website ?? ''
  }, [listing]) */

  useEffect(() => {
    return () => {
      if (unsubscribeSchedulesRef.current) {
        unsubscribeSchedulesRef.current()
      }
    }
  }, []);

  return (
    <Flexbox style={styles.container}>
      <TypeFace medium size={20}>
        Open House Schedule
      </TypeFace>
      <Flexbox row onClick={() => {
        setEditingSchedule()
        setAddOpenHouseOpening(true)
      }} style={styles.addAnotherOpenHouse}>
        <TypeFace semiBold size={11} color={Colors.primary} style={{ cursor: 'pointer' }}>
          + ADD ANOTHER OPEN HOUSE
        </TypeFace>
      </Flexbox>
      {openHouseSchedules.map((openHouseSchedule, index) => (
        <Flexbox row style={{...styles.openHouseRow, width: isMobile ? 'calc(100% - 40px)' : 700, marginTop: index === 0 ? 20 : 10}} key={index}>
          <img src={IconCalendar} width={16} height={16} />
          <TypeFace size={12}>
            Open house: {moment(new Date(openHouseSchedule.date)).format('ddd, MMM D')} ({scheduleFromToString(openHouseSchedule)})
          </TypeFace>
          <div style={{ flex: 1 }} />
          <Flexbox row style={styles.rowButtons} onClick={() => {
            handleDeleteOpenHouseSchedule(openHouseSchedule.id)
          }}>
            <img src={IconDelete} width={12} height={12} />
            <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }}>
              DELETE
            </TypeFace>
          </Flexbox>
          <Flexbox onClick={() => {
            setEditingSchedule(openHouseSchedule)
            setAddOpenHouseOpening(true)
          }} row style={styles.rowButtons}>
            <img src={IconEdit} width={12} height={12} />
            <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }}>
              EDIT
            </TypeFace>
          </Flexbox>
        </Flexbox>
      ))}
      
      {/* <TypeFace medium size={20} style={{ marginTop: 100 }}>
        Website
      </TypeFace>
      <TypeFace bold size={12} style={{ marginTop: 20 }}>
        URL
      </TypeFace>
      <TextInput id={'website-url-input'} style={styles.input} onChange={debouncedChangeHandler} /> */}
      <AddOpenHouseModal
        modalIsOpen={addOpenHouseOpening}
        schedule={editingSchedule}
        onEditOpenHouse={handleEditOpenHouse}
        onAddOpenHouse={handleAddOpenHouse}
        closeModal={() => setAddOpenHouseOpening(false)}
      />
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Flexbox>
  )
}

export default EditListingMarketing

const styles = {
  container: {
    padding: "60px 0",
    alignItems: 'flex-start'
  },
  input: {
    width: 300,
    height: 40,
    marginTop: 5,
    borderRadius: 0,
  },
  addAnotherOpenHouse: {
    width: 200,
    height: 40,
    marginTop: 20,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    border: '1px solid #777',
  },
  rowButtons: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  openHouseRow: {
    width: 700,
    height: 50,
    borderRadius: 0,
    border: '1px solid #ccc',
    paddingLeft: 20,
    paddingRight: 20,
    gap: 10,
  }
}