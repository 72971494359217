import React from 'react';

const Flexbox = (props) => {

  const {style, row, children, ...rest} = props

  // console.log("333333333333333 = ", rest)

  return (
    <div style={{
      display: 'flex',
      flexDirection: row ? 'row' : 'column',
      alignItems: 'center',
      ...style
    }} {...rest}>
      {children}
    </div>
  )
}

export default Flexbox