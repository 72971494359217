import React, { useEffect, useMemo, useState } from 'react';
import { Colors } from 'src/theme/Colors';
import Modal from 'react-modal';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import IconClose from 'src/assets/images/icons/icon-close.svg'
import TextInput from 'src/components/input/TextInput';
import ReactSelect from 'react-select';
import { priceFormat } from 'src/utils/strings';
import { monthlyPaymentAmount } from 'src/utils/calculators';
import useMediaQuery from 'src/hooks/useMediaQuery';

const PaymentCalculatorModal = ({
  originalSalesPrice,
  marketAs,
  maintenance,
  monthlyTax,
  commonCharges,
  modalIsOpen,
  closeModal
}) => {

  const { isMobile } = useMediaQuery()

  const [salePrice, setSalePrice] = useState()
  const [downPayment, setDownPayment] = useState()
  const [interestRate, setInterestRate] = useState("7.625")
  const [term, setTerm] = useState(30)
  const [monthlyTaxAmount, setMonthlyTaxAmount] = useState()

  const [minimumDownPayment, setMinimumDownPayment] = useState()

  const termYearsOptionas = [
    {label: "10 Years", value: 10},
    {label: "15 Years", value: 15},
    {label: "20 Years", value: 20},
    {label: "30 Years", value: 30}
  ]

  useEffect(() => {
    setSalePrice(originalSalesPrice)
    if (originalSalesPrice && originalSalesPrice !== "" && !isNaN(Number(originalSalesPrice))) {
      setDownPayment(`${Number(originalSalesPrice) * 0.2}`)
      setMinimumDownPayment(Number(originalSalesPrice) * 0.2)
    }
  }, [originalSalesPrice])

  const minimumIndicatorColor = useMemo(() => {
    if (minimumDownPayment && downPayment && downPayment !== "" && !isNaN(Number(downPayment))) {
      if (minimumDownPayment > downPayment) {
        return "red"
      }
    }

    return "black"
  }, [downPayment, minimumDownPayment])

  function onCloseModal() {
    closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleClose = () => {
    onCloseModal()
  }

  const downPaymentPercent = useMemo(() => {
    if (salePrice !== '' && downPayment !== '') {
      if (!isNaN(Number(salePrice)) && !isNaN(Number(downPayment))) {
        return `(${(Number(downPayment) / Number(salePrice) * 100).toFixed(1)}%)`
      }
    }
  }, [salePrice, downPayment])

  const payments = useMemo(() => {
    if (salePrice && salePrice !== "" && !isNaN(salePrice)) {
      if (downPayment && downPayment !== "" && !isNaN(downPayment)) {
        if (interestRate && interestRate !== "" && !isNaN(interestRate)) {
          let extraMonthlyFee = 0
          let monthlyTaxAmount = 0
/*           if (marketAs === 'building' || marketAs === 'condo' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') {
 */            
          if (marketAs === 'building' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') {
            // Yearly Calculation
            extraMonthlyFee += Number((monthlyTax / 12).toFixed(2) ?? "0")
            monthlyTaxAmount += Number((monthlyTax / 12).toFixed(2) ?? "0")
          }

          if (marketAs === 'condo') {
            extraMonthlyFee += Number(monthlyTax ?? "0")
            monthlyTaxAmount += Number(monthlyTax ?? "0")
          }
          
          if (marketAs === 'coop') {
            extraMonthlyFee += Number(maintenance ?? "0")
            monthlyTaxAmount += Number(maintenance ?? "0")
          }

          if (marketAs === 'coop' || marketAs === 'condo') {
            extraMonthlyFee += Number(commonCharges ?? "0")
          }
          setMonthlyTaxAmount(monthlyTaxAmount)
          return monthlyPaymentAmount({
            salePrice: Number(salePrice),
            downPayment: Number(downPayment),
            interestRate: Number(interestRate),
            termYears: term,
            extra: extraMonthlyFee,
          })
        }
      }
    }

    return null
  }, [salePrice, downPayment, interestRate, term, marketAs, commonCharges, monthlyTax, maintenance])

  

  const styles = {
    separator: {
      height: 1,
      width: '100%',
      backgroundColor: '#DFDFDF',
      marginTop: 20,
      marginBottom: 20
    },
    titleHeader: {
      width: '100%',
      height: 44,
      backgroundColor: Colors.primary,
      justifyContent: 'center',
    },
    closeButton: {
      position: 'absolute',
      width: 44,
      height: 44,
      left: 0,
      top: 0,
      backgroundColor: '#0000',
      borderRadius: 0,
    },
    content: {
      width: 'calc(100% - 30px)',
      paddingTop: 15,
      paddingBottom: 15,
      gap: 20
    },
    row: {
      width: '100%',
      gap: 20,
    },
    rowCaptionPart: {
      width: isMobile ? 140 : 200,
      alignItems: 'flex-end',
    },
    rowContentPart: {
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 10
    },
    input: {
      width: isMobile ? 150 : 200,
      height: 44,
    },
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{ width: isMobile ? 'calc(100vw - 40px)' : 600 }}>
        <Flexbox style={styles.titleHeader}>
          <Button onClick={handleClose} icon={<img src={IconClose} width={20} height={20} alt="close-icon" />} secondary style={styles.closeButton} textColor='white'>
          </Button>
          <TypeFace bold color="white">
            Payment Calculator
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.content}>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                SALE PRICE
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TextInput
                style={styles.input}
                value={salePrice}
                onChange={e => setSalePrice(e.target.value)}
              />
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                DOWN PAYMENT
              </TypeFace>
            </Flexbox>
            <Flexbox row style={{...styles.rowContentPart, gap: isMobile ? 5 : 10}}>
              <TextInput
                style={{...styles.input, width: isMobile ? 110 : 200}}
                value={downPayment}
                onChange={e => setDownPayment(e.target.value)}
              />
             {/*  <TypeFace size={isMobile ? 10 : 14}>
                {downPaymentPercent}
              </TypeFace> */}
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TypeFace color={minimumIndicatorColor} size={isMobile ? 10 : 14}>
              {downPayment ? `$${priceFormat(downPayment, true)}` : ''} {downPaymentPercent}
              </TypeFace>
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                INTEREST RATE
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TextInput
                style={styles.input}
                value={interestRate}
                onChange={e => setInterestRate(e.target.value)}
              />
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                TERM
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <div style={{ width: isMobile ? 150 : 200 }}>
                <ReactSelect
                  placeholder="Select multiple"
                  options={termYearsOptionas}
                  defaultValue={{ label: '30 Years', value: 30 }}
                  onChange={(val) => {
                    setTerm(val.value)
                    if (val.value <= 15) {
                      setInterestRate("6.875")
                    } else {
                      setInterestRate("7.625")
                    }
                  }}
                />
              </div>
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                MORTGAGE AMOUNT
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TypeFace>
                {payments ? `$${priceFormat(payments.mortgageAmount, true)}` : ''}
              </TypeFace>
            </Flexbox>
          </Flexbox>
          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                MORTGAGE PAYMENT
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TypeFace>
                {payments ? `$${priceFormat(payments.mortgagePayment, true)}` : ''}
              </TypeFace>
            </Flexbox>
          </Flexbox>
          {marketAs === 'condo' && (
            <Flexbox row style={styles.row}>
              <Flexbox style={styles.rowCaptionPart}>
                <TypeFace size={isMobile ? 11 : 13} semiBold>
                  COMMON CHARGES
                </TypeFace>
              </Flexbox>
              <Flexbox row style={styles.rowContentPart}>
                <TypeFace>
                  ${priceFormat(commonCharges)}
                </TypeFace>
              </Flexbox>
            </Flexbox>
          )}
          {(marketAs === 'coop') && (
            <Flexbox row style={styles.row}>
              <Flexbox style={styles.rowCaptionPart}>
                <TypeFace size={isMobile ? 11 : 13} semiBold>
                  MAINTENANCE
                </TypeFace>
              </Flexbox>
              <Flexbox row style={styles.rowContentPart}>
                <TypeFace>
                  ${priceFormat(maintenance)}
                </TypeFace>
              </Flexbox>
            </Flexbox>
          )}
          {(marketAs === 'building' || marketAs === 'condo' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') && (
            <Flexbox row style={styles.row}>
              <Flexbox style={styles.rowCaptionPart}>
                <TypeFace size={isMobile ? 11 : 13} semiBold>
                  {(marketAs === 'building' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') ? 'TAXES' : 'MONTHLY TAXES'}
                </TypeFace>
              </Flexbox>
              <Flexbox row style={styles.rowContentPart}>
                <TypeFace>
                ${priceFormat(monthlyTaxAmount)}
                </TypeFace>
              </Flexbox>
            </Flexbox>
          )}

          <div style={styles.separator} />

          <Flexbox row style={styles.row}>
            <Flexbox style={styles.rowCaptionPart}>
              <TypeFace size={isMobile ? 11 : 13} semiBold>
                EST.MONTHLY PAYMENT
              </TypeFace>
            </Flexbox>
            <Flexbox row style={styles.rowContentPart}>
              <TypeFace bold>
                {payments ? `$${priceFormat(payments.estMonthlyPayment, true)}` : ''}
              </TypeFace>
            </Flexbox>
          </Flexbox>

          <TypeFace size={12} color='#7f7f7f'>
            Home insurance or tax implications not included
          </TypeFace>
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default PaymentCalculatorModal
