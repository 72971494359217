import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { updateAlogliaObject } from "src/algolia";
import { firestore } from "src/firebase";
import { roleLabelFromValue } from "src/model/types";
import AdminLayout from "../../../../layouts/AuthLayout";
import { Button, Flex, Input, Table, Tooltip, Typography } from "antd";
import { ArrowLeftOutlined, BankOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;

const AdminAgencyDetails = () => {
  const { agencyId } = useParams();
  const navigate = useNavigate();

  const unmountedRef = useRef(false);

  const [agencyData, setAgencyData] = useState();
  const [agencyMembers, setAgencyMembers] = useState([]);
  const [agListing, setAgListing] = useState([]);
  const [search, setSearch] = useState("");

  const handleApprove = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      agencyApproved: true,
      agencyReleased: false,
      releasedDate: "",
      activatedDate: new Date().getTime(),
    });
  };

  const handleBlock = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      profileBlocked: true,
      activatedDate: "",
    });

    const q = query(collection(firestore, `listing`), where("by", "==", userId));
    getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        const listingData = doc.data();
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: true,
        });

        updateAlogliaObject(listingData.algoliaID, {
          status: "Blocked",
        });
      });
    });
  };

  const handleUnblock = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      profileBlocked: false,
      activatedDate: new Date().getTime(),
    });

    const q = query(collection(firestore, `listing`), where("by", "==", userId));
    getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        const listingData = doc.data();
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: false,
        });

        updateAlogliaObject(listingData.algoliaID, {
          status: doc.data().status,
        });
      });
    });
  };

  const handleViewListing = (creatorId) => {
    navigate(`/admin/listings/${creatorId}`);
  };

  function getData(query = "") {
    if (query === "") return setAgListing(agencyMembers);
    const res = agencyMembers.filter((obj) => {
      if (obj?.name?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.email?.toLowerCase().includes(query?.toLowerCase())) return true;
      if (obj?.licenseNumber == query) return true;
      return false;
    });
    setAgListing(res);
  }

  useEffect(() => {
    unmountedRef.current = false;

    getDoc(doc(firestore, `agencies/${agencyId}`)).then((agencyDoc) => {
      setAgencyData(agencyDoc.data());
    });

    const snapshotListener = onSnapshot(
      query(collection(firestore, "profiles"), where("agency", "==", agencyId)),
      (querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setAgencyMembers(d);
          setAgListing(d);
        }
      }
    );
    return () => {
      unmountedRef.current = true;
      snapshotListener();
    };
  }, [agencyId]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        <Flex vertical>
          <Text>{record.name ? `${record.name}` : "-"}</Text>
          <a href={`mailto:${record.email}`}>{record.email ? `${record.email}` : "-"}</a>
        </Flex>
      ),
    },
    {
      title: "License",
      dataIndex: "licenseNumber",
      render: (_, record) => (
        <Flex vertical>
          <Text>{record.licenseName ? `${record.licenseName}` : "-"}</Text>
          <Text>{record.licenseNumber ? `${record.licenseNumber}` : "-"}</Text>
        </Flex>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => roleLabelFromValue(role),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, item) => (
        <Text>
          {item.role !== "broker"
            ? item.profileBlocked
              ? "Blocked"
              : item.agencyApproved
              ? "Approved"
              : "Unapproved"
            : "-"}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      render: (text) => moment(text).format("MMM DD, YYYY"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, item) => {
        if (item.role !== "broker") {
          return (
            <Flex gap={5}>
              {item.agencyApproved !== true && <Button onClick={() => handleApprove(item.userId)}>Approve</Button>}
              {item.profileBlocked ? (
                <Button onClick={() => handleUnblock(item.userId)}>Unblock</Button>
              ) : (
                <Button danger onClick={() => handleBlock(item.userId)}>
                  Block
                </Button>
              )}
              <Tooltip title="View Listings">
                <Button size="default" onClick={() => handleViewListing(item.userId)}>
                  <BankOutlined />
                </Button>
              </Tooltip>
            </Flex>
          );
        }
      },
    },
  ];

  const header = (
    <Flex gap={10}>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/admin/agencies")}
        style={{ color: "black", fontWeight: "bold" }}
      />
      <span>{agencyData?.name ?? "Agency Details"}</span>
    </Flex>
  );

  const headerRight = (
    <div>
      <Search
        placeholder="Search for agent name, email, license number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={(value) => {
          getData(value);
        }}
        style={{
          width: 300,
        }}
        onReset={() => {
          setSearch("");
          setAgListing(agencyMembers);
        }}
        allowClear
        size="large"
      />
    </div>
  );

  return (
    <AdminLayout title={header} headerRight={headerRight}>
      <Table dataSource={agListing} columns={columns} style={{ width: "100%" }} />
    </AdminLayout>
  );
};

export default AdminAgencyDetails;
