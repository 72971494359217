import React from 'react'
import { Colors } from 'src/theme/Colors'
import TypeFace from '../typography/Typefaces'
import useMediaQuery from 'src/hooks/useMediaQuery'


const HomeBlueCopyRightFooter = (props) => {

  const { isMobile } = useMediaQuery()

  return (
    <>
      {/* <img
        src={require('src/assets/images/bg/img-home-footer.jpg')}
        style={{
          ...styles.imageFooter,
          width: isMobile ? '100%' : '75%',
          height: isMobile ? 120 : 240,
        }}
      /> */}
      <div style={styles.container}>
        <TypeFace medium size={14} color={'white'}>
          2023 Ⓒ Copyright - All Rights Reserved.
        </TypeFace>
      </div>
    </>
  )
}

export default HomeBlueCopyRightFooter

const styles = {
  container: {
    display: 'flex',
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primary,
  },
  imageFooter: {
    marginTop: 30,
    width: '100%',
    height: 240,
    objectFit: 'contain',
  },
}