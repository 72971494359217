import React from 'react';
import Flexbox from '../wrapper/Flexbox';
import Modal from 'react-modal';
import useMediaQuery from 'src/hooks/useMediaQuery';
import HomeDownloadAppPanel from 'src/components/home/HomeDownloadAppPanel';

const DownloadAppModal = (props) => {

  const { isMobile } = useMediaQuery()

  function closeModal() {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{
        width: isMobile ? 'calc(100vw - 80px)' : 1024,
        padding: isMobile ? '20px 16px 16px' : '40px 30px 30px',
      }}>
        {/* <TypeFace medium size={16}>
          Download iOS or Android application
        </TypeFace>
        <Flexbox row style={styles.download}>
          <img src={require('src/assets/images/appstore.png')} style={styles.button} />
          <img src={require('src/assets/images/googleplay.png')} style={styles.button} />
        </Flexbox> */}
        <HomeDownloadAppPanel style={isMobile ? { textAlign: "center" } : {}} isModal={true} />
      </Flexbox>
    </Modal>
  )
}

export default DownloadAppModal

const styles = {
  download: {
    marginTop: 20,
    justifyContent: "space-between"
  },
  button: {
    width: "48%",
    maxWidth: 204,
    // height: 59
  }
}