import React, { useEffect } from 'react';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import HomeSocialConnectPanel from 'src/components/home/HomeSocialConnectPanel';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import TypeFace from 'src/components/typography/Typefaces';

const Cookie = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <div style={styles.container}>
            <div style={{ width: "90%", maxWidth: 1178 }}>
              <TypeFace type="div" bold style={styles.title}>
                COOKIE POLICY
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.subTitle}>
                Welcome to ListMeet!
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This cookie policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; www.ListMeet.com
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                (Hereinafter referred to as “ListMeet”).
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                The information collected through cookies will be under responsibility and in charge of:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; ListMeet
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; support@ListMeet.com
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                This cookie policy should be read together with our <a href="/privacy-policy">privacy policy</a> and our <a href="/terms">terms and conditions</a>.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                By using the website, you accept the use of cookies by List, in the terms contained in this policy.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                1. WHAT ARE COOKIES?
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before. Cookies are a very common web technology; most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently. Cookies are used to measure which parts of the website users visit and to personalize their experience. Cookies also provide information that helps us monitor and improve the performance of the website.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                2. COOKIE SETTINGS
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of ListMeet.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent ListMeet from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                We provide the links for the management and blocking of cookies depending on the browser you use:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Microsoft Edge:&nbsp;
                <a href="https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&amp;rs=en-us&amp;ad=us" target='_blank' rel="noreferrer">https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&amp;rs=en-us&amp;ad=us</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Firefox:&nbsp;
                <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target='_blank' rel="noreferrer">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Chrome:&nbsp;
                <a href="https://support.google.com/chrome/answer/95647?hl=en" target='_blank' rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=en</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Safari:&nbsp;
                <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target='_blank' rel="noreferrer">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Opera:&nbsp;
                <a href="https://help.opera.com/en/latest/web-preferences/" target='_blank' rel="noreferrer">https://help.opera.com/en/latest/web-preferences/</a>
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                In cases where you access the website through an iOS or Android mobile device, please follow the instructions below to delete or block cookies on your device:
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; Android:&nbsp;
                <a href="https://support.google.com/answer/32050" target='_blank' rel="noreferrer">https://support.google.com/answer/32050</a>
              </TypeFace>
              <TypeFace type="div" semiBold style={{ ...styles.text, ...styles.inline }}>
                &#8226; iOS:&nbsp;
                <a href="https://support.apple.com/en-us/HT201265" target='_blank' rel="noreferrer">https://support.apple.com/en-us/HT201265</a>
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                3. FIRST-PARTY COOKIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                We use cookies to enhance the performance of our website and personalize your online experience. Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our cookies. We always use cookie data in a responsible way.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                4. THIRD-PARTY COOKIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services.
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                5. PURPOSES OF OUR COOKIES
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                Our cookies are used for the following purposes:
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Necessary: <TypeFace>Necessary cookies are essential files that enable basic functions on the website, such as navigation and security. They do not collect personal information.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Functional: <TypeFace>We use functional cookies to enhance your online experience by remembering user preferences and settings and maintaining consistency across the website, without tracking activities.</TypeFace>
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                Analytics and Performance: <TypeFace>These cookies collect information about how users interact with our website, including the most visited pages, as well as other analytical data. We use this data to improve how our website works and to understand how users interact with the website.</TypeFace>
              </TypeFace>

              <br />
              <TypeFace type="div" semiBold style={styles.subTitle}>
                6. CONTACT US
              </TypeFace>
              <TypeFace type="div" style={styles.text}>
                If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through our contact forms or by using the contact information below:
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                ListMeet.
              </TypeFace>
              <TypeFace type="div" semiBold style={styles.text}>
                <a href="mailto:support@ListMeet.com">support@ListMeet.com</a>
              </TypeFace>
            </div>
          </div>
          <HomeSocialConnectPanel />
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default Cookie

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0",
  },
  title: {
    fontSize: 24,
    margin: "30px 0",
  },
  subTitle: {
    fontSize: 20,
    margin: "20px 0",
    textAlign: "left",
  },
  text: {
    fontSize: 16,
    margin: "16px 0",
    textAlign: "left",
    lineHeight: 1.6
  },
  inline: {
    paddingLeft: 16,
  }
}